import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { RouteKey, routeFor } from '../../Routes/routes';
import LoadSummaryContainer from '../../containers/load/loadSummary/LoadSummaryContainer';
import Page from '../common/Page';

const LoadSummary: React.FC = () => {
    const { id } = useParams<string>();
    const navigate = useNavigate();

    return (
        <Page>
            <LoadSummaryContainer
                id={id!}
                onContinue={() => navigate(routeFor(RouteKey.WorkQueue)())}
            />
        </Page>
    );
};

export default LoadSummary;
