import React, { useEffect, useState } from 'react';

const usePersistedState = <T>(
    defaultState: T,
    key: string,
    onStateChange?: (state: T) => void
): [T, (x: React.SetStateAction<T>) => void] => {
    const restored = localStorage.getItem(key);
    const restoredValue = restored ? JSON.parse(restored) : defaultState;
    const [value, setValue] = useState<T>(restoredValue);

    const setValueInStorage = (update: React.SetStateAction<T>): void => {
        let newValue = update;
        if (typeof update === 'function') {
            newValue = (update as (x: T) => T)(value);
        }

        localStorage.setItem(key, JSON.stringify(newValue));
        setValue(newValue);
    };

    useEffect(() => {
        if (onStateChange) {
            onStateChange(restoredValue);
        }
    }, [restoredValue, onStateChange]);

    useEffect(() => {
        const handler = (e: StorageEvent) => {
            if (e.key === key) {
                if (e.newValue) {
                    setValue(JSON.parse(e.newValue));
                } else {
                    setValue(defaultState);
                }
            }
        };

        window.addEventListener('storage', handler);

        return () => window.removeEventListener('storage', handler);
    }, [defaultState, key]);

    return [value, setValueInStorage];
};

export default usePersistedState;
