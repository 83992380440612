import * as React from 'react';
import { IodPlan } from '../../../../models/iods/IodPlan';
import IodReplanCard from '../../../../components/iod/update/IodReplanCard';
import { AreIodPlansEqual } from '../../../../services/iodPlanService';
import {
    IodPlanStatistics,
    getIodPlanStatistics
} from '../../../../services/iodPlanStatisticsService';

interface IodReplanOptionsContainerProps {
    currentPlan: IodPlan;
    iodPlanVariations: IodPlan[];
    selectedPlan: IodPlan | null;
    hoveredPlan: IodPlan | null;
    onReplanSelected(replan: IodPlan | null): void;
    onReplanHovered(replan: IodPlan | null): void;
}

const sortIodPlansByIodPlanStatistics = (
    lhs: IodPlan,
    rhs: IodPlan
): number => {
    const lhsStats: IodPlanStatistics = getIodPlanStatistics(lhs);
    const rhsStats: IodPlanStatistics = getIodPlanStatistics(rhs);

    return (
        lhsStats.resequencePalletCount +
        lhsStats.transferCount +
        lhsStats.transferredPalletCount +
        lhsStats.tunnelledPalletCount -
        (rhsStats.resequencePalletCount +
            rhsStats.transferCount +
            rhsStats.transferredPalletCount +
            rhsStats.tunnelledPalletCount)
    );
};

const IodReplanOptionsContainer: React.FC<IodReplanOptionsContainerProps> = ({
    currentPlan,
    iodPlanVariations,
    selectedPlan,
    onReplanSelected,
    onReplanHovered
}) => {
    const isSameAsSelectedPlan = selectedPlan
        ? AreIodPlansEqual(selectedPlan)
        : false;
    const isSameAsCurrentPlan = AreIodPlansEqual(currentPlan);

    return (
        <>
            {iodPlanVariations
                .sort(sortIodPlansByIodPlanStatistics)
                .map((iodPlan, index) => (
                    <IodReplanCard
                        key={index}
                        iodPlan={iodPlan}
                        isSelected={
                            isSameAsSelectedPlan &&
                            isSameAsSelectedPlan(iodPlan)
                        }
                        isDisabled={isSameAsCurrentPlan(iodPlan)}
                        onSelected={onReplanSelected}
                        onPlanHovered={onReplanHovered}
                    />
                ))}
        </>
    );
};

export default IodReplanOptionsContainer;
