import * as React from 'react';
import { isNullOrUndefined } from 'is-what';
import AddBayForm from '../../../components/admin/bayManagement/AddBayForm';
import { LookupDataConsumer } from '../../../contexts/LookupDataProvider';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import Bay from '../../../models/bays/Bay';
import { apiPost } from '../../../services/api';
import { buildClassesHook } from '../../../helpers/styles';
import { useState } from 'react';

const useClasses = buildClassesHook({
    formContainer: {
        padding: '2em 2em',
        borderRadius: '1em',
        marginTop: '3em',
    },
});

interface BayFormContainerProps {
    onAddBayRedirect: () => void;
}

const BayFormContainer: React.FC<BayFormContainerProps> = ({
    onAddBayRedirect,
}) => {
    const classes = useClasses();

    const [bay, setBay] = useState<Bay>({
        id: 0,
        capabilities: [],
        isOutOfOrder: false,
        isVirtualBay: false,
        store: null,
    });

    const handleBayChange = (bay: Bay) => {
        setBay(bay);
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        await apiPost('Bay', bay);
        onAddBayRedirect();
    };

    const isFormValid = () =>
        !isNullOrUndefined(bay.id) && !isNullOrUndefined(bay.store);

    return (
        <Grid fluid={true}>
            <Row justify="center">
                <Column size={'auto'} className={classes.formContainer}>
                    <LookupDataConsumer>
                        {({ loading, stores }) => (
                            <AddBayForm
                                bay={bay}
                                loading={loading}
                                isFormValid={isFormValid()}
                                stores={stores}
                                onBayChange={handleBayChange}
                                onSubmit={handleSubmit}
                            />
                        )}
                    </LookupDataConsumer>
                </Column>
            </Row>
        </Grid>
    );
};

export default BayFormContainer;
