import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import useInterval from '../../hooks/useInterval';
import {
    oneDay,
    oneHour,
    oneMinute,
    oneSecond,
    tenSeconds,
    twoMinutes,
} from '../../models/common/TimeAsMiliseconds';
import { formatToFriendlyTime } from '../../services/timeStringService';
import { useClasses } from './TimeComponent.styles';

interface TimeElapsedSinceProps {
    className?: string;
    overdueClassName?: string;
    elapsedSince: Date;
    elapsedUntil?: Date;
    minRefreshInterval?: number;
    useColors?: boolean;
    overdue?(date: Date): boolean;
    estimate?: boolean;
}

const getTimeElapsed = (date: Date, currentTime: Date) =>
    currentTime.valueOf() - date.valueOf();

const calculateTimeElapsed = (date: Date, currentTime: Date) =>
    formatToFriendlyTime(getTimeElapsed(date, currentTime));

const getIntervalDuration = (
    compareDate: Date,
    currentTime: Date,
    minRefreshInterval: number,
    elapsedUntil?: Date
): number | null => {
    if (elapsedUntil) {
        return null;
    }

    const timeElapsed = Math.abs(getTimeElapsed(compareDate, currentTime));
    if (timeElapsed < twoMinutes) {
        return minRefreshInterval || oneSecond;
    }
    if (timeElapsed < oneHour) {
        return Math.max(minRefreshInterval, tenSeconds);
    }
    if (timeElapsed < oneDay) {
        return Math.max(minRefreshInterval, oneMinute);
    }
    return Math.max(minRefreshInterval, oneHour);
};

const TimeElapsedSince: React.FC<TimeElapsedSinceProps> = ({
    className,
    overdueClassName,
    elapsedSince,
    elapsedUntil,
    minRefreshInterval = 0,
    useColors,
    overdue,
    estimate = false,
}) => {
    const classes = useClasses();

    const [currentTime, setCurrentTime] = useState(new Date());
    const [interval, setInterval] = useState(
        getIntervalDuration(
            elapsedSince,
            currentTime,
            minRefreshInterval,
            elapsedUntil
        )
    );

    useInterval(() => {
        const now = new Date();
        setCurrentTime(now);
        setInterval(getIntervalDuration(elapsedSince, now, minRefreshInterval));
    }, interval);

    return (
        <span
            className={classNames(className, {
                [overdueClassName || classes.overdue]:
                    overdue && overdue(currentTime) && useColors,
            })}
        >
            {estimate ? '~' : ''}
            {calculateTimeElapsed(elapsedSince, elapsedUntil || currentTime)}
        </span>
    );
};

export default TimeElapsedSince;
