import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Button from '../../lib/bootstrap-ui/Button';

const useClasses = buildClassesHook({
    container: {
        width: '100%',
    },
    button: {
        width: 120,
        marginBottom: '15px',
    },
});

interface ConfirmCancelButtonsProps
    extends React.HTMLAttributes<HTMLDivElement> {
    onConfirm(): void;
    onCancel(): void;
    disableCancel?: boolean;
    disableConfirm?: boolean;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmCancelButtons: React.FC<ConfirmCancelButtonsProps> = ({
    disableCancel,
    disableConfirm,
    onCancel,
    onConfirm,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    className,
    ...otherProps
}) => {
    const classes = useClasses();

    return (
        <div
            className={classNames(classes.container, className)}
            {...otherProps}
        >
            <Button
                className={classNames(classes.button, 'float-left')}
                disabled={disableCancel}
                styleType="secondary"
                onClick={onCancel}
            >
                {cancelText}
            </Button>
            <Button
                className={classNames(classes.button, 'float-right')}
                disabled={disableConfirm}
                onClick={onConfirm}
            >
                {confirmText}
            </Button>
        </div>
    );
};

export default ConfirmCancelButtons;
