import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    workHistoryTable: {
        '& a': {
            textDecoration: 'underline',
        },
    },
    noDataContainer: {
        height: '80vh',
    },
    dateRow: {
        top: '6.9rem',
        zIndex: 2,
    },
});
