import * as React from 'react';
import { useState } from 'react';
import ThirdPartyValue from '../../../models/thirdPartyValues/ThirdPartyValue';
import LoadingModal from '../../../components/common/LoadingModal';
import ErrorModal from '../../../components/common/ErrorModal';
import { apiDelete } from '../../../services/api';
import DeleteModal from '../../../components/admin/thirdPartyManagement/update/DeleteModal';
import DropdownOption from '../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import { getErrorMessage } from '../../../helpers/errors';

interface DeleteModalContainerProps {
    showModal: boolean;
    thirdPartyValue: ThirdPartyValue;
    selectedOption: DropdownOption;
    onClose(): void;
}

const DeleteModalContainer: React.FC<DeleteModalContainerProps> = ({
    showModal,
    thirdPartyValue,
    selectedOption,
    onClose
}) => {
    const [updatingValues, setUpdatingValues] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleDeleteRequested = async (value: ThirdPartyValue) => {
        setUpdatingValues(true);
        try {
            await apiDelete(`${selectedOption.value}/${value.id}`);
            setUpdatingValues(false);
            onClose();
        } catch (e) {
            setUpdatingValues(false);
            setErrorMessage(getErrorMessage(e));
        }
    };

    const showLoadingModal = showModal && updatingValues;
    const showDeleteModal = showModal && !updatingValues && !errorMessage;
    const showErrorModal = showModal && !!errorMessage;

    return (
        <>
            <LoadingModal
                showModal={showLoadingModal}
                loadingText="deleting..."
            />
            <DeleteModal
                showModal={showDeleteModal}
                selectedOption={selectedOption}
                thirdPartyValue={thirdPartyValue}
                onDeleteRequested={handleDeleteRequested}
                onClose={onClose}
            />
            <ErrorModal
                showModal={showErrorModal}
                header="Error"
                errorText={errorMessage}
                onClose={onClose}
            />
        </>
    );
};

export default DeleteModalContainer;
