import * as React from 'react';
import { useEffect, useState } from 'react';

import Button from '../../../../lib/bootstrap-ui/Button';
import { TextInput } from '../../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../../lib/bootstrap-ui/Modal';
import { SMSTemplate } from '../../../../models/smsTemplates/SMSTemplate';
import { useClasses } from './SMSTemplateModalStyles';

interface SendTestSMSTemplateModalProps {
    showModal: boolean;
    smsTemplate: SMSTemplate;
    onTestRequested(smsTemplateId: string, contactNumber: string): void;
    onClose(): void;
}

const SendTestSMSTemplateModal: React.FC<SendTestSMSTemplateModalProps> = ({
    showModal,
    smsTemplate,
    onTestRequested,
    onClose
}) => {
    const classes = useClasses();

    const [contactNumber, setContactNumber] = useState<string | null>(null);
    const [contactNumberIsValid, setContactNumberIsValid] = useState(false);
    const [contactNumberError, setContactNumberError] = useState<
        string | undefined
    >();

    useEffect(() => {
        if (!contactNumber) {
            setContactNumberIsValid(false);
            setContactNumberError('Must provide a contact number');
        }
        const strippedContactNumber = contactNumber?.replace(' ', '') || '';
        const contactNumberRegex = RegExp('/(\\+?)(\\d{11,15})/');

        if (strippedContactNumber.length > 15) {
            setContactNumberIsValid(false);
            setContactNumberError('Contact number is too long');
        } else if (strippedContactNumber.length < 11) {
            setContactNumberIsValid(false);
            setContactNumberError('Contact number is too short');
        } else if (contactNumberRegex.test(strippedContactNumber)) {
            setContactNumberIsValid(false);
            setContactNumberError('Contact number is not a valid format');
        } else {
            setContactNumberIsValid(true);
            setContactNumberError(undefined);
        }
    }, [contactNumber]);

    const handleTestRequested = () => {
        if (smsTemplate && smsTemplate.id && contactNumber) {
            onTestRequested(smsTemplate.id, contactNumber);
        }
    };

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Send Test SMS</h5>
            </ModalHeader>
            <ModalBody>
                <div>
                    <TextInput
                        placeholder="Phone number..."
                        value={contactNumber}
                        onChange={setContactNumber}
                        label="Send To:"
                        hideBottomPadding
                        error={contactNumberError}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={!contactNumberIsValid}
                    onClick={() => handleTestRequested()}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SendTestSMSTemplateModal;
