import { IodPlan, FinishingTrailerTrip } from '../models/iods/IodPlan';
import { Load } from '../models/loads/Load';
import { StoreNumber } from '../models/stores/StoreNumber';
import { UserProfile } from './authentication';
import { userHasAccessToStore } from './userService';
import getCombinations, {
    getPermutations,
    removeDuplicates,
} from '../helpers/arrayFunctions';
import {
    getAllLoadsFromIodPlan,
    getAllFinishingTrailerLoadsFromIodPlan,
    getFinishingTrailerFromIodPlanForLoad,
    getIodPlan,
    IodPlanComparator,
    getTransfersFromIodPlanForFinishingTrailerLoad,
    getAllGroupedLoadsFromIodPlan,
} from './iodPlanService';
import {
    LoadRelationship,
    doesIodPlanMaintainRelationships,
    getLoadRelationships,
} from './iodPlanRelationshipService';
import { FTRoute, LoadGroup } from '../models/loads/LoadGroup';

interface TransfersForStore {
    store: StoreNumber;
    transfers: Load[];
}

const recur = (
    finishingTLoads: FTRoute,
    transferCombinationsToAssign: TransfersForStore[][],
    transfersToMaintain: TransfersForStore[],
    originalIodPlan: IodPlan
): FinishingTrailerTrip[][] => {
    if (finishingTLoads.length === 0) return [[]];

    if (
        transferCombinationsToAssign.filter((comb) => comb.length).length === 0
    ) {
        return [
            finishingTLoads.map((loadGroup) => {
                const transfersForLoad: Load[] =
                    getTransfersFromIodPlanForFinishingTrailerLoad(
                        originalIodPlan,
                        loadGroup[0]
                    ).filter(
                        (transfer) =>
                            !finishingTLoads.some((loadGroup) =>
                                loadGroup.some(
                                    (load) => load.id === transfer.id
                                )
                            ) &&
                            transfersToMaintain
                                .map((ttm) => ttm.store)
                                .includes(transfer.sourceStore as StoreNumber)
                    );

                return {
                    load: loadGroup[0],
                    predecessorTransfers: transfersForLoad,
                };
            }),
        ];
    }

    return transferCombinationsToAssign.reduce(
        (
            finishingTrailerTrips: FinishingTrailerTrip[][],
            transferCombination: TransfersForStore[]
        ) => {
            const transferCombinationLoads: Load[] = transferCombination.reduce(
                (rv: Load[], curr: TransfersForStore) =>
                    rv.concat(curr.transfers),
                []
            );

            const transfersToLoadThatUserDoesNotHaveAccessTo: Load[] =
                getTransfersFromIodPlanForFinishingTrailerLoad(
                    originalIodPlan,
                    finishingTLoads[0][0]
                ).filter(
                    (transfer) =>
                        !finishingTLoads.some((loadGroup) =>
                            loadGroup.some((load) => load.id === transfer.id)
                        ) &&
                        transfersToMaintain
                            .map((ttm) => ttm.store)
                            .includes(transfer.sourceStore as StoreNumber)
                );

            const startingTrailerTrip: FinishingTrailerTrip = {
                load: finishingTLoads[0][0],
                predecessorTransfers:
                    transfersToLoadThatUserDoesNotHaveAccessTo.concat(
                        transferCombinationLoads
                    ),
            };

            const startingTrailerTripTransferStores =
                startingTrailerTrip.predecessorTransfers.map(
                    (transfer) => transfer.sourceStore as StoreNumber
                );

            const remainingFinishingTrailerLoads = finishingTLoads.filter(
                (loadGroup) =>
                    !loadGroup.some(
                        (load) => load.id === startingTrailerTrip.load.id
                    )
            );

            const remainingTransfersToAssign: TransfersForStore[][] =
                transferCombinationsToAssign.filter(
                    (combination) =>
                        !combination
                            .map((comb) => comb.store)
                            .some((store) =>
                                startingTrailerTripTransferStores.includes(
                                    store
                                )
                            )
                );

            const remainingTransfersToMaintain: TransfersForStore[] =
                transfersToMaintain.filter(
                    (transfer) =>
                        !startingTrailerTripTransferStores.includes(
                            transfer.store
                        )
                );

            const value = recur(
                remainingFinishingTrailerLoads,
                remainingFinishingTrailerLoads.length === 1 &&
                    remainingTransfersToAssign.length > 0
                    ? [
                          remainingTransfersToAssign.sort(
                              (lhs, rhs) => rhs.length - lhs.length
                          )[0],
                      ]
                    : remainingTransfersToAssign,
                remainingTransfersToMaintain,
                originalIodPlan
            ).map((comb) => [startingTrailerTrip, ...comb]);

            return finishingTrailerTrips.concat(value);
        },
        []
    );
};

const getFinishingTrailerTripsForMultiFinishingTrailerAndMultiTransferPlans = (
    finishingTrailerRoute: FTRoute,
    transfers: Load[],
    originalIodPlan: IodPlan,
    user: UserProfile
): FinishingTrailerTrip[][] => {
    const uniqueTransferStores: StoreNumber[] = transfers
        .map((transfer) => transfer.sourceStore as StoreNumber)
        .filter((v, i, s) => s.indexOf(v) === i);

    const groupedTransfersUserCanAccess: TransfersForStore[] =
        userHasAccessToStore(
            user,
            finishingTrailerRoute[0][0].sourceStore as StoreNumber
        )
            ? uniqueTransferStores.map((store) => ({
                  store: store,
                  transfers: transfers.filter(
                      (transfer) =>
                          (transfer.sourceStore as StoreNumber) === store
                  ),
              }))
            : uniqueTransferStores
                  .filter((store) => userHasAccessToStore(user, store))
                  .map((store) => ({
                      store: store,
                      transfers: transfers.filter(
                          (transfer) =>
                              (transfer.sourceStore as StoreNumber) === store
                      ),
                  }));

    const groupedTransfersUserCannotAccess: TransfersForStore[] =
        userHasAccessToStore(
            user,
            finishingTrailerRoute[0][0].sourceStore as StoreNumber
        )
            ? []
            : uniqueTransferStores
                  .filter((store) => !userHasAccessToStore(user, store))
                  .map((store) => ({
                      store: store,
                      transfers: transfers.filter(
                          (transfer) =>
                              (transfer.sourceStore as StoreNumber) === store
                      ),
                  }));

    const transferCombinations: TransfersForStore[][] =
        groupedTransfersUserCanAccess.length > 1
            ? groupedTransfersUserCanAccess.reduce(
                  (
                      combinations: TransfersForStore[][],
                      _: TransfersForStore,
                      index: number
                  ) =>
                      combinations.concat(
                          getCombinations(
                              groupedTransfersUserCanAccess,
                              index + 1
                          )
                      ),
                  [[]]
              )
            : [groupedTransfersUserCanAccess, []];

    return recur(
        finishingTrailerRoute,
        transferCombinations,
        groupedTransfersUserCannotAccess,
        originalIodPlan
    );
};

const getFinishingTrailerTripForLoads = (
    finishingTrailerRoute: FTRoute,
    originalIodPlan: IodPlan,
    user: UserProfile
): FinishingTrailerTrip[][] => {
    const iodPlanLoadsNotInFTRoute: Load[] = getAllLoadsFromIodPlan(
        originalIodPlan
    ).filter(
        (load) =>
            !finishingTrailerRoute.some((loadGroup) =>
                loadGroup.some((l) => l.id === load.id)
            )
    );

    if (
        finishingTrailerRoute.length <= 1 ||
        iodPlanLoadsNotInFTRoute.length === 0
    ) {
        return [
            finishingTrailerRoute.map((loadGroup) => ({
                load: loadGroup[0],
                predecessorTransfers: iodPlanLoadsNotInFTRoute,
            })),
        ];
    }

    return getFinishingTrailerTripsForMultiFinishingTrailerAndMultiTransferPlans(
        finishingTrailerRoute,
        iodPlanLoadsNotInFTRoute,
        originalIodPlan,
        user
    );
};

const getFinishingTrailerPermutations = (
    startingStoreLoads: LoadGroup,
    allIodPlanLoads: LoadGroup[]
): FTRoute[] => {
    const allIodPlanLoadsExcludingStartingStore: LoadGroup[] =
        allIodPlanLoads.filter(
            (loads) =>
                !loads.some((l) =>
                    startingStoreLoads.some((sl) => sl.id === l.id)
                )
        );

    return allIodPlanLoadsExcludingStartingStore
        .map((_, index) => {
            const permutations: FTRoute[] = getPermutations(
                allIodPlanLoadsExcludingStartingStore,
                index + 1
            );
            return permutations.map((perm) => [startingStoreLoads, ...perm]);
        })
        .reduce((acc, val) => acc.concat(val), [[startingStoreLoads]]);
};

const getFinishingTrailerPermutationsForUser = (
    iodPlan: IodPlan,
    user: UserProfile
): FTRoute[] => {
    const allIodPlanLoads: LoadGroup[] = getAllGroupedLoadsFromIodPlan(iodPlan);
    const finishingTrailerLoads: Load[] =
        getAllFinishingTrailerLoadsFromIodPlan(iodPlan);
    const maxFinishingTrailerStores: number =
        iodPlan.iod.trailerType === 'Single' ? 3 : 2;

    const isUserFromStartingStore: boolean = userHasAccessToStore(
        user,
        iodPlan.trip1.load.sourceStore as StoreNumber
    );
    const groupedLoadsFromUserStore: LoadGroup[] = allIodPlanLoads.filter(
        (loadGroup) =>
            userHasAccessToStore(user, loadGroup[0].sourceStore as StoreNumber)
    );

    return groupedLoadsFromUserStore.reduce(
        (permutations: FTRoute[], loadsFromUserStore: LoadGroup): FTRoute[] => {
            const loadIsFinishingTrailer: boolean = finishingTrailerLoads.some(
                (load) => loadsFromUserStore.some((l) => l.id === load.id)
            );
            const finishingLoad = loadIsFinishingTrailer
                ? loadsFromUserStore[0]
                : null;
            const finishingTrailer = finishingLoad
                ? getFinishingTrailerFromIodPlanForLoad(iodPlan, finishingLoad)
                : null;

            let finishingTrailerRoutes: FTRoute[] =
                getFinishingTrailerPermutations(
                    loadsFromUserStore,
                    allIodPlanLoads
                );

            // If user is from finishing trailer load that isn't the starting trailer that doesn't have a predecessor transfer
            // we need to provide a finishing trailer route without the user's load
            if (
                !isUserFromStartingStore &&
                finishingLoad &&
                finishingTrailer?.predecessorTransfers.length === 0
            ) {
                finishingTrailerRoutes = finishingTrailerRoutes.concat([
                    [
                        finishingTrailerLoads.filter(
                            (load) => load.id !== finishingLoad.id
                        ) as LoadGroup,
                    ],
                ]);
            }

            // If user is from transfer load we need to provide finishing trailer routes
            // that include transfer as finishing trailer whilst maintaining route order.
            // Where there are multiple transfers from the same store, these cannot be
            // considered for being changed to a finishing trailer.
            if (
                !isUserFromStartingStore &&
                !loadIsFinishingTrailer &&
                loadsFromUserStore.length === 1
            ) {
                const startingLoad: Load = finishingTrailerLoads[0];
                const finishingTrailerLoadsExcludingStartingLoad: Load[] =
                    finishingTrailerLoads.filter(
                        (load) => load.id !== startingLoad.id
                    );

                const finishingTrailerRoutePermutations: Load[][] =
                    getPermutations(
                        [
                            ...finishingTrailerLoadsExcludingStartingLoad,
                            loadsFromUserStore[0],
                        ],
                        finishingTrailerLoadsExcludingStartingLoad.length + 1
                    );

                finishingTrailerRoutes = finishingTrailerRoutes
                    .concat([
                        finishingTrailerRoutePermutations.map(
                            (perm) => [startingLoad, ...perm] as LoadGroup
                        ),
                    ])
                    .concat([[finishingTrailerLoads as LoadGroup]]);
            }

            finishingTrailerRoutes = finishingTrailerRoutes.filter(
                (perm) => perm.length <= maxFinishingTrailerStores
            );

            // Stock transfers from the same store can't be considered to be a finishing trailer
            finishingTrailerRoutes = finishingTrailerRoutes.filter(
                (perm) => !perm.some((loadGroup) => loadGroup.length > 1)
            );

            return permutations.concat(finishingTrailerRoutes);
        },
        []
    );
};

export const getIodPlanVariationsForUser = (
    iodPlan: IodPlan,
    user: UserProfile
): IodPlan[] => {
    const finishingTrailerPermutations: LoadGroup[][] =
        getFinishingTrailerPermutationsForUser(iodPlan, user);

    const finishingTrailerTrips = finishingTrailerPermutations.reduce<
        FinishingTrailerTrip[][]
    >(
        (trips, finishingTrailerRoute) =>
            trips.concat(
                getFinishingTrailerTripForLoads(
                    finishingTrailerRoute,
                    iodPlan,
                    user
                )
            ),
        []
    );

    const iodPlans: IodPlan[] = finishingTrailerTrips
        .map((iodPlanTrips) => getIodPlan(iodPlan.iod, iodPlanTrips))
        .concat(iodPlan);

    const relationshipsToMaintain: LoadRelationship[] =
        getLoadRelationships(iodPlan);

    return removeDuplicates(iodPlans, IodPlanComparator).filter((iodPlan) =>
        doesIodPlanMaintainRelationships(iodPlan, relationshipsToMaintain)
    );
};
