import * as React from 'react';
import { useState } from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import {
    Switch,
    TextArea,
    TextInput,
} from '../../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { SMSTemplate } from '../../../../models/smsTemplates/SMSTemplate';
import {
    AvailableSubstitutions,
    Substitution,
} from '../../../../models/smsTemplates/Substitutions';
import Icon from '../../../common/Icon';
import { useClasses } from './SMSTemplateModalStyles';

interface AddSMSTemplateModalProps {
    showModal: boolean;
    onAddRequested(value: SMSTemplate): void;
    onClose(): void;
}

const newSMSTemplate: SMSTemplate = {
    name: '',
    message: '',
    isDefault: false,
    isPrimary: false,
};

const AddSMSTemplateModal: React.FC<AddSMSTemplateModalProps> = ({
    showModal,
    onAddRequested,
    onClose,
}) => {
    const classes = useClasses();

    const [smsTemplateName, setSMSTemplateName] = useState(newSMSTemplate.name);
    const [smsTemplateMessage, setSMSTemplateMessage] = useState(
        newSMSTemplate.message
    );
    const [smsTemplateIsDefault, setSMSTemplateIsDefault] = useState(
        newSMSTemplate.isDefault
    );

    const isInvalid = !smsTemplateName || !smsTemplateMessage;

    const handleSMSTemplateNameChange = (value: string | null) => {
        setSMSTemplateName(value ?? '');
    };

    const handleSMSTemplateMessageChange = (value: string | null) => {
        setSMSTemplateMessage(value ?? '');
    };

    const handleAddRequested = () => {
        const smsTemplate = {
            name: smsTemplateName,
            message: smsTemplateMessage,
            isDefault: smsTemplateIsDefault,
            isPrimary: false,
        };
        onAddRequested(smsTemplate);
    };

    const handleSubsitutionSelected = (
        substitution: Substitution,
        focusElementIdAfter: string
    ) => {
        setSMSTemplateMessage(smsTemplateMessage + ` ${substitution.value}`);
        document.getElementById(focusElementIdAfter)?.focus();
    };

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Add SMS Template</h5>
            </ModalHeader>
            <ModalBody>
                <div>
                    <TextInput
                        placeholder="Template Name..."
                        value={smsTemplateName}
                        onChange={handleSMSTemplateNameChange}
                        label="Name:"
                        hideBottomPadding
                    />
                </div>
                <div>
                    <label>
                        Message:{' '}
                        <Icon
                            type="info"
                            title="Please use the links below to add tokens to your message which will be replaced automatically when the message is sent"
                        />
                    </label>
                    <div>
                        {AvailableSubstitutions.map((s) => (
                            <Button
                                styleType={'link'}
                                key={s.label}
                                element={'a'}
                                className={classes.substitutionLink}
                                onClick={() =>
                                    handleSubsitutionSelected(
                                        s,
                                        'SMSTemplateMessage'
                                    )
                                }
                            >
                                {s.label}
                            </Button>
                        ))}
                    </div>
                    <TextArea
                        id="SMSTemplateMessage"
                        value={smsTemplateMessage}
                        onChange={handleSMSTemplateMessageChange}
                        autoConvertWhitespace
                        autoTrim
                        hideBottomPadding
                    />
                </div>
                <div>
                    <Switch
                        id="SMSTemplateIsDefault"
                        label="Default"
                        value={smsTemplateIsDefault}
                        onChange={setSMSTemplateIsDefault}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={isInvalid}
                    onClick={() => handleAddRequested()}
                >
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddSMSTemplateModal;
