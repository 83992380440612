import * as React from 'react';

import { useEffect, useState } from 'react';
import Icon from '../../../components/common/Icon';
import Button from '../../../lib/bootstrap-ui/Button';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import { Row } from '../../../lib/bootstrap-ui/Grid';
import { IsValidEmail } from '../../../services/emailService';
import { useClasses } from './BookingEmailEntryContainer.styles';

interface BookingEmailEntryContainerProps {
    emailAddresses: string | null;
    onEmailAddressesChange(emailAddresses: string): void;
    onEmailInvalidated(invalid: boolean): void;
}

const BookingEmailEntryContainer: React.FC<BookingEmailEntryContainerProps> = ({
    emailAddresses,
    onEmailAddressesChange,
    onEmailInvalidated,
}) => {
    const classes = useClasses();

    const maxEmailAddresses = 5;
    const [separatedEmailAddresses, setSeparatedEmailAddresses] = useState<
        string[]
    >(emailAddresses ? emailAddresses.split(';') : ['']);
    const containsInvalidEmail = separatedEmailAddresses.some(
        (e) => !IsValidEmail(e)
    );

    useEffect(
        () => {
            onEmailInvalidated(containsInvalidEmail);
        },
        // eslint-disable-next-line
        [containsInvalidEmail]
    );

    const handleBookingEmailsUpdated = (newEmailAddresses: string[]) => {
        setSeparatedEmailAddresses(newEmailAddresses);
        onEmailAddressesChange(newEmailAddresses.join(';'));
    };

    const handleRemoveEmail = (index: number) => {
        const newEmailAddresses = [...separatedEmailAddresses];
        newEmailAddresses.splice(index, 1);
        handleBookingEmailsUpdated(newEmailAddresses);
    };

    const handleAddEmail = () => {
        const newEmailAddresses = [...separatedEmailAddresses];
        newEmailAddresses.push('');
        handleBookingEmailsUpdated(newEmailAddresses);
    };

    const handleBookingEmailChange = (
        emailAddress: string | null,
        index: number
    ) => {
        const newEmailAddresses = [...separatedEmailAddresses];
        newEmailAddresses[index] = emailAddress || '';
        handleBookingEmailsUpdated(newEmailAddresses);
    };

    return (
        <>
            {separatedEmailAddresses.map((e, i) => (
                <Row key={i} className={classes.inputRow}>
                    <TextInput
                        className={classes.emailInput}
                        value={e || ''}
                        error={
                            !IsValidEmail(e)
                                ? 'Please input a valid email'
                                : null
                        }
                        onChange={(value) => handleBookingEmailChange(value, i)}
                        placeholder={`example${i + 1}@haulier.com`}
                    />
                    <Button
                        className={classes.removeButton}
                        disabled={separatedEmailAddresses.length === 1}
                        styleType="link"
                        onClick={() => handleRemoveEmail(i)}
                    >
                        <Icon type="minus" size="lg" />
                    </Button>
                    <Button
                        className={classes.addButton}
                        style={{
                            visibility:
                                i === separatedEmailAddresses.length - 1
                                    ? 'visible'
                                    : 'hidden',
                        }}
                        disabled={
                            separatedEmailAddresses.length === maxEmailAddresses
                        }
                        styleType="link"
                        onClick={handleAddEmail}
                    >
                        <Icon type="plus" size="lg" />
                    </Button>
                </Row>
            ))}
        </>
    );
};

export default BookingEmailEntryContainer;
