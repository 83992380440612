import { Booking } from '../models/bookings/Booking';
import BookingFilter from '../models/bookings/BookingFilter';

export type BookingTableReducerAction = 'Add' | 'Update' | 'Remove' | 'Replace';

export interface BookingTableAction {
    action: BookingTableReducerAction;
    bookings: Booking[];
    currentPage: number;
    filter: BookingFilter;
}

const belongsOnPreviousPage = (
    bookings: Booking[],
    booking: Booking,
    currentPage: number
) => {
    return (
        currentPage > 1 &&
        new Date(booking.startDate) < new Date(bookings![0].startDate)
    );
};

const bookingIsWithinDateRange = (
    booking: Booking,
    bookingFilter: BookingFilter
) =>
    (!bookingFilter.startDate ||
        new Date(booking.startDate) >= bookingFilter.startDate) &&
    (!bookingFilter.endDate ||
        new Date(booking.startDate) < bookingFilter.endDate);

const bookingTableReducer = (
    state: Booking[],
    bookingTableAction: BookingTableAction
): Booking[] => {
    const bookingIds = bookingTableAction.bookings.map(b => b.id);
    switch (bookingTableAction.action) {
        case 'Add':
            const bookingsToAdd = bookingTableAction.bookings.filter(
                b =>
                    bookingIsWithinDateRange(b, bookingTableAction.filter) &&
                    !belongsOnPreviousPage(
                        state,
                        b,
                        bookingTableAction.currentPage
                    )
            );
            return [...state, ...bookingsToAdd];
        case 'Update':
            const unaffectedBookings = state.filter(
                b => !bookingIds.includes(b.id)
            );
            const updatedBookings = [
                ...unaffectedBookings,
                ...bookingTableAction.bookings
            ];
            return updatedBookings.filter(
                b =>
                    bookingIsWithinDateRange(b, bookingTableAction.filter) &&
                    !belongsOnPreviousPage(
                        state,
                        b,
                        bookingTableAction.currentPage
                    )
            );
        case 'Remove':
            return state.filter(b => !bookingIds.includes(b.id));
        case 'Replace':
            return bookingTableAction.bookings;
    }
};

export default bookingTableReducer;
