import * as React from 'react';
import { useContext, useState } from 'react';

import BayManagementTable from '../../../components/admin/bayManagement/BayManagementTable';
import SingleColumnRow from '../../../components/common/SingleColumnRow';
import UnsavedChangesModal from '../../../components/common/UnsavedChangesModal';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import Button from '../../../lib/bootstrap-ui/Button';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import Bay from '../../../models/bays/Bay';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { routeFor, RouteKey } from '../../../Routes/routes';
import { apiPut, useData } from '../../../services/api';
import { areBaysEqual } from '../../../services/bayService';
import { getStoreDropdownOptions } from '../../../services/storeService';
import RequireSaveContainer from '../RequireSaveContainer';
import { useClasses } from './BayManagementContainer.styles';

const BayManagementContainer: React.FC = () => {
    const classes = useClasses();

    const { bayCapabilities, stores } = useContext(LookupContext);
    const [selectedStore, setSelectedStore] = useState<number | null>(null);
    const [storeToChangeTo, setStoreToChangeTo] = useState<StoreNumber | null>(
        null
    );
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
    const [existingBays, loading, refreshExistingBays] = useData<Bay[]>(
        selectedStore ? `Store/${selectedStore}/Bays` : `Bay`
    );

    const hasBayUpdated = (bay: Bay): boolean => {
        const matchingBay = existingBays?.find((b) => b.id === bay.id);
        return !!matchingBay && !areBaysEqual(matchingBay, bay);
    };

    const handleStoreChange = (newSelectedStore: number) => {
        setSelectedStore(newSelectedStore);
        setStoreToChangeTo(null);
    };

    const requestStoreChange = (
        outstandingChanges: boolean,
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const newSelectedStore = parseInt(
            event.target.value,
            10
        ) as StoreNumber;
        if (outstandingChanges) {
            setStoreToChangeTo(newSelectedStore);
            setShowSaveChangesModal(true);
        } else {
            handleStoreChange(newSelectedStore);
        }
    };

    const handleSaveClicked = async (updatedBays: Bay[]) => {
        await apiPut('Bay', updatedBays);
        refreshExistingBays();
        setStoreToChangeTo(null);
    };

    const handleDiscardChanges = () => {
        if (storeToChangeTo) {
            handleStoreChange(storeToChangeTo);
        }
        setShowSaveChangesModal(false);
    };

    const handleGoBackAndClose = () => {
        setShowSaveChangesModal(false);
        setStoreToChangeTo(null);
    };

    return (
        <RequireSaveContainer<Bay>
            existingValues={existingBays || []}
            comparator={(lhs, rhs) => lhs.id === rhs.id}
            hasValueUpdated={hasBayUpdated}
            Save={handleSaveClicked}
        >
            {({ updatedValues, outstandingChanges, onChange }) => (
                <>
                    <Grid fluid>
                        <Row className={classes.actionRow}>
                            <Column size={6}>
                                <SelectDropdown
                                    id="StoreSelect"
                                    label="Store:"
                                    className={classes.storeSelector}
                                    dropdownOptions={getStoreDropdownOptions(
                                        stores.map(String),
                                        {
                                            addAllStores: true,
                                        }
                                    )}
                                    selectedOption={
                                        selectedStore
                                            ? String(selectedStore)
                                            : ''
                                    }
                                    onChange={(event) =>
                                        requestStoreChange(
                                            outstandingChanges,
                                            event
                                        )
                                    }
                                    disabled={loading}
                                    hideBottomPadding
                                />
                            </Column>
                            <Column
                                size={6}
                                align="right"
                                className={classes.contentAlignEndColumn}
                            >
                                <Button
                                    element="a"
                                    href={routeFor(RouteKey.CreateBay)()}
                                >
                                    Add Bay
                                </Button>
                            </Column>
                        </Row>
                        <SingleColumnRow>
                            <BayManagementTable
                                bays={updatedValues}
                                bayCapabilities={bayCapabilities}
                                loading={!updatedValues}
                                onBayChange={onChange}
                            />
                        </SingleColumnRow>
                    </Grid>
                    {showSaveChangesModal && (
                        <UnsavedChangesModal
                            isOpen={showSaveChangesModal}
                            onRequestDiscardChanges={handleDiscardChanges}
                            onRequestClose={handleGoBackAndClose}
                            onRequestGoBack={handleGoBackAndClose}
                        />
                    )}
                </>
            )}
        </RequireSaveContainer>
    );
};

export default BayManagementContainer;
