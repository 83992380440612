import * as React from 'react';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';

const RecurringBookingDurationContainer: React.FC<FormStepProps<
    RecurringBookingToAdd
>> = ({ formObject: booking, onChange: onBookingChange }) => {
    const handleBookingDurationChange = (value: string | null) => {
        const duration = !!value ? parseInt(value) : 0;
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            duration
        };
        onBookingChange(editedBooking);
    };

    return (
        <TextInput
            label="Booking Duration (hrs):"
            size="small"
            type="number"
            minValue={0}
            value={booking.duration}
            onChange={handleBookingDurationChange}
            highlightOnFocus
        />
    );
};

export default RecurringBookingDurationContainer;
