import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    fixedHeader: {
        '& thead th': {
            backgroundColor: theme.colors.white,
            position: 'sticky',
            top: 0,
        },
    },
    mediumTable: {
        '& td, & th': {
            padding: '0.5rem',
        },
    },
    striped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: theme.colors.nearlyWhite,
        },
    },
    noRowBorders: {
        '& tbody tr': {
            borderTop: 'none',
            borderBottom: 'none',
        },
    },
    rowBorders: {
        '& tbody tr': {
            borderBottom: `2px solid ${theme.colors.lightGrey}`,
        },
    },
    table: {
        '& td': {
            border: 0,
        },
        '& thead th': {
            color: theme.colors.lightBlack,
            fontWeight: 'normal',
            border: 0,
            zIndex: 1,
        },
        '& thead tr': {
            borderBottom: `2px solid ${theme.colors.lightestGrey}`,
        },
        '& th.noPadding': {
            padding: 0,
        },
    },
}));
