import { Notification } from '.';
import { Change } from '../updates/Change';
import { Load } from '../loads/Load';
import { ReleaseApplication } from '../loads/ReleaseApplication';
import { Pallet } from '../pallets/Pallet';
import { StoreNumber } from '../stores/StoreNumber';

interface ChangesWithAffectedStores {
    stores: number[];
    changes: Change[];
}

export const AlertNotification: Notification<ChangesWithAffectedStores> = {
    message: 'Alert'
};

export const LoadAddedNotification: Notification<Load> = {
    message: 'LoadAdded'
};

export const LoadsAddedNotification: Notification<Load[]> = {
    message: 'LoadsAdded'
};

export interface LoadReleased {
    releasedLoad: Load;
    palletsForReleasedLoad: Pallet[];
}

export const LoadReleasedNotification: Notification<LoadReleased> = {
    message: 'LoadReleased'
};

interface LoadReleaseError {
    loadId: string;
    errorOrigin: ReleaseApplication;
}

export const LoadReleaseErrorNotification: Notification<LoadReleaseError> = {
    message: 'LoadReleaseError'
};

export interface LoadRemoved {
    loadId: string;
    loadName: string;
    sourceStore: StoreNumber;
}

export const LoadRemovedNotification: Notification<LoadRemoved> = {
    message: 'LoadRemoved'
};

export interface ChangesWithAffectedLoad {
    affectedLoad: Load;
    changes: Change[];
}

export const LoadUpdatedNotification: Notification<ChangesWithAffectedLoad> = {
    message: 'LoadUpdated'
};
