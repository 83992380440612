import * as React from 'react';

import { useClasses } from './LoadSummaryRow.styles';

interface LoadSummaryRowProps {
    header: string;
    children: React.ReactNode;
}

const LoadSummaryRow: React.FC<LoadSummaryRowProps> = ({
    header,
    children,
}) => {
    const classes = useClasses();

    return (
        <div className={classes.LoadSummaryRow}>
            <div>
                <h5>{header}</h5>
            </div>
            <div>{children}</div>
        </div>
    );
};

export default LoadSummaryRow;
