import * as React from 'react';
import classnames from 'classnames';
import { buildClassesHook } from '../../../helpers/styles';
import { CSSProperties } from 'react';

interface CSSGridItemProps {
    row: number | string;
    column: number | string;
    smallRow?: number | string;
    smallColumn?: number | string;
    rowSpan?: number;
    columnSpan?: number;
    smallRowSpan?: number;
    smallColumnSpan?: number;
    style?: CSSProperties | undefined;
    className?: string | undefined;
    children:
        | React.ReactNode
        | (({ className }: { className: string }) => JSX.Element);
}

const useClasses = buildClassesHook({
    gridItem: {
        gridColumn: ({ column, columnSpan }: CSSGridItemProps) =>
            `${column}${columnSpan ? `/ span ${columnSpan}` : ''}`,
        gridRow: ({ row, rowSpan }: CSSGridItemProps) =>
            `${row}${rowSpan ? `/ span ${rowSpan}` : ''}`
    }
});

const CSSGridItem: React.FC<CSSGridItemProps> = props => {
    const {
        row,
        column,
        rowSpan,
        columnSpan,
        style,
        children,
        className,
        ...otherProps
    } = props;

    const classes = useClasses(props);

    const gridStyle = {
        gridColumn: `${column}${columnSpan ? `/ span ${columnSpan}` : ''}`,
        gridRow: `${row}${rowSpan ? `/ span ${rowSpan}` : ''}`,
        ...style
    };
    if (typeof children === 'function') {
        return children({
            className: classnames(classes.gridItem, className)
        });
    }
    return (
        <div style={gridStyle} {...otherProps}>
            {children}
        </div>
    );
};

export default CSSGridItem;
