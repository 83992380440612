import React from 'react';

const ErrorDisplay: React.FC<{ error: any }> = ({ error }) => (
    <div
        style={{
            width: '600px',
            maxWidth: '80%',
            margin: '0 auto',
            textAlign: 'center'
        }}
    >
        <h1 className="text-danger">An Error has Occured</h1>
        <p className="text-danger">
            Please report this issue to the system administrator with all the
            details provided below.
        </p>
        <textarea
            style={{
                width: '100%',
                maxWidth: 'none',
                height: '400px',
                border: 'none',
                textAlign: 'left',
                color: '#888',
                resize: 'none'
            }}
            value={
                typeof error === 'string'
                    ? error
                    : JSON.stringify(error, null, 2)
            }
            readOnly
        />
    </div>
);

export default ErrorDisplay;
