import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../../helpers/styles';
import { Booking } from '../../../../models/bookings/Booking';
import { theme, Theme } from '../../../../theme';
import Icon from '../../../common/Icon';
import LoadingSpinnerIcon from '../../../loading/LoadingSpinnerIcon';
import BookingTableRowActionButton from './BookingTableRowActionButton';

export type BookingAction =
    | 'Loading'
    | 'Complete'
    | 'Check Out'
    | 'Check In'
    | 'Requires Orders'
    | 'Release Load';

const useClasses = buildClassesHook((theme: Theme) => ({
    loadingIndicator: {
        margin: '0.1rem 0.5rem',
    },
    informationIcon: {
        margin: '0 0.5rem',
    },
    actionInformation: {
        color: theme.colors.errorDark,
        display: 'inline',
    },
}));

interface BookingTableRowActionProps {
    booking: Booking;
    bookingAction: BookingAction;
    disable?: boolean;
    onActionInitiated(type: ActionType, id: string): void;
}

export type ActionType = 'Complete' | 'Check In';

const BookingTableRowAction: React.FC<BookingTableRowActionProps> = ({
    booking,
    bookingAction,
    disable = false,
    onActionInitiated,
}) => {
    const classes = useClasses();

    return (
        <>
            {bookingAction === 'Loading' ? (
                <LoadingSpinnerIcon
                    className={classes.loadingIndicator}
                    color={theme.colors.success}
                />
            ) : bookingAction === 'Complete' ||
              bookingAction === 'Check In' ||
              bookingAction === 'Check Out' ? (
                <BookingTableRowActionButton
                    buttonTextClassName={classNames(
                        'd-none',
                        'd-xl-table-cell'
                    )}
                    buttonType={bookingAction}
                    booking={booking}
                    userCannotPerformAction={disable}
                    onActionInitiated={onActionInitiated}
                />
            ) : (
                <span className={classes.actionInformation}>
                    <Icon
                        className={classes.informationIcon}
                        type="info"
                        title={bookingAction}
                    />
                    <span className={classNames('d-none', 'd-xl-table-cell')}>
                        {bookingAction}
                    </span>
                </span>
            )}
        </>
    );
};

export default BookingTableRowAction;
