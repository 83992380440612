import * as React from 'react';
import { useState } from 'react';
import Button from '../../../lib/bootstrap-ui/Button';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';
import NameLookup from '../../common/NameLookup';
import PhoneNumberInput from '../../common/PhoneNumberInput';

interface CheckInModalProps {
    booking: Booking;
    showModal: boolean;
    onCheckIn(
        haulierName: string,
        registrationPlate: string,
        contactNumber: string | null
    ): void;
    onClose(): void;
}

const CheckInModal: React.FC<CheckInModalProps> = ({
    booking,
    showModal,
    onCheckIn,
    onClose
}) => {
    const [bookingHaulierName, setBookingHaulierName] = useState(
        booking.haulierName
    );
    const [bookingRegistrationPlate, setBookingRegistrationPlate] = useState(
        booking.registrationPlate
    );
    const [bookingContactNumber, setBookingContactNumber] = useState(
        booking.contactNumber
    );

    const handleCheckIn = () => {
        onCheckIn(
            bookingHaulierName!,
            bookingRegistrationPlate!,
            bookingContactNumber
        );
    };

    const isHaulierValid = !!bookingHaulierName;
    const isRegValid = !!bookingRegistrationPlate;

    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Check In Booking {booking.reference}</h5>
            </ModalHeader>

            <ModalBody>
                <div>
                    <NameLookup
                        lookupType="Haulier"
                        defaultSearchValue={bookingHaulierName}
                        isValid={isHaulierValid}
                        onNameSelected={setBookingHaulierName}
                        required
                        autoConvertWhitespace
                    />
                </div>
                <div>
                    <TextInput
                        placeholder="Enter Registration Plate..."
                        value={bookingRegistrationPlate}
                        onChange={setBookingRegistrationPlate}
                        label="Registration Plate:"
                        error={
                            !isRegValid
                                ? 'Please input a registration plate'
                                : null
                        }
                        helpText="*Required"
                    />
                </div>
                <div>
                    <PhoneNumberInput
                        label="Contact Number:"
                        onPhoneNumberChanged={setBookingContactNumber}
                        phoneNumber={bookingContactNumber}
                        placeholder="Contact Number..."
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={!isHaulierValid || !isRegValid}
                    onClick={handleCheckIn}
                >
                    Check In
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CheckInModal;
