import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    title: {
        margin: '1rem 4rem 1rem 0',
        whiteSpace: 'nowrap',
    },
    '@media (max-width: 600px)': {
        title: {
            margin: '1rem 0',
        },
    },
    headerRow: {
        display: 'flex',
        backgroundColor: theme.colors.white,
        zIndex: 2,
        position: 'sticky',
        top: 0,
        justifyContent: 'space-between',
    },
}));
