import * as React from 'react';
import { useState, useRef, useCallback, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { API_URI } from '../environment-variables';
import { acquireApiToken, useAuth } from '../services/authentication';

interface SignalRContextProviderProps {
    maxRetries?: number;
    initialRetryDelayMs?: number;
}

interface SignalRContextProps {
    subscribe(message: string, handler: (...args: any) => void): () => void;
    retryConnect(): void;
    retries: number;
    retriesFailed: boolean;
    disconnected: boolean;
}

const SignalRContext = React.createContext<SignalRContextProps>({
    subscribe: () => async () => {
        return;
    },
    retryConnect: () => {
        return;
    },
    retries: 0,
    retriesFailed: false,
    disconnected: false,
});

function getRetryDelay(baseDelayMs: number, retryNumber: number) {
    return baseDelayMs * Math.pow(5, retryNumber);
}

const SignalRContextProvider: React.FC<
    React.PropsWithChildren<SignalRContextProviderProps>
> = ({ maxRetries = 3, initialRetryDelayMs = 5000, children }) => {
    const [disconnected, setDisconnected] = useState(false);
    const [retries, setRetries] = useState(0);
    const {
        authState: { loggedIn },
    } = useAuth();
    const connection = useRef<signalR.HubConnection>(
        new signalR.HubConnectionBuilder()
            .withUrl(`${API_URI}hubs/messages`, {
                accessTokenFactory: async () => {
                    const token = await acquireApiToken();
                    return token || '';
                },
            })
            .withAutomaticReconnect()
            .build()
    );

    const initialLoginDetected = useRef(false);

    connection.current.onclose(() => {
        setDisconnected(true);
    });

    const startConnection = useCallback(async () => {
        await connection.current.start();
        setDisconnected(false);
    }, []);

    const attemptRetry = useCallback(async () => {
        setRetries((prev) => prev + 1);
        await startConnection();
    }, [startConnection]);

    useEffect(() => {
        if (loggedIn && !initialLoginDetected.current) {
            initialLoginDetected.current = true;
            startConnection();
        }
    }, [startConnection, loggedIn]);

    useEffect(() => {
        if (!disconnected && retries > 0) {
            setRetries(0);
        }
    }, [disconnected, retries]);

    useEffect(() => {
        if (disconnected && retries < maxRetries) {
            const id = setTimeout(
                attemptRetry,
                getRetryDelay(initialRetryDelayMs, retries)
            );

            return () => {
                clearTimeout(id);
            };
        }
    }, [disconnected, retries, maxRetries, attemptRetry, initialRetryDelayMs]);

    const retriesFailed = retries >= maxRetries;

    return (
        <SignalRContext.Provider
            value={{
                subscribe: (
                    message: string,
                    handler: (...args: any) => void
                ) => {
                    connection.current.on(message, handler);
                    return () => connection.current.off(message, handler);
                },
                retryConnect: () => {
                    setRetries(0);
                    attemptRetry();
                },
                retries,
                retriesFailed,
                disconnected,
            }}
        >
            {children}
        </SignalRContext.Provider>
    );
};

export { SignalRContextProvider, SignalRContext };
