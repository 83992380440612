import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    bookingContainer: {
        display: 'flex',
        height: '100%',
        flexFlow: 'column nowrap',
    },
    bookingTableContainer: {
        height: '100%',
    },
    navSection: {
        borderRight: '1px solid #eee',
        paddingRight: '0.5rem',
        marginRight: '0.5rem',
    },
    chartsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '1rem',
    },
    bookingTable: {
        '& thead th': {
            top: '65px',
            whiteSpace: 'nowrap',
        },
    },
    actionContainer: {
        backgroundColor: 'white',
        position: 'sticky',
        top: '0',
        zIndex: 99,
    },
    pageSelectionContainer: {
        width: 'fit-content',
        margin: '0 auto',
        position: 'sticky',
        bottom: '1rem',
        zIndex: 1,
        '& ul.pagination': {
            margin: 0,
        },
    },
});
