import * as React from 'react';
import AutoCompleteDropdown from '../../lib/bootstrap-ui/Dropdown/AutoCompleteDropdown';
import {
    filterValues,
    whitespaceToNull,
    safeTrim,
} from '../../helpers/stringFunctions';
import { useData } from '../../services/api';

interface NameLookupByReferenceProps {
    onNameSelected(selectedCustomer: string | null): void;
    isValid: boolean;
    lookupType: 'Customer' | 'Haulier' | 'SupplierCode';
    required?: boolean;
    placeholder?: string;
    title?: string;
    autoConvertWhitespace?: boolean;
    disableAutoTrim?: boolean;
    hideBottomPadding?: boolean;
    lookupSearchValue: string | null;
    handleUpdateSearchValue(selectedCustomer: string | null): void;
}

const NameLookupByReference: React.FC<NameLookupByReferenceProps> = ({
    onNameSelected,
    isValid,
    lookupType,
    required,
    hideBottomPadding,
    placeholder = `Enter ${lookupType} Name...`,
    title = `${lookupType} Name:`,
    autoConvertWhitespace = false,
    disableAutoTrim = false,
    lookupSearchValue,
    handleUpdateSearchValue,
}) => {
    const [lookupData] = useData<string[]>(`${lookupType}/Values`);

    const searchLookupNames = filterValues(lookupData?.sort() || []);

    const handleValueChange = (value: string) => {
        handleUpdateSearchValue(value);
        let newValue: string | null = value;

        if (!disableAutoTrim) {
            newValue = safeTrim(newValue);
        }

        if (autoConvertWhitespace) {
            newValue = whitespaceToNull(newValue);
        }

        onNameSelected(newValue);
    };

    const handleCustomerValueChange = (
        _: React.FormEvent<HTMLInputElement>,
        value: string
    ) => {
        handleUpdateSearchValue(value);
        if (searchLookupNames(lookupSearchValue || '')) {
            const customer = searchLookupNames(lookupSearchValue || '').find(
                (c) => c.toUpperCase() === value.toUpperCase()
            );
            if (customer) {
                handleValueChange(customer);
            } else {
                onNameSelected(null);
            }
        }
    };

    const getItemValue = (item: string) => item;

    return (
        <AutoCompleteDropdown
            id={`${lookupType}Input`}
            label={title}
            placeholder={placeholder}
            items={searchLookupNames(lookupSearchValue || '')}
            value={lookupSearchValue || ''}
            getItemValue={getItemValue}
            error={!isValid ? `Please input a ${lookupType} name` : null}
            onSearchValueChange={
                lookupType === 'Customer'
                    ? handleCustomerValueChange
                    : (_, newValue) => handleValueChange(newValue)
            }
            onSelect={handleValueChange}
            helpText={required ? '*Required' : undefined}
            hideBottomPadding={hideBottomPadding}
        />
    );
};

export default NameLookupByReference;
