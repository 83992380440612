import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import ColdStoreOrderSelection from '../../../components/bookings/ColdStoreOrderSelection';
import { apiGet } from '../../../services/api';
import { storeToCompanyCode } from '../../../services/storeService';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { InternalWorkOrder } from '../../../models/orders/InternalWorkOrder';

interface InternalOrderSelectionContainerProps {
    orderContainerClassName?: string;
    searchValue: string;
    selectedOrders: InternalWorkOrder[];
    store: StoreNumber;
    onOrdersChange(
        selectedOrders: InternalWorkOrder[],
        invalidOrderSelected?: boolean
    ): void;
    onSearchValueChange(searchValue: string): void;
}

const InternalWorkOrderSelectionContainer: React.FC<InternalOrderSelectionContainerProps> = ({
    orderContainerClassName,
    searchValue,
    selectedOrders,
    store,
    onOrdersChange,
    onSearchValueChange
}) => {
    const [filteredOrders, setFilteredOrders] = useState<InternalWorkOrder[]>(
        []
    );
    const [
        invalidOrderSelected,
        setInvalidOrderSelected
    ] = useState<InternalWorkOrder | null>(null);

    const ordersToDisplay = useMemo(
        () =>
            filteredOrders.filter(
                o =>
                    !selectedOrders.some(order => order.id === o.id) &&
                    (!invalidOrderSelected ||
                        (invalidOrderSelected &&
                            o.id !== invalidOrderSelected.id))
            ) ?? [],
        [filteredOrders, selectedOrders, invalidOrderSelected]
    );

    useEffect(() => {
        const fetchFilteredOrders = async () => {
            const filteredOrders: InternalWorkOrder[] = await apiGet(
                `InternalWorkOrders/Company/${storeToCompanyCode(store)}`,
                { SearchTerm: searchValue }
            );
            setFilteredOrders(filteredOrders);
        };

        if (searchValue.length > 1) {
            fetchFilteredOrders();
            return;
        }

        setFilteredOrders([]);
    }, [store, searchValue]);

    const handleInvalidOrderSelected = (
        selectedOrder: InternalWorkOrder | null
    ) => {
        setInvalidOrderSelected(selectedOrder);
    };

    return (
        <ColdStoreOrderSelection
            orderContainerClassName={orderContainerClassName}
            orders={ordersToDisplay}
            searchValue={searchValue}
            selectedOrders={selectedOrders}
            onInvalidOrderSelected={handleInvalidOrderSelected}
            onOrdersChange={onOrdersChange}
            onOrderSearchValueChange={onSearchValueChange}
        />
    );
};

export default InternalWorkOrderSelectionContainer;
