import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    filterModal: {
        width: '750px',
    },
    checkBoxListContainer: {
        marginTop: '1.5rem',
    },
});
