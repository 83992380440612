import * as React from 'react';
import { useState } from 'react';

import { getSelectedOptionSuffix } from '../../../../containers/admin/thirdPartyManagement/ThirdPartyManagementHelper';
import Button from '../../../../lib/bootstrap-ui/Button';
import DropdownOption from '../../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import { TextInput } from '../../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../../lib/bootstrap-ui/Modal';
import ThirdPartyValue from '../../../../models/thirdPartyValues/ThirdPartyValue';
import { useClasses } from './ModalStyle';

interface EditModalProps {
    showModal: boolean;
    thirdPartyValue: ThirdPartyValue;
    selectedOption: DropdownOption;
    onEditRequested(updatedValue: string): void;
    onClose(): void;
}

const EditModal: React.FC<EditModalProps> = ({
    showModal,
    thirdPartyValue,
    selectedOption,
    onEditRequested,
    onClose
}) => {
    const classes = useClasses();

    const [updatedValue, setUpdatedValue] = useState<string>(
        thirdPartyValue.value
    );

    const isInvalid =
        !updatedValue ||
        updatedValue.trim() === '' ||
        updatedValue === thirdPartyValue.value;

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Edit {selectedOption.label}</h5>
            </ModalHeader>
            <ModalBody>
                <TextInput
                    label={`${selectedOption.label}:`}
                    placeholder={`Enter ${getSelectedOptionSuffix(
                        selectedOption
                    )}...`}
                    value={thirdPartyValue.value}
                    onChange={value => setUpdatedValue(value ?? '')}
                    autoConvertWhitespace
                    autoTrim
                />
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={isInvalid}
                    onClick={() => onEditRequested(updatedValue)}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditModal;
