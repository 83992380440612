import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    dangerButton: {
        color: theme.colors.error,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    iconButton: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    icon: {
        marginRight: '1rem',
        display: 'inline-block',
        fontSize: '1.35rem',
    },
    piggybackIcon: {
        '--fa-primary-color': '#f783ac',
        '--fa-primary-opacity': 1,
        '--fa-secondary-color': '#fab5cd',
        '--fa-secondary-opacity': 0.8,
    },
    piggybackRemoveIcon: {
        color: theme.colors.error,
    },
    removeOrdersIcon: {
        color: theme.colors.errorDark,
    },
}));
