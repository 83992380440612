import { isBefore } from 'date-fns';
import * as React from 'react';
import Icon from '../../../components/common/Icon';
import { groupBy, removeDuplicates } from '../../../helpers/arrayFunctions';
import { formatDate } from '../../../helpers/dates';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import Table from '../../../lib/bootstrap-ui/Table';
import { Load } from '../../../models/loads/Load';
import { OrderPickList } from '../../../models/orders/Order';
import { KeyValuePair } from '../../../models/utils/KeyValuePair';
import { apiPost } from '../../../services/api';
import {
    getPickList,
    getPickListAmendment,
} from '../../../services/loadService';
import LoadingModal from '../../common/LoadingModal';
import { useClasses } from './PickListDownloadTable.styles';
import { DownloadExportType } from '../../common/DownloadExportType';

interface PickListDownloadTableProps {
    load: Load;
}

const PickListDownloadTable: React.FC<PickListDownloadTableProps> = ({
    load,
}) => {
    const classes = useClasses();

    const [downloading, setDownloading] =
        React.useState<DownloadExportType | null>(null);
    const isDownloading = !!downloading;
    const [confirmPickListIdDownload, setConfirmPickListIdDownload] =
        React.useState<string | null>(null);

    const [
        isFullPickListDownloadConfirmed,
        setIsFullPickListDownloadConfirmed,
    ] = React.useState<boolean | null>(null);

    const [downloadTypeToConfirm, setDownloadTypeToConfirm] = React.useState<
        string | null
    >(null);

    const showAmendmentColumn: boolean =
        removeDuplicates(
            load.orderLoadingStatuses
                .filter((ols) => ols.releaseToChess)
                .map((ols) => ols.order.pickLists)
                .reduce((a, b) => a.concat(b), [])
                .filter((pl) => pl.isAmendment)
                .map((pl) => pl.pickListNumber)
        ).length > 0;

    const handleDownloadPickListClicked = async (
        load: Load,
        pickListUpdateId: string,
        outstandingAmendment: boolean
    ) => {
        setDownloading(DownloadExportType.PickList);
        setDownloadTypeToConfirm('full ' + DownloadExportType.PickList);
        try {
            await getPickList(load, pickListUpdateId);
            setDownloading(null);

            if (outstandingAmendment) {
                setConfirmPickListIdDownload(pickListUpdateId);
                setIsFullPickListDownloadConfirmed(true);
            }
        } catch (error) {
            setDownloading(null);
            setDownloadTypeToConfirm(null);
        }
    };

    const handleDownloadPickListAmendmentClicked = async (
        load: Load,
        pickListUpdateId: string,
        outstandingAmendment: boolean
    ) => {
        setDownloading(DownloadExportType.PickListAmendment);
        setDownloadTypeToConfirm(DownloadExportType.PickListAmendment);
        try {
            await getPickListAmendment(load, pickListUpdateId);
            setDownloading(null);
            if (outstandingAmendment) {
                setConfirmPickListIdDownload(pickListUpdateId);
                setIsFullPickListDownloadConfirmed(false);
            }
        } catch (error) {
            setDownloading(null);
            setDownloadTypeToConfirm(null);
        }
    };

    const handlePickListDownloadConfirmedClicked = async () => {
        try {
            await apiPost(
                `Load/${load.id}/PickList/${confirmPickListIdDownload}/Confirm`,
                isFullPickListDownloadConfirmed
            );
            setConfirmPickListIdDownload(null);
            setDownloadTypeToConfirm(null);
            setIsFullPickListDownloadConfirmed(null);
        } catch (error) {
            setConfirmPickListIdDownload(null);
            setDownloadTypeToConfirm(null);
            setIsFullPickListDownloadConfirmed(null);
        }
    };

    const handlePickListDownloadClosedClicked = async () => {
        setConfirmPickListIdDownload(null);
        setDownloadTypeToConfirm(null);
    };

    const groupedPickLists: KeyValuePair<string, OrderPickList[]>[] =
        React.useMemo(
            () =>
                groupBy(
                    load.orderLoadingStatuses
                        .filter((ols) => ols.releaseToChess)
                        .map((ols) => ols.order.pickLists)
                        .reduce((a, b) => a.concat(b), [])
                        .sort((a, b) =>
                            isBefore(
                                new Date(a.generatedTime),
                                new Date(b.generatedTime)
                            )
                                ? 1
                                : -1
                        ),
                    'pickListUpdateId'
                ),
            [load]
        );

    const mostRecentPickListUpdateId = groupedPickLists[0].key;

    return (
        <Table
            className={classes.pickListTable}
            headers={
                showAmendmentColumn
                    ? ['Time', 'Pick List', 'Amendment']
                    : ['Time', 'Pick List']
            }
            fixedHeader
            small
        >
            {groupedPickLists.map(
                ({ key: pickListUpdateId, value: pickListCollection }) => (
                    <tr key={pickListUpdateId}>
                        <td className={classes.pickListText}>
                            {formatDate(
                                pickListCollection[0].generatedTime,
                                'dd/MM HH:mm'
                            )}
                            {pickListCollection.some(
                                (pl) => pl.outstandingAmendment
                            ) && (
                                <Icon
                                    className={classes.warningIcon}
                                    title="Outstanding Amendment"
                                    type="warning-thicc"
                                />
                            )}
                        </td>
                        <td>
                            {pickListUpdateId === mostRecentPickListUpdateId ? (
                                <Button
                                    className={classes.downloadButton}
                                    disabled={isDownloading}
                                    styleType="link"
                                    onClick={() =>
                                        handleDownloadPickListClicked(
                                            load,
                                            pickListUpdateId,
                                            pickListCollection.some(
                                                (pl) => pl.outstandingAmendment
                                            )
                                        )
                                    }
                                >
                                    Download
                                    <Icon
                                        className={classes.downloadIcon}
                                        type="download"
                                    />
                                </Button>
                            ) : (
                                <div className={classes.pickListText}>N/A</div>
                            )}
                        </td>
                        {showAmendmentColumn && (
                            <td>
                                {pickListCollection.some(
                                    (pl) => pl.isAmendment
                                ) ? (
                                    <Button
                                        className={classes.downloadButton}
                                        disabled={isDownloading}
                                        styleType="link"
                                        onClick={() =>
                                            handleDownloadPickListAmendmentClicked(
                                                load,
                                                pickListUpdateId,
                                                pickListCollection.some(
                                                    (pl) =>
                                                        pl.outstandingAmendment
                                                )
                                            )
                                        }
                                    >
                                        Download
                                        <Icon
                                            className={classes.downloadIcon}
                                            title="Pick List Amendment has not been downloaded"
                                            type="download"
                                        />
                                    </Button>
                                ) : (
                                    <div className={classes.pickListText}>
                                        N/A
                                    </div>
                                )}
                            </td>
                        )}
                    </tr>
                )
            )}
            {isDownloading && (
                <LoadingModal
                    showModal
                    loadingText={`Downloading ${downloading}...`}
                />
            )}
            {!!confirmPickListIdDownload && !!downloadTypeToConfirm && (
                <Modal isOpen={!!confirmPickListIdDownload}>
                    <ModalHeader>
                        Confirm {downloadTypeToConfirm} downloaded
                    </ModalHeader>
                    <ModalBody>
                        Please confirm the download of {downloadTypeToConfirm}{' '}
                        was successful
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={handlePickListDownloadConfirmedClicked}
                        >
                            Confirm
                        </Button>
                        <Button
                            styleType="secondary"
                            onClick={handlePickListDownloadClosedClicked}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </Table>
    );
};

export default PickListDownloadTable;
