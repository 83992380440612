import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    logoContainer: {
        position: 'absolute',
        top: '-5rem',
        left: '0',
        right: '0',
        textAlign: 'center',
    },
    logoBorder: {
        backgroundColor: theme.colors.primary,
        borderRadius: '100%',
        padding: '.125rem',
        display: 'inline-block',
    },
    logoWrapper: {
        backgroundColor: theme.colors.white,
        borderRadius: '100%',
        padding: '2.5rem 1rem',
    },
    logo: {
        width: '8rem',
    },
}));
