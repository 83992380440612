declare global {
    interface Window {
        __dock_env: {
            TENANT_ID: string;
            CLIENT_ID: string;
            REDIRECT_URI: string;
            LOGOUT_REDIRECT_URI: string;
            API_URI: string;
            APP_INSIGHTS_CONNECTION_STRING: string;
            ENVIRONMENT: string;
            IDP: string;
            API_SCOPE: string;
            BLOB_CLIENT_URI: string;
            OFFLINE: boolean;
            GENERATE_LOAD_PLANS: boolean;
            ALLOW_IOD_DELETION: boolean;
            BAYWATCH_LINK: string;
        };
    }
}

const TENANT_ID = window.__dock_env.TENANT_ID;
const CLIENT_ID = window.__dock_env.CLIENT_ID;
const REDIRECT_URI = window.__dock_env.REDIRECT_URI;
const LOGOUT_REDIRECT_URI = window.__dock_env.LOGOUT_REDIRECT_URI;
const API_URI = window.__dock_env.API_URI;
const APP_INSIGHTS_CONNECTION_STRING =
    window.__dock_env.APP_INSIGHTS_CONNECTION_STRING;
const ENVIRONMENT = window.__dock_env.ENVIRONMENT;
const API_SCOPE = window.__dock_env.API_SCOPE;
const IDP = window.__dock_env.IDP;
const BLOB_CLIENT_URI = window.__dock_env.BLOB_CLIENT_URI;
const OFFLINE = window.__dock_env.OFFLINE;
const GENERATE_LOAD_PLANS = window.__dock_env.GENERATE_LOAD_PLANS;
const ALLOW_IOD_DELETION = window.__dock_env.ALLOW_IOD_DELETION;
const BAYWATCH_LINK = window.__dock_env.BAYWATCH_LINK;

export {
    TENANT_ID,
    CLIENT_ID,
    REDIRECT_URI,
    LOGOUT_REDIRECT_URI,
    API_URI,
    APP_INSIGHTS_CONNECTION_STRING,
    ENVIRONMENT,
    API_SCOPE,
    IDP,
    BLOB_CLIENT_URI,
    OFFLINE,
    GENERATE_LOAD_PLANS,
    ALLOW_IOD_DELETION,
    BAYWATCH_LINK
};
