import React from 'react';
import NavlinkArea from './NavlinkArea';
import NavItem, { NavItemProps } from './NavItem';

const NavSubItem: React.FC<NavItemProps> = ({
    itemName,
    iconType,
    routeTo,
    horizontal,
    navExpanded,
}) => (
    <NavlinkArea navExpanded={navExpanded}>
        <NavItem
            isASubItem
            itemName={itemName}
            iconType={iconType}
            routeTo={routeTo}
            horizontal={horizontal}
            navExpanded={navExpanded}
        />
    </NavlinkArea>
);

export default NavSubItem;
