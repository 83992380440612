import * as React from 'react';
import { theme } from '../../theme';
import Watermark from '@uiw/react-watermark';

const SandboxWatermark: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Watermark
        content="Sandbox Environment"
        height={20}
        fontSize={22}
        offsetLeft={30}
        fontColor={theme.colors.translucentBeige}
    >
        {children}
    </Watermark>
);

export default SandboxWatermark;
