import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APP_INSIGHTS_CONNECTION_STRING } from '../environment-variables';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: APP_INSIGHTS_CONNECTION_STRING,
        enableAutoRouteTracking: false, // true will log all page views
        extensions: [reactPlugin],
    },
});
appInsights.loadAppInsights();

export default reactPlugin;
