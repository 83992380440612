import { BookingType } from '../models/bookings/BookingType';
import { RecurringBookingOptionType } from '../models/bookings/BookingOptionType';

const getCollectionOptions = (): RecurringBookingOptionType[] => {
    return ['email', 'edit', 'cancel', 'viewHistory'];
};

const getDeliveryOptions = (): RecurringBookingOptionType[] => {
    return ['email', 'edit', 'cancel', 'viewHistory'];
};

const getNoBookingOptions = (
    isSuperUser: boolean
): RecurringBookingOptionType[] =>
    isSuperUser ? ['edit', 'viewHistory', 'cancel'] : ['viewHistory'];

const getAvailableOptions = (
    type: BookingType,
    isSuperUser: boolean
): RecurringBookingOptionType[] => {
    switch (type) {
        case BookingType.Collection:
            return getCollectionOptions();
        case BookingType.Delivery:
            return getDeliveryOptions();
        case BookingType.NoBookings:
            return getNoBookingOptions(isSuperUser);
        default:
            throw Error('Invalid Booking Type Provided');
    }
};

export { getAvailableOptions };
