import { buildClassesHook } from '../../helpers/styles';
import { NavlinkAreaProps } from './NavlinkArea';

type NavlinkAreaClasses =
    | 'navlinkArea'
    | 'navlinkAreaExpanded'
    | 'navlinkContainer';

export const useClasses = buildClassesHook<
    NavlinkAreaClasses,
    NavlinkAreaProps
>({
    navlinkArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    navlinkAreaExpanded: {
        justifyContent: 'space-between',
    },
    navlinkContainer: {
        margin: ({ horizontal }) => (horizontal ? '0' : '0.7rem 0'),
    },
});
