import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import LoadingIndicator from '../../loading/LoadingIndicator';
import CSSGrid from '../CSSGrid';
import TimeSlotCard from './TimeSlotCard';

const useClasses = buildClassesHook({
    timeSlotSelectionGrid: {
        marginBottom: '14px',
        maxWidth: '100%',
    },
    loadingPlaceholder: {
        minHeight: '220px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

interface TimeSlotPickerProps {
    allowOverrideFullTimeSlots?: boolean;
    disabled?: boolean;
    loading?: boolean;
    highlightedTimeSlots?: TimeSlot[];
    selectedTimeSlot: TimeSlot | null;
    timeSlots: TimeSlot[];

    onTimeSlotSelected(selectedTimeSlot: TimeSlot): void;
}

const areTimeSlotsEqual = (lhs: TimeSlot, rhs: TimeSlot) => {
    return (
        new Date(lhs.start).valueOf() === new Date(rhs.start).valueOf() &&
        new Date(lhs.end).valueOf() === new Date(rhs.end).valueOf()
    );
};

const TimeSlotPicker: React.FC<TimeSlotPickerProps> = ({
    allowOverrideFullTimeSlots = false,
    disabled = false,
    loading = false,
    highlightedTimeSlots,
    selectedTimeSlot,
    timeSlots,
    onTimeSlotSelected,
}) => {
    const classes = useClasses();

    return loading ? (
        <div className={classes.loadingPlaceholder}>
            <LoadingIndicator />
        </div>
    ) : (
        <CSSGrid
            columns="repeat(auto-fill, minmax(80px, 1fr))"
            rows="repeat(auto-fill, 44px)"
            gap={10}
            className={classes.timeSlotSelectionGrid}
        >
            {timeSlots.map((t, i) => {
                return (
                    <TimeSlotCard
                        key={i}
                        disabled={disabled}
                        timeSlot={t}
                        isSelected={
                            selectedTimeSlot !== null &&
                            areTimeSlotsEqual(t, selectedTimeSlot)
                        }
                        isHighlighted={
                            highlightedTimeSlots &&
                            highlightedTimeSlots.includes(t)
                        }
                        allowOverrideFullTimeSlots={allowOverrideFullTimeSlots}
                        onTimeSlotSelected={onTimeSlotSelected}
                    />
                );
            })}
        </CSSGrid>
    );
};

export default TimeSlotPicker;
