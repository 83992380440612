import * as React from 'react';
import Modal, {
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../lib/bootstrap-ui/Modal';
import Button from '../../lib/bootstrap-ui/Button';

interface LogOutConfirmModalProps {
    showModal: boolean;
    onLogOutConfirmed(): void;
    onCancel(): void;
}

const LogOutConfirmModal: React.FC<LogOutConfirmModalProps> = ({
    showModal,
    onLogOutConfirmed,
    onCancel
}) => (
    <Modal isOpen={showModal} onRequestClose={onCancel}>
        <ModalHeader>
            <h5>Log Out</h5>
        </ModalHeader>
        <ModalBody>Are you sure you would like to log out?</ModalBody>
        <ModalFooter>
            <Button styleType="secondary" onClick={onCancel}>
                Cancel
            </Button>
            <Button styleType="danger" onClick={onLogOutConfirmed}>
                Log Out
            </Button>
        </ModalFooter>
    </Modal>
);

export default LogOutConfirmModal;
