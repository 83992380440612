import { buildClassesHook } from '../../helpers/styles';

export const moreOptionsTooltip = {
    width: 'max-content',
    zIndex: 3000,
};

export const useClasses = buildClassesHook({
    ellipsisIcon: {
        cursor: 'pointer',
        zIndex: 10,
    },
    iconButton: {
        padding: 0,
    },
    moreOptionsTooltip,
});
