import * as React from 'react';

import classNames from 'classnames';
import CSSGrid from '../../../components/common/CSSGrid';
import { IodPlan } from '../../../models/iods/IodPlan';
import { Load } from '../../../models/loads/Load';
import { LoadGroup } from '../../../models/loads/LoadGroup';
import { groupTripTransfersBySourceStore } from '../../../services/iodPlanService';
import Center from '../../common/Center';
import Icon from '../../common/Icon';
import TransportOnlyDetailsCard from '../../load/LoadDetails/TransportOnlyDetailsCard';
import { useClasses } from './IodPlanGrid.styles';

interface IodPlanGridProps {
    iodPlan: IodPlan;
    selectedLoadId?: string;
    groupAllTransfers?: boolean;
    arrowClassname?: string;
    gridClassname?: string;
    gridItemRenderer(load: Load, loadsFromSameStore: LoadGroup): JSX.Element;
    transportOnlyPalletCount?: number;
}

const getFinishingTrailerStartColumn = (iodPlan: IodPlan): 1 | 2 | 3 => {
    const trip2HasValue = !!iodPlan.trip2;
    const trip3HasValue = !!iodPlan.trip3;
    const trip1Has3Transfers = !!iodPlan.trip1.predecessorTransfers[2];

    if (!trip2HasValue && !trip3HasValue) {
        return 3;
    }

    if (trip2HasValue && !trip3HasValue) {
        return trip1Has3Transfers ? 3 : 2;
    }

    return trip1Has3Transfers ? 3 : 1;
};

const getFinishingTrailerStartRow = (iodPlan: IodPlan): 3 | 4 => {
    const trip1TransferCount: number =
        iodPlan.trip1.predecessorTransfers.length;
    const trip2TransferCount: number =
        iodPlan.trip2?.predecessorTransfers.length || 0;
    const trip3TransferCount: number =
        iodPlan.trip3?.predecessorTransfers.length || 0;

    const maxTransferCount: number = Math.max(
        trip1TransferCount,
        trip2TransferCount,
        trip3TransferCount
    );

    return maxTransferCount === 1 ? 4 : 3;
};

const IodPlanGrid: React.FC<IodPlanGridProps> = ({
    iodPlan,
    selectedLoadId,
    arrowClassname,
    gridClassname,
    gridItemRenderer,
    transportOnlyPalletCount = 0,
}) => {
    const classes = useClasses();

    const finishingTrailerStartColumn: number =
        getFinishingTrailerStartColumn(iodPlan);
    const finishingTrailerStartRow: number =
        getFinishingTrailerStartRow(iodPlan);

    const groupedTrip1Transfers = groupTripTransfersBySourceStore(
        iodPlan.trip1,
        selectedLoadId
    );
    const groupedTrip2Transfers = groupTripTransfersBySourceStore(
        iodPlan.trip2,
        selectedLoadId
    );
    const groupedTrip3Transfers = groupTripTransfersBySourceStore(
        iodPlan.trip3,
        selectedLoadId
    );

    return (
        <CSSGrid
            className={classNames(classes.autoGrid, gridClassname)}
            gap={5}
        >
            {groupedTrip1Transfers[2]?.length ? (
                <>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn - 2,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        {gridItemRenderer(
                            groupedTrip1Transfers[2][0],
                            groupedTrip1Transfers[2]
                        )}
                    </Center>

                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn - 1,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        {groupedTrip1Transfers[2][0]?.sourceStore && (
                            <Icon
                                className={arrowClassname}
                                type="arrow-right"
                            />
                        )}
                    </Center>
                </>
            ) : null}

            {groupedTrip1Transfers[0]?.length ? (
                <>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn,
                            gridRow: finishingTrailerStartRow - 2,
                        }}
                    >
                        {gridItemRenderer(
                            groupedTrip1Transfers[0][0],
                            groupedTrip1Transfers[0]
                        )}
                    </Center>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn,
                            gridRow: finishingTrailerStartRow - 1,
                        }}
                    >
                        {groupedTrip1Transfers[0][0] && (
                            <Icon
                                className={arrowClassname}
                                type="arrow-down"
                            />
                        )}
                    </Center>
                </>
            ) : null}

            {transportOnlyPalletCount > 0 && (
                <>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn - 5,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        <TransportOnlyDetailsCard
                            palletCount={transportOnlyPalletCount}
                        />
                    </Center>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn - 4,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        <Icon
                            className={classes.transportOnlyArrow}
                            type="arrow-right"
                        />
                    </Center>
                </>
            )}

            <Center
                style={{
                    gridColumn: finishingTrailerStartColumn,
                    gridRow: finishingTrailerStartRow,
                }}
            >
                {gridItemRenderer(iodPlan.trip1.load, [])}
            </Center>

            {groupedTrip1Transfers[1]?.length ? (
                <>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn,
                            gridRow: finishingTrailerStartRow + 1,
                        }}
                    >
                        {groupedTrip1Transfers[1][0] && (
                            <Icon className={arrowClassname} type="arrow-up" />
                        )}
                    </Center>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn,
                            gridRow: finishingTrailerStartRow + 2,
                        }}
                    >
                        {gridItemRenderer(
                            groupedTrip1Transfers[1][0],
                            groupedTrip1Transfers[1]
                        )}
                    </Center>
                </>
            ) : null}

            {iodPlan.trip2 && (
                <>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn + 1,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        {iodPlan.trip2.load && (
                            <Icon
                                className={arrowClassname}
                                type="arrow-right"
                            />
                        )}
                    </Center>

                    {groupedTrip2Transfers[0]?.length ? (
                        <>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 2,
                                    gridRow: finishingTrailerStartRow - 2,
                                }}
                            >
                                {gridItemRenderer(
                                    groupedTrip2Transfers[0][0],
                                    groupedTrip2Transfers[0]
                                )}
                            </Center>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 2,
                                    gridRow: finishingTrailerStartRow - 1,
                                }}
                            >
                                {groupedTrip2Transfers[0][0] && (
                                    <Icon
                                        className={arrowClassname}
                                        type="arrow-down"
                                    />
                                )}
                            </Center>
                        </>
                    ) : null}

                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn + 2,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        {gridItemRenderer(iodPlan.trip2.load, [])}
                    </Center>

                    {groupedTrip2Transfers[1]?.length ? (
                        <>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 2,
                                    gridRow: finishingTrailerStartRow + 1,
                                }}
                            >
                                {groupedTrip2Transfers[1][0] && (
                                    <Icon
                                        className={arrowClassname}
                                        type="arrow-up"
                                    />
                                )}
                            </Center>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 2,
                                    gridRow: finishingTrailerStartRow + 2,
                                }}
                            >
                                {gridItemRenderer(
                                    groupedTrip2Transfers[1][0],
                                    groupedTrip2Transfers[1]
                                )}
                            </Center>
                        </>
                    ) : null}
                </>
            )}

            {iodPlan.trip3 && (
                <>
                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn + 3,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        {iodPlan.trip3.load && (
                            <Icon
                                className={arrowClassname}
                                type="arrow-right"
                            />
                        )}
                    </Center>

                    {groupedTrip3Transfers[0]?.length ? (
                        <>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 4,
                                    gridRow: finishingTrailerStartRow - 2,
                                }}
                            >
                                {gridItemRenderer(
                                    groupedTrip3Transfers[0][0],
                                    groupedTrip3Transfers[0]
                                )}
                            </Center>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 4,
                                    gridRow: finishingTrailerStartRow - 1,
                                }}
                            >
                                {groupedTrip3Transfers[0][0] && (
                                    <Icon
                                        className={arrowClassname}
                                        type="arrow-down"
                                    />
                                )}
                            </Center>
                        </>
                    ) : null}

                    {groupedTrip3Transfers[1]?.length ? (
                        <>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 4,
                                    gridRow: finishingTrailerStartRow + 1,
                                }}
                            >
                                {groupedTrip3Transfers[1][0] && (
                                    <Icon
                                        className={arrowClassname}
                                        type="arrow-up"
                                    />
                                )}
                            </Center>
                            <Center
                                style={{
                                    gridColumn: finishingTrailerStartColumn + 4,
                                    gridRow: finishingTrailerStartRow + 2,
                                }}
                            >
                                {gridItemRenderer(
                                    groupedTrip3Transfers[1][0],
                                    groupedTrip3Transfers[1]
                                )}
                            </Center>
                        </>
                    ) : null}

                    <Center
                        style={{
                            gridColumn: finishingTrailerStartColumn + 4,
                            gridRow: finishingTrailerStartRow,
                        }}
                    >
                        {gridItemRenderer(iodPlan.trip3.load, [])}
                    </Center>
                </>
            )}
        </CSSGrid>
    );
};

export default IodPlanGrid;
