import * as React from 'react';
import { Load } from '../../../models/loads/Load';
import { LoadsWithTransfers } from '../../../models/loads/LoadsWithTransfers';
import Center from '../../common/Center';
import { CSSGridItem } from '../../common/CSSGrid';
import Icon from '../../common/Icon';
import LoadDetailsCard from '../LoadDetails/LoadDetailsCard';
import { useClasses } from './TransferDetailsGridItems.styles';

interface TransferDetailsGridItemsProps {
    loadsWithTransfers: LoadsWithTransfers;
    selectedLoad: Load;
}

const getColumn = (index: number) => {
    switch (index % 5) {
        case 0:
            return 4;
        case 1:
            return 3;
        case 2:
            return 5;
        case 3:
            return 2;
        case 4:
            return 6;
        default:
            return 4;
    }
};

const getSmallColumn = (index: number) => {
    switch (index % 2) {
        case 0:
            return 1;
        case 1:
            return 2;
        default:
            return 1;
    }
};

const TransferDetailsGridItems: React.FC<TransferDetailsGridItemsProps> = ({
    loadsWithTransfers,
    selectedLoad
}) => {
    const classes = useClasses();

    return (
        <>
            <CSSGridItem column={4} smallColumn={1} row={1}>
                {({ className }) => (
                    <LoadDetailsCard
                        className={className}
                        load={selectedLoad}
                        loadsFromSameStore={loadsWithTransfers.transfers}
                        iodId={null}
                        role="Transfer"
                        isSelected
                    />
                )}
            </CSSGridItem>
            {loadsWithTransfers.loads.length > 1 && (
                <CSSGridItem column={4} row={2}>
                    {({ className }) => (
                        <Center className={className}>
                            <Icon className={classes.arrow} type="arrow-down" />
                        </Center>
                    )}
                </CSSGridItem>
            )}
            {loadsWithTransfers.transfers.map((successor, i) => (
                <CSSGridItem
                    key={successor.id}
                    column={getColumn(i)}
                    smallColumn={getSmallColumn(i)}
                    row={3 + Math.floor(i / 5)}
                    smallRow={3 + Math.floor(i / 2)}
                >
                    {({ className }) => (
                        <LoadDetailsCard
                            className={className}
                            load={successor}
                            loadsFromSameStore={[]}
                            iodId={null}
                            role="Finishing-Trailer"
                            isSelected={successor.id === selectedLoad.id}
                        />
                    )}
                </CSSGridItem>
            ))}
        </>
    );
};

export default TransferDetailsGridItems;
