import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../lib/bootstrap-ui/Modal';
import { HistoricalLoadFilter } from '../../../models/loads/HistoricalLoadFilter';
import WorkHistoryFilterModalBody from './WorkHistoryFilterModalBody';

interface WorkHistoryFilterModalProps {
    isOpen: boolean;
    filter: HistoricalLoadFilter;
    onFilterConfirm(newFilter: HistoricalLoadFilter): void;
    onRequestClose(): void;
}

const areHistoricalLoadFiltersEqual = (
    lhs: HistoricalLoadFilter,
    rhs: HistoricalLoadFilter
) =>
    lhs.loadReference === rhs.loadReference &&
    lhs.orderNumber === rhs.orderNumber &&
    lhs.iodNumber === rhs.iodNumber &&
    lhs.startDate?.valueOf() === rhs.startDate?.valueOf() &&
    lhs.endDate?.valueOf() === rhs.endDate?.valueOf();

const WorkHistoryFilterModal: React.FC<WorkHistoryFilterModalProps> = ({
    isOpen,
    filter,
    onFilterConfirm,
    onRequestClose
}) => {
    const [historicalLoadFilter, setHistoricalLoadFilter] = useState(filter);

    useEffect(() => setHistoricalLoadFilter(filter), [filter]);

    const handleFilterConfirm = () => {
        onFilterConfirm(historicalLoadFilter);
        onRequestClose();
    };

    const handleRequestClose = () => {
        setHistoricalLoadFilter(filter);
        onRequestClose();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={handleRequestClose}>
            <ModalHeader
                showCloseButton
                onCloseButtonClick={handleRequestClose}
            >
                <h5>Historical Load Filter</h5>
            </ModalHeader>
            <ModalBody>
                <WorkHistoryFilterModalBody
                    filter={historicalLoadFilter}
                    onFilterChange={setHistoricalLoadFilter}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleRequestClose} styleType="secondary">
                    Close
                </Button>
                <Button
                    disabled={
                        areHistoricalLoadFiltersEqual(
                            filter,
                            historicalLoadFilter
                        ) ||
                        !!(
                            historicalLoadFilter.startDate &&
                            historicalLoadFilter.endDate &&
                            historicalLoadFilter.startDate.valueOf() >
                                historicalLoadFilter.endDate.valueOf()
                        )
                    }
                    onClick={handleFilterConfirm}
                    styleType="primary"
                >
                    Apply
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default WorkHistoryFilterModal;
