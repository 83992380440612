import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    editButton: {
        float: 'right',
        padding: 0,
        height: '1.5rem',
        maxWidth: '2rem',
    },
    linkIcon: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    inputField: {
        margin: 0,
        padding: 0,
        height: '1.5rem',
        '& ::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    valueContainer: {
        display: 'inline-flex',
    },
    value: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '7.5rem',
    },
}));
