import * as React from 'react';
import LoginButton from './LoginButton';
import { useClasses } from './LoginCard.styles';
import LoginCardLogo from './LoginCardLogo';
import LoginCardTitle from './LoginCardTitle';

const LoginCard: React.FC = () => {
    const classes = useClasses();

    return (
        <div className={classes.loginCard}>
            <LoginCardLogo />
            <LoginCardTitle />
            <div>
                <LoginButton />
            </div>
        </div>
    );
};

export default LoginCard;
