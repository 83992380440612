import * as React from 'react';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { useContext, useState } from 'react';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import TimeSlotSelectionContainer from '../../../containers/bookings/TimeSlotSelectionContainer';
import { BookingType } from '../../../models/bookings/BookingType';
import { addHours } from 'date-fns';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import { TrailerType } from '../../../models/trailers/TrailerType';
import { StoreNumber } from '../../../models/stores/StoreNumber';

interface RecurrencePatternSelectorProps {
    recurrence: string | null;
    bookingType: BookingType;
    duration: number;
    resources: LoadingResource[];
    startDate: Date | null;
    store: StoreNumber;
    trailerType: TrailerType;
    onStartDateChange(selectedDate: Date | null): void;
    onRecurrencePatternChange(recurrencePattern: string): void;
}

const RecurrencePatternSelector: React.FC<RecurrencePatternSelectorProps> = ({
    recurrence,
    startDate,
    bookingType,
    duration,
    resources,
    store,
    trailerType,
    onStartDateChange,
    onRecurrencePatternChange
}) => {
    const { recurrencePattern } = useContext(LookupContext);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(
        startDate
            ? {
                  start: startDate,
                  end: addHours(startDate, duration),
                  isAvailable: true,
                  unavailableReasons: ['No Reason']
              }
            : null
    );

    const handleRecurrencePatternChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const pattern = event.target.value;
        setSelectedTimeSlot(null);
        onRecurrencePatternChange(pattern);
    };

    const handleRecurrenceTimeChange = (selectedTimeSlot: TimeSlot) => {
        setSelectedTimeSlot(selectedTimeSlot);
        onStartDateChange(selectedTimeSlot.start);
    };

    return (
        <>
            <SelectDropdown
                defaultText="Select..."
                dropdownOptions={recurrencePattern.map(p => ({
                    label: p,
                    value: p
                }))}
                label="Recurrence Pattern:"
                selectedOption={recurrence}
                onChange={handleRecurrencePatternChange}
            />
            <TimeSlotSelectionContainer
                selectedTimeSlot={selectedTimeSlot}
                disabled={!recurrence}
                disableResourceAvailabilityCheck
                disableWeekends={recurrence === 'Weekdays'}
                bookingType={bookingType}
                duration={duration}
                resources={resources}
                store={store}
                trailerType={trailerType}
                onTimeSlotSelected={handleRecurrenceTimeChange}
            />
        </>
    );
};

export default RecurrencePatternSelector;
