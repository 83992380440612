import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    progressSummaryMilestoneContent: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        fontWeight: 500,
        textAlign: 'center',
        padding: '0.5rem',
    },
    progressSummaryMilestoneCell: {
        borderLeft: `2px solid ${theme.colors.darkGrey} !important` /*TODO: Fix Table component styling*/,
        padding: '0 !important', //TODO: Fix Table component styling
    },
    active: {
        background: theme.colors.primary,
        color: theme.colors.white,
    },
    complete: {
        background: theme.colors.primaryLight,
        color: theme.colors.black,
    },
    overdue: {
        background: theme.colors.error,
        color: theme.colors.white,
    },
    loaded: {
        background: theme.colors.success,
        color: theme.colors.white,
    },
}));
