import * as React from 'react';
import { toast } from 'react-toastify';
import UpdateNotification from '../components/common/UpdateNotification';
import { pluralise } from '../helpers/stringFunctions';
import { Load } from '../models/loads/Load';
import { LoadStatus } from '../models/loads/LoadStatus';
import { LoadRemoved } from '../models/notifications/Load';
import { ReducerAction } from '../models/reducers/ReducerAction';
import { StoreNumber } from '../models/stores/StoreNumber';
import { Change } from '../models/updates/Change';

export type LoadingTableActionType =
    | 'REPLACE_LOADS'
    | 'ADD_LOAD'
    | 'ADD_LOADS'
    | 'UPDATE_LOAD'
    | 'REMOVE_LOAD'
    | 'RELEASE_LOAD';

interface LoadingTableModifyLoads {
    affectedLoads: Load[];
    selectedStore?: StoreNumber;
}

interface LoadingTableUpdateLoad {
    affectedLoad: Load;
}

type LoadingTableAction<T> = ReducerAction<LoadingTableActionType, T>;

interface LoadingTableAddLoadAction extends LoadingTableAction<Load> {
    type: 'ADD_LOAD';
}

interface LoadingTableBulkModifyLoadsAction
    extends LoadingTableAction<LoadingTableModifyLoads> {
    type: 'ADD_LOADS' | 'REPLACE_LOADS';
}

interface LoadingTableModifyLoadAction
    extends LoadingTableAction<LoadingTableUpdateLoad> {
    type: 'UPDATE_LOAD';
    changes?: Change[];
}

interface LoadingTableRemoveLoadAction extends LoadingTableAction<LoadRemoved> {
    type: 'REMOVE_LOAD';
}

interface LoadingTableReleaseLoadAction extends LoadingTableAction<Load> {
    type: 'RELEASE_LOAD';
    changes?: Change[];
}

const LoadingTableReducer = (
    state: Load[],
    loadingTableAction:
        | LoadingTableBulkModifyLoadsAction
        | LoadingTableAddLoadAction
        | LoadingTableModifyLoadAction
        | LoadingTableRemoveLoadAction
        | LoadingTableReleaseLoadAction
): Load[] => {
    switch (loadingTableAction.type) {
        case 'ADD_LOAD':
            toast.info(
                <UpdateNotification heading="Part Load Added">
                    Part Load {loadingTableAction.payload.loadName} added
                </UpdateNotification>
            );
            return [...state, loadingTableAction.payload];
        case 'ADD_LOADS':
            const addedLoadsForStore = loadingTableAction.payload.affectedLoads.filter(
                l => l.sourceStore === loadingTableAction.payload.selectedStore
            );
            const numberOfLoadsAdded = addedLoadsForStore.length;
            if (numberOfLoadsAdded === 0) {
                return state;
            } else {
                toast.info(
                    <UpdateNotification heading="Loads Added">
                        {pluralise('new load', numberOfLoadsAdded)} added
                    </UpdateNotification>
                );
            }

            return [...state, ...addedLoadsForStore];
        case 'UPDATE_LOAD':
            const affectedLoad = state.find(
                l => l.id === loadingTableAction.payload.affectedLoad.id
            );

            if (!affectedLoad) {
                return state;
            }

            const isLoadComplete =
                loadingTableAction.payload.affectedLoad.status ===
                    LoadStatus.Completed &&
                affectedLoad.status !== LoadStatus.Completed;

            const changes: Change[] = loadingTableAction.changes || [];

            if (changes.length > 0) {
                toast.info(
                    <UpdateNotification
                        heading={`Load ${
                            loadingTableAction.payload.affectedLoad.loadName
                        } ${isLoadComplete ? 'Completed' : 'Updated'}`}
                    >
                        {changes.map(c => (
                            <div key={c.id}>{c.message}</div>
                        ))}
                    </UpdateNotification>
                );
            }

            const unaffectedLoads = state.filter(
                l => l.id !== loadingTableAction.payload.affectedLoad.id
            );

            return isLoadComplete
                ? unaffectedLoads
                : [...unaffectedLoads, loadingTableAction.payload.affectedLoad];
        case 'RELEASE_LOAD':
            if (!state.some(l => l.id === loadingTableAction.payload.id)) {
                return state;
            }
            return [
                ...state.filter(l => l.id !== loadingTableAction.payload.id),
                loadingTableAction.payload
            ];
        case 'REMOVE_LOAD':
            if (!state.some(l => l.id === loadingTableAction.payload.loadId)) {
                return state;
            }

            toast.info(
                <UpdateNotification
                    heading={`Load ${loadingTableAction.payload.loadName} Removed`}
                />
            );

            return state.filter(
                l => l.id !== loadingTableAction.payload.loadId
            );
        case 'REPLACE_LOADS':
            return loadingTableAction.payload.affectedLoads;
    }
};

export default LoadingTableReducer;
