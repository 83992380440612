import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    page: {
        height: '100%',
        padding: '0 1rem',
        display: 'flex',
        flexDirection: 'column',
    },
});
