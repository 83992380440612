import * as React from 'react';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import RecurrencePatternSelector from '../../../components/bookings/recurring/RecurrencePatternSelector';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';

const RecurringBookingSchedule: React.FC<
    FormStepProps<RecurringBookingToAdd>
> = ({ formObject: booking, onChange: onBookingChange }) => {
    const handleBookingRecurrenceChange = (
        selectedRecurrencePattern: string
    ) => {
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            startDate: null,
            recurrencePattern: selectedRecurrencePattern,
        };
        onBookingChange(editedBooking);
    };

    const handleBookingStartDateChange = (selectedDate: Date | null) => {
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            startDate: selectedDate,
        };
        onBookingChange(editedBooking);
    };

    return (
        <>
            {!!booking.duration && !!booking.bookingType && (
                <RecurrencePatternSelector
                    recurrence={booking.recurrencePattern}
                    bookingType={booking.bookingType}
                    duration={booking.duration}
                    resources={booking.resources || []}
                    startDate={booking.startDate}
                    store={booking.store}
                    trailerType={booking.trailerType!}
                    onRecurrencePatternChange={handleBookingRecurrenceChange}
                    onStartDateChange={handleBookingStartDateChange}
                />
            )}
        </>
    );
};

export default RecurringBookingSchedule;
