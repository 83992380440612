import * as React from 'react';
import AdminContainer, {
    AdminPage,
} from '../../containers/admin/common/AdminContainer';
import Page from '../common/Page';

const AccessControl: React.FC = () => (
    <Page>
        <AdminContainer page={AdminPage.AccessControl} />
    </Page>
);

export default AccessControl;
