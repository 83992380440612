import * as React from 'react';
import { useContext, useState } from 'react';
import CancelBookingModal from '../../components/bookings/modals/CancelBookingModal';
import ErrorModal from '../../components/common/ErrorModal';
import LoadingModal from '../../components/common/LoadingModal';
import { LookupContext } from '../../contexts/LookupDataProvider';
import { Booking } from '../../models/bookings/Booking';
import BookingOptions from '../../models/bookings/BookingOptions';
import { apiPost } from '../../services/api';
import { deleteAllBookingAttachments } from '../../services/bookingService';

interface CancelBookingModalContainerProps {
    options: BookingOptions;
    showModal: boolean;
    onCancelBooking?(): void;
    onClose(): void;
}

const CancelBookingModalContainer: React.FC<CancelBookingModalContainerProps> = ({
    options,
    showModal,
    onCancelBooking,
    onClose
}) => {
    const isRecurring = options.bookingType === 'recurring';
    const { booking } = options;
    const { bookingCancellationReasons } = useContext(LookupContext);

    const [cancelling, setCancelling] = useState(false);
    const [error, setError] = useState<string | null>();
    const [errorDeletingAttachments, setErrorDeletingAttachments] = useState(
        false
    );

    const handleRequestClose = () => {
        if (cancelling) {
            return;
        }
        onClose();
    };

    const handleDeleteBookingAttachments = async (booking: Booking) => {
        const success = await deleteAllBookingAttachments(booking);
        if (!success) {
            setError(
                "An error has occurred when attempting to delete the Booking's attachments. You can still cancel the Booking."
            );
            setErrorDeletingAttachments(true);
            setCancelling(false);
        }
        return success;
    };

    const handleCancelBooking = async (bookingCancellationReason: string) => {
        const cancelUrl = isRecurring
            ? `RecurringBooking/${booking.id}/Cancel`
            : `Booking/${booking.id}/Cancel`;
        try {
            await apiPost(cancelUrl, bookingCancellationReason);
        } catch (e) {
            console.warn(e);
            setError('An error has occurred when cancelling the Booking.');
            setErrorDeletingAttachments(false);
            return false;
        }
        return true;
    };

    const handleCancelBookingRequested = async (
        bookingCancellationReason: string
    ) => {
        setCancelling(true);
        if (!errorDeletingAttachments && !isRecurring) {
            const deleteAttachmentsSuccess = await handleDeleteBookingAttachments(
                booking as Booking
            );
            if (!deleteAttachmentsSuccess) {
                return;
            }
        }
        setError(null);
        const cancelBookingSuccess = await handleCancelBooking(
            bookingCancellationReason
        );
        setCancelling(false);
        if (cancelBookingSuccess && onCancelBooking) {
            onCancelBooking();
        }
    };

    const modalHeader = `Cancel Booking ${booking.reference}`;
    const showCancelModal = showModal && !error && !cancelling;

    return (
        <>
            <LoadingModal
                showModal={showModal && cancelling}
                header={modalHeader}
                loadingText="cancelling"
            />
            <ErrorModal
                showModal={showModal && !!error && !cancelling}
                header={modalHeader}
                showFooterButtons={errorDeletingAttachments}
                errorText={error || ''}
                onContinue={onClose}
                onClose={onClose}
            />
            <CancelBookingModal
                showModal={showCancelModal}
                options={options}
                bookingCancellationReasons={bookingCancellationReasons}
                onCancelBooking={handleCancelBookingRequested}
                onClose={handleRequestClose}
            />
        </>
    );
};

export default CancelBookingModalContainer;
