import * as React from 'react';
import { useState } from 'react';
import Popup from 'reactjs-popup';
import { buildClassesHook } from '../../../helpers/styles';
import { CardText } from '../../../lib/bootstrap-ui/Card';
import { Booking } from '../../../models/bookings/Booking';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import {
    bookingTypeHasAssociatedLoad,
    getFormattedBookingHeader,
    getRecurrenceString,
} from '../../../services/bookingService';
import { getTrailerTypeLabelForBooking } from '../../../services/trailerService';
import { theme } from '../../../theme';
import Icon from '../../common/Icon';
import SingleColumnRow from '../../common/SingleColumnRow';
import { BookingSummaryRow } from '../view/summary';

const useClasses = buildClassesHook({
    bookingReference: {
        cursor: 'default',
    },
    bookingReferenceWithClick: {
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.colors.primary,
        textDecoration: 'underline',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    tooltipHeader: {
        padding: '0.5rem',
        textAlign: 'center',
    },
    tooltipBody: {
        padding: '0 0.75rem 0.5rem 0.5rem',
        wordBreak: 'break-word',
    },
    secondHeading: {
        textAlign: 'center',
    },
    resources: {
        paddingTop: '10px',
    },
    store: {
        position: 'absolute',
        left: '0.75rem',
        fontSize: 20,
    },
    loadType: {
        position: 'absolute',
        display: 'inline',
        right: '0.75rem',
        fontSize: 20,
    },
    summaryRowData: {
        maxHeight: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    instructionsRowData: {
        whiteSpace: 'normal',
    },
    noOrdersIcon: {
        fontSize: '0.75em',
        position: 'absolute',
        color: theme.colors.error,
        marginLeft: '2px',
    },
});

interface BookingTooltipProps {
    options: TooltipOptions;
    showNoOrdersIcon?: boolean;
    onTriggerClick?(): void;
}

type TooltipOptions =
    | {
          booking: Booking;
          type: 'booking';
      }
    | {
          booking: RecurringBooking;
          type: 'recurring';
      };

const BookingTooltip: React.FC<BookingTooltipProps> = ({
    options,
    showNoOrdersIcon = false,
    onTriggerClick,
}) => {
    const classes = useClasses();

    const [open, setOpen] = useState(false);
    const booking = options.booking;

    const renderBookingContent = (booking: Booking) => (
        <>
            {booking.customerReference && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Cust. Reference"
                >
                    {booking.customerReference}
                </BookingSummaryRow>
            )}
            {booking.customerName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Cust. Name"
                >
                    {booking.customerName}
                </BookingSummaryRow>
            )}
            {booking.haulierName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Haulier Name"
                >
                    {booking.haulierName}
                </BookingSummaryRow>
            )}
            {booking.productCode && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Product Code"
                >
                    {booking.productCode}
                </BookingSummaryRow>
            )}
            {booking.supplierCode && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Supplier Code"
                >
                    {booking.supplierCode}
                </BookingSummaryRow>
            )}
            {booking.load && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Total Pallets"
                >
                    {booking.load.totalPalletSpaces}
                </BookingSummaryRow>
            )}
        </>
    );

    const renderRecurringBookingContent = (booking: RecurringBooking) => (
        <BookingSummaryRow
            dataColumnClassName={classes.summaryRowData}
            header="Cust. Name"
        >
            {booking.customerName}
        </BookingSummaryRow>
    );

    const handleTriggerClick = () => {
        if (onTriggerClick) {
            setOpen(false);
            onTriggerClick();
        }
    };

    return (
        <div>
            <Popup
                trigger={
                    <span
                        className={
                            onTriggerClick
                                ? classes.bookingReferenceWithClick
                                : classes.bookingReference
                        }
                        onClick={handleTriggerClick}
                    >
                        {booking.reference}
                        {showNoOrdersIcon &&
                            bookingTypeHasAssociatedLoad(booking.bookingType) &&
                            options.type === 'booking' && (
                                <Icon
                                    style={{
                                        visibility: !options.booking.load
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                    className={classes.noOrdersIcon}
                                    type="warning"
                                    title="Booking has no associated orders"
                                />
                            )}
                    </span>
                }
                position={['right top', 'right bottom']}
                keepTooltipInside
                on="hover"
                onOpen={() => setOpen(true)}
                open={open}
                contentStyle={{ width: '475px' }}
            >
                <>
                    <div className={classes.tooltipHeader}>
                        <CardText className={classes.store}>
                            {booking.store}
                        </CardText>
                        <h4 style={{ display: 'inline' }}>
                            {booking.reference}
                        </h4>
                        <CardText className={classes.loadType}>
                            {getTrailerTypeLabelForBooking(booking, true)}
                        </CardText>
                        <SingleColumnRow className={classes.secondHeading}>
                            <h6>
                                {options.type === 'booking'
                                    ? getFormattedBookingHeader(options.booking)
                                    : getRecurrenceString(options.booking)}
                            </h6>
                        </SingleColumnRow>
                    </div>
                    <div className={classes.tooltipBody}>
                        {options.type === 'booking'
                            ? renderBookingContent(options.booking)
                            : renderRecurringBookingContent(options.booking)}
                        {booking.resources.length > 0 && (
                            <BookingSummaryRow
                                className={classes.resources}
                                dataColumnClassName={classes.summaryRowData}
                                header="Resources"
                            >
                                {booking.resources.map((resource) => (
                                    <div key={resource}> {resource}</div>
                                ))}
                            </BookingSummaryRow>
                        )}
                        {booking.bookingInstructions && (
                            <>
                                <hr />
                                <SingleColumnRow
                                    className={classes.instructionsRowData}
                                >
                                    {booking.bookingInstructions}
                                </SingleColumnRow>
                            </>
                        )}
                    </div>
                </>
            </Popup>
        </div>
    );
};

export default BookingTooltip;
