import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';
import { NavItemContentProps } from './NavItemContent';

type NavItemContentClasses =
    | 'navlink'
    | 'navlinkIcon'
    | 'navSubItemIcon'
    | 'navlinkText'
    | 'navlinkArrow'
    | 'navlinkArrowActive'
    | 'navlinkArrowDown'
    | 'navlinkArrowUp';

export const useClasses = buildClassesHook<
    NavItemContentClasses,
    NavItemContentProps,
    Theme
>((theme: Theme) => ({
    navlink: {
        color: theme.colors.mediumDarkGrey,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.white,
            textDecoration: 'none',
        },
        '&.active': {
            color: theme.colors.white,
        },
        display: 'flex',
        alignItems: 'center',
    },
    navlinkIcon: {
        display: ({ horizontal }) => (horizontal ? 'block' : 'inline'),
        margin: ({ horizontal }) => (horizontal ? '0 auto 4px' : '0'),
        fontSize: '1.3em',
        width: '1.25em !important',
    },
    navSubItemIcon: {
        fontSize: '1.1em',
    },
    navlinkText: {
        marginLeft: ({ horizontal }) => (horizontal ? 0 : '1.6rem'),
        verticalAlign: 'text-bottom',
    },
    navlinkArrow: {
        marginLeft: '0.35em',
        transition: 'transform 0.3s linear',
    },
    navlinkArrowActive: {
        color: theme.colors.white,
    },
    navlinkArrowDown: {
        transform: 'rotate(0deg)',
    },
    navlinkArrowUp: {
        transform: 'rotate(180deg)',
    },
}));
