import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    navSubItems: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        transition: 'all 0.35s ease-in-out',
    },
    navSubItemsExpanded: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    navSubItemsShow: {
        paddingTop: '0.5rem',
        paddingBottom: '1rem',
        maxHeight: '100rem',
        overflow: 'auto',
        animation: '$subItemsShow 0.25s',
    },
    navSubItemsHide: {
        maxHeight: 0,
        overflow: 'hidden',
        opacity: 0,
        visibility: 'hidden',
    },
    '@keyframes subItemsShow': {
        '0%': {
            overflow: 'hidden',
        },
        '95%': {
            overflow: 'auto',
        },
    },
});
