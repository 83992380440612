import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    smsTemplateManagementTable: {
        '& thead th': {
            fontSize: '1.2em',
            top: '7.95rem',
            zIndex: 5
        },
        '& td': {
            verticalAlign: 'middle'
        }
    },
    noDataContainer: {
        height: '75vh'
    }
});
