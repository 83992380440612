import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    stockOutTable: {
        '& th': {
            whiteSpace: 'nowrap',
        },
    },
    dataCell: {
        padding: '0.25rem 1rem !important', //TODO: Fix Table component styling
        whiteSpace: 'nowrap',
    },
    productDescriptionCell: {
        maxWidth: '22rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    headerCell: {
        padding: '0.25rem !important', //TODO: Fix Table component styling
        fontWeight: 500,
        borderTop: 'none !important', //TODO: Fix Table component styling
    },
});
