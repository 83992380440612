import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    piggybackTooltip: {
        '&-content': {
            '&.popup-content': {
                background: theme.colors.lightestGrey,
            },
            '&[role=tooltip].popup-content': {
                width: '30rem',
            },
        },
        '&-arrow': {
            '&.popup-arrow': {
                color: theme.colors.lightestGrey,
            },
        },
    },
    tooltipHeader: {
        padding: '0.5rem',
        textAlign: 'center',
    },
    tooltipBody: {
        padding: '0 0.75rem 0.5rem 0.5rem',
        wordBreak: 'break-word',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
    piggybackIcon: {
        '--fa-primary-color': '#f783ac',
        '--fa-primary-opacity': 1,
        '--fa-secondary-color': '#fab5cd',
        '--fa-secondary-opacity': 0.8,
        fontSize: '1.25rem',
        lineHeight: '1rem',
        '&:hover': {
            '--fa-primary-color': '#fab5cd',
            '--fa-primary-opacity': 0.8,
            '--fa-secondary-color': '#f783ac',
            '--fa-secondary-opacity': 1,
        },
    },
}));
