import React from 'react';

import LogOutButton from '../authentication/LogoutButton';
import NavItemContent from './NavItemContent';
import NavlinkArea from './NavlinkArea';
import NavTooltip from './NavTooltip';

interface NavLogOutLinkProps {
    expanded: boolean;
    horizontal?: boolean;
}

const NavLogOutLink: React.FC<NavLogOutLinkProps> = ({
    expanded,
    horizontal,
}) => (
    <NavlinkArea horizontal={horizontal} navExpanded={expanded}>
        <NavTooltip navExpanded={expanded} tooltipText="Logout">
            {({ ref, props }) => (
                <div ref={ref as React.Ref<HTMLDivElement>} {...props}>
                    <LogOutButton>
                        <NavItemContent
                            iconType="sign-out"
                            itemName="Logout"
                            navExpanded={expanded}
                            horizontal={horizontal}
                        />
                    </LogOutButton>
                </div>
            )}
        </NavTooltip>
    </NavlinkArea>
);

export default NavLogOutLink;
