import {
    compareAsc,
    differenceInHours,
    eachDayOfInterval,
    format,
    parseISO
} from 'date-fns';

export const compareDatesAsc = (a: string, b: string): number =>
    compareAsc(parseISO(a), parseISO(b));

export const formatDate = (date: string, dateFormat: string): string =>
    format(parseISO(date), dateFormat);

export const eachDay = (a: Date, b: Date): Date[] =>
    eachDayOfInterval({ start: a, end: b });

export const diffHours = (a: string, b: string) =>
    differenceInHours(parseISO(a), parseISO(b));
