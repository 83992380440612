import * as React from 'react';
import classNames from 'classnames';

const ModalBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    children,
    ...rest
}) => (
    <div className={classNames(className, 'modal-body')} {...rest}>
        {children}
    </div>
);

export default ModalBody;
