import * as React from 'react';

import LoginCard from '../../components/authentication/LoginCard';
import Center from '../../components/common/Center';
import { useClasses } from './LoginFormContainer.styles';
import { useAuth } from '../../services/authentication';
import { Navigate, useLocation } from 'react-router';

const LoginFormContainer: React.FC = () => {
    const { authState } = useAuth();
    const location = useLocation();
    const classes = useClasses();

    return (
        <>
            {authState.loggedIn ? (
                <Navigate to="/" state={{ from: location }} />
            ) : (
                <div className={classes.pageBackground}>
                    <Center>
                        <LoginCard />
                    </Center>
                </div>
            )}
        </>
    );
};

export default LoginFormContainer;
