import * as React from 'react';
import { useState } from 'react';
import { Load } from '../../../models/loads/Load';
import { apiPost } from '../../../services/api';
import LoadingModal from '../../../components/common/LoadingModal';
import ErrorModal from '../../../components/common/ErrorModal';
import Modal, {
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../../lib/bootstrap-ui/Modal';
import Button from '../../../lib/bootstrap-ui/Button';

interface CancelLoadModalProps {
    showModal: boolean;
    load: Load;
    onClose();
}

const CancelLoadModal: React.FC<CancelLoadModalProps> = ({
    showModal,
    load,
    onClose
}) => {
    const [cancelling, setCancelling] = useState(false);
    const [error, setError] = useState(false);

    const handleErrorDismissed = () => {
        setError(false);
    };

    const handleLoadCancel = async () => {
        try {
            setCancelling(true);
            await apiPost(`Load/${load.id}/Cancel`);
            setCancelling(false);
            onClose();
        } catch (error) {
            console.warn(error);
            setError(true);
            setCancelling(false);
        }
    };

    const modalHeader = `Cancel Part Load ${load.loadName}`;

    if (showModal && cancelling) {
        return (
            <LoadingModal
                showModal={showModal && cancelling}
                header={modalHeader}
                loadingText="cancelling"
            />
        );
    }

    if (showModal && error) {
        return (
            <ErrorModal
                showModal={showModal && !!error}
                header={modalHeader}
                errorText={`There was an error cancelling Part Load ${load.loadName}. Please try again. If this issue persists please contact IT.`}
                onClose={handleErrorDismissed}
            />
        );
    }

    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>{modalHeader}</h5>
            </ModalHeader>
            <ModalBody>
                <p>
                    Are you sure you want to cancel Part Load {load.loadName}?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="danger"
                    disabled={cancelling}
                    onClick={handleLoadCancel}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CancelLoadModal;
