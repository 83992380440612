export enum OrderStatus {
    AwaitingUnloading = 'Awaiting Unloading',
    AwaitingSpace = 'Awating Space',
    AwaitingStock = 'Awaiting Stock',
    Picking = 'Picking',
    Loading = 'Loading',
    ReadyToDespatch = 'Ready to Despatch',
    ReadyToFinalCheck = 'Ready to Final Check',
    ReadyToRelease = 'Ready to Release',
    Completed = 'Completed',
    AwaitingLoadRelease = 'Awaiting Load Release',
    AwaitingTunnelStock = 'Awaiting Tunnel Stock',
    AwaitingConfirmation = 'Awaiting Confirmation'
}
