import classNames from 'classnames';
import * as React from 'react';
import AutoSuggest from 'react-autosuggest';
import InputBase from '../Forms/Base/InputBase';
import { useClasses } from './AutoCompleteDropdown.styles';

interface AutoCompleteDropdownProps {
    id?: string;
    hideBottomPadding?: boolean;
    disabled?: boolean;
    dropdownFullContainerWidth?: boolean;
    items: any[];
    label?: string;
    helpText?: string;
    loading?: boolean;
    error?: string | null;
    placeholder?: string;
    className?: string;
    onBlur?: () => void;
    value: string;
    onSearchValueChange(e: React.FormEvent<any>, value: string): void;
    getItemValue?(item: any): any;
    getItemText?(item: any): any;
    shouldItemRender?(item: any, value: any): boolean;
    onSelect(value: string, item: any): void;
    shouldRenderSuggestions?(): boolean;
    onInputClick?(): void;
}

const AutoCompleteDropdown: React.FC<AutoCompleteDropdownProps> = ({
    disabled,
    items,
    placeholder,
    loading,
    onSearchValueChange,
    onBlur,
    getItemValue = (item) => item,
    getItemText = (item) => item,
    onSelect,
    shouldItemRender,
    shouldRenderSuggestions,
    onInputClick,
    className,
    value,
    dropdownFullContainerWidth,
    ...rest
}) => {
    const classes = useClasses();

    const handleValueChange = (
        e: React.FormEvent<any>,
        value: AutoSuggest.ChangeEvent
    ) => {
        if (e.type !== 'keydown') {
            onSearchValueChange(e, value.newValue);
        }
    };

    return (
        <InputBase {...rest}>
            {({ className, onBlur: baseOnBlur, id }) => (
                <AutoSuggest
                    renderSuggestion={getItemText}
                    inputProps={{
                        className: classNames(className, 'form-control'),
                        id,
                        disabled: disabled,
                        placeholder,
                        onBlur: () => {
                            baseOnBlur();
                            if (onBlur) {
                                onBlur();
                            }
                        },
                        value,
                        onChange: handleValueChange,
                        onClick: onInputClick,
                    }}
                    theme={{
                        suggestionsContainer: dropdownFullContainerWidth
                            ? classes.fullWidthContainer
                            : classes.container,
                        suggestionsContainerOpen: classes.openContainer,
                        suggestionsList: classes.menu,
                        suggestion: classes.item,
                        suggestionHighlighted: classes.highlightedItem,
                    }}
                    highlightFirstSuggestion
                    getSuggestionValue={getItemValue}
                    onSuggestionsFetchRequested={() => {
                        /* Do nothing */
                    }}
                    onSuggestionsClearRequested={() => {
                        /* Do nothing */
                    }}
                    suggestions={loading ? [] : items}
                    onSuggestionSelected={(_, value) => {
                        onSelect(value.suggestionValue, value.suggestion);
                        onBlur?.();
                    }}
                    shouldRenderSuggestions={shouldRenderSuggestions}
                />
            )}
        </InputBase>
    );
};

export default AutoCompleteDropdown;
