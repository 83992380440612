import { compareAsc } from 'date-fns';
import { compareDatesAsc } from '../helpers/dates';
import { Drop } from '../models/orders/Drop';
import { OrderLoadingStatus } from '../models/orders/OrderLoadingStatus';

export const getAllDropsInSequence = (
    orderLoadingStatuses: OrderLoadingStatus[]
): Drop[] => {
    const distinctAddresses: string[] = Array.from(
        new Set(orderLoadingStatuses.map(ols => ols.order.deliveryAddress))
    );

    // Create a new drop for every delivery address
    const dropsNoSequence: Drop[] = distinctAddresses.map(da => {
        const ordersOnDrop: OrderLoadingStatus[] = orderLoadingStatuses.filter(
            o => o.order.deliveryAddress === da
        );
        const dropToAdd: Drop = {
            deliveryAddress: da,
            deliveryDate: new Date(
                ordersOnDrop
                    .map(o => o.order)
                    .sort(
                        (a, b) =>
                            a &&
                            b &&
                            compareDatesAsc(a.deliveryDate, b.deliveryDate)
                    )[0].deliveryDate
            ),
            orders: ordersOnDrop
        };

        return dropToAdd;
    });

    const distinctDeliveryDates: number[] = Array.from(
        new Set(dropsNoSequence.map(dns => dns.deliveryDate.valueOf()))
    );

    // Merge drops that have different delivery addresses but same delivery dates
    const distinctDropsInSequence: Drop[] = distinctDeliveryDates
        .map(ddd => {
            const firstDropForDeliveryDate: Drop = dropsNoSequence.filter(
                dns => dns.deliveryDate.valueOf() === ddd
            )[0];
            const otherDropsForDeliveryDate: Drop[] = dropsNoSequence.filter(
                dns =>
                    dns.deliveryDate.valueOf() === ddd &&
                    dns.deliveryAddress !==
                        firstDropForDeliveryDate?.deliveryAddress
            );

            otherDropsForDeliveryDate.map(
                od =>
                    (firstDropForDeliveryDate.orders = firstDropForDeliveryDate.orders.concat(
                        od.orders
                    ))
            );

            return firstDropForDeliveryDate;
        })
        .sort((a, b) => a && b && compareAsc(a.deliveryDate, b.deliveryDate));

    return distinctDropsInSequence;
};
