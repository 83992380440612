import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    cancelledIcon: {
        color: theme.colors.errorDark,
        marginRight: '2px',
    },
    noShowIcon: {
        color: theme.colors.darkGrey,
    },
    cancelledColumn: {
        fontWeight: 400,
        color: theme.colors.errorDark,
    },
    noShowColumn: {
        fontWeight: 400,
        color: theme.colors.darkGrey,
    },
    cancelledOrNoShowColumnIcon: {
        marginLeft: '0.6rem',
    },
    viewHistoryIcon: {
        margin: '0.1rem 0.5rem',
    },
    viewHistoryButton: {
        padding: 0,
        verticalAlign: 'baseline',
    },
    referenceData: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    typeColumn: {
        '& > div': {
            height: '2em',
            width: '100%',
            minWidth: 5,
        },
    },
    bookingTableRow: {
        '& td': {
            padding: '0.5rem 0.75rem',
            whiteSpace: 'nowrap',
        },
        '& td$typeColumn': {
            width: 5,
            padding: 0,
            paddingTop: 5,
        },
    },
    restrictedCell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '12rem',
    },
}));
