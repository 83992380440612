import * as React from 'react';
import { SMSTemplateManagementModalType } from '../../../../containers/admin/smsTemplates/SMSTemplateManagementContainer';
import Table from '../../../../lib/bootstrap-ui/Table';
import {
    HeaderDetails,
    TextAlign,
} from '../../../../lib/bootstrap-ui/Table/Table';
import { SMSTemplate } from '../../../../models/smsTemplates/SMSTemplate';
import NoDataFoundTableRow from '../../../common/table/NoDataFoundTableRow';
import { useClasses } from './SMSTemplateManagementTable.styles';
import SMSTemplateManagementTableRow from './SMSTemplateManagementTableRow';

interface SMSTemplateManagementTableProps {
    smsTemplates: SMSTemplate[];
    loading: boolean;
    onModalOpened(
        value: SMSTemplate,
        action: SMSTemplateManagementModalType
    ): void;
}

const SMSTemplateManagementTableHeaders: (string | HeaderDetails)[] = [
    { title: 'Name', width: '20rem' },
    'Message',
    { title: 'Default', width: '8rem', align: TextAlign.Center },
    { title: 'Actions', width: '10rem', align: TextAlign.Center },
];

const SMSTemplateManagementTable: React.FC<SMSTemplateManagementTableProps> = ({
    smsTemplates,
    loading,
    onModalOpened,
}) => {
    const classes = useClasses();

    return (
        <Table
            headers={SMSTemplateManagementTableHeaders}
            loading={loading}
            placeholderRows={15}
            className={classes.smsTemplateManagementTable}
            fixedHeader
        >
            {smsTemplates
                .sort((a, b) =>
                    a.isPrimary || b.isPrimary
                        ? Number(b.isPrimary) - Number(a.isPrimary)
                        : a.name.localeCompare(b.name)
                )
                .map((st) => (
                    <SMSTemplateManagementTableRow
                        smsTemplate={st}
                        key={st.id!}
                        onModalOpened={onModalOpened}
                    />
                ))}
            {!loading && smsTemplates.length === 0 && (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No SMS Templates were found"
                    iconType="no-sms-templates"
                    columnSpan={SMSTemplateManagementTableHeaders.length}
                    size="large"
                />
            )}
        </Table>
    );
};

export default SMSTemplateManagementTable;
