import * as React from 'react';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../lib/bootstrap-ui/Modal';
import { Load } from '../../../models/loads/Load';
import PickListDownloadTable from '../pickList/PickListDownloadTable';

interface ManagePickListsModalProps {
    showModal: boolean;
    load: Load;
    onClose(): void;
}

const ManagePickListsModal: React.FC<ManagePickListsModalProps> = ({
    showModal,
    load,
    onClose
}) => {
    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Pick Lists for {load.loadName}</h5>
            </ModalHeader>
            <ModalBody>
                <PickListDownloadTable load={load} />
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ManagePickListsModal;
