import * as React from 'react';
import { useEffect, useState } from 'react';
import AutoCompleteDropdown from '../../lib/bootstrap-ui/Dropdown/AutoCompleteDropdown';
import { getColdStoreOrderText } from '../../services/ColdStoreOrderService';
import ColdStoreOrderCard from './view/card/ColdStoreOrderCard';
import classNames from 'classnames';
import { InternalWorkOrder } from '../../models/orders/InternalWorkOrder';
import { StockTransferOrder } from '../../models/orders/StockTransferOrder';
import { ThirdPartyCollectionOrder } from '../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import Icon from '../common/Icon';
import { useClasses } from './ColdStoreOrderSelection.styles';

interface ColdStoreOrderSelectionProps<T extends TType> {
    containerClassName?: string;
    orderContainerClassName?: string;
    orders: T[];
    resultSize?: number;
    searchValue: string;
    selectedOrders: T[];
    disabled?: boolean;
    onInvalidOrderSelected(selectedOrder: T | null): void;
    onOrdersChange(selectedOrders: T[], invalidOrderSelected?: boolean): void;
    onOrderSearchValueChange(searchValue: string): void;
    onInputClick?(): void;
    onInputBlur?(): void;
}

type TType = InternalWorkOrder | ThirdPartyCollectionOrder | StockTransferOrder;

function ColdStoreOrderSelection<T extends TType>({
    containerClassName,
    orderContainerClassName,
    orders,
    resultSize,
    searchValue,
    selectedOrders,
    disabled = false,
    onInvalidOrderSelected,
    onOrdersChange,
    onOrderSearchValueChange,
    onInputClick,
    onInputBlur,
}: ColdStoreOrderSelectionProps<T>): React.ReactElement {
    const classes = useClasses();

    const [filteredOrders, setFilteredOrders] = useState<T[]>([]);
    const [invalidOrderSelected, setInvalidOrderSelected] =
        useState<T | null>();

    useEffect(() => {
        if (orders) {
            setFilteredOrders(orders.slice(0, resultSize));
        }
    }, [orders, resultSize]);

    const handleSearchValueChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        value: string
    ) => {
        onOrderSearchValueChange(value);
    };

    const resetInvalidOrderSelected = () => {
        setInvalidOrderSelected(null);
        onOrdersChange(selectedOrders);
        onInvalidOrderSelected(null);
    };

    const handleOrderSelected = (_: string, item: T) => {
        if (!item.isAttached && !item.isCancelled) {
            onOrdersChange([...selectedOrders, item]);
            setInvalidOrderSelected(null);
        } else {
            setInvalidOrderSelected(item);
            onOrdersChange(selectedOrders, true);
            onInvalidOrderSelected(item);
        }

        onOrderSearchValueChange('');
        setFilteredOrders([]);
    };

    const handleOrderRemoved = (removedOrderId: string) => {
        const updatedOrders = selectedOrders.filter(
            (o) => o.id !== removedOrderId
        );
        onOrdersChange(updatedOrders);
    };

    const getItemText = (item: T) => (
        <div className={item.isCancelled ? classes.cancelledOrder : undefined}>
            {getColdStoreOrderText(item)}
            {item.isAttached && (
                <Icon className={classes.orderHasBookingIcon} type="book" />
            )}
        </div>
    );
    const getItemValue = (item: T) => item.orderNumber;

    return (
        <div className={containerClassName}>
            <AutoCompleteDropdown
                disabled={disabled}
                items={filteredOrders}
                hideBottomPadding
                value={searchValue}
                getItemText={getItemText}
                getItemValue={getItemValue}
                onSearchValueChange={handleSearchValueChange}
                onSelect={handleOrderSelected}
                onInputClick={onInputClick}
                onBlur={onInputBlur}
                shouldRenderSuggestions={() => filteredOrders.length > 0}
                label="Order Number:"
            />
            {invalidOrderSelected && (
                <div className={classes.invalidOrderContainer}>
                    <ColdStoreOrderCard
                        key={invalidOrderSelected.id}
                        cardClass={classes.invalidThirdPartyOrder}
                        collectionOrder={invalidOrderSelected}
                        showAdditionalInfo
                        onRequestRemove={resetInvalidOrderSelected}
                    />
                </div>
            )}
            {selectedOrders.length > 0 && (
                <div
                    className={classNames(
                        orderContainerClassName,
                        classes.orderContainer
                    )}
                >
                    {selectedOrders.map((o, i) => (
                        <ColdStoreOrderCard
                            key={i}
                            collectionOrder={o}
                            onRequestRemove={handleOrderRemoved}
                        />
                    ))}
                </div>
            )}
            {selectedOrders.length === 0 && !invalidOrderSelected && (
                <div
                    className={classNames(
                        classes.noOrdersText,
                        classes.noOrdersSection
                    )}
                >
                    <Icon
                        className={classes.dollyIcon}
                        type="person-dolly-empty"
                    />
                    No orders selected
                </div>
            )}
        </div>
    );
}

export default ColdStoreOrderSelection;
