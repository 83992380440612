import * as React from 'react';
import { useContext } from 'react';
import { CanAccessContext } from '../../../contexts/CanAccessContext';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';

interface AccessControlConfirmModalProps {
    showModal: boolean;
    onClose(): void;
}

const AccessControlConfirmModal: React.FC<AccessControlConfirmModalProps> = ({
    showModal,
    onClose,
}) => {
    const { revokeAccess } = useContext(CanAccessContext);

    const handleRequestClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={showModal} onRequestClose={handleRequestClose}>
            <ModalHeader>
                <h5>Warning</h5>
            </ModalHeader>
            <>
                <ModalBody>
                    Are you sure you want to revoke access to Dock System and
                    Booking System for all users?
                </ModalBody>
                <ModalFooter>
                    <Button styleType="danger" onClick={revokeAccess}>
                        Revoke Access
                    </Button>
                    <Button styleType="secondary" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </>
        </Modal>
    );
};

export default AccessControlConfirmModal;
