import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Button from '../../lib/bootstrap-ui/Button';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { theme } from '../../theme';

export type StoreSelectorOption = StoreNumber | 'All';

const useClasses = buildClassesHook({
    storeButtonNotSelected: {
        backgroundColor: theme.colors.white,
        color: theme.colors.primary,
        borderColor: theme.colors.white,
    },
    storeButtonSelected: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover': {
            color: theme.colors.white,
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
    storeButton: {
        padding: '8px 16px',
        border: 0,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
});

interface StoreSelectorPillsProps {
    className?: string;
    active: StoreSelectorOption;
    options?: StoreSelectorOption[];

    onStoreOptionChange(number: StoreSelectorOption): void;
}

const StoreSelectorPills: React.FC<StoreSelectorPillsProps> = ({
    onStoreOptionChange,
    active,
    options = [1, 2, 3, 4, 'All'],
    className,
}) => {
    const classes = useClasses();

    return (
        <div className={className}>
            {options.map((option) => (
                <Button
                    key={option}
                    className={classNames(
                        classes.storeButton,
                        active === option
                            ? classes.storeButtonSelected
                            : classes.storeButtonNotSelected
                    )}
                    styleType="link"
                    onClick={() => onStoreOptionChange(option)}
                >
                    {option}
                </Button>
            ))}
        </div>
    );
};

export default StoreSelectorPills;
