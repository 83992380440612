import classnames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import { canOverrideTimeSlot } from '../../../services/timeSlotService';
import { Theme } from '../../../theme';
import Center from '../Center';

const useClasses = buildClassesHook((theme: Theme) => ({
    card: {
        border: 'none',
        boxShadow: `1px 1px 3px 1px ${theme.colors.lightGrey}`,
        borderRadius: '0.25rem',
        cursor: 'pointer',
    },
    cardFull: {
        backgroundColor: theme.colors.purple,
    },
    cardSelected: {
        backgroundColor: theme.colors.primary,
    },
    cardBody: {
        padding: '0.25em',
        textAlign: 'center',
    },
    cardText: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        display: 'inline',
        color: theme.colors.darkGrey,
        margin: '0px',
    },
    cardTextStartTime: {
        fontSize: '1.5rem',
        color: 'black',
    },
    disabledCardTextStartTime: {
        fontSize: '1.5rem',
        color: theme.colors.darkGrey,
    },
    cardTextSelected: {
        color: 'white',
    },
    cardFullText: {
        color: theme.colors.white,
    },
    cardHightlighted: {
        boxShadow: `0px 0px 5px 1px ${theme.colors.primary}`,
    },
    cardDisabled: {
        backgroundColor: theme.colors.lightGrey,
        cursor: 'not-allowed',
    },
}));

interface TimeSlotCardProps {
    allowOverrideFullTimeSlots?: boolean;
    disabled?: boolean;
    timeSlot: TimeSlot;
    isHighlighted?: boolean;
    isSelected?: boolean;
    onTimeSlotSelected(selectedTimeSlot: TimeSlot): void;
}

const TimeSlotCard: React.FC<TimeSlotCardProps> = ({
    timeSlot,
    allowOverrideFullTimeSlots = false,
    disabled = false,
    isHighlighted = false,
    isSelected = false,
    onTimeSlotSelected,
}) => {
    const classes = useClasses();

    const isTimeSlotFullAndOverridable =
        allowOverrideFullTimeSlots && canOverrideTimeSlot(timeSlot);

    const handleTimeSlotSelected = () => {
        if (!timeSlot.isAvailable && !isTimeSlotFullAndOverridable) {
            return;
        }
        onTimeSlotSelected(timeSlot);
    };

    const getTimeSlotCardTitle = () => {
        if (isSelected) {
            return 'Selected';
        }
        if (timeSlot.isAvailable) {
            return 'Available';
        }
        if (isTimeSlotFullAndOverridable) {
            return 'Override Time Slot';
        }
        return 'Unavailable';
    };

    return (
        <>
            <div
                className={classnames(classes.card, {
                    [classes.cardDisabled]:
                        disabled ||
                        (!timeSlot.isAvailable &&
                            !isTimeSlotFullAndOverridable),
                    [classes.cardFull]: isTimeSlotFullAndOverridable,
                    [classes.cardSelected]: isSelected,
                    [classes.cardHightlighted]: !isSelected && isHighlighted,
                })}
                title={getTimeSlotCardTitle()}
            >
                <div
                    className={classes.cardBody}
                    onClick={handleTimeSlotSelected}
                >
                    <Center>
                        <div
                            className={classnames(
                                classes.cardText,
                                classes.cardTextStartTime,
                                {
                                    [classes.disabledCardTextStartTime]:
                                        disabled ||
                                        (!timeSlot.isAvailable &&
                                            !isTimeSlotFullAndOverridable),
                                    [classes.cardTextSelected]: isSelected,
                                    [classes.cardFullText]:
                                        isTimeSlotFullAndOverridable,
                                }
                            )}
                        >
                            {format(new Date(timeSlot.start), 'HH:mm')}
                        </div>
                    </Center>
                </div>
            </div>
        </>
    );
};

export default TimeSlotCard;
