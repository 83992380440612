import * as React from 'react';
import { useState } from 'react';
import UnsavedChangesModal from '../../../../components/common/UnsavedChangesModal';
import { buildClassesHook } from '../../../../helpers/styles';
import Button from '../../../../lib/bootstrap-ui/Button';
import { TextArea } from '../../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { BookingType } from '../../../../models/bookings/BookingType';
import { EditRecurringBooking } from '../../../../models/bookings/EditRecurringBooking';
import { RecurringBooking } from '../../../../models/bookings/RecurringBooking';
import InformationMessage from '../../../common/InformationMessage';
import NameLookup from '../../../common/NameLookup';

const useClasses = buildClassesHook({
    editModal: {
        maxHeight: '90vh',
        minWidth: '400px',
    },
});

interface EditModalProps {
    recurringBooking: RecurringBooking;
    showModal: boolean;
    onEditConfirmed(editedBooking: EditRecurringBooking): void;
    onClose(): void;
}

const EditModal: React.FC<EditModalProps> = ({
    recurringBooking,
    showModal,
    onEditConfirmed,
    onClose,
}) => {
    const classes = useClasses();

    const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
        useState(false);
    const [bookingCustomerName, setBookingCustomerName] = useState(
        recurringBooking.customerName
    );
    const [bookingInstructions, setBookingInstructions] = useState(
        recurringBooking.bookingInstructions
    );

    const unsavedChanges =
        recurringBooking.customerName !== bookingCustomerName ||
        recurringBooking.bookingInstructions !== bookingInstructions;

    const handleEditConfirmed = async () => {
        const editedBooking: EditRecurringBooking = {
            bookingInstructions: bookingInstructions,
            customerName: bookingCustomerName,
        };
        onEditConfirmed(editedBooking);

        setShowUnsavedChangesModal(false);
    };

    const handleDiscardChangesClicked = () => {
        setBookingCustomerName(recurringBooking.customerName);
        setBookingInstructions(recurringBooking.bookingInstructions);
        setShowUnsavedChangesModal(false);
        onClose();
    };

    const handleRequestClose = () => {
        if (unsavedChanges) {
            setShowUnsavedChangesModal(true);
        } else {
            onClose();
        }
    };

    const isInvalid = !unsavedChanges || !bookingCustomerName;
    const modalHeader = `Edit Recurring Booking ${recurringBooking.reference}`;

    return (
        <>
            <Modal
                isOpen={showModal && !showUnsavedChangesModal}
                modalClassName={classes.editModal}
                onRequestClose={handleRequestClose}
            >
                <ModalHeader>
                    <h5>{modalHeader}</h5>
                </ModalHeader>
                <ModalBody>
                    <div>
                        {(recurringBooking.bookingType ===
                            BookingType.Delivery ||
                            recurringBooking.bookingType ===
                                BookingType.Internal) && (
                            <NameLookup
                                lookupType="Customer"
                                defaultSearchValue={bookingCustomerName}
                                isValid={!!bookingCustomerName}
                                onNameSelected={(customerName) =>
                                    setBookingCustomerName(customerName)
                                }
                                required
                                autoConvertWhitespace
                            />
                        )}
                    </div>
                    <div>
                        <TextArea
                            id="BookingInstructions"
                            label={
                                recurringBooking.bookingType ===
                                BookingType.NoBookings
                                    ? 'No Bookings Reason:'
                                    : 'Booking Instructions:'
                            }
                            value={bookingInstructions}
                            onChange={setBookingInstructions}
                            autoConvertWhitespace
                            autoTrim
                        />
                    </div>
                    <InformationMessage hideIcon>
                        This will also update all associated bookings.
                    </InformationMessage>
                </ModalBody>
                <ModalFooter>
                    <Button styleType="secondary" onClick={handleRequestClose}>
                        Close
                    </Button>
                    <Button
                        styleType="primary"
                        disabled={isInvalid}
                        onClick={handleEditConfirmed}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={handleDiscardChangesClicked}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
        </>
    );
};

export default EditModal;
