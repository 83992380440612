import React, { useState } from 'react';
import Button from '../../../lib/bootstrap-ui/Button/Button';
import AccessControlConfirmModal from './AccessControlConfirmModal';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { useClasses } from './AccessControlSettings.styles';

const AccessControlSettings: React.FC = () => {
    const classes = useClasses();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Grid fluid>
                <Row>
                    <Column size={12} className={classes.formContainer}>
                        <Row className={classes.selectorRow}>
                            <Column size={4}>
                                <Button
                                    styletype="secondary"
                                    onClick={() => setShowModal(true)}
                                >
                                    Revoke Access
                                </Button>
                                <AccessControlConfirmModal
                                    showModal={showModal}
                                    onClose={() => setShowModal(false)}
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </Grid>
        </>
    );
};

export default AccessControlSettings;
