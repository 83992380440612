import React, { useContext } from 'react';
import { CanAccessContext } from '../contexts/CanAccessContext';
import { useAuth } from '../services/authentication';
import { RouteDefinition, routeFor } from './routes';
import { Navigate, useLocation } from 'react-router';

interface AppRouteProps {
    route: RouteDefinition;
}

const AppRoute: React.FC<React.PropsWithChildren<AppRouteProps>> = ({
    route,
    children,
}) => {
    const { canAccess } = useContext(CanAccessContext);
    const { authState } = useAuth();
    const location = useLocation();

    if (!authState.loggedIn) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    const routeShouldDisplay =
        canAccess && (!route.display || route.display(authState));

    const shouldRedirect = route.redirectAction?.redirectCondition(authState);

    return (
        <>
            {shouldRedirect ? (
                <Navigate
                    to={routeFor(route.redirectAction!.redirectToKey)()}
                    replace
                />
            ) : (
                routeShouldDisplay && children
            )}
        </>
    );
};

export default AppRoute;
