import React, { useContext, useEffect, useState } from 'react';
import { Location } from 'history';
import { useLocation } from 'react-router';
import { RouteKey, routeFor } from '../../Routes/routes';
import { CanAccessContext } from '../../contexts/CanAccessContext';
import { ENVIRONMENT } from '../../environment-variables';
import { UserRole } from '../../helpers/userRole';
import { useNotificationListener } from '../../hooks/useNotificationListener';
import { AccessControlUpdated } from '../../models/notifications/AccessControl';
import { useAuth } from '../../services/authentication';
import { BAYWATCH_LINK } from '../../environment-variables';
import {
    userInRole,
    userIsAdmin,
    userIsNotReader,
} from '../../services/userService';
import { useClasses } from './Nav.styles';
import NavItemContent from './NavItemContent';
import NavLogOutLink from './NavLogOutLink';
import NavParentItem from './NavParentItem';
import NavlinkArea from './NavlinkArea';

const isExactPath =
    <Params extends { [K in keyof Params]?: string }>(
        ...pathsToMatch: string[]
    ) =>
    (location: Location) =>
        pathsToMatch.some((p) => location.pathname === p);

const startsWithAnyPath =
    <Params extends { [K in keyof Params]?: string }>(
        ...pathsToMatch: string[]
    ) =>
    (location: Location) =>
        pathsToMatch.some((p) => location.pathname.startsWith(p));

const isExactOrStartsWithAnyPath =
    <Params extends { [K in keyof Params]?: string }>(
        ...pathsToMatch: string[]
    ) =>
    (location: Location) =>
        isExactPath(...pathsToMatch)(location) ||
        startsWithAnyPath(...pathsToMatch)(location);

const isLoadingScreen = isExactOrStartsWithAnyPath(
    '/Store/',
    '/Load/',
    '/LoadingSummary',
    '/HistoricLoad',
    '/Iod/'
);
const isBookingScreen = startsWithAnyPath('/Bookings', '/RecurringBookings');

const isAdminScreen = startsWithAnyPath('/Admin/');

const filterShouldPersistScreens = isExactOrStartsWithAnyPath(
    '/Store/1/WorkHistory',
    '/Store/2/WorkHistory',
    '/Store/3/WorkHistory',
    '/Store/4/WorkHistory',
    '/Load/',
    '/Iod/'
);

export interface NavProps {
    expanded: boolean;
    horizontal?: boolean;
    onExpandToggle(): void;
}

const Nav: React.FC<NavProps> = (props) => {
    const { expanded, onExpandToggle, horizontal } = props;

    const {
        authState: { user, loggedIn },
    } = useAuth();

    const isUserAdmin = userIsAdmin(user);
    const showNav = loggedIn;
    const showWorkQueue = loggedIn && !userInRole(user)(UserRole.Gatehouse);
    const showBookings = loggedIn && userIsNotReader(user);
    const showAdmin = loggedIn && isUserAdmin;

    const location = useLocation();

    const classes = useClasses(props);

    const [showWorkQueueItems, setShowWorkQueueItems] =
        useState<boolean>(false);
    const [showBookingsItems, setShowBookingsItems] = useState<boolean>(false);
    const isOnBookingPage: boolean = isBookingScreen(location);
    const isOnWorkQueuePage: boolean = isLoadingScreen(location);
    const isOnSettingsPage: boolean = isAdminScreen(location);
    const filterShouldPersist: boolean = filterShouldPersistScreens(location);

    useEffect(() => {
        setShowBookingsItems(isOnBookingPage);
        if (isOnBookingPage) {
            setShowWorkQueueItems(false);
        }
    }, [isOnBookingPage]);

    useEffect(() => {
        setShowWorkQueueItems(isOnWorkQueuePage);
        if (isOnWorkQueuePage) {
            setShowBookingsItems(false);
        }
    }, [isOnWorkQueuePage]);

    useEffect(() => {
        if (!isOnSettingsPage) return;
        setShowBookingsItems(false);
        setShowWorkQueueItems(false);
    }, [isOnSettingsPage]);

    useEffect(() => {
        if (!filterShouldPersist) {
            localStorage.removeItem('work-history-filter');
        }
    }, [filterShouldPersist]);

    const { fetchCanAccess } = useContext(CanAccessContext);
    useNotificationListener(AccessControlUpdated, () => {
        fetchCanAccess();
    });

    if (!showNav) return null;

    return (
        <div className={classes.container}>
            <div className={classes.navlinksContainer}>
                <div>
                    {!horizontal && (
                        <>
                            <NavlinkArea
                                horizontal={horizontal}
                                navExpanded={expanded}
                            >
                                <NavItemContent
                                    iconType={expanded ? 'cross-solid' : 'bars'}
                                    navExpanded={expanded}
                                    onClick={onExpandToggle}
                                />
                            </NavlinkArea>

                            <hr className={classes.navLine} />
                        </>
                    )}
                    {showWorkQueue && (
                        <NavParentItem
                            setShowSubItems={() =>
                                setShowWorkQueueItems((prev) => !prev)
                            }
                            showSubItems={showWorkQueueItems}
                            itemName="Work Queue"
                            iconType="truck-loading-duo"
                            routeTo={routeFor(RouteKey.WorkQueue)()}
                            navExpanded={expanded}
                            horizontal={horizontal}
                            isActive={isLoadingScreen}
                        />
                    )}

                    {showBookings && (
                        <NavParentItem
                            setShowSubItems={() =>
                                setShowBookingsItems((prev) => !prev)
                            }
                            showSubItems={showBookingsItems}
                            itemName="Bookings"
                            iconType="book-duo"
                            routeTo={routeFor(RouteKey.Bookings)()}
                            navExpanded={expanded}
                            horizontal={horizontal}
                            isActive={isBookingScreen}
                        />
                    )}
                    {showAdmin && (
                        <NavParentItem
                            itemName="Settings"
                            iconType="settings"
                            routeTo={routeFor(RouteKey.BayManagement)()}
                            navExpanded={expanded}
                            horizontal={horizontal}
                            isActive={isAdminScreen}
                        />
                    )}
                    {showWorkQueue && (
                        <>
                            <hr className={classes.navLine} />
                            <NavParentItem
                                itemName="Bay Watch"
                                iconType="baywatch-link"
                                routeTo={BAYWATCH_LINK}
                                target="_blank"
                                navExpanded={expanded}
                                horizontal={horizontal}
                                isExternalLink={true}
                            />
                        </>
                    )}
                </div>
                {horizontal ? (
                    <NavLogOutLink
                        expanded={expanded}
                        horizontal={horizontal}
                    />
                ) : (
                    <div>
                        <div className={classes.navFooterLinks}>
                            <NavLogOutLink
                                expanded={expanded}
                                horizontal={horizontal}
                            />
                        </div>

                        {ENVIRONMENT.toLocaleUpperCase() !== 'PRODUCTION' && (
                            <div className={classes.environmentLabel}>
                                {ENVIRONMENT.toLocaleUpperCase()}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Nav;
