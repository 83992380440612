import { isSameDay } from 'date-fns';
import * as React from 'react';

import { formatDate } from '../../../helpers/dates';
import Table, { TableDividerRow } from '../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../lib/bootstrap-ui/Table/Table';
import { Load } from '../../../models/loads/Load';
import NoDataFoundTableRow from '../../common/table/NoDataFoundTableRow';
import { useClasses } from './WorkHistoryTable.styles';
import WorkHistoryTableRow from './WorkHistoryTableRow';

interface WorkHistoryTableProps {
    loads: Load[];
    loading: boolean;
}

const WorkHistoryTableHeaders: (string | HeaderDetails)[] = [
    { title: 'Expanded', hide: true, width: '1rem', zIndex: 2 },
    { title: 'PL', tooltip: 'Part Load', width: '5rem', zIndex: 2 },
    { title: 'Sub', zIndex: 2 },
    { title: 'IOD', zIndex: 2 },
    { title: 'Trailer', zIndex: 2 },
    { title: 'Trailer No.', zIndex: 2 },
    { title: 'Type', zIndex: 2 },
    { title: 'Completed', zIndex: 2 },
    { title: 'Destination', width: '20rem', zIndex: 2 },
    { title: 'Unloaded', zIndex: 2 },
    { title: 'Loaded', zIndex: 2 },
    { title: 'Pallets', zIndex: 2 },
    { title: 'Drops', zIndex: 2 },
    { title: 'Bay', zIndex: 2 },
];

const sortLoads = (a: Load, b: Load) => {
    if (a.completedAt === b.completedAt) {
        return 0;
    } else if (!a.completedAt) {
        return 1;
    } else if (!b.completedAt) {
        return -1;
    }
    return (
        new Date(b.completedAt).valueOf() - new Date(a.completedAt).valueOf()
    );
};

const WorkHistoryTable: React.FC<WorkHistoryTableProps> = ({
    loads,
    loading,
}) => {
    const classes = useClasses();

    return (
        <Table
            className={classes.workHistoryTable}
            headers={WorkHistoryTableHeaders}
            loading={loading}
            placeholderRows={15}
            fixedHeader
            medium
        >
            {loads.sort(sortLoads).map((l, i) => (
                <React.Fragment key={l.id}>
                    {(i === 0 ||
                        (!loads[i - 1].completedAt && l.completedAt) ||
                        (loads[i - 1].completedAt &&
                            l.completedAt &&
                            !isSameDay(
                                new Date(loads[i - 1].completedAt!),
                                new Date(l.completedAt)
                            )) ||
                        (loads[i - 1].completedAt && !l.completedAt)) && (
                        <TableDividerRow
                            className={classes.dateRow}
                            colSpan={WorkHistoryTableHeaders.length}
                        >
                            {l.completedAt
                                ? formatDate(l.completedAt, 'EEEE dd/MM')
                                : 'N/A'}
                        </TableDividerRow>
                    )}
                    <WorkHistoryTableRow key={l.id} load={l} />
                </React.Fragment>
            ))}
            {!loading && loads.length === 0 && (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No loads were found for this store"
                    iconType="no-loads"
                    columnSpan={WorkHistoryTableHeaders.length}
                    size="large"
                />
            )}
        </Table>
    );
};

export default WorkHistoryTable;
