import { addMonths, isSameMonth, startOfMonth } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import DatePickerBody, { WeekDay } from './DatePickerBody';
import DatePickerHeader from './DatePickerHeader';

const useClasses = buildClassesHook({
    datePicker: {
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '400px',
    },
});

interface DatePickerProps {
    disabled?: boolean;
    disableWeekends?: boolean;
    excludedDates?: Date[];
    minDate?: Date;
    selectedDay: Date | null;
    weekStartsOn?: WeekDay;

    onDateSelected: (selectedDay: Date) => void;
    onMonthChange?: (startOfMonth: Date) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
    disabled = false,
    disableWeekends = false,
    excludedDates = [],
    minDate,
    selectedDay,
    weekStartsOn,
    onDateSelected,
    onMonthChange,
}) => {
    const classes = useClasses();

    const [selectedMonth, setSelectedMonth] = useState(
        (selectedDay && startOfMonth(selectedDay)) || new Date()
    );

    useEffect(() => {
        if (selectedDay) {
            setSelectedMonth(startOfMonth(selectedDay));
        }
    }, [selectedDay]);

    const handleMonthChange = (updatedMonth: Date) => {
        if (onMonthChange) {
            onMonthChange(updatedMonth);
        }
        setSelectedMonth(updatedMonth);
    };

    const handleNextMonthClick = () => {
        const updatedMonth = addMonths(selectedMonth, 1);
        handleMonthChange(updatedMonth);
    };

    const handlePreviousMonthClick = () => {
        const updatedMonth = addMonths(selectedMonth, -1);
        handleMonthChange(updatedMonth);
    };

    const handleDateSelected = (newSelectedDay: Date) => {
        if (isSameMonth(newSelectedDay, addMonths(selectedMonth, 1))) {
            setSelectedMonth(addMonths(selectedMonth, 1));
        }

        if (isSameMonth(newSelectedDay, addMonths(selectedMonth, -1))) {
            setSelectedMonth(addMonths(selectedMonth, -1));
        }

        onDateSelected(newSelectedDay);
    };

    return (
        <Grid className={classes.datePicker}>
            <Row justify="center">
                <Column>
                    <DatePickerHeader
                        selectedMonth={selectedMonth}
                        disabled={disabled}
                        minDate={minDate}
                        onNextMonthClick={handleNextMonthClick}
                        onPreviousMonthClick={handlePreviousMonthClick}
                    />
                    <DatePickerBody
                        disabled={disabled}
                        disableWeekends={disableWeekends}
                        selectedMonth={selectedMonth}
                        selectedDate={selectedDay}
                        excludedDates={excludedDates}
                        minDate={minDate}
                        weekStartsOn={weekStartsOn}
                        onDateSelected={handleDateSelected}
                    />
                </Column>
            </Row>
        </Grid>
    );
};

export default DatePicker;
