import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    switch: {
        cursor: 'pointer',
    },
    switchLabel: {
        cursor: 'pointer',
    },
});
