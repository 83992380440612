import * as React from 'react';
import { splitToCols } from '../../helpers/sorting';
import { buildClassesHook } from '../../helpers/styles';
import { Checkbox } from '../../lib/bootstrap-ui/Forms';
import { Column, Row } from '../../lib/bootstrap-ui/Grid';

const useClasses = buildClassesHook({
    label: {
        display: 'block',
    },
    singleRowCheckboxes: {
        justifyContent: 'space-between',
        marginLeft: '0px',
        marginRight: '0px',
    },
});

interface CheckboxListSelectorProps {
    label: string;
    inline?: boolean;
    availableItems: string[];
    selectedItems: string[];
    createEmptyColumns?: boolean;
    columns?: number;
    disabled?: boolean;
    onChange(selectedItems: string[]): void;
    getItemLabel?(item: string): string;
}

const CheckboxListSelector: React.FC<CheckboxListSelectorProps> = ({
    label,
    inline = false,
    availableItems,
    selectedItems,
    createEmptyColumns = false,
    columns = 0,
    disabled = false,
    onChange,
    getItemLabel,
}) => {
    const classes = useClasses();

    const handleCheckboxStatusChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const item: string = event.target.value;
        if (event.target.checked) {
            onChange([...selectedItems, item]);
        } else {
            onChange([...selectedItems.filter((r) => r !== item)]);
        }
    };

    const asRows = splitToCols(availableItems, columns);

    const renderMissingCols = (missingCols: number): JSX.Element[] => {
        const missingColumns: JSX.Element[] = [];

        for (let i = 0; i < missingCols; i++) {
            missingColumns.push(<Column key={i}> </Column>);
        }
        return missingColumns;
    };

    return (
        <>
            <label className={classes.label}>{label}</label>
            {columns > 1 ? (
                asRows.map((rows, rowKey) => (
                    <Row key={rowKey}>
                        {rows.map((r, i) => (
                            <Column key={i}>
                                <Checkbox
                                    checked={selectedItems.includes(r)}
                                    inline={inline}
                                    label={getItemLabel ? getItemLabel(r) : r}
                                    value={r}
                                    onChange={handleCheckboxStatusChange}
                                    disabled={disabled}
                                />
                            </Column>
                        ))}
                        {createEmptyColumns &&
                            renderMissingCols(columns - rows.length)}
                    </Row>
                ))
            ) : (
                <Row className={classes.singleRowCheckboxes}>
                    {availableItems.map((r, i) => (
                        <React.Fragment key={i}>
                            <Checkbox
                                checked={selectedItems.includes(r)}
                                inline={inline}
                                label={r}
                                value={r}
                                onChange={handleCheckboxStatusChange}
                                disabled={disabled}
                            />
                        </React.Fragment>
                    ))}
                </Row>
            )}
        </>
    );
};

export default CheckboxListSelector;
