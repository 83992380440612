import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';
import Bay from '../../../models/bays/Bay';
import { doesBayHaveCapability } from '../../../services/bayService';
import { theme } from '../../../theme';
import Icon from '../../common/Icon';

const useClasses = buildClassesHook({
    contentCenterColumn: {
        verticalAlign: 'middle',
        textAlign: 'center',
        '& Button': {
            width: '100%'
        }
    }
});

interface BayManagementTableRowProps {
    bay: Bay;
    bayCapabilities: string[];
    onBayChange: (updatedBay: Bay) => void;
}

const BayManagementTableRow: React.FC<BayManagementTableRowProps> = ({
    bay,
    bayCapabilities,
    onBayChange
}) => {
    const classes = useClasses();

    const handleCapabilityValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const capability: string = event.target.value;
        if (event.target.checked) {
            const updatedBay: Bay = {
                ...bay,
                capabilities: [...bay.capabilities, capability]
            };
            onBayChange(updatedBay);
        } else {
            const updatedBay: Bay = {
                ...bay,
                capabilities: [...bay.capabilities].filter(
                    c => c !== capability
                )
            };
            onBayChange(updatedBay);
        }
    };

    const handleToggleOutOfOrder = () => {
        const updatedBay: Bay = {
            ...bay,
            isOutOfOrder: !bay.isOutOfOrder
        };
        onBayChange(updatedBay);
    };

    const hasCapability = doesBayHaveCapability(bay);

    return (
        <tr key={bay.id!}>
            <td>{bay.id}</td>
            <td>{bay.store}</td>
            {bayCapabilities.map(c => (
                <td key={c} className={classes.contentCenterColumn}>
                    <Checkbox
                        checked={hasCapability(c)}
                        size="1.2em"
                        value={c}
                        onChange={handleCapabilityValueChange}
                    />
                </td>
            ))}
            <td className={classes.contentCenterColumn}>
                {bay.isVirtualBay ? (
                    <Icon
                        type="checked"
                        color={theme.colors.success}
                        size="2x"
                    />
                ) : (
                    ''
                )}
            </td>
            <td className={classes.contentCenterColumn}>
                {bay.isOutOfOrder ? (
                    <Icon
                        type="out-of-order"
                        color={theme.colors.error}
                        size="2x"
                    />
                ) : (
                    ''
                )}
            </td>
            <td className={classes.contentCenterColumn}>
                <Button onClick={handleToggleOutOfOrder}>
                    {bay.isOutOfOrder
                        ? 'Mark as In Order'
                        : 'Mark as Out of Order'}
                </Button>
            </td>
        </tr>
    );
};

export default BayManagementTableRow;
