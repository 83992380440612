import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    infoContainer: {
        position: 'sticky',
        top: 0,
        width: '100%',
        backgroundColor: theme.colors.white,
        zIndex: 1,
        borderBottom: `2px solid ${theme.colors.lightestGrey}`,
    },
}));
