import * as React from 'react';
import { useState } from 'react';
import BookingEmailEntryContainer from '../../../containers/bookings/update/BookingEmailEntryContainer';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import { apiPost } from '../../../services/api';
import { theme } from '../../../theme';
import InformationMessage from '../../common/InformationMessage';
import LoadingIndicator from '../../loading/LoadingIndicator';

const useClasses = buildClassesHook({
    loadingContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    modal: {
        minWidth: '425px',
    },
    addButton: {
        margin: 'auto 10px auto 5px',
        color: theme.colors.primary,
        display: 'inline-block',
        padding: '8px 0 0 0',
    },
    removeButton: {
        margin: 'auto 5px auto 10px',
        color: theme.colors.error,
        display: 'inline-block',
        padding: '8px 0 0 0',
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    emailInput: {
        display: 'inline-block',
        marginBottom: '0',
    },
});

interface EmailModalProps {
    booking: Booking | RecurringBooking;
    bookingType: 'booking' | 'recurring';
    showModal: boolean;
    emailType?: 'booked' | 'rescheduled' | 'edited';
    onEmailSent?(): void;
    onClose(): void;
}

const EmailModal: React.FC<EmailModalProps> = ({
    booking,
    showModal,
    bookingType,
    emailType = 'booked',
    onEmailSent,
    onClose,
}) => {
    const classes = useClasses();

    const [bookingEmailAddresses, setBookingEmailAddresses] = useState(
        booking.emailAddresses
    );
    const [emailingConfirmation, setEmailingConfirmation] = useState(false);
    const [error, setError] = useState(false);
    const [containsInvalidEmail, setContainsInvalidEmail] = useState(true);
    const urlBookingType =
        bookingType === 'recurring' ? 'RecurringBooking' : 'Booking';
    const isEmailEntered =
        bookingEmailAddresses && bookingEmailAddresses.replace(/;/g, '') !== '';

    const handleSendClicked = async () => {
        if (!bookingEmailAddresses) {
            return;
        }

        setEmailingConfirmation(true);
        try {
            await apiPost(
                `${urlBookingType}/${booking.id}/Email/${emailType}`,
                bookingEmailAddresses
            );
        } catch (error) {
            console.warn(error);
            setError(true);
            setEmailingConfirmation(false);
            return;
        }
        setEmailingConfirmation(false);
        if (onEmailSent) {
            onEmailSent();
        }
    };

    const handleEmailModalClose = () => {
        if (emailingConfirmation) {
            return;
        }
        setError(false);
        onClose();
    };

    const handleEmailAddressesChanged = (emailAddresses: string) => {
        setBookingEmailAddresses(emailAddresses);
    };

    return (
        <Modal
            className={classes.modal}
            isOpen={showModal}
            onRequestClose={handleEmailModalClose}
        >
            <ModalHeader>
                <h5>
                    {bookingType === 'recurring'
                        ? `Email Recurring Booking Summary ${booking.reference} `
                        : `Email Booking Summary ${booking.reference} `}
                </h5>
            </ModalHeader>
            {emailingConfirmation ? (
                <div className={classes.loadingContainer}>
                    <LoadingIndicator text="sending email" />
                </div>
            ) : (
                <>
                    <ModalBody>
                        {error ? (
                            <InformationMessage messageType="error">
                                There was an error sending the email.
                            </InformationMessage>
                        ) : (
                            <BookingEmailEntryContainer
                                emailAddresses={bookingEmailAddresses}
                                onEmailAddressesChange={
                                    handleEmailAddressesChanged
                                }
                                onEmailInvalidated={setContainsInvalidEmail}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            styleType="secondary"
                            onClick={handleEmailModalClose}
                        >
                            Close without sending
                        </Button>
                        {!error && isEmailEntered && (
                            <Button
                                styleType="primary"
                                disabled={containsInvalidEmail}
                                onClick={handleSendClicked}
                            >
                                Send
                            </Button>
                        )}
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};

export default EmailModal;
