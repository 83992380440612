import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { RouteKey, routeFor } from '../../Routes/routes';
import RecurringBookingFormContainer from '../../containers/recurringBookings/form/RecurringBookingFormContainer';
import { BookingType } from '../../models/bookings/BookingType';
import Page from '../common/Page';

const RecurringBookingForm: React.FC = () => {
    const { bookingType } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <RecurringBookingFormContainer
                bookingType={bookingType as BookingType}
                onBookingConfirmation={(id: string) =>
                    navigate(routeFor(RouteKey.RecurringBookingSummary)({ id }))
                }
            />
        </Page>
    );
};

export default RecurringBookingForm;
