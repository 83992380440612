import { useRef } from 'react';

export const useConditionalDebounce = <T>(
    duration: number,
    debouceCondition: (value: T) => boolean,
    action: (value: T) => void
) => {
    const timeoutId = useRef<number | null>(null);

    const cancelTimeout = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = null;
    };

    const debounce = (value: T) => {
        if (debouceCondition(value)) {
            if (timeoutId.current) cancelTimeout();
            action(value);
            return;
        }

        const timeout = setTimeout(() => action(value), duration);
        timeoutId.current = (timeout as unknown) as number;
    };

    return debounce;
};
