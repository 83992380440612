import { addMonths, format, isBefore, startOfMonth } from 'date-fns';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import { Theme } from '../../../theme';
import Icon from '../Icon';

const useClasses = buildClassesHook((theme: Theme) => ({
    headerColumn: {
        textAlign: 'center',
        flex: 6,
    },
    iconColumn: {
        textAlign: 'center',
        flex: 1,
        margin: 'auto',
    },
    headerRow: {
        marginBottom: '10px',
        display: 'flex',
    },
    iconButton: {
        padding: 0,
    },
    headerText: {
        margin: 0,
    },
    headerTextDisabled: {
        color: theme.colors.darkGrey,
    },
}));

interface DatePickerHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    disabled?: boolean;
    displayFormat?: string;
    minDate?: Date;
    selectedMonth: Date;

    onNextMonthClick: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
    onPreviousMonthClick: (
        event: React.MouseEvent<SVGElement, MouseEvent>
    ) => void;
}

const MonthDisplayFormat = 'MMMM yyyy';

const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
    disabled = false,
    displayFormat = MonthDisplayFormat,
    minDate,
    selectedMonth,
    onNextMonthClick,
    onPreviousMonthClick,
}) => {
    const classes = useClasses();

    const displayPreviousButton =
        (minDate &&
            !isBefore(selectedMonth, startOfMonth(addMonths(minDate, 1)))) ||
        !minDate;

    return (
        <div className={classes.headerRow}>
            <div className={classes.iconColumn}>
                {displayPreviousButton && (
                    <Button
                        onClick={onPreviousMonthClick}
                        className={classes.iconButton}
                        disabled={disabled}
                        styleType="link"
                    >
                        <Icon type="previous" />
                    </Button>
                )}
            </div>
            <div className={classes.headerColumn}>
                <h3
                    className={
                        !disabled
                            ? classes.headerText
                            : classes.headerTextDisabled
                    }
                >
                    {format(selectedMonth, displayFormat)}
                </h3>
            </div>
            <div className={classes.iconColumn}>
                <Button
                    onClick={onNextMonthClick}
                    className={classes.iconButton}
                    disabled={disabled}
                    styleType="link"
                >
                    <Icon type="next" />
                </Button>
            </div>
        </div>
    );
};

export default DatePickerHeader;
