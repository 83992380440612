import * as React from 'react';
import classnames from 'classnames';
import { buildClassesHook } from '../../../helpers/styles';

interface CSSGridProps extends React.HTMLAttributes<HTMLElement> {
    rows?: string;
    columns?: string;
    smallRows?: string;
    smallColumns?: string;
    gap?: number;
}

const useClasses = buildClassesHook({
    grid: {
        display: 'grid',
        gridTemplateRows: (props: CSSGridProps) =>
            props.smallRows || props.rows,
        gridTemplateColumns: (props: CSSGridProps) =>
            props.smallColumns || props.columns,
        gridGap: (props: CSSGridProps) => `${props.gap}px`
    }
});

const CSSGrid: React.FC<React.PropsWithChildren<CSSGridProps>> = props => {
    const { className, children, ...otherProps } = props;

    const classes = useClasses(props);

    return (
        <div className={classnames(classes.grid, className)} {...otherProps}>
            {children}
        </div>
    );
};

export default CSSGrid;
