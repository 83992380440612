import { buildClassesHook } from '../../../../../../helpers/styles';

export const useClasses = buildClassesHook({
    loadingProgressDetailTable: {
        maxWidth: '1000px',
        margin: '1rem auto 2.5rem auto',
        '& th': {
            whiteSpace: 'nowrap',
            padding: '0.5rem 0 !important',
        },
    },
});
