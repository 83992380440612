import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Button from '../../lib/bootstrap-ui/Button';
import Icon from './Icon';
import { moreOptionsTooltip, useClasses } from './MoreOptions.styles';

interface PopUpProps {
    triggerClassName?: string;
    onClose?(): void;
    children: (close: () => void) => React.ReactNode;
}

const PopUp: React.FC<PopUpProps> = ({
    children,
    triggerClassName,
    onClose,
}) => {
    const classes = useClasses();

    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <Popup
            contentStyle={moreOptionsTooltip}
            arrow={false}
            trigger={
                <div className={triggerClassName}>
                    <Button
                        styleType="link"
                        className={classes.iconButton}
                        stopOnClickPropagation
                        onClick={toggleOpen}
                    >
                        <Icon
                            className={classes.ellipsisIcon}
                            type="ellipsis"
                            size="lg"
                        />
                    </Button>
                </div>
            }
            onOpen={() => setOpen(true)}
            onClose={onClose}
            open={open}
            keepTooltipInside
            position="bottom right"
            on="click"
            closeOnDocumentClick
        >
            {children(() => setOpen(false)) as any}
        </Popup>
    );
};

export default PopUp;
