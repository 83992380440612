import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    LoadingSummaryTableRow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '12Rem',
    },
    EditPriorityCell: {
        padding: '0 !important', //TODO: fix table styling
        verticalAlign: 'middle !important', //TODO: fix table styling
    },
    EditPriorityFormGroup: {
        marginBottom: '0',
    },
    EditPriorityDropdown: {
        padding: '0.1rem 0.5rem !important',
    },
    EditPriorityFormGroupDisabled: {
        backgroundColor: 'transparent !important',
        border: '1px solid transparent',
        backgroundImage: 'none',
    },
});
