import { StoreNumber } from '../stores/StoreNumber';
import { oneSecond, oneMinute } from '../common/TimeAsMiliseconds';

export interface LoadingMilestoneThreshold {
    id: number;
    store: StoreNumber;
    milestone: LoadingMilestone;
    thresholdDuration: string | null;
}

export type LoadingMilestone =
    | 'Allocated'
    | 'Picked'
    | 'Scanned'
    | 'Checked'
    | 'Marshalled - Holding Area'
    | 'Marshalled - Moving to Trailer'
    | 'Loaded'
    | 'Problem'
    | 'Picked - By Load Xml';

export const convertThresholdToMilliseconds = (
    thresholdDuration: string
): number => {
    const minutes = parseInt(
        thresholdDuration.substr(0, thresholdDuration.indexOf(':'))
    );
    const seconds = parseInt(
        thresholdDuration.substr(thresholdDuration.indexOf(':') + 1)
    );

    return minutes * oneMinute + seconds * oneSecond;
};
