import { RecurringBooking } from '../models/bookings/RecurringBooking';

const sortRecurringBookings = (
    booking1: RecurringBooking,
    booking2: RecurringBooking
) => {
    const startDate1 = new Date(booking1.startDate);
    const startDate2 = new Date(booking2.startDate);

    if (startDate1.getHours() !== startDate2.getHours()) {
        return startDate1.getHours() - startDate2.getHours();
    }
    const endHours1 = startDate1.getHours() + booking1.duration;
    const endHours2 = startDate2.getHours() + booking2.duration;
    return endHours1 - endHours2;
};

export { sortRecurringBookings };
