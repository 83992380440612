import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableBody } from './index';

interface TableBodyWithLoadingPlaceholderProps {
    className?: string;
    colSpan?: number;
    loading?: boolean;
    placeholderRows: number;
}

interface TableBodyPlaceHolderProps {
    colSpan?: number;
    className?: string;
    numberOfRows: number;
}

const TableBodyPlaceHolder: React.FC<TableBodyPlaceHolderProps> = ({
    colSpan,
    className,
    numberOfRows,
}) => (
    <TableBody className={className}>
        {[...Array(numberOfRows)].map((_, i) => (
            <tr key={i}>
                <td colSpan={colSpan}>
                    <Skeleton />
                </td>
            </tr>
        ))}
    </TableBody>
);

const TableBodyWithLoadingPlaceholder: React.FC<
    React.PropsWithChildren<TableBodyWithLoadingPlaceholderProps>
> = ({ className, children, colSpan, loading, placeholderRows }) =>
    loading ? (
        <TableBodyPlaceHolder
            colSpan={colSpan}
            className={className}
            numberOfRows={placeholderRows}
        />
    ) : (
        <TableBody className={className}>{children}</TableBody>
    );

export default TableBodyWithLoadingPlaceholder;
