import * as React from 'react';
import { useContext } from 'react';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { useCurrentUser } from '../../../services/authentication';
import { getDefaultBookingTrailerType } from '../../../services/bookingService';
import { getStoreDropdownOptions } from '../../../services/storeService';
import { userToAllStores } from '../../../services/userService';

const RecurringBookingStoreContainer: React.FC<
    FormStepProps<RecurringBookingToAdd>
> = ({ formObject: booking, onChange: onBookingChange }) => {
    const { loading, stores } = useContext(LookupContext);

    const user = useCurrentUser();

    const availableStores = !user ? [1] : userToAllStores(user);

    const handleStoreChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const store = parseInt(event.target.value) as StoreNumber;

        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            resources: [],
            startDate: null,
            store,
            trailerType: getDefaultBookingTrailerType(booking.bookingType),
        };
        onBookingChange(editedBooking);
    };

    const storeDropdownOptions = getStoreDropdownOptions(
        stores.map(String)
    ).filter((s) => availableStores.includes(s.value));

    return loading ? (
        <LoadingIndicator />
    ) : (
        <Row>
            <Column>
                <SelectDropdown
                    id="StoreSelect"
                    defaultText="Select Store..."
                    dropdownOptions={storeDropdownOptions}
                    label="Store:"
                    selectedOption={String(booking.store)}
                    onChange={handleStoreChange}
                />
            </Column>
        </Row>
    );
};

export default RecurringBookingStoreContainer;
