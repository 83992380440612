import classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { getDayString } from '../../../../helpers/stringFunctions';
import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

const useClasses = buildClassesHook((theme: Theme) => ({
    dateHeading: {
        borderTop: '0 !important',
        position: 'sticky',
        top: '7rem',
        backgroundColor: theme.colors.white,
        zIndex: 1,
    },
}));

interface BookingTableDateRowProps {
    date: Date;
    historicalBooking?: boolean;
    className?: string;
    colSpan?: number;
}

const BookingTableDateRow: React.FC<BookingTableDateRowProps> = ({
    date,
    historicalBooking = false,
    className,
    colSpan = 0,
}) => {
    const classes = useClasses();

    return (
        <tr>
            <th
                className={classNames(classes.dateHeading, className)}
                colSpan={colSpan}
            >
                {`${getDayString(date.getDay())} ${format(
                    date,
                    historicalBooking ? 'dd/MM/yyyy' : 'dd/MM'
                )}`}
            </th>
        </tr>
    );
};

export default BookingTableDateRow;
