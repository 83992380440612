import { CreateStockTransfer } from '../models/loads/CreateStockTransfer';
import { StoreNumber } from '../models/stores/StoreNumber';
import { ReducerAction } from '../models/reducers/ReducerAction';
import { StockTransferOrder } from '../models/orders/StockTransferOrder';
import { LoadType } from '../models/loads/LoadType';

type ActionType = 'SET_SOURCE_STORE' | 'SET_DESTINATION_STORE' | 'SET_ORDERS';

type CreateStockTransferFormReducerAction<T> = ReducerAction<ActionType, T>;

interface SetSourceStoreAction
    extends CreateStockTransferFormReducerAction<StoreNumber> {
    type: 'SET_SOURCE_STORE';
}
interface SetDestinationStoreAction
    extends CreateStockTransferFormReducerAction<StoreNumber> {
    type: 'SET_DESTINATION_STORE';
}
interface SetOrdersAction
    extends CreateStockTransferFormReducerAction<{
        orders: StockTransferOrder[];
        invalidOrderAttached: boolean;
    }> {
    type: 'SET_ORDERS';
}

const CreateStockTransferFormReducer = (
    state: CreateStockTransfer,
    action: SetSourceStoreAction | SetDestinationStoreAction | SetOrdersAction
): CreateStockTransfer => {
    switch (action.type) {
        case 'SET_SOURCE_STORE':
            if (action.payload === state.destinationStore) throw new Error();
            const isTunnelAfterSourceStoreUpdate: boolean =
                (action.payload === 2 && state.destinationStore === 3) ||
                (action.payload === 3 && state.destinationStore === 2);
            return {
                ...state,
                sourceStore: action.payload,
                orders: [],
                loadType: isTunnelAfterSourceStoreUpdate
                    ? LoadType.Tunnel
                    : LoadType.StockTrailer
            };
        case 'SET_DESTINATION_STORE':
            if (action.payload === state.sourceStore) throw new Error();
            const isTunnelAfterDestinationStoreUpdate: boolean =
                ((action.payload === 2 && state.sourceStore === 3) ||
                    (action.payload === 3 && state.sourceStore === 2)) &&
                state.orders.reduce(
                    (palletCount, currentOrder) =>
                        palletCount + currentOrder.palletSpaces,
                    0
                ) <= 8;
            return {
                ...state,
                destinationStore: action.payload,
                loadType: isTunnelAfterDestinationStoreUpdate
                    ? LoadType.Tunnel
                    : LoadType.StockTrailer
            };
        case 'SET_ORDERS':
            const isTunnelAfterOrdersUpdate: boolean =
                ((state.sourceStore === 2 && state.destinationStore === 3) ||
                    (state.sourceStore === 3 &&
                        state.destinationStore === 2)) &&
                action.payload.orders.reduce(
                    (palletCount, currentOrder) =>
                        palletCount + currentOrder.palletSpaces,
                    0
                ) <= 8;
            return {
                ...state,
                orders: action.payload.orders,
                invalidOrderAttached: action.payload.invalidOrderAttached,
                loadType: isTunnelAfterOrdersUpdate
                    ? LoadType.Tunnel
                    : LoadType.StockTrailer
            };
    }
};

export default CreateStockTransferFormReducer;
