import * as React from 'react';

import { useMemo } from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { Load } from '../../../../models/loads/Load';
import { StockOut } from '../../../../models/orders/StockOut';
import { useData } from '../../../../services/api';
import StockOutTable, {
    OrderStockOut,
} from '../../../stockOut/table/view/StockOutTable';
import { useClasses } from './StockOutModal.styles';

interface StockOutModalProps {
    load: Load;
    isOpen: boolean;
    onClose(): void;
}

const StockOutModal: React.FC<StockOutModalProps> = ({
    load,
    isOpen,
    onClose,
}) => {
    const classes = useClasses();

    const [loadStockOuts, loading] = useData<StockOut[]>(
        isOpen ? `Load/${load.id}/StockOuts` : null
    );

    const orderStockOuts: OrderStockOut[] = useMemo(() => {
        if (!loadStockOuts) {
            return [];
        }
        const groupedStockOuts = loadStockOuts.reduce(
            (grouped: OrderStockOut[], current: StockOut) => {
                const customerOrderNumber: string | undefined =
                    load.orderLoadingStatuses.find(
                        (ols) => ols.id === current.orderLoadingStatusId
                    )?.order.customerOrderNumber;

                if (!customerOrderNumber)
                    throw Error(
                        `Unable to match stock out ${current.id} with order on load ${load.id}`
                    );

                const stockOutsForOrder: OrderStockOut | undefined =
                    grouped.find(
                        (g) => g.customerOrderNumber === customerOrderNumber
                    );

                stockOutsForOrder
                    ? stockOutsForOrder.stockOuts.push(current)
                    : grouped.push({
                          customerOrderNumber: customerOrderNumber,
                          stockOuts: [current],
                      });
                return grouped;
            },
            []
        );

        return groupedStockOuts;
    }, [loadStockOuts, load]);

    return (
        <Modal
            modalClassName={classes.stockOutModal}
            isOpen={isOpen}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>{load.loadName} Stock Outs</h5>
            </ModalHeader>
            <ModalBody className={classes.stockOutModalBody}>
                <div className={classes.stockOutTableContainer}>
                    <StockOutTable
                        orderStockOuts={orderStockOuts}
                        loading={loading}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} styleType="secondary">
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default StockOutModal;
