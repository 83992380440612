import * as React from 'react';

import Center from '../common/Center';
import SingleColumnRow from '../common/SingleColumnRow';
import Icon, { IconType } from './Icon';
import { useClasses } from './NoDataFoundMessage.styles';

interface NoDataFoundMessageProps {
    messageText: string;
    iconType: IconType;
}

const NoDataFoundMessage: React.FC<NoDataFoundMessageProps> = ({
    messageText,
    iconType,
}) => {
    const classes = useClasses();

    return (
        <Center className={classes.noDataFoundContainer}>
            <SingleColumnRow>
                <Icon className={classes.noDataFoundIcon} type={iconType} />
            </SingleColumnRow>
            <SingleColumnRow className={classes.noDataFoundMessage}>
                {messageText}
            </SingleColumnRow>
        </Center>
    );
};

export default NoDataFoundMessage;
