import * as React from 'react';
import { useState, useMemo, useContext } from 'react';
import { CreateBooking } from '../../../models/bookings/BookingToAdd';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { useEffect } from 'react';
import { BookingType } from '../../../models/bookings/BookingType';
import { apiGet, useData } from '../../../services/api';
import {
    TrailerTypeResource,
    StoreResource,
    SiteResource,
} from '../../../models/bookings/StoreBookingResource';
import CheckboxListSelector from '../../../components/common/CheckboxListSelector';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import { isLoadingResourceApplicableForBookingType } from '../../../services/bookingService';

const BookingFormResourceContainer: React.FC<FormStepProps<CreateBooking>> = ({
    formObject: booking,
    onChange: onBookingChange,
}) => {
    const [minDuration, setMinDuration] = useState<number>(0);
    const { loadingResources } = useContext(LookupContext);

    const [storeResources, loadingStoreResources] = useData<StoreResource[]>(
        `Store/${booking.store}/Resources`
    );

    const [siteResources, loadingSiteResources] =
        useData<SiteResource[]>('SiteResource');

    const availableResourcesForStore = useMemo(() => {
        let availableResourcesForStore = loadingResources;
        if (storeResources && siteResources) {
            const unavailableStoreLoadingResources = [
                ...storeResources
                    .filter(
                        (r) =>
                            r.amount === 0 ||
                            !isLoadingResourceApplicableForBookingType(
                                r.resource,
                                booking.bookingType
                            )
                    )
                    .map((s) => s.resource),
            ];
            const unavailableSiteLoadingResources = siteResources
                .filter((r) => r.amount === 0)
                .map((r) => r.resource);
            const missingResources = loadingResources.filter(
                (r) =>
                    !(
                        storeResources.map((r) => r.resource).includes(r) ||
                        siteResources.map((r) => r.resource).includes(r)
                    )
            );

            const unavailableResources = [
                ...unavailableSiteLoadingResources,
                ...unavailableStoreLoadingResources,
                ...missingResources,
            ];

            availableResourcesForStore = availableResourcesForStore.filter(
                (r) => !unavailableResources.includes(r)
            );
        }
        return availableResourcesForStore;
    }, [loadingResources, storeResources, siteResources, booking.bookingType]);

    const availableResourcesConfirmed =
        !loadingSiteResources &&
        !loadingStoreResources &&
        siteResources &&
        storeResources;

    const handleBookingResourceChange = (
        selectedResources: LoadingResource[]
    ) => {
        const editedBooking: CreateBooking = {
            ...booking,
            resources: selectedResources,
        };
        onBookingChange(editedBooking);
    };

    const handleNoResourceSelectionChange = () => {
        const editedBooking: CreateBooking = {
            ...booking,
            resources: null,
            noResourcesSelected: !booking.noResourcesSelected,
        };
        onBookingChange(editedBooking);
    };

    const handleBookingDurationChange = (value: string | null) => {
        const duration = !!value ? parseInt(value) : 0;
        const editedBooking: CreateBooking = {
            ...booking,
            duration,
        };
        onBookingChange(editedBooking);
    };

    useEffect(
        () => {
            const setInternalOrDeliveryAndCollectionTrailerType = async () => {
                const trailerTypeResource: TrailerTypeResource = await apiGet(
                    `Store/${booking.store}/BookingType/${booking.bookingType}/TrailerType/${booking.trailerType}/Resources`
                );
                setMinDuration(trailerTypeResource.hourDuration);
                onBookingChange({
                    ...booking,
                    duration: trailerTypeResource.hourDuration,
                });
            };

            if (
                booking.bookingType === BookingType.Internal ||
                booking.bookingType === BookingType.DeliveryAndCollection
            ) {
                setInternalOrDeliveryAndCollectionTrailerType();
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <label htmlFor="BookingDurationInput">
                Booking Duration (hrs):
            </label>
            <TextInput
                error={!booking.duration ? 'Please enter a duration' : null}
                size="small"
                type="number"
                minValue={minDuration}
                value={booking.duration}
                onChange={handleBookingDurationChange}
                highlightOnFocus
            />
            {booking.bookingType !== BookingType.NoBookings &&
                (availableResourcesConfirmed ? (
                    <CheckboxListSelector
                        inline
                        label={'Resources: '}
                        columns={2}
                        availableItems={availableResourcesForStore}
                        selectedItems={booking.resources || []}
                        disabled={booking.noResourcesSelected}
                        onChange={handleBookingResourceChange}
                    />
                ) : (
                    <LoadingIndicator />
                ))}
            {booking.bookingType === BookingType.Internal &&
                availableResourcesConfirmed && (
                    <CheckboxListSelector
                        inline
                        label={''}
                        columns={2}
                        availableItems={['No Resources']}
                        selectedItems={
                            booking.noResourcesSelected ? ['No Resources'] : []
                        }
                        onChange={handleNoResourceSelectionChange}
                    />
                )}
        </>
    );
};

export default BookingFormResourceContainer;
