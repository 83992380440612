import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    overdue: {
        color: theme.colors.error,
    },
    updatedLink: {
        animation: '$updatedFade 2s',
        '& a': {
            animation: '$linkUpdatedFade 2s',
        },
    },
    destinationColumn: {
        minWidth: '250px',
    },
    statusColumn: {
        color: theme.colors.grey,
        minWidth: '100px',
    },
    numberCell: {
        textAlign: 'center',
    },
    '@keyframes updatedFade': {
        '0%': {
            backgroundColor: 'auto',
            color: 'auto',
        },
        '50%': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
        },
        '100%': {
            backgroundColor: 'auto',
            color: 'auto',
        },
    },
    '@keyframes linkUpdatedFade': {
        '0%': {
            color: 'auto',
        },
        '50%': {
            color: theme.colors.white,
        },
        '100%': {
            color: 'auto',
        },
    },
    defaultCell: {
        color: theme.colors.black,
    },
    referenceCell: {
        display: 'flex',
        alignContent: 'center',
    },
    piggybackCell: {
        padding: 0,
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    iconButton: {
        border: 'none',
        padding: 0,
    },
    stockOutIcon: {
        '--fa-primary-color': '#eec992',
        '--fa-primary-opacity': 1,
        '--fa-secondary-color': '#dda960',
        '--fa-secondary-opacity': 1,
        fontSize: '1.1rem',
    },
    expandIcon: {
        transition: 'transform 0.25s linear',
        fontSize: '0.9rem',
        '& path': {
            transition: 'fill 1s ease-in',
            fill: theme.colors.darkGrey,
        },
    },
    expandIconExpand: {
        transform: 'rotate(0deg)',
    },
    expandIconCollapse: {
        transform: 'rotate(180deg)',
        '& path': {
            fill: theme.colors.primary,
        },
    },
    orderRow: {
        padding: '0px 1rem',
        transition: 'all 0.5s ease-out',
    },
    orderRowCollapsed: {
        maxHeight: 0,
        overflow: 'hidden',
        opacity: 0,
    },
    orderRowExpanded: {
        maxHeight: '100rem',
        overflow: 'auto',
        padding: '0px 1rem',
        animation: '$orderRowExpand 1s',
    },
    orderTableContent: {
        backgroundColor: '#f8f8f8',
        padding: '0 !important',
    },
    orderTableContentExpanded: {
        padding: '0 0 0.5rem 0 !important',
    },
    '@keyframes orderRowExpand': {
        '0%': {
            overflow: 'hidden',
        },
        '95%': {
            overflow: 'auto',
        },
    },
}));
