import { Notification } from '.';
import { SMSTemplate } from '../smsTemplates/SMSTemplate';
import { Change } from '../updates/Change';

interface ChangesWithAffectedSMSTemplates {
    affectedSMSTemplates: SMSTemplate[];
    changes: Change[];
}

export const SMSTemplateUpdatedNotification: Notification<ChangesWithAffectedSMSTemplates> = {
    message: 'SMSTemplateUpdated'
};

export const SMSTemplateRemovedNotification: Notification<string> = {
    message: 'SMSTemplateRemoved'
};
