import { startOfMinute } from 'date-fns';
import * as React from 'react';
import { useReducer, useState } from 'react';

import ErrorModal from '../../../components/common/ErrorModal';
import Icon from '../../../components/common/Icon';
import StoreSelector from '../../../components/common/store/StoreSelector';
import StockTransferOrderSelector from '../../../components/orders/StockTransferOrderSelector';
import Button from '../../../lib/bootstrap-ui/Button';
import { Load } from '../../../models/loads/Load';
import { LoadType } from '../../../models/loads/LoadType';
import { StockTransferOrder } from '../../../models/orders/StockTransferOrder';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import CreateStockTransferFormReducer from '../../../reducers/CreateStockTransferFormReducer';
import { apiPost } from '../../../services/api';
import { useClasses } from './StockTransferFormContainer.styles';

interface StockTransferFormContainerProps {
    onStockTransferCreated(loadId: string): void;
}

const StockTransferFormContainer: React.FC<StockTransferFormContainerProps> = ({
    onStockTransferCreated,
}) => {
    const classes = useClasses();

    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [stockTransferToCreate, updateStockTransferToCreate] = useReducer(
        CreateStockTransferFormReducer,
        {
            loadType: null,
            trailerType: 'Single',
            sourceStore: null,
            destinationStore: null,
            loadByTime: null,
            orders: [],
            invalidOrderAttached: false,
        }
    );

    const handleStoreChange = (
        type: 'Source' | 'Destination',
        value: StoreNumber
    ) => {
        if (type === 'Source') {
            updateStockTransferToCreate({
                type: 'SET_SOURCE_STORE',
                payload: value,
            });
            return;
        }
        updateStockTransferToCreate({
            type: 'SET_DESTINATION_STORE',
            payload: value,
        });
    };

    const handleOrdersChange = (
        selectedOrders: StockTransferOrder[],
        invalidOrderSelected
    ) => {
        updateStockTransferToCreate({
            type: 'SET_ORDERS',
            payload: {
                orders: selectedOrders,
                invalidOrderAttached: invalidOrderSelected,
            },
        });
    };

    const handleSubmitClicked = async () => {
        try {
            setSubmitting(true);
            const createStockTransferRequest = {
                ...stockTransferToCreate,
                loadByTime: startOfMinute(new Date()),
                orderIds: stockTransferToCreate.orders.map((order) => order.id),
            };
            const createdStockTransfer: Load = await apiPost(
                'Load',
                createStockTransferRequest
            );
            onStockTransferCreated(createdStockTransfer.id);
        } catch (error) {
            setSubmitting(false);
            setError(true);
        }
    };

    const handleErrorDismiss = () => {
        setError(false);
    };

    const isStockTransferValid: boolean =
        stockTransferToCreate.orders.length > 0 &&
        !!stockTransferToCreate.sourceStore &&
        !!stockTransferToCreate.destinationStore;

    return (
        <div className={classes.stockTransferFormContainer}>
            <h2>
                Create{' '}
                {stockTransferToCreate.loadType === LoadType.Tunnel
                    ? 'Tunnel '
                    : 'Stock '}
                Transfer
            </h2>
            <div className={classes.formContent}>
                <StoreSelector
                    sourceStore={stockTransferToCreate.sourceStore}
                    destinationStore={stockTransferToCreate.destinationStore}
                    onStoreSelected={handleStoreChange}
                />
                <hr />
                <h4>Attach Orders</h4>
                <StockTransferOrderSelector
                    containerClassName={classes.orderSelectorContainer}
                    disabled={!stockTransferToCreate.sourceStore}
                    selectedOrders={stockTransferToCreate.orders}
                    store={stockTransferToCreate.sourceStore!}
                    onOrdersChange={handleOrdersChange}
                />
                <hr />
                <div className={classes.buttonContainer}>
                    <Button
                        onClick={handleSubmitClicked}
                        disabled={submitting || !isStockTransferValid}
                    >
                        Create{' '}
                        {stockTransferToCreate.loadType === LoadType.Tunnel
                            ? 'Tunnel '
                            : 'Stock '}
                        Transfer
                        <Icon
                            className={classes.buttonIcon}
                            type="chevron-right"
                        />
                    </Button>
                </div>
            </div>
            {error && (
                <ErrorModal
                    showModal={error}
                    header="Error creating Stock Transfer"
                    errorText="There was an error creating the Stock Transfer. Please try again. If this error persists, please contact IT."
                    onClose={handleErrorDismiss}
                />
            )}
        </div>
    );
};

export default StockTransferFormContainer;
