import * as React from 'react';
import { BookingType } from '../../models/bookings/BookingType';
import TimeSlotSelectionContainer from './TimeSlotSelectionContainer';
import TimeSlot, {
    TimeSlotUnavailableReason
} from '../../models/timeSlot/TimeSlot';
import { useEffect, useState } from 'react';
import { useData } from '../../services/api';
import { formatToUtc } from '../../services/timeStringService';
import { getTimeSlotsOverPeriodUrl } from '../../services/timeSlotService';
import { LoadingResource } from '../../models/bookings/LoadingResource';
import { TrailerType } from '../../models/trailers/TrailerType';
import { StoreNumber } from '../../models/stores/StoreNumber';

interface RescheduleTimeSlotContainerProps {
    allowOverrideFullTimeSlots?: boolean;
    bookingType: BookingType;
    duration: number;
    excludedBookingIds: string[];
    resources: LoadingResource[];
    selectedTimeSlot: TimeSlot | null;
    store: StoreNumber;
    trailerType: TrailerType;

    onTimeSlotSelected(selectedTimeSlot: TimeSlot): void;
}

const RescheduleTimeSlotContainer: React.FC<RescheduleTimeSlotContainerProps> = ({
    allowOverrideFullTimeSlots = false,
    bookingType,
    duration,
    excludedBookingIds,
    resources,
    selectedTimeSlot,
    store,
    trailerType,
    onTimeSlotSelected
}) => {
    const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(selectedTimeSlot);

    const [currentTimeSlots] = useData<TimeSlot[]>(
        selectedTimeSlot
            ? getTimeSlotsOverPeriodUrl({
                  bookingType,
                  checkResourceAvailability: true,
                  duration: 1,
                  excludedBookingIds,
                  utcStart: formatToUtc(selectedTimeSlot.start),
                  utcEnd: formatToUtc(selectedTimeSlot.end),
                  resources,
                  store,
                  trailerType
              })
            : null
    );

    const timeSlotStart = selectedTimeSlot ? selectedTimeSlot.start : null;
    const timeSlotEnd = selectedTimeSlot ? selectedTimeSlot.end : null;

    useEffect(() => {
        if (currentTimeSlots && timeSlotStart && timeSlotEnd) {
            const isAvailable = currentTimeSlots.every(t => t.isAvailable);

            const unavailableReasons: TimeSlotUnavailableReason[] = !isAvailable
                ? currentTimeSlots.find(t => !t.isAvailable)!.unavailableReasons
                : ['No Reason'];

            const editedTimeSlot: TimeSlot = {
                start: timeSlotStart,
                end: timeSlotEnd,
                isAvailable,
                unavailableReasons
            };
            setTimeSlot(editedTimeSlot);
        }
    }, [currentTimeSlots, timeSlotStart, timeSlotEnd]);

    return (
        <TimeSlotSelectionContainer
            allowOverrideFullTimeSlots={allowOverrideFullTimeSlots}
            excludedBookingIds={excludedBookingIds}
            duration={duration}
            resources={resources}
            store={store}
            trailerType={trailerType}
            bookingType={bookingType}
            selectedTimeSlot={timeSlot}
            onTimeSlotSelected={onTimeSlotSelected}
        />
    );
};

export default RescheduleTimeSlotContainer;
