import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    dateHeading: {
        borderTop: '0 !important',
        position: 'sticky',
        top: '0',
        backgroundColor: theme.colors.white,
        zIndex: 1,
    },
}));
