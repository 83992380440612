import * as React from 'react';
import { Load } from '../../../../models/loads/Load';
import EditableCell from '../../../common/table/EditableCell';
import HighlightableTableCell from '../../../common/table/HighlightableTableCell';
import { Link } from 'react-router-dom';
import { routeFor, RouteKey } from '../../../../Routes/routes';

interface BayOrPagerNumberCellProps {
    className?: string;
    editBayOrPagerNumber: boolean;
    bayOrPagerNumber: number | null;
    load: Load;
    showEditButton: boolean;
    enableVirtualBayLink: boolean;
    previousValue?: number;
    highlightUpdates?: boolean;
    onEditButtonClick(): void;
    onChange(value: string | null): void;
    onSubmit(): void;
}

const BayOrPagerNumberCell: React.FC<BayOrPagerNumberCellProps> = ({
    className,
    editBayOrPagerNumber,
    bayOrPagerNumber,
    load,
    showEditButton,
    enableVirtualBayLink,
    previousValue,
    highlightUpdates = false,
    onEditButtonClick,
    onChange,
    onSubmit,
}) =>
    !load.isPreDropped ? (
        <EditableCell
            className={className}
            edit={editBayOrPagerNumber}
            value={bayOrPagerNumber}
            type="number"
            showEditButton={showEditButton}
            previousValue={previousValue}
            highlightUpdates={highlightUpdates}
            onEditButtonClick={onEditButtonClick}
            onChange={onChange}
            onSubmit={onSubmit}
            onEnterPressed={onSubmit}
        />
    ) : (
        <HighlightableTableCell highlight={previousValue !== load.bayId}>
            {enableVirtualBayLink ? (
                <Link to={routeFor(RouteKey.Release)({ id: load.id })}>
                    {load.bayId}
                </Link>
            ) : (
                load.bayId
            )}
        </HighlightableTableCell>
    );

export default BayOrPagerNumberCell;
