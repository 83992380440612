import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IodDetailsContainer from '../../containers/iod/iodDetails/IodDetailsContainer';
import Page from '../common/Page';

const IodDetails: React.FC = () => {
    const { iodNumber } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Page>
            <IodDetailsContainer
                iodNumber={iodNumber!}
                selectedLoadId={
                    location?.search?.split('?selectedLoad=')[1] ?? null
                }
                onBackButtonClick={() => navigate(-1)}
            />
        </Page>
    );
};

export default IodDetails;
