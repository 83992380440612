import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';

const useClasses = buildClassesHook({
    container: {
        padding: 10,
        position: 'relative',
        letterSpacing: '1px',
        textAlign: 'center',
    },
    indicator: {
        margin: '2px auto 0',
        width: 10,
        height: 1,
        backgroundColor: '#785681',
        animation: '$slide 4s infinite',
    },
    '@keyframes slide': {
        '0%': {
            transform: 'translate(-25px, 0)',
        },
        '50%': {
            transform: 'translate(25px, 0)',
        },
        '100%': {
            transform: 'translate(-25px, 0)',
        },
    },
});

interface LoadingIndicatorProps {
    className?: string;
    children?: React.ReactNode;
    text?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
    className,
    text,
}) => {
    const classes = useClasses();

    return (
        <div className={classNames(classes.container, className)}>
            <div>{text || 'loading'}</div>
            <div className={classes.indicator} />
        </div>
    );
};

export default LoadingIndicator;
