import { WorkQueueTableModal } from '../containers/workQueue/WorkQueueTableContainer';
import { LoadType } from '../models/loads/LoadType';
import { Load } from '../models/loads/Load';
import { getPiggybackedPalletCountForLoad } from './loadService';
import { LoadStatus } from '../models/loads/LoadStatus';

const getAvailableOptions = (
    load: Load,
    piggybackFeature: boolean,
    updateTransferOrdersFeature: boolean,
    cancelNoIodTransferFeature: boolean
): WorkQueueTableModal[] => {
    let loadOptions: WorkQueueTableModal[] = [];

    const hasLoadOrFinishingTrailerBeenReleased =
        load.hasBeenReleased ||
        (load.associatedIod?.hasFinishingTrailerBeenReleased ?? false);

    const canBePiggybacked =
        piggybackFeature &&
        !hasLoadOrFinishingTrailerBeenReleased &&
        (load.loadType === LoadType.StockTrailer ||
            load.loadType === LoadType.TopUp ||
            load.loadType === LoadType.TrailerStart);

    if (canBePiggybacked) {
        loadOptions = [...loadOptions, 'addPiggybackOrders'];

        if (load.loadType === LoadType.StockTrailer) {
            loadOptions = [...loadOptions, 'piggybackTransfer'];
        }

        if (getPiggybackedPalletCountForLoad(load)) {
            loadOptions = [...loadOptions, 'removePiggybackOrders'];
        }
    }

    const canRemoveOrders =
        updateTransferOrdersFeature &&
        !hasLoadOrFinishingTrailerBeenReleased &&
        load.isStockTrailer &&
        !load.isCancelled &&
        load.status !== LoadStatus.Completed &&
        load.orderLoadingStatuses?.length > 1;

    if (canRemoveOrders) {
        loadOptions = [...loadOptions, 'removeOrders'];
    }

    if (load.isNoIodStockTrailer) {
        if (updateTransferOrdersFeature) {
            loadOptions = [...loadOptions, 'updateNonIodStockTransferOrders'];
        }
        if (cancelNoIodTransferFeature) {
            loadOptions = [...loadOptions, 'cancelLoad'];
        }
    }

    if (load.isReleased) {
        loadOptions = [...loadOptions, 'managePickLists'];
    }

    return loadOptions;
};

export { getAvailableOptions };
