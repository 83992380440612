import * as React from 'react';
import Bay from '../../../models/bays/Bay';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';

interface BayStatusOptionsSelectorProps {
    bay: Bay;
    onBayStatusChange: (bayStatusOptions: BayStatusOptions) => void;
}

export interface BayStatusOptions {
    isOutOfOrder: boolean;
    isVirtualBay: boolean;
}

const BayStatusOptionsSelector: React.FC<BayStatusOptionsSelectorProps> = ({
    bay,
    onBayStatusChange
}) => {
    const handleOutOfOrderValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const isOutOfOrder: boolean = event.target.checked;
        const updatedBayStatus: BayStatusOptions = {
            isOutOfOrder,
            isVirtualBay: bay.isVirtualBay
        };
        onBayStatusChange(updatedBayStatus);
    };

    const handleVirtualBayValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const isVirtualBay: boolean = event.target.checked;
        const updatedBayStatus: BayStatusOptions = {
            isOutOfOrder: bay.isOutOfOrder,
            isVirtualBay
        };
        onBayStatusChange(updatedBayStatus);
    };

    return (
        <>
            <Checkbox
                checked={bay.isVirtualBay}
                label="Virtual Bay"
                value="isVirtualBay"
                onChange={handleVirtualBayValueChange}
            />
            <Checkbox
                checked={bay.isOutOfOrder}
                label="Out Of Order"
                value="isOutOfOrder"
                onChange={handleOutOfOrderValueChange}
            />
        </>
    );
};

export default BayStatusOptionsSelector;
