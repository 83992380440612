import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import ManagementTable from '../../../components/admin/thirdPartyManagement/view/ManagementTable';
import SingleColumnRow from '../../../components/common/SingleColumnRow';
import { useNotificationListener } from '../../../hooks/useNotificationListener';
import usePersistedState from '../../../hooks/usePersistedState';
import Button from '../../../lib/bootstrap-ui/Button';
import DropdownOption from '../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import {
    CustomerAddedNotification,
    CustomerRemovedNotification,
    CustomerUpdatedNotification,
} from '../../../models/notifications/Customer';
import {
    HaulierAddedNotification,
    HaulierRemovedNotification,
    HaulierUpdatedNotification,
} from '../../../models/notifications/Haulier';
import {
    SupplierCodeAddedNotification,
    SupplierCodeRemovedNotification,
    SupplierCodeUpdatedNotification,
} from '../../../models/notifications/SupplierCode';
import {
    NewThirdPartyValueAdded,
    ThirdPartyValueUpdated,
} from '../../../models/notifications/ThirdPartyValue';
import ThirdPartyValue from '../../../models/thirdPartyValues/ThirdPartyValue';
import { apiGet } from '../../../services/api';
import AddModalContainer from './AddModalContainer';
import DeleteModalContainer from './DeleteModalContainer';
import EditModalContainer from './EditModalContainer';
import { useClasses } from './ThirdPartyManagementContainer.styles';
import { getSelectedOptionSuffix } from './ThirdPartyManagementHelper';

export type SelectableValueType = 'Customer' | 'Haulier' | 'SupplierCode';
export type ModalType = 'add' | 'edit' | 'delete';

const dropdownOptions = [
    {
        label: 'Haulier',
        value: 'Haulier',
    },
    {
        label: 'Customer',
        value: 'Customer',
    },
    {
        label: 'Supplier Code',
        value: 'SupplierCode',
    },
];

const ThirdPartyManagementContainer: React.FC = () => {
    const classes = useClasses();

    const [selectedOption, setSelectedOption] =
        usePersistedState<DropdownOption>(
            dropdownOptions[0],
            'third-party-management-value'
        );
    const [values, setValues] = useState<ThirdPartyValue[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [modalToShow, setModalToShow] = useState<ModalType | null>(null);
    const [valueToChange, setValueToChange] = useState<ThirdPartyValue | null>(
        null
    );

    const handleOptionSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = dropdownOptions.find(
            (d) => d.value === e.target.value
        );
        if (selectedOption) {
            setSelectedOption(selectedOption);
        }
        setFilter('');
    };

    const createStringPropertyComparer = <T,>(
        propertyName: string
    ): ((a: T, b: T) => number) => {
        return (a, b) => {
            const propA = a[propertyName];
            const propB = b[propertyName];
            return propA.localeCompare(propB, undefined, {
                sensitivity: 'accent',
            });
        };
    };

    const compareValues =
        createStringPropertyComparer<ThirdPartyValue>('value');

    useEffect(() => {
        const fetchValues = async () => {
            setLoading(true);
            const fetchedValues: ThirdPartyValue[] = await apiGet(
                selectedOption.value
            );
            setValues(fetchedValues.sort(compareValues));
            setLoading(false);
        };

        fetchValues();
        // eslint-disable-next-line
    }, [selectedOption]);

    const filteredValues = useMemo(() => {
        const newFilteredValues = values.filter((v) =>
            v.value.toUpperCase().trim().includes(filter.toUpperCase().trim())
        );
        return newFilteredValues;
    }, [filter, values]);

    const handleFilterChange = (value: string | null) => {
        const newFilter = value ?? '';
        setFilter(newFilter);
    };

    const handleValueAdded = (
        thirdPartyAddedValue: NewThirdPartyValueAdded,
        valueType: SelectableValueType
    ) => {
        if (selectedOption.value === valueType) {
            const addedValue = thirdPartyAddedValue.valueAdded;
            setValues((prev) => [...prev, addedValue].sort(compareValues));
        }
    };

    const handleValueUpdated = (
        thirdPartyUpdatedValue: ThirdPartyValueUpdated,
        valueType: SelectableValueType
    ) => {
        if (selectedOption.value === valueType) {
            const updatedValue = thirdPartyUpdatedValue.valueUpdated;
            const updatedValues = values
                .map((v) => (v.id === updatedValue.id ? updatedValue : v))
                .sort(compareValues);
            setValues(updatedValues);
        }
    };

    const handleValueDeleted = (
        deletedId: number,
        valueType: SelectableValueType
    ) => {
        if (selectedOption.value === valueType) {
            setValues(values.filter((v) => v.id !== deletedId));
        }
    };

    const handleModalOpened = (
        thirdPartyValue: ThirdPartyValue,
        modalType: ModalType
    ) => {
        setValueToChange(thirdPartyValue);
        setModalToShow(modalType);
    };

    useNotificationListener(CustomerAddedNotification, (customerAddedEvent) =>
        handleValueAdded(customerAddedEvent, 'Customer')
    );
    useNotificationListener(
        CustomerRemovedNotification,
        (customerDeletedEvent) =>
            handleValueDeleted(Number(customerDeletedEvent), 'Customer')
    );
    useNotificationListener(
        CustomerUpdatedNotification,
        (customerUpdatedEvent) =>
            handleValueUpdated(customerUpdatedEvent, 'Customer')
    );

    useNotificationListener(HaulierAddedNotification, (haulierAddedEvent) =>
        handleValueAdded(haulierAddedEvent, 'Haulier')
    );
    useNotificationListener(HaulierRemovedNotification, (haulierDeletedEvent) =>
        handleValueDeleted(Number(haulierDeletedEvent), 'Haulier')
    );
    useNotificationListener(HaulierUpdatedNotification, (haulierUpdatedEvent) =>
        handleValueUpdated(haulierUpdatedEvent, 'Haulier')
    );

    useNotificationListener(
        SupplierCodeAddedNotification,
        (supplierCodeAddedEvent) =>
            handleValueAdded(supplierCodeAddedEvent, 'SupplierCode')
    );
    useNotificationListener(
        SupplierCodeRemovedNotification,
        (supplierCodeDeletedEvent) =>
            handleValueDeleted(Number(supplierCodeDeletedEvent), 'SupplierCode')
    );
    useNotificationListener(
        SupplierCodeUpdatedNotification,
        (supplierCodeUpdatedEvent) =>
            handleValueUpdated(supplierCodeUpdatedEvent, 'SupplierCode')
    );

    const resetModalToShow = () => setModalToShow(null);

    return (
        <>
            <Grid fluid>
                <Row>
                    <Column size={12} className={classes.formContainer}>
                        <Row className={classes.selectorRow}>
                            <Column size={4}>
                                <SelectDropdown
                                    defaultValue="Select..."
                                    className={classes.valueTypeSelector}
                                    dropdownOptions={dropdownOptions.map(
                                        (d) => ({
                                            ...d,
                                            label: `${d.label}s`,
                                        })
                                    )}
                                    selectedOption={selectedOption.value}
                                    onChange={handleOptionSelected}
                                />
                            </Column>
                            <Column size={4}>
                                <TextInput
                                    className={classes.filter}
                                    placeholder={`${getSelectedOptionSuffix(
                                        selectedOption
                                    )}...`}
                                    size="small"
                                    value={filter}
                                    onChange={handleFilterChange}
                                    autoTrim
                                />
                            </Column>
                            <Column
                                size={4}
                                align="right"
                                className={classes.contentAlignEndColumn}
                            >
                                <Button
                                    onClick={() => setModalToShow('add')}
                                    styleType="primary"
                                >
                                    Add {selectedOption.label}
                                </Button>
                            </Column>
                        </Row>
                        <SingleColumnRow>
                            <ManagementTable
                                thirdPartyValues={filteredValues}
                                loading={loading}
                                valueTitle={selectedOption.label}
                                onModalSelected={handleModalOpened}
                            />
                        </SingleColumnRow>
                    </Column>
                </Row>
            </Grid>
            {modalToShow === 'add' && (
                <AddModalContainer
                    showModal={modalToShow === 'add'}
                    selectedOption={selectedOption}
                    onClose={() => resetModalToShow()}
                />
            )}
            {!!valueToChange && modalToShow === 'delete' && (
                <DeleteModalContainer
                    showModal={modalToShow === 'delete'}
                    selectedOption={selectedOption}
                    thirdPartyValue={valueToChange}
                    onClose={() => resetModalToShow()}
                />
            )}
            {!!valueToChange && modalToShow === 'edit' && (
                <EditModalContainer
                    showModal={modalToShow === 'edit'}
                    selectedOption={selectedOption}
                    thirdPartyValue={valueToChange}
                    onClose={() => resetModalToShow()}
                />
            )}
        </>
    );
};

export default ThirdPartyManagementContainer;
