import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { RouteKey, routeFor } from '../../Routes/routes';
import BookingFormContainer from '../../containers/bookings/bookingForm/BookingFormContainer';
import { BookingType } from '../../models/bookings/BookingType';
import Page from '../common/Page';

const BookingForm: React.FC = () => {
    const { bookingType } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <BookingFormContainer
                bookingType={bookingType as BookingType}
                onBookingConfirmation={(id: string) =>
                    navigate(routeFor(RouteKey.BookingSummary)({ id }))
                }
            />
        </Page>
    );
};

export default BookingForm;
