import * as React from 'react';
import { formatDate } from '../../helpers/dates';
import { buildClassesHook } from '../../helpers/styles';
import Table from '../../lib/bootstrap-ui/Table';
import { ThirdPartyCollectionOrder } from '../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import { theme } from '../../theme';
import Icon from '../common/Icon';
import SingleColumnRow from '../common/SingleColumnRow';

const useClasses = buildClassesHook({
    orderTableRow: {
        paddingRight: 0,
        marginTop: 10,
    },
    tableRowColumn: {
        paddingRight: 0,
    },
    orderTable: {
        '& thead th': {
            padding: '0.25rem 0',
            fontWeight: 500,
        },
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        maxHeight: 200,
        overflow: 'auto',
        '& thead': {
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
        },
        '& tbody': {
            display: 'block',
        },
        '& tr': {
            width: '100%',
            display: 'table',
            tableLayout: 'fixed',
            wordBreak: 'break-word',
        },
    },
    noOrdersText: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.5rem',
        color: theme.colors.mediumDarkGrey,
    },
    dollyIcon: {
        fontSize: '2rem',
        marginTop: '0.5rem',
    },
});

interface BookingOrderTableProps {
    thirdPartyCollectionOrders?: ThirdPartyCollectionOrder[] | null;
}

const BookingOrderTable: React.FC<BookingOrderTableProps> = ({
    thirdPartyCollectionOrders,
}) => {
    const classes = useClasses();

    const sortOrders = (
        order1: ThirdPartyCollectionOrder,
        order2: ThirdPartyCollectionOrder
    ) => {
        if (order1.deliveryDate < order2.deliveryDate) {
            return -1;
        } else if (order1.deliveryDate > order2.deliveryDate) {
            return 1;
        }
        return 0;
    };

    const orderedThirdPartyCollectionOrders = thirdPartyCollectionOrders
        ? thirdPartyCollectionOrders.sort(sortOrders)
        : null;

    return (
        <SingleColumnRow
            className={classes.orderTableRow}
            columnClassName={classes.tableRowColumn}
        >
            <Table
                className={classes.orderTable}
                headers={[
                    'Order Number',
                    'Cust. Order Number',
                    'Pallets',
                    'Order',
                    'Delivery',
                ]}
                hoverable
            >
                {orderedThirdPartyCollectionOrders?.map((o, i) => (
                    <tr key={i}>
                        <td style={{ padding: 0 }}>{o.orderNumber}</td>
                        <td style={{ padding: 0 }}>{o.customerOrderNumber}</td>
                        <td style={{ padding: 0 }}>{o.palletSpaces}</td>
                        <td style={{ padding: 0 }}>
                            {formatDate(o.orderDate, 'dd/MM')}
                        </td>
                        <td style={{ padding: 0 }}>
                            {formatDate(o.deliveryDate, 'dd/MM')}
                        </td>
                    </tr>
                ))}
            </Table>
            {!orderedThirdPartyCollectionOrders && (
                <div className={classes.noOrdersText}>
                    <Icon
                        className={classes.dollyIcon}
                        type="person-dolly-empty"
                    />
                    No orders attached
                </div>
            )}
        </SingleColumnRow>
    );
};

export default BookingOrderTable;
