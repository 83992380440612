import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    progressSummaryContainer: {
        display: 'flex',
        height: '100%',
        flexFlow: 'column nowrap',
    },
    progressSummaryTableContainer: {
        height: '100%',
        whiteSpace: 'nowrap',
        '& thead th': {
            top: '4.5rem',
        },
    },
});
