import * as React from 'react';

class Delay extends React.Component<
    { children: React.ReactNode; ms: number },
    { display: boolean }
> {
    state = {
        display: false
    };

    timeout: NodeJS.Timeout;

    componentDidMount = () => {
        this.timeout = setTimeout(
            () => this.setState({ display: true }),
            this.props.ms
        );
    };

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
    };

    render = () => {
        if (!this.state.display) {
            return null;
        }
        return this.props.children;
    };
}

export default Delay;
