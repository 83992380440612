import { HttpErrorResponse } from '../models/common/HttpErrorResponse';

export const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) return error.message;

    const httpErrorMessage = (error as HttpErrorResponse)?.message;

    return (
        (Array.isArray(httpErrorMessage)
            ? httpErrorMessage.shift()
            : httpErrorMessage) ?? String(error)
    );
};
