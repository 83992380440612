import * as React from 'react';

import Table from '../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../lib/bootstrap-ui/Table/Table';
import { OrderLoadingStatus } from '../../../models/orders/OrderLoadingStatus';
import NoDataFoundTableRow from '../../common/table/NoDataFoundTableRow';
import { useClasses } from './PiggybackOrderRemovalTable.styles';

interface PiggybackOrderRemovalTableProps {
    orders: OrderLoadingStatus[];
}

const tableHeaders: (string | HeaderDetails)[] = [
    'IOD',
    'Job Number',
    'Customer Order',
    'Customer',
    'Pallets',
];

const PiggybackOrderRemovalTable: React.FC<PiggybackOrderRemovalTableProps> = ({
    orders,
}) => {
    const classes = useClasses();

    return (
        <Table
            className={classes.piggybackTable}
            headers={tableHeaders}
            placeholderRows={6}
            fixedHeader
        >
            {orders.length === 0 ? (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No Orders are to piggybacked on this Load"
                    iconType="no-loads"
                    columnSpan={tableHeaders.length}
                />
            ) : (
                orders.map((orderWithLoadInfo) => {
                    return (
                        <tr key={orderWithLoadInfo.order.id}>
                            <td>{orderWithLoadInfo.order.iodNumber}</td>
                            <td>{orderWithLoadInfo.order.orderNumber}</td>
                            <td>
                                {orderWithLoadInfo.order.customerOrderNumber}
                            </td>
                            <td>{orderWithLoadInfo.order.customerName}</td>
                            <td>{orderWithLoadInfo.order.palletSpaces}</td>
                        </tr>
                    );
                })
            )}
        </Table>
    );
};

export default PiggybackOrderRemovalTable;
