import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { buildClassesHook } from '../../helpers/styles';
import { Column, Row } from '../../lib/bootstrap-ui/Grid';

const useClasses = buildClassesHook({
    dataRow: {
        marginBottom: '0.5em',
        fontSize: '1.25rem',
        width: '100%',
    },
});

interface DataRowProps {
    label: string;
    loading?: boolean;
    children: React.ReactNode | (() => React.ReactNode);
}

const ReleaseDataRow: React.FC<Readonly<DataRowProps>> = ({
    label,
    loading,
    children,
}) => {
    const classes = useClasses();

    return (
        <Row className={classes.dataRow}>
            <Column sm={6}>
                <h5>{label}</h5>
            </Column>
            <Column sm={6}>
                {loading ? (
                    <Skeleton />
                ) : typeof children === 'function' ? (
                    children()
                ) : (
                    children
                )}
            </Column>
        </Row>
    );
};

export default ReleaseDataRow;
