import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Load } from '../../../models/loads/Load';
import { OrderWithLoadInfo } from '../../../models/orders/OrderWithLoadInfo';
import { apiPost, useData } from '../../../services/api';
import ErrorModal from '../../common/ErrorModal';
import LoadingIndicator from '../../loading/LoadingIndicator';
import OrderPalletTypeCounts from '../../orders/OrderPalletTypeCounts';
import OrderSelectionTable from '../tables/OrderSelectionTable';
import { useClasses } from './PiggybackModal.styles';

interface PiggybackOntoLoadModalProps {
    load: Load;
    isOpen: boolean;
    onClose(): void;
}

const PiggybackOntoLoadModal: React.FC<PiggybackOntoLoadModalProps> = ({
    load,
    isOpen,
    onClose,
}) => {
    const classes = useClasses();

    const [piggybackOrders, isLoading] = useData<OrderWithLoadInfo[]>(
        isOpen ? `Load/${load.id}/PiggybackOrders` : null
    );
    const [selectedOrders, setSelectedOrders] = useState<OrderWithLoadInfo[]>(
        []
    );
    const [error, setError] = useState<Error | null>(null);
    const [updateInProgress, setUpdateInProgress] = useState(false);

    const handlePiggybackOrdersOnLoad = async () => {
        try {
            setUpdateInProgress(true);
            await apiPost(`Load/${load.id}/Piggyback`, {
                loadId: load.id,
                orderIds: selectedOrders.map(
                    (orderWithLoadInfo) => orderWithLoadInfo.order.id
                ),
            });
            setUpdateInProgress(false);
            setSelectedOrders([]);
            onClose();
        } catch (error) {
            setUpdateInProgress(false);
            setError(error as Error);
        }
    };

    const handleOrderSelected = (order: OrderWithLoadInfo, add: boolean) => {
        if (add) {
            setSelectedOrders((prev) => [...prev, order]);
        } else {
            setSelectedOrders((prev) =>
                prev.filter(
                    (orderWithLoadInfo) =>
                        orderWithLoadInfo.order.id !== order.order.id
                )
            );
        }
    };

    const headerText = `Piggyback Orders on Load ${load.loadName}`;

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose}>
                <ModalHeader>
                    <h5>{headerText}</h5>
                </ModalHeader>
                {updateInProgress ? (
                    <LoadingIndicator text="Piggybacking Orders..." />
                ) : (
                    <>
                        <ModalBody>
                            <div
                                className={classNames(classes.tableContainer, {
                                    [classes.loading]: isLoading,
                                })}
                            >
                                <OrderSelectionTable
                                    loading={isLoading}
                                    noOrdersAvailableMessage="No Orders available to piggyback on this Load"
                                    orders={piggybackOrders || []}
                                    selectedOrderIds={selectedOrders.map(
                                        (orderWithLoadInfo) =>
                                            orderWithLoadInfo.order.id
                                    )}
                                    onOrderSelected={handleOrderSelected}
                                />
                                <OrderPalletTypeCounts
                                    ordersOnLoad={load.orderLoadingStatuses.map(
                                        (ols) => ols.order
                                    )}
                                    selectedOrders={selectedOrders.map(
                                        (o) => o.order
                                    )}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose} styleType="secondary">
                                Close
                            </Button>
                            <Button
                                styleType="primary"
                                disabled={selectedOrders.length === 0}
                                onClick={handlePiggybackOrdersOnLoad}
                            >
                                Piggyback
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Modal>
            <ErrorModal
                showModal={isOpen && !!error}
                header={headerText}
                errorText={`There was an error piggybacking selected Order${
                    selectedOrders.length > 1 ? 's' : ''
                } onto the Load ${load.loadName}`}
                onClose={() => setError(null)}
            />
        </>
    );
};

export default PiggybackOntoLoadModal;
