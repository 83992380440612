import classnames from 'classnames';
import * as React from 'react';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { pluralise } from '../../../helpers/stringFunctions';
import Card, {
    CardBody,
    CardText,
    CardTitle,
} from '../../../lib/bootstrap-ui/Card';
import { LoadRole } from '../../../models/iods/IodPlan';
import { Load } from '../../../models/loads/Load';
import { LoadGroup } from '../../../models/loads/LoadGroup';
import { LoadType } from '../../../models/loads/LoadType';
import { groupLoadsByLoadType } from '../../../services/iodPlanService';
import {
    getPalletCountToRemoveForLoad,
    getPalletsToPickForIod,
    getPiggybackedPalletCountForLoad,
} from '../../../services/loadService';
import Center from '../../common/Center';
import LoadTimeRemaining from '../../time/LoadTimeRemaining';
import PartLoadReference from '../common/view/PartLoadReference';
import LoadStatus from '../table/view/LoadStatus';
import LoadDetailsPiggybackTooltip from './LoadDetailsPiggybackTooltip';
import { useClasses } from './LoadDetailsCard.styles';

interface LoadDetailsCardProps {
    className?: string;
    load: Load;
    loadsFromSameStore: LoadGroup;
    iodId: string | null;
    isSelected: boolean;
    role: LoadRole;
    resequence?: number;
    isFirstLoadInPlan?: boolean;
    numberOfOtherTransfersFromSameStore?: number;
}

type loadTypeToDisplay = 'Tunnel' | 'Third Party' | 'Transfer' | 'Piggyback';

function getLoadTypeToDisplay(
    load: Load,
    role: LoadRole
): loadTypeToDisplay | undefined {
    switch (load.loadType) {
        case LoadType.Tunnel:
            return 'Tunnel';
        case LoadType.ThirdParty:
            return 'Third Party';
        case LoadType.StockTrailer:
            return 'Transfer';
        default:
            return load.hasPiggybackedOrders && role === 'Transfer'
                ? 'Piggyback'
                : undefined;
    }
}

const loadTypeSummary = (
    loadsFromSameStore: LoadGroup,
    role: LoadRole
): JSX.Element => {
    if (role === 'Finishing-Trailer') {
        return <></>;
    }

    const loadTypeCounts = groupLoadsByLoadType(loadsFromSameStore).reduce<
        string[]
    >((rv, curr) => {
        const load = curr[0];
        const loadType = getLoadTypeToDisplay(load, 'Transfer');

        if (!loadType) return rv;

        return [
            ...rv,
            load.loadType === LoadType.Tunnel
                ? loadType
                : pluralise(loadType, curr.length),
        ];
    }, []);

    return (
        <>
            {loadTypeCounts.map((ltc, i) => (
                <CardText key={i}>{ltc}</CardText>
            ))}
        </>
    );
};

const LoadDetailsCard: React.FC<LoadDetailsCardProps> = ({
    className,
    load,
    loadsFromSameStore,
    iodId,
    isSelected,
    role,
    resequence,
    isFirstLoadInPlan,
}) => {
    const classes = useClasses();

    const piggybackOn: number = getPiggybackedPalletCountForLoad(load);
    const piggybackOff: number = getPalletCountToRemoveForLoad(load);

    const palletsToShow: number = getPalletsToPickForIod(load, iodId);
    const heavyPalletsToShow: number = getPalletsToPickForIod(
        load,
        iodId,
        true
    );

    const piggybackPopupPosition: PopupPosition[] = isFirstLoadInPlan
        ? ['left center']
        : ['bottom center'];

    return (
        <Card
            className={classnames('text-center', className, classes.card, {
                [classes.selectedFinishingTrailerCard]:
                    isSelected && role === 'Finishing-Trailer',
                [classes.selectedTransferCard]:
                    isSelected && role === 'Transfer',
                [classes.stackedCard]:
                    load.isMergedStockTrailer ||
                    loadsFromSameStore.length > 1 ||
                    piggybackOn > 0,
                [classes.transferCard]: role === 'Transfer',
                [classes.finishingTrailerLoadCard]:
                    role === 'Finishing-Trailer',
            })}
        >
            <CardBody className={classes.body}>
                <div className={classes.headerContainer}>
                    <CardText>{load.sourceStore}</CardText>
                    {!!piggybackOn && (
                        <>
                            <LoadDetailsPiggybackTooltip
                                load={load}
                                popupPosition={piggybackPopupPosition}
                            />
                        </>
                    )}
                    <PartLoadReference
                        className={classes.partLoadRef}
                        load={load}
                        displayAmendmentLink
                    />
                </div>
                <Center className={classes.bodyContainer}>
                    <CardTitle className={classes.loadByTime}>
                        <LoadTimeRemaining
                            loadByTime={load.loadByTime}
                            completedTime={load.completedAt}
                            useColors
                        />
                    </CardTitle>
                    <CardText className={classes.pallets}>
                        {pluralise('Pallet', palletsToShow)}
                        {!!heavyPalletsToShow && ` (${heavyPalletsToShow}H)`}
                        {!!piggybackOn && ` (+${piggybackOn})`}
                        {!!piggybackOff && ` (-${piggybackOff})`}
                    </CardText>
                    {!!resequence && (
                        <CardText>Resequence {resequence}</CardText>
                    )}
                    <div className={classes.statusContainer}>
                        <CardText className={classes.status}>
                            <LoadStatus load={load} hideIcon disableLinks />
                        </CardText>
                    </div>
                </Center>
                <div className={classes.footerContainer}>
                    {loadTypeSummary(loadsFromSameStore, role)}
                </div>
            </CardBody>
        </Card>
    );
};

export default LoadDetailsCard;
