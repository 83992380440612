import * as React from 'react';
import { useMemo, useState } from 'react';

import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Load } from '../../../models/loads/Load';
import { OrderWithLoadInfo } from '../../../models/orders/OrderWithLoadInfo';
import { apiPut } from '../../../services/api';
import ErrorModal from '../../common/ErrorModal';
import InformationMessage from '../../common/InformationMessage';
import LoadingIndicator from '../../loading/LoadingIndicator';
import OrderSelectionTable from '../../piggyback/tables/OrderSelectionTable';
import OrderPalletTypeCounts from '../OrderPalletTypeCounts';
import { useClasses } from './OrderRemovalModal.styles';

interface OrderRemovalModalProps {
    load: Load;
    isOpen: boolean;
    onClose: () => void;
}

const OrderRemovalModal: React.FC<OrderRemovalModalProps> = ({
    load,
    isOpen,
    onClose,
}) => {
    const classes = useClasses();

    const orders = useMemo(
        () =>
            load.orderLoadingStatuses?.map((ols) => ({
                order: ols.order,
                loadName: load.loadName,
                loadByTime: new Date(load.loadByTime),
            })),
        [load]
    );
    const [ordersToRemove, setOrdersToRemove] = useState<OrderWithLoadInfo[]>(
        []
    );

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

    const handleRemoveOrdersFromLoad = async () => {
        setUpdateInProgress(true);

        const remainingOrderIds = load.orderLoadingStatuses
            .filter(
                (ols) =>
                    !ordersToRemove.some((o) => o.order.id === ols.order.id)
            )
            .map((o) => o.orderId);

        try {
            await apiPut(`Load/${load.id}/Orders`, {
                orderIds: remainingOrderIds,
            });

            onClose();
        } catch {
            setShowErrorModal(true);
        } finally {
            setUpdateInProgress(false);
        }
    };

    const handleOrderSelected = (order: OrderWithLoadInfo, add: boolean) => {
        if (add) {
            setOrdersToRemove((prev) => [...prev, order]);
        } else {
            setOrdersToRemove((prev) =>
                prev.filter(
                    (orderWithLoadInfo) =>
                        orderWithLoadInfo.order.id !== order.order.id
                )
            );
        }
    };

    const orderText = ordersToRemove?.length === 1 ? 'order' : 'orders';
    const headerText = `Remove ${orderText} from Part Load ${load.loadName}?`;

    return (
        <>
            <Modal isOpen={isOpen}>
                <ModalHeader>
                    <h5>{headerText}</h5>
                </ModalHeader>
                {updateInProgress ? (
                    <LoadingIndicator text="Removing Orders..." />
                ) : (
                    <>
                        <ModalBody>
                            <div className={classes.tableContainer}>
                                <OrderSelectionTable
                                    loading={updateInProgress}
                                    noOrdersAvailableMessage="No orders available to remove from this Load"
                                    orders={orders || []}
                                    selectedOrderIds={ordersToRemove.map(
                                        (orderWithLoadInfo) =>
                                            orderWithLoadInfo.order.id
                                    )}
                                    onOrderSelected={handleOrderSelected}
                                />
                                <OrderPalletTypeCounts
                                    removeOrders
                                    ordersOnLoad={load.orderLoadingStatuses.map(
                                        (ols) => ols.order
                                    )}
                                    selectedOrders={ordersToRemove.map(
                                        (o) => o.order
                                    )}
                                />
                            </div>
                            <InformationMessage messageType="warning">
                                This will create a new stock transfer per IOD
                                for the removed orders
                            </InformationMessage>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose} styleType="secondary">
                                Close
                            </Button>
                            <Button
                                styleType="danger"
                                disabled={!ordersToRemove?.length}
                                onClick={handleRemoveOrdersFromLoad}
                            >
                                Remove
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Modal>
            <ErrorModal
                showModal={isOpen && showErrorModal}
                header={`Error removing ${orderText}`}
                errorText={`There was an error removing the ${orderText} from the Part Load`}
                onClose={() => setShowErrorModal(false)}
            ></ErrorModal>
        </>
    );
};

export default OrderRemovalModal;
