import * as React from 'react';

import Button from '../../../lib/bootstrap-ui/Button';
import Icon from '../Icon';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import HighlightableTableCell from './HighlightableTableCell';
import { useClasses } from './EditableCell.styles';

interface EditableCellProps {
    className?: string;
    edit: boolean;
    value: any;
    type?: 'text' | 'number' | 'email' | 'date';
    title?: string;
    showEditButton: boolean;
    previousValue?: any;
    highlightUpdates?: boolean;
    maxValue?: number;
    onEditButtonClick(): void;
    onChange(value: string | null): void;
    onSubmit(): void;
    onEnterPressed?(): void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    className,
    edit,
    value,
    type = 'text',
    title,
    showEditButton,
    previousValue,
    highlightUpdates = false,
    maxValue,
    onEditButtonClick,
    onChange,
    onSubmit,
    onEnterPressed,
}) => {
    const classes = useClasses();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onEnterPressed && onEnterPressed();
        }
    };

    return (
        <HighlightableTableCell
            className={className}
            highlight={previousValue !== value}
            title={title}
            highlightUpdates={highlightUpdates && !edit}
        >
            {!edit ? (
                <>
                    <div className={classes.valueContainer}>
                        <div className={classes.value}>{value}</div>
                    </div>
                    <Button
                        className={classes.editButton}
                        style={{
                            visibility: showEditButton ? 'visible' : 'hidden',
                        }}
                        element="button"
                        styleType="link"
                        stopOnClickPropagation
                        onClick={onEditButtonClick}
                    >
                        <Icon className={classes.linkIcon} type="pencil" />
                    </Button>
                </>
            ) : (
                <TextInput
                    autoFocus
                    className={classes.inputField}
                    size="small"
                    type={type}
                    value={value}
                    onChange={onChange}
                    onBlur={onSubmit}
                    onKeyDown={handleKeyDown}
                    maxValue={maxValue}
                    highlightOnFocus={type === 'number'}
                />
            )}
        </HighlightableTableCell>
    );
};

export default EditableCell;
