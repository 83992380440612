import { BayStatus } from '../models/bays/BayStatus';
import { LoadType } from '../models/loads/LoadType';
import { Trailer } from '../models/trailers/Trailer';

interface ReleaseValidationProps {
    bayStatuses: BayStatus[] | null;
    checkingBay: boolean;
    currentBayIdOfSelectedTrailer: number | null;
    isDetectedTrailerValid: boolean;
    isSelectedTrailerAtSelectedBay: boolean;
    selectedBayNumber: number | null;
    selectedTrailer: Trailer | null;
    fetchedTrailer: Trailer | null;
    trailerDetectedAtSelectedBay: Trailer | null;
    trailerType: string | null;
    loadType: string | null;
    hasTransportOnlyOrders: boolean;
    isReedBoardallLoad: boolean;
    onUseDetectedTrailer: () => void;
}

export interface ReleaseValidationObject {
    message: string;
    blockRelease: boolean;
    messageType?: 'info' | 'loading';
    detectedTrailerButtonLink?: () => void;
}

const checkingForTrailerAtBay = (
    checkingBay: boolean
): ReleaseValidationObject | null => {
    if (checkingBay) {
        return {
            message: 'Checking for trailer at bay...',
            blockRelease: false,
            messageType: 'loading',
        };
    }
    return null;
};

const selectedTrailerDetectedAtDifferentBay = (
    checkingBay: boolean,
    selectedTrailer: Trailer | null,
    currentBayIdOfSelectedTrailer: number | null,
    selectedBayNumber: number | null
): ReleaseValidationObject | null => {
    if (
        !checkingBay &&
        selectedTrailer?.id &&
        currentBayIdOfSelectedTrailer &&
        selectedBayNumber !== currentBayIdOfSelectedTrailer
    ) {
        return {
            message: `The selected trailer has been detected at bay ${currentBayIdOfSelectedTrailer}. Please ensure your selection is correct.`,
            blockRelease: false,
        };
    }
    return null;
};

const alternateTrailerDetectedAtSelectedBay = (
    checkingBay: boolean,
    trailerDetectedAtSelectedBay: Trailer | null,
    isSelectedTrailerAtSelectedBay: boolean,
    isDetectedTrailerValid: boolean,
    onUseDetectedTrailer: () => void
): ReleaseValidationObject | null => {
    if (
        !checkingBay &&
        trailerDetectedAtSelectedBay &&
        !isSelectedTrailerAtSelectedBay &&
        isDetectedTrailerValid
    ) {
        return {
            message: `Trailer ${
                trailerDetectedAtSelectedBay!.id
            } detected at this bay.`,
            blockRelease: false,
            detectedTrailerButtonLink: onUseDetectedTrailer,
        };
    }
    return null;
};

const incompatibleTrailerSelectedForLoad = (
    selectedTrailer: Trailer | null,
    trailerType: string | null,
    checkingBay: boolean
): ReleaseValidationObject | null => {
    if (
        selectedTrailer &&
        selectedTrailer.trailerType !== 'Unknown' &&
        trailerType !== selectedTrailer.trailerType &&
        !checkingBay
    ) {
        return {
            message: `Trailer ${
                selectedTrailer.id
            } has an incompatible trailer type for this part load. ${
                selectedTrailer!.trailerType
            } trailer selected.`,
            blockRelease: trailerType === 'Decker',
        };
    }
    return null;
};

const unknownTrailerSelected = (
    selectedTrailer: Trailer | null,
    isReedBoardallLoad?: boolean
): ReleaseValidationObject | null => {
    if (
        isReedBoardallLoad &&
        selectedTrailer &&
        selectedTrailer.trailerType === 'Unknown' &&
        selectedTrailer.id !== '' &&
        selectedTrailer.id
    ) {
        return {
            message: `Unknown trailer selected.`,
            blockRelease: true,
        };
    }
    return null;
};

const inactiveTrailerSelected = (
    selectedTrailer: Trailer | null,
    loadType: string | null
): ReleaseValidationObject | null => {
    if (
        selectedTrailer &&
        selectedTrailer.active === false &&
        selectedTrailer.trailerType !== 'Unknown'
    ) {
        if (loadType === 'Stock Trailer') {
            return {
                message:
                    'Selected trailer is VOR - please check before proceeding.',
                blockRelease: false,
            };
        } else {
            return {
                message: 'Selected trailer is VOR.',
                blockRelease: true,
            };
        }
    }
    return null;
};

const reedBoardallTrailerSelected = (
    selectedTrailer: Trailer | null,
    isReedBoardallLoad?: boolean
): ReleaseValidationObject | null => {
    if (
        !isReedBoardallLoad &&
        selectedTrailer &&
        selectedTrailer.trailerType !== 'Unknown' &&
        selectedTrailer.id !== null &&
        selectedTrailer.id !== 'PRE'
    ) {
        return {
            message: 'Reed Boardall trailer selected.',
            blockRelease: false,
        };
    }
    return null;
};

const iodAlreadyReleasedToBay = (
    selectedBayNumber: number | null,
    bayStatuses: BayStatus[] | null
): ReleaseValidationObject | null => {
    if (bayStatuses) {
        const bayStatus = bayStatuses.find((bay: { id: any }) => {
            return bay.id === selectedBayNumber;
        });

        if (bayStatus && bayStatus.releasedTo) {
            return {
                message: 'An IOD has already been released to this bay.',
                blockRelease: false,
            };
        }
    }
    return null;
};

const iodAlreadyReleasedToTrailer = (
    selectedTrailer: Trailer | null,
    bayStatuses: BayStatus[] | null
): ReleaseValidationObject | null => {
    if (bayStatuses && selectedTrailer) {
        const bayStatus = bayStatuses.find((bay: { trailerId: any }) => {
            return selectedTrailer.id === bay.trailerId;
        });

        if (bayStatus && bayStatus.releasedTo) {
            return {
                message: 'An IOD has already been released to this trailer.',
                blockRelease: true,
            };
        }
    }
    return null;
};

const trailerAlreadyAssignedBecauseOfTransportOnlyJobs = (
    hasTransportOnlyOrders: boolean,
    loadType: string | null,
    selectedTrailer: Trailer | null,
    fetchedTrailer: Trailer | null
): ReleaseValidationObject | null => {
    if (
        hasTransportOnlyOrders &&
        (loadType === LoadType.TrailerStart ||
            loadType === LoadType.FullLoad) &&
        fetchedTrailer != null &&
        selectedTrailer != null
    ) {
        if (
            JSON.stringify(fetchedTrailer) === JSON.stringify(selectedTrailer)
        ) {
            return {
                message: `Trailer has already been assigned because the IOD includes Transport Only jobs which should already be on trailer ${fetchedTrailer?.id}.`,
                blockRelease: false,
                messageType: 'info',
            };
        } else {
            return {
                message: `There are Transport Only jobs for this load which should already be on trailer ${fetchedTrailer?.id} - changing trailer number carries a risk that these jobs will be missed.`,
                blockRelease: false,
            };
        }
    }
    return null;
};

const getReleaseScreenWarningsAndErrors = ({
    bayStatuses,
    checkingBay,
    currentBayIdOfSelectedTrailer,
    isDetectedTrailerValid,
    isSelectedTrailerAtSelectedBay,
    selectedBayNumber,
    selectedTrailer,
    fetchedTrailer,
    trailerDetectedAtSelectedBay,
    trailerType,
    loadType,
    hasTransportOnlyOrders,
    isReedBoardallLoad,
    onUseDetectedTrailer,
}: ReleaseValidationProps) => {
    const releaseValidationObjects: (ReleaseValidationObject | null)[] = [
        checkingForTrailerAtBay(checkingBay),
        selectedTrailerDetectedAtDifferentBay(
            checkingBay,
            selectedTrailer,
            currentBayIdOfSelectedTrailer,
            selectedBayNumber
        ),
        alternateTrailerDetectedAtSelectedBay(
            checkingBay,
            trailerDetectedAtSelectedBay,
            isSelectedTrailerAtSelectedBay,
            isDetectedTrailerValid,
            onUseDetectedTrailer
        ),
        incompatibleTrailerSelectedForLoad(
            selectedTrailer,
            trailerType,
            checkingBay
        ),
        unknownTrailerSelected(selectedTrailer, isReedBoardallLoad),
        inactiveTrailerSelected(selectedTrailer, loadType),
        reedBoardallTrailerSelected(selectedTrailer, isReedBoardallLoad),
        iodAlreadyReleasedToBay(selectedBayNumber, bayStatuses),
        iodAlreadyReleasedToTrailer(selectedTrailer, bayStatuses),
        trailerAlreadyAssignedBecauseOfTransportOnlyJobs(
            hasTransportOnlyOrders,
            loadType,
            selectedTrailer,
            fetchedTrailer
        ),
    ];
    return releaseValidationObjects.filter(
        Boolean
    ) as ReleaseValidationObject[];
};

export { getReleaseScreenWarningsAndErrors };
