import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';

import InternalWorkOrderSelectionContainer from '../../containers/bookings/update/InternalWorkOrderSelectionContainer';
import { compareDatesAsc } from '../../helpers/dates';
import Button from '../../lib/bootstrap-ui/Button';
import { TextInput } from '../../lib/bootstrap-ui/Forms';
import Grid, { Column, Row } from '../../lib/bootstrap-ui/Grid';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../lib/bootstrap-ui/Modal';
import { Booking } from '../../models/bookings/Booking';
import { BookingType } from '../../models/bookings/BookingType';
import { InternalWorkOrder } from '../../models/orders/InternalWorkOrder';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { useData } from '../../services/api';
import {
    GetCustomerName,
    IsCustomerNameValid,
} from '../../services/bookingService';
import NameLookupByReference from '../common/NameLookupByReference';
import SingleColumnRow from '../common/SingleColumnRow';
import LoadingIndicator from '../loading/LoadingIndicator';
import { useClasses } from './UpdateThirdPartyOrdersModal.styles';

interface UpdateInternalWorkOrdersModalProps {
    modalHeader: string;
    booking: Booking;
    showModal: boolean;
    isLoading: boolean;
    requireChangesToSave?: boolean;
    requireOrdersToSave?: boolean;
    onSubmit(
        selectedOrderIds: string[],
        customerReference: string | null,
        customerName: string | null
    ): void;
    onClose(unsavedChanges: boolean): void;
}

const UpdateInternalWorkOrdersModal: React.FC<
    UpdateInternalWorkOrdersModalProps
> = ({
    modalHeader,
    booking,
    showModal,
    isLoading,
    requireChangesToSave,
    requireOrdersToSave,
    onSubmit,
    onClose,
}) => {
    const classes = useClasses();

    const [orderNumberSearchValue, setOrderNumberSearchValue] = useState('');
    const [selectedOrders, setSelectedOrders] = useState<InternalWorkOrder[]>(
        []
    );
    const [invalidOrder, setInvalidOrder] = useState<boolean>(false);

    const [customerName, setCustomerName] = useState<string | null>(
        booking.customerName
    );
    const [customerReference, setCustomerReference] = useState<string | null>(
        booking.customerReference
    );
    const [lookupSearchValue, setLookupSearchValue] = useState<string | null>(
        booking.customerName
    );
    const [bookingOrders, loadingBookingOrders] = useData<InternalWorkOrder[]>(
        `Booking/${booking.id}/InternalWorkOrders`
    );

    useEffect(() => {
        if (bookingOrders) {
            setSelectedOrders(bookingOrders);
        }
    }, [bookingOrders]);

    const [loadedCustomerNames, loadingCustomerNames] =
        useData<string[]>(`Customer/Values`);

    const noOrderChanges = () => {
        const originalOrderNumbers =
            booking.load?.orderLoadingStatuses.map(
                (o) => o.order.orderNumber
            ) ?? [];
        const currentOrderNumbers = selectedOrders.map((o) => o.orderNumber);
        return (
            originalOrderNumbers.every((o) =>
                currentOrderNumbers.includes(o)
            ) && originalOrderNumbers.length === currentOrderNumbers.length
        );
    };

    useEffect(
        () => {
            if (booking.bookingType === BookingType.DeliveryAndCollection) {
                return;
            }
            if (noOrderChanges()) {
                setCustomerReference(booking.customerReference);
                setCustomerName(booking.customerName);
                setLookupSearchValue(booking.customerName);
            } else {
                const sortedInternalWorkOrders: InternalWorkOrder[] =
                    selectedOrders.sort((lhs, rhs) =>
                        compareDatesAsc(lhs.deliveryDate, rhs.deliveryDate)
                    );
                const orderNumbers = sortedInternalWorkOrders
                    .map((o) => o.orderNumber)
                    .join(', ');

                setCustomerReference(orderNumbers);

                const orderWithEarliestDeliveryTime =
                    sortedInternalWorkOrders[0];

                const updatedCustomerName: string | null = GetCustomerName(
                    loadedCustomerNames,
                    orderWithEarliestDeliveryTime
                );

                if (updatedCustomerName !== customerName)
                    setCustomerName(updatedCustomerName);

                setLookupSearchValue(updatedCustomerName);
            }
        },
        // eslint-disable-next-line
        [selectedOrders]
    );

    const handleOrdersChange = (
        selectedOrders: InternalWorkOrder[],
        invalidOrderSelected?: boolean
    ) => {
        setSelectedOrders(selectedOrders);
        invalidOrderSelected ? setInvalidOrder(true) : setInvalidOrder(false);
    };

    const handleOrderSearchValueChange = (searchValue: string) => {
        setOrderNumberSearchValue(searchValue);
    };

    const handleRequestClose = () => {
        onClose(
            (bookingOrders || selectedOrders.length !== 0) &&
                selectedOrders !== bookingOrders
        );
    };

    const handleConfirmClicked = () => {
        onSubmit(
            selectedOrders.map((order) => order.id),
            customerReference,
            customerName
        );
    };

    const isCustomerNameValid = IsCustomerNameValid(
        customerName,
        booking.bookingType
    );

    const disableSubmit =
        (!bookingOrders && selectedOrders.length === 0) ||
        (requireChangesToSave && selectedOrders === bookingOrders) ||
        (requireOrdersToSave && selectedOrders.length === 0) ||
        invalidOrder ||
        !isCustomerNameValid;

    return (
        <>
            <Modal
                isOpen={showModal}
                onRequestClose={handleRequestClose}
                modalClassName={classNames(classes.updateOrdersModal, {
                    [classes.loadingModal]:
                        isLoading ||
                        loadingBookingOrders ||
                        loadingCustomerNames,
                })}
            >
                <ModalHeader>
                    <h5>{modalHeader}</h5>
                </ModalHeader>
                <>
                    {isLoading || loadingBookingOrders ? (
                        <div className={classes.loadingContainer}>
                            <LoadingIndicator text="retrieving orders" />
                        </div>
                    ) : (
                        <>
                            <ModalBody className={classes.updateOrdersBody}>
                                <Grid>
                                    <SingleColumnRow>
                                        <InternalWorkOrderSelectionContainer
                                            searchValue={orderNumberSearchValue}
                                            selectedOrders={selectedOrders}
                                            store={booking.store as StoreNumber}
                                            onOrdersChange={handleOrdersChange}
                                            onSearchValueChange={
                                                handleOrderSearchValueChange
                                            }
                                        />
                                    </SingleColumnRow>
                                    <hr />
                                    <Row>
                                        <Column>
                                            <NameLookupByReference
                                                lookupType="Customer"
                                                isValid={isCustomerNameValid}
                                                onNameSelected={setCustomerName}
                                                required={
                                                    booking.bookingType !==
                                                    BookingType.Internal
                                                }
                                                autoConvertWhitespace
                                                lookupSearchValue={
                                                    lookupSearchValue
                                                }
                                                handleUpdateSearchValue={
                                                    setLookupSearchValue
                                                }
                                            />
                                        </Column>
                                        <Column>
                                            <TextInput
                                                hideBottomPadding
                                                label="Customer Reference:"
                                                placeholder="Customer Reference..."
                                                value={customerReference}
                                                disabled={noOrderChanges()}
                                                onChange={setCustomerReference}
                                                autoConvertWhitespace
                                                autoTrim
                                            />
                                        </Column>
                                    </Row>
                                    {invalidOrder && (
                                        <Row>
                                            <Column>
                                                <div>
                                                    <p
                                                        className={
                                                            classes.errorMessage
                                                        }
                                                    >
                                                        One of the orders
                                                        selected has been
                                                        cancelled or is already
                                                        attached to another
                                                        booking
                                                    </p>
                                                </div>
                                            </Column>
                                        </Row>
                                    )}
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    styleType="secondary"
                                    onClick={handleRequestClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    styleType="primary"
                                    disabled={disableSubmit}
                                    onClick={handleConfirmClicked}
                                >
                                    Confirm
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </>
            </Modal>
        </>
    );
};

export default UpdateInternalWorkOrdersModal;
