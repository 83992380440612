import { Location } from 'history';
import React from 'react';
import { IconType } from '../common/Icon';
import NavItemContent from './NavItemContent';
import NavTooltip from './NavTooltip';

export interface NavItemProps {
    itemName?: string;
    iconType: IconType;
    isASubItem?: boolean;
    routeTo?: string;
    target?: string;
    navExpanded: boolean;
    horizontal?: boolean;
    isExternalLink?: boolean;
    isActive?: <Params extends { [K in keyof Params]?: string }>(
        location: Location
    ) => boolean;
}

const NavItem: React.FC<NavItemProps> = ({
    itemName,
    iconType,
    isASubItem = false,
    routeTo,
    target,
    navExpanded,
    horizontal,
    isExternalLink,
    isActive,
}) => (
    <NavTooltip navExpanded={navExpanded} tooltipText={itemName}>
        {({ ref, props }) => (
            <div ref={ref as React.Ref<HTMLDivElement>} {...props}>
                <NavItemContent
                    iconType={iconType}
                    itemName={itemName}
                    isASubItem={isASubItem}
                    routeTo={routeTo}
                    target={target}
                    navExpanded={navExpanded}
                    horizontal={horizontal}
                    isExternalLink={isExternalLink}
                    isActive={isActive}
                />
            </div>
        )}
    </NavTooltip>
);

export default NavItem;
