import { addHours, parseISO } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';
import RescheduleModal from '../../components/bookings/modals/RescheduleModal';
import UpdateResourcesModal from '../../components/bookings/modals/update/UpdateResourcesModal';
import ErrorModal from '../../components/common/ErrorModal';
import LoadingModal from '../../components/common/LoadingModal';
import UnsavedChangesModal from '../../components/common/UnsavedChangesModal';
import { diffHours } from '../../helpers/dates';
import { Booking } from '../../models/bookings/Booking';
import BookingDetails from '../../models/bookings/BookingDetails';
import { TrailerTypeResource } from '../../models/bookings/StoreBookingResource';
import UpdateResources from '../../models/bookings/UpdateResources';
import TimeSlot from '../../models/timeSlot/TimeSlot';
import { apiGet, apiPut, useData } from '../../services/api';
import { getTimeSlotsOverPeriodUrl } from '../../services/timeSlotService';
import { formatToUtc } from '../../services/timeStringService';

interface UpdateResourcesModalContainerProps {
    booking: Booking;
    showModal: boolean;
    onUpdate(): void;
    onClose(): void;
}

const UpdateResourcesModalContainer: React.FC<UpdateResourcesModalContainerProps> = ({
    booking,
    showModal,
    onUpdate,
    onClose
}) => {
    const [
        storeTrailerTypeResources,
        loadingStoreTrailerTypeResources
    ] = useData<TrailerTypeResource[]>(
        `Store/${booking.store}/BookingType/${booking.bookingType}/TrailerTypeResources/Active`
    );

    const [bookingDetails, setBookingDetails] = useState<BookingDetails>({
        bookingType: booking.bookingType,
        duration: booking.hourDuration,
        overrideStoreResourceValues: false,
        resources: booking.resources,
        store: booking.store,
        trailerType: booking.trailerType,
        selectedTimeSlot: null,
        secondaryTrailerType: booking.secondaryTrailerType
    });

    const [updatedResources, setUpdatedResources] = useState<UpdateResources>();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(
        false
    );
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);

    const [error, setError] = useState<unknown>(false);

    const originalTrailerTypeResource =
        storeTrailerTypeResources?.find(
            r =>
                r.trailerType === booking.trailerType &&
                (!booking.secondaryTrailerType ||
                    r.secondaryTrailerType === booking.secondaryTrailerType)
        ) || null;

    useEffect(() => {
        const isUsingNonDefaultResources =
            booking.resources &&
            originalTrailerTypeResource &&
            (booking.resources.length >
                originalTrailerTypeResource.resources.length ||
                booking.hourDuration !==
                    originalTrailerTypeResource.hourDuration);

        if (isUsingNonDefaultResources) {
            const editedBooking: BookingDetails = {
                ...bookingDetails,
                overrideStoreResourceValues: true
            };
            setBookingDetails(editedBooking);
        }
        //eslint-disable-next-line
    }, [originalTrailerTypeResource]);

    const handleSubmitConfirmed = async (
        updatedResourcesDetails: UpdateResources
    ) => {
        setSubmitting(true);
        try {
            await apiPut(
                `Booking/${booking.id}/UpdateResources`,
                updatedResourcesDetails
            );
            setSubmitting(false);
            onUpdate();
        } catch (e) {
            console.warn(e);
            setSubmitting(false);
            setError(e);
        }
    };

    const handleSubmitRequested = async () => {
        setLoading(true);

        const updatedBookingResources: UpdateResources = {
            resources: bookingDetails.resources,
            trailerType: bookingDetails.trailerType!,
            secondaryTrailerType: bookingDetails.secondaryTrailerType,
            startDateTime: new Date(booking.startDate),
            endDateTime: addHours(
                parseISO(booking.startDate),
                bookingDetails.duration
            ),
            isTimeSlotOverridden: false
        };

        const currentTimeSlots: TimeSlot[] = await apiGet(
            getTimeSlotsOverPeriodUrl({
                bookingType: booking.bookingType,
                checkResourceAvailability: true,
                duration: 1,
                excludedBookingIds: [booking.id],
                utcStart: formatToUtc(updatedBookingResources.startDateTime),
                utcEnd: formatToUtc(updatedBookingResources.endDateTime),
                resources: updatedBookingResources.resources ?? [],
                store: booking.store,
                trailerType: bookingDetails.trailerType
            })
        );

        const isTimeSlotUnavailable = currentTimeSlots.some(
            ts =>
                !ts.isAvailable &&
                ts.unavailableReasons.some(r => r !== 'In Past')
        );

        const previousTrailerTypeResources = storeTrailerTypeResources?.find(
            r =>
                r.trailerType === booking.trailerType &&
                (!booking.secondaryTrailerType ||
                    r.secondaryTrailerType === booking.secondaryTrailerType)
        );
        const newTrailerTypeResources = storeTrailerTypeResources?.find(
            r =>
                r.trailerType === bookingDetails.trailerType &&
                (!booking.secondaryTrailerType ||
                    r.secondaryTrailerType === booking.secondaryTrailerType)
        );
        const previousBookingDuration = diffHours(
            booking.endDate,
            booking.startDate
        );

        const resourcesIncreased =
            bookingDetails.duration > previousBookingDuration ||
            updatedBookingResources?.resources?.some(
                r => !booking.resources.includes(r)
            ) ||
            (previousTrailerTypeResources?.pointsPerLoad ?? 0) <
                (newTrailerTypeResources?.pointsPerLoad ?? 0);

        if (
            isTimeSlotUnavailable &&
            (resourcesIncreased ||
                currentTimeSlots.some(ts =>
                    ts.unavailableReasons.some(r => r === 'Outside Open Hours')
                ))
        ) {
            setUpdatedResources(updatedBookingResources);
            setLoading(false);
            setShowRescheduleModal(true);
        } else {
            handleSubmitConfirmed(updatedBookingResources);
        }
    };

    const showLoadingModal = showModal && (submitting || loading);
    const showUpdateResourcesModal =
        showModal && !showLoadingModal && !showUnsavedChangesModal && !error;
    const unsavedChanges =
        bookingDetails.duration !== booking.hourDuration ||
        booking.trailerType !== bookingDetails.trailerType ||
        (bookingDetails.secondaryTrailerType &&
            bookingDetails.secondaryTrailerType !==
                booking.secondaryTrailerType) ||
        (!!bookingDetails.resources &&
            booking.resources.length !== bookingDetails.resources.length);

    const modalHeader = `Update Booking ${booking.reference} Resources`;

    const handleClose = () => {
        if (unsavedChanges) {
            setShowUnsavedChangesModal(true);
        } else {
            onClose();
        }
    };

    const handleTimeSlotSelected = (timeSlot: TimeSlot) => {
        setShowRescheduleModal(false);
        const rescheduledBooking: UpdateResources = {
            ...updatedResources!,
            startDateTime: timeSlot.start,
            endDateTime: timeSlot.end,
            isTimeSlotOverridden: !timeSlot.isAvailable
        };
        handleSubmitConfirmed(rescheduledBooking);
    };

    return (
        <>
            <UpdateResourcesModal
                bookingDetails={bookingDetails}
                booking={booking}
                unsavedChanges={unsavedChanges}
                storeTrailerTypeResources={storeTrailerTypeResources || []}
                loading={loadingStoreTrailerTypeResources}
                showModal={showUpdateResourcesModal}
                onBookingDetailsChange={setBookingDetails}
                onSubmit={handleSubmitRequested}
                onClose={handleClose}
            />
            {showLoadingModal && (
                <LoadingModal
                    showModal={showLoadingModal}
                    header={modalHeader}
                    loadingText={submitting ? 'submitting' : 'loading'}
                />
            )}
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={onClose}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
            <ErrorModal
                showModal={showModal && !!error}
                header={modalHeader}
                errorText="There was an error updating the booking&#39;s resources."
                onClose={onClose}
            />
            {updatedResources && showRescheduleModal && (
                <RescheduleModal
                    startDate={new Date(updatedResources.startDateTime)}
                    endDate={new Date(updatedResources.endDateTime)}
                    bookingType={booking.bookingType}
                    excludedBookingIds={[booking.id]}
                    resources={updatedResources.resources ?? []}
                    store={booking.store}
                    trailerType={updatedResources.trailerType}
                    showModal={showModal && showRescheduleModal}
                    onTimeSlotSelected={handleTimeSlotSelected}
                    onClose={() => setShowRescheduleModal(false)}
                />
            )}
        </>
    );
};

export default UpdateResourcesModalContainer;
