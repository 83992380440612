import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import RecurringBookingTable from '../../../components/bookings/recurring/RecurringBookingTable';
import ErrorModal from '../../../components/common/ErrorModal';
import { StoreSelectorOption } from '../../../components/common/StoreSelectorPills';
import UpdateNotification from '../../../components/common/UpdateNotification';
import { useNotificationListener } from '../../../hooks/useNotificationListener';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import { RecurringBookingUpdatedNotification } from '../../../models/notifications/RecurringBooking';
import { useData } from '../../../services/api';
import { isNotificationRelevant } from '../../../services/bookingService';
import { sortRecurringBookings } from '../../../services/recurringBookingService';
import { useClasses } from './RecurringBookingTableContainer.styles';

interface RecurringBookingTableContainerProps {
    storeOption: StoreSelectorOption;
}

const RecurringBookingTableContainer: React.FC<
    RecurringBookingTableContainerProps
> = ({ storeOption }) => {
    const classes = useClasses();

    const [showErrorModal, setShowErrorModal] = useState(false);

    const [recurringBookings, loading, fetchRecurringBookings, errorMessage] =
        useData<RecurringBooking[]>(
            storeOption !== 'All'
                ? `Store/${storeOption}/RecurringBookings/Active`
                : `RecurringBookings/Active`
        );

    const handleRefreshBookings = async () => {
        if (loading) {
            return;
        }
        await fetchRecurringBookings();
    };

    useEffect(() => setShowErrorModal(!!errorMessage), [errorMessage]);

    const sortedRecurringBookings = useMemo(
        () => recurringBookings?.sort(sortRecurringBookings),
        [recurringBookings]
    );

    useNotificationListener(
        RecurringBookingUpdatedNotification,
        (recurringBookingUpdatedEvent) => {
            if (
                recurringBookingUpdatedEvent.action !== 'Generate Bookings' &&
                isNotificationRelevant(
                    storeOption,
                    recurringBookingUpdatedEvent.updatedRecurringBooking.store
                )
            ) {
                toast.info(
                    <UpdateNotification
                        heading={recurringBookingUpdatedEvent.title}
                    >
                        {recurringBookingUpdatedEvent.message}
                    </UpdateNotification>
                );
                handleRefreshBookings();
            }
        }
    );

    const closeErrorModal = () => {
        setShowErrorModal(false);
    };

    return (
        <div className={classes.recurringBookingTableContainer}>
            <RecurringBookingTable
                recurringBookingTableClassName={classes.recurringBookingTable}
                recurringBookings={sortedRecurringBookings || []}
                onRefreshBookings={handleRefreshBookings}
                loading={loading}
                showStore={storeOption === 'All'}
            />
            {showErrorModal && (
                <ErrorModal
                    showModal={showErrorModal}
                    errorText="There was an error retrieving the list of recurring bookings, please try refreshing the page. If this error continues, please contact IT."
                    onClose={closeErrorModal}
                />
            )}
        </div>
    );
};

export default RecurringBookingTableContainer;
