import * as React from 'react';
import { Load } from '../../../models/loads/Load';
import { useState } from 'react';
import LoadingModal from '../../common/LoadingModal';
import ErrorModal from '../../common/ErrorModal';
import UnsavedChangesModal from '../../common/UnsavedChangesModal';
import UpdateStockTransferOrderModal from '../../orders/UpdateStockTransferOrdersModal';
import { apiPut } from '../../../services/api';

interface UpdateNonIodStockTransferOrdersModalProps {
    showModal: boolean;
    load: Load;
    onClose(): void;
}

const UpdateNonIodStockTransferOrdersModal: React.FC<UpdateNonIodStockTransferOrdersModalProps> = ({
    showModal,
    load,
    onClose
}) => {
    const [error, setError] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(
        false
    );
    const [submittingUpdate, setSubmittingUpdate] = useState(false);

    const handleConfirmClicked = async (selectedOrderIds: string[]) => {
        try {
            setSubmittingUpdate(true);
            await apiPut(`Load/${load.id}/Orders`, {
                orderIds: selectedOrderIds
            });
            setSubmittingUpdate(false);
            onClose();
        } catch (error) {
            console.warn(error);
            setError(!!error);
            setSubmittingUpdate(false);
        }
    };

    const modalHeader = `Confirm Orders on ${load.loadName}`;

    if (showModal && submittingUpdate) {
        return (
            <LoadingModal
                showModal={showModal && submittingUpdate}
                header={modalHeader}
                loadingText="updating load"
            />
        );
    }

    if (showModal && error) {
        return (
            <ErrorModal
                showModal={showModal && error}
                header={modalHeader}
                errorText="There was an error updating the load&#39;s orders."
                onClose={onClose}
            />
        );
    }

    if (showModal && showUnsavedChangesModal) {
        return (
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={onClose}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
        );
    }

    return (
        <UpdateStockTransferOrderModal
            showModal={showModal}
            modalHeader={modalHeader}
            load={load}
            isLoading={false}
            onSubmit={handleConfirmClicked}
            onClose={onClose}
        />
    );
};

export default UpdateNonIodStockTransferOrdersModal;
