import classnames from 'classnames';
import * as React from 'react';

interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
    size?: number | string;
    xs?: number | string;
    sm?: number | string;
    md?: number | string;
    lg?: number | string;
    xl?: number | string;
    offset?: number;
    xsOffset?: number;
    smOffset?: number;
    mdOffset?: number;
    lgOffset?: number;
    xlOffset?: number;
    order?: number | 'first' | 'last';
    xsOrder?: number | 'first' | 'last';
    smOrder?: number | 'first' | 'last';
    mdOrder?: number | 'first' | 'last';
    lgOrder?: number | 'first' | 'last';
    xlOrder?: number | 'first' | 'last';
    align?: string;
    children: React.ReactNode;
    className?: string;
}

const Column: React.FC<ColumnProps> = ({
    size,
    xs,
    sm,
    md,
    lg,
    xl,
    offset,
    xsOffset,
    smOffset,
    mdOffset,
    lgOffset,
    xlOffset,
    order,
    xsOrder,
    smOrder,
    mdOrder,
    lgOrder,
    xlOrder,
    align,
    children,
    className,
    ...otherProps
}) => {
    const newClassName = classnames(className, {
        col: !(size || xs || sm || md || lg || xl),
        [`col-${size}`]: size,
        [`col-${xs}`]: xs,
        [`col-sm-${sm}`]: sm,
        [`col-md-${md}`]: md,
        [`col-lg-${lg}`]: lg,
        [`col-xl-${xl}`]: xl,
        [`offset-${offset}`]: offset,
        [`offset-${xsOffset}`]: xsOffset,
        [`offset-sm-${smOffset}`]: smOffset,
        [`offset-md-${mdOffset}`]: mdOffset,
        [`offset-lg-${lgOffset}`]: lgOffset,
        [`offset-xl-${xlOffset}`]: xlOffset,
        [`order-${order}`]: order,
        [`order-${xsOrder}`]: xsOrder,
        [`order-sm-${smOrder}`]: smOrder,
        [`order-md-${mdOrder}`]: mdOrder,
        [`order-lg-${lgOrder}`]: lgOrder,
        [`order-xl-${xlOrder}`]: xlOrder,
        [`align-self-${align}`]: align
    });

    return (
        <div className={newClassName} {...otherProps}>
            {children}
        </div>
    );
};

export default Column;
