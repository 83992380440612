import * as React from 'react';

interface TableBodyProps {
    children: React.ReactNode;
    className?: string;
    [otherProps: string]: any;
}

const TableBody: React.FC<TableBodyProps> = ({
    children,
    className,
    ...otherProps
}) => (
    <tbody className={className} {...otherProps}>
        {children}
    </tbody>
);

export default TableBody;
