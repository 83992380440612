import { endOfDay, startOfDay } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';

import ErrorModal from '../../components/common/ErrorModal';
import HeaderContainer from '../../components/common/HeaderContainer';
import Pagination from '../../components/common/Pagination';
import WorkHistoryFilterModal from '../../components/workHistory/filter/WorkHistoryFilterModal';
import WorkHistoryTable from '../../components/workHistory/table/WorkHistoryTable';
import usePersistedState from '../../hooks/usePersistedState';
import FilterResults from '../../models/common/FilterResults';
import { HistoricalLoadFilter } from '../../models/loads/HistoricalLoadFilter';
import { Load } from '../../models/loads/Load';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { useData } from '../../services/api';
import { useCurrentUser } from '../../services/authentication';
import { formatToUtc } from '../../services/timeStringService';
import { userToAllStores } from '../../services/userService';
import LoadingTableActionContainer, {
    Action,
} from '../load/loadTable/view/LoadingTableActionContainer';
import { useClasses } from './WorkHistoryContainer.styles';

const actions: Action[] = [
    { title: 'storeSelector' },
    { title: 'filters' },
    { title: 'workQueue' },
];

interface WorkHistoryContainerProps {
    selectedStore?: StoreNumber;
    onStoreChange: (store: StoreNumber) => void;
}

const WORK_HISTORY_PAGE_SIZE = 200;

const areHistoricalLoadFiltersEqual = (
    lhs: HistoricalLoadFilter,
    rhs: HistoricalLoadFilter
) =>
    lhs.loadReference === rhs.loadReference &&
    lhs.orderNumber === rhs.orderNumber &&
    lhs.iodNumber === rhs.iodNumber &&
    lhs.startDate?.valueOf === rhs.startDate?.valueOf &&
    lhs.endDate?.valueOf === rhs.endDate?.valueOf;

const defaultFilter: HistoricalLoadFilter = {
    loadReference: null,
    orderNumber: null,
    iodNumber: null,
    startDate: null,
    endDate: null,
};

const WorkHistoryContainer: React.FC<WorkHistoryContainerProps> = ({
    selectedStore,
    onStoreChange,
}) => {
    const classes = useClasses();
    const user = useCurrentUser();

    const storesToShow: StoreNumber[] = userToAllStores(user);

    const [store, setStore] = usePersistedState<StoreNumber>(
        selectedStore || userToAllStores(user!)[0],
        'loading-store'
    );
    const [filter, setFilter] = usePersistedState<HistoricalLoadFilter>(
        defaultFilter,
        'work-history-filter'
    );
    const [resultPageOffset, setResultPageOffset] = useState<number>(0);
    const [modalToDisplay, setModalToDisplay] = useState<
        'filter' | 'error' | null
    >(null);
    const [loads, loading, , error] = useData<FilterResults<Load>>(
        `Store/${selectedStore}/Load/Completed`,
        {
            loadReference: filter.loadReference,
            orderNumber: filter.orderNumber,
            iodNumber: filter.iodNumber,
            startDate: filter.startDate
                ? formatToUtc(startOfDay(filter.startDate))
                : null,
            endDate: filter.endDate
                ? formatToUtc(endOfDay(filter.endDate))
                : null,
            offset: resultPageOffset,
            values: WORK_HISTORY_PAGE_SIZE,
        }
    );

    const resetPageNumber = () => {
        setResultPageOffset(0);
    };

    const handleFilterReset = () => {
        resetPageNumber();
        setFilter(defaultFilter);
        localStorage.removeItem('work-history-filter');
    };

    useEffect(() => {
        if (error) {
            setModalToDisplay('error');
            handleFilterReset();
        }
        //eslint-disable-next-line
    }, [error]);

    const handleStoreChange = async (store: StoreNumber) => {
        onStoreChange(store);
        setStore(store);
        resetPageNumber();
    };

    const handleFilterChange = (newFilter: HistoricalLoadFilter) => {
        resetPageNumber();
        setFilter(newFilter);
        setModalToDisplay(null);
    };

    const totalPages = Math.ceil(
        (loads?.totalResultCount || 0) / WORK_HISTORY_PAGE_SIZE
    );

    return (
        <>
            <HeaderContainer headerText="Work History">
                <LoadingTableActionContainer
                    actionsToDisplay={actions}
                    activeFilters={
                        !areHistoricalLoadFiltersEqual(filter, defaultFilter)
                    }
                    storeOption={store}
                    storeOptions={storesToShow}
                    onStoreOptionChange={handleStoreChange}
                    onFilterClicked={() => setModalToDisplay('filter')}
                    onFilterReset={handleFilterReset}
                />
            </HeaderContainer>
            <div className={classes.workHistoryTableContainer}>
                <WorkHistoryTable
                    loads={loads?.resultsToShow || []}
                    loading={loading || !loads}
                />
            </div>
            {totalPages > 1 && (
                <div className={classes.pageSelectionContainer}>
                    <Pagination
                        currentPage={
                            (resultPageOffset + WORK_HISTORY_PAGE_SIZE) /
                            WORK_HISTORY_PAGE_SIZE
                        }
                        totalPages={totalPages}
                        onPageLinkClick={(pageNumber: number) =>
                            setResultPageOffset(
                                (pageNumber - 1) * WORK_HISTORY_PAGE_SIZE
                            )
                        }
                    />
                </div>
            )}
            <WorkHistoryFilterModal
                isOpen={modalToDisplay === 'filter'}
                filter={filter}
                onFilterConfirm={handleFilterChange}
                onRequestClose={() => setModalToDisplay(null)}
            />
            <ErrorModal
                showModal={modalToDisplay === 'error'}
                errorText="There was an error retrieving the list of loads, please try refreshing the page. If this error continues, please contact IT."
                onClose={() => setModalToDisplay(null)}
            />
        </>
    );
};

export default WorkHistoryContainer;
