import * as React from 'react';
import { useState } from 'react';

import Button from '../../lib/bootstrap-ui/Button';
import { useAuth } from '../../services/authentication';
import { useClasses } from './LogoutButton.styles';
import LogOutConfirmModal from './LogoutConfirmModal';

const LogOutButton: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { logout } = useAuth();
    const [showConfirm, setShowConfirm] = useState(false);

    const classes = useClasses();

    const toggleConfirmModal = () => {
        setShowConfirm((prev) => !prev);
    };

    return (
        <>
            <Button
                onClick={toggleConfirmModal}
                className={classes.logOutButton}
            >
                {children}
            </Button>
            {showConfirm && (
                <LogOutConfirmModal
                    showModal={showConfirm}
                    onLogOutConfirmed={logout}
                    onCancel={toggleConfirmModal}
                />
            )}
        </>
    );
};

export default LogOutButton;
