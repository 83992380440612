import * as React from 'react';
import { compile } from 'path-to-regexp';
import LoginFormContainer from '../containers/authentication/LoginFormContainer';
import { UserRole } from '../helpers/userRole';
import NotFound from '../pages/NotFound';
import UnauthorisedUser from '../pages/UnauthorisedUser';
import AccessControl from '../pages/admin/AccessControl';
import AddBay from '../pages/admin/AddBay';
import BayManagement from '../pages/admin/BayManagement';
import LoadingProgressConfig from '../pages/admin/LoadingProgressConfig';
import SiteResourceConfig from '../pages/admin/SiteResourceConfig';
import StoreResourceConfig from '../pages/admin/StoreResourceConfig';
import ThirdPartyManagement from '../pages/admin/ThirdPartyManagement';
import BookingForm from '../pages/bookings/BookingForm';
import BookingSummary from '../pages/bookings/BookingSummary';
import Bookings from '../pages/bookings/Bookings';
import BookingsHistory from '../pages/bookings/BookingsHistory';
import RecurringBookingForm from '../pages/bookings/RecurringBookingForm';
import RecurringBookingSummary from '../pages/bookings/RecurringBookingSummary';
import RecurringBookings from '../pages/bookings/RecurringBookings';
import IodDetails from '../pages/iods/IodDetails';
import CreateStockTransferForm from '../pages/loads/CreateStockTransferForm';
import LoadIssues from '../pages/loads/LoadIssues';
import LoadSummary from '../pages/loads/LoadSummary';
import LoadingProgressDetail from '../pages/loads/LoadingProgressDetail';
import Release from '../pages/loads/Release';
import StockTrailerDetails from '../pages/loads/StockTrailerDetails';
import ProgressSummary from '../pages/progressSummary/ProgressSummary';
import WorkHistory from '../pages/workHistory/WorkHistory';
import WorkQueue from '../pages/workQueue/WorkQueue';
import { AuthState } from '../services/authentication';
import {
    allRoles,
    userInRole,
    userIsAdmin,
    userIsNotReader,
    userIsTester,
    userUnauthorised,
} from '../services/userService';
import SMSTemplateManagement from '../pages/admin/SMSTemplateManagement';

type DisplayFunction = (props: AuthState) => boolean;

export enum RouteKey {
    AppLayout = 'AppLayout',
    WorkQueue = 'WorkQueue',
    StoreWorkQueue = 'StoreWorkQueue',
    LoadSummary = 'LoadSummary',
    ProgressSummary = 'ProgressSummary',
    StoreProgressSummary = 'StoreProgresssummary',
    WorkHistory = 'WorkHistory',
    StoreWorkHistory = 'StoreWorkHistory',
    IodDetails = 'IodDetails',
    StockTrailerDetails = 'StockTrailerDetails',
    LoadIssues = 'LoadIssues',
    Release = 'Release',
    BayManagement = 'BayManagement',
    CreateBay = 'CreateBay',
    NotFound = 'NotFound',
    Login = 'Login',
    Bookings = 'Bookings',
    BookingsHistory = 'BookingsHistory',
    RecurringBookings = 'RecurringBookings',
    BookingForm = 'CreateBooking',
    RecurringBookingForm = 'CreateRecurringBooking',
    LoadingProgressConfig = 'LoadingProgressConfig',
    StoreResourceConfig = 'StoreResourceConfig',
    SiteResourceConfig = 'SiteResourceConfig',
    Unauthorised = 'Unauthorised',
    BookingSummary = 'BookingSummary',
    RecurringBookingSummary = 'RecurringBookingSummary',
    ThirdPartyManagement = 'ThirdPartyManagement',
    LoadingProgressDetailed = 'LoadingProgressDetailed',
    SMSTemplateManagement = 'SMSTemplateManagement',
    AccessControl = 'AccessControls',
    CreateStockTransfer = 'CreateStockTransfer',
}

interface RouteRedirectAction {
    redirectToKey: RouteKey;
    redirectCondition: DisplayFunction;
}

export interface RouteDefinition {
    key: RouteKey;
    path?: string;
    element: React.ReactNode;
    display?: DisplayFunction;
    redirectAction?: RouteRedirectAction;
}

const ifUnauthorised: DisplayFunction = ({ user }) =>
    userUnauthorised(user, allRoles);

const ifLoggedIn: DisplayFunction = (authState) =>
    authState.loggedIn && !ifUnauthorised(authState);

const ifAdmin: DisplayFunction = ({ user }) => userIsAdmin(user);
const ifGatehouse: DisplayFunction = ({ user }) =>
    userInRole(user)(UserRole.Gatehouse);

const ifNotGatehouse: DisplayFunction = ({ user }) =>
    !userInRole(user)(UserRole.Gatehouse);

const ifTester: DisplayFunction = ({ user }) => userIsTester(user);

const ifNotDockSystemReader: DisplayFunction = ({ user }) =>
    userIsNotReader(user);

export const loginRoute: RouteDefinition = {
    key: RouteKey.Login,
    path: '/login',
    element: <LoginFormContainer />,
};

export const appRoutes: RouteDefinition[] = [
    {
        key: RouteKey.Unauthorised,
        path: '/unauthorised',
        element: <UnauthorisedUser />,
        display: (authState) =>
            ifLoggedIn(authState) && ifUnauthorised(authState),
    },
    {
        key: RouteKey.WorkQueue,
        path: '/',
        element: <WorkQueue />,
        display: ifLoggedIn,
        redirectAction: {
            redirectToKey: RouteKey.Bookings,
            redirectCondition: ifGatehouse,
        },
    },
    {
        key: RouteKey.LoadSummary,
        path: '/Load/:id/Summary',
        element: <LoadSummary />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotGatehouse(authState),
    },
    {
        key: RouteKey.ProgressSummary,
        path: '/ProgressSummary',
        element: <ProgressSummary />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotGatehouse(authState),
    },
    {
        key: RouteKey.WorkHistory,
        path: '/WorkHistory',
        element: <WorkHistory />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotGatehouse(authState),
    },
    {
        key: RouteKey.Bookings,
        path: '/Bookings',
        element: <Bookings />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.BookingsHistory,
        path: '/Bookings/History',
        element: <BookingsHistory />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.RecurringBookings,
        path: '/RecurringBookings',
        element: <RecurringBookings />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.StoreWorkQueue,
        path: '/Store/:store/WorkQueue',
        element: <WorkQueue />,
        display: ifLoggedIn,
    },
    {
        key: RouteKey.StoreProgressSummary,
        path: '/Store/:store/ProgressSummary',
        element: <ProgressSummary />,
        display: ifLoggedIn,
    },
    {
        key: RouteKey.StoreWorkHistory,
        path: '/Store/:store/WorkHistory',
        element: <WorkHistory />,
        display: ifLoggedIn,
    },
    {
        key: RouteKey.IodDetails,
        path: '/Iod/:iodNumber/Details',
        element: <IodDetails />,
        display: ifLoggedIn,
    },
    {
        key: RouteKey.StockTrailerDetails,
        path: '/StockTrailer/:loadId/Details',
        element: <StockTrailerDetails />,
        display: ifLoggedIn,
    },
    {
        key: RouteKey.LoadIssues,
        path: '/Load/:id/Issues',
        element: <LoadIssues />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.Release,
        path: '/Load/:id/Release',
        element: <Release />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.BayManagement,
        path: '/Admin/BayManagement',
        element: <BayManagement />,
        display: ifAdmin,
    },
    {
        key: RouteKey.CreateBay,
        path: '/Admin/CreateBay',
        element: <AddBay />,
        display: ifAdmin,
    },
    {
        key: RouteKey.BookingForm,
        path: '/Bookings/:bookingType/Create',
        element: <BookingForm />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.RecurringBookingForm,
        path: '/RecurringBookings/:bookingType/Create',
        element: <RecurringBookingForm />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.RecurringBookingSummary,
        path: '/RecurringBookings/:id/Summary',
        element: <RecurringBookingSummary />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.BookingSummary,
        path: '/Bookings/:id/Summary',
        element: <BookingSummary />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.LoadingProgressConfig,
        path: '/Admin/LoadingProgress',
        element: <LoadingProgressConfig />,
        display: ifAdmin,
    },
    {
        key: RouteKey.StoreResourceConfig,
        path: '/Admin/StoreResource',
        element: <StoreResourceConfig />,
        display: ifAdmin,
    },
    {
        key: RouteKey.SiteResourceConfig,
        path: '/Admin/SiteResource',
        element: <SiteResourceConfig />,
        display: ifAdmin,
    },
    {
        key: RouteKey.ThirdPartyManagement,
        path: '/Admin/ThirdPartyManagement',
        element: <ThirdPartyManagement />,
        display: ifAdmin,
    },
    {
        key: RouteKey.LoadingProgressDetailed,
        path: '/Load/:id/Progress',
        element: <LoadingProgressDetail />,
        display: ifLoggedIn,
    },
    {
        key: RouteKey.SMSTemplateManagement,
        path: '/Admin/SMSTemplates',
        element: <SMSTemplateManagement />,
        display: ifAdmin,
    },
    {
        key: RouteKey.AccessControl,
        path: '/Admin/AccessControl',
        element: <AccessControl />,
        display: ifTester,
    },
    {
        key: RouteKey.CreateStockTransfer,
        path: '/Load/CreateStockTransfer',
        element: <CreateStockTransferForm />,
        display: (authState) =>
            ifLoggedIn(authState) && ifNotDockSystemReader(authState),
    },
    {
        key: RouteKey.NotFound,
        path: '*',
        element: <NotFound />,
        display: ifLoggedIn,
    },
];

const routeFor = (key: RouteKey) => (params?: object) => {
    const matchingRoute = appRoutes.find((r) => r.key === key);

    if (!matchingRoute || !matchingRoute.path) {
        return '';
    }
    const toPath = compile(matchingRoute.path);
    const path = toPath(params);
    return path;
};

export { routeFor };
