import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    storeSelector: {
        display: 'flex',
        flexDirection: 'row',
    },
    sourceStoreSelectorContainer: {
        flex: 1,
    },
    destinationStoreSelectorContainer: {
        flex: 1,
    },
    storeOptionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 0.5rem',
    },
    storeOption: {
        margin: '0.25rem 0',
        borderRadius: 5,
        fontWeight: 500,
        fontSize: '1.25rem',
        cursor: 'pointer',
        padding: '0.5rem 1rem',
        maxWidth: '9.5rem',
        width: '100%',
        border: `2px solid`,
    },
    storeOptionUnselected: {
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.mediumDarkGrey,
        '&:hover': {
            backgroundColor: theme.colors.primaryLight,
            borderColor: theme.colors.primary,
        },
        '&:focus-within': {
            backgroundColor: theme.colors.primaryLight,
            borderColor: theme.colors.primary,
        },
    },
    storeOptionSelected: {
        backgroundColor: theme.colors.primaryLight,
        borderColor: theme.colors.primary,
    },
    storeOptionDisabled: {
        backgroundColor: theme.colors.lightGrey,
        borderColor: theme.colors.mediumDarkGrey,
        color: theme.colors.lightBlack,
        cursor: 'auto',
    },
    arrowContainer: {
        alignSelf: 'center',
        padding: '0 2.5rem',
    },
    arrowIcon: {
        fontSize: '3rem',
        color: theme.colors.mediumDarkGrey,
    },
    storeOptionCheckboxContainer: {
        width: '100%',
        cursor: 'pointer',
    },
    storeOptionCheckbox: {
        transform: 'scale(1.2)',
        cursor: 'pointer',
    },
    storeOptionCheckboxLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'baseline',
        cursor: 'pointer',
    },
}));
