import classNames from 'classnames';
import { isBefore, isSameDay } from 'date-fns';
import { isNullOrUndefined } from 'is-what';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

const useClasses = buildClassesHook((theme: Theme) => ({
    selected: {
        backgroundColor: theme.colors.primary,
        fontWeight: 'bold',
        color: 'white',
    },
    selectable: {
        cursor: 'pointer',
    },
    disabled: {
        color: theme.colors.lightGrey,
        cursor: 'not-allowed',
    },
    dateCell: {
        borderRadius: '5px',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        fontSize: '1.2rem',
    },
    selectedAndDisabled: {
        backgroundColor: theme.colors.lightGrey,
        color: 'white',
    },
}));

interface DateCellProps extends React.HTMLAttributes<HTMLDivElement> {
    date: Date;
    disabled?: boolean;
    minDate?: Date;
    selectedDate: Date | null;
    selectedMonth: Date;
    onDateSelected: (date: Date) => void;
}

const DateCell: React.FC<DateCellProps> = ({
    date,
    disabled,
    minDate,
    selectedDate,
    selectedMonth,
    className,
    onDateSelected,
    ...otherProps
}) => {
    const classes = useClasses();

    const isDateBeforeMinDate =
        !isNullOrUndefined(minDate) && isBefore(date, minDate);
    const isSelected =
        !isNullOrUndefined(selectedDate) && isSameDay(date, selectedDate);
    const isDisabled = disabled || isDateBeforeMinDate;

    const onClickAction = !isDisabled ? () => onDateSelected(date) : undefined;

    return (
        <div
            key={date.getDate()}
            className={classNames(
                {
                    [classes.selectedAndDisabled]: isSelected && isDisabled,
                    [classes.selectable]: !isDisabled,
                    [classes.disabled]: isDisabled,
                    [classes.selected]: isSelected,
                },
                classes.dateCell,
                className
            )}
            onClick={onClickAction}
            {...otherProps}
        >
            {date.getDate()}
        </div>
    );
};

export default DateCell;
