import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';

import BookingSummaryModalContainer from '../../../containers/bookings/view/modal/BookingSummaryModalContainer';
import Button from '../../../lib/bootstrap-ui/Button';
import { BookingStatus } from '../../../models/bookings/BookingStatus';
import { HistoricalBooking } from '../../../models/bookings/HistoricalBooking';
import { getTrailerTypeLabelForBooking } from '../../../services/trailerService';
import BookingColourSegment from '../../bookings/BookingColourSegment';
import ViewHistoryModal from '../../bookings/modals/bookingHistory/ViewHistoryModal';
import BookingTooltip from '../../bookings/tooltip/BookingTooltip';
import Icon from '../../common/Icon';
import { useClasses } from './BookingsHistoryTableRow.styles';
import { formatDate } from '../../../helpers/dates';

interface BookingsHistoryTableRowProps {
    historicalBooking: HistoricalBooking;
    showStoreResources?: boolean;
    showStore?: boolean;
}

const BookingsHistoryTableRow: React.FC<BookingsHistoryTableRowProps> = ({
    historicalBooking,
    showStoreResources = false,
    showStore = false,
}) => {
    const classes = useClasses();

    const [viewHistory, setViewHistory] = useState(false);
    const [showSummaryModal, setShowSummaryModal] = useState(false);

    const bookingCreator = historicalBooking
        ? historicalBooking.bookingHistory.find((h) => h.action === 'Add')
              ?.userName
        : '';
    const checkedInTime =
        historicalBooking &&
        historicalBooking.bookingHistory.find((h) => h.action === 'Check In')
            ?.actionDateTime;
    const checkedOutTime =
        historicalBooking &&
        historicalBooking.bookingHistory.find((h) => h.action === 'Complete')
            ?.actionDateTime;
    const status = historicalBooking.booking.status;
    const cancellationReason = historicalBooking.booking.cancellationReason;

    const getTimeToDisplay = (startDate: Date, displayDate: Date) => {
        const startDayAndMonth = format(startDate, 'dd/MM');
        const displayDayAndMonth = format(displayDate, 'dd/MM');
        if (startDayAndMonth !== displayDayAndMonth) {
            return format(displayDate, 'HH:mm (dd/MM)');
        }
        return format(displayDate, 'HH:mm');
    };

    const cancelledTime = historicalBooking.booking.cancelledTime
        ? formatDate(historicalBooking.booking.cancelledTime, 'dd-MM-yyyy')
        : null;

    return (
        <>
            <tr className={classes.bookingTableRow}>
                <td
                    className={classes.typeColumn}
                    title={historicalBooking.booking.bookingType || ''}
                >
                    <BookingColourSegment
                        bookingType={historicalBooking.booking.bookingType}
                    />
                </td>
                <td>
                    <div className={classes.referenceData}>
                        <BookingTooltip
                            options={{
                                booking: historicalBooking.booking,
                                type: 'booking',
                            }}
                            onTriggerClick={() => setShowSummaryModal(true)}
                        />
                        {status === BookingStatus.Cancelled &&
                            cancellationReason !== 'No-Show' && (
                                <Icon
                                    className={classes.cancelledIcon}
                                    title={
                                        cancellationReason
                                            ? `Cancelled: ${cancellationReason}`
                                            : 'Cancelled'
                                    }
                                    type="cancel"
                                />
                            )}
                        {status === BookingStatus.Cancelled &&
                            cancellationReason === 'No-Show' && (
                                <Icon
                                    className={classes.noShowIcon}
                                    title="No-Show"
                                    type="no-show"
                                />
                            )}
                    </div>
                </td>
                <td>
                    {getTrailerTypeLabelForBooking(historicalBooking.booking)}
                </td>
                <td
                    className={classNames(
                        'd-none',
                        'd-xl-table-cell',
                        classes.restrictedCell
                    )}
                    style={{ maxWidth: '8rem' }}
                    title={historicalBooking.booking.haulierName || undefined}
                >
                    {historicalBooking.booking.haulierName}
                </td>
                <td>
                    {historicalBooking.booking.startDate &&
                        formatDate(
                            historicalBooking.booking.startDate,
                            'HH:mm'
                        )}
                </td>
                <td
                    className={classes.restrictedCell}
                    title={historicalBooking.booking.customerName || undefined}
                >
                    {historicalBooking.booking.customerName}
                </td>
                <td
                    className={classNames(
                        'd-none',
                        'd-xl-table-cell',
                        classes.restrictedCell
                    )}
                    title={
                        historicalBooking.booking.customerReference || undefined
                    }
                >
                    {historicalBooking.booking.customerReference}
                </td>

                {showStoreResources && (
                    <>
                        <td>
                            {historicalBooking.booking.resources!.includes(
                                'Clamp Truck'
                            ) && <Icon type="tick" />}
                        </td>
                        <td>
                            {historicalBooking.booking.resources!.includes(
                                'Handball Team'
                            ) && <Icon type="tick" />}
                        </td>
                        <td>
                            {historicalBooking.booking.resources!.includes(
                                'Counterbalance Truck'
                            ) && <Icon type="tick" />}
                        </td>
                        <td>
                            {historicalBooking.booking.resources!.includes(
                                'Conveyor Belt'
                            ) && <Icon type="tick" />}
                        </td>
                    </>
                )}
                <td className={classNames('d-none', 'd-xl-table-cell')}>
                    {historicalBooking.booking.registrationPlate}
                </td>
                <td>{historicalBooking.booking.bayOrPagerNumber}</td>
                {status === BookingStatus.Cancelled &&
                historicalBooking.booking.cancelledTime != null &&
                cancellationReason !== 'No-Show' ? (
                    <td colSpan={2} title={`Cancelled On ${cancelledTime}`}>
                        <div className={classes.cancelledColumn}>
                            Cancelled:{' '}
                            {formatDate(
                                historicalBooking.booking.cancelledTime,
                                'HH:mm dd/MM'
                            )}
                        </div>
                    </td>
                ) : status === BookingStatus.Cancelled &&
                  historicalBooking.booking.cancelledTime != null &&
                  cancellationReason === 'No-Show' ? (
                    <td colSpan={2} title={`No-Show On ${cancelledTime}`}>
                        <div className={classes.noShowColumn}>
                            No-Show:{' '}
                            {formatDate(
                                historicalBooking.booking.cancelledTime,
                                'HH:mm dd/MM'
                            )}
                        </div>
                    </td>
                ) : (
                    <>
                        <td>
                            {checkedInTime
                                ? getTimeToDisplay(
                                      parseISO(
                                          historicalBooking.booking.startDate
                                      ),
                                      parseISO(checkedInTime)
                                  )
                                : ''}
                        </td>
                        <td>
                            {checkedOutTime
                                ? getTimeToDisplay(
                                      parseISO(
                                          historicalBooking.booking.startDate
                                      ),
                                      parseISO(checkedOutTime)
                                  )
                                : ''}
                        </td>
                    </>
                )}
                <td
                    style={{ maxWidth: 150 }}
                    className={classes.restrictedCell}
                    title={bookingCreator}
                >
                    {bookingCreator}
                </td>
                {showStore && <td>{historicalBooking.booking.store}</td>}
                <td>
                    <Button
                        className={classes.viewHistoryButton}
                        styleType="link"
                        onClick={() => setViewHistory(true)}
                    >
                        <span style={{ display: 'inline' }}>
                            <Icon
                                className={classes.viewHistoryIcon}
                                type="history"
                                title="View History"
                            />
                            <span
                                className={classNames(
                                    'd-none',
                                    'd-xl-table-cell'
                                )}
                            >
                                View History
                            </span>
                        </span>
                    </Button>
                </td>
            </tr>
            <ViewHistoryModal
                booking={historicalBooking.booking}
                overrideValues={historicalBooking.bookingHistory.sort(
                    (a, b) =>
                        parseISO(a.actionDateTime).valueOf() -
                        parseISO(b.actionDateTime).valueOf()
                )}
                type="booking"
                showModal={viewHistory}
                onClose={() => setViewHistory(false)}
            />
            <BookingSummaryModalContainer
                bookingId={historicalBooking.booking.id}
                showModal={showSummaryModal}
                onClose={() => setShowSummaryModal(false)}
            />
        </>
    );
};

export default BookingsHistoryTableRow;
