import { Notification } from '.';
import { BayStatus } from '../bays/BayStatus';

export const BayStatusUpdatedNotification: Notification<BayStatus> = {
    message: 'BayStatusUpdated'
};

export const BaysUpdatedNotification: Notification<BayStatus[]> = {
    message: 'BaysUpdated'
};
