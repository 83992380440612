import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ViewHistoryModal from '../../../../components/bookings/modals/bookingHistory/ViewHistoryModal';
import EmailModal from '../../../../components/bookings/modals/EmailModal';
import RecurringSummaryModal from '../../../../components/bookings/modals/recurringBooking/RecurringSummaryModal';
import UndoCheckInModal from '../../../../components/bookings/modals/UndoCheckInModal';
import BookingTable from '../../../../components/bookings/view/table/BookingTable';
import { StoreSelectorOption } from '../../../../components/common/StoreSelectorPills';
import UpdateNotification from '../../../../components/common/UpdateNotification';
import { useNotificationListener } from '../../../../hooks/useNotificationListener';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingOptionType } from '../../../../models/bookings/BookingOptionType';
import {
    BookingUpdated,
    BookingUpdatedNotification
} from '../../../../models/notifications/Booking';
import {
    RecurringBookingUpdated,
    RecurringBookingUpdatedNotification
} from '../../../../models/notifications/RecurringBooking';
import { isNotificationRelevant } from '../../../../services/bookingService';
import AttachmentsModalContainer from '../../AttachmentsModalContainer';
import CancelModalContainer from '../../CancelBookingModalContainer';
import CheckInModalContainer from '../../CheckInModalContainer';
import CheckOutModalContainer from '../../CheckOutModalContainer';
import InstructionsModalContainer from '../../InstructionsModalContainer';
import RescheduleModalContainer from '../../RescheduleModalContainer';
import BookingSmsModalContainer from '../../update/BookingSmsModalContainer';
import ConvertToDeliveryModalContainer from '../../update/ConvertToDeliveryModalContainer';
import EditContactNumberModalContainer from '../../update/EditContactNumberModalContainer';
import EditModalContainer from '../../update/EditModalContainer';
import UpdateOrdersModalContainer from '../../update/UpdateOrdersModalContainer';
import UpdateResourcesModalContainer from '../../UpdateResourcesModalContainer';
import BookingSummaryModalContainer from './BookingSummaryModalContainer';

interface BookingTableContainerProps {
    className?: string;
    bookingTableClassName?: string;
    bookings: Booking[] | null;
    loading: boolean;
    refreshingBookings: boolean;
    selectedStoreOption: StoreSelectorOption;
    showAction?: boolean;
    showOptions?: boolean;
    showStore?: boolean;

    onRecurringBookingUpdate(
        recurringBookingUpdate: RecurringBookingUpdated
    ): void;
    onBookingUpdate(bookingUpdate: BookingUpdated): void;
    onRefresh(): Promise<void>;
}

const BookingTableContainer: React.FC<BookingTableContainerProps> = ({
    className,
    bookingTableClassName,
    bookings,
    loading,
    refreshingBookings,
    selectedStoreOption,
    showAction,
    showStore,
    onRecurringBookingUpdate,
    onBookingUpdate,
    onRefresh
}) => {
    const [
        modalToDisplay,
        setModalToDisplay
    ] = useState<BookingOptionType | null>(null);

    const [completingBookingsIds, setCompletingBookingsIds] = useState<
        string[]
    >([]);
    const [bookingToShowModalForId, setBookingToShowModalForId] = useState<
        string | null
    >(null);
    const [
        bookingToShowModalFor,
        setBookingToShowModalFor
    ] = useState<Booking | null>();
    const [allowCheckIn, setAllowCheckIn] = useState(true);

    useEffect(() => {
        const updatedBookingToShow = bookings?.find(
            b => b.id === bookingToShowModalForId
        );
        if (updatedBookingToShow) {
            setBookingToShowModalFor(updatedBookingToShow);
        }
    }, [bookingToShowModalForId, bookings]);

    const handleCheckOutRequested = (bookingId: string) => {
        const booking = bookings!.find(b => b.id === bookingId);
        if (booking) {
            setCompletingBookingsIds(prev => [...prev, booking.id]);
        }
    };

    const handleDisplayModalRequested = (
        bookingId: string,
        modalToDisplay: BookingOptionType
    ) => {
        setBookingToShowModalForId(bookingId);
        setModalToDisplay(modalToDisplay);
    };

    const resetModalToDisplay = () => {
        setModalToDisplay(null);
        setBookingToShowModalFor(null);
        setBookingToShowModalForId(null);
    };

    const handleCheckIn = () => {
        resetModalToDisplay();
        setAllowCheckIn(true);
    };

    const handleCheckInModalClose = () => {
        resetModalToDisplay();
        setAllowCheckIn(true);
    };

    const stopCheckInIfRequired = (updatedBooking: Booking) => {
        if (
            modalToDisplay === 'checkIn' &&
            updatedBooking.id === bookingToShowModalForId
        ) {
            setAllowCheckIn(false);
        }
    };

    const handleBookingUpdate = (bookingUpdate: BookingUpdated) => {
        onBookingUpdate(bookingUpdate);
        stopCheckInIfRequired(bookingUpdate.updatedBooking);
        if (
            bookingUpdate.action === 'Undo Check In' ||
            bookingUpdate.action === 'Complete'
        ) {
            setCompletingBookingsIds(
                completingBookingsIds.filter(
                    id => id !== bookingUpdate.updatedBooking.id
                )
            );
        }
        if (
            (bookingUpdate.action === 'Cancel' ||
                bookingUpdate.action === 'Complete') &&
            bookingUpdate.updatedBooking.id === bookingToShowModalFor?.id
        ) {
            setBookingToShowModalFor(null);
            setBookingToShowModalForId(null);
        }
    };

    useNotificationListener(BookingUpdatedNotification, bookingUpdatedEvent => {
        if (
            isNotificationRelevant(
                selectedStoreOption,
                bookingUpdatedEvent.updatedBooking.store
            )
        ) {
            toast.info(
                <UpdateNotification heading={bookingUpdatedEvent.title}>
                    {bookingUpdatedEvent.message}
                </UpdateNotification>
            );
            handleBookingUpdate(bookingUpdatedEvent);
        }
    });

    useNotificationListener(
        RecurringBookingUpdatedNotification,
        recurringBookingUpdatedEvent => {
            if (
                isNotificationRelevant(
                    selectedStoreOption,
                    recurringBookingUpdatedEvent.updatedRecurringBooking.store
                )
            ) {
                toast.info(
                    <UpdateNotification
                        heading={recurringBookingUpdatedEvent.title}
                    >
                        {recurringBookingUpdatedEvent.message}
                    </UpdateNotification>
                );
                onRecurringBookingUpdate(recurringBookingUpdatedEvent);
            }
        }
    );

    return (
        <div className={className}>
            <BookingTable
                className={bookingTableClassName}
                bookings={bookings}
                loading={loading && !refreshingBookings}
                onDisplayModal={handleDisplayModalRequested}
                completingBookingsIds={completingBookingsIds}
                showAction={showAction}
                showStore={showStore}
            />
            {modalToDisplay === 'edit' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <EditModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'edit'}
                        onEmailSent={resetModalToDisplay}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'email' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <EmailModal
                        booking={bookingToShowModalFor}
                        bookingType="booking"
                        showModal={modalToDisplay === 'email'}
                        onEmailSent={resetModalToDisplay}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'cancel' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <CancelModalContainer
                        options={{
                            booking: bookingToShowModalFor,
                            bookingType: 'booking'
                        }}
                        showModal={modalToDisplay === 'cancel'}
                        onCancelBooking={resetModalToDisplay}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'recurringBookingSummary' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.recurringBooking &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <RecurringSummaryModal
                        recurringBooking={
                            bookingToShowModalFor.recurringBooking
                        }
                        showModal={modalToDisplay === 'recurringBookingSummary'}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'bookingSummary' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <BookingSummaryModalContainer
                        bookingId={bookingToShowModalFor.id}
                        showModal={modalToDisplay === 'bookingSummary'}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'reschedule' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <RescheduleModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'reschedule'}
                        onEmailSent={resetModalToDisplay}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'undoCheckIn' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <UndoCheckInModal
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'undoCheckIn'}
                        onUndoCheckIn={resetModalToDisplay}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'updateOrders' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <UpdateOrdersModalContainer
                        bookingId={bookingToShowModalFor.id}
                        showModal={modalToDisplay === 'updateOrders'}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'viewHistory' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <ViewHistoryModal
                        booking={bookingToShowModalFor}
                        type="booking"
                        showModal={modalToDisplay === 'viewHistory'}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'checkOut' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <CheckOutModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'checkOut'}
                        onCheckOutRequested={handleCheckOutRequested}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'updateResources' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <UpdateResourcesModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'updateResources'}
                        onUpdate={resetModalToDisplay}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'manageAttachments' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <AttachmentsModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'manageAttachments'}
                        onUpload={onRefresh}
                        onDelete={onRefresh}
                        onClose={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'checkIn' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <CheckInModalContainer
                        booking={bookingToShowModalFor}
                        allowCheckIn={allowCheckIn}
                        showModal={modalToDisplay === 'checkIn'}
                        onCheckIn={handleCheckIn}
                        onClose={handleCheckInModalClose}
                    />
                )}
            {modalToDisplay === 'instructions' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <InstructionsModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'instructions'}
                        onClose={resetModalToDisplay}
                        onSubmit={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'convertToDelivery' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <ConvertToDeliveryModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'convertToDelivery'}
                        onClose={resetModalToDisplay}
                        onConvertToDelivery={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'editContactNumber' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <EditContactNumberModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'editContactNumber'}
                        onClose={resetModalToDisplay}
                        onSave={resetModalToDisplay}
                    />
                )}
            {modalToDisplay === 'sendSms' &&
                bookingToShowModalFor &&
                bookingToShowModalFor.id === bookingToShowModalForId && (
                    <BookingSmsModalContainer
                        booking={bookingToShowModalFor}
                        showModal={modalToDisplay === 'sendSms'}
                        onClose={resetModalToDisplay}
                        onSave={resetModalToDisplay}
                    />
                )}
        </div>
    );
};

export default BookingTableContainer;
