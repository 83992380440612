import * as React from 'react';
import { useState } from 'react';
import SummaryModal from '../../../../components/bookings/view/modal/SummaryModal';
import saveFile from '../../../../components/helpers/saveFile';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingAttachment } from '../../../../models/bookings/BookingAttachment';
import { ThirdPartyCollectionOrder } from '../../../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import { useData } from '../../../../services/api';
import { downloadBookingAttachment } from '../../../../services/bookingService';

interface BookingSummaryModalContainerProps {
    bookingId: string;
    showModal: boolean;
    onClose(): void;
}

const BookingSummaryModalContainer: React.FC<
    BookingSummaryModalContainerProps
> = ({ bookingId, showModal, onClose }) => {
    const [originalBookingId] = useState<string>(bookingId);

    const [downloadingAttachments, setDownloadingAttachments] = useState<
        BookingAttachment[]
    >([]);

    const [booking, loadingBooking, fetchBooking] = useData<Booking>(
        originalBookingId && showModal ? `Booking/${originalBookingId}` : null
    );

    const [
        thirdPartyCollectionOrders,
        loadingThirdPartyCollectionOrders,
        fetchBookingThirdPartyOrders,
    ] = useData<ThirdPartyCollectionOrder[]>(
        booking?.load ? `Booking/${originalBookingId}/CollectionOrders` : null
    );

    const onDownloadRequested = async (attachment: BookingAttachment) => {
        setDownloadingAttachments((prev) => [...prev, attachment]);

        const downloadedFile = await downloadBookingAttachment(attachment);
        saveFile(downloadedFile, attachment.fileName);

        setDownloadingAttachments((prev) =>
            prev.filter((a) => a.id !== attachment.id)
        );
    };

    const handleDownloadRequested = (fileName: string) => {
        const matchedAttachment = booking?.attachments.find(
            (attachment) => attachment.fileName === fileName
        );
        matchedAttachment && onDownloadRequested(matchedAttachment);
    };

    return (
        <>
            {booking && (
                <SummaryModal
                    showModal={showModal}
                    booking={booking}
                    downloadingAttachmentNames={downloadingAttachments.map(
                        (attachment) => attachment.fileName
                    )}
                    loading={
                        loadingThirdPartyCollectionOrders || loadingBooking
                    }
                    thirdPartyCollectionOrders={thirdPartyCollectionOrders}
                    onBookingUpdate={fetchBooking}
                    onBookingOrdersUpdate={fetchBookingThirdPartyOrders}
                    onAttachmentDownloadRequested={handleDownloadRequested}
                    onClose={onClose}
                />
            )}
        </>
    );
};

export default BookingSummaryModalContainer;
