import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    marginTop: {
        marginTop: '2em',
    },
    formContainer: {
        padding: '2em',
        maxWidth: 650,
    },
    summaryContainer: {
        padding: '2em',
        maxWidth: 650,
        marginLeft: '0px',
    },
    nextButton: {
        float: 'right',
    },
    previousButton: {
        float: 'left',
    },
    zeroPadding: {
        padding: '0px',
    },
});
