import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import { UserRole } from '../../../helpers/userRole';
import { BookingType } from '../../../models/bookings/BookingType';
import { useAuth } from '../../../services/authentication';
import { getAvailableOptions } from '../../../services/recurringBookingOptionsService';
import { userInRole, userIsAdmin } from '../../../services/userService';
import { Theme } from '../../../theme';
import IconMenuLink from '../../common/IconMenuLink';

const useClasses = buildClassesHook((theme: Theme) => ({
    cancelButton: {
        color: theme.colors.error,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    primaryButton: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    iconButton: {
        padding: '5px',
    },
    icon: {
        marginRight: '10px',
        width: '1.5em !important',
    },
}));

export type RecurringBookingOptionType =
    | 'email'
    | 'edit'
    | 'viewHistory'
    | 'recurringBookingSummary'
    | 'cancel';

interface RecurringBookingOptionsProps {
    bookingType: BookingType;
    onOptionRequested(type: RecurringBookingOptionType): void;
}

const RecurringBookingOptions: React.FC<RecurringBookingOptionsProps> = ({
    bookingType,
    onOptionRequested,
}) => {
    const { authState } = useAuth();
    const isSuperUser =
        userIsAdmin(authState.user) ||
        userInRole(authState.user)(UserRole.ShiftManager);

    const classes = useClasses();

    const validOptions = getAvailableOptions(bookingType, isSuperUser);
    return (
        <>
            {validOptions.includes('email') && (
                <IconMenuLink
                    buttonClassName={classNames(
                        classes.iconButton,
                        classes.primaryButton
                    )}
                    label="Email"
                    icon="envelope"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('email')}
                />
            )}
            {validOptions.includes('edit') && (
                <IconMenuLink
                    buttonClassName={classNames(
                        classes.iconButton,
                        classes.primaryButton
                    )}
                    label="Edit"
                    icon="edit"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('edit')}
                />
            )}
            {validOptions.includes('viewHistory') && (
                <IconMenuLink
                    buttonClassName={classNames(
                        classes.iconButton,
                        classes.primaryButton
                    )}
                    label="View History"
                    icon="history"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('viewHistory')}
                />
            )}
            {validOptions.includes('cancel') && (
                <IconMenuLink
                    buttonClassName={classNames(
                        classes.iconButton,
                        classes.cancelButton
                    )}
                    label="Cancel"
                    icon="cross"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('cancel')}
                />
            )}
        </>
    );
};

export default RecurringBookingOptions;
