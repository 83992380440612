const splitToCols = <T>(arr: T[], width: number): T[][] => {
    if (width <= 1) return [arr];

    const cols: T[][] = [];
    let index = 0;

    arr.forEach(item => {
        if (!cols[index]) {
            cols[index] = [];
        }

        if (cols[index].length >= width) {
            ++index;
            cols[index] = [];
        }
        cols[index].push(item);
    });
    return cols;
};

export { splitToCols };
