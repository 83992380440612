import * as React from 'react';

import classNames from 'classnames';
import Table from '../../../../lib/bootstrap-ui/Table';
import { StockOut } from '../../../../models/orders/StockOut';
import { useClasses } from './StockOutTable.styles';

export interface OrderStockOut {
    customerOrderNumber: string;
    stockOuts: StockOut[];
}

interface StockOutTableProps {
    orderStockOuts: OrderStockOut[];
    loading: boolean;
}

const tableHeaders = [
    'Order',
    'Product Desc',
    'Product Code',
    'Cust. Pallet',
    'Ordered',
    'Short',
];

const StockOutTable: React.FC<StockOutTableProps> = ({
    orderStockOuts,
    loading,
}) => {
    const classes = useClasses();

    return (
        <Table
            className={classes.stockOutTable}
            headers={tableHeaders}
            loading={loading}
            placeholderRows={5}
            fixedHeader
        >
            {orderStockOuts.map((oso) => (
                <>
                    <tr key={oso.customerOrderNumber}>
                        <th
                            colSpan={tableHeaders.length}
                            className={classes.headerCell}
                        >
                            {oso.customerOrderNumber}
                        </th>
                    </tr>
                    {oso.stockOuts.map((stockOut) => (
                        <tr key={stockOut.id}>
                            <td className={classes.dataCell}></td>
                            <td
                                title={stockOut.productDesc}
                                className={classNames(
                                    classes.dataCell,
                                    classes.productDescriptionCell
                                )}
                            >
                                {stockOut.productDesc}
                            </td>
                            <td
                                title={stockOut.productCode}
                                className={classes.dataCell}
                            >
                                {stockOut.productCode}
                            </td>
                            <td
                                title={stockOut.customerPalletNumber}
                                className={classes.dataCell}
                            >
                                {stockOut.customerPalletNumber}
                            </td>
                            <td className={classes.dataCell}>
                                {stockOut.casesOrdered}
                            </td>
                            <td className={classes.dataCell}>
                                {stockOut.casesOrdered -
                                    stockOut.casesAvailable}
                            </td>
                        </tr>
                    ))}
                </>
            ))}
        </Table>
    );
};

export default StockOutTable;
