import { isSameDay } from 'date-fns';
import * as React from 'react';
import Table from '../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../lib/bootstrap-ui/Table/Table';
import { HistoricalBooking } from '../../../models/bookings/HistoricalBooking';
import { RouteKey } from '../../../Routes/routes';
import BookingTableDateRow from '../../bookings/view/table/BookingTableDateRow';
import NoDataFoundTableRow from '../../common/table/NoDataFoundTableRow';
import { useClasses } from './BookingsHistoryTable.styles';
import BookingsHistoryTableRow from './BookingsHistoryTableRow';

interface BookingsHistoryTableProps {
    historicalBookings: HistoricalBooking[];
    className?: string;
    loading: boolean;
    showStore?: boolean;
}

const bookingsHistoryTableHeaders: (string | HeaderDetails)[] = [
    {
        title: '',
        width: 5,
        noPadding: true,
    },
    { title: 'Reference', width: '9em' },
    'Type',
    { title: 'Haulier', displayFrom: 'xl' },
    'Start',
    'Customer',
    {
        title: 'Cust. Ref',
        displayFrom: 'xl',
        tooltip: 'Customer Reference',
    },
    {
        title: 'Reg',
        displayFrom: 'xl',
        tooltip: 'Registration Plate',
    },
    'Bay/Pager',
    {
        title: 'Checked In',
        width: 75,
    },
    {
        title: 'Checked Out',
        width: 75,
    },
    'Created By',
];

const BookingsHistoryTable: React.FC<BookingsHistoryTableProps> = ({
    historicalBookings,
    className,
    loading,
    showStore,
}) => {
    const classes = useClasses();

    const headers = showStore
        ? [...bookingsHistoryTableHeaders, 'Store', '']
        : [...bookingsHistoryTableHeaders, ''];

    return (
        <Table
            loading={loading}
            headers={headers}
            placeholderRows={15}
            fixedHeader
            hoverable={historicalBookings.length > 0}
            className={className}
        >
            <>
                {historicalBookings.map((b, i) => (
                    <React.Fragment key={i}>
                        {(i === 0 ||
                            !isSameDay(
                                new Date(
                                    historicalBookings[i - 1].booking.startDate
                                ),
                                new Date(b.booking.startDate)
                            )) && (
                            <BookingTableDateRow
                                date={new Date(b.booking.startDate)}
                                historicalBooking
                                colSpan={headers.length}
                            />
                        )}
                        <BookingsHistoryTableRow
                            historicalBooking={b}
                            showStoreResources={false}
                            showStore={showStore}
                        />
                    </React.Fragment>
                ))}
                {!loading && historicalBookings.length === 0 && (
                    <NoDataFoundTableRow
                        containerClassName={classes.noDataContainer}
                        messageText="No historical bookings match your filters"
                        iconType="no-bookings"
                        columnSpan={headers.length}
                        size="large"
                        alternativeLink={{
                            route: RouteKey.Bookings,
                            linkText: 'Search Active Bookings',
                        }}
                    />
                )}
            </>
        </Table>
    );
};

export default BookingsHistoryTable;
