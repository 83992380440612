import classnames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { buildClassesHook } from '../../../helpers/styles';

const useClasses = buildClassesHook({
    cursorNotAllowed: {
        cursor: 'not-allowed',
    },
});

interface ButtonProps {
    element?: 'button' | 'a' | 'input' | 'Link';
    styleType?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark'
        | 'link';
    outline?: boolean;
    size?: 'sm' | 'lg';
    block?: boolean;
    active?: boolean;
    disabled?: boolean;
    className?: string;
    to?: string;
    children: React.ReactNode;
    stopOnClickPropagation?: boolean;
    onClick?(event?: React.MouseEvent): void;
    [otherProps: string]: any;
}

const Button: React.FC<ButtonProps> = ({
    element = 'button',
    styleType = 'primary',
    outline = false,
    block = false,
    active = false,
    disabled = false,
    size,
    className,
    children,
    to,
    stopOnClickPropagation,
    onClick,
    ...otherProps
}: ButtonProps) => {
    const classes = useClasses();

    const allProps = {
        className: classnames(className, 'btn', {
            [`btn-${styleType}`]: !outline,
            [`btn-outline-${styleType}`]: outline,
            [`btn-${size}`]: size,
            [`btn-block`]: block,
            [classes.cursorNotAllowed]: disabled,
            active,
            disabled: disabled && element === 'a',
        }),
        'aria-pressed': active,
        disabled,
        ...otherProps,
    };

    const handleOnClick = (event: React.MouseEvent) => {
        if (!onClick) return;
        if (stopOnClickPropagation) {
            event.stopPropagation();
        }
        onClick(event);
    };

    switch (element) {
        case 'a':
            return (
                <a onClick={handleOnClick} {...allProps}>
                    {children}
                </a>
            );
        case 'button':
            return (
                <button onClick={handleOnClick} {...allProps}>
                    {children}
                </button>
            );
        case 'input':
            return (
                <input onClick={handleOnClick} {...allProps}>
                    {children}
                </input>
            );
        case 'Link':
            if (!to) {
                return <></>;
            }
            return (
                <Link to={to} {...allProps}>
                    {children}
                </Link>
            );
    }
};

export default Button;
