import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    stickyNoteIcon: {
        width: '1em',
        color: '#daa520',
        marginRight: '1em',
    },
    referenceData: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    typeColumn: {
        '& > div': {
            height: '2em',
            minWidth: 5,
        },
    },
    tableRow: {
        '& td': {
            padding: '0.5rem 0.75rem',
            whiteSpace: 'nowrap',
        },
        '& td$typeColumn': {
            width: 5,
            padding: 0,
            paddingTop: 5,
        },
    },
});
