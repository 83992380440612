import { Notification } from '.';
import { RecurringBooking } from '../bookings/RecurringBooking';
import NotificationBase from '../updates/NotificationBase';

type RecurringBookingUpdateAction =
    | 'Add'
    | 'Generate Bookings'
    | 'Update'
    | 'Cancel';

export interface RecurringBookingUpdated extends NotificationBase {
    updatedRecurringBooking: RecurringBooking;
    action: RecurringBookingUpdateAction;
}

export const RecurringBookingUpdatedNotification: Notification<RecurringBookingUpdated> = {
    message: 'RecurringBookingUpdated'
};
