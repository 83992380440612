import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    modalStyle: {
        maxHeight: '90vh',
        minWidth: '500px',
        maxWidth: '600px',
    },
    substitutionLink: {
        marginRight: '0.5rem',
        marginBottom: '0.25rem',
        padding: 0,
        color: `${theme.colors.primary} !important`,
    },
    textAreaStyle: {
        minHeight: '6.5em',
        maxHeight: '50vh',
    },
}));
