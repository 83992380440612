import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';

import CancelLoadModal from '../../components/load/modals/CancelLoadModal';
import ManagePickListsModal from '../../components/load/modals/ManagePickListsModal';
import UpdateBookingLoadOrdersModal from '../../components/load/modals/UpdateBookingLoadOrdersModal';
import UpdateNonIodStockTransferOrdersModal from '../../components/load/modals/UpdateNonIodStockTransferOrdersModal';
import OrderRemovalModal from '../../components/orders/modals/OrderRemovalModal';
import PiggybackOntoLoadModal from '../../components/piggyback/modals/PiggybackOntoLoadModal';
import PiggybackTransferToLoadModal from '../../components/piggyback/modals/PiggybackTransferToLoadModal';
import RemovePiggybacksModal from '../../components/piggyback/modals/RemovePiggybacksModal';
import StockOutModal from '../../components/stockOut/modals/view/StockOutModal';
import WorkQueueTable from '../../components/workQueue/WorkQueueTable';
import { Load } from '../../models/loads/Load';
import EditContactNumberModalContainer from '../load/update/EditContactNumberModalContainer';
import LoadSmsModalContainer from '../load/update/LoadSmsModalContainer';
import { useClasses } from './WorkQueueTableContainer.styles';

export type WorkQueueTableModal =
    | 'sendSms'
    | 'editContactNumber'
    | 'stockOuts'
    | 'addPiggybackOrders'
    | 'removePiggybackOrders'
    | 'piggybackTransfer'
    | 'removeOrders'
    | 'updateBookingLoadOrders'
    | 'updateNonIodStockTransferOrders'
    | 'managePickLists'
    | 'error'
    | 'cancelLoad';

interface WorkQueueTableContainerProps {
    className?: string;
    loads: Load[];
    loading: boolean;
    showError: boolean;
    onRedirectToRelease: (id: string) => void;
}

const WorkQueueTableContainer: React.FC<WorkQueueTableContainerProps> = ({
    className,
    loads,
    loading,
    showError,
    onRedirectToRelease
}) => {
    const classes = useClasses();

    const [
        modalToDisplay,
        setModalToDisplay
    ] = useState<WorkQueueTableModal | null>(null);

    const [loadToShowModalForId, setLoadToShowModalForId] = useState<
        string | null
    >(null);
    const [loadToShowModalFor, setLoadToShowModalFor] = useState<Load | null>();

    useEffect(() => {
        const updatedLoadToShow = loads?.find(
            l => l.id === loadToShowModalForId
        );
        if (updatedLoadToShow) {
            setLoadToShowModalFor(updatedLoadToShow);
        }
    }, [loadToShowModalForId, loads]);

    const handleDisplayModalRequested = (loadId: string) => (
        modalToDisplay: WorkQueueTableModal
    ) => {
        setLoadToShowModalForId(loadId);
        setModalToDisplay(modalToDisplay);
    };

    const closeModal = () => {
        setModalToDisplay(null);
    };

    return (
        <div className={classNames(classes.workQueueTableContainer, className)}>
            <WorkQueueTable
                loads={loads}
                onDisplayModal={handleDisplayModalRequested}
                loading={loading}
                showError={showError}
            />
            {loadToShowModalFor &&
                loadToShowModalFor.id === loadToShowModalForId && (
                    <>
                        {modalToDisplay === 'sendSms' && (
                            <LoadSmsModalContainer
                                load={loadToShowModalFor}
                                showModal={modalToDisplay === 'sendSms'}
                                onSave={closeModal}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'editContactNumber' && (
                            <EditContactNumberModalContainer
                                load={loadToShowModalFor}
                                showModal={
                                    modalToDisplay === 'editContactNumber'
                                }
                                onSave={closeModal}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'stockOuts' && (
                            <StockOutModal
                                load={loadToShowModalFor}
                                isOpen={modalToDisplay === 'stockOuts'}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'addPiggybackOrders' && (
                            <PiggybackOntoLoadModal
                                load={loadToShowModalFor}
                                isOpen={modalToDisplay === 'addPiggybackOrders'}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'removePiggybackOrders' && (
                            <RemovePiggybacksModal
                                load={loadToShowModalFor}
                                isOpen={
                                    modalToDisplay === 'removePiggybackOrders'
                                }
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'piggybackTransfer' && (
                            <PiggybackTransferToLoadModal
                                load={loadToShowModalFor}
                                isOpen={modalToDisplay === 'piggybackTransfer'}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'removeOrders' && (
                            <OrderRemovalModal
                                load={loadToShowModalFor}
                                isOpen={modalToDisplay === 'removeOrders'}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'updateBookingLoadOrders' && (
                            <UpdateBookingLoadOrdersModal
                                load={loadToShowModalFor}
                                showModal={
                                    modalToDisplay === 'updateBookingLoadOrders'
                                }
                                onSaveRedirect={onRedirectToRelease}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay ===
                            'updateNonIodStockTransferOrders' && (
                            <UpdateNonIodStockTransferOrdersModal
                                load={loadToShowModalFor}
                                showModal={
                                    modalToDisplay ===
                                    'updateNonIodStockTransferOrders'
                                }
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'managePickLists' && (
                            <ManagePickListsModal
                                load={loadToShowModalFor}
                                showModal={modalToDisplay === 'managePickLists'}
                                onClose={closeModal}
                            />
                        )}
                        {modalToDisplay === 'cancelLoad' && (
                            <CancelLoadModal
                                load={loadToShowModalFor}
                                showModal={modalToDisplay === 'cancelLoad'}
                                onClose={closeModal}
                            />
                        )}
                    </>
                )}
        </div>
    );
};

export default WorkQueueTableContainer;
