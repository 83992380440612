import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    recurringBookingSummaryContainer: {
        border: 'none',
        borderRadius: '5px',
        position: 'sticky',
        top: '30px',
        padding: '2em',
        margin: '0px',
    },
    recurringBookingSummaryRow: {
        marginBottom: '15px',
    },
    resourceItem: {
        listStyle: 'none',
    },
});
