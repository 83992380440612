import * as React from 'react';
import AdminContainer, {
    AdminPage,
} from '../../containers/admin/common/AdminContainer';
import Page from '../common/Page';

const ThirdPartyManagement: React.FC = () => (
    <Page>
        <AdminContainer page={AdminPage.SMSTemplateManagement} />
    </Page>
);

export default ThirdPartyManagement;
