import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    statsHeader: {
        color: theme.colors.mediumDarkGrey,
        textTransform: 'uppercase',
        fontSize: '0.85rem',
        fontWeight: 500,
    },
    statsValue: {
        color: theme.colors.darkGrey,
        fontSize: '1.75rem',
        fontWeight: 500,
        lineHeight: '2rem',
    },
    positive: {
        color: theme.colors.error,
    },
    negative: {
        color: theme.colors.successDark,
    },
    infoSection: {
        margin: 'auto 0.75rem',
    },
    comparisonSection: {
        fontSize: '1.2rem',
        margin: 'auto 0.15rem',
    },
    arrowIcon: {
        fontSize: '0.9rem',
    },
}));
