import DropdownOption from '../lib/bootstrap-ui/Dropdown/DropdownOption';

const getStoreDropdownOptions = (
    stores: string[],
    options?: { addAllStores?: boolean }
) => {
    const storeDropdownOptions = [] as DropdownOption[];
    const addAllStoresOption = options && options.addAllStores;

    if (addAllStoresOption) {
        storeDropdownOptions.push({
            label: 'All Stores',
            value: '',
        });
    }

    const storeOptions = stores
        .filter((store) => store.toString() !== '0')
        .map(
            (s): DropdownOption => ({
                label: `Store ${s}`,
                value: parseInt(s, 10),
            })
        );

    return storeDropdownOptions.concat(storeOptions);
};

const storeToCompanyCode = (store: number) => {
    if (store === 1) {
        return 1;
    }
    if (store === 2 || store === 3) {
        return 4;
    }
    if (store === 4) {
        return 5;
    }
    return 0;
};

export { getStoreDropdownOptions, storeToCompanyCode };
