import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import SignalRAlert from '../../components/signalR/SignalRAlert';
import { SignalRContext } from '../../contexts/SignalR';
import { buildClassesHook } from '../../helpers/styles';

const useClasses = buildClassesHook({
    signalRMessage: {
        position: 'fixed',
        width: '91vw',
        zIndex: 999,
        textAlign: 'center'
    }
});

const SignalRDisconnectContainer: React.FC = () => {
    const { retryConnect, retriesFailed, disconnected, retries } = useContext(
        SignalRContext
    );
    const [doneManualRetry, setDoneManualRetry] = useState(false);
    const [displayMessageIfError, setDisplayMessageIfError] = useState(true);

    const classes = useClasses();

    useEffect(() => {
        setDoneManualRetry(false);
        if (disconnected) {
            setDisplayMessageIfError(true);
        }
    }, [disconnected]);

    if (
        displayMessageIfError &&
        disconnected &&
        (retries > 1 || doneManualRetry)
    ) {
        if (!retriesFailed) {
            return (
                <SignalRAlert
                    className={classes.signalRMessage}
                    messageType="disconnect"
                    onDismiss={() => setDisplayMessageIfError(false)}
                />
            );
        }
        if (doneManualRetry) {
            return (
                <SignalRAlert
                    className={classes.signalRMessage}
                    messageType="reconnect failure"
                />
            );
        }
        return (
            <SignalRAlert
                className={classes.signalRMessage}
                messageType="retry"
                onRetryClick={() => {
                    setDoneManualRetry(true);
                    retryConnect();
                }}
            />
        );
    }

    return null;
};

export default SignalRDisconnectContainer;
