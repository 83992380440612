import { buildClassesHook } from '../../../../../helpers/styles';
import { Theme } from '../../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    labelText: {
        color: theme.colors.mediumDarkGrey,
        margin: '0 0.25rem',
        fontSize: '1.2rem',
        fontWeight: 500,
        lineHeight: 1.2,
    },
    contentCell: {
        minWidth: '12rem',
        '& h5': {
            fontSize: '1.2rem',
        },
    },
    '@media (max-width: 964px)': {
        contentCell: {
            minWidth: '8rem',
        },
    },
}));
