import * as React from 'react';
import { Row, Column } from '../../../lib/bootstrap-ui/Grid';
import { CreateBooking } from '../../../models/bookings/BookingToAdd';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import {
    BookingAttachment,
    UploadStatus,
} from '../../../models/bookings/BookingAttachment';
import { FileType } from '../../../models/common/FileType';
import {
    acceptedAttachmentFileTypes,
    IsCustomerNameValid,
} from '../../../services/bookingService';
import FileUploader from '../../../components/common/fileUpload/FileUploader';
import BookingDetailFields, {
    Property,
} from '../../../components/bookings/update/form/BookingDetailFields';

const BookingFormDetailsContainer: React.FC<FormStepProps<CreateBooking>> = ({
    formObject: booking,
    onChange: onBookingChange,
}) => {
    const handlePropertyChange = (name: Property) => (value: string | null) => {
        onBookingChange({ ...booking, [name]: value });
    };

    const handleFileAccepted = (files: File[]) => {
        const attachments: BookingAttachment[] = files.map((file) => ({
            fileName: file.name,
            type: file.type as FileType,
            status: UploadStatus.Pending,
        }));

        const newAttachmentFileNames = attachments.map(
            (attachment) => attachment.fileName
        );
        const nonDuplicateAttachments = booking.attachments.filter(
            (attachment) =>
                !newAttachmentFileNames.includes(attachment.fileName)
        );
        const nonDuplicateAttachmentFiles = booking.attachmentFiles.filter(
            (file) => !newAttachmentFileNames.includes(file.name)
        );

        onBookingChange({
            ...booking,
            attachments: [...nonDuplicateAttachments, ...attachments],
            attachmentFiles: [...nonDuplicateAttachmentFiles, ...files],
        });
    };

    const handleRemoveFile = (fileName: string) => {
        onBookingChange({
            ...booking,
            attachments: booking.attachments.filter(
                (attachment) => attachment.fileName !== fileName
            ),
            attachmentFiles: booking.attachmentFiles.filter(
                (file) => file.name !== fileName
            ),
        });
    };

    return (
        <div>
            <BookingDetailFields
                booking={booking}
                isCustomerValid={IsCustomerNameValid(
                    booking.customerName,
                    booking.bookingType
                )}
                onPropertyValueChange={handlePropertyChange}
            />
            <Row>
                <Column>
                    <FileUploader
                        files={booking.attachmentFiles.map((attachment) => ({
                            name: attachment.name,
                            type: attachment.type as FileType,
                        }))}
                        acceptedFileTypes={acceptedAttachmentFileTypes}
                        displayAlertOnFailure
                        showRemoveAction
                        onUploadRequested={handleFileAccepted}
                        onRemoveActionClicked={handleRemoveFile}
                    />
                </Column>
            </Row>
        </div>
    );
};

export default BookingFormDetailsContainer;
