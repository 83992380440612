import * as React from 'react';

import Table from '../../../lib/bootstrap-ui/Table';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import NoDataFoundTableRow from '../../common/table/NoDataFoundTableRow';
import { useClasses } from './RecurringBookingTable.styles';
import RecurringBookingTableRow from './RecurringBookingTableRow';

interface RecurringBookingTableProps {
    recurringBookings: RecurringBooking[];
    loading: boolean;
    showStore: boolean;
    recurringBookingTableClassName?: string;
    onRefreshBookings(): void;
}

const defaultTableHeaders = [
    {
        title: '',
        noPadding: true,
    },
    { title: 'Reference', width: '9em' },
    'Type',
    'Customer',
    'Recurrence',
    'Start',
    'End',
    'End Date',
    {
        title: 'ClT',
        tooltip: 'Clamp Truck',
    },
    {
        title: 'HbT',
        tooltip: 'Handball Team',
    },
    {
        title: 'CoT',
        tooltip: 'Counterbalance Truck',
    },
    {
        title: 'CvB',
        tooltip: 'Conveyor Belt',
    },
    {
        title: 'WS',
        tooltip: 'Weighing Scale',
    },
];

const RecurringBookingTable: React.FC<RecurringBookingTableProps> = ({
    recurringBookings,
    recurringBookingTableClassName,
    loading,
    onRefreshBookings,
    showStore,
}) => {
    const classes = useClasses();

    const headers = showStore
        ? [...defaultTableHeaders, 'Store', '']
        : [...defaultTableHeaders, ''];

    return (
        <Table
            className={recurringBookingTableClassName}
            loading={loading}
            headers={headers}
            placeholderRows={10}
            fixedHeader
            hoverable={recurringBookings && recurringBookings.length > 0}
        >
            {recurringBookings.map((rb, i) => (
                <RecurringBookingTableRow
                    key={i}
                    onRefreshBookings={onRefreshBookings}
                    recurringBooking={rb}
                    showStore={showStore}
                />
            ))}
            {!loading && recurringBookings.length === 0 && (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No recurring bookings match your filters"
                    iconType="no-bookings"
                    columnSpan={headers.length}
                    size="large"
                />
            )}
        </Table>
    );
};

export default RecurringBookingTable;
