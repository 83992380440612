import { buildClassesHook } from '../../../../../helpers/styles';
import { Theme } from '../../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    orderRow: {
        '& $iconButtonCell': {
            paddingTop: 0,
            paddingBottom: 0,
            verticalAlign: 'middle',
        },
    },
    cancelledOrderRow: {
        '& td': {
            textDecoration: 'line-through',
        },
    },
    iconButton: {
        border: 'none',
        padding: 0,
        paddingLeft: '10px',
    },
    iconButtonCell: {
        padding: 0,
        verticalAlign: 'middle',
        textAlign: 'right',
        '& svg': {
            fontSize: '1.35rem',
        },
    },
    piggybackIcon: {
        '--fa-primary-color': '#f783ac',
        '--fa-primary-opacity': 1,
        '--fa-secondary-color': '#fab5cd',
        '--fa-secondary-opacity': 0.8,
        '&:hover': {
            '--fa-primary-color': '#fab5cd',
            '--fa-primary-opacity': 0.8,
            '--fa-secondary-color': '#f783ac',
            '--fa-secondary-opacity': 1,
        },
    },
    errorIcon: {
        color: theme.colors.error,
    },
    warningIcon: {
        color: theme.colors.warning,
    },
}));
