import * as React from 'react';
import { toast } from 'react-toastify';
import UpdateNotification from '../components/common/UpdateNotification';
import { groupBy, removeDuplicates } from '../helpers/arrayFunctions';
import { LoadStatus } from '../models/loads/LoadStatus';
import { LoadWithPallets } from '../models/loads/LoadsWithPallets';
import { Pallet } from '../models/pallets/Pallet';
import { Change } from '../models/updates/Change';
import { KeyValuePair } from '../models/utils/KeyValuePair';
import {
    AddLoadAction,
    AddPalletAction,
    AddPalletsAction,
    RemoveLoadAction,
    RemovePalletAction,
    RemovePalletsAction,
    ReplaceAction,
    UpdateLoadAction,
    UpdatePalletAction
} from './LoadingProgressSummaryReducerActions';

const LoadingProgressSummaryReducer = (
    state: LoadWithPallets[],
    loadingTableAction:
        | ReplaceAction
        | AddLoadAction
        | UpdateLoadAction
        | RemoveLoadAction
        | AddPalletAction
        | AddPalletsAction
        | UpdatePalletAction
        | RemovePalletAction
        | RemovePalletsAction
): LoadWithPallets[] => {
    switch (loadingTableAction.type) {
        case 'REPLACE_LOADSWITHPALLETS':
            return loadingTableAction.payload;

        case 'ADD_LOAD':
            toast.info(
                <UpdateNotification heading="Load Released">
                    {loadingTableAction.payload.releasedLoad.loadName} released
                </UpdateNotification>
            );

            const newLoadWithPallets: LoadWithPallets = {
                ...loadingTableAction.payload.releasedLoad,
                pallets: loadingTableAction.payload.palletsForReleasedLoad
            };

            return [...state, newLoadWithPallets];

        case 'UPDATE_LOAD':
            const affectedLoad = state.find(
                l => l.id === loadingTableAction.payload.affectedLoad.id
            );

            if (!affectedLoad) {
                return state;
            }

            const isLoadComplete =
                loadingTableAction.payload.affectedLoad.status ===
                    LoadStatus.Completed &&
                affectedLoad.status !== LoadStatus.Completed;

            const changes: Change[] = loadingTableAction.payload.changes || [];

            if (changes.length > 0) {
                toast.info(
                    <UpdateNotification
                        heading={`Load ${
                            loadingTableAction.payload.affectedLoad.loadName
                        } ${isLoadComplete ? 'Completed' : 'Updated'}`}
                    >
                        {changes.map(c => (
                            <div key={c.id}>{c.message}</div>
                        ))}
                    </UpdateNotification>
                );
            }

            const unaffectedLoads = state.filter(
                l => l.id !== loadingTableAction.payload.affectedLoad.id
            );

            return isLoadComplete
                ? unaffectedLoads
                : [
                      ...unaffectedLoads,
                      {
                          ...loadingTableAction.payload.affectedLoad,
                          pallets: affectedLoad.pallets
                      }
                  ];

        case 'REMOVE_LOAD':
            if (!state.some(l => l.id === loadingTableAction.payload.loadId)) {
                return state;
            }

            toast.info(
                <UpdateNotification
                    heading={`Load ${loadingTableAction.payload.loadName} Removed`}
                />
            );

            return state.filter(
                l => l.id !== loadingTableAction.payload.loadId
            );

        case 'ADD_PALLET':
            const palletToAdd: Pallet = loadingTableAction.payload;
            const palletToAddOrderId: string = palletToAdd.orderId;

            return state.map(lwp => {
                if (
                    lwp.orderLoadingStatuses.some(
                        ols => ols.orderId === palletToAddOrderId
                    )
                ) {
                    return {
                        ...lwp,
                        pallets: [...lwp.pallets, palletToAdd]
                    };
                }
                return lwp;
            });

        case 'ADD_PALLETS':
            const palletsToAddPerOrder: KeyValuePair<
                string,
                Pallet[]
            >[] = groupBy(loadingTableAction.payload, 'orderId');
            const orderIdsToAddTo: string[] = palletsToAddPerOrder.map(
                kvp => kvp.key
            );

            return state.map((lwp: LoadWithPallets) => {
                if (
                    lwp.orderLoadingStatuses.some(ols =>
                        orderIdsToAddTo.includes(ols.orderId)
                    )
                ) {
                    const palletsToAddToLoad: Pallet[] = palletsToAddPerOrder
                        .filter(kvp =>
                            lwp.orderLoadingStatuses
                                .map(ols => ols.orderId)
                                .includes(kvp.key)
                        )
                        .reduce(
                            (
                                palletsToAdd: Pallet[],
                                currentKvp: KeyValuePair<string, Pallet[]>
                            ) => palletsToAdd.concat(currentKvp.value),
                            []
                        );

                    return {
                        ...lwp,
                        pallets: [...lwp.pallets, ...palletsToAddToLoad]
                    };
                }
                return lwp;
            });

        case 'UPDATE_PALLETS':
            const palletIdsToUpdate: string[] = loadingTableAction.payload.map(
                ap => ap.id
            );

            const updatePalletsOrderIds: string[] = loadingTableAction.payload.map(
                pallet => pallet.orderId
            );

            return state.map((lwp: LoadWithPallets) => {
                if (
                    lwp.orderLoadingStatuses.some(ols =>
                        updatePalletsOrderIds.includes(ols.orderId)
                    )
                ) {
                    const updatePalletsLoadOrderIds = lwp.orderLoadingStatuses.map(
                        ols => ols.orderId
                    );
                    const updatePalletsUnaffectedPallets =
                        lwp.pallets.filter(
                            p => !palletIdsToUpdate.includes(p.id)
                        ) || [];
                    const updatePalletsAffectedPallets =
                        loadingTableAction.payload.filter(ap =>
                            updatePalletsLoadOrderIds.includes(ap.orderId)
                        ) || [];
                    return {
                        ...lwp,
                        pallets: [
                            ...updatePalletsUnaffectedPallets,
                            ...updatePalletsAffectedPallets
                        ]
                    };
                }
                return lwp;
            });

        case 'REMOVE_PALLET':
            return state.map((lwp: LoadWithPallets) => {
                if (
                    lwp.orderLoadingStatuses.some(
                        ols =>
                            ols.orderId ===
                            loadingTableAction.payload.cancelledPalletOrderId
                    )
                ) {
                    return {
                        ...lwp,
                        pallets: lwp.pallets.filter(
                            p =>
                                p.id !==
                                loadingTableAction.payload.cancelledPalletId
                        )
                    };
                }
                return lwp;
            });

        case 'REMOVE_PALLETS':
            const palletIdsToRemove: string[] = loadingTableAction.payload.map(
                removedPallet => removedPallet.cancelledPalletId
            );
            const orderIdsToUpdate: string[] = removeDuplicates(
                loadingTableAction.payload.map(
                    removedPallet => removedPallet.cancelledPalletOrderId
                )
            );

            return state.map((lwp: LoadWithPallets) => {
                if (
                    lwp.orderLoadingStatuses.some(ols =>
                        orderIdsToUpdate.includes(ols.orderId)
                    )
                ) {
                    return {
                        ...lwp,
                        pallets: lwp.pallets.filter(
                            p => !palletIdsToRemove.includes(p.id)
                        )
                    };
                }
                return lwp;
            });
    }
};

export default LoadingProgressSummaryReducer;
