import classnames from 'classnames';
import * as React from 'react';

import DateRangePills, {
    DateRange,
} from '../../../components/common/DateRangePills';
import Icon from '../../../components/common/Icon';
import StoreSelectorPills, {
    StoreSelectorOption,
} from '../../../components/common/StoreSelectorPills';
import { useBreakpoint } from '../../../hooks/useWindowSize';
import Button from '../../../lib/bootstrap-ui/Button';
import { Booking } from '../../../models/bookings/Booking';
import DateInterval from '../../../models/common/DateInterval';
import { routeFor, RouteKey } from '../../../Routes/routes';
import BookingSummaryChart from '../view/summary/BookingSummaryChart';
import ResourceSummaryChart from '../view/summary/ResourceSummaryChart';
import { useClasses } from './BookingTableActionContainer.styles';

interface BookingTableActionContainerProps {
    className?: string;
    bookings?: Booking[];
    actionsToDisplay: Action[];
    activeFilters?: boolean;
    dateRange?: DateRange;
    storeOption?: StoreSelectorOption;
    storeOptions?: StoreSelectorOption[];

    onDateRangeChange?(
        dateRange: DateRange,
        dateInterval: DateInterval | null
    ): void;
    onStoreOptionChange?(storeOption: StoreSelectorOption): void;
    onAddBookingClicked?(): void;
    onFilterClicked?(): void;
    onFilterReset?(): void;
}

export interface Action {
    title: ActionType;
    disabled?: boolean;
}

export type ActionType =
    | 'add'
    | 'charts'
    | 'dateSelector'
    | 'storeSelector'
    | 'filters'
    | 'bookingNav'
    | 'recurringBookingNav'
    | 'historicalBookingNav';

const BookingTableActionContainer: React.FC<
    BookingTableActionContainerProps
> = ({
    className,
    activeFilters,
    actionsToDisplay,
    bookings,
    dateRange,
    storeOption,
    storeOptions,
    onDateRangeChange,
    onStoreOptionChange,
    onAddBookingClicked,
    onFilterClicked,
    onFilterReset,
}) => {
    const classes = useClasses();

    const isXLWidthOrAbove = useBreakpoint('xl');

    const actionsToDisplayIncludes = (actionType: ActionType) =>
        actionsToDisplay.some((y) => y.title === actionType);

    const actionIsDisabled = (actionType: ActionType) =>
        actionsToDisplay.find((x) => x.title === actionType)?.disabled;

    return (
        <div className={classnames(classes.actionContainer, className)}>
            <div className={classes.buttonContainer}>
                {actionsToDisplayIncludes('storeSelector') &&
                    storeOption &&
                    onStoreOptionChange && (
                        <StoreSelectorPills
                            onStoreOptionChange={onStoreOptionChange}
                            active={storeOption}
                            options={storeOptions}
                            className={classnames(
                                classes.navSection,
                                'd-none',
                                'd-lg-flex'
                            )}
                        />
                    )}

                {actionsToDisplayIncludes('dateSelector') &&
                    dateRange &&
                    onDateRangeChange && (
                        <DateRangePills
                            displayMonthDropdown
                            onDateRangeChange={onDateRangeChange}
                            active={dateRange}
                            hideNames={!isXLWidthOrAbove}
                            className={classes.navSection}
                        />
                    )}

                {actionsToDisplayIncludes('filters') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Filter Bookings"
                            onClick={(
                                e: React.MouseEvent<
                                    HTMLButtonElement,
                                    MouseEvent
                                >
                            ) => {
                                e.preventDefault();
                                onFilterClicked && onFilterClicked();
                            }}
                            styleType="link"
                            disabled={actionIsDisabled('filters')}
                        >
                            <Icon
                                type={activeFilters ? 'filter-solid' : 'filter'}
                            />
                        </Button>
                        {activeFilters && (
                            <Button
                                className={classes.button}
                                onClick={onFilterReset}
                                styleType="link"
                                disabled={actionIsDisabled('filters')}
                            >
                                <Icon type="filter-clear" />
                            </Button>
                        )}
                    </span>
                )}

                {actionsToDisplayIncludes('bookingNav') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Bookings"
                            element="Link"
                            to={routeFor(RouteKey.Bookings)()}
                            styleType="link"
                            disabled={actionIsDisabled('bookingNav')}
                        >
                            <Icon type="book" />
                        </Button>
                    </span>
                )}

                {actionsToDisplayIncludes('recurringBookingNav') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Recurring Bookings"
                            element="Link"
                            to={routeFor(RouteKey.RecurringBookings)()}
                            styleType="link"
                            disabled={actionIsDisabled('recurringBookingNav')}
                        >
                            <Icon type="recurring-booking" />
                        </Button>
                    </span>
                )}

                {actionsToDisplayIncludes('historicalBookingNav') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Bookings History"
                            element="Link"
                            to={routeFor(RouteKey.BookingsHistory)()}
                            styleType="link"
                            disabled={actionIsDisabled('historicalBookingNav')}
                        >
                            <Icon type="history" />
                        </Button>
                    </span>
                )}

                {actionsToDisplayIncludes('add') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Create Booking"
                            onClick={onAddBookingClicked}
                            styleType="link"
                            disabled={actionIsDisabled('add')}
                        >
                            <Icon type="plus" />
                        </Button>
                    </span>
                )}
            </div>

            {actionsToDisplayIncludes('charts') && (
                <div
                    className={classnames(
                        classes.chartsContainer,
                        'd-none',
                        'd-sm-flex'
                    )}
                >
                    {storeOption && storeOption !== 'All' && (
                        <>
                            <div className="d-none d-xl-block">
                                <BookingSummaryChart
                                    width={100}
                                    height={50}
                                    bookings={bookings || []}
                                />
                            </div>
                            <div className="d-none d-xl-block">
                                <ResourceSummaryChart
                                    width={100}
                                    height={50}
                                    bookings={bookings || []}
                                    store={storeOption}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default BookingTableActionContainer;
