import classNames from 'classnames';
import * as React from 'react';

import { useClasses } from './TableDividerRow.styles';

interface TableDividerRowProps {
    className?: string;
    colSpan?: number;
}

const TableDividerRow: React.FC<
    React.PropsWithChildren<TableDividerRowProps>
> = ({ className, colSpan = 1, children }) => {
    const classes = useClasses();

    return (
        <tr>
            <th
                className={classNames(classes.dateHeading, className)}
                colSpan={colSpan}
            >
                {children}
            </th>
        </tr>
    );
};

export default TableDividerRow;
