import { useEffect, useContext } from 'react';
import { SignalRContext } from '../contexts/SignalR';
import { Notification } from '../models/notifications';

export function useNotificationListener<T extends object>(
    notification: Notification<T>,
    handler: (data: T) => void,
    dependencies?: any[]
): void {
    const signalRContext = useContext(SignalRContext);

    if (!signalRContext) {
        throw new Error('Must be used inside a SignalRContextProvider');
    }

    const { subscribe } = signalRContext;

    // eslint-disable-next-line
    useEffect(() => subscribe(notification.message, handler), dependencies);
}
