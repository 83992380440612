import * as React from 'react';
import { useState } from 'react';

import AddBookingTypeSelectionModal from '../../../components/bookings/modals/AddBookingTypeSelectionModal';
import HeaderContainer from '../../../components/common/HeaderContainer';
import { StoreSelectorOption } from '../../../components/common/StoreSelectorPills';
import { UserRole } from '../../../helpers/userRole';
import usePersistedState from '../../../hooks/usePersistedState';
import { useCurrentUser } from '../../../services/authentication';
import {
    userInRole,
    userIsAdmin,
    userToAllStores,
} from '../../../services/userService';
import BookingTableActionContainer, {
    Action,
} from '../../bookings/bookingTable/BookingTableActionContainer';
import RecurringBookingTableContainer from './RecurringBookingTableContainer';
import { useClasses } from './RecurringBookingsContainer.styles';

const RecurringBookingContainer: React.FC = () => {
    const classes = useClasses();

    const user = useCurrentUser();

    const storeOptions: StoreSelectorOption[] = !user
        ? [1]
        : userToAllStores(user);
    if (userIsAdmin(user)) {
        storeOptions.push('All');
    }
    const showAddBookingButtons = !userInRole(user)(UserRole.Gatehouse);

    const [selectedStoreOption, setSelectedStoreOption] =
        usePersistedState<StoreSelectorOption>(
            storeOptions[0],
            'booking-store'
        );
    const [showAddModal, setShowAddModal] = useState(false);

    const getBookingActions = () => {
        let actions: Action[] = [{ title: 'bookingNav' }];

        if (showAddBookingButtons) {
            actions = [...actions, { title: 'add' }];
        }

        if (storeOptions.length > 1) {
            actions = [...actions, { title: 'storeSelector' }];
        }

        return actions;
    };

    return (
        <div className={classes.recurringBookingContainer}>
            <HeaderContainer headerText="Recurring Bookings">
                <BookingTableActionContainer
                    actionsToDisplay={getBookingActions()}
                    storeOption={selectedStoreOption}
                    storeOptions={storeOptions}
                    onStoreOptionChange={setSelectedStoreOption}
                    onAddBookingClicked={() => setShowAddModal(true)}
                />
            </HeaderContainer>
            <RecurringBookingTableContainer storeOption={selectedStoreOption} />
            {showAddModal && (
                <AddBookingTypeSelectionModal
                    open={showAddModal}
                    hideDefaultBookingTypeOptions
                    onRequestClose={() => setShowAddModal(false)}
                />
            )}
        </div>
    );
};

export default RecurringBookingContainer;
