import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    inputContainer: {
        display: 'flex',
        border: `1px solid ${theme.colors.lightGrey}`,
        borderRadius: '0.2rem',
    },
    focussed: {
        boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%)',
    },
    input: {
        border: 'none',
        '&:focus': {
            boxShadow: 'none',
        },
    },
    readonly: {
        backgroundColor: `${theme.colors.white} !important`,
    },
    clearButton: {
        padding: '0 0.5rem 0 0',
        color: theme.colors.mediumDarkGrey,
        '&:hover': {
            color: theme.colors.darkGrey,
        },
    },
}));
