import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    statusIcon: {
        marginRight: 6,
    },
    invalid: {
        color: theme.colors.error,
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    picking: {
        color: theme.colors.primary,
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    readyToRelease: {
        color: theme.colors.success,
        '&:hover': {
            color: theme.colors.successDark,
        },
    },
    statusButton: {
        textDecoration: 'underline',
        padding: 0,
        border: 0,
    },
}));
