import { Notification } from '.';
import {
    NewThirdPartyValueAdded,
    ThirdPartyValueUpdated
} from './ThirdPartyValue';

export const HaulierAddedNotification: Notification<NewThirdPartyValueAdded> = {
    message: 'HaulierAdded'
};

export const HaulierRemovedNotification: Notification<number> = {
    message: 'HaulierRemoved'
};

export const HaulierUpdatedNotification: Notification<ThirdPartyValueUpdated> = {
    message: 'HaulierUpdated'
};
