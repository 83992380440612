import { endOfDay } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';

import DatePicker from '../../common/DatePicker';
import { useClasses } from './RecurringBookingEndDateSelector.styles';

interface RecurringBookingEndDateSelectorProps {
    startDate: Date | null;
    endDate: Date | null;
    onEndDateChange(endDate: Date | null): void;
}

const RecurringBookingEndDateSelector: React.FC<
    RecurringBookingEndDateSelectorProps
> = ({ startDate, endDate, onEndDateChange }) => {
    const classes = useClasses();

    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
        endDate
    );

    const handleBookingEndDateChange = (selectedDate: Date | null) => {
        const newSelectedDate =
            selectedDate != null ? endOfDay(new Date(selectedDate)) : null;

        setSelectedEndDate(newSelectedDate);
        onEndDateChange(newSelectedDate);
    };

    return (
        <>
            <div className={classes.datePickerContainer}>
                <label>
                    If you would like this recurring booking to end on a
                    particular date, please select one below. Otherwise click
                    Next to continue.
                </label>
                <label className={classes.instructionsLabel}>
                    Please note: Bookings will be generated up to 23:59 on the
                    chosen date
                </label>
                <DatePicker
                    selectedDay={selectedEndDate}
                    minDate={startDate ?? undefined}
                    onDateSelected={handleBookingEndDateChange}
                />
            </div>
        </>
    );
};

export default RecurringBookingEndDateSelector;
