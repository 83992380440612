import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    bookingSummaryContainer: {
        padding: '0.5rem',
        margin: 0,
    },
    bookingSummaryRow: {
        marginBottom: '15px',
    },
    confirmButton: {
        marginLeft: '15px',
    },
    bookingSummaryReference: {
        marginBottom: '15px',
        fontSize: 18,
    },
    summaryRowData: {
        maxHeight: 100,
        overflow: 'auto',
    },
});
