import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import usePersistedState from '../../hooks/usePersistedState';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { routeFor, RouteKey } from '../../Routes/routes';
import { useCurrentUser } from '../../services/authentication';
import { userIsNotReader, userToAllStores } from '../../services/userService';
import NavSubItem from './NavSubItem';
import { useClasses } from './NavSubItems.styles';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

interface NavSubItemsProps {
    subItemsParentName: 'Work Queue' | 'Bookings';
    showSubItems: boolean;
    navExpanded: boolean;
}

const NavSubItems: React.FC<NavSubItemsProps> = ({
    subItemsParentName,
    showSubItems,
    navExpanded,
}) => {
    const user = useCurrentUser();
    const defaultStore = userToAllStores(user!)[0];

    const classes = useClasses();

    const [storeRoute, setStoreRoute] = useState({ store: defaultStore });
    const [currentStore, setCurrentStore] = useState(defaultStore);

    const [,] = usePersistedState<StoreNumber>(
        defaultStore,
        'loading-store',
        (newStore: StoreNumber) => setCurrentStore(newStore)
    );

    useEffect(() => {
        setStoreRoute({ store: currentStore });
    }, [currentStore]);

    const [createNoIodTransferFeature] = useFeatureFlag(
        'phase1_createNoIodTransfer'
    );

    const canCreateNoIodTransfer =
        !!createNoIodTransferFeature && userIsNotReader(user);

    return (
        <div
            className={classnames(
                classes.navSubItems,
                { [classes.navSubItemsExpanded]: navExpanded },
                {
                    [classes.navSubItemsHide]: !showSubItems,
                    [classes.navSubItemsShow]: showSubItems,
                }
            )}
        >
            {subItemsParentName === 'Work Queue' ? (
                <>
                    <NavSubItem
                        itemName="Progress Summary"
                        iconType="tasks-alt"
                        routeTo={routeFor(RouteKey.StoreProgressSummary)(
                            storeRoute
                        )}
                        navExpanded={navExpanded}
                    />

                    <NavSubItem
                        itemName="Work History"
                        iconType="history-duo"
                        routeTo={routeFor(RouteKey.StoreWorkHistory)(
                            storeRoute
                        )}
                        navExpanded={navExpanded}
                    />
                    {canCreateNoIodTransfer && (
                        <NavSubItem
                            itemName="Create Stock Transfer"
                            iconType="plus-solid"
                            routeTo={routeFor(RouteKey.CreateStockTransfer)()}
                            navExpanded={navExpanded}
                        />
                    )}
                </>
            ) : (
                <>
                    <NavSubItem
                        itemName="Recurring Bookings"
                        iconType="recurring-booking-duo"
                        routeTo={routeFor(RouteKey.RecurringBookings)()}
                        navExpanded={navExpanded}
                    />

                    <NavSubItem
                        itemName="Bookings History"
                        iconType="history-duo"
                        routeTo={routeFor(RouteKey.BookingsHistory)()}
                        navExpanded={navExpanded}
                    />
                </>
            )}
        </div>
    );
};

export default NavSubItems;
