import * as React from 'react';

import { useClasses } from './HeaderContainer.styles';

interface HeaderContainerProps {
    headerText: string;
    children: JSX.Element;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({
    headerText,
    children,
}) => {
    const classes = useClasses();

    return (
        <div className={classes.headerRow}>
            {children}
            <h2 className={classes.title}>{headerText}</h2>
        </div>
    );
};

export default HeaderContainer;
