import * as React from 'react';

interface UpdateNotificationProps {
    heading: string;
}

const UpdateNotification: React.FC<React.PropsWithChildren<
    UpdateNotificationProps
>> = ({ heading, children }) => (
    <>
        <h4>{heading}</h4>
        <div>{children}</div>
    </>
);

export default UpdateNotification;
