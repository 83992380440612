import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    workQueueContainer: {
        display: 'flex',
        height: '100%',
        flexFlow: 'column nowrap'
    },
    statisticsContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0.5rem'
    }
});
