import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    actionContainer: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colors.white,
        zIndex: 2,
        padding: '1rem',
    },
    adminPageOptionsContainer: {},
}));
