import * as React from 'react';
import { useState } from 'react';
import { Booking } from '../../../models/bookings/Booking';
import { BookingType } from '../../../models/bookings/BookingType';
import { apiPut } from '../../../services/api';
import ErrorModal from '../../common/ErrorModal';
import LoadingModal from '../../common/LoadingModal';
import UnsavedChangesModal from '../../common/UnsavedChangesModal';
import UpdateInternalWorkOrdersModal from '../../orders/UpdateInternalWorkOrdersModal';
import UpdateThirdPartyOrdersModal from '../../orders/UpdateThirdPartyOrdersModal';

interface UpdateOrdersModalProps {
    booking: Booking;
    showModal: boolean;
    onUpdate?(): void;
    onClose(): void;
}

const UpdateOrdersModal: React.FC<UpdateOrdersModalProps> = ({
    booking,
    showModal,
    onUpdate,
    onClose
}) => {
    const [error, setError] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(
        false
    );
    const [submittingUpdate, setSubmittingUpdate] = useState(false);
    const handleConfirmClicked = async (
        selectedOrderIds: string[],
        customerReference: string | null,
        customerName: string | null
    ) => {
        setSubmittingUpdate(true);
        try {
            await apiPut(`Booking/${booking.id}/Orders`, {
                orderIds: selectedOrderIds,
                customerReference: customerReference,
                customerName: customerName
            });
            setSubmittingUpdate(false);
            onUpdate && onUpdate();
            onClose();
        } catch (e) {
            console.warn(e);
            setError(!!e);
            setSubmittingUpdate(false);
        }
    };

    const handleRequestClose = (unsavedChanges: boolean) => {
        if (submittingUpdate) {
            return;
        }
        if (unsavedChanges) {
            setShowUnsavedChangesModal(true);
            return;
        }
        onClose();
    };

    const modalHeader = `Add/Remove Orders on ${booking.reference}`;

    const showUpdateOrdersModal =
        !submittingUpdate && !error && !showUnsavedChangesModal;

    return (
        <>
            <LoadingModal
                showModal={showModal && submittingUpdate}
                header={modalHeader}
                loadingText="updating booking"
            />
            <ErrorModal
                showModal={showModal && error}
                header={modalHeader}
                errorText="There was an error updating the booking&#39;s orders."
                onClose={onClose}
            />
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={onClose}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
            {booking.bookingType === BookingType.Internal ? (
                <UpdateInternalWorkOrdersModal
                    modalHeader={modalHeader}
                    booking={booking}
                    showModal={showModal && showUpdateOrdersModal}
                    isLoading={submittingUpdate}
                    requireChangesToSave
                    onSubmit={handleConfirmClicked}
                    onClose={handleRequestClose}
                />
            ) : (
                <UpdateThirdPartyOrdersModal
                    modalHeader={modalHeader}
                    booking={booking}
                    showModal={showModal && showUpdateOrdersModal}
                    isLoading={submittingUpdate}
                    requireChangesToSave
                    onSubmit={handleConfirmClicked}
                    onClose={handleRequestClose}
                />
            )}
        </>
    );
};

export default UpdateOrdersModal;
