import * as React from 'react';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';
import { Row, Column } from '../../../lib/bootstrap-ui/Grid';
import NameLookup from '../../../components/common/NameLookup';
import { TextArea } from '../../../lib/bootstrap-ui/Forms';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { BookingType } from '../../../models/bookings/BookingType';

const RecurringBookingDetailContainer: React.FC<FormStepProps<
    RecurringBookingToAdd
>> = ({ formObject: booking, onChange: onBookingChange }) => {
    const handleCustomerNameChange = (customerName: string) => {
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            customerName
        };
        onBookingChange(editedBooking);
    };

    const handleBookingInstructionChange = (value: string | null) => {
        const bookingInstructions = value;
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            bookingInstructions
        };
        onBookingChange(editedBooking);
    };

    const isNoBookingsBooking: boolean =
        booking.bookingType === BookingType.NoBookings;

    return (
        <>
            {!isNoBookingsBooking && (
                <Row>
                    <Column>
                        <NameLookup
                            lookupType="Customer"
                            defaultSearchValue={booking.customerName}
                            isValid={!!booking.customerName}
                            onNameSelected={handleCustomerNameChange}
                            required
                            autoConvertWhitespace
                        />
                    </Column>
                </Row>
            )}
            <Row>
                <Column>
                    <TextArea
                        id="BookingInstructions"
                        label={
                            isNoBookingsBooking
                                ? 'No Bookings Reason:'
                                : 'Booking Instructions:'
                        }
                        value={booking.bookingInstructions}
                        onChange={handleBookingInstructionChange}
                        autoConvertWhitespace
                        autoTrim
                    />
                </Column>
            </Row>
        </>
    );
};

export default RecurringBookingDetailContainer;
