import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { WorkQueueTableModal } from '../../containers/workQueue/WorkQueueTableContainer';
import { ALLOW_IOD_DELETION } from '../../environment-variables';
import debounce from '../../helpers/debounce';
import Button from '../../lib/bootstrap-ui/Button';
import { Load } from '../../models/loads/Load';
import { OrderLoadingStatus } from '../../models/orders/OrderLoadingStatus';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { apiPatch } from '../../services/api';
import { useCurrentUser } from '../../services/authentication';
import { getResequencePalletCountForLoad } from '../../services/iodPlanStatisticsService';
import { getAvailableOptions } from '../../services/loadOptionsService';
import {
    getLoadTrailerTypeName,
    getPiggybackedPalletCountForLoad,
    getSubcontractedString,
    getTotalDrops,
    getTotalPalletSpaces,
} from '../../services/loadService';
import {
    userHasAccessToStore,
    userIsNotReader,
    userIsTester,
} from '../../services/userService';
import Icon from '../common/Icon';
import MoreOptions from '../common/MoreOptions';
import HighlightableTableCell from '../common/table/HighlightableTableCell';
import IodDetailsLink from '../helpers/IodDetailsLink';
import PartLoadReference from '../load/common/view/PartLoadReference';
import LoadOrdersTable from '../load/loadOrders/table/view/LoadOrdersTable';
import BayOrPagerNumberCell from '../load/table/view/BayOrPagerNumberCell';
import ContactNumberCell from '../load/table/view/ContactNumberCell';
import LoadOptions from '../load/table/view/LoadOptions';
import LoadTimeRemaining from '../time/LoadTimeRemaining';
import { useClasses } from './WorkQueueTableRow.styles';
import { WorkQueueFeatureFlags } from '../../models/loads/WorkQueueFeatureFlags';
import { LoadStatus as LoadStatusEnum } from '../../models/loads/LoadStatus';
import LoadStatus from '../load/table/view/LoadStatus';

interface WorkQueueTableRowProps {
    load: Load;
    featureFlags: WorkQueueFeatureFlags;
    onDisplayModal: (
        loadId: string
    ) => (actionForModal: WorkQueueTableModal) => void;
}

const getUnloadStringForLoad = (load: Load | null): string => {
    if (!load) return '0';

    const unloadPalletCount: number = load?.orderLoadingStatuses.reduce(
        (unloadPalletCount: number, ols: OrderLoadingStatus) =>
            (unloadPalletCount += ols.isToUnload ? ols.order.palletSpaces : 0),
        0
    );

    const resequencePalletCount: number = getResequencePalletCountForLoad(load);

    return !!unloadPalletCount
        ? `${resequencePalletCount} (+${unloadPalletCount})`
        : `${resequencePalletCount}`;
};

const WorkQueueTableRow: React.FC<WorkQueueTableRowProps> = ({
    load,
    featureFlags,
    onDisplayModal,
}) => {
    const user = useCurrentUser();

    const userCanDeleteIods: boolean = ALLOW_IOD_DELETION && userIsTester(user);
    const userCanModifyLoad =
        userCanDeleteIods ||
        userHasAccessToStore(user, load.sourceStore as StoreNumber);
    const userNotReader = userIsNotReader(user);

    const classes = useClasses();

    const [previousLoad, setPreviousLoad] = useState<Load | null>(null);
    const [showOrders, setShowOrders] = useState(false);
    const [editBayOrPagerNumber, setEditBayOrPagerNumber] = useState(false);
    const [bayOrPagerNumber, setBayOrPagerNumber] = useState<number | null>(
        load.bayId || load.bayOrPagerNumber
    );

    const debouncedSetUpdateEnd = debounce(() => setPreviousLoad(load), 2000);

    useEffect(
        () => {
            if (previousLoad !== load) {
                debouncedSetUpdateEnd();
            }
        },
        // eslint-disable-next-line
        [load]
    );

    useEffect(() => {
        setBayOrPagerNumber(load.bayId || load.bayOrPagerNumber);
    }, [load.bayId, load.bayOrPagerNumber]);

    const handleBayOrPagerNumberSubmit = async () => {
        setEditBayOrPagerNumber(false);
        if (load.bayOrPagerNumber !== bayOrPagerNumber) {
            await apiPatch(`Load/${load.id}/BayOrPagerNumber`, {
                bayOrPagerNumber: bayOrPagerNumber,
            });
        }
    };

    const handleBayOrPagerNumberChange = (value: string | null) => {
        const newBayOrPagerNumber = value ? parseInt(value) : null;
        if (!newBayOrPagerNumber || newBayOrPagerNumber > 0) {
            setBayOrPagerNumber(newBayOrPagerNumber);
        }
    };

    const bayOrPagerNumberUpdated: boolean =
        !!previousLoad &&
        previousLoad.bayOrPagerNumber !== load.bayOrPagerNumber;

    const loadHasSameIdAsPrevious =
        !!previousLoad && previousLoad.id === load.id;

    const loadHasStockOuts: boolean = load.orderLoadingStatuses.some(
        (ols) => ols.hasStockOut
    );

    const previousLoadPiggybackedPallets: number = previousLoad
        ? getPiggybackedPalletCountForLoad(previousLoad)
        : 0;
    const piggybackedPallets: number = getPiggybackedPalletCountForLoad(load);
    const displayModalForLoad = onDisplayModal(load.id);

    const validOptionsForLoad = getAvailableOptions(
        load,
        featureFlags.piggybackFeature,
        featureFlags.updateTransferOrdersFeature,
        featureFlags.cancelNoIodTransferFeature
    );

    const showEditBayOrPagerButton =
        userNotReader &&
        (load.status === LoadStatusEnum.ReadyToRelease ||
            load.status === LoadStatusEnum.AwaitingBay) &&
        !bayOrPagerNumberUpdated &&
        load.isThirdParty;

    return (
        <>
            <tr key={load.id} onClick={() => setShowOrders((prev) => !prev)}>
                <td>
                    <Icon
                        type="chevron-down"
                        className={classNames(classes.expandIcon, {
                            [classes.expandIconExpand]: !showOrders,
                            [classes.expandIconCollapse]: showOrders,
                        })}
                    />
                </td>
                <HighlightableTableCell
                    highlightedClassName={classes.updatedLink}
                    className={classes.referenceCell}
                    highlight={previousLoad?.loadName !== load.loadName}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    <PartLoadReference load={load} displayAmendmentLink />
                    {loadHasStockOuts && (
                        <Button
                            title="Stock Outs"
                            element="button"
                            styleType="link"
                            className={classes.iconButton}
                            stopOnClickPropagation
                            onClick={() => displayModalForLoad('stockOuts')}
                        >
                            <Icon
                                className={classes.stockOutIcon}
                                type="empty-box"
                            />
                        </Button>
                    )}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        'd-none',
                        'd-md-table-cell',
                        classes.defaultCell
                    )}
                    highlight={
                        (previousLoad
                            ? getSubcontractedString(previousLoad)
                            : undefined) !== getSubcontractedString(load)
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {getSubcontractedString(load)}
                </HighlightableTableCell>
                <HighlightableTableCell
                    highlight={
                        previousLoad?.associatedIodNumber !==
                        load.associatedIodNumber
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    <IodDetailsLink load={load} />
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        'd-none',
                        'd-lg-table-cell',
                        classes.defaultCell
                    )}
                    highlight={previousLoad?.trailerType !== load.trailerType}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {getLoadTrailerTypeName(load)}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        'd-none',
                        'd-lg-table-cell',
                        classes.defaultCell
                    )}
                    highlight={previousLoad?.trailerId !== load.trailerId}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {load.trailerId}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        'd-none',
                        'd-sm-table-cell',
                        classes.defaultCell
                    )}
                    highlight={previousLoad?.loadType !== load.loadType}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {load.loadType}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classes.statusColumn}
                    highlight={previousLoad?.status !== load.status}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    <LoadStatus
                        load={load}
                        disableLinks={
                            !featureFlags.releaseLoadsFeature || !userNotReader
                        }
                        onClick={() =>
                            displayModalForLoad(
                                load.isNoIodStockTrailer
                                    ? 'updateNonIodStockTransferOrders'
                                    : 'updateBookingLoadOrders'
                            )
                        }
                    />
                </HighlightableTableCell>
                <HighlightableTableCell
                    highlight={previousLoad?.loadByTime !== load.loadByTime}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    <LoadTimeRemaining
                        loadByTime={load.loadByTime}
                        completedTime={load.completedAt}
                        useColors
                    />
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        classes.defaultCell,
                        classes.destinationColumn,
                        'd-none',
                        'd-lg-table-cell'
                    )}
                    highlight={previousLoad?.destination !== load.destination}
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {load.destination}
                </HighlightableTableCell>
                <ContactNumberCell
                    contactNumber={load.contactNumber}
                    previousContactNumber={previousLoad?.contactNumber || null}
                    load={load}
                    userCanEdit={userNotReader}
                    onEditContactNumberClick={() =>
                        displayModalForLoad('editContactNumber')
                    }
                    onSendSmsClick={() => displayModalForLoad('sendSms')}
                />
                <BayOrPagerNumberCell
                    className={classNames('d-none', 'd-xl-table-cell')}
                    editBayOrPagerNumber={editBayOrPagerNumber}
                    bayOrPagerNumber={bayOrPagerNumber}
                    load={load}
                    showEditButton={showEditBayOrPagerButton}
                    enableVirtualBayLink={
                        featureFlags.releaseLoadsFeature && userNotReader
                    }
                    previousValue={
                        previousLoad?.bayId || previousLoad?.bayOrPagerNumber
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                    onEditButtonClick={() => setEditBayOrPagerNumber(true)}
                    onChange={handleBayOrPagerNumberChange}
                    onSubmit={handleBayOrPagerNumberSubmit}
                />
                <HighlightableTableCell
                    className={classNames(
                        classes.defaultCell,
                        classes.numberCell
                    )}
                    highlight={
                        getUnloadStringForLoad(previousLoad) !==
                        getUnloadStringForLoad(load)
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {getUnloadStringForLoad(load)}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        classes.defaultCell,
                        classes.numberCell
                    )}
                    highlight={
                        (previousLoad && previousLoadPiggybackedPallets !== 0
                            ? `${
                                  previousLoad?.totalPalletSpaces -
                                  getPiggybackedPalletCountForLoad(previousLoad)
                              } (+${previousLoadPiggybackedPallets})`
                            : previousLoad?.totalPalletSpaces) !==
                        (piggybackedPallets !== 0
                            ? `${
                                  load.totalPalletSpaces - piggybackedPallets
                              } (+${piggybackedPallets})`
                            : load.totalPalletSpaces)
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {piggybackedPallets !== 0
                        ? `${
                              load.totalPalletSpaces - piggybackedPallets
                          } (+${piggybackedPallets})`
                        : load.totalPalletSpaces}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        'd-none',
                        'd-md-table-cell',
                        classes.numberCell,
                        classes.defaultCell
                    )}
                    highlight={
                        (previousLoad
                            ? getTotalPalletSpaces(previousLoad)
                            : undefined) !== getTotalPalletSpaces(load)
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {getTotalPalletSpaces(load)}
                </HighlightableTableCell>
                <HighlightableTableCell
                    className={classNames(
                        'd-none',
                        'd-xl-table-cell',
                        classes.numberCell,
                        classes.defaultCell
                    )}
                    highlight={
                        (previousLoad
                            ? getTotalDrops(previousLoad)
                            : undefined) !== getTotalDrops(load)
                    }
                    highlightUpdates={loadHasSameIdAsPrevious}
                >
                    {getTotalDrops(load)}
                </HighlightableTableCell>
                <td className={classes.piggybackCell}>
                    {(userCanDeleteIods || !!validOptionsForLoad?.length) && (
                        <MoreOptions>
                            {(close: () => void) => (
                                <LoadOptions
                                    loadingTableModals={validOptionsForLoad}
                                    load={load}
                                    userCanModifyLoad={userCanModifyLoad}
                                    userCanDeleteIods={userCanDeleteIods}
                                    onModalRequested={(type) => {
                                        close();
                                        displayModalForLoad(type);
                                    }}
                                />
                            )}
                        </MoreOptions>
                    )}
                </td>
            </tr>
            <tr style={{ border: 'none' }}>
                <td
                    colSpan={18}
                    className={classNames(classes.orderTableContent, {
                        [classes.orderTableContentExpanded]: showOrders,
                    })}
                >
                    <div
                        className={classNames(classes.orderRow, {
                            [classes.orderRowCollapsed]: !showOrders,
                            [classes.orderRowExpanded]: showOrders,
                        })}
                    >
                        <LoadOrdersTable
                            load={load}
                            piggybackFeature={
                                featureFlags.piggybackFeature && userNotReader
                            }
                        />
                    </div>
                </td>
            </tr>
        </>
    );
};

export default WorkQueueTableRow;
