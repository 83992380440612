import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    backButtonContentContainer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.35rem',
    },
    backButtonIcon: {
        marginRight: '0.5rem',
        fontSize: '1.5rem',
        textAlign: 'left',
    },
    summaryButton: {
        '&:hover': {
            backgroundColor: theme.colors.lightGrey,
            textDecoration: 'none',
            textAlign: 'left',
        },
    },
}));
