import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    piggybackTable: {
        '& thead th': {
            padding: '0.75rem 0.5rem',
        },
        '& td': {
            padding: '0.5rem',
        },
    },
    noDataContainer: {
        minHeight: 250,
    },
});
