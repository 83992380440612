import * as React from 'react';
import classnames from 'classnames';

interface LoadingSpinnerIconProps {
    size?: string | number;
    className?: string;
    color?: string;
}

const LoadingSpinnerIcon: React.FC<Readonly<LoadingSpinnerIconProps>> = ({
    className,
    size = '1em',
    color = '#524c4c'
}) => (
    <svg
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className={classnames('lds-rolling', className)}
        style={{ background: 'none' }}
    >
        <circle
            cx="50"
            cy="50"
            fill="none"
            stroke={color}
            strokeWidth="10"
            r="42"
            strokeDasharray="197.92033717615698 67.97344572538566"
            transform="rotate(207.92 50 50)"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.5s"
                begin="0s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
);

export default LoadingSpinnerIcon;
