import * as React from 'react';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import { useContext } from 'react';

interface BayCapabilityOptionsProps {
    selectedBayCapabilities: string[];
    onBayCapabilityChange: (capabilities: string[]) => void;
}

const BayCapabilityOptions: React.FC<BayCapabilityOptionsProps> = ({
    selectedBayCapabilities,
    onBayCapabilityChange
}) => {
    const hasBayBeenSelected = (capability: string) => {
        return (
            selectedBayCapabilities &&
            selectedBayCapabilities.includes(capability)
        );
    };

    const handleCapabilityValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const capability: string = event.target.value;
        if (event.target.checked) {
            onBayCapabilityChange([...selectedBayCapabilities, capability]);
        } else {
            onBayCapabilityChange(
                selectedBayCapabilities.filter(c => c !== capability)
            );
        }
    };

    const { bayCapabilities } = useContext(LookupContext);
    return (
        <div>
            {bayCapabilities.map(c => (
                <Checkbox
                    key={c}
                    checked={hasBayBeenSelected(c)}
                    label={c}
                    value={c}
                    onChange={handleCapabilityValueChange}
                />
            ))}
        </div>
    );
};

export default BayCapabilityOptions;
