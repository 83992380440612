import * as React from 'react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { formatDate } from '../../../../helpers/dates';
import Button from '../../../../lib/bootstrap-ui/Button';
import Card, {
    CardBody,
    CardText,
    CardTitle,
} from '../../../../lib/bootstrap-ui/Card';
import Grid, { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../../models/bookings/Booking';
import { Load } from '../../../../models/loads/Load';
import { InternalWorkOrder } from '../../../../models/orders/InternalWorkOrder';
import { StockTransferOrder } from '../../../../models/orders/StockTransferOrder';
import { ThirdPartyCollectionOrder } from '../../../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import { getColdStoreOrderText } from '../../../../services/ColdStoreOrderService';
import { useData } from '../../../../services/api';
import Icon from '../../../common/Icon';
import { useClasses } from './ColdStoreOrderCard.styles';

interface ColdStoreOrderCardProps<T extends TType> {
    cardClass?: string;
    collectionOrder: T;
    showAdditionalInfo?: boolean;
    onRequestRemove(orderId: string): void;
}

type TType = InternalWorkOrder | ThirdPartyCollectionOrder | StockTransferOrder;

const isStockTransferOrder = (
    entity: ThirdPartyCollectionOrder | StockTransferOrder
): entity is StockTransferOrder =>
    (entity as StockTransferOrder).lastAssociatedLoadId !== undefined;
const isBooking = (entity: Booking | Load): entity is Booking =>
    (entity as Booking).bookingType !== undefined;

const getAttachedEntityUrl = (order: TType): string | null => {
    if (isStockTransferOrder(order)) {
        return order.isAttached ? `Load/${order.lastAssociatedLoadId}` : null;
    }
    return order.isAttached ? `Booking/${order.lastAssociatedBookingId}` : null;
};

const getOrderAttachedText = (entity: Load | Booking): string => {
    if (isBooking(entity)) {
        return `Order is attached to Booking ${entity.reference}`;
    }
    return `Order is attached to Load ${entity.loadName}`;
};

function ColdStoreOrderCard<T extends TType>({
    cardClass,
    collectionOrder,
    showAdditionalInfo,
    onRequestRemove,
}: ColdStoreOrderCardProps<T>) {
    type OrderAttachEntity = T extends Pick<
        StockTransferOrder,
        'lastAssociatedLoadId'
    >
        ? Booking
        : Load;

    const classes = useClasses();

    const [associatedBookingForOrder, setAssociatedBookingForOrder] =
        useState<OrderAttachEntity>();

    const [bookingForOrder] = useData<OrderAttachEntity>(
        showAdditionalInfo ? getAttachedEntityUrl(collectionOrder) : null
    );

    useEffect(() => {
        if (bookingForOrder) {
            setAssociatedBookingForOrder(bookingForOrder);
        }
    }, [bookingForOrder]);

    return (
        <Card className={classNames(cardClass, classes.orderCard)}>
            <CardBody className={classes.orderCardBody}>
                <Grid>
                    <Row className={classes.titleRow}>
                        <Column size={11}>
                            <CardTitle className={classes.orderCardTitle}>
                                {getColdStoreOrderText(collectionOrder)}
                            </CardTitle>
                        </Column>
                        <Column size={1}>
                            {onRequestRemove && (
                                <Button
                                    className={classes.removeButton}
                                    styleType="link"
                                    onClick={() =>
                                        onRequestRemove(collectionOrder.id)
                                    }
                                >
                                    <Icon type="delete" />
                                </Button>
                            )}
                        </Column>
                    </Row>
                    {showAdditionalInfo && (
                        <Row>
                            <Column>
                                {associatedBookingForOrder && (
                                    <CardText
                                        className={classNames(
                                            classes.orderCardText,
                                            classes.additionalInfoText
                                        )}
                                    >
                                        {getOrderAttachedText(
                                            associatedBookingForOrder
                                        )}
                                    </CardText>
                                )}
                                {collectionOrder.isCancelled && (
                                    <CardText
                                        className={classNames(
                                            classes.orderCardText,
                                            classes.additionalInfoText
                                        )}
                                    >
                                        Order is Cancelled
                                    </CardText>
                                )}
                            </Column>
                        </Row>
                    )}
                    <Row>
                        <Column md={7}>
                            <Row>
                                <Column>
                                    <CardText className={classes.orderCardText}>
                                        {collectionOrder.customerCode}
                                    </CardText>
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <CardText className={classes.orderCardText}>
                                        Pallets: {collectionOrder.palletSpaces}
                                    </CardText>
                                </Column>
                            </Row>
                        </Column>
                        <Column md={5}>
                            <Row>
                                <Column className={classes.dateLabel}>
                                    <CardText>Order:</CardText>
                                </Column>
                                <Column className={classes.dateValue}>
                                    <CardText>
                                        {formatDate(
                                            collectionOrder.orderDate,
                                            'dd/MM/yyyy'
                                        )}
                                    </CardText>
                                </Column>
                            </Row>
                            <Row>
                                <Column className={classes.dateLabel}>
                                    <CardText>Delivery:</CardText>
                                </Column>
                                <Column className={classes.dateValue}>
                                    <CardText>
                                        {formatDate(
                                            collectionOrder.deliveryDate,
                                            'dd/MM/yyyy'
                                        )}
                                    </CardText>
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                </Grid>
            </CardBody>
        </Card>
    );
}

export default ColdStoreOrderCard;
