import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import UpdateNotification from '../../../components/common/UpdateNotification';
import LoadSmsModal from '../../../components/load/modals/LoadSmsModal';
import Bay from '../../../models/bays/Bay';
import { HttpErrorResponse } from '../../../models/common/HttpErrorResponse';
import { Load } from '../../../models/loads/Load';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { apiPatch, apiPost, useData } from '../../../services/api';
import { getErrorMessage } from '../../../helpers/errors';

interface LoadSmsModalContainerProps {
    load: Load;
    showModal: boolean;
    onSave?(): void;
    onClose(): void;
}

const LoadSmsModalContainer: React.FC<LoadSmsModalContainerProps> = ({
    load,
    showModal,
    onSave,
    onClose,
}) => {
    const [sendingLoadSms, setSendingLoadSms] = useState(false);
    const [updatingLoad, setUpdatingLoad] = useState(false);
    const [error, setError] = useState<HttpErrorResponse>();
    const getBaysUrl = () => {
        switch (load.trailerType) {
            case 'Handball':
                return `Store/${load.sourceStore}/Bays/ValidHandballBays`;
            case 'Specialised':
                return `Store/${load.sourceStore}/Bays/ValidStackUpBays`;
        }
        return `Store/${load.sourceStore}/Bays/Valid`;
    };

    const [bays, loadingBays] = useData<Bay[]>(getBaysUrl(), {
        loadingBaysOnly: String(load.isThirdParty),
    });
    const [smsTemplates, loadingSMSTemplates] = useData<SMSTemplate[]>(
        'SMSTemplate',
        {
            excludeBookingCheckInTemplate: String(true),
        }
    );

    const updateLoad = async (
        contactNumber: string,
        bayNumber: number,
        bayNumberUpdateable: boolean
    ) => {
        setUpdatingLoad(true);
        if (load.bayOrPagerNumber !== bayNumber && bayNumberUpdateable) {
            try {
                await apiPatch(`Load/${load.id}/BayOrPagerNumber`, {
                    bayOrPagerNumber: bayNumber,
                });
            } catch (e) {
                console.warn(e);
                setUpdatingLoad(false);
                setError(e as HttpErrorResponse);
                return;
            }
        }

        if (load.contactNumber !== contactNumber) {
            try {
                await apiPatch(`Load/${load.id}/ContactNumber`, {
                    contactNumber: contactNumber,
                });
            } catch (e) {
                console.warn(e);
                setUpdatingLoad(false);
                setError(e as HttpErrorResponse);
                return;
            }
        }
        setUpdatingLoad(false);
    };

    const sendSmsMessage = async (smsTemplateId: string) => {
        setSendingLoadSms(true);
        try {
            await apiPost(`Load/${load.id}/Sms`, {
                smsTemplateId,
            });
            toast.info(
                <UpdateNotification
                    heading={`SMS Message sent for Load ${load.loadName}`}
                >
                    Message sent:{' '}
                    {smsTemplates?.find((t) => t.id === smsTemplateId)?.name}
                </UpdateNotification>
            );
            if (onSave) {
                onSave();
            }
        } catch (e) {
            console.warn(e);
            setSendingLoadSms(false);
            setError(e as HttpErrorResponse);
        }
    };

    const handleSaveClicked = async (
        contactNumber: string,
        smsTemplateId: string,
        bayNumber: number,
        bayNumberUpdateable: boolean
    ) => {
        await updateLoad(contactNumber, bayNumber, bayNumberUpdateable);

        if (!error) {
            await sendSmsMessage(smsTemplateId);
        }
    };

    const handleLoadSmsModalClose = () => {
        if (sendingLoadSms) {
            return;
        }
        onClose();
    };

    const modalHeader = `Contact driver: ${load.loadName}`;
    const showLoadingModal =
        (updatingLoad ||
            sendingLoadSms ||
            loadingBays ||
            loadingSMSTemplates) &&
        !error;
    const showSmsModal =
        !updatingLoad &&
        !sendingLoadSms &&
        !loadingBays &&
        !loadingSMSTemplates &&
        !error;
    const loadingText = updatingLoad
        ? 'Updating load...'
        : sendingLoadSms
        ? 'Sending message...'
        : 'Loading...';

    return (
        <>
            <LoadingModal
                showModal={showModal && showLoadingModal}
                header={modalHeader}
                loadingText={loadingText}
            />
            <LoadSmsModal
                load={load}
                bays={bays ?? []}
                loadingBays={loadingBays}
                smsTemplates={smsTemplates || []}
                showModal={showModal && showSmsModal}
                onSave={handleSaveClicked}
                onClose={handleLoadSmsModalClose}
            />
            {error && (
                <ErrorModal
                    showModal={showModal && !!error}
                    header={modalHeader}
                    errorText={`An error has occurred when sending the text message: ${getErrorMessage(
                        error
                    )}`}
                    onClose={handleLoadSmsModalClose}
                />
            )}
        </>
    );
};

export default LoadSmsModalContainer;
