import classNames from 'classnames';
import { addDays, endOfMonth, startOfMonth } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import { buildClassesHook } from '../../helpers/styles';
import SplitButton from '../../lib/bootstrap-ui/Button/SplitButton';
import DropdownOption from '../../lib/bootstrap-ui/Dropdown/DropdownOption';
import DateInterval from '../../models/common/DateInterval';
import { theme } from '../../theme';
import Icon from '../common/Icon';
import IconButton from './IconButton';

const useClasses = buildClassesHook({
    navIcon: {
        marginRight: '0.5rem',
    },
    monthButton: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
            color: theme.colors.primaryDark,
        },
    },
    monthButtonNotSelected: {
        backgroundColor: theme.colors.white,
        color: theme.colors.primary,
    },
    monthButtonSelected: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover': {
            color: theme.colors.white,
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
});

const getDateIntervalForMonth = (month: number): DateInterval => {
    const currentDate = new Date();
    const startDate = startOfMonth(new Date(currentDate));
    startDate.setMonth(month);

    if (month < currentDate.getMonth()) {
        startDate.setFullYear(currentDate.getFullYear() + 1);
    }
    const endDate = endOfMonth(startDate);
    return {
        start: startDate,
        end: endDate,
    };
};

const monthDropdownOptions: DropdownOption[] = [
    {
        label: 'Jan',
        value: getDateIntervalForMonth(0),
    },
    {
        label: 'Feb',
        value: getDateIntervalForMonth(1),
    },
    {
        label: 'Mar',
        value: getDateIntervalForMonth(2),
    },
    {
        label: 'Apr',
        value: getDateIntervalForMonth(3),
    },
    {
        label: 'May',
        value: getDateIntervalForMonth(4),
    },
    {
        label: 'Jun',
        value: getDateIntervalForMonth(5),
    },
    {
        label: 'Jul',
        value: getDateIntervalForMonth(6),
    },
    {
        label: 'Aug',
        value: getDateIntervalForMonth(7),
    },
    {
        label: 'Sep',
        value: getDateIntervalForMonth(8),
    },
    {
        label: 'Oct',
        value: getDateIntervalForMonth(9),
    },
    {
        label: 'Nov',
        value: getDateIntervalForMonth(10),
    },
    {
        label: 'Dec',
        value: getDateIntervalForMonth(11),
    },
];

export type DateRange = 'Day' | 'Week' | 'Month' | 'All';

interface DateRangePillsProps {
    className?: string;
    active: DateRange;
    hideNames?: boolean;
    displayMonthDropdown?: boolean;
    onDateRangeChange(
        dateRange: DateRange,
        dateInterval: DateInterval | null
    ): void;
}

const DateRangePills: React.FC<DateRangePillsProps> = ({
    onDateRangeChange,
    hideNames,
    active,
    displayMonthDropdown = false,
    className,
}) => {
    const classes = useClasses();

    const [selectedMonth, setSelectedMonth] = useState<DropdownOption | null>(
        null
    );

    const shiftDropdownOptions = (options: DropdownOption[]) => {
        const shiftedOptions = [...options];
        const places = new Date().getMonth();
        for (let i = 0; i < places; i++) {
            const element = shiftedOptions.shift();
            shiftedOptions.push(element!);
        }
        return shiftedOptions;
    };

    const shiftedDropdownMonths = shiftDropdownOptions(monthDropdownOptions);

    const getDateInterval = (
        dateRange: DateRange,
        includeStartDate?: boolean
    ): DateInterval | null => {
        if (dateRange === 'All') {
            return null;
        }
        const startDate = new Date();
        const endDate = addDays(
            startDate,
            dateRange === 'Day' ? 1 : dateRange === 'Week' ? 7 : 30
        );
        return { start: includeStartDate ? startDate : null, end: endDate };
    };

    const handleMonthChange = (month: DropdownOption | null) => {
        onDateRangeChange('Month', month?.value);
        setSelectedMonth(month);
    };

    const handleDateRangeChange = (dateRange: DateRange) => {
        setSelectedMonth(null);
        onDateRangeChange(dateRange, getDateInterval(dateRange));
    };

    return (
        <div className={className}>
            <IconButton
                active={active === 'Day'}
                buttonText="Day"
                icon="calendar-day"
                hideText={hideNames}
                onClick={() => handleDateRangeChange('Day')}
            />
            <IconButton
                active={active === 'Week'}
                buttonText="Week"
                icon="calendar-week"
                hideText={hideNames}
                onClick={() => handleDateRangeChange('Week')}
            />
            {displayMonthDropdown ? (
                <SplitButton
                    className={classNames(
                        classes.monthButton,
                        active === 'Month'
                            ? classes.monthButtonSelected
                            : classes.monthButtonNotSelected
                    )}
                    dropdownOptions={shiftedDropdownMonths}
                    onDropdownOptionChange={handleMonthChange}
                    width={!hideNames ? 110 : 65}
                    selectedValue={selectedMonth?.value}
                    active={active === 'Month'}
                    onMainButtonClick={() => handleDateRangeChange('Month')}
                >
                    <Icon
                        type="calendar"
                        className={classNames({
                            [classes.navIcon]: !hideNames,
                        })}
                    />
                    {!hideNames && (selectedMonth?.label ?? 'Month')}
                </SplitButton>
            ) : (
                <IconButton
                    active={active === 'Month'}
                    buttonText="Month"
                    icon="calendar"
                    hideText={hideNames}
                    onClick={() => handleDateRangeChange('Month')}
                />
            )}
            <IconButton
                active={active === 'All'}
                buttonText="All"
                icon="calendar-blank"
                hideText={hideNames}
                onClick={() => handleDateRangeChange('All')}
            />
        </div>
    );
};

export default DateRangePills;
