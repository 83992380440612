import React from 'react';
import NavlinkArea from './NavlinkArea';
import NavItemContent from './NavItemContent';
import NavItem, { NavItemProps } from './NavItem';
import NavSubItems from './NavSubItems';

interface NavParentItemProps extends NavItemProps {
    showSubItems?: boolean;
    setShowSubItems?(): void;
    isExternalLink?: boolean;
}

const NavParentItem: React.FC<NavParentItemProps> = ({
    itemName,
    iconType,
    routeTo,
    target,
    navExpanded,
    horizontal,
    showSubItems,
    isExternalLink,
    setShowSubItems,
    isActive,
}) => {
    const hasSubItems = !!setShowSubItems;

    return (
        <>
            <NavlinkArea
                navExpanded={navExpanded}
                horizontal={horizontal}
                containsParentItem
            >
                <NavItem
                    itemName={itemName}
                    iconType={iconType}
                    routeTo={routeTo}
                    target={target}
                    navExpanded={navExpanded}
                    horizontal={horizontal}
                    isActive={isActive}
                    isExternalLink={isExternalLink}
                />

                {hasSubItems && (
                    <NavItemContent
                        iconType="chevron-down"
                        navExpanded={navExpanded}
                        showSubItems={showSubItems}
                        onClick={setShowSubItems}
                    />
                )}
            </NavlinkArea>

            {hasSubItems &&
                (itemName === 'Work Queue' || itemName === 'Bookings') && (
                    <NavSubItems
                        subItemsParentName={itemName!}
                        navExpanded={navExpanded}
                        showSubItems={showSubItems!}
                    />
                )}
        </>
    );
};

export default NavParentItem;
