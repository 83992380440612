export type TrailerType =
    | 'Single'
    | 'Decker'
    | 'Handball'
    | 'Specialised'
    | 'Van'
    | 'Internal'
    | 'Unknown';

export const StackUpLabel = 'Stack-Up';
export const DeTopLabel = 'De-Top';
