import classnames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import BookingSummary from '../../../../components/bookings/view/summary';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { buildClassesHook } from '../../../../helpers/styles';
import Grid, { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingType } from '../../../../models/bookings/BookingType';
import { RecurringBooking } from '../../../../models/bookings/RecurringBooking';
import { useData } from '../../../../services/api';

const useClasses = buildClassesHook({
    bookingSummaryContainer: {
        border: 'none',
        borderRadius: '5px',
        position: 'sticky',
        top: '30px',
        padding: '2em',
        margin: '0px',
    },
});

interface BookingSummaryContainerProps {
    id: string;
    type: 'recurring' | 'booking';
    hideSendEmail?: boolean;
    onContinue(): void;
}

const BookingSummaryContainer: React.FC<BookingSummaryContainerProps> = ({
    id,
    type,
    onContinue,
}) => {
    const classes = useClasses();

    const [sendEmail, setSendEmail] = useState(false);

    const [booking, loading] = useData<Booking | RecurringBooking>(
        type === 'booking' ? `Booking/${id}` : `RecurringBooking/${id}`
    );

    const bookingType = booking ? booking.bookingType : null;

    useEffect(() => {
        if (bookingType) {
            setSendEmail(
                bookingType !== BookingType.Resource &&
                    bookingType !== BookingType.Internal &&
                    bookingType !== BookingType.NoBookings
            );
        }
    }, [bookingType]);

    const getOptions = () => {
        if (type === 'recurring') {
            return {
                booking: booking as RecurringBooking,
                bookingType: type,
            };
        }

        return {
            booking: booking as Booking,
            bookingType: type,
        };
    };

    return loading ? (
        <LoadingIndicator />
    ) : (
        <Grid className={classnames(classes.bookingSummaryContainer)} fluid>
            <Row justify="center">
                <Column style={{ maxWidth: 650 }}>
                    <BookingSummary
                        options={getOptions()}
                        hideSendEmail={!sendEmail}
                        onContinue={onContinue}
                    />
                </Column>
            </Row>
        </Grid>
    );
};

export default BookingSummaryContainer;
