import * as React from 'react';
import { Column, Row } from '../../lib/bootstrap-ui/Grid/index';
import { RowProps } from '../../lib/bootstrap-ui/Grid/Row';

interface SingleColumnRowProps extends RowProps {
    columnSize?: string | number;
    rowJustifyContent?: string;
    columnClassName?: string;
}

const SingleColumnRow: React.FC<SingleColumnRowProps> = ({
    children,
    className,
    columnSize,
    columnClassName,
    rowJustifyContent,
    ...rest
}) => (
    <Row justify={rowJustifyContent} className={className} {...rest}>
        <Column className={columnClassName} size={columnSize}>
            {children}
        </Column>
    </Row>
);

export default SingleColumnRow;
