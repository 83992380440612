import classnames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { pluralise } from '../../../../helpers/stringFunctions';
import { buildClassesHook } from '../../../../helpers/styles';
import Grid from '../../../../lib/bootstrap-ui/Grid';
import { CreateBooking } from '../../../../models/bookings/BookingToAdd';
import { BookingType } from '../../../../models/bookings/BookingType';
import { getTrailerTypeLabelForBooking } from '../../../../services/trailerService';
import SingleColumnRow from '../../../common/SingleColumnRow';
import BookingSummaryRow from './BookingSummaryRow';

const useClasses = buildClassesHook({
    bookingSummaryContainer: {
        border: 'none',
        borderRadius: '5px',
        position: 'sticky',
        top: '30px',
        padding: '2em',
        margin: '0px',
    },
    bookingSummaryRow: {
        marginBottom: '15px',
    },
    resourceItem: {
        listStyle: 'none',
    },
    summaryRowData: {
        maxHeight: 200,
        overflow: 'auto',
    },
});

interface BookingFormSummaryContainerProps {
    booking: CreateBooking;
}

const BookingFormSummaryPanel: React.FC<BookingFormSummaryContainerProps> = ({
    booking,
}) => {
    const classes = useClasses();

    const renderCustomerInfo = (booking: CreateBooking) => (
        <>
            {booking.customerName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Customer Name"
                >
                    {booking.customerName}
                </BookingSummaryRow>
            )}

            {booking.haulierName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Haulier Name"
                >
                    {booking.haulierName}
                </BookingSummaryRow>
            )}

            {booking.customerReference && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Customer Ref."
                >
                    {booking.customerReference}
                </BookingSummaryRow>
            )}

            {(booking.customerName ||
                booking.haulierName ||
                booking.customerReference) && <hr />}
        </>
    );

    const renderProductInfo = (booking: CreateBooking) => (
        <>
            {(booking.bookingType === BookingType.Collection ||
                booking.bookingType === BookingType.DeliveryAndCollection ||
                booking.bookingType === BookingType.Internal) &&
                booking.orders.length > 0 && (
                    <BookingSummaryRow
                        dataColumnClassName={classes.summaryRowData}
                        header="Order Numbers"
                    >
                        {booking.orders.map((o, i) => (
                            <div key={i}>{o.orderNumber}</div>
                        ))}
                    </BookingSummaryRow>
                ) && (
                    <BookingSummaryRow header="Total Pallets">
                        {booking.orders.reduce(
                            (total, order) => (total += order.palletSpaces),
                            0
                        )}
                    </BookingSummaryRow>
                )}

            {booking.productCode && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Product Code"
                >
                    {booking.productCode}
                </BookingSummaryRow>
            )}

            {booking.supplierCode && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Supplier Code"
                >
                    {booking.supplierCode}
                </BookingSummaryRow>
            )}

            {booking.trailerType && (
                <BookingSummaryRow header="Load Type">
                    {getTrailerTypeLabelForBooking(booking)}
                </BookingSummaryRow>
            )}

            {booking.resources && booking.resources.length > 0 && (
                <BookingSummaryRow header="Resources">
                    {booking.resources.map((r, i) => (
                        <div key={i} className={'resourceItem'}>
                            {r}
                        </div>
                    ))}
                </BookingSummaryRow>
            )}
        </>
    );

    const renderTimeInfo = (booking: CreateBooking) => (
        <>
            {!!booking.selectedTimeSlot?.start && (
                <BookingSummaryRow header="Booking Time">
                    {format(
                        new Date(booking.selectedTimeSlot.start),
                        'dd/MM/yyyy HH:mm'
                    )}
                </BookingSummaryRow>
            )}

            {!!booking.duration && (
                <BookingSummaryRow header="Duration">
                    {pluralise('hour', booking.duration)}
                </BookingSummaryRow>
            )}
        </>
    );

    return (
        <Grid className={classnames(classes.bookingSummaryContainer)}>
            <SingleColumnRow className={classes.bookingSummaryRow}>
                <h4>{booking.bookingType} Booking Summary</h4>
            </SingleColumnRow>
            <hr />

            {booking.store && (
                <BookingSummaryRow header="Store">
                    {booking.store}
                </BookingSummaryRow>
            )}

            {renderCustomerInfo(booking)}
            {renderProductInfo(booking)}
            {renderTimeInfo(booking)}
        </Grid>
    );
};

export default BookingFormSummaryPanel;
