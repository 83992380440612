import { buildClassesHook } from '../../../../../../helpers/styles';
import { Theme } from '../../../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    palletDataRow: {
        '& td': {
            padding: '0 !important', //TODO: Fix Table component styling
            verticalAlign: 'middle !important', //TODO: Fix Table component styling
        },
    },
    rejectedPallet: {
        backgroundColor: theme.colors.errorLight,
        color: theme.colors.errorDark,
    },
    palletDataColumn: {
        padding: '0.25rem 0.5rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    loadingProgressMilestoneCell: {
        borderLeft: `2px solid ${theme.colors.darkGrey} !important` /*TODO: Fix Table component styling*/,
        padding: '0 !important', //TODO: Fix Table component styling
    },
}));
