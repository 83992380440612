import * as React from 'react';
import classNames from 'classnames';

const ButtonGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    children,
    className,
    ...otherProps
}) => (
    <div className={classNames('btn-group', className)} {...otherProps}>
        {children}
    </div>
);

export default ButtonGroup;
