export enum UserRole {
    Admin = 'Admin',
    Store1 = 'Store1',
    Store2 = 'Store2',
    Store3 = 'Store3',
    Store4 = 'Store4',
    Gatehouse = 'Gatehouse',
    ShiftManager = 'ShiftManager',
    Tester = 'Tester',
    DockSystemReader = 'DockSystemReader',
}
