import { compareDesc, isBefore } from 'date-fns';
import { IodPlan, FinishingTrailerTrip } from '../models/iods/IodPlan';
import { Drop } from '../models/orders/Drop';
import { Load } from '../models/loads/Load';
import {
    getAllFinishingTrailerLoadsFromIodPlan,
    getAllTransferLoadsFromIodPlan,
    getLoadRoleInPlan,
    getTransfersFromIodPlanForFinishingTrailerLoad
} from './iodPlanService';
import { OrderLoadingStatus } from '../models/orders/OrderLoadingStatus';
import { getAllDropsInSequence } from './orderService';
import { getOrdersOnIodPlanUntilLoad } from './iodPlanService';
import { TrailerType } from '../models/trailers/TrailerType';
import { getPalletsToPickForIod } from './loadService';

const deckerHeavyPalletThreshold = 12;

export interface IodPlanStatistics {
    totalPalletCount: number;
    transferCount: number;
    transferredPalletCount: number;
    tunnelledPalletCount: number;
    resequencePalletCount: number;
}

const getResequencePalletCount = (
    ordersToLoad: OrderLoadingStatus[],
    alreadyLoadedOrders: OrderLoadingStatus[],
    isDecker: boolean
): number => {
    if (alreadyLoadedOrders.length === 0) return 0;

    // Deckers currently loaded to avoid resequencing for drop order (either by having 1 drop or 2 drops with one drop per deck)
    if (isDecker) {
        const ordersLoadedWithHeavyPallets: OrderLoadingStatus[] = alreadyLoadedOrders.filter(
            o => o.order.containsHeavyPallets
        );

        const numberOfHeavyPalletsLoaded: number = ordersLoadedWithHeavyPallets.reduce(
            (rv, curr) => rv + curr.order.palletSpaces,
            0
        );

        if (numberOfHeavyPalletsLoaded > deckerHeavyPalletThreshold) {
            return numberOfHeavyPalletsLoaded - deckerHeavyPalletThreshold;
        }
        return 0;
    }

    const allDropsInSequence: Drop[] = getAllDropsInSequence(
        ordersToLoad.concat(alreadyLoadedOrders)
    );

    const latestDeliveryDateToBeLoaded: Date = allDropsInSequence
        .filter(d => d.orders.some(o => ordersToLoad.includes(o)))
        .sort(
            (a, b) => a && b && compareDesc(a.deliveryDate, b.deliveryDate)
        )[0].deliveryDate;

    const ordersToResequence: OrderLoadingStatus[] = allDropsInSequence
        .filter(d => isBefore(d.deliveryDate, latestDeliveryDateToBeLoaded))
        .map(d => d.orders)
        .reduce((prev, curr) => prev.concat(curr), [])
        .filter(o => alreadyLoadedOrders.includes(o));

    return ordersToResequence.reduce(
        (rv, curr) => rv + curr.order.palletSpaces,
        0
    );
};

export const getResequencePalletCountForLoadInIodPlan = (
    iodPlan: IodPlan,
    currentLoad: Load
): number => {
    if (
        currentLoad.orderLoadingStatuses.length === 0 ||
        getLoadRoleInPlan(iodPlan, currentLoad) === 'Transfer' ||
        currentLoad.id === iodPlan.trip1.load.id
    ) {
        return 0;
    }

    const ordersAlreadyLoadedBeforeCurrentLoad: OrderLoadingStatus[] = getOrdersOnIodPlanUntilLoad(
        iodPlan,
        currentLoad
    );

    const ordersToBeLoadedForCurrentLoad: OrderLoadingStatus[] = currentLoad.orderLoadingStatuses
        .concat(
            ...getTransfersFromIodPlanForFinishingTrailerLoad(
                iodPlan,
                currentLoad
            ).map(transfer => transfer.orderLoadingStatuses)
        )
        .filter(ols => ols.releaseToChess);

    return getResequencePalletCount(
        ordersToBeLoadedForCurrentLoad,
        ordersAlreadyLoadedBeforeCurrentLoad,
        currentLoad.trailerType === 'Decker'
    );
};

export const getResequencePalletCountForLoad = (currentLoad: Load): number => {
    const ordersAlreadyLoadedBeforeCurrentLoad: OrderLoadingStatus[] = currentLoad.orderLoadingStatuses.filter(
        ols => !ols.isToReleaseOrLoad && !ols.isToUnload && !ols.isTransportOnly
    );
    const ordersToBeLoadedForCurrentLoad: OrderLoadingStatus[] = currentLoad.orderLoadingStatuses.filter(
        ols => ols.isToReleaseOrLoad
    );

    return getResequencePalletCount(
        ordersToBeLoadedForCurrentLoad,
        ordersAlreadyLoadedBeforeCurrentLoad,
        currentLoad.trailerType === 'Decker'
    );
};

const getIodPlanResequencePalletCount = (iodPlan: IodPlan): number => {
    const finishingTrailerLoads: Load[] =
        ([iodPlan.trip1.load, iodPlan.trip2?.load, iodPlan.trip3?.load].filter(
            load => !!load
        ) as Load[]) || [];

    return finishingTrailerLoads.reduce<number>(
        (resequencePalletCount, finishingTrailerLoad) =>
            resequencePalletCount +
            getResequencePalletCountForLoadInIodPlan(
                iodPlan,
                finishingTrailerLoad
            ),
        0
    );
};

const getTransferPalletSpaces = (transfer: Load): number => {
    return transfer.orderLoadingStatuses
        .filter(o => !o.isCancelled && o.order.store === transfer.sourceStore)
        .reduce<number>((rv, curr) => rv + curr.order.palletSpaces, 0);
};

export const getTransportOnlyPalletCountForLoad = (load?: Load): number => {
    if (load == null) {
        return 0;
    }
    if (!load.associatedIodId) {
        return 0;
    }
    const orders = load.orderLoadingStatuses
        .filter(ols => ols.isTransportOnly)
        .map(o => o.order);
    return orders.reduce((rv, curr) => rv + curr.palletSpaces, 0);
};

const getMaximumPalletsToTunnelForTrailerType = (
    trailerType: TrailerType
): number => {
    switch (trailerType) {
        case 'Single': {
            return 8;
        }
        case 'Decker': {
            return 12;
        }
        default: {
            return 0;
        }
    }
};

const isEligibleForTunnel = (transfer: Load, successor: Load): boolean =>
    (transfer.sourceStore === 2 || transfer.sourceStore === 3) &&
    (successor.sourceStore === 2 || successor.sourceStore === 3) &&
    getTransferPalletSpaces(transfer) <=
        getMaximumPalletsToTunnelForTrailerType(successor.trailerType);

const getTransferredPalletsForFinishingTrailerTrip = (
    trip: FinishingTrailerTrip,
    type: 'transfer' | 'tunnel'
): number => {
    if (type === 'transfer') {
        return trip.predecessorTransfers
            .filter(transfer => !isEligibleForTunnel(transfer, trip.load))
            .reduce<number>(
                (palletSpaces, transfer) =>
                    palletSpaces +
                    getPalletsToPickForIod(transfer, trip.load.associatedIodId),
                0
            );
    }

    return trip.predecessorTransfers
        .filter(transfer => isEligibleForTunnel(transfer, trip.load))
        .reduce<number>(
            (palletSpaces, transfer) =>
                palletSpaces +
                getPalletsToPickForIod(transfer, trip.load.associatedIodId),
            0
        );
};

export const getIodPlanStatistics = (iodPlan: IodPlan): IodPlanStatistics => {
    const totalPalletCount: number = getAllFinishingTrailerLoadsFromIodPlan(
        iodPlan
    ).reduce(
        (totalPalletSpaces: number, finishingTrailerLoad: Load) =>
            totalPalletSpaces +
            finishingTrailerLoad.orderLoadingStatuses
                .filter(ols => ols.isToReleaseOrLoad || ols.isTransportOnly)
                .reduce(
                    (
                        loadPalletSpaces: number,
                        orderLoadingStatus: OrderLoadingStatus
                    ) =>
                        orderLoadingStatus.order.iodId === iodPlan.iod.id
                            ? loadPalletSpaces +
                              orderLoadingStatus.order.palletSpaces
                            : loadPalletSpaces,
                    0
                ),
        0
    );

    const transferCount: number = getAllTransferLoadsFromIodPlan(iodPlan)
        .length;

    const resequencePalletCount: number = getIodPlanResequencePalletCount(
        iodPlan
    );

    const transferredPalletCount: number =
        getTransferredPalletsForFinishingTrailerTrip(
            iodPlan.trip1,
            'transfer'
        ) +
        (iodPlan.trip2
            ? getTransferredPalletsForFinishingTrailerTrip(
                  iodPlan.trip2,
                  'transfer'
              )
            : 0) +
        (iodPlan.trip3
            ? getTransferredPalletsForFinishingTrailerTrip(
                  iodPlan.trip3,
                  'transfer'
              )
            : 0);

    const tunnelledPalletCount: number =
        getTransferredPalletsForFinishingTrailerTrip(iodPlan.trip1, 'tunnel') +
        (iodPlan.trip2
            ? getTransferredPalletsForFinishingTrailerTrip(
                  iodPlan.trip2,
                  'tunnel'
              )
            : 0) +
        (iodPlan.trip3
            ? getTransferredPalletsForFinishingTrailerTrip(
                  iodPlan.trip3,
                  'tunnel'
              )
            : 0);

    return {
        totalPalletCount,
        transferCount,
        transferredPalletCount,
        tunnelledPalletCount,
        resequencePalletCount
    };
};
