import * as React from 'react';

const UnauthorisedUser: React.FC = () => (
    <div>
        You do not have the correct access permissions. If this is incorrect,
        please contact the system administrator.
    </div>
);

export default UnauthorisedUser;
