import * as React from 'react';
import Table, { TableDividerRow } from '../../../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../../../lib/bootstrap-ui/Table/Table';
import { Load } from '../../../../../models/loads/Load';
import { LoadStatus } from '../../../../../models/loads/LoadStatus';
import { OrderLoadingStatus } from '../../../../../models/orders/OrderLoadingStatus';
import LoadOrderRow from './LoadOrderRow';
import { useClasses } from './LoadOrdersTable.styles';

interface LoadOrdersTableProps {
    load: Load;
    piggybackFeature?: boolean;
}

const loadOrdersTableHeaders: (string | HeaderDetails)[] = [
    { title: 'Job Number', width: '7.5rem', top: 0 },
    { title: 'Customer Order Number', width: '10rem', top: 0 },
    { title: 'Customer', width: '15rem', top: 0 },
    { title: 'Destination', width: '15rem', top: 0 },
    { title: 'Pallets', width: '3rem', top: 0 },
    { title: 'IOD', width: '6rem', top: 0 },
    { title: 'Store', width: '6rem', top: 0 },
    { title: 'Action', width: '2rem', hide: true, top: 0 },
];

const sortAndGroupOrders = (
    orders: OrderLoadingStatus[]
): OrderLoadingStatus[] => {
    const sortedUniqueDeliveryAddresses: string[] = orders
        .sort(
            (lhs, rhs) =>
                new Date(rhs.order.deliveryDate).valueOf() -
                new Date(lhs.order.deliveryDate).valueOf()
        )
        .map((ols) => ols.order.deliveryAddress)
        .filter((v, i, s) => s.indexOf(v) === i);

    const groupedOrders: OrderLoadingStatus[][] =
        sortedUniqueDeliveryAddresses.map((da) =>
            orders
                .filter((ols) => da === ols.order.deliveryAddress)
                .sort(
                    (lhs, rhs) =>
                        new Date(rhs.order.deliveryDate).valueOf() -
                        new Date(lhs.order.deliveryDate).valueOf()
                )
        );

    return groupedOrders.reduce((lhs, rhs) => lhs.concat(rhs), []);
};

const LoadOrdersTable: React.FC<LoadOrdersTableProps> = ({
    load,
    piggybackFeature = false,
}) => {
    const classes = useClasses();

    const piggybackOnOrders: OrderLoadingStatus[] = !load.isTransfer
        ? load.orderLoadingStatuses.filter(
              (o) =>
                  o.order.iodId !== load.associatedIodId && o.isToReleaseOrLoad
          )
        : [];
    const piggybackOffOrders: OrderLoadingStatus[] = !load.isTransfer
        ? load.orderLoadingStatuses.filter(
              (o) => o.order.iodId !== load.associatedIodId && o.isToUnload
          ) ?? []
        : [];

    const transportOnlyOrders: OrderLoadingStatus[] =
        load.orderLoadingStatuses.filter(
            (o) => o.order.iodId === load.associatedIodId && o.isTransportOnly
        );

    const ordersCanBeAmended =
        !load.isReleased &&
        load.isStockTrailer &&
        !load.isCancelled &&
        load.status !== LoadStatus.Completed;

    return (
        <>
            <Table
                className={classes.loadOrdersTable}
                headers={loadOrdersTableHeaders}
            >
                {sortAndGroupOrders(
                    load.orderLoadingStatuses.filter(
                        (ols) =>
                            (load.isTransfer ||
                                ols.order.iodId === load.associatedIodId) &&
                            ols.isToReleaseOrLoad
                    )
                ).map((ols) => (
                    <LoadOrderRow
                        key={ols.order.id}
                        load={load}
                        orderLoadingStatus={ols}
                        orderCanBePiggybacked={
                            piggybackFeature &&
                            !ols.order.isForOverfullIod &&
                            !ols.order.isInvalidOnDecker &&
                            !ols.order.isForInvalidPalletMixIod &&
                            ordersCanBeAmended
                        }
                    />
                ))}

                {transportOnlyOrders.length > 0 && (
                    <>
                        <TableDividerRow
                            className={classes.loadOrdersTableDivider}
                            colSpan={loadOrdersTableHeaders.length}
                        >
                            Transport Only Jobs
                        </TableDividerRow>
                        {sortAndGroupOrders(transportOnlyOrders).map((ols) => (
                            <LoadOrderRow
                                key={ols.order.id}
                                load={load}
                                orderLoadingStatus={ols}
                            />
                        ))}
                    </>
                )}

                {!load.isTransfer && piggybackOnOrders.length > 0 && (
                    <>
                        <TableDividerRow
                            className={classes.loadOrdersTableDivider}
                            colSpan={loadOrdersTableHeaders.length}
                        >
                            Piggyback Orders On
                        </TableDividerRow>
                        {sortAndGroupOrders(piggybackOnOrders).map((ols) => (
                            <LoadOrderRow
                                key={ols.order.id}
                                load={load}
                                orderLoadingStatus={ols}
                            />
                        ))}
                    </>
                )}

                {!load.isTransfer && piggybackOffOrders.length > 0 && (
                    <>
                        <TableDividerRow
                            className={classes.loadOrdersTableDivider}
                            colSpan={loadOrdersTableHeaders.length}
                        >
                            Piggyback Orders Off
                        </TableDividerRow>
                        {sortAndGroupOrders(piggybackOffOrders).map((ols) => (
                            <LoadOrderRow
                                key={ols.order.id}
                                load={load}
                                orderLoadingStatus={ols}
                            />
                        ))}
                    </>
                )}
            </Table>
        </>
    );
};

export default LoadOrdersTable;
