import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    progressSummaryTable: {
        maxWidth: '1000px',
        marginLeft: '0.5rem',
        '& a': {
            textDecoration: 'underline',
        },
    },
    noDataContainer: {
        height: '80vh',
    },
});
