import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import Button from '../../../../../lib/bootstrap-ui/Button';
import { Load } from '../../../../../models/loads/Load';
import { OrderLoadingStatus } from '../../../../../models/orders/OrderLoadingStatus';
import Icon from '../../../../common/Icon';
import PiggybackOrderToLoadModal from '../../../../piggyback/modals/PiggybackOrderToLoadModal';
import { useClasses } from './LoadOrderRow.styles';

interface LoadOrderRowProps {
    load: Load;
    orderLoadingStatus: OrderLoadingStatus;
    orderCanBePiggybacked?: boolean;
}

const LoadingProgressOrderRow: React.FC<LoadOrderRowProps> = ({
    load,
    orderLoadingStatus,
    orderCanBePiggybacked,
}) => {
    const classes = useClasses();

    const [showPiggybackModal, setShowPiggybackModal] = useState(false);

    const order = orderLoadingStatus.order;
    const isInvalidOrder =
        order.isForOverfullIod ||
        order.isForInvalidPalletMixIod ||
        (order.isInvalidOnDecker && !order.hasUkPallets);

    return (
        <>
            <tr
                className={classNames(classes.orderRow, {
                    [classes.cancelledOrderRow]: orderLoadingStatus.isCancelled,
                })}
            >
                <td>{order.orderNumber}</td>
                <td>{order.customerOrderNumber}</td>
                <td>{order.customerName}</td>
                <td>{order.deliveryAddress}</td>
                <td>
                    {`${order.palletSpaces} ${
                        order.containsHeavyPallets ? '(H)' : ''
                    }`}
                </td>
                <td>{order.iodNumber}</td>
                <td>
                    {!orderLoadingStatus.isTransportOnly &&
                        `Store ${order.store}`}
                </td>
                <td className={classes.iconButtonCell}>
                    {orderCanBePiggybacked && (
                        <Button
                            className={classes.iconButton}
                            title="Piggyback Orders"
                            element="button"
                            styleType="link"
                            stopOnClickPropagation
                            onClick={() => setShowPiggybackModal(true)}
                        >
                            <Icon
                                className={classes.piggybackIcon}
                                type="piggyback"
                            />
                        </Button>
                    )}
                    {isInvalidOrder ? (
                        <Icon
                            className={classes.errorIcon}
                            type="warning"
                            title="Invalid Order"
                        />
                    ) : (
                        order.isInvalidOnDecker && (
                            <Icon
                                className={classes.warningIcon}
                                type="warning"
                                title="Order on Invalid IOD"
                            />
                        )
                    )}
                </td>
            </tr>
            <PiggybackOrderToLoadModal
                load={load}
                orderLoadingStatus={orderLoadingStatus}
                isOpen={showPiggybackModal}
                onClose={() => setShowPiggybackModal(false)}
            />
        </>
    );
};

export default LoadingProgressOrderRow;
