import * as React from 'react';
import { useState } from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import {
    Switch,
    TextArea,
    TextInput,
} from '../../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { SMSTemplate } from '../../../../models/smsTemplates/SMSTemplate';
import {
    AvailableSubstitutions,
    Substitution,
} from '../../../../models/smsTemplates/Substitutions';
import Icon from '../../../common/Icon';
import { useClasses } from './SMSTemplateModalStyles';

interface EditSMSTemplateModalProps {
    showModal: boolean;
    smsTemplate: SMSTemplate;
    onEditRequested(updatedValue: SMSTemplate): void;
    onClose(): void;
}

const EditSMSTemplateModal: React.FC<EditSMSTemplateModalProps> = ({
    showModal,
    smsTemplate,
    onEditRequested,
    onClose,
}) => {
    const classes = useClasses();

    const [smsTemplateName, setSMSTemplateName] = useState(smsTemplate.name);
    const [smsTemplateMessage, setSMSTemplateMessage] = useState(
        smsTemplate.message
    );
    const [smsTemplateIsDefault, setSMSTemplateIsDefault] = useState(
        smsTemplate.isDefault
    );

    const isInvalid = !smsTemplateName || !smsTemplateMessage;

    const handleSMSTemplateNameChange = (value: string | null) => {
        setSMSTemplateName(value ?? '');
    };

    const handleSMSTemplateMessageChange = (value: string | null) => {
        setSMSTemplateMessage(value ?? '');
    };

    const handleEditRequested = () => {
        const updatedSMSTemplate = {
            id: smsTemplate.id,
            name: smsTemplateName,
            message: smsTemplateMessage,
            isDefault: smsTemplateIsDefault,
            isPrimary: smsTemplate.isPrimary,
        };
        onEditRequested(updatedSMSTemplate);
    };

    const handleSubsitutionSelected = (
        substitution: Substitution,
        focusElementIdAfter: string
    ) => {
        setSMSTemplateMessage(smsTemplateMessage + ` ${substitution.value}`);
        document.getElementById(focusElementIdAfter)?.focus();
    };

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Edit SMS Template</h5>
            </ModalHeader>
            <ModalBody>
                <div>
                    <TextInput
                        placeholder="Template Name..."
                        value={smsTemplateName}
                        onChange={handleSMSTemplateNameChange}
                        label="Name:"
                        hideBottomPadding
                    />
                </div>
                <div>
                    {!smsTemplate.isPrimary && (
                        <>
                            <label>
                                Message:{' '}
                                <Icon
                                    type="info"
                                    title="Please use the links below to add tokens to your message which will be replaced automatically when the message is sent"
                                />
                            </label>
                            <div>
                                {AvailableSubstitutions.map((s) => (
                                    <Button
                                        styleType={'link'}
                                        key={s.label}
                                        element={'a'}
                                        className={classes.substitutionLink}
                                        onClick={() =>
                                            handleSubsitutionSelected(
                                                s,
                                                'SMSTemplateMessage'
                                            )
                                        }
                                    >
                                        {s.label}
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                    <TextArea
                        id="SMSTemplateMessage"
                        value={smsTemplateMessage}
                        onChange={handleSMSTemplateMessageChange}
                        textAreaClassName={classes.textAreaStyle}
                        autoConvertWhitespace
                        autoTrim
                        hideBottomPadding
                    />
                </div>
                {!smsTemplate.isPrimary && (
                    <div>
                        <Switch
                            id="SMSTemplateIsDefault"
                            label="Default"
                            value={smsTemplateIsDefault}
                            onChange={setSMSTemplateIsDefault}
                        />
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={isInvalid}
                    onClick={() => handleEditRequested()}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditSMSTemplateModal;
