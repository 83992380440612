import classnames from 'classnames';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { buildClassesHook } from '../../../helpers/styles';
import { FileType } from '../../../models/common/FileType';
import { Theme } from '../../../theme';
import Center from '../Center';
import Icon from '../Icon';

const useClasses = buildClassesHook((theme: Theme) => ({
    container: {
        cursor: 'pointer',
        height: '6rem',
        border: 'dashed 3px',
        borderRadius: '0.25rem',
        '& svg': {
            fontSize: '2.5rem',
        },
        '& p': {
            fontWeight: 500,
            margin: '0.5rem 0 0 0',
        },
    },
    dropContainer: {
        borderColor: theme.colors.mediumDarkGrey,
        backgroundColor: theme.colors.lightestGrey,
        '& svg, p': {
            color: theme.colors.mediumDarkGrey,
        },
        '&:hover': {
            borderColor: theme.colors.primaryDark,
            backgroundColor: theme.colors.primaryLight,
        },
        '&:hover svg': {
            color: theme.colors.primaryDark,
        },
        '&:hover p': {
            color: theme.colors.primaryDark,
        },
    },
    dropContainerHighlighted: {
        borderColor: theme.colors.primaryDark,
        backgroundColor: theme.colors.primaryLight,
        '& svg, p': {
            color: theme.colors.primaryDark,
        },
    },
    promptText: {
        userSelect: 'none',
    },
}));

interface DragNDropUploaderProps {
    acceptedFileTypes?: FileType[] | string[];
    allowMultipleFileUpload?: boolean;
    onFilesAccepted(files: File[]): void;
    onFilesRejected?(files: File[]): void;
}

const DragNDropUploader: React.FC<DragNDropUploaderProps> = ({
    acceptedFileTypes,
    allowMultipleFileUpload = false,
    onFilesAccepted,
    onFilesRejected,
}) => {
    const classes = useClasses();

    return (
        <Dropzone
            accept={acceptedFileTypes}
            multiple={allowMultipleFileUpload}
            onDropAccepted={onFilesAccepted}
            onDropRejected={onFilesRejected}
        >
            {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                    <div
                        className={classnames(classes.container, {
                            [classes.dropContainer]: !isDragActive,
                            [classes.dropContainerHighlighted]: isDragActive,
                        })}
                        {...getRootProps()}
                    >
                        <Center>
                            <input {...getInputProps()} />
                            <Icon type="upload" />
                            <p className={classes.promptText}>
                                {!isDragActive
                                    ? 'Click here or drop a file to upload'
                                    : 'Drop file here to upload'}
                            </p>
                        </Center>
                    </div>
                );
            }}
        </Dropzone>
    );
};

export default DragNDropUploader;
