import Bay from '../models/bays/Bay';
import { arraysContainSameElements } from '../helpers/arrayFunctions';

const mapBaysToDropdownOptions = (bays: Bay[] | null) => {
    return bays?.map(b => ({
        label: String(b.id),
        value: b.id
    }));
};

const doesBayHaveCapability = (bay: Bay) => (capability: string) =>
    bay.capabilities.includes(capability);

const areBaysEqual = (lhs: Bay, rhs: Bay): boolean => {
    return (
        lhs.id === rhs.id &&
        lhs.store === rhs.store &&
        lhs.isOutOfOrder === rhs.isOutOfOrder &&
        lhs.isVirtualBay === rhs.isVirtualBay &&
        arraysContainSameElements(lhs.capabilities, rhs.capabilities)
    );
};

export { doesBayHaveCapability, mapBaysToDropdownOptions, areBaysEqual };
