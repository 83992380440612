import * as React from 'react';
import { formatDate } from '../../../../helpers/dates';
import { buildClassesHook } from '../../../../helpers/styles';
import { BookingModification } from '../../../../models/bookings/BookingModification';

const useClasses = buildClassesHook({
    historyTableRow: {
        '& td': {
            padding: '0.5rem 0.75rem',
        },
    },
});

interface HistoryTableRowProps {
    modification: BookingModification;
}

const HistoryTableRow: React.FC<HistoryTableRowProps> = ({ modification }) => {
    const classes = useClasses();

    return (
        <tr className={classes.historyTableRow}>
            <td>{modification.action}</td>
            <td>{modification.description}</td>
            <td>{modification.userName}</td>
            <td>
                {formatDate(modification.actionDateTime, 'dd/MM/yyyy HH:mm')}
            </td>
        </tr>
    );
};

export default HistoryTableRow;
