import * as React from 'react';
import { useState } from 'react';
import { RouteKey, routeFor } from '../../../Routes/routes';
import { buildClassesHook } from '../../../helpers/styles';
import { UserRole } from '../../../helpers/userRole';
import Button from '../../../lib/bootstrap-ui/Button';
import DropdownOption from '../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { BookingType } from '../../../models/bookings/BookingType';
import { useCurrentUser } from '../../../services/authentication';
import { userInRole, userIsAdmin } from '../../../services/userService';

const useClasses = buildClassesHook({
    addBookingTypeSelectionModal: {
        width: 300,
    },
});

type AddBookingType =
    | 'Delivery'
    | 'Collection'
    | 'Delivery and Collection'
    | 'Internal'
    | 'Resource'
    | 'No Bookings'
    | 'Recurring Delivery'
    | 'Recurring Collection'
    | 'Recurring No Bookings';

type BookingCategory = 'Default' | 'Recurring' | 'Admin';

interface AddBookingOptions {
    name: AddBookingType;
    categories: BookingCategory[];
    type: BookingType;
}

const createBookingOptions: AddBookingOptions[] = [
    {
        name: 'Delivery',
        categories: ['Default'],
        type: BookingType.Delivery,
    },
    {
        name: 'Collection',
        categories: ['Default'],
        type: BookingType.Collection,
    },
    {
        name: 'Delivery and Collection',
        categories: ['Default'],
        type: BookingType.DeliveryAndCollection,
    },
    {
        name: 'Internal',
        categories: ['Default'],
        type: BookingType.Internal,
    },
    {
        name: 'Resource',
        categories: ['Default'],
        type: BookingType.Resource,
    },
    {
        name: 'No Bookings',
        categories: ['Admin'],
        type: BookingType.NoBookings,
    },
    {
        name: 'Recurring Delivery',
        categories: ['Default', 'Recurring'],
        type: BookingType.Delivery,
    },
    {
        name: 'Recurring Collection',
        categories: ['Default', 'Recurring'],
        type: BookingType.Collection,
    },
    {
        name: 'Recurring No Bookings',
        categories: ['Admin', 'Recurring'],
        type: BookingType.NoBookings,
    },
];

const getAvailableBookingTypeOptions = (
    onlyShowRecurring: boolean,
    hideAdminOptions: boolean
): AddBookingOptions[] => {
    let availableOptions: AddBookingOptions[] = createBookingOptions;

    if (onlyShowRecurring) {
        availableOptions = availableOptions.filter((option) =>
            option.categories.includes('Recurring')
        );
    }

    if (hideAdminOptions) {
        availableOptions = availableOptions.filter(
            (option) => !option.categories.includes('Admin')
        );
    }

    return availableOptions;
};

interface AddBookingTypeSelectionModalProps {
    open: boolean;
    hideDefaultBookingTypeOptions?: boolean;
    onRequestClose(): void;
}

const AddBookingTypeSelectionModal: React.FC<
    AddBookingTypeSelectionModalProps
> = ({ open, hideDefaultBookingTypeOptions = false, onRequestClose }) => {
    const user = useCurrentUser();
    const isSuperUser =
        userIsAdmin(user) || userInRole(user)(UserRole.ShiftManager);

    const classes = useClasses();

    const availableOptions = getAvailableBookingTypeOptions(
        hideDefaultBookingTypeOptions,
        !isSuperUser
    );

    const [selectedCreateBookingType, setSelectedCreateBookingType] =
        useState<AddBookingType>(availableOptions[0].name);
    const selectedBookingOption = createBookingOptions.find(
        (cbo) => cbo.name === selectedCreateBookingType
    );

    const bookingDropdownOptions: DropdownOption[] = availableOptions.map(
        (cbo) => ({
            label: cbo.name,
            value: cbo.name,
        })
    );

    const handleBookingTypeSelected = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const bookingType = event.target.value as AddBookingType;
        setSelectedCreateBookingType(bookingType);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={onRequestClose}
            className={classes.addBookingTypeSelectionModal}
        >
            <ModalHeader showCloseButton onCloseButtonClick={onRequestClose}>
                <h5>Add Booking</h5>
            </ModalHeader>
            <ModalBody>
                <p>Which type of booking?</p>
                <SelectDropdown
                    dropdownOptions={bookingDropdownOptions}
                    selectedOption={selectedCreateBookingType}
                    defaultText=""
                    onChange={handleBookingTypeSelected}
                    hideBottomPadding
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    element="Link"
                    to={
                        selectedBookingOption?.categories.includes('Recurring')
                            ? routeFor(RouteKey.RecurringBookingForm)({
                                  bookingType: selectedBookingOption.type,
                              })
                            : routeFor(RouteKey.BookingForm)({
                                  bookingType: selectedBookingOption?.type,
                              })
                    }
                >
                    Go
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddBookingTypeSelectionModal;
