import * as React from 'react';
import { useState } from 'react';
import CheckOutModal from '../../components/bookings/modals/CheckOutModal';
import ErrorModal from '../../components/common/ErrorModal';
import LoadingModal from '../../components/common/LoadingModal';
import { Booking } from '../../models/bookings/Booking';
import { apiPost } from '../../services/api';
import { deleteAllBookingAttachments } from '../../services/bookingService';

interface CheckOutModalContainerProps {
    booking: Booking;
    showModal: boolean;
    onCheckOutRequested?(bookingId: string): void;
    onClose(): void;
}

const CheckOutModalContainer: React.FC<CheckOutModalContainerProps> = ({
    booking,
    showModal,
    onCheckOutRequested,
    onClose
}) => {
    const [updatingBooking, setUpdatingBooking] = useState(false);
    const [error, setError] = useState<string | null>();
    const [errorDeletingAttachments, setErrorDeletingAttachments] = useState(
        false
    );

    const actionText = booking.requiresCheckIn ? `checking out` : `completing`;

    const modalHeader = `${
        booking.requiresCheckIn ? 'Check Out' : 'Complete'
    } Booking ${booking.reference}`;

    const handleRequestClose = () => {
        if (updatingBooking) {
            return;
        }
        onClose();
    };

    const handleDeleteBookingAttachments = async () => {
        const success = await deleteAllBookingAttachments(booking);
        if (!success) {
            setError(
                "An error occurred when attempting to delete the Booking's attachments. You can still Check Out the Booking."
            );
            setErrorDeletingAttachments(true);
            setUpdatingBooking(false);
        }
        return success;
    };

    const handleCheckOut = async () => {
        try {
            await apiPost(`Booking/${booking.id}/Complete`);
        } catch (e) {
            console.warn(e);
            setError(`An error has occurred when ${actionText} the Booking.`);
            setErrorDeletingAttachments(false);
            return false;
        }
        return true;
    };

    const handleCheckOutRequested = async () => {
        onCheckOutRequested && onCheckOutRequested(booking.id);
        setUpdatingBooking(true);
        if (!errorDeletingAttachments) {
            const deleteAttachmentsSuccess = await handleDeleteBookingAttachments();
            if (!deleteAttachmentsSuccess) {
                return;
            }
        }
        setError(null);
        const checkOutSuccess = await handleCheckOut();
        setUpdatingBooking(false);
        if (checkOutSuccess) {
            onClose();
        }
    };

    return (
        <>
            <LoadingModal
                showModal={showModal && updatingBooking}
                header={modalHeader}
                loadingText={actionText}
            />
            <ErrorModal
                showModal={showModal && !!error && !updatingBooking}
                showFooterButtons={errorDeletingAttachments}
                header={modalHeader}
                errorText={error || ''}
                onClose={onClose}
                onContinue={handleCheckOutRequested}
            />
            <CheckOutModal
                booking={booking}
                showModal={showModal && !updatingBooking && !error}
                onCheckOut={handleCheckOutRequested}
                onClose={handleRequestClose}
            />
        </>
    );
};

export default CheckOutModalContainer;
