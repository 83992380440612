import * as React from 'react';
import { Row, Column } from '../../../../lib/bootstrap-ui/Grid';

interface BookingSummaryRowProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    labelColumnSize?: number;
    dataColumnClassName?: string;
    header: string;
    children: React.ReactNode;
}

const BookingSummaryRow: React.FC<BookingSummaryRowProps> = ({
    className,
    dataColumnClassName,
    header,
    labelColumnSize = 6,
    children,
    ...otherProps
}) => (
    <Row className={className} {...otherProps}>
        <Column size={labelColumnSize}>
            <h6 style={{ display: 'inline' }}>{header}</h6>
        </Column>
        <Column className={dataColumnClassName} size={12 - labelColumnSize}>
            {children}
        </Column>
    </Row>
);

export default BookingSummaryRow;
