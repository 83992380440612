import DropdownOption from '../lib/bootstrap-ui/Dropdown/DropdownOption';
import { BookingType } from '../models/bookings/BookingType';
import { TrailerTypeResource } from '../models/bookings/StoreBookingResource';
import { Trailer } from '../models/trailers/Trailer';
import {
    StackUpLabel,
    DeTopLabel,
    TrailerType
} from '../models/trailers/TrailerType';
import { RecurringBooking } from '../models/bookings/RecurringBooking';
import { RecurringBookingToAdd } from '../models/bookings/RecurringBookingToAdd';
import { Booking } from '../models/bookings/Booking';
import { CreateBooking } from '../models/bookings/BookingToAdd';

const getTrailerId = (trailer: Trailer | null) => {
    return trailer && trailer.id;
};

const getTrailerTypeLabelForBookingType = (
    bookingType: BookingType,
    trailerType: TrailerType,
    secondaryTrailerType?: TrailerType | null,
    useShortDescription?: boolean
): string => {
    if (bookingType === 'Resource') {
        return 'Resource';
    }

    if (bookingType === BookingType.DeliveryAndCollection) {
        return (
            (useShortDescription ? '' : 'Delivery ') +
            (trailerType === 'Specialised' ? DeTopLabel : trailerType) +
            (useShortDescription ? ', ' : ', Collection ') +
            (secondaryTrailerType === 'Specialised'
                ? StackUpLabel
                : secondaryTrailerType)
        );
    }

    if (trailerType === 'Specialised') {
        return bookingType === BookingType.Collection
            ? StackUpLabel
            : DeTopLabel;
    }

    return trailerType;
};

const getTrailerTypeLabelForBooking = (
    booking: Booking | RecurringBooking | RecurringBookingToAdd | CreateBooking,
    useShortDescription?: boolean
): string =>
    booking.trailerType
        ? getTrailerTypeLabelForBookingType(
              booking.bookingType,
              booking.trailerType,
              booking['secondaryTrailerType'],
              useShortDescription
          )
        : '';

const getTrailerAndSecondaryTrailerDropdownValue = (
    trailerType: TrailerType | null,
    secondaryTrailerType: TrailerType | null
): string | null =>
    trailerType && secondaryTrailerType
        ? `${trailerType}, ${secondaryTrailerType}`
        : trailerType;

const getTrailerTypesFromDropdownValue = (value: string): TrailerType[] =>
    value.split(', ') as TrailerType[];

const getTrailerTypeDropdownOptionsForBookingType = (
    trailerTypeResources: TrailerTypeResource[],
    bookingType: BookingType
): DropdownOption[] => {
    return (
        trailerTypeResources?.map(trailerTypeResource => ({
            label: getTrailerTypeLabelForBookingType(
                bookingType,
                trailerTypeResource.trailerType,
                trailerTypeResource.secondaryTrailerType
            ),
            value: getTrailerAndSecondaryTrailerDropdownValue(
                trailerTypeResource.trailerType,
                trailerTypeResource.secondaryTrailerType
            )
        })) ?? []
    );
};

export {
    getTrailerId,
    getTrailerTypeDropdownOptionsForBookingType,
    getTrailerTypeLabelForBookingType,
    getTrailerTypesFromDropdownValue,
    getTrailerAndSecondaryTrailerDropdownValue,
    getTrailerTypeLabelForBooking
};
