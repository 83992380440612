import * as React from 'react';
import classNames from 'classnames';
import { convertThresholdToMilliseconds } from '../../../../../../models/loads/LoadingMilestoneThreshold';
import TimeElapsedSince from '../../../../../time/TimeElapsedSince';
import { useClasses } from './LoadingProgressDetailMilestoneCell.styles';

interface LoadingProgressDetailMilestoneCellProps {
    milestoneStartedAt: Date | null;
    milestoneEndedAt?: Date | null;
    thresholdDuration?: string | null;
    loaded?: boolean;
    estimate?: boolean;
    isTransferPallet?: boolean;
}

const getTimeElapsed = (date: Date, currentTime: Date) => {
    return currentTime.valueOf() - date.valueOf();
};

const isPalletOverdue = (
    milestoneStart: Date,
    currentTime: Date,
    duration?: string | null
): boolean => {
    if (!duration) {
        return false;
    }

    const timeElapsed = getTimeElapsed(milestoneStart, currentTime);
    const thresholdInMiliseconds = convertThresholdToMilliseconds(duration);
    return timeElapsed > thresholdInMiliseconds;
};

const LoadingProgressDetailMilestoneCell: React.FC<
    LoadingProgressDetailMilestoneCellProps
> = ({
    milestoneStartedAt,
    milestoneEndedAt,
    thresholdDuration,
    loaded,
    estimate = false,
    isTransferPallet = false,
}) => {
    const classes = useClasses();

    return (
        <>
            {isTransferPallet ? (
                <span
                    className={classNames(
                        classes.loadingProgressMilestoneContent,
                        classes.inactive
                    )}
                ></span>
            ) : (
                milestoneStartedAt && (
                    <TimeElapsedSince
                        className={classNames(
                            classes.loadingProgressMilestoneContent,
                            {
                                [classes.active]: !milestoneEndedAt && !loaded,
                                [classes.complete]: milestoneEndedAt && !loaded,
                                [classes.loaded]: loaded,
                            }
                        )}
                        overdueClassName={classes.overdue}
                        useColors
                        elapsedSince={milestoneStartedAt}
                        elapsedUntil={milestoneEndedAt || undefined}
                        overdue={(currentTime: Date) =>
                            !milestoneEndedAt &&
                            isPalletOverdue(
                                milestoneStartedAt,
                                currentTime,
                                thresholdDuration
                            )
                        }
                        estimate={estimate}
                    />
                )
            )}
        </>
    );
};

export default LoadingProgressDetailMilestoneCell;
