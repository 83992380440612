import * as React from 'react';

import { toast } from 'react-toastify';
import InformationMessage from '../../../../components/common/InformationMessage';
import UpdateNotification from '../../../../components/common/UpdateNotification';
import IodPlanInfoHeader from '../../../../components/iod/view/IodPlanInfoHeader';
import TransferDetails from '../../../../components/load/TransferDetails';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { pluralise } from '../../../../helpers/stringFunctions';
import { useNotificationListener } from '../../../../hooks/useNotificationListener';
import { LoadsWithTransfers } from '../../../../models/loads/LoadsWithTransfers';
import {
    ChangesWithAffectedLoad,
    LoadRemoved,
    LoadRemovedNotification,
    LoadUpdatedNotification,
} from '../../../../models/notifications/Load';
import { useData } from '../../../../services/api';
import { useClasses } from './StockTrailerDetailsContainer.styles';

interface StockTrailerDetailsContainerProps {
    loadId: string;
    onBackButtonClick(): void;
}

const handleLoadUpdatedNotification =
    (
        loadsWithTransfers: LoadsWithTransfers | null,
        fetch: () => Promise<void>
    ) =>
    async (changesWithAffectedLoad: ChangesWithAffectedLoad) => {
        if (!loadsWithTransfers) return;

        if (
            loadsWithTransfers.loads.some(
                (l) =>
                    l.id === changesWithAffectedLoad.affectedLoad.id &&
                    changesWithAffectedLoad.changes.length > 0
            )
        ) {
            toast.info(
                <UpdateNotification
                    heading={`Load ${changesWithAffectedLoad.affectedLoad.loadName} Updated`}
                >
                    {changesWithAffectedLoad.changes.map((c) => (
                        <div key={c.id}>{c.message}</div>
                    ))}
                </UpdateNotification>
            );
            await fetch();
        }
    };

const handleLoadRemovedNotification =
    (
        loadsWithTransfers: LoadsWithTransfers | null,
        fetch: () => Promise<void>
    ) =>
    async (loadRemoved: LoadRemoved) => {
        if (!loadsWithTransfers) return;

        if (loadsWithTransfers.loads.some((l) => l.id === loadRemoved.loadId)) {
            toast.info(
                <UpdateNotification
                    heading={`Load ${loadRemoved.loadName} Removed`}
                />
            );
            await fetch();
        }
    };

const StockTrailerDetailsContainer: React.FC<
    StockTrailerDetailsContainerProps
> = ({ loadId, onBackButtonClick }) => {
    const classes = useClasses();

    const [loadsWithTransfers, loading, fetchLoadsWithTransfers] =
        useData<LoadsWithTransfers>(`Load/${loadId}/AssociatedLoads`);

    useNotificationListener(
        LoadUpdatedNotification,
        handleLoadUpdatedNotification(
            loadsWithTransfers,
            fetchLoadsWithTransfers
        )
    );
    useNotificationListener(
        LoadRemovedNotification,
        handleLoadRemovedNotification(
            loadsWithTransfers,
            fetchLoadsWithTransfers
        )
    );

    if (!loadsWithTransfers && loading) {
        return <LoadingIndicator />;
    }

    const selectedLoad =
        loadsWithTransfers &&
        (loadsWithTransfers.loads.find((l) => l.id === loadId) ||
            loadsWithTransfers.transfers.find((l) => l.id === loadId));

    if (!selectedLoad) {
        return (
            <InformationMessage
                messageType="error"
                className={classes.removedMessage}
            >
                This load has been removed.
            </InformationMessage>
        );
    }

    return (
        <div className={classes.container}>
            <IodPlanInfoHeader
                iodNumberOrLoadName={selectedLoad.loadName}
                onBackButtonClick={onBackButtonClick}
            />
            <h4 className="text-center">
                {pluralise('Pallet', selectedLoad.totalPalletSpacesToPick)}
            </h4>
            <TransferDetails
                loadsWithTransfers={loadsWithTransfers!}
                selectedLoad={selectedLoad}
            />
        </div>
    );
};

export default StockTrailerDetailsContainer;
