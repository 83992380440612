import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme/index';

export const useClasses = buildClassesHook((theme: Theme) => ({
    loginCard: {
        height: '400px',
        width: '350px',
        background: theme.colors.white,
        display: 'flex',
        flexDirection: 'column',
        padding: '8rem 0.75rem 3rem 0.75rem',
        boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: '0.25rem',
        position: 'relative',
    },
}));
