import * as React from 'react';
import { useState } from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import { TextInput } from '../../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../../lib/bootstrap-ui/Modal';

import { getSelectedOptionSuffix } from '../../../../containers/admin/thirdPartyManagement/ThirdPartyManagementHelper';
import DropdownOption from '../../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import { useClasses } from './ModalStyle';

interface AddModalProps {
    showModal: boolean;
    selectedOption: DropdownOption;
    onAddRequested(value: string): void;
    onClose(): void;
}

const AddModal: React.FC<AddModalProps> = ({
    showModal,
    selectedOption,
    onAddRequested,
    onClose
}) => {
    const classes = useClasses();

    const [value, setValue] = useState<string>('');

    const isInvalid = !value || value.trim() === '';

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Add {selectedOption.label}</h5>
            </ModalHeader>
            <ModalBody>
                <div>
                    <TextInput
                        label={`${selectedOption.label}:`}
                        placeholder={`Enter ${getSelectedOptionSuffix(
                            selectedOption
                        )}`}
                        value={value}
                        onChange={value => setValue(value ?? '')}
                        autoTrim
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={isInvalid}
                    onClick={() => onAddRequested(value)}
                >
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddModal;
