import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    outstandingPickListAmendmentIcon: {
        fontSize: '0.75rem',
        color: theme.colors.warningDark,
        cursor: 'pointer',
        verticalAlign: 'top',
    },
}));
