import { UserRole } from '../helpers/userRole';
import { UserProfile } from './authentication';
import { StoreNumber } from '../models/stores/StoreNumber';

const adminRoles: UserRole[] = [UserRole.Admin, UserRole.Tester];

const allRoles: UserRole[] = [
    ...adminRoles,
    UserRole.Store1,
    UserRole.Store2,
    UserRole.Store3,
    UserRole.Store4,
    UserRole.Gatehouse,
    UserRole.ShiftManager,
    UserRole.DockSystemReader,
];

const mapStoreToRole = (store: StoreNumber) => {
    switch (store) {
        case 1:
            return UserRole.Store1;
        case 2:
            return UserRole.Store2;
        case 3:
            return UserRole.Store3;
        case 4:
            return UserRole.Store4;
        default:
            throw new Error('No mapping between store and UserRole');
    }
};

const userUnauthorised = (user: UserProfile | null, allowedRoles: UserRole[]) =>
    !user?.roles?.length || !allowedRoles.some((r) => user.roles.includes(r));

const userInRole = (user: UserProfile | null) => (role: UserRole) =>
    !!user?.roles?.includes(role);

const userIsAdmin = (user: UserProfile | null) =>
    adminRoles.some(userInRole(user));

const userIsTester = (user: UserProfile | null) =>
    userInRole(user)(UserRole.Tester);

const userIsNotReader = (user: UserProfile | null) =>
    !userInRole(user)(UserRole.DockSystemReader);

const userToAllStores = (user: UserProfile | null): StoreNumber[] => {
    if (!user) return [];

    const roles = user.roles;
    const storeNumbers: StoreNumber[] = [];
    if (roles) {
        if (userInRole(user)(UserRole.Gatehouse)) {
            return [];
        }
        if (userIsAdmin(user) || !userIsNotReader(user)) {
            return [1, 2, 3, 4];
        }
        if (roles.includes(UserRole.Store1)) {
            storeNumbers.push(1);
        }
        if (roles.includes(UserRole.Store2)) {
            storeNumbers.push(2);
        }
        if (roles.includes(UserRole.Store3)) {
            storeNumbers.push(3);
        }
        if (roles.includes(UserRole.Store4)) {
            storeNumbers.push(4);
        }
    }
    return storeNumbers.length > 0 ? storeNumbers : [1];
};

const userHasAccessToStore = (user: UserProfile | null, store: StoreNumber) => {
    if (userIsAdmin(user)) {
        return true;
    }
    return userInRole(user)(mapStoreToRole(store));
};

export {
    allRoles,
    userInRole,
    userToAllStores,
    userUnauthorised,
    userIsAdmin,
    userIsTester,
    userIsNotReader,
    userHasAccessToStore,
};
