import * as React from 'react';
import classnames from 'classnames';

export interface NavProps extends React.HTMLAttributes<HTMLUListElement> {
    pills?: boolean;
    fill?: boolean;
}

const Nav: React.FC<NavProps> = ({
    children,
    className,
    pills = false,
    fill = false,
    ...rest
}) => (
    <ul
        className={classnames('nav', className, {
            'nav-pills': pills,
            'nav-fill': fill
        })}
        {...rest}
    >
        {children}
    </ul>
);

export default Nav;
