import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { buildClassesHook } from '../../../helpers/styles';
import { getSvgAsUri } from '../../../services/iconService';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    dropdown: {
        WebkitAppearance: 'none',
        backgroundImage: `url("${getSvgAsUri(
            faChevronDown,
            theme.colors.darkGrey
        )}")`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundSize: '0.8rem',
    },
}));
