import * as React from 'react';
import { useState } from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Alert from '../../../lib/bootstrap-ui/Alert';
import { FileType } from '../../../models/common/FileType';
import DragNDropUploader from './DragNDropUploader';
import FileGrid, { FileToDisplay } from './FileGrid';

const useClasses = buildClassesHook({
    alert: {
        fontWeight: 500,
        padding: '0.5rem',
        margin: '0.5rem 0',
    },
});

interface FileUploaderProps {
    files: FileToDisplay[];
    acceptedFileTypes?: FileType[] | string[];
    displayAlertOnFailure?: boolean;
    downloadingFileNames?: string[];
    deletingFileNames?: string[];
    uploadingFileNames?: string[];
    uploadFailure?: boolean;
    showDownloadAction?: boolean;
    showRemoveAction?: boolean;
    onUploadRequested(files: File[]): void;
    onDownloadActionClicked?(fileName: string): void;
    onRemoveActionClicked?(fileName: string): void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
    files,
    acceptedFileTypes,
    displayAlertOnFailure = false,
    downloadingFileNames,
    deletingFileNames,
    uploadingFileNames,
    uploadFailure = false,
    showDownloadAction = false,
    showRemoveAction = false,
    onUploadRequested,
    onDownloadActionClicked,
    onRemoveActionClicked,
}) => {
    const classes = useClasses();

    const [displayAlert, setDisplayAlert] = useState(false);

    const handleFileRejected = () => {
        if (displayAlertOnFailure) {
            setDisplayAlert(true);
            setTimeout(() => setDisplayAlert(false), 5000 /*5 seconds*/);
        }
    };

    return (
        <>
            <DragNDropUploader
                acceptedFileTypes={acceptedFileTypes}
                allowMultipleFileUpload
                onFilesAccepted={onUploadRequested}
                onFilesRejected={handleFileRejected}
            />
            {displayAlertOnFailure && displayAlert && (
                <Alert type="danger" className={classes.alert}>
                    Supported file types: Outlook, Word, Excel, PDF documents
                </Alert>
            )}
            {displayAlertOnFailure && uploadFailure && (
                <Alert type="danger" className={classes.alert}>
                    There was a problem with uploading the attachment. Please
                    contact the IT department.
                </Alert>
            )}
            {!uploadFailure && files.length > 0 && (
                <FileGrid
                    files={files}
                    downloadingFileNames={downloadingFileNames}
                    uploadingFileNames={uploadingFileNames}
                    deletingFileNames={deletingFileNames}
                    showDownloadAction={showDownloadAction}
                    showRemoveAction={showRemoveAction}
                    onDownloadActionClicked={onDownloadActionClicked}
                    onRemoveActionClicked={onRemoveActionClicked}
                />
            )}
        </>
    );
};

export default FileUploader;
