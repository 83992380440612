import classNames from 'classnames';
import * as React from 'react';
import ReactModal from 'react-modal';
import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

const useClasses = buildClassesHook((theme: Theme) => ({
    modal: {
        padding: '1rem',
        overflow: 'visible',
        borderRadius: '4px',
        background: theme.colors.white,
        border: '1px solid rgb(204, 204, 204)',
    },
    overlay: {
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.translucentWhite,
        zIndex: 50,
    },
}));

interface ModalProps {
    isOpen: boolean;
    closeOnOverlayClick?: boolean;
    modalClassName?: string;
    overlayClassName?: string;
    className?: string;
    onAfterClose?(): void;
    onRequestClose?(): void;
    onAfterOpen?(): void;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    isOpen,
    modalClassName,
    overlayClassName,
    onAfterClose,
    onRequestClose,
    onAfterOpen,
    children,
    className,
}) => {
    const classes = useClasses();

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={isOpen}
            className={classNames(classes.modal, modalClassName, className)}
            overlayClassName={classNames(classes.overlay, overlayClassName)}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            onRequestClose={onRequestClose}
        >
            {children}
        </ReactModal>
    );
};

export default Modal;
