import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import ErrorModal from '../../components/common/ErrorModal';
import HeaderContainer from '../../components/common/HeaderContainer';
import UnattachedStockTransferOrderCount from '../../components/orders/UnattachedStockTransferOrderCount';
import UnpickedPalletCount from '../../components/pallets/UnpickedPalletCount';
import { useNotificationListener } from '../../hooks/useNotificationListener';
import usePersistedState from '../../hooks/usePersistedState';
import { Load } from '../../models/loads/Load';
import {
    LoadAddedNotification,
    LoadReleasedNotification,
    LoadRemovedNotification,
    LoadsAddedNotification,
    LoadUpdatedNotification,
} from '../../models/notifications/Load';
import { StoreNumber } from '../../models/stores/StoreNumber';
import LoadingTableReducer from '../../reducers/LoadingTableReducer';
import { useData } from '../../services/api';
import { useCurrentUser } from '../../services/authentication';
import { userIsNotReader, userToAllStores } from '../../services/userService';
import LoadingTableActionContainer, {
    Action,
} from '../load/loadTable/view/LoadingTableActionContainer';
import { useClasses } from './WorkQueueContainer.styles';
import WorkQueueTableContainer from './WorkQueueTableContainer';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const actions: Action[] = [
    { title: 'storeSelector' },
    { title: 'progressSummary' },
    { title: 'workHistory' },
    { title: 'add' },
];

interface WorkQueueContainerProps {
    selectedStore?: StoreNumber;
    onStoreChange: (store: StoreNumber) => void;
    onRedirectToRelease: (id: string) => void;
}

const WorkQueueContainer: React.FC<WorkQueueContainerProps> = ({
    selectedStore,
    onStoreChange,
    onRedirectToRelease,
}) => {
    const classes = useClasses();
    const user = useCurrentUser();

    const storesToShow: StoreNumber[] = userToAllStores(user);
    const [store, setStore] = usePersistedState<StoreNumber>(
        selectedStore || userToAllStores(user!)[0],
        'loading-store'
    );
    const [loading, setLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [loads, loadingLoads, , loadingLoadsError] = useData<Load[]>(
        `Store/${store}/Load/Incomplete`
    );
    localStorage.removeItem('work-history-filter');

    const [loadsToDisplay, dispatchLoads] = useReducer(LoadingTableReducer, []);

    const [createNoIodTransferFeature] = useFeatureFlag(
        'phase1_createNoIodTransfer'
    );

    const canCreateNoIodTransfer =
        !!createNoIodTransferFeature && userIsNotReader(user);

    useEffect(() => {
        setLoading(true);
        if (loads) {
            dispatchLoads({
                type: 'REPLACE_LOADS',
                payload: {
                    affectedLoads: loads,
                },
            });
            setLoading(false);
        }
    }, [loads]);

    useEffect(() => {
        const hasLoadingError = !!loadingLoadsError;
        if (hasLoadingError) {
            setLoading(false);
        }
        setShowErrorModal(hasLoadingError);
        setHasError(hasLoadingError);
    }, [loadingLoadsError]);

    const handleStoreChange = async (store: StoreNumber) => {
        onStoreChange(store);
        setStore(store);
    };

    useNotificationListener(LoadAddedNotification, (loadAdded) => {
        const isNewLoadNotificationRelevant: boolean =
            loadAdded.sourceStore === store;
        if (isNewLoadNotificationRelevant) {
            dispatchLoads({
                type: 'ADD_LOAD',
                payload: loadAdded,
            });
        }
    });

    useNotificationListener(LoadsAddedNotification, (loadsAdded) => {
        const isNewLoadNotificationRelevant: boolean = loadsAdded.some(
            (l) => l.sourceStore === store
        );
        if (isNewLoadNotificationRelevant) {
            dispatchLoads({
                type: 'ADD_LOADS',
                payload: {
                    affectedLoads: loadsAdded,
                    selectedStore: store,
                },
            });
        }
    });

    useNotificationListener(LoadReleasedNotification, (loadReleasedEvent) => {
        const isLoadReleaseNotificationRelevant: boolean = loadsToDisplay.some(
            (l) => l.id === loadReleasedEvent.releasedLoad.id
        );
        if (isLoadReleaseNotificationRelevant) {
            dispatchLoads({
                type: 'RELEASE_LOAD',
                payload: loadReleasedEvent.releasedLoad,
            });
        }
    });

    useNotificationListener(LoadRemovedNotification, (loadRemoved) => {
        if (loadRemoved.sourceStore === store) {
            dispatchLoads({
                type: 'REMOVE_LOAD',
                payload: loadRemoved,
            });
        }
    });

    useNotificationListener(
        LoadUpdatedNotification,
        (changesWithAffectedLoad) => {
            const isUpdateNotificationRelevant: boolean = loadsToDisplay.some(
                (ltd) => ltd.id === changesWithAffectedLoad.affectedLoad.id
            );

            if (isUpdateNotificationRelevant) {
                dispatchLoads({
                    type: 'UPDATE_LOAD',
                    payload: {
                        affectedLoad: changesWithAffectedLoad.affectedLoad,
                    },
                    changes: changesWithAffectedLoad.changes,
                });
            }
        }
    );

    const handleErrorModalClose = () => {
        setShowErrorModal(false);
    };

    return (
        <>
            <div className={classes.workQueueContainer}>
                <HeaderContainer headerText="Work Queue">
                    <>
                        <LoadingTableActionContainer
                            actionsToDisplay={actions}
                            storeOption={store}
                            storeOptions={storesToShow}
                            onStoreOptionChange={handleStoreChange}
                            createNoIodTransferFeature={
                                canCreateNoIodTransfer ?? false
                            }
                        />
                        <div className={classes.statisticsContainer}>
                            <UnpickedPalletCount store={store} />
                            <UnattachedStockTransferOrderCount store={store} />
                        </div>
                    </>
                </HeaderContainer>
                <WorkQueueTableContainer
                    loads={loadsToDisplay}
                    loading={loadingLoads || loading}
                    showError={hasError}
                    onRedirectToRelease={onRedirectToRelease}
                />
            </div>
            {showErrorModal && (
                <ErrorModal
                    showModal={showErrorModal}
                    errorText="There was an error retrieving the list of loads, please try refreshing the page. If this error continues, please contact IT."
                    onClose={handleErrorModalClose}
                />
            )}
        </>
    );
};

export default WorkQueueContainer;
