import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    inputWrapperContainer: {
        display: 'flex',
        border: `1px solid ${theme.colors.lightGrey}`,
        borderRadius: '0.2rem',
    },
    focussed: {
        boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%)',
    },
    phoneNumberDropdownButton: {
        backgroundColor: theme.colors.lightestGrey,
        border: `none`,
    },
    phoneNumberDropdown: {
        '& li.country': {
            padding: '.25rem .25rem .25rem 3rem !important',
            backgroundColor: theme.colors.lightestGrey,
            '&:hover': {
                backgroundColor: `${theme.colors.lightGrey} !important`,
            },
            '& .flag': {
                top: 0,
            },
        },
        '& li.country.highlight': {
            backgroundColor: `${theme.colors.lightGrey} !important`,
        },
        '& li.country.preferred.highlight': {
            backgroundColor: theme.colors.lightGrey,
        },
    },
    phoneNumberInputLabel: {
        display: 'block',
        width: '100%',
    },
    phoneNumberInput: {
        border: 'none !important',
        fontSize: '1rem !important',
        '&:focus': {
            boxShadow: 'none !important',
        },
    },
    phoneNumberInputSearchField: {
        borderBottom: `1px solid ${theme.colors.grey}`,
        '& input': {
            width: 'calc(100% - 1.1rem)',
        },
    },
    clearButton: {
        padding: '0 0.5rem 0 0',
        color: theme.colors.mediumDarkGrey,
        '&:hover': {
            color: theme.colors.darkGrey,
        },
    },
}));
