import * as React from 'react';
import { useState } from 'react';
import AttachmentsModalContainer from '../../../../containers/bookings/AttachmentsModalContainer';
import CancelBookingModalContainer from '../../../../containers/bookings/CancelBookingModalContainer';
import RescheduleModalContainer from '../../../../containers/bookings/RescheduleModalContainer';
import UpdateResourcesModalContainer from '../../../../containers/bookings/UpdateResourcesModalContainer';
import ConvertToDeliveryModalContainer from '../../../../containers/bookings/update/ConvertToDeliveryModalContainer';
import EditModalContainer from '../../../../containers/bookings/update/EditModalContainer';
import { buildClassesHook } from '../../../../helpers/styles';
import { UserRole } from '../../../../helpers/userRole';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingOptionType } from '../../../../models/bookings/BookingOptionType';
import { ThirdPartyCollectionOrder } from '../../../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import { useCurrentUser } from '../../../../services/authentication';
import { getFormattedBookingHeader } from '../../../../services/bookingService';
import { getFileTypeFromFileName } from '../../../../services/fileService';
import { userInRole, userIsAdmin } from '../../../../services/userService';
import MoreOptions from '../../../common/MoreOptions';
import SingleColumnRow from '../../../common/SingleColumnRow';
import FileGrid from '../../../common/fileUpload/FileGrid';
import LoadingIndicator from '../../../loading/LoadingIndicator';
import BookingOptions from '../../BookingOptions';
import EmailModal from '../../modals/EmailModal';
import UndoCheckInModal from '../../modals/UndoCheckInModal';
import UpdateOrdersModal from '../../modals/UpdateOrdersModal';
import ViewHistoryModal from '../../modals/bookingHistory/ViewHistoryModal';
import BookingSummaryModalCard from '../summary/BookingSummaryModalCard';

const useClasses = buildClassesHook({
    summaryModal: {
        maxWidth: 900,
        minWidth: 700,
    },
    summaryModalHeader: {
        textAlign: 'center',
        display: 'block',
    },
    store: {
        left: '0.75rem',
        fontSize: 20,
        float: 'left',
    },
    options: {
        display: 'inline',
        float: 'right',
    },
});

interface SummaryModalProps {
    booking: Booking;
    downloadingAttachmentNames?: string[];
    loading: boolean;
    thirdPartyCollectionOrders?: ThirdPartyCollectionOrder[] | null;
    showModal: boolean;
    onBookingUpdate(): void;
    onBookingOrdersUpdate(): void;
    onAttachmentDownloadRequested(fileName: string): void;
    onClose(): void;
}

const SummaryModal: React.FC<SummaryModalProps> = ({
    booking,
    downloadingAttachmentNames = [],
    loading,
    thirdPartyCollectionOrders,
    showModal,
    onBookingUpdate,
    onBookingOrdersUpdate,
    onAttachmentDownloadRequested,
    onClose,
}) => {
    const classes = useClasses();

    const user = useCurrentUser();

    const hideUndoCheckIn =
        !userIsAdmin(user) &&
        !userInRole(user)(UserRole.Gatehouse) &&
        !userInRole(user)(UserRole.ShiftManager) &&
        !userInRole(user)(UserRole.Tester);

    const [modalToDisplay, setModalToDisplay] =
        useState<BookingOptionType | null>(null);

    const resetModalToDisplay = () => {
        setModalToDisplay(null);
    };

    const handleBookingUpdateActionCompleted = () => {
        onBookingUpdate();
        resetModalToDisplay();
    };

    const handleBookingOrdersUpdate = () => {
        onBookingOrdersUpdate();
        onBookingUpdate();
    };

    return (
        <>
            <Modal
                modalClassName={classes.summaryModal}
                isOpen={showModal}
                onRequestClose={onClose}
            >
                <ModalHeader className={classes.summaryModalHeader}>
                    <div style={{ display: 'inline' }}>
                        <span className={classes.store}>{booking.store}</span>
                        <h4 style={{ display: 'inline' }}>
                            {booking.reference} {booking.bookingType} Booking
                            Summary
                        </h4>
                        <MoreOptions triggerClassName={classes.options}>
                            {(close: () => void) => (
                                <BookingOptions
                                    bookingType={booking.bookingType}
                                    bookingStatus={booking.status}
                                    store={booking.store}
                                    hideUndoCheckIn={hideUndoCheckIn}
                                    onOptionRequested={(type) => {
                                        close();
                                        setModalToDisplay(type);
                                    }}
                                />
                            )}
                        </MoreOptions>
                    </div>
                    <SingleColumnRow>
                        <h5>{getFormattedBookingHeader(booking)}</h5>
                    </SingleColumnRow>
                </ModalHeader>
                <ModalBody>
                    {loading ? (
                        <LoadingIndicator />
                    ) : (
                        <>
                            <BookingSummaryModalCard
                                booking={booking}
                                thirdPartyCollectionOrders={
                                    thirdPartyCollectionOrders
                                }
                            />
                            {booking.attachments.length > 0 && (
                                <>
                                    <hr />
                                    <FileGrid
                                        files={booking.attachments.map(
                                            (attachment) => ({
                                                name: attachment.fileName,
                                                type: getFileTypeFromFileName(
                                                    attachment.fileName
                                                ),
                                            })
                                        )}
                                        downloadingFileNames={
                                            downloadingAttachmentNames
                                        }
                                        showDownloadAction
                                        onDownloadActionClicked={
                                            onAttachmentDownloadRequested
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button styleType="secondary" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
            {modalToDisplay === 'cancel' && (
                <CancelBookingModalContainer
                    options={{
                        bookingType: 'booking',
                        booking: booking,
                    }}
                    showModal={modalToDisplay === 'cancel'}
                    onCancelBooking={onClose}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'email' && (
                <EmailModal
                    booking={booking}
                    bookingType="booking"
                    showModal={modalToDisplay === 'email'}
                    onEmailSent={handleBookingUpdateActionCompleted}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'edit' && (
                <EditModalContainer
                    booking={booking}
                    showModal={modalToDisplay === 'edit'}
                    onEditConfirmed={onBookingUpdate}
                    onEmailSent={handleBookingUpdateActionCompleted}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'viewHistory' && (
                <ViewHistoryModal
                    booking={booking}
                    type="booking"
                    showModal={modalToDisplay === 'viewHistory'}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'reschedule' && (
                <RescheduleModalContainer
                    booking={booking}
                    showModal={modalToDisplay === 'reschedule'}
                    onReschedule={onBookingUpdate}
                    onEmailSent={handleBookingUpdateActionCompleted}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'updateOrders' && (
                <UpdateOrdersModal
                    booking={booking}
                    showModal={modalToDisplay === 'updateOrders'}
                    onUpdate={handleBookingOrdersUpdate}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'undoCheckIn' && (
                <UndoCheckInModal
                    booking={booking}
                    showModal={modalToDisplay === 'undoCheckIn'}
                    onUndoCheckIn={handleBookingUpdateActionCompleted}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'updateResources' && (
                <UpdateResourcesModalContainer
                    booking={booking}
                    showModal={modalToDisplay === 'updateResources'}
                    onUpdate={handleBookingUpdateActionCompleted}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'manageAttachments' && (
                <AttachmentsModalContainer
                    showModal={modalToDisplay === 'manageAttachments'}
                    booking={booking}
                    onUpload={onBookingUpdate}
                    onDelete={onBookingUpdate}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'convertToDelivery' && (
                <ConvertToDeliveryModalContainer
                    booking={booking}
                    showModal={modalToDisplay === 'convertToDelivery'}
                    onClose={resetModalToDisplay}
                    onConvertToDelivery={handleBookingUpdateActionCompleted}
                />
            )}
        </>
    );
};

export default SummaryModal;
