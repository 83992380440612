import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import SMSTemplateManagementTable from '../../../components/admin/smsTemplates/view/SMSTemplateManagementTable';
import { useNotificationListener } from '../../../hooks/useNotificationListener';
import Button from '../../../lib/bootstrap-ui/Button';
import {
    SMSTemplateRemovedNotification,
    SMSTemplateUpdatedNotification,
} from '../../../models/notifications/SMSTemplates';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import SMSTemplateTableReducer from '../../../reducers/SMSTemplateTableReducer';
import { useData } from '../../../services/api';
import AddSMSTemplateModalContainer from './AddSMSTemplateModalContainer';
import DeleteSMSTemplateModalContainer from './DeleteSMSTemplateModalContainer';
import EditSMSTemplateModalContainer from './EditSMSTemplateModalContainer';
import SendTestSMSTemplateModalContainer from './SendTestSMSTemplateModalContainer';
import { useClasses } from './SMSTemplateManagementContainer.styles';

export type SMSTemplateManagementModalType = 'add' | 'edit' | 'delete' | 'test';

const SMSTemplateManagementContainer: React.FC = () => {
    const classes = useClasses();

    const [smsTemplates, loadingSMSTemplates] =
        useData<SMSTemplate[]>('SMSTemplate');

    const [loading, setLoading] = useState(false);
    const [modalToShow, setModalToShow] =
        useState<SMSTemplateManagementModalType | null>(null);
    const [valueToChange, setValueToChange] = useState<SMSTemplate | null>(
        null
    );
    const [smsTemplatesToDisplay, dispatchSMSTemplates] = useReducer(
        SMSTemplateTableReducer,
        []
    );

    useEffect(() => {
        setLoading(true);
        if (smsTemplates) {
            dispatchSMSTemplates({
                type: 'ReplaceSMSTemplates',
                payload: {
                    affectedSMSTemplates: smsTemplates,
                },
            });
            setLoading(false);
        }
    }, [smsTemplates]);

    useNotificationListener(
        SMSTemplateUpdatedNotification,
        (changesWithAffectedSMSTemplates) =>
            dispatchSMSTemplates({
                type: 'UpdateSMSTemplate',
                payload: {
                    affectedSMSTemplates:
                        changesWithAffectedSMSTemplates.affectedSMSTemplates,
                },
                changes: changesWithAffectedSMSTemplates.changes,
            })
    );

    useNotificationListener(SMSTemplateRemovedNotification, (smsTemplateId) =>
        dispatchSMSTemplates({
            type: 'RemoveSMSTemplate',
            payload: {
                removedSMSTemplateID: String(smsTemplateId),
            },
        })
    );

    const handleModalOpened = (
        smsTemplate: SMSTemplate,
        modalType: SMSTemplateManagementModalType
    ) => {
        setValueToChange(smsTemplate);
        setModalToShow(modalType);
    };

    const resetModalToShow = () => setModalToShow(null);

    return (
        <>
            <div className={classes.smsTemplateManagementContainer}>
                <div className={classes.actionContainer}>
                    <Button
                        onClick={() => setModalToShow('add')}
                        styleType="primary"
                    >
                        Add SMS Template
                    </Button>
                </div>
                <div style={{ height: '100%' }}>
                    <SMSTemplateManagementTable
                        smsTemplates={smsTemplatesToDisplay || []}
                        loading={loadingSMSTemplates || loading}
                        onModalOpened={handleModalOpened}
                    />
                </div>
            </div>
            {modalToShow === 'add' && (
                <AddSMSTemplateModalContainer
                    showModal={modalToShow === 'add'}
                    onClose={() => resetModalToShow()}
                />
            )}
            {!!valueToChange &&
                modalToShow === 'delete' &&
                !valueToChange.isPrimary && (
                    <DeleteSMSTemplateModalContainer
                        showModal={modalToShow === 'delete'}
                        smsTemplate={valueToChange}
                        onClose={() => resetModalToShow()}
                    />
                )}
            {!!valueToChange && modalToShow === 'edit' && (
                <EditSMSTemplateModalContainer
                    showModal={modalToShow === 'edit'}
                    smsTemplate={valueToChange}
                    onClose={() => resetModalToShow()}
                />
            )}
            {!!valueToChange && modalToShow === 'test' && (
                <SendTestSMSTemplateModalContainer
                    showModal={modalToShow === 'test'}
                    smsTemplate={valueToChange}
                    onClose={() => resetModalToShow()}
                />
            )}
        </>
    );
};

export default SMSTemplateManagementContainer;
