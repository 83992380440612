import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { buildClassesHook } from '../../../../helpers/styles';
import { getSvgAsUri } from '../../../../services/iconService';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    formGroup: {
        position: 'relative',
    },
    inline: {
        '& label': {
            marginRight: '1rem',
        },
    },
    notInvalid: {
        marginBottom: 'calc(1.2em + 1.25rem)',
    },
    invalid: {
        backgroundImage: `url("${getSvgAsUri(
            faExclamationCircle,
            theme.colors.errorDark
        )}")`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundSize: '1.2rem',
        borderColor: theme.colors.errorDark,
    },
}));
