import { Notification } from '.';
import {
    NewThirdPartyValueAdded,
    ThirdPartyValueUpdated
} from './ThirdPartyValue';

export const CustomerAddedNotification: Notification<NewThirdPartyValueAdded> = {
    message: 'CustomerAdded'
};

export const CustomerRemovedNotification: Notification<number> = {
    message: 'CustomerRemoved'
};

export const CustomerUpdatedNotification: Notification<ThirdPartyValueUpdated> = {
    message: 'CustomerUpdated'
};
