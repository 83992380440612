import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    workHistoryTableContainer: {
        whiteSpace: 'nowrap',
        padding: '0 0.9375rem',
        '& thead th': {
            top: '4.5rem'
        }
    },
    pageSelectionContainer: {
        position: 'sticky',
        bottom: 0,
        paddingBottom: '1rem',
        zIndex: 1,
        '& ul.pagination': {
            margin: 0
        }
    }
});
