import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    noOrdersText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5rem',
        color: theme.colors.mediumDarkGrey,
    },
    dollyIcon: {
        fontSize: '2rem',
        marginTop: '0.5rem',
    },
    orderContainer: {
        maxHeight: '40vh',
        overflowY: 'auto',
        '& > div': {
            width: '95%',
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    invalidOrderContainer: {
        maxHeight: '20vh',
        '& > div': {
            width: '95%',
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    invalidThirdPartyOrder: {
        boxShadow: `1px 1px 3px 1px ${theme.colors.errorDark}`,
        marginBottom: 0,
    },
    noOrdersSection: {
        minHeight: '10rem',
    },
    orderHasBookingIcon: {
        marginLeft: '1rem',
    },
    cancelledOrder: {
        textDecoration: 'line-through',
    },
}));
