import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import ErrorModal from '../../components/common/ErrorModal';
import ProgressSummaryTable from '../../components/progressSummary/ProgressSummaryTable';
import { useNotificationListener } from '../../hooks/useNotificationListener';
import { LoadingMilestoneThreshold } from '../../models/loads/LoadingMilestoneThreshold';
import { useData, apiPatch } from '../../services/api';
import LoadingProgressSummaryReducer from '../../reducers/LoadingProgressSummaryReducer';
import { LoadWithPallets } from '../../models/loads/LoadsWithPallets';
import { OrderStatus } from '../../models/orders/OrderStatus';
import {
    LoadUpdatedNotification,
    LoadRemovedNotification,
    LoadReleasedNotification,
} from '../../models/notifications/Load';
import {
    PalletAddedNotification,
    PalletsAddedNotification,
    PalletRemovedNotification,
    PalletsRemovedNotification,
    PalletsUpdatedNotification,
} from '../../models/notifications/Pallet';
import { StoreSelectorOption } from '../../components/common/StoreSelectorPills';

interface ProgressSummaryTableContainerProps {
    className?: string;
    selectedStore: StoreSelectorOption;
}

const orderStatuses: OrderStatus[] = [
    OrderStatus.Picking,
    OrderStatus.AwaitingConfirmation,
];

const ProgressSummaryTableContainer: React.FC<
    ProgressSummaryTableContainerProps
> = ({ className, selectedStore }) => {
    const [loading, setLoading] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const loadsUrl =
        selectedStore !== 'All'
            ? `Store/${selectedStore}/Loads/WithPallets`
            : 'Loads/WithPallets';

    const [loadsWithPallets, loadingLoadsWithPallets] = useData<
        LoadWithPallets[]
    >(loadsUrl, { orderStatuses });

    const milestonesUrl =
        selectedStore !== 'All'
            ? `Store/${selectedStore}/LoadingMilestone/Thresholds`
            : 'LoadingMilestone/Thresholds';

    const [milestoneThresholds, loadingMilestoneThresholds] =
        useData<LoadingMilestoneThreshold[]>(milestonesUrl);

    const [loadsToDisplay, updateLoadsToDisplay] = useReducer(
        LoadingProgressSummaryReducer,
        []
    );

    useEffect(() => {
        setLoading(true);
    }, [selectedStore]);

    useEffect(() => {
        setLoading(true);
        if (loadsWithPallets) {
            updateLoadsToDisplay({
                type: 'REPLACE_LOADSWITHPALLETS',
                payload: loadsWithPallets,
            });
            setLoading(false);
        }
    }, [loadsWithPallets]);

    useNotificationListener(
        LoadUpdatedNotification,
        (changesWithAffectedLoad) => {
            const isUpdateNotificationRelevant: boolean = loadsToDisplay.some(
                (ltd) => ltd.id === changesWithAffectedLoad.affectedLoad.id
            );
            if (isUpdateNotificationRelevant) {
                updateLoadsToDisplay({
                    type: 'UPDATE_LOAD',
                    payload: changesWithAffectedLoad,
                });
            }
        }
    );

    useNotificationListener(LoadRemovedNotification, (loadRemoved) => {
        if (loadRemoved.sourceStore === selectedStore) {
            updateLoadsToDisplay({
                type: 'REMOVE_LOAD',
                payload: loadRemoved,
            });
        }
    });

    useNotificationListener(LoadReleasedNotification, (loadReleased) => {
        const isLoadReleasedNotificationRelevant: boolean =
            loadReleased.releasedLoad.sourceStore === selectedStore;
        if (isLoadReleasedNotificationRelevant) {
            updateLoadsToDisplay({
                type: 'ADD_LOAD',
                payload: loadReleased,
            });
        }
    });

    useNotificationListener(PalletAddedNotification, (palletAdded) => {
        if (!loadsWithPallets) return;

        const isAddNotificationRelevant: boolean = loadsToDisplay.some((lwp) =>
            lwp.orderLoadingStatuses.some(
                (ols) => ols.orderId === palletAdded.addedPallet.orderId
            )
        );
        if (isAddNotificationRelevant) {
            updateLoadsToDisplay({
                type: 'ADD_PALLET',
                payload: palletAdded.addedPallet,
            });
        }
    });

    useNotificationListener(PalletsAddedNotification, (palletsAdded) => {
        if (!loadsWithPallets) return;

        const isAnyAddNotificationRelevant: boolean = loadsToDisplay.some(
            (lwp) =>
                lwp.orderLoadingStatuses.some((ols) =>
                    palletsAdded
                        .map((notification) => notification.addedPallet.orderId)
                        .includes(ols.orderId)
                )
        );
        if (isAnyAddNotificationRelevant) {
            updateLoadsToDisplay({
                type: 'ADD_PALLETS',
                payload: palletsAdded.map(
                    (notification) => notification.addedPallet
                ),
            });
        }
    });

    useNotificationListener(PalletRemovedNotification, (palletRemoved) => {
        if (!loadsWithPallets) return;

        const isRemoveNotificationRelevant: boolean = loadsToDisplay.some((l) =>
            l.pallets.some((p) => p.id === palletRemoved.cancelledPalletId)
        );

        if (isRemoveNotificationRelevant) {
            updateLoadsToDisplay({
                type: 'REMOVE_PALLET',
                payload: palletRemoved,
            });
        }
    });

    useNotificationListener(PalletsRemovedNotification, (palletsRemoved) => {
        if (!loadsWithPallets) return;

        const isAnyRemoveNotificationRelevant: boolean = loadsToDisplay.some(
            (l) =>
                l.orderLoadingStatuses.some((ols) =>
                    palletsRemoved
                        .map(
                            (notification) =>
                                notification.cancelledPalletOrderId
                        )
                        .includes(ols.orderId)
                )
        );

        if (isAnyRemoveNotificationRelevant) {
            updateLoadsToDisplay({
                type: 'REMOVE_PALLETS',
                payload: palletsRemoved,
            });
        }
    });

    useNotificationListener(PalletsUpdatedNotification, (updatedPallets) => {
        if (!loadsWithPallets) return;

        const palletOrderIds = updatedPallets.map((up) => up.orderId);
        const isUpdateNotificationRelevant: boolean = loadsToDisplay.some((l) =>
            l.orderLoadingStatuses.some((ols) =>
                palletOrderIds.includes(ols.orderId)
            )
        );

        if (isUpdateNotificationRelevant) {
            updateLoadsToDisplay({
                type: 'UPDATE_PALLETS',
                payload: updatedPallets,
            });
        }
    });

    const handleLoadWithPalletsUpdated = async (
        updatedLoad: LoadWithPallets
    ) => {
        try {
            await apiPatch(`Load/${updatedLoad.id}/Priority`, {
                priority: updatedLoad.priority,
            });
        } catch (error) {
            console.warn(error);
        }
    };

    const closeErrorModal = () => {
        setShowErrorModal(false);
    };

    return (
        <div className={className}>
            <ProgressSummaryTable
                loadsWithPallets={loadsToDisplay || []}
                milestoneThresholds={milestoneThresholds || []}
                loading={
                    loading ||
                    loadingLoadsWithPallets ||
                    loadingMilestoneThresholds
                }
                onLoadUpdated={handleLoadWithPalletsUpdated}
            />
            <ErrorModal
                showModal={showErrorModal}
                errorText="There was an error retrieving the list of loads, please try refreshing the page. If this error continues, please contact IT."
                onClose={closeErrorModal}
            />
        </div>
    );
};

export default ProgressSummaryTableContainer;
