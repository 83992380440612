import classNames from 'classnames';
import React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import { TrailerTypeResource } from '../../../models/bookings/StoreBookingResource';
import { TimeSlotUnavailableReason } from '../../../models/timeSlot/TimeSlot';
import Center from '../../common/Center';
import CSSGrid from '../../common/CSSGrid';
import BookingCard from '../view/card/BookingCard';

const useClasses = buildClassesHook({
    overrideTimeSlotModal: {
        maxHeight: '90vh',
    },
    multipleConflictingBookingsModal: {
        maxWidth: '675px',
    },
    singleConflictingBookingModal: {
        maxWidth: '500px',
    },
    conflictingBookingsContainer: {
        maxHeight: '40vh',
        overflowY: 'auto',
        padding: '0.5rem',
    },
    bookingCard: {
        width: '100%',
    },
    conflictingBookingsHeader: {
        marginTop: '1.5rem',
    },
    areYouSureText: {
        marginBottom: 0,
        marginTop: '0.5rem',
        fontWeight: 600,
    },
});

interface OverrideTimeSlotModal {
    showModal: boolean;
    unavailableReasons: TimeSlotUnavailableReason[];
    unavailableResources?: LoadingResource[];
    bookingsInTimeSlot: Booking[];
    storeTrailerTypeResources: TrailerTypeResource[];
    onCancelSelect(): void;
    onConfirmSelect(): void;
}

const OverrideTimeSlotModal: React.FC<OverrideTimeSlotModal> = ({
    showModal,
    unavailableReasons,
    unavailableResources,
    bookingsInTimeSlot,
    storeTrailerTypeResources,
    onCancelSelect,
    onConfirmSelect,
}) => {
    const classes = useClasses();

    const multipleConflictingBookings = bookingsInTimeSlot.length > 1;

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classNames(
                classes.overrideTimeSlotModal,
                multipleConflictingBookings
                    ? classes.multipleConflictingBookingsModal
                    : classes.singleConflictingBookingModal
            )}
        >
            <ModalHeader>
                <h5>Override Unavailable Time Slot</h5>
            </ModalHeader>
            <ModalBody>
                {unavailableReasons.includes('No Booking Period') && (
                    <p>{"This time slot overlaps a 'No Bookings' period."}</p>
                )}
                {unavailableReasons.includes('Too Many Bookings') && (
                    <p>
                        This time slot currently has too many bookings and is
                        not able to fulfill this booking.
                    </p>
                )}

                {unavailableReasons.includes(
                    'Required Resource Unavailable'
                ) && (
                    <>
                        <p>
                            The following resources required by this booking are
                            unavailable as they are in use during the selected
                            time slot:
                        </p>
                        <ul>
                            {unavailableResources?.map((reason, i) => (
                                <li key={i}>{reason}</li>
                            ))}
                        </ul>
                    </>
                )}
                <h5 className={classes.conflictingBookingsHeader}>
                    Conflicting Bookings
                </h5>
                <Center>
                    <CSSGrid
                        className={classes.conflictingBookingsContainer}
                        columns={`repeat(${
                            multipleConflictingBookings ? '2' : '1'
                        }, 18rem)`}
                        gap={20}
                    >
                        {bookingsInTimeSlot.map((b) => (
                            <BookingCard
                                key={b.id}
                                className={classes.bookingCard}
                                booking={b}
                                trailerTypeResource={storeTrailerTypeResources.find(
                                    (r) =>
                                        r.bookingType === b.bookingType &&
                                        r.trailerType === b.trailerType
                                )}
                            />
                        ))}
                    </CSSGrid>
                </Center>
                <p className={classes.areYouSureText}>
                    Are you sure you want to select this time slot?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onCancelSelect}>
                    Cancel
                </Button>
                <Button styleType="danger" onClick={onConfirmSelect}>
                    Override
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OverrideTimeSlotModal;
