import classnames from 'classnames';
import * as React from 'react';
import InputBase from '../Forms/Base/InputBase';
import DropdownOption from './DropdownOption';

import { useClasses } from './SelectDropdown.styles';

interface SelectDropdownProps
    extends React.HtmlHTMLAttributes<HTMLSelectElement> {
    dropdownClassName?: string;
    formGroupClassName?: string;
    hideBottomPadding?: boolean;
    defaultText?: string;
    disabled?: boolean;
    error?: string | null;
    dropdownOptions: DropdownOption[];
    label?: string;
    multiple?: boolean;
    selectedOption: string | number | string[] | null;
    loadingOptions?: boolean;
    noOptionsMessage?: string;
    onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
    dropdownClassName,
    formGroupClassName,
    defaultText,
    dropdownOptions = [],
    disabled = false,
    multiple = false,
    onChange,
    selectedOption,
    noOptionsMessage,
    loadingOptions,
    onBlur,
    ...rest
}) => {
    const classes = useClasses();

    const options = dropdownOptions;
    const showNoOptionsMessage =
        !loadingOptions && noOptionsMessage && options.length === 0;

    return (
        <InputBase className={formGroupClassName} {...rest}>
            {({ className: inputBaseClassname, onBlur: baseOnBlur, id }) => (
                <select
                    id={id}
                    className={classnames(
                        dropdownClassName,
                        inputBaseClassname,
                        'form-control',
                        classes.dropdown
                    )}
                    disabled={disabled}
                    multiple={multiple}
                    onChange={onChange}
                    value={selectedOption || 'default'}
                    onBlur={(e) => {
                        baseOnBlur();
                        if (onBlur) {
                            onBlur(e);
                        }
                    }}
                    autoFocus
                >
                    {defaultText && (
                        <option disabled hidden value={'default'}>
                            {defaultText}
                        </option>
                    )}
                    {showNoOptionsMessage && (
                        <option disabled value={'No Options'}>
                            {noOptionsMessage}
                        </option>
                    )}
                    {options.map((o, i) => (
                        <option key={i} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </select>
            )}
        </InputBase>
    );
};

export default SelectDropdown;
