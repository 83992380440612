import * as React from 'react';
import { useState } from 'react';
import AlertContainer from '../../components/common/AlertContainer';
import CSSGrid, { CSSGridItem } from '../../components/common/CSSGrid';
import Nav from '../../components/navigation/Nav';
import SignalRDisconnectContainer from '../../containers/signalR/SignalRDisconnectContainer';
import useWindowSize from '../../hooks/useWindowSize';

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [navExpanded, setNavExpanded] = useState(false);
    const { isPortrait } = useWindowSize();

    let columns = navExpanded ? '300px auto' : '80px auto';
    if (isPortrait) {
        columns = '1fr';
    }

    const rows = isPortrait ? '1fr 80px' : 'auto';

    return (
        <CSSGrid
            columns={columns}
            rows={rows}
            style={isPortrait ? { height: '100vh' } : undefined}
        >
            <CSSGridItem column={1} row={isPortrait ? 2 : 1}>
                <Nav
                    expanded={navExpanded}
                    onExpandToggle={() => setNavExpanded((prev) => !prev)}
                    horizontal={isPortrait}
                />
            </CSSGridItem>
            <CSSGridItem
                column={isPortrait ? 1 : 2}
                row={1}
                style={
                    isPortrait
                        ? {
                              height: 'calc(100vh - 80px)',
                              width: '100vw',
                              overflow: 'auto',
                          }
                        : undefined
                }
            >
                <AlertContainer />
                <SignalRDisconnectContainer />
                {children}
            </CSSGridItem>
        </CSSGrid>
    );
};

Layout.displayName = Layout.name;

export default Layout;
