import * as React from 'react';
import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import Center from '../../../../components/common/Center';
import InformationMessage from '../../../../components/common/InformationMessage';
import UpdateNotification from '../../../../components/common/UpdateNotification';
import TrailerTooSmallMessage from '../../../../components/load/LoadIssues/TrailerTooSmallMessage';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { useNotificationListener } from '../../../../hooks/useNotificationListener';
import Alert from '../../../../lib/bootstrap-ui/Alert';
import { Load } from '../../../../models/loads/Load';
import { LoadStatus } from '../../../../models/loads/LoadStatus';
import {
    ChangesWithAffectedLoad,
    LoadRemoved,
    LoadRemovedNotification,
    LoadUpdatedNotification,
} from '../../../../models/notifications/Load';
import { useData } from '../../../../services/api';
import { useClasses } from './LoadIssuesContainer.styles';

interface LoadIssuesProps {
    id: string;
}

const LoadIssuesContainer: React.FC<LoadIssuesProps> = ({ id }) => {
    const classes = useClasses();

    const [load, loading] = useData<Load>(`Load/${id}`);
    const [loadToDisplay, setLoadToDisplay] = useState<Load | null>(null);

    useEffect(() => {
        if (load) {
            setLoadToDisplay(load);
        }
    }, [load]);

    const handleLoadUpdatedNotification = (
        changesWithAffectedLoad: ChangesWithAffectedLoad
    ) => {
        if (load === null) {
            return;
        }

        if (
            changesWithAffectedLoad.affectedLoad.id === id &&
            changesWithAffectedLoad.changes.length > 0
        ) {
            toast.info(
                <UpdateNotification
                    heading={`Load ${changesWithAffectedLoad.affectedLoad.loadName} Updated`}
                >
                    {changesWithAffectedLoad.changes.map((c) => (
                        <div key={c.id}>{c.message}</div>
                    ))}
                </UpdateNotification>
            );
            setLoadToDisplay(changesWithAffectedLoad.affectedLoad);
        }
    };

    const handleLoadRemovedNotification = (loadRemoved: LoadRemoved) => {
        if (load === null) {
            return;
        }

        if (loadRemoved.loadId === load.id) {
            toast.info(
                <UpdateNotification
                    heading={`Load ${loadRemoved.loadName} Removed`}
                />
            );
            setLoadToDisplay(null);
        }
    };

    useNotificationListener(
        LoadUpdatedNotification,
        handleLoadUpdatedNotification
    );
    useNotificationListener(
        LoadRemovedNotification,
        handleLoadRemovedNotification
    );

    return (
        <>
            {loading && <LoadingIndicator />}
            {!loading && loadToDisplay === null && (
                <InformationMessage
                    messageType="error"
                    className={classes.removedMessage}
                >
                    This load has been removed.
                </InformationMessage>
            )}
            {!loading && loadToDisplay !== null && (
                <Center>
                    <div className={classes.container}>
                        <h2 className="text-center">
                            {loadToDisplay.loadName}
                        </h2>
                        {loadToDisplay.status !== LoadStatus.Invalid &&
                            loadToDisplay.status !==
                                LoadStatus.UnexpectedState && (
                                <Alert type="success" className={classes.alert}>
                                    This load is valid.
                                </Alert>
                            )}
                        {loadToDisplay.status === LoadStatus.Invalid && (
                            <TrailerTooSmallMessage load={loadToDisplay} />
                        )}
                        {loadToDisplay.status ===
                            LoadStatus.UnexpectedState && (
                            <Alert type="danger" className={classes.alert}>
                                An error has occurred with this Part Load.
                                Please contact the IT department.
                            </Alert>
                        )}
                    </div>
                </Center>
            )}
        </>
    );
};

export default LoadIssuesContainer;
