import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    progressSummaryTotalRow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '12Rem',
        fontWeight: 'bold',
        textAlign: 'left',
    },
});
