import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    stockOutModal: {
        maxWidth: 900,
    },
    stockOutTableContainer: {
        margin: '0.5rem',
        overflow: 'auto',
        maxHeight: 400,
        padding: '0 1rem',
    },
    stockOutModalBody: {
        padding: '1rem 0',
    },
});
