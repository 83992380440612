import { ReducerAction } from '../models/reducers/ReducerAction';

type ActionType =
    | 'ADD_ORDERS'
    | 'ADD_ORDER'
    | 'REMOVE_ORDERS'
    | 'REPLACE_ORDERS';

interface AddOrdersAction extends ReducerAction<ActionType, string[]> {
    type: 'ADD_ORDERS';
}

interface AddOrderAction extends ReducerAction<ActionType, string> {
    type: 'ADD_ORDER';
}

interface RemoveOrdersAction extends ReducerAction<ActionType, string[]> {
    type: 'REMOVE_ORDERS';
}

interface ReplaceOrderAction extends ReducerAction<ActionType, string[]> {
    type: 'REPLACE_ORDERS';
}

export const UnattachedStockTransferOrderCountReducer = (
    unattachedStockTransferOrderIds: string[],
    action:
        | AddOrdersAction
        | AddOrderAction
        | RemoveOrdersAction
        | ReplaceOrderAction
): string[] => {
    switch (action.type) {
        case 'ADD_ORDERS':
            return [...unattachedStockTransferOrderIds, ...action.payload];
        case 'ADD_ORDER':
            return [...unattachedStockTransferOrderIds, action.payload];
        case 'REMOVE_ORDERS':
            return unattachedStockTransferOrderIds.filter(
                id => !action.payload.some(rId => rId === id)
            );
        case 'REPLACE_ORDERS':
            return action.payload;
    }
};
