import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import SendTestSMSTemplateModal from '../../../components/admin/smsTemplates/update/SendTestSMSTemplateModal';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import UpdateNotification from '../../../components/common/UpdateNotification';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { apiPost } from '../../../services/api';
import { getErrorMessage } from '../../../helpers/errors';

interface SendTestSMSTemplateModalContainerProps {
    showModal: boolean;
    smsTemplate: SMSTemplate;
    onClose(): void;
}

const SendTestSMSTemplateModalContainer: React.FC<
    SendTestSMSTemplateModalContainerProps
> = ({ showModal, smsTemplate, onClose }) => {
    const [sendingSms, setSendingSms] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleTestRequested = async (
        smsTemplateId: string,
        contactNumber: string
    ) => {
        setSendingSms(true);
        try {
            await apiPost(`SMSTemplate/${smsTemplateId}/Test`, contactNumber);
            toast.info(
                <UpdateNotification heading="SMS Message Sent">
                    A test SMS message was sent to {contactNumber}
                </UpdateNotification>
            );
            setSendingSms(false);
            onClose();
        } catch (e) {
            setSendingSms(false);
            setErrorMessage(
                `An error has occurred when trying to send a test version of the SMS Template: ${getErrorMessage(
                    e
                )}`
            );
        }
    };

    const showLoadingModal = showModal && sendingSms;
    const showEditModal = showModal && !sendingSms && !errorMessage;
    const showErrorModal = showModal && !!errorMessage;

    return (
        <>
            <LoadingModal
                showModal={showLoadingModal}
                loadingText="Sending message..."
            />
            <SendTestSMSTemplateModal
                showModal={showEditModal}
                smsTemplate={smsTemplate}
                onTestRequested={handleTestRequested}
                onClose={onClose}
            />
            <ErrorModal
                showModal={showErrorModal}
                header="Error"
                errorText={errorMessage}
                onClose={onClose}
            />
        </>
    );
};

export default SendTestSMSTemplateModalContainer;
