import { addHours, format, getDay, parseISO } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import RecurringBookingEditModalContainer from '../../../containers/recurringBookings/modals/RecurringBookingEditModalContainer';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import Icon from '../../common/Icon';
import MoreOptions from '../../common/MoreOptions';
import EmailModal from '../modals/EmailModal';
import ViewHistoryModal from '../modals/bookingHistory/ViewHistoryModal';
import RecurringBookingOptions, {
    RecurringBookingOptionType,
} from './RecurringBookingOptions';

import CancelBookingModalContainer from '../../../containers/bookings/CancelBookingModalContainer';
import { formatDate } from '../../../helpers/dates';
import { getDayString } from '../../../helpers/stringFunctions';
import { getTrailerTypeLabelForBooking } from '../../../services/trailerService';
import BookingColourSegment from '../BookingColourSegment';
import RecurringSummaryModal from '../modals/recurringBooking/RecurringSummaryModal';
import BookingTooltip from '../tooltip/BookingTooltip';
import { useClasses } from './RecurringBookingTableRow.styles';

interface RecurringBookingTableRowprops {
    recurringBooking: RecurringBooking;
    showStore: boolean;

    onRefreshBookings(): void;
}

const RecurringBookingTableRow: React.FC<RecurringBookingTableRowprops> = ({
    recurringBooking,
    showStore,
    onRefreshBookings,
}) => {
    const classes = useClasses();

    const [modalToDisplay, setModalToDisplay] =
        useState<RecurringBookingOptionType | null>(null);

    const resetModalToDisplay = () => {
        setModalToDisplay(null);
    };

    const resetModalAndRefreshBookings = () => {
        resetModalToDisplay();
        onRefreshBookings();
    };

    const hasInstructions = !!recurringBooking.bookingInstructions;

    return (
        <>
            <tr className={classes.tableRow}>
                <td
                    className={classes.typeColumn}
                    title={recurringBooking.bookingType || ''}
                >
                    <BookingColourSegment
                        bookingType={recurringBooking.bookingType}
                    />
                </td>
                <td className={classes.referenceData}>
                    <BookingTooltip
                        options={{
                            booking: recurringBooking,
                            type: 'recurring',
                        }}
                        onTriggerClick={() =>
                            setModalToDisplay('recurringBookingSummary')
                        }
                    />
                    <div>
                        {hasInstructions && (
                            <Icon
                                title={'Booking Instructions'}
                                className={classes.stickyNoteIcon}
                                type="sticky-note"
                            />
                        )}
                    </div>
                </td>
                <td>{getTrailerTypeLabelForBooking(recurringBooking)}</td>
                <td>{recurringBooking.customerName}</td>
                <td>
                    {recurringBooking.recurrencePattern}
                    {recurringBooking.recurrencePattern === 'Weekly' &&
                        ` (${getDayString(
                            getDay(parseISO(recurringBooking.startDate))
                        )})`}
                </td>
                <td>{formatDate(recurringBooking.startDate, 'HH:mm')}</td>
                <td>
                    {format(
                        addHours(
                            parseISO(recurringBooking.startDate),
                            recurringBooking.duration
                        ),
                        'HH:mm'
                    )}
                </td>
                <td>
                    {recurringBooking.endDate &&
                        formatDate(recurringBooking.endDate, 'dd/MM/yyyy')}
                </td>
                <td>
                    {recurringBooking.resources.includes('Clamp Truck') && (
                        <Icon type="tick" />
                    )}
                </td>
                <td>
                    {recurringBooking.resources.includes('Handball Team') && (
                        <Icon type="tick" />
                    )}
                </td>
                <td>
                    {recurringBooking.resources.includes(
                        'Counterbalance Truck'
                    ) && <Icon type="tick" />}
                </td>
                <td>
                    {recurringBooking.resources.includes('Conveyor Belt') && (
                        <Icon type="tick" />
                    )}
                </td>
                <td>
                    {recurringBooking.resources.includes('Weighing Scale') && (
                        <Icon type="tick" />
                    )}
                </td>
                {showStore && <td>{recurringBooking.store}</td>}
                <td>
                    <MoreOptions>
                        {(close: () => void) => (
                            <RecurringBookingOptions
                                bookingType={recurringBooking.bookingType}
                                onOptionRequested={(type) => {
                                    close();
                                    setModalToDisplay(type);
                                }}
                            />
                        )}
                    </MoreOptions>
                </td>
            </tr>
            {modalToDisplay === 'cancel' && (
                <CancelBookingModalContainer
                    options={{
                        bookingType: 'recurring',
                        booking: recurringBooking,
                    }}
                    showModal={modalToDisplay === 'cancel'}
                    onCancelBooking={resetModalAndRefreshBookings}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'email' && (
                <EmailModal
                    booking={recurringBooking}
                    bookingType="recurring"
                    showModal={modalToDisplay === 'email'}
                    onEmailSent={resetModalToDisplay}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'edit' && (
                <RecurringBookingEditModalContainer
                    recurringBooking={recurringBooking}
                    showModal={modalToDisplay === 'edit'}
                    onEditConfirmed={() => onRefreshBookings()}
                    onEmailSent={resetModalToDisplay}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'viewHistory' && (
                <ViewHistoryModal
                    booking={recurringBooking}
                    type="recurringBooking"
                    showModal={modalToDisplay === 'viewHistory'}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'recurringBookingSummary' && (
                <RecurringSummaryModal
                    recurringBooking={recurringBooking}
                    showModal={modalToDisplay === 'recurringBookingSummary'}
                    onClose={resetModalToDisplay}
                />
            )}
        </>
    );
};

export default RecurringBookingTableRow;
