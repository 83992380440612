import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    UnattachedStockTransferOrderCount: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0.25rem 0.75rem',
        borderRight: '2px solid darkgray',
        '&:last-child': {
            borderRight: 'none',
        },
    },
    UnattachedOrdersText: {
        margin: 0,
        fontSize: '0.7rem',
        fontWeight: 600,
        color: 'darkgrey',
        textTransform: 'uppercase',
    },
    UnattachedOrdersValue: {
        lineHeight: 0.8,
        margin: 0,
    },
});
