import * as React from 'react';
import { Link } from 'react-router-dom';
import { routeFor, RouteKey } from '../../../../Routes/routes';
import { useClasses } from './PartLoadReference.styles';
import Icon from '../../../common/Icon';

interface LoadProgressDetailsLinkProps {
    loadId: string;
    loadName?: string;
    displayAmendmentLink: boolean;
}

const LoadProgressDetailsLink: React.FC<LoadProgressDetailsLinkProps> = ({
    loadId,
    loadName,
    displayAmendmentLink,
}) => {
    const classes = useClasses();

    return (
        <Link
            to={routeFor(RouteKey.LoadingProgressDetailed)({
                id: loadId,
            })}
        >
            {displayAmendmentLink ? (
                <Icon
                    className={classes.outstandingPickListAmendmentIcon}
                    title="Outstanding Pick List Amendment"
                    type="warning-thicc"
                />
            ) : (
                loadName
            )}
        </Link>
    );
};

export default LoadProgressDetailsLink;
