import React from 'react';
import { toast } from 'react-toastify';
import { UserRole } from '../../helpers/userRole';
import { useNotificationListener } from '../../hooks/useNotificationListener';
import { AlertNotification } from '../../models/notifications/Load';
import { useAuth, UserProfile } from '../../services/authentication';

const userInAnyStore = (user: UserProfile | null) => (stores: number[]) => {
    if (!user) {
        return false;
    }
    const storeRoles: UserRole[] = stores.map((s) =>
        s === 1
            ? UserRole.Store1
            : s === 2
            ? UserRole.Store2
            : s === 3
            ? UserRole.Store3
            : UserRole.Store4
    );
    return user.roles.some((r) => storeRoles.includes(r));
};

const AlertHandler: React.FC = () => {
    const { authState } = useAuth();
    const isInAnyStore = userInAnyStore(authState && authState.user);

    useNotificationListener(AlertNotification, (changesWithAffectedStores) => {
        if (isInAnyStore(changesWithAffectedStores.stores)) {
            toast.error(
                <>
                    <h4>Changes made to released orders.</h4>
                    {changesWithAffectedStores.changes.map((c) => (
                        <div key={c.id}>{c.message}</div>
                    ))}
                </>,
                {
                    autoClose: false,
                }
            );
        }
    });

    return null;
};

export default AlertHandler;
