import * as React from 'react';
import Modal, {
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../../lib/bootstrap-ui/Modal';
import Button from '../../../lib/bootstrap-ui/Button';
import { BookingAttachment } from '../../../models/bookings/BookingAttachment';

interface DeleteAttachmentModalProps {
    bookingAttachment: BookingAttachment;
    showModal: boolean;
    onDelete(): void;
    onClose(): void;
}

const DeleteAttachmentModal: React.FC<DeleteAttachmentModalProps> = ({
    bookingAttachment,
    showModal,
    onDelete,
    onClose
}) => {
    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Delete {bookingAttachment.fileName}</h5>
            </ModalHeader>
            <ModalBody>
                Are you sure you want to delete{' '}
                <span style={{ fontStyle: 'italic' }}>
                    {bookingAttachment.fileName}
                </span>
                ?
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button styleType="danger" onClick={onDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteAttachmentModal;
