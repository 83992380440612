import { FileType } from '../models/common/FileType';

const getFileTypeFromFileName = (fileName: string) => {
    const extension = fileName.slice(fileName.lastIndexOf('.'));
    switch (extension) {
        case '.msg':
            return FileType.msg;
        case '.eml':
            return FileType.eml;
        case '.pdf':
            return FileType.pdf;
        case '.doc':
            return FileType.doc;
        case '.docx':
            return FileType.docx;
        case '.rtf':
            return FileType.rtf;
        case '.xls':
            return FileType.xls;
        case '.xlsx':
            return FileType.xlsx;
        default:
            return FileType.unknown;
    }
};

export { getFileTypeFromFileName };
