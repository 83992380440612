import { Notification } from '.';
import { Booking } from '../bookings/Booking';
import NotificationBase from '../updates/NotificationBase';

type BookingUpdateAction =
    | 'Add'
    | 'Edit'
    | 'Update Resources'
    | 'Cancel'
    | 'Check In'
    | 'Undo Check In'
    | 'Complete'
    | 'Reschedule'
    | 'Convert To Delivery';

export interface BookingUpdated extends NotificationBase {
    updatedBooking: Booking;
    action: BookingUpdateAction;
}

export const BookingUpdatedNotification: Notification<BookingUpdated> = {
    message: 'BookingUpdated'
};
