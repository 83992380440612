import { isNullOrUndefined } from 'is-what';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import Bay from '../../../models/bays/Bay';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { getStoreDropdownOptions } from '../../../services/storeService';
import SingleColumnRow from '../../common/SingleColumnRow';
import BayCapabilityOptions from './BayCapabilityOptions';
import BayStatusOptionsSelector, {
    BayStatusOptions
} from './BayStatusOptionsSelector';

const useClasses = buildClassesHook({
    formTitle: {
        marginBottom: '1em'
    },
    header: {
        margin: '0.5em 0'
    },
    addButton: {
        marginTop: '2em',
        width: '100%'
    }
});

interface AddBayFormProps {
    bay: Bay;
    loading: boolean;
    isFormValid: boolean;
    stores: StoreNumber[];

    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
    onBayChange: (bay: Bay) => void;
}

const handleStoreChange = (onBayChange: (bay: Bay) => void, bay: Bay) => (
    event: React.ChangeEvent<HTMLSelectElement>
) => {
    const store: number = parseInt(event.target.value, 10);
    onBayChange({ ...bay, store });
};

const handleBayStatusChange = (onBayChange: (bay: Bay) => void, bay: Bay) => (
    bayStatusOptions: BayStatusOptions
) => {
    onBayChange({
        ...bay,
        isOutOfOrder: bayStatusOptions.isOutOfOrder,
        isVirtualBay: bayStatusOptions.isVirtualBay
    });
};

const handleBayNumberChange = (onBayChange: (bay: Bay) => void, bay: Bay) => (
    value: string | null
) => {
    const id = !!value ? parseInt(value, 10) : null;
    onBayChange({ ...bay, id });
};

const handleBayCapabilityChange = (
    onBayChange: (bay: Bay) => void,
    bay: Bay
) => (capabilities: string[]) => {
    onBayChange({ ...bay, capabilities });
};

const AddBayForm: React.FC<AddBayFormProps> = ({
    bay,
    loading,
    isFormValid,
    stores,
    onBayChange,
    onSubmit
}) => {
    const classes = useClasses();

    const onStoreChange = handleStoreChange(onBayChange, bay);
    const onBayStatusChange = handleBayStatusChange(onBayChange, bay);
    const onBayNumberChange = handleBayNumberChange(onBayChange, bay);
    const onBayCapabilityChange = handleBayCapabilityChange(onBayChange, bay);

    return (
        <form>
            <h1 className={classes.formTitle}>Add Bay</h1>
            <SingleColumnRow>
                <TextInput
                    label="Bay Number:"
                    placeholder="Enter Bay Number"
                    type="number"
                    value={bay && !isNullOrUndefined(bay.id) ? bay.id : ''}
                    onChange={onBayNumberChange}
                    highlightOnFocus
                />
            </SingleColumnRow>
            <SingleColumnRow>
                <SelectDropdown
                    id="StoreSelect"
                    label="Store:"
                    defaultText="Select Store..."
                    dropdownOptions={getStoreDropdownOptions(
                        stores.map(String)
                    )}
                    selectedOption={
                        bay && !isNullOrUndefined(bay.store)
                            ? String(bay.store)
                            : null
                    }
                    onChange={onStoreChange}
                    disabled={loading}
                />
            </SingleColumnRow>
            <hr />
            <SingleColumnRow>
                <h5 className={classes.header}>Capabilities</h5>
                <BayCapabilityOptions
                    selectedBayCapabilities={bay.capabilities}
                    onBayCapabilityChange={onBayCapabilityChange}
                />
            </SingleColumnRow>
            <hr />
            <SingleColumnRow>
                <h5 className={classes.header}>Statuses</h5>
                <BayStatusOptionsSelector
                    bay={bay}
                    onBayStatusChange={onBayStatusChange}
                />
            </SingleColumnRow>
            <SingleColumnRow columnSize={12}>
                <Button
                    className={classes.addButton}
                    disabled={!isFormValid}
                    onClick={onSubmit}
                >
                    Add Bay
                </Button>
            </SingleColumnRow>
        </form>
    );
};

export default AddBayForm;
