import * as React from 'react';
import { useData } from '../services/api';
import { useAuth } from '../services/authentication';
import { TrailerType } from '../models/trailers/TrailerType';
import { LoadingResource } from '../models/bookings/LoadingResource';
import { BookingType } from '../models/bookings/BookingType';
import { BookingCancellationReason } from '../models/bookings/BookingCancellationReason';
import { StoreNumber } from '../models/stores/StoreNumber';

interface LookupContextProps {
    loading: boolean;
    bayCapabilities: string[];
    bookingTypes: BookingType[];
    loadingResources: LoadingResource[];
    recurrencePattern: string[];
    stores: StoreNumber[];
    trailerTypes: TrailerType[];
    bookingCancellationReasons: BookingCancellationReason[];
}

export const LookupContext = React.createContext<LookupContextProps>({
    loading: true,
    bayCapabilities: [],
    bookingTypes: [],
    loadingResources: [],
    recurrencePattern: [],
    stores: [],
    trailerTypes: [],
    bookingCancellationReasons: []
});

interface LookupDataProps {
    children: React.ReactNode;
}

interface LookupData {
    BayCapability: string[];
    BookingType: BookingType[];
    LoadingResource: LoadingResource[];
    RecurrencePattern: string[];
    Store: StoreNumber[];
    TrailerType: TrailerType[];
    BookingCancellationReason: BookingCancellationReason[];
}

const LookupDataProvider: React.FC<LookupDataProps> = ({ children }) => {
    const {
        authState: { loggedIn }
    } = useAuth();

    const lookupUrl = loggedIn ? `Lookup` : null;
    const [lookup, loading] = useData<LookupData>(lookupUrl);

    const lookupData = lookup || {
        BayCapability: [],
        BookingType: [],
        LoadingResource: [],
        RecurrencePattern: [],
        Store: [],
        TrailerType: [],
        BookingCancellationReason: []
    };

    return (
        <LookupContext.Provider
            value={{
                loading: loading,
                bayCapabilities: lookupData.BayCapability,
                bookingTypes: lookupData.BookingType,
                loadingResources: lookupData.LoadingResource,
                recurrencePattern: lookupData.RecurrencePattern,
                stores: lookupData.Store,
                trailerTypes: lookupData.TrailerType,
                bookingCancellationReasons: lookupData.BookingCancellationReason
            }}
        >
            {children}
        </LookupContext.Provider>
    );
};

const LookupDataConsumer = LookupContext.Consumer;

export { LookupDataProvider, LookupDataConsumer };
