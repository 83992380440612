import { addDays } from 'date-fns';
import * as React from 'react';
import { useContext, useState } from 'react';
import CheckboxListSelector from '../../components/common/CheckboxListSelector';
import DatePickerInput from '../../components/common/DatePicker/DatePickerInput';
import { DateRange } from '../../components/common/DateRangePills';
import SingleColumnRow from '../../components/common/SingleColumnRow';
import StoreSelectorPills, {
    StoreSelectorOption
} from '../../components/common/StoreSelectorPills';
import { LookupContext } from '../../contexts/LookupDataProvider';
import usePersistedState from '../../hooks/usePersistedState';
import { TextInput } from '../../lib/bootstrap-ui/Forms';
import { Column, Row } from '../../lib/bootstrap-ui/Grid';
import BookingFilter from '../../models/bookings/BookingFilter';
import { BookingType } from '../../models/bookings/BookingType';
import { LoadingResource } from '../../models/bookings/LoadingResource';
import { getBookingsEndDateFromDateRange } from '../../services/bookingService';

interface BookingTableFilterContainerProps {
    bookingFilter: BookingFilter;
    showStoreSelector: boolean;
    selectedStoreOption: StoreSelectorOption;

    onStoreOptionChange(storeOption: StoreSelectorOption): void;
    onFilterChange(filter: BookingFilter): void;
}

const BookingTableFilterContainer: React.FC<BookingTableFilterContainerProps> = ({
    bookingFilter,
    showStoreSelector,
    selectedStoreOption,
    onStoreOptionChange,
    onFilterChange
}) => {
    const { loadingResources, bookingTypes } = useContext(LookupContext);

    const [selectedDateRange] = usePersistedState<DateRange>(
        'Day',
        'booking-date-range'
    );

    const [displayDatePicker, setDisplayDatePicker] = useState(false);

    const handleFilterPropertyChange = <T,>(name: keyof BookingFilter) => (
        value: T
    ) => {
        onFilterChange({ ...bookingFilter, [name]: value });
    };

    const handleDateSelected = (selectedDate: Date) => {
        onFilterChange({
            ...bookingFilter,
            startDate: selectedDate,
            endDate: selectedDate
                ? addDays(selectedDate, 1)
                : getBookingsEndDateFromDateRange(selectedDateRange),
            startDateFilteredApplied: !!selectedDate
        });
        setDisplayDatePicker(false);
    };

    return (
        <>
            {showStoreSelector && (
                <>
                    <SingleColumnRow>
                        <label>Store:</label>
                    </SingleColumnRow>
                    <SingleColumnRow style={{ marginBottom: '1rem' }}>
                        <StoreSelectorPills
                            onStoreOptionChange={onStoreOptionChange}
                            active={selectedStoreOption}
                        />
                    </SingleColumnRow>
                </>
            )}
            <Row>
                <Column>
                    <TextInput
                        label="Reference:"
                        placeholder="Reference..."
                        size="small"
                        value={bookingFilter.reference}
                        onChange={handleFilterPropertyChange<string | null>(
                            'reference'
                        )}
                        autoConvertWhitespace
                        hideBottomPadding
                    />
                </Column>
                <Column>
                    <TextInput
                        label="Order Number:"
                        placeholder="Order Number..."
                        size="small"
                        value={bookingFilter.orderNumber}
                        onChange={handleFilterPropertyChange<string | null>(
                            'orderNumber'
                        )}
                        autoConvertWhitespace
                        hideBottomPadding
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <TextInput
                        label="Supplier/Product Code:"
                        placeholder="Supplier/Product code..."
                        size="small"
                        value={bookingFilter.productOrSupplierCode}
                        onChange={handleFilterPropertyChange<string | null>(
                            'productOrSupplierCode'
                        )}
                        autoConvertWhitespace
                        hideBottomPadding
                    />
                </Column>
                <Column>
                    <TextInput
                        label="Customer Name:"
                        placeholder="Customer Name..."
                        size="small"
                        value={bookingFilter.customerName}
                        onChange={handleFilterPropertyChange<string | null>(
                            'customerName'
                        )}
                        autoConvertWhitespace
                        hideBottomPadding
                    />
                </Column>
            </Row>
            <Row>
                <Column size={6}>
                    <DatePickerInput
                        inputLabel="Date:"
                        inputPlaceholder="dd/mm/yyyy"
                        popupPosition="bottom left"
                        isOpen={displayDatePicker}
                        value={bookingFilter.startDate}
                        onDateSelected={handleDateSelected}
                        onClose={() => setDisplayDatePicker(false)}
                        onOpen={() => setDisplayDatePicker(true)}
                    />
                </Column>
            </Row>
            <div onClick={() => setDisplayDatePicker(false)}>
                <SingleColumnRow>
                    <CheckboxListSelector
                        label={'Booking Types:'}
                        inline
                        columns={2}
                        availableItems={bookingTypes}
                        selectedItems={bookingFilter.bookingTypes || []}
                        onChange={handleFilterPropertyChange<BookingType[]>(
                            'bookingTypes'
                        )}
                    />
                </SingleColumnRow>

                <SingleColumnRow style={{ marginTop: '1rem' }}>
                    <CheckboxListSelector
                        label={'Resources:'}
                        inline
                        columns={2}
                        availableItems={loadingResources}
                        selectedItems={bookingFilter.resources || []}
                        onChange={handleFilterPropertyChange<LoadingResource[]>(
                            'resources'
                        )}
                    />
                </SingleColumnRow>
            </div>
        </>
    );
};

export default BookingTableFilterContainer;
