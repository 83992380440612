export interface Substitution {
    label: string;
    value: string;
}

export const AvailableSubstitutions: Substitution[] = [
    {
        label: 'Bay',
        value: '{bayNumber}'
    },
    {
        label: 'Store',
        value: '{storeNumber}'
    }
];
