import 'bootstrap/dist/css/bootstrap.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Id, toast } from 'react-toastify';
import App from './App';
import { ENVIRONMENT } from './environment-variables';
import './index.css';
import { register } from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
document.title =
    ENVIRONMENT.toLocaleUpperCase() === 'PRODUCTION'
        ? 'Dock System'
        : `Dock System - ${ENVIRONMENT}`;

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

// TODO: The code below is commented for future reference #14423
// const refreshPage =
//     (serviceWorker: ServiceWorker | null, toastId: Id) =>
//     (event: React.MouseEvent<Element, MouseEvent> | undefined) => {
//         event?.preventDefault();
//         toast.dismiss(toastId);
//         if (serviceWorker) {
//             serviceWorker.addEventListener(
//                 'statechange',
//                 (stateChangeEvent) => {
//                     if (
//                         (stateChangeEvent.target as ServiceWorker).state ===
//                         'activated'
//                     ) {
//                         window.location.reload();
//                     }
//                 }
//             );
//             serviceWorker.postMessage({ type: 'SKIP_WAITING' });
//         }
//     };

const handleUpdate = () => {
    const toastId: Id = 'updates-available';
    toast.warning(
        <>
            <h3 style={{ color: '#373D43' }}>
                Action Required: Updates Available
            </h3>
            <h4>
                Press <b>CTRL + F5</b> keys to refresh and receive the updates.
            </h4>
        </>,
        {
            toastId,
            autoClose: false,
            position: 'top-center',
            closeOnClick: false,
            closeButton: false,
        }
    );
};

register({ onUpdate: handleUpdate });
