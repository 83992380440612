import * as React from 'react';
import { BookingType } from '../../models/bookings/BookingType';
import { getBookingTypeColour } from '../../services/bookingService';

interface BookingColourSegmentProps {
    bookingType: BookingType;
}

const BookingRowColourSegment: React.FC<BookingColourSegmentProps> = ({
    bookingType
}) => {
    return <div style={{ background: getBookingTypeColour(bookingType) }} />;
};

export default BookingRowColourSegment;
