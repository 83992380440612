import * as React from 'react';
import HeaderContainer from '../../components/common/HeaderContainer';
import usePersistedState from '../../hooks/usePersistedState';
import { useCurrentUser } from '../../services/authentication';
import { userIsAdmin, userToAllStores } from '../../services/userService';
import LoadingTableActionContainer, {
    Action,
} from '../load/loadTable/view/LoadingTableActionContainer';
import { useClasses } from './ProgressSummaryContainer.styles';
import ProgressSummaryTableContainer from './ProgressSummaryTableContainer';
import { StoreSelectorOption } from '../../components/common/StoreSelectorPills';

const actions: Action[] = [
    { title: 'storeSelector' },
    { title: 'workQueue' },
    { title: 'workHistory' },
];

interface ProgressSummaryProps {
    selectedStore?: StoreSelectorOption;
    onStoreChange: (store: StoreSelectorOption) => void;
}

const ProgressSummaryContainer: React.FC<ProgressSummaryProps> = ({
    selectedStore,
    onStoreChange,
}) => {
    const user = useCurrentUser();

    const storesToShow: StoreSelectorOption[] = !user
        ? [1]
        : userIsAdmin(user)
        ? [...userToAllStores(user), 'All']
        : userToAllStores(user);

    const [store, setStore] = usePersistedState<StoreSelectorOption>(
        selectedStore || storesToShow[0],
        'progress-summary-store'
    );

    const classes = useClasses();

    const handleStoreChange = async (store: StoreSelectorOption) => {
        onStoreChange(store);
        setStore(store);
    };

    return (
        <div className={classes.progressSummaryContainer}>
            <HeaderContainer headerText="Progress Summary">
                <LoadingTableActionContainer
                    actionsToDisplay={actions}
                    storeOption={store}
                    storeOptions={storesToShow}
                    onStoreOptionChange={handleStoreChange}
                />
            </HeaderContainer>
            <ProgressSummaryTableContainer
                className={classes.progressSummaryTableContainer}
                selectedStore={store}
            />
        </div>
    );
};

export default ProgressSummaryContainer;
