import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    stickyNoteIcon: {
        width: '1rem',
        color: '#daa520',
        margin: '0 0.2rem',
    },
    attachmentsIcon: {
        width: '1rem',
        color: theme.colors.darkGrey,
    },
    typeColumn: {
        '& > div': {
            height: '2em',
            width: '100%',
            minWidth: 5,
        },
    },
    bookingTableRow: {
        '& td': {
            padding: '0.5rem 0.75rem',
            whiteSpace: 'nowrap',
        },
        '& td$typeColumn': {
            width: 5,
            padding: 0,
            paddingTop: 5,
        },
    },
    onSite: {
        backgroundColor: theme.colors.palePink,
        '&:hover': {
            backgroundColor: `${theme.colors.palePink} !important`,
        },
    },
    noBookings: {
        backgroundColor: theme.colors.palePink,
        '&:hover': {
            backgroundColor: `${theme.colors.pink} !important`,
        },
    },
    referenceData: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bookingRefText: {
        paddingRight: '1em',
    },
    iconButton: {
        padding: 0,
        border: 0,
        margin: '0 0.2rem',
    },
    recurringIcon: {
        color: theme.colors.lightBlack,
    },
    restrictedCell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '12rem',
    },
    restrictedReasonCell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '25rem',
    },
    bayOrPagerCell: {
        textAlign: 'center',
        width: '1rem',
    },
    haulierName: {
        maxWidth: '8rem',
    },
    updatedLink: {
        animation: '$updatedFade 2s',
        '& span': {
            animation: '$actionTextUpdatedFade 2s',
        },
    },
    overriddenIcon: {
        color: theme.colors.error,
    },
    '@keyframes updatedFade': {
        '0%': {
            backgroundColor: 'auto',
            color: 'auto',
        },
        '50%': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
        },
        '100%': {
            backgroundColor: 'auto',
            color: 'auto',
        },
    },
    '@keyframes actionTextUpdatedFade': {
        '0%': {
            color: 'auto',
        },
        '50%': {
            color: theme.colors.white,
        },
        '100%': {
            color: 'auto',
        },
    },
}));
