import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    backButton: {
        width: '50%',
    },
    cursorNotAllowed: {
        cursor: 'not-allowed',
    },
    releaseButton: {
        width: '100%',
    },
    releaseButtonRow: {
        marginTop: '2em',
    },
    rowTop2: {
        marginTop: '2em',
    },
    loadPlanButton: {
        width: '100%',
    },
    container: {
        minWidth: 330,
        maxWidth: 650,
        width: '100%',
        marginTop: '2em',
        '@media (min-width: 700px)': {
            container: {
                width: 650,
            },
        },
    },
    bayStatusData: {
        minWidth: 74,
    },
    awaitingChessReleaseText: {
        fontSize: '1.25rem',
        fontWeight: 'normal',
    },
    releaseLoadContainer: {
        marginTop: '1.5rem',
    },
});
