import classNames from 'classnames';
import * as React from 'react';

import { useState } from 'react';
import { useClasses } from './InputBase.styles';

interface InputBaseProps {
    id?: string;
    hideBottomPadding?: boolean;
    className?: string;
    label?: string;
    helpText?: string;
    error?: string | null;
    inline?: boolean;
    validateImmediately?: boolean;
}

interface InputBasePropsWithChildren extends InputBaseProps {
    children(props: {
        className: string;
        onBlur(): void;
        id?: string;
    }): React.ReactNode;
}

const InputBase: React.FC<InputBasePropsWithChildren> = ({
    id,
    hideBottomPadding = false,
    className,
    label,
    helpText,
    error,
    inline = false,
    validateImmediately,
    children,
}) => {
    const classes = useClasses();

    const [hasBeenInteractedWith, setHasBeenInteractedWith] = useState(false);
    const hasError = error && error !== '';

    return (
        <div
            className={classNames('form-group', classes.formGroup, className, {
                'form-inline': inline,
                [classes.inline]: inline,
                [classes.notInvalid]:
                    !(
                        (hasBeenInteractedWith || validateImmediately) &&
                        hasError
                    ) &&
                    !helpText &&
                    !hideBottomPadding,
            })}
        >
            {label && <label htmlFor={id}>{label}</label>}
            {children({
                className: classNames({
                    [classes.invalid]:
                        (hasBeenInteractedWith || validateImmediately) &&
                        hasError,
                }),
                onBlur: () => {
                    setHasBeenInteractedWith(true);
                },
                id,
            })}
            {(hasBeenInteractedWith || validateImmediately) && hasError ? (
                <small className="form-text text-danger">{error}</small>
            ) : (
                helpText && (
                    <small className="form-text text-secondary">
                        {helpText}
                    </small>
                )
            )}
        </div>
    );
};

export default InputBase;
