import * as React from 'react';
import BookingContainer from '../../containers/bookings/bookingTable/BookingContainer';
import Page from '../common/Page';

const Bookings: React.FC = () => (
    <Page>
        <BookingContainer />
    </Page>
);

export default Bookings;
