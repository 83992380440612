import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    storeThresholdContainer: {
        padding: '1rem',
        boxShadow: '1px 1px 3px 3px hsl(210, 10%, 92%)',
        borderRadius: '0.5rem',
        maxWidth: '1400px',
        margin: '1.5rem auto',
    },
    thresholdDurationGrid: {
        margin: '1rem 1rem 0 1rem',
    },
});
