import * as React from 'react';
import { useState } from 'react';

import { compareDatesAsc } from '../../../helpers/dates';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { CreateBooking } from '../../../models/bookings/BookingToAdd';
import { BookingType } from '../../../models/bookings/BookingType';
import { ColdStoreOrder } from '../../../models/orders/ColdStoreOrder';
import { InternalWorkOrder } from '../../../models/orders/InternalWorkOrder';
import { ThirdPartyCollectionOrder } from '../../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import { useData } from '../../../services/api';
import { GetCustomerName } from '../../../services/bookingService';
import InternalWorkOrderSelectionContainer from '../update/InternalWorkOrderSelectionContainer';
import ThirdPartyOrderSelectionContainer from '../update/ThirdPartyOrderSelectionContainer';
import { useClasses } from './BookingForOrderContainer.styles';
import BookingFormLoadTypeContainer from './BookingFormLoadTypeContainer';
import BookingFormResourceContainer from './BookingFormResourceContainer';

const BookingFormOrderContainer: React.FC<FormStepProps<CreateBooking>> = ({
    formObject: booking,
    onChange: onBookingChange,
}) => {
    const classes = useClasses();

    const [orderSearchValue, setOrderSearchValue] = useState('');
    const [invalidOrder, setInvalidOrder] = useState<boolean>(false);

    const [loadedCustomerNames] = useData<string[]>(`Customer/Values`);

    const handleOrdersChange = (
        selectedOrders: InternalWorkOrder[] | ThirdPartyCollectionOrder[],
        invalidOrderSelected?: boolean
    ) => {
        let editedBooking: CreateBooking;

        switch (booking.bookingType) {
            case BookingType.Collection:
            case BookingType.DeliveryAndCollection:
            case BookingType.Internal:
                const customerReference: string = selectedOrders
                    .map((o) => o.orderNumber)
                    .join(', ');

                const firstOrder: ColdStoreOrder | null = selectedOrders.sort(
                    (lhs: ColdStoreOrder, rhs: ColdStoreOrder) =>
                        compareDatesAsc(lhs.deliveryDate, rhs.deliveryDate)
                )[0];

                const customerName: string | null = GetCustomerName(
                    loadedCustomerNames,
                    firstOrder
                );

                editedBooking = {
                    ...booking,
                    orders: selectedOrders,
                    customerReference,
                    customerName,
                    invalidOrderSelected: invalidOrderSelected || false,
                };
                break;
            default:
                throw Error();
        }

        onBookingChange(editedBooking);
        setInvalidOrder(invalidOrderSelected || false);
    };

    return (
        <Grid>
            {(booking.bookingType === BookingType.Collection ||
                booking.bookingType === BookingType.Internal ||
                booking.bookingType === BookingType.DeliveryAndCollection) && (
                <>
                    <Row>
                        <Column>
                            {booking.bookingType === BookingType.Internal ? (
                                <InternalWorkOrderSelectionContainer
                                    searchValue={orderSearchValue}
                                    selectedOrders={booking.orders}
                                    store={booking.store}
                                    onOrdersChange={handleOrdersChange}
                                    onSearchValueChange={setOrderSearchValue}
                                />
                            ) : (
                                <ThirdPartyOrderSelectionContainer
                                    searchValue={orderSearchValue}
                                    selectedOrders={booking.orders}
                                    store={booking.store}
                                    onOrdersChange={handleOrdersChange}
                                    onSearchValueChange={setOrderSearchValue}
                                />
                            )}
                        </Column>
                    </Row>
                    <hr />
                </>
            )}
            {booking.bookingType === BookingType.Internal ? (
                <BookingFormResourceContainer
                    formObject={booking}
                    onChange={onBookingChange}
                />
            ) : (
                <Row>
                    <Column>
                        <BookingFormLoadTypeContainer
                            booking={booking}
                            onBookingChange={onBookingChange!}
                        />
                    </Column>
                </Row>
            )}
            {invalidOrder && (
                <Row>
                    <Column>
                        <div className={classes.errorMessageContainer}>
                            <p className={classes.errorMessage}>
                                One of the orders selected has been cancelled or
                                is already attached to another booking
                            </p>
                        </div>
                    </Column>
                </Row>
            )}
        </Grid>
    );
};

export default BookingFormOrderContainer;
