import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    populateDetectedTrailerButton: {
        padding: 0,
        margin: 0,
        color: theme.colors.primary,
        verticalAlign: 'baseline',
        whiteSpace: 'normal',
        textAlign: 'left',
        fontWeight: 600,
        textDecoration: 'underline',
    },
    pickerMessagesColumn: {
        minHeight: '1.5em',
    },
}));
