import * as React from 'react';
import { pluralise } from '../../../helpers/stringFunctions';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import InformationMessage from '../../common/InformationMessage';
import TimeSlotConflictsList from './TimeSlotConflictsList';

const useClasses = buildClassesHook({
    conflictModal: {
        maxWidth: '600px',
    },
});

interface TimeSlotConflictsModalProps {
    unavailableTimeSlots: TimeSlot[];
    isOpen: boolean;
    allowOverride?: boolean;
    onOverrideRequested(): void;
    onCloseRequested(): void;
}

const TimeSlotConflictsModal: React.FC<TimeSlotConflictsModalProps> = ({
    isOpen,
    unavailableTimeSlots,
    allowOverride = false,
    onOverrideRequested,
    onCloseRequested,
}) => {
    const classes = useClasses();

    const tooManyBookingsConflicts = !!unavailableTimeSlots
        ? unavailableTimeSlots.filter((ts) =>
              ts.unavailableReasons.includes('Too Many Bookings')
          )
        : [];
    const outsideHourConflicts = !!unavailableTimeSlots
        ? unavailableTimeSlots.filter((ts) =>
              ts.unavailableReasons.includes('Outside Open Hours')
          )
        : [];
    const resourceConflicts = !!unavailableTimeSlots
        ? unavailableTimeSlots.filter((ts) =>
              ts.unavailableReasons.includes('Required Resource Unavailable')
          )
        : [];
    const noBookingPeriodConflicts = !!unavailableTimeSlots
        ? unavailableTimeSlots.filter((ts) =>
              ts.unavailableReasons.includes('No Booking Period')
          )
        : [];

    const totalConflictTypes = [
        tooManyBookingsConflicts,
        outsideHourConflicts,
        resourceConflicts,
        noBookingPeriodConflicts,
    ].filter((x) => x.length > 0).length;
    const conflictListMaxHeight = `${54 / totalConflictTypes}vh`;

    return (
        <Modal
            isOpen={isOpen}
            modalClassName={classes.conflictModal}
            onRequestClose={onCloseRequested}
        >
            <ModalHeader showCloseButton onCloseButtonClick={onCloseRequested}>
                <h5>Booking Conflicts</h5>
            </ModalHeader>
            <ModalBody>
                {noBookingPeriodConflicts.length > 0 && (
                    <TimeSlotConflictsList
                        label={`No Booking periods have been detected on the following ${pluralise(
                            'day',
                            noBookingPeriodConflicts.length
                        )}:`}
                        conflictTimeSlots={noBookingPeriodConflicts}
                        listMaxHeight={conflictListMaxHeight}
                    />
                )}
                {tooManyBookingsConflicts.length > 0 && (
                    <TimeSlotConflictsList
                        label={`Too many bookings have been detected on the following ${pluralise(
                            'day',
                            tooManyBookingsConflicts.length
                        )}:`}
                        conflictTimeSlots={tooManyBookingsConflicts}
                        listMaxHeight={conflictListMaxHeight}
                    />
                )}
                {outsideHourConflicts.length > 0 && (
                    <TimeSlotConflictsList
                        label={`Bookings scheduled for outside of store working hours have been detected on the following ${pluralise(
                            'day',
                            outsideHourConflicts.length
                        )}:`}
                        conflictTimeSlots={outsideHourConflicts}
                        listMaxHeight={conflictListMaxHeight}
                    />
                )}
                {resourceConflicts.length > 0 && (
                    <TimeSlotConflictsList
                        label={`Insufficient resources available to facilitate bookings on following ${pluralise(
                            'day',
                            resourceConflicts.length
                        )}:`}
                        conflictTimeSlots={resourceConflicts}
                        listMaxHeight={conflictListMaxHeight}
                    />
                )}
                {allowOverride && (
                    <InformationMessage messageType="error" hideIcon>
                        Please note that overriding conflicts will result in
                        these days being over resourced.
                    </InformationMessage>
                )}
            </ModalBody>
            <ModalFooter>
                {allowOverride && (
                    <Button styleType="danger" onClick={onOverrideRequested}>
                        Override Conflicts
                    </Button>
                )}
                <Button onClick={onCloseRequested} styleType="secondary">
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TimeSlotConflictsModal;
