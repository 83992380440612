import * as React from 'react';
import { useState } from 'react';
import NameLookupByReference from './NameLookupByReference';

interface NameLookupProps {
    onNameSelected(selectedCustomer: string | null): void;
    defaultSearchValue: string | null;
    isValid: boolean;
    lookupType: 'Customer' | 'Haulier' | 'SupplierCode';
    required?: boolean;
    placeholder?: string;
    title?: string;
    autoConvertWhitespace?: boolean;
    disableAutoTrim?: boolean;
    hideBottomPadding?: boolean;
}

const NameLookup: React.FC<NameLookupProps> = ({
    onNameSelected,
    defaultSearchValue,
    isValid,
    lookupType,
    required,
    hideBottomPadding,
    placeholder = `Enter ${lookupType} Name...`,
    title = `${lookupType} Name:`,
    autoConvertWhitespace = false,
    disableAutoTrim = false
}) => {
    const [lookupSearchValue, setLookupNameSearchValue] = useState(
        defaultSearchValue
    );

    return (
        <NameLookupByReference
            onNameSelected={onNameSelected}
            isValid={isValid}
            lookupType={lookupType}
            required={required}
            hideBottomPadding={hideBottomPadding}
            placeholder={placeholder}
            title={title}
            autoConvertWhitespace={autoConvertWhitespace}
            disableAutoTrim={disableAutoTrim}
            lookupSearchValue={lookupSearchValue}
            handleUpdateSearchValue={setLookupNameSearchValue}
        />
    );
};

export default NameLookup;
