import { Notification } from '.';
import { StockTransferOrder } from '../orders/StockTransferOrder';

export const StockTransferOrderAddedNotification: Notification<StockTransferOrder> = {
    message: 'StockTransferOrderAdded'
};

export const StockTransferOrderUpdatedNotification: Notification<StockTransferOrder> = {
    message: 'StockTransferOrderUpdated'
};

export const StockTransferOrdersUpdatedNotification: Notification<StockTransferOrder[]> = {
    message: 'StockTransferOrdersUpdated'
};
