import * as React from 'react';
import classNames from 'classnames';
import { WorkQueueTableModal } from '../../../../containers/workQueue/WorkQueueTableContainer';
import {
    ALLOW_IOD_DELETION,
    GENERATE_LOAD_PLANS,
} from '../../../../environment-variables';
import { Load } from '../../../../models/loads/Load';
import {
    deleteAssociatedIods,
    deleteNoIodStockTrailer,
    deleteThirdPartyLoad,
    getLoadExport,
} from '../../../../services/loadService';
import IconMenuLink from '../../../common/IconMenuLink';
import { useClasses } from './LoadOptions.styles';
import LoadingModal from '../../../common/LoadingModal';
import { DownloadExportType } from '../../../common/DownloadExportType';

interface LoadOptionsProps {
    loadingTableModals: WorkQueueTableModal[];
    load: Load;
    userCanModifyLoad: boolean;
    userCanDeleteIods: boolean;
    onModalRequested(type: WorkQueueTableModal): void;
}

const LoadOptions: React.FC<LoadOptionsProps> = ({
    loadingTableModals,
    load,
    userCanModifyLoad,
    userCanDeleteIods,
    onModalRequested,
}) => {
    const classes = useClasses();

    const loadExportType = load.isStockTrailer ? 'Transfer Sheet' : 'Load Plan';

    const [downloading, setDownloading] =
        React.useState<DownloadExportType | null>(null);
    const isDownloading = !!downloading;

    const downloadLoadExport = async () => {
        if (!userCanModifyLoad || !load || !GENERATE_LOAD_PLANS) return;
        setDownloading(loadExportType as DownloadExportType);
        try {
            await getLoadExport(load);
            setDownloading(null);
        } catch (error) {
            setDownloading(null);
            alert(
                `Something went wrong generating the ${
                    load.isTransfer ? 'transfer sheet' : 'load plan'
                }. Please try again, or contact your system administrator.`
            );
        }
    };

    const deleteIods = async () => {
        if (
            !userCanModifyLoad ||
            !userCanDeleteIods ||
            !load ||
            !ALLOW_IOD_DELETION ||
            !window.confirm(
                `Are you sure you want to delete ${
                    load.isNoIodStockTrailer || load.isThirdParty
                        ? `load ${load.loadName}`
                        : `all IODs associated with load ${load.loadName} (including piggybacks)`
                } ?`
            )
        ) {
            return;
        }

        try {
            load.isNoIodStockTrailer
                ? await deleteNoIodStockTrailer(load)
                : load.isThirdParty
                ? await deleteThirdPartyLoad(load)
                : await deleteAssociatedIods(load);
        } catch (error) {
            alert(
                'Something went wrong deleting the associated IOD(s). Please try again, or contact your system administrator.'
            );
        }
    };

    return (
        <>
            {userCanModifyLoad && (
                <>
                    {loadingTableModals.includes('addPiggybackOrders') && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="Add Piggyback Orders"
                            icon="piggyback"
                            iconClassName={classNames(
                                classes.icon,
                                classes.piggybackIcon
                            )}
                            onClick={() =>
                                onModalRequested('addPiggybackOrders')
                            }
                        />
                    )}
                    {loadingTableModals.includes('piggybackTransfer') && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="Piggyback Transfer"
                            icon="piggyback-transfer"
                            iconClassName={classNames(
                                classes.icon,
                                classes.piggybackIcon
                            )}
                            onClick={() =>
                                onModalRequested('piggybackTransfer')
                            }
                        />
                    )}
                    {loadingTableModals.includes('removePiggybackOrders') && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="Remove Piggybacks"
                            icon="piggyback-remove"
                            iconClassName={classNames(
                                classes.icon,
                                classes.piggybackIcon,
                                classes.piggybackRemoveIcon
                            )}
                            onClick={() =>
                                onModalRequested('removePiggybackOrders')
                            }
                        />
                    )}
                    {loadingTableModals.includes('removeOrders') && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="Remove Orders"
                            icon="unlink"
                            iconClassName={classNames(
                                classes.icon,
                                classes.removeOrdersIcon
                            )}
                            onClick={() => onModalRequested('removeOrders')}
                        />
                    )}
                    {loadingTableModals.includes('managePickLists') && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="View Pick Lists"
                            icon="file"
                            iconClassName={classes.icon}
                            onClick={() => onModalRequested('managePickLists')}
                        />
                    )}
                    {GENERATE_LOAD_PLANS &&
                        load?.isReleased &&
                        load?.isFirstStandardLoad && (
                            <IconMenuLink
                                buttonClassName={classes.iconButton}
                                label={`Download ${loadExportType}`}
                                icon="file"
                                iconClassName={classes.icon}
                                onClick={downloadLoadExport}
                            />
                        )}
                    {isDownloading && (
                        <LoadingModal
                            showModal
                            loadingText={`Downloading ${downloading}...`}
                        />
                    )}
                    {loadingTableModals.includes(
                        'updateNonIodStockTransferOrders'
                    ) && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="Update Orders"
                            icon="boxes"
                            iconClassName={classes.icon}
                            onClick={() =>
                                onModalRequested(
                                    'updateNonIodStockTransferOrders'
                                )
                            }
                        />
                    )}
                    {loadingTableModals.includes('cancelLoad') && (
                        <IconMenuLink
                            buttonClassName={classes.iconButton}
                            label="Cancel"
                            icon="cross"
                            iconClassName={classes.icon}
                            onClick={() => onModalRequested('cancelLoad')}
                        />
                    )}
                    {ALLOW_IOD_DELETION &&
                        !load.isInternal &&
                        userCanDeleteIods && (
                            <IconMenuLink
                                buttonClassName={classes.iconButton}
                                label={
                                    load.isNoIodStockTrailer ||
                                    load.isThirdParty
                                        ? 'Delete Load'
                                        : 'Delete IOD(s)'
                                }
                                icon="out-of-order"
                                iconClassName={classNames(
                                    classes.icon,
                                    classes.removeOrdersIcon
                                )}
                                onClick={deleteIods}
                            />
                        )}
                </>
            )}
        </>
    );
};

export default LoadOptions;
