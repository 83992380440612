import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    loginButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 10,
        width: '90%',
    },
});
