import { buildClassesHook } from '../../../../helpers/styles';
import { theme } from '../../../../theme';

export const useClasses = buildClassesHook({
    contactNumberCellButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    contactNumberCellButton: {
        padding: 0,
        height: '1.5rem',
        maxWidth: '2rem',
    },
    contactNumberCellIcon: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    contactNumberCellSMSIcon: {
        fontSize: '1.25rem',
    },
});
