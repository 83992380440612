import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    actionContainer: {
        backgroundColor: 'white',
        position: 'sticky',
        top: '0',
        zIndex: 99,
        padding: 0,
    },
    button: {
        margin: '0 0.25rem',
    },
    buttonContainer: {
        display: 'flex',
        margin: '1rem',
        '& $navSection:last-child': {
            borderRight: 'none',
        },
    },
    navSection: {
        borderRight: '1px solid #eee',
        paddingRight: '0.5rem',
        marginRight: '0.5rem',
    },
    chartsContainer: {
        padding: 0,
        marginTop: '0.5rem',
    },
});
