import { addHours, parseISO } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import RescheduleModal from '../../../components/bookings/modals/RescheduleModal';
import ConvertToDeliveryModal from '../../../components/bookings/modals/update/ConvertToDeliveryModal';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import { Booking } from '../../../models/bookings/Booking';
import { BookingType } from '../../../models/bookings/BookingType';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import RescheduleBooking from '../../../models/bookings/RescheduleBooking';
import { TrailerTypeResource } from '../../../models/bookings/StoreBookingResource';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import { apiGet, apiPost, useData } from '../../../services/api';
import { getTimeSlotsOverPeriodUrl } from '../../../services/timeSlotService';
import { formatToUtc } from '../../../services/timeStringService';

interface ConvertBookingToDelivery extends RescheduleBooking {
    resources: LoadingResource[];
}

interface ConvertToDeliveryModalContainerProps {
    booking: Booking;
    showModal: boolean;
    onConvertToDelivery(): void;
    onClose(): void;
}

const ConvertToDeliveryModalContainer: React.FC<ConvertToDeliveryModalContainerProps> = ({
    booking,
    showModal,
    onConvertToDelivery,
    onClose
}) => {
    const [updatedBooking, setUpdatedBooking] = useState<
        ConvertBookingToDelivery
    >({
        startDateTime: new Date(booking.startDate),
        endDateTime: new Date(booking.endDate),
        resources: booking.resources,
        isTimeSlotOverridden: booking.isTimeSlotOverridden
    });
    const [loading, setLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);

    const [defaultDeliveryResources] = useData<TrailerTypeResource>(
        `Store/${booking.store}/BookingType/${BookingType.Delivery}/TrailerType/${booking.trailerType}/Resources`
    );

    const handleConvertToDeliveryConfirmed = async (
        updatedDeliveryBooking: ConvertBookingToDelivery
    ) => {
        try {
            await apiPost(
                `Booking/${booking.id}/ConvertToDelivery`,
                updatedDeliveryBooking
            );
            onConvertToDelivery();
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.warn(e);
            setShowErrorModal(true);
        }
    };

    const handleConvertToDeliveryRequested = async () => {
        setLoading(true);

        const updatedDuration = Math.max(
            booking.hourDuration,
            defaultDeliveryResources!.hourDuration
        );
        const updatedResources = defaultDeliveryResources!.resources.concat(
            booking.resources.filter(
                r => !defaultDeliveryResources!.resources.includes(r)
            )
        );
        const updatedDeliveryBooking: ConvertBookingToDelivery = {
            startDateTime: new Date(booking.startDate),
            endDateTime: addHours(parseISO(booking.startDate), updatedDuration),
            resources: updatedResources,
            isTimeSlotOverridden: false
        };

        const currentTimeSlots: TimeSlot[] = await apiGet(
            getTimeSlotsOverPeriodUrl({
                bookingType: BookingType.Delivery,
                checkResourceAvailability: true,
                duration: 1,
                excludedBookingIds: [booking.id],
                utcStart: formatToUtc(updatedDeliveryBooking.startDateTime),
                utcEnd: formatToUtc(updatedDeliveryBooking.endDateTime),
                resources: updatedDeliveryBooking.resources,
                store: booking.store,
                trailerType: booking.trailerType
            })
        );

        const requiresRescheduling = currentTimeSlots.some(
            ts =>
                !ts.isAvailable &&
                ts.unavailableReasons.some(r => r !== 'In Past')
        );

        if (requiresRescheduling) {
            setUpdatedBooking(updatedDeliveryBooking);
            setLoading(false);
            setShowRescheduleModal(true);
        } else {
            handleConvertToDeliveryConfirmed(updatedDeliveryBooking);
        }
    };

    const handleTimeSlotSelected = (timeSlot: TimeSlot) => {
        setLoading(true);
        setShowRescheduleModal(false);
        const updatedDeliveryBooking: ConvertBookingToDelivery = {
            ...updatedBooking,
            startDateTime: timeSlot.start,
            endDateTime: timeSlot.end,
            isTimeSlotOverridden: !timeSlot.isAvailable
        };
        handleConvertToDeliveryConfirmed(updatedDeliveryBooking);
    };

    const modalHeader = `Convert Booking ${booking.reference} to Delivery`;
    const showLoadingModal = showModal && loading;
    const showConvertToDeliveryModal =
        showModal &&
        !showLoadingModal &&
        !showRescheduleModal &&
        !showErrorModal;
    const disableConvertButton = !defaultDeliveryResources;

    return (
        <>
            <ConvertToDeliveryModal
                booking={booking}
                showModal={showConvertToDeliveryModal}
                disableConvertButton={disableConvertButton}
                onConvertToDelivery={handleConvertToDeliveryRequested}
                onClose={onClose}
            />
            <LoadingModal header={modalHeader} showModal={showLoadingModal} />
            <ErrorModal
                showModal={showModal && showErrorModal}
                header={modalHeader}
                errorText="There was an error converting the booking to a delivery"
                onClose={onClose}
            />
            <RescheduleModal
                startDate={updatedBooking.startDateTime}
                endDate={updatedBooking.endDateTime}
                bookingType={BookingType.Delivery}
                excludedBookingIds={[booking.id]}
                resources={updatedBooking.resources}
                store={booking.store}
                trailerType={booking.trailerType}
                showModal={showModal && showRescheduleModal}
                onTimeSlotSelected={handleTimeSlotSelected}
                onClose={() => setShowRescheduleModal(false)}
            />
        </>
    );
};

export default ConvertToDeliveryModalContainer;
