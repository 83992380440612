import classNames from 'classnames';
import * as React from 'react';

import { compareDatesAsc } from '../../../helpers/dates';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';
import Table from '../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../lib/bootstrap-ui/Table/Table';
import { Load } from '../../../models/loads/Load';
import {
    getLoadTrailerTypeName,
    getPiggybackedPalletCountForLoad,
} from '../../../services/loadService';
import NoDataFoundTableRow from '../../common/table/NoDataFoundTableRow';
import LoadStatus from '../../load/table/view/LoadStatus';
import LoadTimeRemaining from '../../time/LoadTimeRemaining';
import { useClasses } from './PiggybackLoadSelectionTable.styles';

interface PiggybackLoadSelectionTableProps {
    loading: boolean;
    loads: Load[];
    selectedLoad: Load | null;
    onLoadSelected(load: Load): void;
}

const tableHeaders: (string | HeaderDetails)[] = [
    { title: 'selected', hide: true, width: '1rem' },
    'PL',
    'IOD',
    'Status',
    'Remaining',
    'Pallets',
    'Trailer',
    'Type',
];

const PiggybackLoadSelectionTable: React.FC<
    PiggybackLoadSelectionTableProps
> = ({ loading, loads, selectedLoad, onLoadSelected }) => {
    const classes = useClasses();

    return (
        <Table
            className={classes.piggybackTable}
            headers={tableHeaders}
            loading={loading}
            placeholderRows={6}
            fixedHeader
        >
            {loads.length === 0 ? (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No Loads available for this Order to Piggyback"
                    iconType="no-loads"
                    columnSpan={tableHeaders.length}
                />
            ) : (
                loads
                    .sort((lhs, rhs) =>
                        compareDatesAsc(lhs.loadByTime, rhs.loadByTime)
                    )
                    .map((load) => {
                        const isSelected: boolean = selectedLoad
                            ? selectedLoad.id === load.id
                            : false;
                        const piggybackedPallets: number =
                            getPiggybackedPalletCountForLoad(load);

                        return (
                            <tr
                                key={load.id}
                                className={classNames({
                                    [classes.selectedLoadRow]: isSelected,
                                })}
                                onClick={() => onLoadSelected(load)}
                            >
                                <td>
                                    <Checkbox
                                        checked={isSelected}
                                        value={load.id}
                                    />
                                </td>
                                <td>{load.loadName}</td>
                                <td>{load.associatedIodNumber}</td>
                                <td>
                                    <LoadStatus
                                        load={load}
                                        hideIcon
                                        disableLinks
                                    />
                                </td>
                                <td>
                                    <LoadTimeRemaining
                                        loadByTime={load.loadByTime}
                                        completedTime={load.completedAt}
                                        useColors
                                    />
                                </td>
                                <td>
                                    {piggybackedPallets !== 0
                                        ? `${
                                              load.totalPalletSpaces -
                                              piggybackedPallets
                                          } (+${piggybackedPallets})`
                                        : load.totalPalletSpaces}
                                </td>
                                <td>{getLoadTrailerTypeName(load)}</td>
                                <td>{load.loadType}</td>
                            </tr>
                        );
                    })
            )}
        </Table>
    );
};

export default PiggybackLoadSelectionTable;
