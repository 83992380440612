import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

import ErrorModal from '../../components/common/ErrorModal';
import HeaderContainer from '../../components/common/HeaderContainer';
import Pagination from '../../components/common/Pagination';
import { StoreSelectorOption } from '../../components/common/StoreSelectorPills';
import BookingsHistoryFilterModal from '../../components/historicalBookings/view/BookingsHistoryFilterModal';
import BookingsHistoryTable from '../../components/historicalBookings/view/BookingsHistoryTable';
import { LookupContext } from '../../contexts/LookupDataProvider';
import { arraysContainSameElements } from '../../helpers/arrayFunctions';
import { UserRole } from '../../helpers/userRole';
import usePersistedState from '../../hooks/usePersistedState';
import { BookingStatus } from '../../models/bookings/BookingStatus';
import { HistoricalBooking } from '../../models/bookings/HistoricalBooking';
import HistoricalBookingFilter from '../../models/bookings/HistoricalBookingFilter';
import FilterResults from '../../models/common/FilterResults';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { apiGet } from '../../services/api';
import { useCurrentUser } from '../../services/authentication';
import { BOOKING_TABLE_PAGE_SIZE } from '../../services/bookingService';
import { formatToUtc } from '../../services/timeStringService';
import {
    userInRole,
    userIsAdmin,
    userToAllStores,
} from '../../services/userService';
import BookingTableActionContainer, {
    Action,
} from '../bookings/bookingTable/BookingTableActionContainer';
import { useClasses } from './BookingsHistoryContainer.styles';

export type ModalType = 'filter' | 'error';

const hasActiveFilters = (
    activeFilter: HistoricalBookingFilter,
    defaultFilter: HistoricalBookingFilter
): boolean =>
    !!activeFilter.filter ||
    !!activeFilter.startDate ||
    !!activeFilter.cancelledOrCompletedStartDate ||
    !arraysContainSameElements(activeFilter.statuses, defaultFilter.statuses) ||
    (!!activeFilter.bookingTypes &&
        !arraysContainSameElements(
            activeFilter.bookingTypes,
            defaultFilter.bookingTypes
        )) ||
    (!!activeFilter.resources &&
        !arraysContainSameElements(
            activeFilter.resources,
            defaultFilter.resources
        )) ||
    (!!activeFilter.cancellationReasons &&
        !arraysContainSameElements(
            activeFilter.cancellationReasons,
            defaultFilter.cancellationReasons
        ));

const getBookingActions = (
    activeFilter: HistoricalBookingFilter,
    showRecurringBookingNav: boolean,
    storeOptions: StoreNumber[]
) => {
    let actions: Action[] = [
        { title: 'filters', disabled: !activeFilter.bookingTypes },
        { title: 'bookingNav' },
    ];

    if (showRecurringBookingNav) {
        actions = [...actions, { title: 'recurringBookingNav' }];
    }

    if (storeOptions.length > 1) {
        actions = [...actions, { title: 'storeSelector' }];
    }

    return actions;
};

const BookingsHistoryContainer: React.FC = () => {
    const { bookingTypes, bookingCancellationReasons } =
        useContext(LookupContext);

    const classes = useClasses();

    const user = useCurrentUser();
    const userIsGatehouse = userInRole(user)(UserRole.Gatehouse);

    const rawStoreOptions: StoreNumber[] = !user ? [1] : userToAllStores(user);
    const storeOptions: StoreSelectorOption[] = rawStoreOptions;
    if (userIsAdmin(user) || userIsGatehouse) {
        storeOptions.push('All');
    }

    const showRecurringBookingNav = !userIsGatehouse;

    const [selectedStoreOption, setSelectedStoreOption] =
        usePersistedState<StoreSelectorOption>(
            storeOptions[0],
            'booking-store'
        );

    const [modalToDisplay, setModalToDisplay] = useState<ModalType | null>(
        null
    );

    const defaultFilter: HistoricalBookingFilter = {
        bookingTypes: bookingTypes.length > 0 ? bookingTypes : null,
        filter: null,
        resources: null,
        startDate: null,
        endDate: null,
        statuses: [BookingStatus.Complete, BookingStatus.Cancelled],
        cancelledOrCompletedStartDate: null,
        cancelledOrCompletedEndDate: null,
        cancellationReasons:
            bookingCancellationReasons.length > 0
                ? bookingCancellationReasons
                : null,
    };

    const [filter, setFilter] =
        useState<HistoricalBookingFilter>(defaultFilter);

    const [offset, setOffset] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [filteredhistoricalBookings, setFilteredHistoricalBookings] =
        useState<FilterResults<HistoricalBooking> | null>(null);

    const historicalBookings = filteredhistoricalBookings?.resultsToShow;
    const totalResults = filteredhistoricalBookings?.totalResultCount || 0;

    const currentPage =
        (offset + BOOKING_TABLE_PAGE_SIZE) / BOOKING_TABLE_PAGE_SIZE;
    const totalPages = Math.ceil(totalResults / BOOKING_TABLE_PAGE_SIZE);

    const url =
        selectedStoreOption === 'All'
            ? `Bookings/Completed`
            : `Store/${selectedStoreOption}/Bookings/Completed`;

    const resetPageNumber = () => {
        setOffset(0);
    };

    useEffect(() => {
        if (bookingTypes.length > 0) {
            setFilter((prev) => ({
                ...prev,
                bookingTypes,
            }));
        }
    }, [bookingTypes]);

    useEffect(resetPageNumber, [filter]);

    const lastActiveRequestController = useRef<AbortController | null>();

    useEffect(() => {
        const fetchHistoricalBookings = async () => {
            setLoading(true);

            if (lastActiveRequestController.current) {
                lastActiveRequestController.current.abort();
            }
            const controller = new AbortController();
            const signal = controller.signal;
            lastActiveRequestController.current = controller;

            const queryParams: Record<string, any> = {
                values: BOOKING_TABLE_PAGE_SIZE,
                filter: filter.filter,
                offset: offset,
                bookingTypes: filter.bookingTypes,
                resources: filter.resources,
                startDate: filter.startDate
                    ? formatToUtc(filter.startDate)
                    : null,
                endDate: filter.endDate ? formatToUtc(filter.endDate) : null,
                statuses: filter.statuses,
                cancellationReasons: filter.cancellationReasons,
                cancelledOrCompletedStartDate:
                    filter.cancelledOrCompletedStartDate
                        ? formatToUtc(filter.cancelledOrCompletedStartDate)
                        : null,
                cancelledOrCompletedEndDate: filter.cancelledOrCompletedEndDate
                    ? formatToUtc(filter.cancelledOrCompletedEndDate)
                    : null,
            };
            const results: FilterResults<HistoricalBooking> = await apiGet(
                url,
                queryParams,
                { signal }
            ).catch((err) => {
                // Avoid showing an error message if the fetch was aborted
                if (err.name !== 'AbortError') {
                    setModalToDisplay('error');
                    setLoading(false);
                }
            });
            if (!signal.aborted) {
                setFilteredHistoricalBookings(results);
                setLoading(false);
            }
        };

        fetchHistoricalBookings();
    }, [url, filter, offset]);

    const resetModalToDisplay = () => {
        setModalToDisplay(null);
    };

    const handleStoreChange = (storeNumber: StoreNumber) => {
        resetPageNumber();
        setSelectedStoreOption(storeNumber);
    };

    const handleFilterReset = () => {
        resetPageNumber();
        setFilter(defaultFilter);
    };

    const handleFilterChange = (newFilter: HistoricalBookingFilter) => {
        resetPageNumber();
        setFilter(newFilter);
        resetModalToDisplay();
    };

    return (
        <div className={classes.bookingsHistoryContainer}>
            <HeaderContainer headerText="Bookings History">
                <BookingTableActionContainer
                    actionsToDisplay={getBookingActions(
                        filter,
                        showRecurringBookingNav,
                        rawStoreOptions
                    )}
                    activeFilters={hasActiveFilters(filter, defaultFilter)}
                    storeOptions={storeOptions}
                    storeOption={selectedStoreOption}
                    onFilterClicked={() => setModalToDisplay('filter')}
                    onFilterReset={handleFilterReset}
                    onStoreOptionChange={handleStoreChange}
                />
            </HeaderContainer>
            <div className={classes.bookingsHistoryTableContainer}>
                <BookingsHistoryTable
                    historicalBookings={historicalBookings || []}
                    className={classes.bookingsHistoryTable}
                    loading={loading}
                    showStore={selectedStoreOption === 'All'}
                />
            </div>
            {historicalBookings && totalResults > BOOKING_TABLE_PAGE_SIZE && (
                <div className={classes.pageSelectionContainer}>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageLinkClick={(pageNumber: number) =>
                            setOffset(
                                (pageNumber - 1) * BOOKING_TABLE_PAGE_SIZE
                            )
                        }
                    />
                </div>
            )}
            {modalToDisplay === 'filter' && (
                <BookingsHistoryFilterModal
                    filter={filter || ''}
                    isOpen={modalToDisplay === 'filter'}
                    onFilterConfirm={handleFilterChange}
                    onRequestClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'error' && (
                <ErrorModal
                    showModal={modalToDisplay === 'error'}
                    errorText="There was an error retrieving the list of historical bookings, please try refreshing the page. If this error continues, please contact IT."
                    onClose={resetModalToDisplay}
                />
            )}
        </div>
    );
};

export default BookingsHistoryContainer;
