import { KeyValuePair } from '../models/utils/KeyValuePair';

export const arraysContainSameElements = (
    lhs: any[] | null,
    rhs: any[] | null
): boolean => {
    return (
        (lhs || []).every(element => rhs?.includes(element)) &&
        (rhs || []).every(element => lhs?.includes(element))
    );
};

export const groupBy = <T, K extends keyof T>(
    array: T[],
    key: K,
    keyComparator?: (lhs: T[K], rhs: T[K]) => boolean
): KeyValuePair<T[K], T[]>[] =>
    array.reduce((grouped: KeyValuePair<T[K], T[]>[], current: T) => {
        const currentKey: T[K] = current[key];
        const existingKvp:
            | KeyValuePair<T[K], T[]>
            | undefined = grouped.find(kvp =>
            keyComparator
                ? keyComparator(kvp.key, currentKey)
                : kvp.key === currentKey
        );

        if (!!existingKvp) {
            existingKvp.value.push(current);
        } else {
            const newKvp: KeyValuePair<T[K], T[]> = {
                key: currentKey,
                value: [current]
            };
            grouped.push(newKvp);
        }

        return grouped;
    }, []);

export const removeDuplicates = <T>(
    array: T[],
    comparator?: (lhs: T, rhs: T) => boolean
) =>
    array.reduce((withoutDuplicates: T[], item: T) => {
        const duplicate = !!withoutDuplicates.find(x =>
            comparator ? comparator(item, x) : item === x
        );

        if (!duplicate) {
            withoutDuplicates.push(item);
        }

        return withoutDuplicates;
    }, []);

export function getPermutations<T>(arrayToPermutate: T[], size: number): T[][] {
    if (--size < 0) {
        return [[]];
    }

    const array = [...arrayToPermutate];

    const permutations: T[][] = [];
    array.forEach((value, index, array) => {
        array = array.slice();
        array.splice(index, 1);
        getPermutations(array, size).forEach(permutation => {
            permutation.unshift(value);
            permutations.push(permutation);
        });
    });

    return permutations;
}

export default function getCombinations<T>(
    collection: T[],
    size: number
): T[][] {
    const array = [...collection];

    if (array.length < 1) {
        return [];
    }

    const recur = (array: T[], n: number): T[][] => {
        if (--n < 0) {
            return [[]];
        }

        const combinations: T[][] = [];
        array = array.slice();
        while (array.length - n) {
            const value = array.shift();
            if (value) {
                recur(array, n).forEach(combination => {
                    combination.unshift(value);
                    combinations.push(combination);
                });
            }
        }

        return combinations;
    };

    return recur(array, size);
}
