import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    modalHeader: {
        padding: '0.25rem 1rem',
    },
    closeButton: {
        '&:focus': {
            outline: 'none',
        },
    },
});
