import * as React from 'react';
import SelectDropdown from '../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import DropdownOption from '../../lib/bootstrap-ui/Dropdown/DropdownOption';

interface BayPickerProps {
    bayDropDownOptions: DropdownOption[];
    disabled: boolean;
    loadingBays: boolean;
    selectedOption: string | null;
    onBayChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    hideBottomPadding?: boolean;
}

const BayPicker: React.FC<Readonly<BayPickerProps>> = ({
    bayDropDownOptions,
    disabled,
    selectedOption,
    loadingBays,
    onBayChange,
    hideBottomPadding
}) => (
    <SelectDropdown
        id={'BayDropdown'}
        label={'Bay Number:'}
        dropdownOptions={bayDropDownOptions}
        defaultText={'Select...'}
        onChange={onBayChange}
        disabled={disabled}
        selectedOption={selectedOption}
        hideBottomPadding={hideBottomPadding}
        loadingOptions={loadingBays}
        noOptionsMessage="No Suitable Bays Available"
    />
);

export default BayPicker;
