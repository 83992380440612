import * as React from 'react';

import Alert from '../../../lib/bootstrap-ui/Alert';
import { Load } from '../../../models/loads/Load';
import { LoadInvalidityReason } from '../../../models/loads/LoadInvalidityReason';
import { useClasses } from './TrailerTooSmallMessage.styles';

interface TrailerTooSmallMessageProps {
    load: Load;
}

const TrailerTooSmallMessage: React.FC<TrailerTooSmallMessageProps> = ({
    load,
}) => {
    const classes = useClasses();

    return load.invalidityReasons.length === 0 ? (
        <></>
    ) : (
        <Alert type="danger" className={classes.alert}>
            Load is invalid for the following reasons:
            <ul className={classes.invalidityReasonsList}>
                {load.invalidityReasons.map((reason, i) => (
                    <li key={i}>{reason}</li>
                ))}
            </ul>
            {load.invalidityReasons.includes(
                LoadInvalidityReason.HasInvalidPiggybackedOrders ||
                    LoadInvalidityReason.TooManyPiggybacks
            ) && <p>Please remove piggybacked orders from the load.</p>}
            {load.invalidityReasons.some(
                (i) =>
                    i !== LoadInvalidityReason.HasInvalidPiggybackedOrders &&
                    i !== LoadInvalidityReason.TooManyPiggybacks
            ) && <p>Please update the IOD in Chess to be valid.</p>}
        </Alert>
    );
};

export default TrailerTooSmallMessage;
