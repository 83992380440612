import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import BlockUnsavedChangesNavigation from '../../components/common/BlockUnsavedChangesNavigation';
import ErrorModal from '../../components/common/ErrorModal';
import SavingModal from '../../components/common/SavingModal';
import Button from '../../lib/bootstrap-ui/Button';
import { useClasses } from './RequireSaveContainer.styles';

type ModalType = 'saving' | 'error';

interface RequireSaveContainerProps<T> {
    existingValues: T[];
    children(props: {
        onChange(value: T): void;
        updatedValues: T[];
        outstandingChanges: boolean;
    });
    comparator(lhs: T, rhs: T): boolean;
    hasValueUpdated(value: T): boolean;
    Save(updatedValues: T[]): Promise<any>;
    valueValidator?(value: T): boolean;
}

function RequireSaveContainer<T>({
    existingValues,
    children,
    comparator,
    hasValueUpdated,
    Save,
    valueValidator,
}: RequireSaveContainerProps<T>): React.ReactElement {
    const classes = useClasses();

    const [saved, setSaved] = useState(false);
    const [modalToDisplay, setModalToDisplay] = useState<ModalType | null>(
        null
    );
    const [updatedValues, setUpdatedValues] = useState<T[] | null>(null);

    useEffect(() => {
        setUpdatedValues(existingValues);
    }, [existingValues]);

    const valuesToUpdate: T[] | null = useMemo(() => {
        if (!updatedValues) return null;

        const modifiedValues = updatedValues.filter((v) => hasValueUpdated(v));
        return modifiedValues;
    }, [updatedValues, hasValueUpdated]);

    const outstandingChanges = !!valuesToUpdate && valuesToUpdate.length > 0;

    const updateValue = (value: T) => {
        const modifiedValues =
            updatedValues?.map((v) => (comparator(v, value) ? value : v)) || [];
        setUpdatedValues(modifiedValues);
    };

    const onSave = () => {
        setModalToDisplay('saving');

        Save(valuesToUpdate || [])
            .then(() => {
                setSaved(true);
                setTimeout(() => {
                    setModalToDisplay(null);
                    setSaved(false);
                }, 1000);
            })
            .catch(() => {
                setModalToDisplay('error');
            });
    };

    const onModalClose = () => {
        setModalToDisplay(null);
    };

    const enableSaveButton: boolean =
        outstandingChanges &&
        (!valueValidator ||
            (!!valueValidator &&
                (updatedValues?.every((value) => valueValidator(value)) ||
                    false)));

    return (
        <>
            {children({
                onChange: updateValue,
                updatedValues: updatedValues || [],
                outstandingChanges,
            })}
            <div className={classes.footerRow}>
                <Button
                    className={classes.saveButton}
                    disabled={!enableSaveButton}
                    onClick={onSave}
                >
                    Save Changes
                </Button>
            </div>

            <BlockUnsavedChangesNavigation
                blockNavigation={outstandingChanges}
                onClose={onModalClose}
            />
            {modalToDisplay === 'saving' && (
                <SavingModal
                    isOpen={modalToDisplay === 'saving'}
                    saved={saved}
                />
            )}
            {modalToDisplay === 'error' && (
                <ErrorModal
                    showModal={modalToDisplay === 'error'}
                    errorText="There was an error saving your changes, please try again. If this error continues, please contact IT."
                    onClose={onModalClose}
                />
            )}
        </>
    );
}

export default RequireSaveContainer;
