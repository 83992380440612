import * as React from 'react';
import Table from '../../../../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../../../../lib/bootstrap-ui/Table/Table';
import { LoadingMilestoneThreshold } from '../../../../../../models/loads/LoadingMilestoneThreshold';
import { Order } from '../../../../../../models/orders/Order';
import { Pallet } from '../../../../../../models/pallets/Pallet';
import { useClasses } from './LoadingProgressDetailTable.styles';
import LoadingProgressOrderRow from './LoadingProgressOrderRow';

interface LoadingProgressDetailTableProps {
    loading: boolean;
    orders: Order[];
    tunnelTransferOrders: Order[];
    stockTransferOrders: Order[];
    pallets: Pallet[];
    milestoneThresholds: LoadingMilestoneThreshold[];
    loadCompletedTime?: Date;
}

const loadingProgressTableHeaders: (string | HeaderDetails)[] = [
    'Order',
    'Pallet',
    'Product',
    'Location',
    {
        title: 'Heavy',
        hide: true,
        width: '2rem',
    },
    {
        title: 'Allocated',
        tooltip: 'Rack',
        width: '6rem',
    },
    {
        title: 'Picked',
        tooltip: 'Chamber floor or moving to dock',
        width: '6rem',
    },
    {
        title: 'Problem',
        tooltip: 'Problem',
        width: '6rem',
    },
];

const LoadingProgressDetailTable: React.FC<LoadingProgressDetailTableProps> = ({
    loading,
    orders,
    tunnelTransferOrders,
    stockTransferOrders,
    pallets,
    milestoneThresholds,
    loadCompletedTime,
}) => {
    const classes = useClasses();

    return (
        <Table
            className={classes.loadingProgressDetailTable}
            headers={loadingProgressTableHeaders}
            hoverable
        >
            {!loading &&
                orders.map((o) => (
                    <LoadingProgressOrderRow
                        order={o}
                        orderPallets={
                            pallets?.filter((p) => p.orderId === o.id) || []
                        }
                        milestoneThresholds={milestoneThresholds ?? []}
                        loadCompletedTime={loadCompletedTime}
                        key={o.id}
                    />
                ))}
            {!loading &&
                tunnelTransferOrders.map((o) => (
                    <LoadingProgressOrderRow
                        order={o}
                        orderPallets={
                            pallets?.filter((p) => p.orderId === o.id) || []
                        }
                        milestoneThresholds={milestoneThresholds ?? []}
                        loadCompletedTime={loadCompletedTime}
                        key={o.id}
                        isTunnelTransferOrder
                    />
                ))}
            {!loading &&
                stockTransferOrders.map((o) => (
                    <LoadingProgressOrderRow
                        order={o}
                        orderPallets={
                            pallets?.filter((p) => p.orderId === o.id) || []
                        }
                        milestoneThresholds={milestoneThresholds ?? []}
                        loadCompletedTime={loadCompletedTime}
                        key={o.id}
                        isStockTransferOrder
                    />
                ))}
        </Table>
    );
};

export default LoadingProgressDetailTable;
