import * as React from 'react';

import { Pallet, PalletStatus } from '../../models/pallets/Pallet';
import ProgressSummaryTotalCell from './ProgressSummaryTotalCell';
import { useClasses } from './ProgressSummaryTotalRow.styles';

interface ProgressSummaryTotalRowProps {
    pallets: Pallet[];
}

const filterPalletsByStatus = (
    pallets: Pallet[],
    status: PalletStatus
): Pallet[] => pallets.filter((p) => p.status === status);

const LoadingSummaryTotalRow: React.FC<ProgressSummaryTotalRowProps> = ({
    pallets,
}) => {
    const classes = useClasses();

    return (
        <tr>
            <td colSpan={9} className={classes.progressSummaryTotalRow}>
                Total
            </td>
            <ProgressSummaryTotalCell
                pallets={filterPalletsByStatus(pallets, 'Allocated')}
            />
            <ProgressSummaryTotalCell
                pallets={filterPalletsByStatus(pallets, 'Picked')}
            />
            <ProgressSummaryTotalCell
                pallets={filterPalletsByStatus(pallets, 'Problem')}
            />
        </tr>
    );
};

export default LoadingSummaryTotalRow;
