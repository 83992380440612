import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    updateResourcesModal: {
        width: '555px',
    },
    updateResourcesModalBody: {
        padding: '0.25rem 1rem',
    },
    resourceInputSection: {
        margin: '10px',
    },
    resourceOverride: {
        margin: '0 inherit',
    },
});
