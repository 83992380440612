import * as React from 'react';
import { useState, useMemo } from 'react';
import Modal, {
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../../lib/bootstrap-ui/Modal';
import Button from '../../../lib/bootstrap-ui/Button';
import BookingOptions from '../../../models/bookings/BookingOptions';
import { BookingCancellationReason } from '../../../models/bookings/BookingCancellationReason';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import DropdownOption from '../../../lib/bootstrap-ui/Dropdown/DropdownOption';

interface CancelBookingModalProps {
    options: BookingOptions;
    showModal: boolean;
    bookingCancellationReasons: BookingCancellationReason[];
    onCancelBooking(cancellationReason: string): void;
    onClose(): void;
}

const CancelBookingModal: React.FC<CancelBookingModalProps> = ({
    options,
    showModal,
    bookingCancellationReasons,
    onCancelBooking,
    onClose
}) => {
    const { booking } = options;
    const [bookingCancellationReason, setBookingCancellationReason] = useState<
        string | null
    >(null);

    const cancelBooking = () => {
        if (bookingCancellationReason) {
            onCancelBooking(bookingCancellationReason);
        }
    };

    const cancellationReasonDropDownOptions = useMemo(() => {
        return bookingCancellationReasons.map(
            (cr): DropdownOption => {
                return {
                    label: cr,
                    value: cr
                };
            }
        );
    }, [bookingCancellationReasons]);

    const handleCancellationReasonChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setBookingCancellationReason(event.target.value);
    };

    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Cancel Booking {booking.reference}</h5>
            </ModalHeader>
            <ModalBody>
                <p>Please choose a reason for this cancellation:</p>
                <SelectDropdown
                    dropdownOptions={cancellationReasonDropDownOptions}
                    defaultText={'Select...'}
                    onChange={handleCancellationReasonChange}
                    selectedOption={bookingCancellationReason}
                    hideBottomPadding={true}
                />
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="danger"
                    disabled={!bookingCancellationReason}
                    onClick={cancelBooking}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CancelBookingModal;
