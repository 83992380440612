import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StockTrailerDetailsContainer from '../../containers/load/stockTrailers/view/StockTrailerDetailsContainer';
import Page from '../common/Page';

const StockTrailerDetails: React.FC = () => {
    const { loadId } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <StockTrailerDetailsContainer
                loadId={loadId!}
                onBackButtonClick={() => navigate(-1)}
            />
        </Page>
    );
};

export default StockTrailerDetails;
