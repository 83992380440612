import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    DestinationColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '20Rem',
    },
    expandIcon: {
        transition: 'transform 0.25s linear',
        fontSize: '0.9rem',
        '& path': {
            transition: 'fill 1s ease-in',
            fill: theme.colors.darkGrey,
        },
    },
    expandIconExpand: {
        transform: 'rotate(0deg)',
    },
    expandIconCollapse: {
        transform: 'rotate(180deg)',
        '& path': {
            fill: theme.colors.primary,
        },
    },
    orderRow: {
        padding: '0px 1rem',
        transition: 'all 0.5s ease-out',
    },
    orderRowCollapsed: {
        maxHeight: 0,
        overflow: 'hidden',
        opacity: 0,
    },
    orderRowExpanded: {
        maxHeight: '100rem',
        overflow: 'auto',
        padding: '0px 1rem',
        animation: '$orderRowExpand 1s',
    },
    orderTableContent: {
        backgroundColor: '#f8f8f8',
        padding: '0 !important',
    },
    orderTableContentExpanded: {
        padding: '0 0 0.5rem 0 !important',
    },
    '@keyframes orderRowExpand': {
        '0%': {
            overflow: 'hidden',
        },
        '95%': {
            overflow: 'auto',
        },
    },
}));
