import * as React from 'react';
import AutoCompleteDropdown from '../../lib/bootstrap-ui/Dropdown/AutoCompleteDropdown';
import { Trailer } from '../../models/trailers/Trailer';

interface TrailerPickerProps {
    disabled?: boolean;
    loading: boolean;
    searchValue: string;
    trailerOptions: Trailer[];

    handleAutocompleteValueChange(
        e: React.ChangeEvent<HTMLInputElement>,
        value: string
    ): void;
    handleAutocompleteSelected(value: string, item: any): void;
    getItemText(item: any): string | JSX.Element;
    getItemValue(item: any): string | number;
    shouldItemRender(item: any, value: string): boolean;
}

const TrailerPicker: React.FC<Readonly<TrailerPickerProps>> = ({
    disabled,
    loading,
    searchValue,
    trailerOptions,
    handleAutocompleteValueChange,
    handleAutocompleteSelected,
    getItemText,
    getItemValue,
    shouldItemRender
}) => (
    <AutoCompleteDropdown
        items={trailerOptions}
        value={searchValue}
        onSearchValueChange={handleAutocompleteValueChange}
        onSelect={handleAutocompleteSelected}
        getItemText={getItemText}
        getItemValue={getItemValue}
        shouldItemRender={shouldItemRender}
        loading={loading}
        disabled={disabled}
    />
);

export default TrailerPicker;
