import classNames from 'classnames';
import { compareAsc } from 'date-fns';
import * as React from 'react';

import { Checkbox } from '../../../lib/bootstrap-ui/Forms';
import Table from '../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../lib/bootstrap-ui/Table/Table';
import { OrderWithLoadInfo } from '../../../models/orders/OrderWithLoadInfo';
import NoDataFoundTableRow from '../../common/table/NoDataFoundTableRow';
import { useClasses } from './OrderSelectionTable.styles';

interface OrderSelectionTableProps {
    loading: boolean;
    noOrdersAvailableMessage?: string;
    orders: OrderWithLoadInfo[];
    selectedOrderIds: string[];
    onOrderSelected(order: OrderWithLoadInfo, add: boolean): void;
}

const tableHeaders: (string | HeaderDetails)[] = [
    { title: 'selected', hide: true, width: '1rem' },
    'IOD',
    'Job Number',
    'Customer Order',
    'Customer',
    'Pallets',
    'PL Ref.',
];

const OrderSelectionTable: React.FC<OrderSelectionTableProps> = ({
    loading,
    noOrdersAvailableMessage = 'No Orders available',
    orders,
    selectedOrderIds,
    onOrderSelected,
}) => {
    const classes = useClasses();

    return (
        <Table
            className={classes.selectionTable}
            headers={tableHeaders}
            loading={loading}
            placeholderRows={6}
            fixedHeader
        >
            {orders.length === 0 ? (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText={noOrdersAvailableMessage}
                    iconType="no-loads"
                    columnSpan={tableHeaders.length}
                />
            ) : (
                orders
                    .sort((lhs, rhs) =>
                        compareAsc(lhs.loadByTime, rhs.loadByTime)
                    )
                    .map((orderWithLoadInfo) => {
                        const isSelected: boolean = selectedOrderIds.includes(
                            orderWithLoadInfo.order.id
                        );

                        return (
                            <tr
                                key={orderWithLoadInfo.order.id}
                                className={classNames({
                                    [classes.selectedOrderRow]:
                                        selectedOrderIds.includes(
                                            orderWithLoadInfo.order.id
                                        ),
                                })}
                                onClick={() =>
                                    onOrderSelected(
                                        orderWithLoadInfo,
                                        !isSelected
                                    )
                                }
                            >
                                <td>
                                    <Checkbox
                                        checked={isSelected}
                                        value={orderWithLoadInfo.order.id}
                                    />
                                </td>
                                <td>{orderWithLoadInfo.order.iodNumber}</td>
                                <td>{orderWithLoadInfo.order.orderNumber}</td>
                                <td>
                                    {
                                        orderWithLoadInfo.order
                                            .customerOrderNumber
                                    }
                                </td>
                                <td>{orderWithLoadInfo.order.customerName}</td>
                                <td>{orderWithLoadInfo.order.palletSpaces}</td>
                                <td>{orderWithLoadInfo.loadName}</td>
                            </tr>
                        );
                    })
            )}
        </Table>
    );
};

export default OrderSelectionTable;
