import { buildClassesHook } from '../../../../../../helpers/styles';
import { Theme } from '../../../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    loadingProgressMilestoneContent: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        fontWeight: 500,
        textAlign: 'center',
        padding: '0.25rem',
    },
    active: {
        background: theme.colors.primary,
        color: theme.colors.white,
    },
    complete: {
        background: theme.colors.primaryLight,
        color: theme.colors.black,
    },
    overdue: {
        background: theme.colors.error,
        color: theme.colors.white,
    },
    loaded: {
        background: theme.colors.success,
        color: theme.colors.white,
    },
    inactive: {
        background: theme.colors.mediumGrey,
        padding: '1rem',
    },
}));
