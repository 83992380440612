import * as React from 'react';
import { useState } from 'react';
import InstructionsModal from '../../components/bookings/modals/InstructionsModal';
import ErrorModal from '../../components/common/ErrorModal';
import LoadingModal from '../../components/common/LoadingModal';
import UnsavedChangesModal from '../../components/common/UnsavedChangesModal';
import { Booking } from '../../models/bookings/Booking';
import { apiPut } from '../../services/api';

interface InstructionsModalContainerProps {
    booking: Booking;
    showModal: boolean;
    onClose(): void;
    onSubmit(): void;
}

const InstructionsModalContainer: React.FC<InstructionsModalContainerProps> = ({
    booking,
    showModal,
    onClose,
    onSubmit
}) => {
    const [bookingInstructions, setBookingInstructions] = useState<
        string | null
    >(booking.bookingInstructions);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(
        false
    );
    const [error, setError] = useState<unknown>();
    const [submitting, setSubmitting] = useState(false);

    const unsavedChanges = booking.bookingInstructions !== bookingInstructions;

    const handleCloseRequested = () => {
        if (unsavedChanges) {
            setShowUnsavedChangesModal(true);
            return;
        }
        onClose();
    };

    const handleSubmitRequested = async () => {
        setSubmitting(true);
        try {
            const editedBooking = { ...booking, bookingInstructions };
            await apiPut(`Booking/${booking.id}`, editedBooking);
            setSubmitting(false);
            onSubmit();
        } catch (error) {
            setSubmitting(false);
            console.warn(error);
            setError(error);
        }
    };

    const modalHeader = `Booking ${booking.reference} Instructions`;
    const showInstructionsModal =
        showModal && !showUnsavedChangesModal && !submitting && !error;

    return (
        <>
            <InstructionsModal
                booking={booking}
                bookingInstructions={bookingInstructions}
                showModal={showInstructionsModal}
                unsavedChanges={unsavedChanges}
                onChange={setBookingInstructions}
                onSubmit={handleSubmitRequested}
                onClose={handleCloseRequested}
            />
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={onClose}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
            <LoadingModal
                showModal={showModal && submitting}
                header={modalHeader}
                loadingText="updating booking instructions"
            />
            <ErrorModal
                showModal={showModal && !!error}
                header={modalHeader}
                errorText="There was an error updating the booking instructions"
                onClose={onClose}
            />
        </>
    );
};

export default InstructionsModalContainer;
