import {
    IodPlan,
    FinishingTrailerTrip,
    LoadRole
} from '../models/iods/IodPlan';
import { Load } from '../models/loads/Load';
import { arraysContainSameElements } from '../helpers/arrayFunctions';
import { Iod } from '../models/iods/Iod';
import { OrderLoadingStatus } from '../models/orders/OrderLoadingStatus';
import { LoadGroup } from '../models/loads/LoadGroup';

export const IodPlanComparator = (lhs: IodPlan, rhs: IodPlan): boolean =>
    lhs.iod.id === rhs.iod.id &&
    lhs.trip1.load.id === rhs.trip1.load.id &&
    arraysContainSameElements(
        lhs.trip1.predecessorTransfers,
        rhs.trip1.predecessorTransfers
    ) &&
    lhs.trip2?.load.id === rhs.trip2?.load.id &&
    arraysContainSameElements(
        lhs.trip2?.predecessorTransfers || [],
        rhs.trip2?.predecessorTransfers || []
    ) &&
    lhs.trip3?.load.id === rhs.trip3?.load.id &&
    arraysContainSameElements(
        lhs.trip3?.predecessorTransfers || [],
        rhs.trip3?.predecessorTransfers || []
    );

export const AreIodPlansEqual = (lhs: IodPlan) => (rhs: IodPlan): boolean =>
    IodPlanComparator(lhs, rhs);

const groupLoadsBy = (loads: Load[], groupByProps: string[]) =>
    Array.from<LoadGroup>(
        (
            loads.reduce((map, load) => {
                const key = groupByProps.map(prop => load[prop]).join('-');

                return map.set(key, [...(map.get(key) || []), load]);
            }, new Map()) ?? new Map()
        ).values()
    );

const groupLoadsBySourceStore = (loads: Load[]): LoadGroup[] =>
    groupLoadsBy(loads, ['sourceStore']);

export const groupLoadsByLoadType = (loads: Load[]): LoadGroup[] =>
    groupLoadsBy(loads, ['loadType']);

export const getAllFinishingTrailerLoadsFromIodPlan = (
    iodPlan: IodPlan
): Load[] => {
    const trip1Load: Load = iodPlan.trip1.load;
    const trip2Load: Load | null = iodPlan.trip2?.load ?? null;
    const trip3Load: Load | null = iodPlan.trip3?.load ?? null;

    return [trip1Load, trip2Load, trip3Load].filter(load => !!load) as Load[];
};

export const getAllTransferLoadsFromIodPlan = (iodPlan: IodPlan): Load[] => {
    const trip1Transfers: Load[] = iodPlan.trip1.predecessorTransfers;
    const trip2Transfers: Load[] = iodPlan.trip2
        ? iodPlan.trip2.predecessorTransfers
        : [];
    const trip3Transfers: Load[] = iodPlan.trip3
        ? iodPlan.trip3.predecessorTransfers
        : [];

    return [...trip1Transfers, ...trip2Transfers, ...trip3Transfers];
};

export const getAllGroupedTransferLoadsFromIodPlan = (
    iodPlan: IodPlan
): LoadGroup[] =>
    groupLoadsBySourceStore(getAllTransferLoadsFromIodPlan(iodPlan));

const getLoadsFromFinishingTrailerTrip = (
    trip: FinishingTrailerTrip
): Load[] => [trip.load, ...trip.predecessorTransfers];

export const getFinishingTrailerFromIodPlanForLoad = (
    iodPlan: IodPlan,
    loadToFind: Load
): FinishingTrailerTrip | undefined => {
    const trip1Loads: Load[] = getLoadsFromFinishingTrailerTrip(iodPlan.trip1);
    if (trip1Loads.map(load => load.id).includes(loadToFind.id))
        return iodPlan.trip1;

    const trip2Loads: Load[] = iodPlan.trip2
        ? getLoadsFromFinishingTrailerTrip(iodPlan.trip2)
        : [];
    if (trip2Loads.map(load => load.id).includes(loadToFind.id))
        return iodPlan.trip2!;

    const trip3Loads: Load[] = iodPlan.trip3
        ? getLoadsFromFinishingTrailerTrip(iodPlan.trip3)
        : [];
    if (trip3Loads.map(load => load.id).includes(loadToFind.id))
        return iodPlan.trip3!;

    return undefined;
};

export const getTransfersFromIodPlanForFinishingTrailerLoad = (
    iodPlan: IodPlan,
    load: Load
) => {
    const matchingFinishingTrailerTrip = getFinishingTrailerFromIodPlanForLoad(
        iodPlan,
        load
    );

    if (
        !matchingFinishingTrailerTrip ||
        matchingFinishingTrailerTrip.load.id !== load.id
    ) {
        return [];
    }

    return matchingFinishingTrailerTrip.predecessorTransfers;
};

export const getAllLoadsFromIodPlan = (iodPlan: IodPlan): Load[] => {
    const trip1Loads: Load[] = getLoadsFromFinishingTrailerTrip(iodPlan.trip1);
    const trip2Loads: Load[] = iodPlan.trip2
        ? getLoadsFromFinishingTrailerTrip(iodPlan.trip2)
        : [];
    const trip3Loads: Load[] = iodPlan.trip3
        ? getLoadsFromFinishingTrailerTrip(iodPlan.trip3)
        : [];
    return [...trip1Loads, ...trip2Loads, ...trip3Loads];
};

export const getAllGroupedLoadsFromIodPlan = (iodPlan: IodPlan): LoadGroup[] =>
    groupLoadsBySourceStore(getAllLoadsFromIodPlan(iodPlan));

export const getSuccessorToLoadFromIodPlan = (iodPlan: IodPlan) => (
    predecessor: Load
): Load => {
    const trip1Loads: Load[] = getLoadsFromFinishingTrailerTrip(iodPlan.trip1);

    if (trip1Loads.map(load => load.id).includes(predecessor.id)) {
        if (
            iodPlan.trip1.predecessorTransfers
                .map(transfer => transfer.id)
                .includes(predecessor.id)
        )
            return iodPlan.trip1.load;
        return iodPlan.trip2!.load;
    }

    const trip2Loads: Load[] = iodPlan.trip2
        ? getLoadsFromFinishingTrailerTrip(iodPlan.trip2)
        : [];

    if (trip2Loads.map(load => load.id).includes(predecessor.id)) {
        if (
            iodPlan.trip2?.predecessorTransfers
                .map(transfer => transfer.id)
                .includes(predecessor.id)
        )
            return iodPlan.trip2.load;
        return iodPlan.trip3!.load;
    }

    const trip3Loads: Load[] = iodPlan.trip3
        ? getLoadsFromFinishingTrailerTrip(iodPlan.trip3)
        : [];

    if (trip3Loads.map(load => load.id).includes(predecessor.id)) {
        if (
            iodPlan.trip3?.predecessorTransfers
                .map(transfer => transfer.id)
                .includes(predecessor.id)
        )
            return iodPlan.trip3.load;
        throw Error('Could not find successor load in Iod Plan');
    }

    throw Error('Could not find predecessor load in Iod Plan');
};

export const getIodPlan = (
    iod: Iod,
    finishingTrailerTrips: FinishingTrailerTrip[]
): IodPlan => ({
    iod: iod,
    trip1: finishingTrailerTrips[0],
    trip2: finishingTrailerTrips[1] || undefined,
    trip3: finishingTrailerTrips[2] || undefined
});

export const getOrdersOnIodPlanUntilLoad = (
    iodPlan: IodPlan,
    currentLoad: Load
): OrderLoadingStatus[] => {
    const loadsToThisPoint: Load[] = [
        iodPlan.trip1.load,
        ...iodPlan.trip1.predecessorTransfers
    ];

    if (currentLoad.id === iodPlan.trip3?.load.id) {
        loadsToThisPoint.push(iodPlan.trip2!.load);
        loadsToThisPoint.push(...iodPlan.trip2!.predecessorTransfers);
    }
    return loadsToThisPoint
        .map(l => l.orderLoadingStatuses)
        .reduce((prev, curr) => prev.concat(curr), [])
        .filter(
            ols => ols.order.iodId === iodPlan.iod.id && ols.releaseToChess
        );
};

export const getLoadRoleInPlan = (iodPlan: IodPlan, load: Load): LoadRole =>
    getAllFinishingTrailerLoadsFromIodPlan(iodPlan).find(
        ftl => ftl.id === load.id
    )
        ? 'Finishing-Trailer'
        : 'Transfer';

export const isFirstLoadInPlan = (iodPlan: IodPlan, load: Load): boolean =>
    iodPlan.trip1.load.id === load.id;

const groupTripTransfersBy = (
    trip: FinishingTrailerTrip | null,
    groupByProps: string[],
    selectedLoadId?: string | null
): LoadGroup[] => {
    const groupedTransfers = groupLoadsBy(
        trip?.predecessorTransfers ?? [],
        groupByProps
    );

    if (!selectedLoadId) {
        return groupedTransfers;
    }

    // Order transfer groups to ensure selected load is first (and therefore appears on top of stack in IOD plan)
    return groupedTransfers.reduce<LoadGroup[]>((rv, curr) => {
        const selectedLoad = curr.find((t: Load) => t.id === selectedLoadId);
        let reorderedGroup = curr.filter((t: Load) => t.id !== selectedLoadId);

        if (selectedLoad) {
            reorderedGroup = [selectedLoad, ...reorderedGroup];
        }

        return [...rv, reorderedGroup] as LoadGroup[];
    }, []);
};

export const groupTripTransfersBySourceStore = (
    trip: FinishingTrailerTrip | null,
    selectedLoadId?: string | null
): LoadGroup[] => groupTripTransfersBy(trip, ['sourceStore'], selectedLoadId);
