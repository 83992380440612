import { buildClassesHook } from '../../../../../helpers/styles';
import { theme } from '../../../../../theme';

export const useClasses = buildClassesHook({
    loadOrdersTable: {
        backgroundColor: 'white',
        margin: 0,
        fontSize: '0.9rem',
        color: theme.colors.darkGrey,
        '& thead th, th': {
            whiteSpace: 'nowrap',
            fontWeight: 500,
            textTransform: 'uppercase',
            color: theme.colors.darkGrey,
            backgroundColor: '#f8f8f8',
            padding: '0.35rem 0.5rem',
            fontSize: '0.8rem',
            top: '0',
            zIndex: 1,
        },
        '& thead tr': {
            borderTop: 'none',
        },
    },
    loadOrdersTableDivider: {
        top: '1.5rem !important',
    },
});
