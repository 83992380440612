import * as React from 'react';
import Table from '../../lib/bootstrap-ui/Table';
import { BayStatus } from '../../models/bays/BayStatus';
import BayStatusTableRow from './BayStatusTableRow';

interface BayStatusDataProps {
    bayStatuses: BayStatus[] | null;
}

const BayStatusData: React.FC<Readonly<BayStatusDataProps>> = ({
    bayStatuses
}) => {
    const tableHeaders = ['Bay', 'Trailer'];

    return (
        <>
            {bayStatuses && bayStatuses.length > 0 && (
                <Table headers={tableHeaders} noRowBorders>
                    {bayStatuses.map(element => (
                        <BayStatusTableRow
                            key={element.id}
                            bayStatus={element}
                        />
                    ))}
                </Table>
            )}
        </>
    );
};

export default BayStatusData;
