import { Notification } from '.';
import { Pallet } from '../pallets/Pallet';
import { StoreNumber } from '../stores/StoreNumber';

export interface NewPalletAdded {
    addedPallet: Pallet;
    store: StoreNumber;
}

export const PalletAddedNotification: Notification<NewPalletAdded> = {
    message: 'PalletAdded'
};

export const PalletsAddedNotification: Notification<NewPalletAdded[]> = {
    message: 'PalletsAdded'
};

export interface PalletRemoved {
    cancelledPalletId: string;
    cancelledPalletOrderId: string;
}

export const PalletRemovedNotification: Notification<PalletRemoved> = {
    message: 'PalletRemoved'
};

export const PalletsRemovedNotification: Notification<PalletRemoved[]> = {
    message: 'PalletsRemoved'
};

export const PalletsUpdatedNotification: Notification<Pallet[]> = {
    message: 'PalletsUpdated'
};
