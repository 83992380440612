import classnames from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import BookingEmailEntryContainer from '../../../../containers/bookings/update/BookingEmailEntryContainer';
import { formatDate } from '../../../../helpers/dates';
import { pluralise } from '../../../../helpers/stringFunctions';
import Button from '../../../../lib/bootstrap-ui/Button';
import { Switch } from '../../../../lib/bootstrap-ui/Forms';
import Grid, { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../../models/bookings/Booking';
import BookingOptions from '../../../../models/bookings/BookingOptions';
import { RecurringBooking } from '../../../../models/bookings/RecurringBooking';
import { apiPost } from '../../../../services/api';
import { getRecurrenceString } from '../../../../services/bookingService';
import { getTrailerTypeLabelForBooking } from '../../../../services/trailerService';
import InformationMessage from '../../../common/InformationMessage';
import SingleColumnRow from '../../../common/SingleColumnRow';
import LoadingIndicator from '../../../loading/LoadingIndicator';
import { useClasses } from './BookingSummary.styles';
import BookingSummaryRow from './BookingSummaryRow';

interface BookingSummaryProps {
    options: BookingOptions;
    hideReference?: boolean;
    hideSendEmail?: boolean;
    hideTitle?: boolean;
    onContinue?(): void;
}

const BookingSummary: React.FC<BookingSummaryProps> = ({
    options,
    hideReference = false,
    hideSendEmail = false,
    hideTitle = false,
    onContinue,
}) => {
    const classes = useClasses();

    const [sendEmail, setSendEmail] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [error, setError] = useState(false);
    const [containsInvalidEmail, setContainsInvalidEmail] = useState(true);

    const booking = options.booking;
    const type = options.bookingType;

    const handleSendEmail = async () => {
        if (booking) {
            setError(false);
            try {
                await apiPost(
                    `${
                        options.bookingType === 'recurring'
                            ? 'RecurringBooking'
                            : 'Booking'
                    }/${booking.id}/Email/Booked`,
                    emailAddress
                );
            } catch (error) {
                console.warn(error);
                setError(true);
                return;
            }
            onContinue && onContinue();
        }
    };

    const handleContinueClicked = () => {
        setError(false);
        onContinue && onContinue();
    };

    const renderBookingContent = (booking: Booking) => (
        <>
            {booking.customerName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Customer Name"
                >
                    {booking.customerName}
                </BookingSummaryRow>
            )}
            {booking.haulierName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Haulier Name"
                >
                    {booking.haulierName}
                </BookingSummaryRow>
            )}
            {booking.customerReference && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Customer Reference"
                >
                    {booking.customerReference}
                </BookingSummaryRow>
            )}
            <hr />
            {booking.load && (
                    <BookingSummaryRow
                        dataColumnClassName={classes.summaryRowData}
                        header="Order Numbers"
                    >
                        {booking.load.orderLoadingStatuses.map((ols, i) => (
                            <div key={i}>
                                {ols.order.orderNumber} (
                                {pluralise('pallet', ols.order.palletSpaces)})
                            </div>
                        ))}
                    </BookingSummaryRow>
                ) && (
                    <BookingSummaryRow
                        dataColumnClassName={classes.summaryRowData}
                        header="Total Pallets"
                    >
                        {booking.load.totalPalletSpaces}
                    </BookingSummaryRow>
                )}

            {booking.productCode && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Product Code"
                >
                    {booking.productCode}
                </BookingSummaryRow>
            )}

            {booking.supplierCode && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Supplier Code"
                >
                    {booking.supplierCode}
                </BookingSummaryRow>
            )}

            {booking.trailerType && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Load Type"
                >
                    {getTrailerTypeLabelForBooking(booking)}
                </BookingSummaryRow>
            )}

            {!!booking.resources.length && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Resources"
                >
                    {booking.resources.map((r, i) => (
                        <div key={i} className={'resourceItem'}>
                            {r}
                        </div>
                    ))}
                </BookingSummaryRow>
            )}
            <BookingSummaryRow header="Start Date">
                {formatDate(booking.startDate, 'dd/MM/yyyy HH:mm')}
            </BookingSummaryRow>

            <BookingSummaryRow header="End Date">
                {formatDate(booking.endDate, 'dd/MM/yyyy HH:mm')}
            </BookingSummaryRow>
        </>
    );

    const renderRecurringBookingContent = (
        recurringBooking: RecurringBooking
    ) => (
        <>
            {recurringBooking.customerName && (
                <BookingSummaryRow
                    dataColumnClassName={classes.summaryRowData}
                    header="Customer Name"
                >
                    {recurringBooking.customerName}
                </BookingSummaryRow>
            )}

            {recurringBooking.trailerType && (
                <BookingSummaryRow header="Load Type">
                    {getTrailerTypeLabelForBooking(recurringBooking)}
                </BookingSummaryRow>
            )}

            {!!recurringBooking.resources.length && (
                <BookingSummaryRow header="Resources">
                    {recurringBooking.resources.map((r, i) => (
                        <div key={i} className={'resourceItem'}>
                            {r}
                        </div>
                    ))}
                </BookingSummaryRow>
            )}

            <BookingSummaryRow header="Recurrence">
                {getRecurrenceString(recurringBooking)}
            </BookingSummaryRow>
            {recurringBooking.endDate && (
                <BookingSummaryRow header="End Date">
                    {formatDate(recurringBooking.endDate, 'dd/MM/yyyy')}
                </BookingSummaryRow>
            )}
        </>
    );

    return !booking ? (
        <LoadingIndicator />
    ) : (
        <Grid className={classnames(classes.bookingSummaryContainer)} fluid>
            <Row justify="center">
                <Column>
                    {!hideTitle && (
                        <SingleColumnRow className={classes.bookingSummaryRow}>
                            <h4>
                                {type === 'recurring'
                                    ? `Recurring ${booking.bookingType} Booking
                                Summary`
                                    : `${booking.bookingType} Booking Summary`}
                            </h4>
                        </SingleColumnRow>
                    )}

                    {!hideReference && (
                        <>
                            <BookingSummaryRow
                                className={classes.bookingSummaryReference}
                                header="Reference"
                            >
                                {booking.reference}
                            </BookingSummaryRow>
                            <hr />
                        </>
                    )}

                    <BookingSummaryRow header="Store">
                        {booking.store}
                    </BookingSummaryRow>

                    {options.bookingType === 'recurring'
                        ? renderRecurringBookingContent(options.booking)
                        : renderBookingContent(options.booking)}

                    {booking.bookingInstructions && (
                        <>
                            <hr />
                            <SingleColumnRow className={classes.summaryRowData}>
                                {booking.bookingInstructions}
                            </SingleColumnRow>
                        </>
                    )}

                    {!hideSendEmail && (
                        <>
                            <hr />
                            <Switch
                                label="Email confirmation"
                                value={sendEmail}
                                onChange={setSendEmail}
                            />
                            {sendEmail && (
                                <>
                                    <BookingEmailEntryContainer
                                        emailAddresses={emailAddress}
                                        onEmailAddressesChange={setEmailAddress}
                                        onEmailInvalidated={
                                            setContainsInvalidEmail
                                        }
                                    />
                                    {error && (
                                        <InformationMessage messageType="error">
                                            There was an error sending the
                                            email.
                                        </InformationMessage>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {onContinue && (
                        <>
                            <hr />
                            <Row className={classes.bookingSummaryRow}>
                                <Button
                                    className={classes.confirmButton}
                                    onClick={
                                        sendEmail
                                            ? handleSendEmail
                                            : handleContinueClicked
                                    }
                                    disabled={
                                        !booking.reference ||
                                        (sendEmail && containsInvalidEmail)
                                    }
                                >
                                    OK
                                </Button>
                            </Row>
                        </>
                    )}
                </Column>
            </Row>
        </Grid>
    );
};

export default BookingSummary;
