import classNames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useNotificationListener } from '../../../../hooks/useNotificationListener';
import Button from '../../../../lib/bootstrap-ui/Button';
import { Load } from '../../../../models/loads/Load';
import { LoadPalletCount } from '../../../../models/loads/LoadPalletCount';
import { LoadStatus as LoadStatusEnum } from '../../../../models/loads/LoadStatus';
import { LoadType } from '../../../../models/loads/LoadType';
import {
    PalletAddedNotification,
    PalletRemovedNotification,
    PalletsAddedNotification,
    PalletsRemovedNotification,
    PalletsUpdatedNotification,
} from '../../../../models/notifications/Pallet';
import { routeFor, RouteKey } from '../../../../Routes/routes';
import { useData } from '../../../../services/api';
import Icon, { IconType } from '../../../common/Icon';
import { useClasses } from './LoadStatus.styles';

interface LoadStatusProps {
    load: Load;
    hideIcon?: boolean;
    disableLinks?: boolean;
    onClick?(): void;
}

const getIconTypeForStatus = (status: LoadStatusEnum): IconType => {
    switch (status) {
        case LoadStatusEnum.Invalid:
        case LoadStatusEnum.UnexpectedState:
            return 'error';
        case LoadStatusEnum.ReadyToRelease:
            return 'checked';
        case LoadStatusEnum.AwaitingLoadRelease:
            return 'share-all';
        default:
            return 'info';
    }
};

const getStatusTextForLoad = (
    load: Load,
    loadPalletCount: LoadPalletCount | null
): string => {
    switch (load.status) {
        case LoadStatusEnum.AwaitingBay:
            return 'Haulier Arrived';
        case LoadStatusEnum.Picking:
            return !!loadPalletCount
                ? `Picking ${loadPalletCount.pickedCount}/${loadPalletCount.totalCount}`
                : 'Picking';
        case LoadStatusEnum.AwaitingStock:
            const baseText = load.awaitingTrailer
                ? 'Awaiting Trailer'
                : 'Awaiting Stock';
            return load.isPreDropped ? `Pre Dropped - ${baseText}` : baseText;
        case LoadStatusEnum.Releasing:
            return 'Releasing...';
        default:
            return load.status;
    }
};

const getRouteForLoad = (load: Load): string => {
    switch (load.status) {
        case LoadStatusEnum.AwaitingStock:
        case LoadStatusEnum.AwaitingHaulier:
        case LoadStatusEnum.Releasing:
        case LoadStatusEnum.ReadyToRelease:
        case LoadStatusEnum.PreDroppedAwaitingBay:
            return routeFor(RouteKey.Release)({ id: load.id });
        case LoadStatusEnum.Invalid:
        case LoadStatusEnum.UnexpectedState:
            return routeFor(RouteKey.LoadIssues)({ id: load.id });
        case LoadStatusEnum.Picking:
            return routeFor(RouteKey.LoadingProgressDetailed)({ id: load.id });
        default:
            throw Error('No route has been configured for this Load Status');
    }
};

const LoadStatus: React.FC<LoadStatusProps> = ({
    load,
    hideIcon = false,
    disableLinks = false,
    onClick,
}) => {
    const classes = useClasses();

    const [loadPalletCount, , fetchPalletCount] = useData<LoadPalletCount>(
        load.status === LoadStatusEnum.Picking
            ? `Load/${load.id}/PalletStats`
            : null
    );

    const loadOrderIds: string[] = useMemo(() => {
        return load.orderLoadingStatuses.map((ols) => ols.orderId);
    }, [load]);

    useNotificationListener(PalletAddedNotification, (palletAdded) => {
        const isAddNotificationRelevant: boolean =
            load.status === LoadStatusEnum.Picking &&
            loadOrderIds.some(
                (loid) => loid === palletAdded.addedPallet.orderId
            );
        if (isAddNotificationRelevant) {
            fetchPalletCount();
        }
    });

    useNotificationListener(PalletsAddedNotification, (palletsAdded) => {
        const isAnyAddNotificationRelevant: boolean =
            load.status === LoadStatusEnum.Picking &&
            loadOrderIds.some((loid) =>
                palletsAdded
                    .map((notification) => notification.addedPallet.orderId)
                    .includes(loid)
            );
        if (isAnyAddNotificationRelevant) {
            fetchPalletCount();
        }
    });

    useNotificationListener(PalletsUpdatedNotification, (updatedPallets) => {
        const palletOrderIds = updatedPallets.map((up) => up.orderId);
        const isUpdateNotificationRelevant: boolean =
            load.status === LoadStatusEnum.Picking &&
            loadOrderIds.some((loid) =>
                palletOrderIds.some((poid) => poid === loid)
            );

        if (isUpdateNotificationRelevant) {
            fetchPalletCount();
        }
    });

    useNotificationListener(PalletRemovedNotification, (palletRemoved) => {
        const isRemoveNotificationRelevant: boolean =
            load.status === LoadStatusEnum.Picking &&
            loadOrderIds.some(
                (loid) => loid === palletRemoved.cancelledPalletOrderId
            );

        if (isRemoveNotificationRelevant) {
            fetchPalletCount();
        }
    });

    useNotificationListener(PalletsRemovedNotification, (palletsRemoved) => {
        const isAnyRemoveNotificationRelevant: boolean =
            load.status === LoadStatusEnum.Picking &&
            loadOrderIds.some((loid) =>
                palletsRemoved
                    .map((notification) => notification.cancelledPalletOrderId)
                    .includes(loid)
            );

        if (isAnyRemoveNotificationRelevant) {
            fetchPalletCount();
        }
    });

    if (disableLinks) {
        return (
            <>
                {!hideIcon && (
                    <Icon
                        className={classes.statusIcon}
                        type={getIconTypeForStatus(load.status)}
                    />
                )}
                {getStatusTextForLoad(load, loadPalletCount)}
            </>
        );
    }

    if (load.status === LoadStatusEnum.PreDroppedAwaitingBay) {
        return (
            <Link
                to={getRouteForLoad(load)}
                className={classNames(classes.statusButton)}
            >
                {!hideIcon && (
                    <Icon
                        className={classes.statusIcon}
                        type={getIconTypeForStatus(load.status)}
                    />
                )}
                {getStatusTextForLoad(load, loadPalletCount)}
            </Link>
        );
    }

    return ((load.status === LoadStatusEnum.ReadyToRelease ||
        load.status === LoadStatusEnum.Releasing ||
        load.status === LoadStatusEnum.AwaitingHaulier ||
        (load.status === LoadStatusEnum.AwaitingStock && !load.isPreDropped)) &&
        load.loadType !== LoadType.Internal) ||
        load.status === LoadStatusEnum.Invalid ||
        load.status === LoadStatusEnum.UnexpectedState ||
        load.status === LoadStatusEnum.Picking ? (
        <Link
            to={getRouteForLoad(load)}
            className={classNames({
                [classes.readyToRelease]:
                    load.status === LoadStatusEnum.ReadyToRelease,
                [classes.invalid]:
                    load.status === LoadStatusEnum.Invalid ||
                    load.status === LoadStatusEnum.UnexpectedState,
                [classes.picking]: load.status === LoadStatusEnum.Picking,
            })}
        >
            {!hideIcon && (
                <Icon
                    className={classes.statusIcon}
                    type={getIconTypeForStatus(load.status)}
                />
            )}
            {getStatusTextForLoad(load, loadPalletCount)}
        </Link>
    ) : load.status === LoadStatusEnum.ReadyToRelease &&
      (load.loadType === LoadType.ThirdParty ||
          load.loadType === LoadType.Internal) ? (
        <>
            {onClick && (
                <Button
                    title={getStatusTextForLoad(load, loadPalletCount)}
                    element="button"
                    styleType="link"
                    className={classNames(
                        classes.statusButton,
                        classes.readyToRelease
                    )}
                    stopOnClickPropagation
                    onClick={onClick}
                >
                    {!hideIcon && (
                        <Icon
                            className={classes.statusIcon}
                            type={getIconTypeForStatus(load.status)}
                        />
                    )}
                    {getStatusTextForLoad(load, loadPalletCount)}
                </Button>
            )}
        </>
    ) : (
        <>
            {!hideIcon && (
                <Icon
                    className={classes.statusIcon}
                    type={getIconTypeForStatus(load.status)}
                />
            )}
            {getStatusTextForLoad(load, loadPalletCount)}
        </>
    );
};

export default LoadStatus;
