import { Pallet } from '../models/pallets/Pallet';
import {
    ReplacePalletsAction,
    AddPalletAction,
    AddPalletsAction,
    UpdatePalletsAction,
    RemovePalletAction,
    RemovePalletsAction
} from './LoadingProgressPalletReducerActions';

const LoadingProgressPalletReducer = (
    state: Pallet[],
    loadingProgressPalletAction:
        | ReplacePalletsAction
        | AddPalletAction
        | AddPalletsAction
        | UpdatePalletsAction
        | RemovePalletAction
        | RemovePalletsAction
): Pallet[] => {
    switch (loadingProgressPalletAction.type) {
        case 'ADD_PALLET':
            return [...state, loadingProgressPalletAction.payload];

        case 'ADD_PALLETS':
            return [...state, ...loadingProgressPalletAction.payload];

        case 'UPDATE_PALLETS':
            const updatedPalletIds = loadingProgressPalletAction.payload.map(
                p => p.id
            );
            const unaffectedPallets = state.filter(
                p => !updatedPalletIds.includes(p.id)
            );
            const updatedPallets = [
                ...unaffectedPallets,
                ...loadingProgressPalletAction.payload
            ];
            return updatedPallets;

        case 'REMOVE_PALLET':
            return state.filter(
                p => p.id !== loadingProgressPalletAction.payload
            );

        case 'REMOVE_PALLETS':
            return state.filter(
                p => !loadingProgressPalletAction.payload.includes(p.id)
            );

        case 'REPLACE_PALLETS':
            return loadingProgressPalletAction.payload;
    }
};

export default LoadingProgressPalletReducer;
