import * as React from 'react';

import Button from '../../../lib/bootstrap-ui/Button';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import Icon from '../../common/Icon';
import { useClasses } from './IodPlanInfoHeader.styles';

interface IodPlanInfoHeaderProps {
    iodNumberOrLoadName: string;
    onBackButtonClick(): void;
}

const IodPlanInfoHeader: React.FC<IodPlanInfoHeaderProps> = ({
    iodNumberOrLoadName,
    onBackButtonClick,
}) => {
    const classes = useClasses();

    return (
        <Grid fluid>
            <Row>
                <Column>
                    <Button
                        className={classes.summaryButton}
                        styleType="link"
                        onClick={onBackButtonClick}
                    >
                        <div className={classes.backButtonContentContainer}>
                            <Icon
                                className={classes.backButtonIcon}
                                type="back"
                            />
                            Back
                        </div>
                    </Button>
                </Column>
                <Column>
                    <h2 className="text-center">{iodNumberOrLoadName}</h2>
                </Column>
                <Column>
                    <></>
                </Column>
            </Row>
        </Grid>
    );
};

export default IodPlanInfoHeader;
