import * as React from 'react';
import { useState } from 'react';
import AddSMSTemplateModal from '../../../components/admin/smsTemplates/update/AddSMSTemplateModal';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { apiPost } from '../../../services/api';

interface AddSMSTemplateModalContainerProps {
    showModal: boolean;
    onClose(): void;
}

const AddSMSTemplateModalContainer: React.FC<AddSMSTemplateModalContainerProps> = ({
    showModal,
    onClose
}) => {
    const [updatingValues, setUpdatingValues] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleAddRequested = async (value: SMSTemplate) => {
        setUpdatingValues(true);
        try {
            await apiPost('SMSTemplate', value);
            setUpdatingValues(false);
            onClose();
        } catch (e) {
            setUpdatingValues(false);
            setErrorMessage(
                'An error has occurred when trying to create the SMS Template. If this continues, please contact IT.'
            );
        }
    };

    const showLoadingModal = showModal && updatingValues;
    const showAddModal = showModal && !updatingValues && !errorMessage;
    const showErrorModal = showModal && !!errorMessage;

    return (
        <>
            <LoadingModal
                showModal={showLoadingModal}
                loadingText="adding..."
            />
            <AddSMSTemplateModal
                showModal={showAddModal}
                onAddRequested={handleAddRequested}
                onClose={onClose}
            />
            <ErrorModal
                showModal={showErrorModal}
                header="Error"
                errorText={errorMessage}
                onClose={onClose}
            />
        </>
    );
};

export default AddSMSTemplateModalContainer;
