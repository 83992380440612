import * as React from 'react';
import { useContext } from 'react';
import { TextInput, Checkbox } from '../../lib/bootstrap-ui/Forms';
import { LookupContext } from '../../contexts/LookupDataProvider';
import { TrailerTypeResource } from '../../models/bookings/StoreBookingResource';

interface UpdateDefaultBookingValuesProps {
    duration: number | null;
    availableResources?: string[];
    resources: string[];
    trailerTypeResource: TrailerTypeResource;
    onBookingDurationChange(value: string | null): void;
    onResourceValueChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const UpdateDefaultBookingValues: React.FC<UpdateDefaultBookingValuesProps> = ({
    duration,
    resources,
    availableResources,
    trailerTypeResource,
    onBookingDurationChange,
    onResourceValueChange
}) => {
    const { loadingResources } = useContext(LookupContext);

    const minDuration = trailerTypeResource.hourDuration;

    const filteredResources = loadingResources.filter(
        r => !availableResources || availableResources.includes(r)
    );

    return (
        <>
            <div>
                <label htmlFor="BookingDurationInput">
                    Booking Duration (hrs):
                </label>
                <TextInput
                    error={
                        duration === null || duration < minDuration
                            ? `Must be equal to or greater than ${minDuration}`
                            : null
                    }
                    size="small"
                    type="number"
                    minValue={minDuration}
                    value={duration}
                    onChange={onBookingDurationChange}
                    hideBottomPadding
                    highlightOnFocus
                />
            </div>
            <div>
                {filteredResources.map((r, i) => (
                    <React.Fragment key={i}>
                        <Checkbox
                            checked={resources.includes(r)}
                            disabled={trailerTypeResource.resources.includes(r)}
                            label={r}
                            value={r}
                            onChange={onResourceValueChange}
                        />
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};

export default UpdateDefaultBookingValues;
