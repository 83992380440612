import * as React from 'react';

import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { Load } from '../../../models/loads/Load';
import { useData } from '../../../services/api';
import Icon from '../../common/Icon';
import LoadingIndicator from '../../loading/LoadingIndicator';
import LoadDetailsCard from './LoadDetailsCard';
import { useClasses } from './LoadDetailsPiggybackTooltip.styles';

interface LoadDetailsPiggybackTooltipProps {
    load: Load;
    popupPosition: PopupPosition[];
}

const LoadDetailsPiggybackTooltip: React.FC<
    LoadDetailsPiggybackTooltipProps
> = ({ load, popupPosition }) => {
    const classes = useClasses();

    const [open, setOpen] = useState(false);
    const [loadsToDisplay, setLoadsToDisplay] = useState<Load[]>([]);
    const [successorLoads, isLoading] = useData<Load[]>(
        `Load/${load.id}/SuccessorLoads`
    );

    useEffect(() => {
        if (successorLoads) {
            setLoadsToDisplay(
                successorLoads.filter(
                    (l) => l.associatedIodId !== load.associatedIodId
                )
            );
        }
    }, [successorLoads, load]);

    return (
        <Popup
            trigger={
                <span>
                    <Icon type="piggyback" className={classes.piggybackIcon} />
                </span>
            }
            position={popupPosition}
            keepTooltipInside
            on="click"
            onOpen={() => setOpen(true)}
            open={open}
            closeOnDocumentClick={false}
            className={classes.piggybackTooltip}
        >
            <>
                <div className={classes.tooltipHeader}>
                    <h4>Piggyback Loads</h4>
                </div>
                <div className={classes.tooltipBody}>
                    {isLoading ? (
                        <LoadingIndicator text="Retrieving Piggybacks..." />
                    ) : (
                        <>
                            {loadsToDisplay?.map((pl) => (
                                <LoadDetailsCard
                                    key={pl.id}
                                    load={pl}
                                    loadsFromSameStore={[]}
                                    iodId={pl.associatedIodId}
                                    isSelected={false}
                                    role={'Finishing-Trailer'}
                                />
                            ))}
                        </>
                    )}
                </div>
            </>
        </Popup>
    );
};

export default LoadDetailsPiggybackTooltip;
