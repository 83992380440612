import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    loadInfoContainer: {
        margin: '1.5rem auto 0 auto',
    },
    fieldsContainer: {
        margin: '0 auto',
        flexGrow: 2.5,
        '& table': {
            marginBottom: 0,
            '& td': {
                padding: '0 0.5rem 0.5rem 0',
            },
        },
    },
    backButtonContentContainer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.35rem',
    },
    backButtonIcon: {
        marginRight: '0.5rem',
        fontSize: '1.5rem',
    },
    timeFromNow: {
        fontSize: '1.2rem',
        fontWeight: 500,
        lineHeight: 1,
        whiteSpace: 'nowrap',
        display: 'block',
        marginBottom: '0.5rem',
    },
    summaryButton: {
        '&:hover': {
            backgroundColor: theme.colors.lightGrey,
            textDecoration: 'none',
        },
    },
    destinationInfo: {
        maxWidth: '30vw',
        whiteSpace: 'nowrap',
        '& h4': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    pickListDownloadContainer: {
        maxHeight: 175,
        overflow: 'visible',
    },
    pickListDownloadTable: {
        padding: '0 0.5rem',
    },
    warningIcon: {
        color: theme.colors.warningDark,
        fontSize: '0.7em',
        verticalAlign: 'top',
    },
}));
