import * as React from 'react';
import Nav, { NavProps } from './Nav';
import NavItem from './NavItem';
import NavLink from './NavLink';

interface NavLinksProps extends NavProps {
    links: Link[];
    active?: string | number | (string | number)[];
    disabled?: string[];
}

export interface Link {
    key?: string | number;
    className?: string;
    label: string | React.ReactElement;
    url: string;
    onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

const NavLinks: React.FC<NavLinksProps> = ({
    links,
    active,
    disabled,
    ...rest
}) => (
    <Nav {...rest}>
        {links.map((l, i) => (
            <NavItem
                key={
                    l.key ||
                    (typeof l.label === 'string' ? (l.label as string) : i)
                }
            >
                <NavLink
                    className={l.className}
                    to={l.url}
                    onClick={l.onClick}
                    active={
                        typeof active === 'string' || typeof active === 'number'
                            ? (l.key || l.label) === active
                            : active &&
                              active.some(a => (l.key || l.label) === a)
                    }
                    disabled={
                        disabled && disabled.some(d => (l.key || l.label) === d)
                    }
                >
                    {l.label}
                </NavLink>
            </NavItem>
        ))}
    </Nav>
);

export default NavLinks;
