import * as React from 'react';
import ReleaseDataRow from '../../components/releasing/ReleaseDataRow';
import Grid from '../../lib/bootstrap-ui/Grid';
import { Load } from '../../models/loads/Load';
import LoadTimeRemaining from '../time/LoadTimeRemaining';
import {
    getTotalDrops,
    getSubcontractedString,
    getTotalPalletSpaces,
    getLoadTrailerTypeName,
    getPiggybackedPalletCountForLoad,
} from '../../services/loadService';

interface ReleaseDataProps {
    load: Load | null;
    loading: boolean;
    children?: React.ReactNode;
}

const ReleaseData: React.FC<Readonly<ReleaseDataProps>> = ({
    load,
    loading,
}) => {
    const piggybackedPallets: number = load
        ? getPiggybackedPalletCountForLoad(load)
        : 0;

    return (
        <Grid fluid>
            <ReleaseDataRow label="Reference" loading={loading}>
                {load?.loadName ?? 'no load found'}
            </ReleaseDataRow>
            {(loading || load) && (
                <>
                    {load?.isStockTrailer === false && (
                        <ReleaseDataRow label="IOD Number" loading={loading}>
                            {load?.associatedIodNumber}
                        </ReleaseDataRow>
                    )}
                    <ReleaseDataRow label="Load Type" loading={loading}>
                        {load && load.loadType}
                    </ReleaseDataRow>
                    <ReleaseDataRow label="Trailer Type" loading={loading}>
                        {load && getLoadTrailerTypeName(load)}
                    </ReleaseDataRow>
                    <ReleaseDataRow label="Destination" loading={loading}>
                        {load && load.destination}
                    </ReleaseDataRow>
                    {load && !!load.bayOrPagerNumber && (
                        <ReleaseDataRow label="Bay/Pager" loading={loading}>
                            {load.bayOrPagerNumber}
                        </ReleaseDataRow>
                    )}
                    {load && getSubcontractedString(load) !== '' && (
                        <ReleaseDataRow label="Subcontracted" loading={loading}>
                            {getSubcontractedString(load)}
                        </ReleaseDataRow>
                    )}
                    {load && !!load.registrationPlate && (
                        <ReleaseDataRow
                            label="Registration Plate"
                            loading={loading}
                        >
                            {load.registrationPlate}
                        </ReleaseDataRow>
                    )}
                    {load && !!load.associatedIod && (
                        <ReleaseDataRow label="Total Drops" loading={loading}>
                            {() => (load ? getTotalDrops(load) : null)}
                        </ReleaseDataRow>
                    )}
                    <ReleaseDataRow label="Total Pallets" loading={loading}>
                        {() => (load ? getTotalPalletSpaces(load) : null)}
                    </ReleaseDataRow>
                    <ReleaseDataRow label="Pallets to Load" loading={loading}>
                        {() =>
                            load && piggybackedPallets !== 0
                                ? `${
                                      load.totalPalletSpaces -
                                      piggybackedPallets
                                  } (+${piggybackedPallets})`
                                : load?.totalPalletSpaces
                        }
                    </ReleaseDataRow>
                    {load?.predecessor && (
                        <ReleaseDataRow
                            label="Pallets to Unload"
                            loading={loading}
                        >
                            {() =>
                                load && load.predecessor
                                    ? getPiggybackedPalletCountForLoad(
                                          load.predecessor
                                      )
                                    : null
                            }
                        </ReleaseDataRow>
                    )}

                    <ReleaseDataRow label="Time Remaining" loading={loading}>
                        {load && (
                            <LoadTimeRemaining
                                loadByTime={load.loadByTime}
                                completedTime={load.completedAt}
                            />
                        )}
                    </ReleaseDataRow>
                </>
            )}
        </Grid>
    );
};

export default ReleaseData;
