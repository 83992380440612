import * as React from 'react';
import EditableCell from '../common/table/EditableCell';
import { Booking } from '../../models/bookings/Booking';

interface BayOrPagerNumberCellProps {
    className?: string;
    showEditIcon?: boolean;
    editBayOrPagerNumber: boolean;
    bayOrPagerNumber: number | null;
    booking: Booking;
    previousValue?: number;
    highlightUpdates?: boolean;
    onEditButtonClick(): void;
    onChange(value: string | null): void;
    onSubmit(): void;
}

const BayOrPagerNumberCell: React.FC<BayOrPagerNumberCellProps> = ({
    className,
    showEditIcon = true,
    editBayOrPagerNumber,
    bayOrPagerNumber,
    booking,
    previousValue,
    highlightUpdates = false,
    onEditButtonClick,
    onChange,
    onSubmit
}) => {
    const showEditButton =
        showEditIcon &&
        booking.requiresCheckIn &&
        (!booking.load || !booking.load?.isReleased);

    return (
        <EditableCell
            className={className}
            edit={editBayOrPagerNumber}
            value={bayOrPagerNumber}
            showEditButton={showEditButton}
            previousValue={previousValue}
            highlightUpdates={highlightUpdates}
            onEditButtonClick={onEditButtonClick}
            onChange={onChange}
            onSubmit={onSubmit}
            onEnterPressed={onSubmit}
        />
    );
};

export default BayOrPagerNumberCell;
