export enum LoadInvalidityReason {
    AssociatedIodIsNull = 'Associated IOD is null',
    AssociatedIodIsRequiringChange = 'Associated IOD is requiring change',
    NoActiveOrders = 'No active orders',
    HasInvalidOrders = 'Has orders for overfull IOD',
    HasInvalidPiggybackedOrders = 'Has piggybacked orders for overfull IOD',
    TooManyPiggybacks = 'Too many piggybacked pallets',
    TooManyPallets = 'Too many pallets',
    EuroPalletOnDecker = 'Has orders for a Decker IOD with Euro pallets',
    AsdaCageOnDecker = 'Has orders for a Decker IOD with Asda Cages',
    InvalidPalletMix = 'Has orders for an IOD with pallets and Asda Cages',
}
