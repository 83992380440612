export interface BookingAttachment {
    id?: string;
    bookingId?: string;
    fileName: string;
    status: UploadStatus;
}

export enum UploadStatus {
    Pending = 0,
    Complete = 1
}
