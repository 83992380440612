import * as React from 'react';
import { useClasses } from './LoginCardLogo.styles';

const LoginCardLogo: React.FC = () => {
    const classes = useClasses();

    return (
        <div className={classes.logoContainer}>
            <div className={classes.logoBorder}>
                <div className={classes.logoWrapper}>
                    <img
                        src="img/rb-logo.png"
                        className={classes.logo}
                        alt="Logo"
                    />
                </div>
            </div>
        </div>
    );
};

export default LoginCardLogo;
