import * as React from 'react';
import classnames from 'classnames';
import InputBase from '../Base/InputBase';
import {
    whitespaceToNull,
    safeTrim,
} from '../../../../helpers/stringFunctions';
import { useEffect } from 'react';

interface TextAreaProps {
    id: string;
    className?: string;
    textAreaClassName?: string;
    hideBottomPadding?: boolean;
    disabled?: boolean;
    label?: string;
    error?: string | null;
    placeholder?: string;
    type?: 'text' | 'number';
    readonly?: boolean;
    value?: string | number | string[] | undefined | null;
    onChange: (value: string | null) => void;
    autoConvertWhitespace?: boolean;
    autoTrim?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
    disabled,
    placeholder,
    readonly,
    value,
    onChange,
    textAreaClassName,
    autoConvertWhitespace = false,
    autoTrim = false,
    ...rest
}) => {
    const [textContent, setTextContent] = React.useState(value);

    const handleTextChange = (value: string) => {
        setTextContent(value);

        let newValue: string | null = value;

        if (autoTrim) {
            newValue = safeTrim(newValue);
        }

        if (autoConvertWhitespace) {
            newValue = whitespaceToNull(newValue);
        }

        onChange(newValue);
    };

    useEffect(() => {
        setTextContent(value);
    }, [value]);

    return (
        <InputBase {...rest}>
            {({ className, onBlur, id }) => (
                <textarea
                    className={classnames(
                        className,
                        textAreaClassName,
                        'form-control'
                    )}
                    id={id}
                    placeholder={placeholder}
                    value={textContent ?? ''}
                    onChange={(event) => handleTextChange(event.target.value)}
                    readOnly={readonly}
                    disabled={disabled}
                    onBlur={onBlur}
                />
            )}
        </InputBase>
    );
};

export default TextArea;
