import * as React from 'react';
import { useEffect, useReducer } from 'react';

import { useNotificationListener } from '../../hooks/useNotificationListener';
import {
    PalletAddedNotification,
    PalletRemovedNotification,
    PalletsAddedNotification,
    PalletsRemovedNotification,
    PalletsUpdatedNotification,
} from '../../models/notifications/Pallet';
import { Pallet } from '../../models/pallets/Pallet';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { UnpickedPalletCountReducer } from '../../reducers/UnpickedPalletCountReducer';
import { useData } from '../../services/api';
import { useClasses } from './UnpickedPalletCount.styles';

interface UnpickedPalletCountProps {
    store: StoreNumber;
}

export interface PalletIdWithStore {
    id: string;
    store: StoreNumber;
}

const UnpickedPalletCount: React.FC<UnpickedPalletCountProps> = ({ store }) => {
    const classes = useClasses();

    const [unpickedPallets, loading] = useData<PalletIdWithStore[]>(
        `Store/${store}/Order/Pallets/Unpicked`
    );

    const [unpickedPalletsToDisplay, updateUnpickedPalletsToDisplay] =
        useReducer(UnpickedPalletCountReducer, []);

    useEffect(() => {
        if (!!unpickedPallets) {
            updateUnpickedPalletsToDisplay({
                type: 'REPLACE_PALLETS',
                payload: unpickedPallets.map((pallet) => pallet.id),
            });
        }
    }, [unpickedPallets]);

    useNotificationListener(PalletAddedNotification, (newPalletAdded) => {
        if (
            newPalletAdded.store === store &&
            !unpickedPalletsToDisplay?.includes(newPalletAdded.addedPallet.id)
        ) {
            updateUnpickedPalletsToDisplay({
                type: 'ADD_PALLETS',
                payload: [newPalletAdded.addedPallet.id],
            });
        }
    });

    useNotificationListener(PalletsAddedNotification, (newPalletsAdded) => {
        const palletIdsToAdd: string[] = newPalletsAdded
            .filter(
                (notification) =>
                    notification.store === store &&
                    !unpickedPalletsToDisplay?.includes(
                        notification.addedPallet.id
                    )
            )
            .map((notification) => notification.addedPallet.id);

        if (palletIdsToAdd.length > 0) {
            updateUnpickedPalletsToDisplay({
                type: 'ADD_PALLETS',
                payload: palletIdsToAdd,
            });
        }
    });

    useNotificationListener(PalletRemovedNotification, (palletRemoved) => {
        if (
            unpickedPalletsToDisplay?.includes(palletRemoved.cancelledPalletId)
        ) {
            updateUnpickedPalletsToDisplay({
                type: 'REMOVE_PALLETS',
                payload: [palletRemoved.cancelledPalletId],
            });
        }
    });

    useNotificationListener(PalletsRemovedNotification, (palletsRemoved) => {
        const palletIdsToRemove: string[] = palletsRemoved
            .filter((notification) =>
                unpickedPalletsToDisplay?.includes(
                    notification.cancelledPalletId
                )
            )
            .map((notification) => notification.cancelledPalletId);
        if (palletIdsToRemove.length > 0) {
            updateUnpickedPalletsToDisplay({
                type: 'REMOVE_PALLETS',
                payload: palletIdsToRemove,
            });
        }
    });

    useNotificationListener(PalletsUpdatedNotification, (updatedPallets) => {
        const pickedPallets: Pallet[] = updatedPallets.filter(
            (pallet) =>
                unpickedPalletsToDisplay?.includes(pallet.id) &&
                pallet.status !== 'Allocated'
        );
        if (pickedPallets.length > 0) {
            updateUnpickedPalletsToDisplay({
                type: 'REMOVE_PALLETS',
                payload: pickedPallets.map((pallet) => pallet.id),
            });
        }
    });

    return (
        <div className={classes.unpickedPalletCount}>
            <p className={classes.unpickedPalletsText}>Unpicked Pallets</p>
            <h2 className={classes.unpickedPalletsValue}>
                {loading || !unpickedPalletsToDisplay
                    ? '...'
                    : unpickedPalletsToDisplay.length}
            </h2>
        </div>
    );
};

export default UnpickedPalletCount;
