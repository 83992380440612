import * as React from 'react';

import Button from '../../lib/bootstrap-ui/Button';
import { ReleaseValidationObject } from '../../services/releaseValidationService';
import InformationMessage from '../common/InformationMessage';
import { useClasses } from './PickerMessageDisplay.styles';

interface PickerMessageDisplayProps {
    releaseValidationObjects: ReleaseValidationObject[];
}

const PickerMessageDisplay: React.FC<PickerMessageDisplayProps> = ({
    releaseValidationObjects,
}) => {
    const classes = useClasses();

    return (
        <div className={classes.pickerMessagesColumn}>
            {releaseValidationObjects.map((item, i) => (
                <InformationMessage
                    key={i}
                    messageType={
                        item.blockRelease
                            ? 'error'
                            : item.messageType ?? 'warning'
                    }
                >
                    {item.message}
                    {item.detectedTrailerButtonLink && (
                        <>
                            {' '}
                            <Button
                                className={
                                    classes.populateDetectedTrailerButton
                                }
                                styleType={'link'}
                                size={'sm'}
                                onClick={item.detectedTrailerButtonLink}
                            >
                                Click here to use the detected trailer.
                            </Button>
                        </>
                    )}
                </InformationMessage>
            ))}
        </div>
    );
};

export default PickerMessageDisplay;
