import * as React from 'react';

import { pluralise } from '../../../helpers/stringFunctions';
import { IodPlan } from '../../../models/iods/IodPlan';
import {
    getIodPlanStatistics,
    IodPlanStatistics,
} from '../../../services/iodPlanStatisticsService';
import Center from '../../common/Center';
import { useClasses } from './IodPlanInfo.styles';
import IodPlanInfoHeader from './IodPlanInfoHeader';
import IodPlanStatComparison from './IodPlanStatComparison';

interface IodPlanInfoProps {
    iodPlan: IodPlan;
    comparisons?: IodPlanStatistics[];
    onBackButtonClick(): void;
}

const IodPlanInfo: React.FC<IodPlanInfoProps> = ({
    iodPlan,
    comparisons,
    onBackButtonClick,
}) => {
    const classes = useClasses();

    const iodPlanStats: IodPlanStatistics = getIodPlanStatistics(iodPlan);

    return (
        <Center className={classes.infoContainer}>
            <IodPlanInfoHeader
                iodNumberOrLoadName={iodPlan?.iod.iodNumber}
                onBackButtonClick={onBackButtonClick}
            />
            <h4>{pluralise('Pallet', iodPlanStats.totalPalletCount)}</h4>
            <Center direction="row">
                <IodPlanStatComparison
                    header="Transfers"
                    current={iodPlanStats}
                    property="transferCount"
                    comparisons={comparisons}
                />

                <IodPlanStatComparison
                    header="Transferred Pallets"
                    current={iodPlanStats}
                    property="transferredPalletCount"
                    comparisons={comparisons}
                />

                <IodPlanStatComparison
                    header="Tunnelled Pallets"
                    current={iodPlanStats}
                    property="tunnelledPalletCount"
                    comparisons={comparisons}
                />

                <IodPlanStatComparison
                    header="Resequenced Pallets"
                    current={iodPlanStats}
                    property="resequencePalletCount"
                    comparisons={comparisons}
                />
            </Center>
        </Center>
    );
};

export default IodPlanInfo;
