import * as React from 'react';
import { useContext } from 'react';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import { buildClassesHook } from '../../../helpers/styles';
import Table from '../../../lib/bootstrap-ui/Table';
import Bay from '../../../models/bays/Bay';
import BayManagementTableRow from './BayManagementTableRow';

const useClasses = buildClassesHook({
    bayManagementTable: {
        '& thead th': {
            fontSize: '1.2em',
            textAlign: 'center',
            top: '9.95rem',
            zIndex: 5
        },
        '& td': {
            paddingLeft: '2em',
            verticalAlign: 'middle'
        }
    }
});

interface BayManagementTableProps {
    bays: Bay[];
    bayCapabilities: string[];
    loading: boolean;
    onBayChange: (updatedBay: Bay) => void;
}

const getBayManagementTableHeaders = (bayCapabilities: string[]) => {
    return [
        'Bay Number',
        'Store',
        ...bayCapabilities,
        'Virtual Bay',
        'Out of Order',
        ''
    ];
};

const BayManagementTable: React.FC<BayManagementTableProps> = ({
    bays,
    loading,
    onBayChange
}) => {
    const classes = useClasses();
    const { bayCapabilities } = useContext(LookupContext);

    return (
        <Table
            headers={getBayManagementTableHeaders(bayCapabilities)}
            loading={loading}
            placeholderRows={15}
            className={classes.bayManagementTable}
            fixedHeader
        >
            {bays.map(b => (
                <BayManagementTableRow
                    bay={b}
                    key={b.id!}
                    bayCapabilities={bayCapabilities}
                    onBayChange={onBayChange}
                />
            ))}
        </Table>
    );
};

export default BayManagementTable;
