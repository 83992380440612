import * as React from 'react';
import { ThemeProvider } from 'react-jss';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AppRoute from './Routes/AppRoute';
import ExceptionLogger from './components/applicationInsights/ExceptionLogger';
import AppInsightsContextProvider from './contexts/AppInsights';
import { CanAccessProvider } from './contexts/CanAccessContext';
import { LookupDataProvider } from './contexts/LookupDataProvider';
import { SignalRContextProvider } from './contexts/SignalR';
import { OFFLINE, ENVIRONMENT } from './environment-variables';
import Offline from './pages/Offline';
import AppLayout from './pages/shared/AppLayout';
import { AuthProvider } from './services/authentication';
import { theme } from './theme';
import { appRoutes, loginRoute } from './Routes/routes';
import SandboxWatermark from './components/helpers/SandboxWatermark';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AppLayout />}>
            {appRoutes.map((r) => (
                <Route
                    key={r.key}
                    path={r.path}
                    element={
                        ENVIRONMENT === 'Beta' ? (
                            <SandboxWatermark>
                                <AppRoute route={r}>{r.element}</AppRoute>
                            </SandboxWatermark>
                        ) : (
                            <AppRoute route={r}>{r.element}</AppRoute>
                        )
                    }
                />
            ))}
            <Route
                key={loginRoute.key}
                path={loginRoute.path}
                element={loginRoute.element}
            ></Route>
        </Route>
    )
);

const App: React.FC = () => (
    <ExceptionLogger>
        <AuthProvider>
            <LookupDataProvider>
                <ThemeProvider theme={theme}>
                    {OFFLINE ? (
                        <Offline />
                    ) : (
                        <AppInsightsContextProvider>
                            <SignalRContextProvider>
                                <CanAccessProvider>
                                    <RouterProvider router={router} />
                                </CanAccessProvider>
                            </SignalRContextProvider>
                        </AppInsightsContextProvider>
                    )}
                </ThemeProvider>
            </LookupDataProvider>
        </AuthProvider>
    </ExceptionLogger>
);

App.displayName = App.name;

export default App;
