import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey, routeFor } from '../../Routes/routes';
import ProgressSummaryContainer from '../../containers/progressSummary/ProgressSummaryContainer';
import Page from '../common/Page';
import { StoreSelectorOption } from '../../components/common/StoreSelectorPills';

const ProgressSummary: React.FC = () => {
    const { store } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <ProgressSummaryContainer
                selectedStore={
                    store !== 'All'
                        ? (parseInt(store ?? '') as StoreSelectorOption) ??
                          undefined
                        : store
                }
                onStoreChange={(store: StoreSelectorOption) =>
                    navigate(routeFor(RouteKey.StoreProgressSummary)({ store }))
                }
            />
        </Page>
    );
};

export default ProgressSummary;
