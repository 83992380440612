import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    addButton: {
        margin: '0 0 auto 5px',
        color: theme.colors.primary,
        display: 'inline-block',
        padding: '8px 0 0 0',
    },
    removeButton: {
        margin: '0 5px auto 10px',
        color: theme.colors.error,
        display: 'inline-block',
        padding: '8px 0 0 0',
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    emailInput: {
        flexGrow: 1,
        marginBottom: 0,
    },
    inputRow: {
        margin: '0.5rem 0',
    },
}));
