import * as React from 'react';
import { useEffect, useReducer } from 'react';

import { useNotificationListener } from '../../hooks/useNotificationListener';
import {
    StockTransferOrderAddedNotification,
    StockTransferOrdersUpdatedNotification,
    StockTransferOrderUpdatedNotification,
} from '../../models/notifications/StockTransferOrders';
import { StockTransferOrder } from '../../models/orders/StockTransferOrder';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { UnattachedStockTransferOrderCountReducer } from '../../reducers/UnattachedStockTransferOrderCountReducer';
import { useData } from '../../services/api';
import { storeToCompanyCode } from '../../services/storeService';
import { useClasses } from './UnattachedStockTransferOrderCount.styles';

interface UnattachedStockTransferOrderCountProps {
    store: StoreNumber;
}

const UnattachedStockTransferOrderCount: React.FC<
    UnattachedStockTransferOrderCountProps
> = ({ store }) => {
    const classes = useClasses();

    const [unattachedOrders, loading] = useData<StockTransferOrder[]>(
        `Store/${store}/StockTransferOrders/Unattached`
    );
    const [unattachedOrderIdsToDisplay, updateUnattachedOrderIdsToDisplay] =
        useReducer(UnattachedStockTransferOrderCountReducer, []);

    useEffect(() => {
        if (!!unattachedOrders) {
            updateUnattachedOrderIdsToDisplay({
                type: 'REPLACE_ORDERS',
                payload: unattachedOrders.map((order) => order.id),
            });
        }
    }, [unattachedOrders]);

    useNotificationListener(
        StockTransferOrderAddedNotification,
        (addedStockTransferOrder) => {
            if (
                storeToCompanyCode(store) ===
                    addedStockTransferOrder.companyCode &&
                !unattachedOrderIdsToDisplay.includes(
                    addedStockTransferOrder.id
                )
            ) {
                updateUnattachedOrderIdsToDisplay({
                    type: 'ADD_ORDER',
                    payload: addedStockTransferOrder.id,
                });
            }
        }
    );

    useNotificationListener(
        StockTransferOrderUpdatedNotification,
        (updatedStockTransferOrder) => {
            if (
                updatedStockTransferOrder.companyCode !==
                storeToCompanyCode(store)
            )
                return;

            const isOrderBeingTracked: boolean =
                unattachedOrderIdsToDisplay.includes(
                    updatedStockTransferOrder.id
                );
            const isOrderAttachedOrCancelled: boolean =
                updatedStockTransferOrder.isAttached ||
                updatedStockTransferOrder.isCancelled;

            if (isOrderBeingTracked && isOrderAttachedOrCancelled) {
                updateUnattachedOrderIdsToDisplay({
                    type: 'REMOVE_ORDERS',
                    payload: [updatedStockTransferOrder.id],
                });
                return;
            }

            if (!isOrderBeingTracked && !isOrderAttachedOrCancelled) {
                updateUnattachedOrderIdsToDisplay({
                    type: 'ADD_ORDER',
                    payload: updatedStockTransferOrder.id,
                });
                return;
            }
        }
    );

    useNotificationListener(
        StockTransferOrdersUpdatedNotification,
        (updatedStockTransferOrders) => {
            const stockTransferOrdersForStore: StockTransferOrder[] =
                updatedStockTransferOrders.filter(
                    (sto) => sto.companyCode === storeToCompanyCode(store)
                );

            if (stockTransferOrdersForStore.length === 0) return;

            const stockTransferOrderIdsToAdd: string[] =
                stockTransferOrdersForStore
                    .filter(
                        (sto) =>
                            !unattachedOrderIdsToDisplay.includes(sto.id) &&
                            !sto.isAttached &&
                            !sto.isCancelled
                    )
                    .map((sto) => sto.id);

            const stockTransferOrderIdsToRemove: string[] =
                stockTransferOrdersForStore
                    .filter(
                        (sto) =>
                            unattachedOrderIdsToDisplay.includes(sto.id) &&
                            (sto.isAttached || sto.isCancelled)
                    )
                    .map((sto) => sto.id);

            if (stockTransferOrderIdsToAdd.length !== 0) {
                updateUnattachedOrderIdsToDisplay({
                    type: 'ADD_ORDERS',
                    payload: stockTransferOrderIdsToAdd,
                });
            }

            if (stockTransferOrderIdsToRemove.length !== 0) {
                updateUnattachedOrderIdsToDisplay({
                    type: 'REMOVE_ORDERS',
                    payload: stockTransferOrderIdsToRemove,
                });
            }
        }
    );

    return (
        <div className={classes.UnattachedStockTransferOrderCount}>
            <p className={classes.UnattachedOrdersText}>Unattached Orders</p>
            <h2 className={classes.UnattachedOrdersValue}>
                {loading || !unattachedOrderIdsToDisplay
                    ? '...'
                    : unattachedOrderIdsToDisplay.length}
            </h2>
        </div>
    );
};

export default UnattachedStockTransferOrderCount;
