import classNames from 'classnames';
import { addHours } from 'date-fns';
import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import UpdateDefaultBookingValues from '../../components/bookings/UpdateDefaultBookingValues';
import SingleColumnRow from '../../components/common/SingleColumnRow';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import { LookupContext } from '../../contexts/LookupDataProvider';
import { buildClassesHook } from '../../helpers/styles';
import SelectDropdown from '../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { Switch } from '../../lib/bootstrap-ui/Forms';
import Grid from '../../lib/bootstrap-ui/Grid';
import BookingDetails from '../../models/bookings/BookingDetails';
import { LoadingResource } from '../../models/bookings/LoadingResource';
import RecurringBookingDetails from '../../models/bookings/RecurringBookingDetails';
import {
    SiteResource,
    StoreResource,
    TrailerTypeResource,
} from '../../models/bookings/StoreBookingResource';
import { useData } from '../../services/api';
import { isLoadingResourceApplicableForBookingType } from '../../services/bookingService';
import {
    getTrailerAndSecondaryTrailerDropdownValue,
    getTrailerTypeDropdownOptionsForBookingType,
    getTrailerTypesFromDropdownValue,
} from '../../services/trailerService';

const useClasses = buildClassesHook({
    marginTop: {
        padding: '0px',
    },
    resourceOverrideSection: {
        padding: '0',
        fontSize: '0.9em',
    },
    resourceInputSection: {
        margin: '10px',
    },
    loadTypeDropdownContainer: {
        paddingBottom: 20,
    },
    loadTypeDropdown: {
        paddingBottom: 0,
        marginBottom: 0,
    },
});

type BookingDetailsOptions =
    | {
          details: BookingDetails;
          type: 'booking';
      }
    | {
          details: RecurringBookingDetails;
          type: 'recurring';
      };

interface LoadTypeSelectionContainerProps {
    resourceOverrideClassName?: string;
    disabled?: boolean;
    options: BookingDetailsOptions;
    loading?: boolean;
    onBookingDetailsUpdate(
        details: RecurringBookingDetails | BookingDetails
    ): void;
    overrideDefaultResourcesOnTrailerTypeChange?: boolean;
}

const LoadTypeSelectionContainer: React.FC<LoadTypeSelectionContainerProps> = ({
    resourceOverrideClassName,
    disabled = false,
    options,
    loading,
    onBookingDetailsUpdate,
    overrideDefaultResourcesOnTrailerTypeChange = false,
}) => {
    const classes = useClasses();

    const bookingDetails = options.details;
    const isRecurring = options.type === 'recurring';

    const [storeResources, loadingStoreResources] = useData<StoreResource[]>(
        `Store/${bookingDetails.store}/Resources`
    );

    const [siteResources, loadingSiteResources] =
        useData<SiteResource[]>('SiteResource');

    const [storeTrailerTypeResources, loadingStoreTrailerTypeResources] =
        useData<TrailerTypeResource[]>(
            `Store/${bookingDetails.store}/BookingType/${bookingDetails.bookingType}/TrailerTypeResources/Active`
        );

    const selectedTrailerTypeResource = storeTrailerTypeResources?.find(
        (r) =>
            r.trailerType === bookingDetails.trailerType &&
            (!bookingDetails.secondaryTrailerType ||
                r.secondaryTrailerType === bookingDetails.secondaryTrailerType)
    );

    const { trailerTypes, loadingResources } = useContext(LookupContext);

    const handleBookingDurationChange = (value: string | null) => {
        const duration = !!value ? parseInt(value) : 0;

        const shouldDurationReset = duration > bookingDetails.duration;

        if (!isRecurring) {
            const originalDetails = bookingDetails as BookingDetails;
            const editedDetails: BookingDetails = {
                ...bookingDetails,
                duration,
                selectedTimeSlot:
                    shouldDurationReset || !originalDetails.selectedTimeSlot
                        ? null
                        : {
                              ...originalDetails.selectedTimeSlot,
                              end: addHours(
                                  originalDetails.selectedTimeSlot.start,
                                  duration
                              ),
                          },
            };
            onBookingDetailsUpdate(editedDetails);
        } else {
            const originalDetails = bookingDetails as RecurringBookingDetails;
            const editedDetails: RecurringBookingDetails = {
                ...bookingDetails,
                duration,
                startDate: shouldDurationReset
                    ? null
                    : originalDetails.startDate,
                recurrencePattern: shouldDurationReset
                    ? null
                    : originalDetails.recurrencePattern,
            };
            onBookingDetailsUpdate(editedDetails);
        }
    };

    const availableResourcesForStore = useMemo(() => {
        let availableResourcesForStore = loadingResources;
        if (storeResources && siteResources) {
            const unavailableStoreLoadingResources = [
                ...storeResources
                    .filter(
                        (r) =>
                            r.amount === 0 ||
                            !isLoadingResourceApplicableForBookingType(
                                r.resource,
                                bookingDetails.bookingType
                            )
                    )
                    .map((s) => s.resource),
            ];
            const unavailableSiteLoadingResources = siteResources
                .filter((r) => r.amount === 0)
                .map((r) => r.resource);

            const missingResources = loadingResources.filter(
                (r) =>
                    !(
                        storeResources.map((r) => r.resource).includes(r) ||
                        siteResources.map((r) => r.resource).includes(r)
                    )
            );

            const unavailableResources = [
                ...unavailableSiteLoadingResources,
                ...unavailableStoreLoadingResources,
                ...missingResources,
            ];

            availableResourcesForStore = availableResourcesForStore.filter(
                (r) => !unavailableResources.includes(r)
            );
        }
        return availableResourcesForStore;
    }, [
        loadingResources,
        storeResources,
        siteResources,
        bookingDetails.bookingType,
    ]);

    useEffect(
        () => {
            if (!selectedTrailerTypeResource) {
                return;
            }

            const selectedResources = (
                availableResourcesForStore ||
                selectedTrailerTypeResource.resources
            ).filter(
                (r) =>
                    selectedTrailerTypeResource.resources.includes(r) ||
                    bookingDetails.resources?.includes(r)
            );

            let editedDetails: BookingDetails | RecurringBookingDetails = {
                ...bookingDetails,
            };

            if (!bookingDetails.overrideStoreResourceValues) {
                editedDetails.resources = selectedResources || [];
            }

            if (!bookingDetails.duration) {
                editedDetails = {
                    ...editedDetails,
                    duration: selectedTrailerTypeResource.hourDuration,
                    resources: selectedTrailerTypeResource.resources,
                };
            }

            onBookingDetailsUpdate(editedDetails);
        },
        //eslint-disable-next-line
        [selectedTrailerTypeResource]
    );

    const handleTrailerTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const [trailerType, secondaryTrailerType] =
            getTrailerTypesFromDropdownValue(event.target.value);

        const matchingTrailerTypeResource = storeTrailerTypeResources?.find(
            (r) =>
                r.trailerType === trailerType &&
                (!secondaryTrailerType ||
                    r.secondaryTrailerType === secondaryTrailerType)
        );

        const selectedResources = overrideDefaultResourcesOnTrailerTypeChange
            ? bookingDetails.resources
            : null;
        const overrideStoreResourceValues =
            overrideDefaultResourcesOnTrailerTypeChange
                ? bookingDetails.overrideStoreResourceValues
                : false;
        const duration = overrideDefaultResourcesOnTrailerTypeChange
            ? Math.max(
                  bookingDetails.duration,
                  matchingTrailerTypeResource?.hourDuration ?? 0
              )
            : 0;

        if (!isRecurring) {
            const editedDetails: BookingDetails = {
                ...bookingDetails,
                duration,
                resources: selectedResources,
                trailerType: trailerType,
                overrideStoreResourceValues,
                selectedTimeSlot: null,
                secondaryTrailerType: secondaryTrailerType,
            };
            onBookingDetailsUpdate(editedDetails);
        } else {
            const editedDetails: RecurringBookingDetails = {
                ...bookingDetails,
                duration,
                resources: selectedResources,
                trailerType: trailerType,
                overrideStoreResourceValues,
                startDate: null,
                recurrencePattern: null,
                secondaryTrailerType: secondaryTrailerType,
            };
            onBookingDetailsUpdate(editedDetails);
        }
    };

    const handleOverrideConfigValuesChange = (
        overrideStoreResourceValues: boolean
    ) => {
        if (selectedTrailerTypeResource) {
            if (!isRecurring) {
                const editedDetails: BookingDetails = {
                    ...bookingDetails,
                    overrideStoreResourceValues,
                    duration: selectedTrailerTypeResource.hourDuration,
                    resources: selectedTrailerTypeResource.resources,
                    selectedTimeSlot: null,
                };
                onBookingDetailsUpdate(editedDetails);
            } else {
                const editedDetails: RecurringBookingDetails = {
                    ...bookingDetails,
                    overrideStoreResourceValues,
                    duration: selectedTrailerTypeResource.hourDuration,
                    resources: selectedTrailerTypeResource.resources,
                    recurrencePattern: null,
                    startDate: null,
                };
                onBookingDetailsUpdate(editedDetails);
            }
        } else {
            const editedDetails: BookingDetails | RecurringBookingDetails = {
                ...bookingDetails,
                overrideStoreResourceValues,
            };

            onBookingDetailsUpdate(editedDetails);
        }
    };

    const handleResourceValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const resource = event.target.value;
        const isChecked = event.target.checked;
        const resources = isChecked
            ? [...bookingDetails.resources!, resource as LoadingResource]
            : [...bookingDetails.resources!.filter((b) => b !== resource)];

        const originalResources = bookingDetails.resources!;

        const shouldTimeSlotReset = originalResources.length < resources.length;

        if (!isRecurring) {
            const originalDetails = bookingDetails as BookingDetails;
            const selectedTimeSlot = shouldTimeSlotReset
                ? null
                : originalDetails.selectedTimeSlot;

            const editedDetails: BookingDetails = {
                ...bookingDetails,
                resources,
                selectedTimeSlot,
            };
            onBookingDetailsUpdate(editedDetails);
        } else {
            const originalDetails = bookingDetails as RecurringBookingDetails;
            const startDate = shouldTimeSlotReset
                ? null
                : originalDetails.startDate;
            const recurrencePattern = shouldTimeSlotReset
                ? null
                : originalDetails.recurrencePattern;

            const editedDetails: RecurringBookingDetails = {
                ...bookingDetails,
                resources,
                startDate,
                recurrencePattern,
            };
            onBookingDetailsUpdate(editedDetails);
        }
    };

    const getTrailerTypeDropdownOptions = () => {
        let trailerTypeDropdownResources: TrailerTypeResource[] = [];
        if (storeTrailerTypeResources) {
            trailerTypeDropdownResources = storeTrailerTypeResources;
            if (availableResourcesForStore) {
                trailerTypeDropdownResources =
                    trailerTypeDropdownResources.filter((t) =>
                        t.resources.every((r) =>
                            availableResourcesForStore.includes(r)
                        )
                    );
            }
        }
        return getTrailerTypeDropdownOptionsForBookingType(
            trailerTypeDropdownResources,
            bookingDetails.bookingType
        );
    };

    const dropdownOptions = getTrailerTypeDropdownOptions();

    return !bookingDetails.bookingType ||
        !trailerTypes ||
        !storeTrailerTypeResources ||
        loading ||
        loadingStoreResources ||
        loadingSiteResources ? (
        <LoadingIndicator />
    ) : (
        <Grid>
            {
                <SingleColumnRow
                    className={classNames(
                        classes.marginTop,
                        classes.loadTypeDropdownContainer
                    )}
                >
                    <SelectDropdown
                        className={classes.loadTypeDropdown}
                        id="LoadTypeDropdown"
                        defaultText="Select Load Type..."
                        dropdownOptions={dropdownOptions}
                        label="Load Type:"
                        selectedOption={getTrailerAndSecondaryTrailerDropdownValue(
                            bookingDetails.trailerType,
                            bookingDetails.secondaryTrailerType
                        )}
                        disabled={
                            disabled ||
                            loadingStoreTrailerTypeResources ||
                            dropdownOptions.length <= 1
                        }
                        onChange={handleTrailerTypeChange}
                    />
                </SingleColumnRow>
            }
            <SingleColumnRow className={classes.resourceOverrideSection}>
                <Switch
                    label="Override Default Resources"
                    id="OverrideDefaultResources"
                    value={bookingDetails.overrideStoreResourceValues}
                    disabled={
                        !bookingDetails.trailerType ||
                        !selectedTrailerTypeResource
                    }
                    onChange={handleOverrideConfigValuesChange}
                />
                {bookingDetails.overrideStoreResourceValues &&
                    !!bookingDetails.resources &&
                    selectedTrailerTypeResource && (
                        <div
                            className={classNames(
                                classes.resourceInputSection,
                                resourceOverrideClassName
                            )}
                        >
                            <UpdateDefaultBookingValues
                                duration={bookingDetails.duration}
                                availableResources={availableResourcesForStore}
                                resources={bookingDetails.resources || []}
                                trailerTypeResource={
                                    selectedTrailerTypeResource
                                }
                                onBookingDurationChange={
                                    handleBookingDurationChange
                                }
                                onResourceValueChange={
                                    handleResourceValueChange
                                }
                            />
                        </div>
                    )}
            </SingleColumnRow>
        </Grid>
    );
};

export default LoadTypeSelectionContainer;
