import * as React from 'react';

import { Pallet } from '../../models/pallets/Pallet';
import { useClasses } from './ProgressSummaryTotalCell.styles';

interface ProgressSummaryTotalCellProps {
    pallets: Pallet[];
}

const ProgressSummaryTotalCell: React.FC<ProgressSummaryTotalCellProps> = ({
    pallets,
}) => {
    const classes = useClasses();

    return (
        <td className={classes.progressSummaryTotalCell}>
            <div className={classes.progressSummaryTotalCellContent}>
                {pallets.length}
            </div>
        </td>
    );
};

export default ProgressSummaryTotalCell;
