import * as React from 'react';
import { useState } from 'react';
import DuplicateCustomerReferenceModal from '../../../components/bookings/modals/DuplicateCustomerReferenceModal';
import { BookingFormSummaryPanel } from '../../../components/bookings/view/summary';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { getErrorMessage } from '../../../helpers/errors';
import Button from '../../../lib/bootstrap-ui/Button';
import { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../models/bookings/Booking';
import {
    BookingToAdd,
    CreateBooking,
} from '../../../models/bookings/BookingToAdd';
import { BookingType } from '../../../models/bookings/BookingType';
import { apiDelete, apiPost } from '../../../services/api';
import { useCurrentUser } from '../../../services/authentication';
import { BookingFormSteps } from '../../../services/bookingFormService';
import {
    getBookingWithExistingCustomerReference,
    getDefaultBookingTrailerType,
    uploadBookingAttachments,
} from '../../../services/bookingService';
import { userToAllStores } from '../../../services/userService';
import { useClasses } from './BookingFormContainer.styles';

interface BookingFormContainerProps {
    bookingType: BookingType;
    onBookingConfirmation(id: string): void;
}

type ModalType = 'duplicateCustRef' | 'error' | 'attachmentError';

const convertToBookingToAddRequest = (
    createBookingEntity: CreateBooking
): BookingToAdd => ({
    id: null,
    attachments: createBookingEntity.attachments,
    bookingInstructions: createBookingEntity.bookingInstructions,
    bookingType: createBookingEntity.bookingType,
    customerName: createBookingEntity.customerName,
    customerReference: createBookingEntity.customerReference,
    haulierName: createBookingEntity.haulierName,
    orderIds:
        ((createBookingEntity.bookingType === BookingType.Collection ||
            createBookingEntity.bookingType ===
                BookingType.DeliveryAndCollection ||
            createBookingEntity.bookingType === BookingType.Internal) &&
            createBookingEntity.orders?.map((order) => order.id)) ||
        [],
    productCode: createBookingEntity.productCode,
    resources: createBookingEntity.resources,
    selectedTimeSlot: createBookingEntity.selectedTimeSlot,
    store: createBookingEntity.store,
    supplierCode: createBookingEntity.supplierCode,
    timeSlotOverrideRequested: createBookingEntity.timeSlotOverrideRequested,
    trailerType: createBookingEntity.trailerType,
    secondaryTrailerType: createBookingEntity.secondaryTrailerType,
    noResourcesSelected: createBookingEntity.noResourcesSelected,
});

const BookingFormContainer: React.FC<BookingFormContainerProps> = ({
    bookingType,
    onBookingConfirmation,
}) => {
    const classes = useClasses();

    const user = useCurrentUser();

    const allUserStores = userToAllStores(user);
    const store = !user ? 1 : allUserStores[0];
    const showStoreSelector = allUserStores.length > 1;

    const [booking, setBooking] = useState<CreateBooking>({
        id: null,
        bookingType: bookingType,
        bookingInstructions: null,
        customerName: null,
        customerReference: null,
        haulierName: null,
        resources: bookingType === BookingType.NoBookings ? [] : null,
        selectedTimeSlot: null,
        store: store,
        trailerType: getDefaultBookingTrailerType(bookingType),
        secondaryTrailerType: null,
        orderIds: [],
        orders: [],
        duration: 0,
        overrideStoreResource: false,
        timeSlotOverrideRequested: false,
        productCode: null,
        supplierCode: null,
        attachments: [],
        attachmentFiles: [],
        invalidOrderSelected: false,
        noResourcesSelected: false,
    });

    const [submittingBooking, setSubmittingBooking] = useState(false);
    const [uploadingAttachments, setUploadingAttachments] = useState(false);
    const [
        duplicateCustomerReferenceBooking,
        setDuplicateCustomerReferenceBooking,
    ] = useState<Booking>();

    const [modalToDisplay, setModalToDisplay] = useState<ModalType | null>(
        null
    );
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [step, setStep] = useState(0);

    const formSteps = BookingFormSteps(bookingType, showStoreSelector);

    const currentFormStep = formSteps[step];
    const CurrentFormStepComponent = currentFormStep.component;

    const isLastStep = step === formSteps.length - 1;
    const isFirstStep = step === 0;

    const [createdBookingResultId, setCreatedBookingResultId] =
        useState<string>();

    const handleBookingChange = (updatedBooking: CreateBooking) => {
        setBooking(updatedBooking);
    };

    const resetModalToDisplay = () => {
        setModalToDisplay(null);
    };

    const createBooking = async (
        bookingToAdd: BookingToAdd
    ): Promise<
        { success: true; id: string } | { success: false; message: string }
    > => {
        try {
            const createdBooking: Booking = await apiPost(
                'Booking',
                bookingToAdd
            );
            setBooking((prev) => ({
                ...prev,
                id: createdBooking.id,
            }));
            setSubmittingBooking(false);
            return { success: true, id: createdBooking.id };
        } catch (error) {
            setSubmittingBooking(false);
            return { success: false, message: getErrorMessage(error) };
        }
    };

    const confirmBooking = (bookingId: string) => {
        onBookingConfirmation(bookingId);
    };

    const submitBooking = async () => {
        const createdBookingResult = await createBooking(
            convertToBookingToAddRequest(booking)
        );

        if (!createdBookingResult.success) {
            setSubmittingBooking(false);
            setErrorMessage(
                `Error submitting booking. ${createdBookingResult.message}`
            );
            setModalToDisplay('error');
            return;
        }

        if (createdBookingResult.id && booking.attachments.length > 0) {
            setUploadingAttachments(true);
            const success = await uploadBookingAttachments(
                createdBookingResult.id,
                booking.attachmentFiles
            );

            if (!success) {
                setErrorMessage(
                    'Oops! The booking was successfully created, however, there was an error uploading the attachments. ' +
                        "Attachments can be re-uploaded via the 'Attachments' section of the Booking Table."
                );
                setModalToDisplay('attachmentError');
                setCreatedBookingResultId(createdBookingResult.id);

                await apiDelete(
                    `Booking/${createdBookingResult.id}/Attachments`
                );

                return;
            }

            const attachmentFileNames = booking.attachments.map(
                (attachment) => attachment.fileName
            );
            await apiPost(
                `Booking/${createdBookingResult.id}/Attachments/Confirm`,
                attachmentFileNames
            );
            setUploadingAttachments(false);
        }
        confirmBooking(createdBookingResult.id);
    };

    const checkForDuplicateCustomerReference = async () => {
        const bookingWithCustomerReference =
            await getBookingWithExistingCustomerReference(
                booking.customerReference
            );
        if (bookingWithCustomerReference) {
            setDuplicateCustomerReferenceBooking(bookingWithCustomerReference);
            setModalToDisplay('duplicateCustRef');
            return true;
        }
        return false;
    };

    const handleOnNextClicked = async () => {
        if (isLastStep) {
            setSubmittingBooking(true);
            const isDuplicateCustomerReference =
                await checkForDuplicateCustomerReference();
            if (!isDuplicateCustomerReference) {
                submitBooking();
            }
        } else {
            setStep(step + 1);
        }
    };

    const handleCloseDuplicateCustomerReferenceModal = () => {
        resetModalToDisplay();
        setSubmittingBooking(false);
    };

    return (
        <>
            <Row justify="center">
                <Column
                    xs={12}
                    lg={8}
                    xl={8}
                    xlOffset={2}
                    className={classes.formContainer}
                >
                    {submittingBooking ? (
                        <LoadingIndicator text="creating booking" />
                    ) : uploadingAttachments ? (
                        <LoadingIndicator text="uploading attachments" />
                    ) : (
                        <>
                            <Row>
                                <Column>
                                    <CurrentFormStepComponent
                                        formObject={booking}
                                        onChange={handleBookingChange}
                                        onConfirmation={() =>
                                            onBookingConfirmation(booking.id!)
                                        }
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column className={classes.marginTop}>
                                    {!isFirstStep && (
                                        <Button
                                            className={classes.previousButton}
                                            onClick={() => setStep(step - 1)}
                                        >
                                            Previous
                                        </Button>
                                    )}
                                    <Button
                                        className={classes.nextButton}
                                        onClick={handleOnNextClicked}
                                        disabled={
                                            submittingBooking ||
                                            !currentFormStep.validator(booking)
                                        }
                                    >
                                        {isLastStep ? 'Submit' : 'Next'}
                                    </Button>
                                </Column>
                            </Row>
                        </>
                    )}
                </Column>
                <Column
                    xsOrder={'first'}
                    xs={12}
                    lgOrder={'last'}
                    lg={4}
                    xl={3}
                    className={classes.zeroPadding}
                >
                    <BookingFormSummaryPanel booking={booking} />
                </Column>
            </Row>
            {modalToDisplay === 'duplicateCustRef' && (
                <DuplicateCustomerReferenceModal
                    isOpen={modalToDisplay === 'duplicateCustRef'}
                    booking={duplicateCustomerReferenceBooking!}
                    onRequestSubmit={() => {
                        resetModalToDisplay();
                        setSubmittingBooking(true);
                        submitBooking();
                    }}
                    onRequestGoBack={handleCloseDuplicateCustomerReferenceModal}
                    onRequestClose={handleCloseDuplicateCustomerReferenceModal}
                />
            )}
            {modalToDisplay === 'error' && errorMessage && (
                <ErrorModal
                    showModal={modalToDisplay === 'error'}
                    errorText={errorMessage}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'attachmentError' &&
                errorMessage &&
                createdBookingResultId && (
                    <ErrorModal
                        showModal={modalToDisplay === 'attachmentError'}
                        errorText={errorMessage}
                        onClose={() => {
                            resetModalToDisplay();
                            confirmBooking(createdBookingResultId);
                        }}
                    />
                )}
        </>
    );
};

export default BookingFormContainer;
