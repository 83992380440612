import * as React from 'react';
import { useMemo, useState } from 'react';
import { addHours, format, startOfHour } from 'date-fns';
import { Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import useInterval from '../../../../hooks/useInterval';
import { Booking } from '../../../../models/bookings/Booking';
import { StoreResource } from '../../../../models/bookings/StoreBookingResource';
import { StoreNumber } from '../../../../models/stores/StoreNumber';
import { useData } from '../../../../services/api';
import { theme } from '../../../../theme';

interface ResourceSummaryChartProps {
    width: number;
    height: number;
    bookings: Booking[];
    store: StoreNumber;
}

const ResourceSummaryChart: React.FC<ResourceSummaryChartProps> = ({
    width,
    height,
    bookings,
    store
}) => {
    const [storeResources] = useData<StoreResource[]>(
        `Store/${store}/Resources`
    );
    const [firstHour, setFirstHour] = useState<Date>(startOfHour(new Date()));

    useInterval(() => {
        setFirstHour(startOfHour(new Date()));
    }, 60000 /* every minute */);

    const clampTruckStoreResource =
        storeResources &&
        storeResources.find(s => s.resource === 'Clamp Truck');

    const maxClampTrucks =
        (clampTruckStoreResource && clampTruckStoreResource.amount) || 0;

    const bookingStats = useMemo(
        () =>
            Array.from({ length: 24 }, (_, k) => {
                const dateTimeToCompare = addHours(firstHour, k);
                return {
                    name: format(dateTimeToCompare, 'HH:00'),
                    clampTrucks: maxClampTrucks
                        ? (bookings || [])
                              .filter(
                                  b =>
                                      new Date(b.startDate) <=
                                          dateTimeToCompare &&
                                      new Date(b.endDate) > dateTimeToCompare
                              )
                              .reduce((rv, curr) => {
                                  return (
                                      rv +
                                      (curr.resources.some(
                                          r => r === 'Clamp Truck'
                                      )
                                          ? 1
                                          : 0)
                                  );
                              }, 0) / maxClampTrucks
                        : 0
                };
            }),
        [firstHour, bookings, maxClampTrucks]
    );

    if (maxClampTrucks <= 0) {
        return null;
    }

    return (
        <BarChart width={width} height={height} data={bookingStats}>
            <XAxis
                dataKey="name"
                tick={false}
                axisLine={{ stroke: theme.colors.primary, strokeWidth: 0.8 }}
                height={1}
            />
            <YAxis hide domain={[0, 1]} unit="%" />
            <Tooltip
                cursor={{ fill: theme.colors.lightestGrey }}
                content={({ label }) => (
                    <div style={{ backgroundColor: '#fff' }}>{label}</div>
                )}
            />
            {maxClampTrucks > 0 && (
                <Bar dataKey="clampTrucks" fill={theme.colors.green} />
            )}
            <ReferenceLine
                y={1}
                ifOverflow="extendDomain"
                stroke={theme.colors.error}
                strokeDasharray="3 3"
            />
        </BarChart>
    );
};

export default ResourceSummaryChart;
