import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import ColdStoreOrderSelection from '../../components/bookings/ColdStoreOrderSelection';
import { apiGet } from '../../services/api';
import { storeToCompanyCode } from '../../services/storeService';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { StockTransferOrder } from '../../models/orders/StockTransferOrder';

interface StockTransferOrderSelectorProps {
    containerClassName?: string;
    orderContainerClassName?: string;
    selectedOrders: StockTransferOrder[];
    store: StoreNumber;
    disabled?: boolean;
    onOrdersChange(
        selectedOrders: StockTransferOrder[],
        invalidOrderSelected?: boolean
    ): void;
}

const StockTransferOrderSelector: React.FC<StockTransferOrderSelectorProps> = ({
    containerClassName,
    orderContainerClassName,
    selectedOrders,
    store,
    disabled = false,
    onOrdersChange,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [filteredOrders, setFilteredOrders] = useState<StockTransferOrder[]>(
        []
    );
    const [invalidOrderSelected, setInvalidOrderSelected] =
        useState<StockTransferOrder | null>(null);

    const [inputClicked, setInputClicked] = useState(false);

    const ordersToDisplay = useMemo(
        () =>
            filteredOrders.filter(
                (o) =>
                    !selectedOrders.some((order) => order.id === o.id) &&
                    (!invalidOrderSelected ||
                        (invalidOrderSelected &&
                            o.id !== invalidOrderSelected.id))
            ) ?? [],
        [filteredOrders, selectedOrders, invalidOrderSelected]
    );

    useEffect(() => {
        const fetchFilteredOrders = async () => {
            const filteredOrders: StockTransferOrder[] = await apiGet(
                `StockTransferOrders/Company/${storeToCompanyCode(store)}`,
                { SearchTerm: searchValue }
            );
            setFilteredOrders(filteredOrders);
        };

        if (searchValue.length > 0 || inputClicked) {
            fetchFilteredOrders();
            return;
        }

        setFilteredOrders([]);
    }, [store, searchValue, inputClicked]);

    const handleInvalidOrderSelected = (
        selectedOrder: StockTransferOrder | null
    ) => {
        setInvalidOrderSelected(selectedOrder);
    };

    return (
        <ColdStoreOrderSelection
            containerClassName={containerClassName}
            orderContainerClassName={orderContainerClassName}
            orders={ordersToDisplay}
            searchValue={searchValue}
            selectedOrders={selectedOrders}
            disabled={disabled}
            onInvalidOrderSelected={handleInvalidOrderSelected}
            onOrdersChange={onOrdersChange}
            onOrderSearchValueChange={setSearchValue}
            onInputClick={() => setInputClicked(true)}
            onInputBlur={() => setInputClicked(false)}
        />
    );
};

export default StockTransferOrderSelector;
