import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';
import { acceptedAttachmentFileTypes } from '../../../services/bookingService';
import { getFileTypeFromFileName } from '../../../services/fileService';
import FileGrid, { FileToDisplay } from '../../common/fileUpload/FileGrid';
import FileUploader from '../../common/fileUpload/FileUploader';

const useClasses = buildClassesHook({
    attachmentsModal: {
        minWidth: 550,
    },
    downloadGrid: {
        marginTop: 0,
    },
});

interface AttachmentsModalProps {
    showModal: boolean;
    files: FileToDisplay[];
    downloadingFileNames: string[];
    deletingFileNames: string[];
    uploadingFileNames: string[];
    uploadFailure: boolean;
    booking: Booking;
    onDownloadRequested(fileName: string): void;
    onUploadRequested(files: File[]): void;
    onDeleteRequested(fileName: string): void;
    onRequestClose(): void;
}

const AttachmentsModal: React.FC<AttachmentsModalProps> = ({
    showModal,
    files,
    downloadingFileNames,
    deletingFileNames,
    uploadingFileNames,
    uploadFailure,
    booking,
    onDownloadRequested,
    onDeleteRequested,
    onUploadRequested,
    onRequestClose,
}) => {
    const classes = useClasses();

    return (
        <Modal
            className={classes.attachmentsModal}
            isOpen={showModal}
            onRequestClose={onRequestClose}
        >
            <ModalHeader>
                <h5>{`${booking.reference} Attachments`}</h5>
            </ModalHeader>
            <ModalBody>
                {booking.isCheckedIn ? (
                    <FileGrid
                        className={classes.downloadGrid}
                        files={booking.attachments.map((attachment) => ({
                            name: attachment.fileName,
                            type: getFileTypeFromFileName(attachment.fileName),
                        }))}
                        downloadingFileNames={downloadingFileNames}
                        showDownloadAction
                        onDownloadActionClicked={onDownloadRequested}
                    />
                ) : (
                    <FileUploader
                        files={files}
                        acceptedFileTypes={acceptedAttachmentFileTypes}
                        displayAlertOnFailure
                        downloadingFileNames={downloadingFileNames}
                        deletingFileNames={deletingFileNames}
                        uploadingFileNames={uploadingFileNames}
                        uploadFailure={uploadFailure}
                        showDownloadAction
                        showRemoveAction
                        onDownloadActionClicked={onDownloadRequested}
                        onRemoveActionClicked={onDeleteRequested}
                        onUploadRequested={onUploadRequested}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onRequestClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AttachmentsModal;
