import classnames from 'classnames';
import * as React from 'react';

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
    fluid?: boolean;
    className?: string;
    children: React.ReactNode;
}

const Grid: React.FC<GridProps> = ({
    fluid,
    className,
    children,
    ...otherProps
}) => {
    const newClassName = classnames(className, {
        'container-fluid': fluid,
        container: !fluid
    });
    return (
        <div className={newClassName} {...otherProps}>
            {children}
        </div>
    );
};

export default Grid;
