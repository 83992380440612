import * as React from 'react';
import { ModalType } from '../../../../containers/admin/thirdPartyManagement/ThirdPartyManagementContainer';
import { buildClassesHook } from '../../../../helpers/styles';
import Table from '../../../../lib/bootstrap-ui/Table';
import ThirdPartyValue from '../../../../models/thirdPartyValues/ThirdPartyValue';
import ManagementTableRow from './ManagementTableRow';

const useClasses = buildClassesHook({
    managementTable: {
        tableLayout: 'fixed',
        '& thead th': {
            fontSize: '1.2em',
            textAlign: 'center',
            zIndex: 1,
            top: '8.5rem'
        },
        '& td': {
            fontSize: '1.1em',
            verticalAlign: 'middle',
            '& Button': {
                fontSize: '1.1em'
            }
        }
    }
});

interface ManagementTableProps {
    thirdPartyValues: ThirdPartyValue[];
    loading: boolean;
    valueTitle: string;
    onModalSelected(value: ThirdPartyValue, action: ModalType): void;
}

const ManagementTable: React.FC<ManagementTableProps> = ({
    thirdPartyValues,
    loading,
    valueTitle,
    onModalSelected
}) => {
    const classes = useClasses();

    return (
        <Table
            headers={[`${valueTitle}s`, 'Actions']}
            loading={loading}
            placeholderRows={15}
            className={classes.managementTable}
            fixedHeader
        >
            {thirdPartyValues.map(v => (
                <ManagementTableRow
                    thirdPartyValue={v}
                    key={v.id!}
                    onModalSelected={onModalSelected}
                />
            ))}
        </Table>
    );
};

export default ManagementTable;
