import * as React from 'react';
import { SMSTemplateManagementModalType } from '../../../../containers/admin/smsTemplates/SMSTemplateManagementContainer';
import Button from '../../../../lib/bootstrap-ui/Button';
import { SMSTemplate } from '../../../../models/smsTemplates/SMSTemplate';
import { theme } from '../../../../theme';
import Icon from '../../../common/Icon';
import { useClasses } from './SMSTemplateManagementTableRow.styles';
import classNames from 'classnames';

interface SMSTemplateManagementTableRowProps {
    smsTemplate: SMSTemplate;
    onModalOpened(
        value: SMSTemplate,
        action: SMSTemplateManagementModalType
    ): void;
}

const SMSTemplateManagementTableRow: React.FC<
    SMSTemplateManagementTableRowProps
> = ({ smsTemplate, onModalOpened }) => {
    const classes = useClasses();

    return (
        <tr
            key={smsTemplate.id!}
            className={classNames({
                [classes.primaryTemplateRow]: smsTemplate.isPrimary,
            })}
        >
            <td>{smsTemplate.name}</td>
            <td>{smsTemplate.message}</td>
            <td className={classes.contentCenterColumn}>
                {smsTemplate.isDefault ? (
                    <Icon
                        type="checked"
                        color={theme.colors.success}
                        size="2x"
                    />
                ) : (
                    ''
                )}
            </td>
            <td className={classes.contentCenterColumn}>
                <Button
                    element="button"
                    styleType="link"
                    onClick={() => onModalOpened(smsTemplate, 'edit')}
                >
                    <Icon className={classes.iconButton} type="pencil" />
                </Button>
                <Button
                    element="button"
                    styleType="link"
                    onClick={() => onModalOpened(smsTemplate, 'test')}
                >
                    <Icon className={classes.iconButton} type="envelope" />
                </Button>
                <Button
                    element="button"
                    styleType="link"
                    onClick={() => onModalOpened(smsTemplate, 'delete')}
                    disabled={smsTemplate.isPrimary}
                >
                    <Icon
                        className={classNames(classes.deleteButton, {
                            [classes.hiddenButton]: smsTemplate.isPrimary,
                        })}
                        type="delete"
                    />
                </Button>
            </td>
        </tr>
    );
};

export default SMSTemplateManagementTableRow;
