import classNames from 'classnames';
import * as React from 'react';

import Button from '../../../lib/bootstrap-ui/Button';
import { routeFor, RouteKey } from '../../../Routes/routes';
import Center from '../../common/Center';
import SingleColumnRow from '../../common/SingleColumnRow';
import Icon, { IconType } from '../Icon';
import { useClasses } from './NoDataFoundTableRow.styles';

interface AlternativeLink {
    route: RouteKey;
    linkText: string;
}

type Size = 'small' | 'medium' | 'large';

interface NoDataFoundTableRowProps {
    messageText: string;
    iconType: IconType;
    columnSpan: number;
    size?: Size;
    alternativeLink?: AlternativeLink;
    containerClassName?: string;
    error?: boolean;
}

const NoDataFoundTableRow: React.FC<NoDataFoundTableRowProps> = ({
    messageText,
    iconType,
    columnSpan,
    size = 'medium',
    alternativeLink,
    containerClassName,
    error,
}) => {
    const classes = useClasses();

    const iconClasses = (className: string) =>
        classNames(className, {
            [classes.smallIcon]: size === 'small',
            [classes.mediumIcon]: size === 'medium',
            [classes.largeIcon]: size === 'large',
        });

    return (
        <tr>
            <td colSpan={columnSpan}>
                <Center className={containerClassName}>
                    <SingleColumnRow>
                        {error ? (
                            <Icon
                                className={iconClasses(
                                    classes.errorFetchingDataIcon
                                )}
                                type={iconType}
                            />
                        ) : (
                            <Icon
                                className={iconClasses(classes.noDataFoundIcon)}
                                type={iconType}
                            />
                        )}
                    </SingleColumnRow>
                    <SingleColumnRow
                        className={classNames(classes.noDataFoundMessage, {
                            [classes.smallText]: size === 'small',
                            [classes.mediumText]: size === 'medium',
                            [classes.largeText]: size === 'large',
                        })}
                    >
                        {messageText}
                    </SingleColumnRow>
                    {alternativeLink && (
                        <SingleColumnRow>
                            <Button
                                to={routeFor(alternativeLink.route)()}
                                element="Link"
                                size="sm"
                            >
                                {alternativeLink.linkText}
                            </Button>
                        </SingleColumnRow>
                    )}
                </Center>
            </td>
        </tr>
    );
};

export default NoDataFoundTableRow;
