import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';

import Button from '../../lib/bootstrap-ui/Button';
import Grid, { Column, Row } from '../../lib/bootstrap-ui/Grid';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../lib/bootstrap-ui/Modal';
import { Load } from '../../models/loads/Load';
import { StockTransferOrder } from '../../models/orders/StockTransferOrder';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { useData } from '../../services/api';
import SingleColumnRow from '../common/SingleColumnRow';
import LoadingIndicator from '../loading/LoadingIndicator';
import StockTransferOrderSelector from './StockTransferOrderSelector';
import { useClasses } from './UpdateThirdPartyOrdersModal.styles';

interface UpdateStockTransferOrdersModalProps {
    modalHeader: string;
    load: Load;
    showModal: boolean;
    isLoading: boolean;
    onSubmit(selectedOrderIds: string[]): void;
    onClose(unsavedChanges: boolean): void;
}

const UpdateStockTransferOrderModal: React.FC<
    UpdateStockTransferOrdersModalProps
> = ({ modalHeader, load, showModal, isLoading, onSubmit, onClose }) => {
    const classes = useClasses();

    const [isInvalidOrderSelected, setIsInvalidOrderSelected] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState<StockTransferOrder[]>(
        []
    );

    const [stockTransferOrders, loadingStockTransferOrders] = useData<
        StockTransferOrder[]
    >(`Load/${load.id}/StockTransferOrders`);

    useEffect(() => {
        if (stockTransferOrders) {
            setSelectedOrders(stockTransferOrders);
        }
    }, [stockTransferOrders]);

    const handleOrdersChange = (
        selectedOrders: StockTransferOrder[],
        invalidOrderSelected?: boolean
    ) => {
        setSelectedOrders(selectedOrders);
        invalidOrderSelected
            ? setIsInvalidOrderSelected(true)
            : setIsInvalidOrderSelected(false);
    };

    const handleRequestClose = () => {
        onClose(stockTransferOrders !== selectedOrders);
    };

    const handleConfirmClicked = () => {
        onSubmit(selectedOrders.map((order) => order.id));
    };

    const disableSubmit = selectedOrders.length === 0 || isInvalidOrderSelected;

    return (
        <>
            <Modal
                isOpen={showModal}
                onRequestClose={handleRequestClose}
                modalClassName={classNames(classes.updateOrdersModal, {
                    [classes.loadingModal]:
                        isLoading || loadingStockTransferOrders,
                })}
            >
                <ModalHeader>
                    <h5>{modalHeader}</h5>
                </ModalHeader>
                <>
                    {isLoading || loadingStockTransferOrders ? (
                        <div className={classes.loadingContainer}>
                            <LoadingIndicator text="retrieving orders" />
                        </div>
                    ) : (
                        <>
                            <ModalBody className={classes.updateOrdersBody}>
                                <Grid>
                                    <SingleColumnRow>
                                        <StockTransferOrderSelector
                                            selectedOrders={selectedOrders}
                                            store={
                                                load.sourceStore as StoreNumber
                                            }
                                            onOrdersChange={handleOrdersChange}
                                        />
                                    </SingleColumnRow>
                                    <hr />
                                    {isInvalidOrderSelected && (
                                        <Row>
                                            <Column>
                                                <div>
                                                    <p
                                                        className={
                                                            classes.errorMessage
                                                        }
                                                    >
                                                        One of the orders
                                                        selected has been
                                                        cancelled or is already
                                                        attached to another
                                                        booking
                                                    </p>
                                                </div>
                                            </Column>
                                        </Row>
                                    )}
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    styleType="secondary"
                                    onClick={handleRequestClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    styleType="primary"
                                    disabled={disableSubmit}
                                    onClick={handleConfirmClicked}
                                >
                                    Confirm
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </>
            </Modal>
        </>
    );
};

export default UpdateStockTransferOrderModal;
