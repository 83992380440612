import * as React from 'react';

import { useCurrentUser } from '../../services/authentication';
import { useClasses } from './LoginCardTitle.styles';

const LoginCardTitle: React.FC = () => {
    const user = useCurrentUser();
    const classes = useClasses();

    const message =
        user == null ? (
            <span>
                Welcome to the
                <br /> Dock System
            </span>
        ) : (
            <span>
                Welcome back
                <br /> {user.name}
            </span>
        );

    return <h3 className={classes.title}>{message}</h3>;
};

export default LoginCardTitle;
