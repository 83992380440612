import classNames from 'classnames';
import * as React from 'react';

import { useClasses } from './LoadDetailInfo.styles';

interface LoadDetailInfoProps {
    className?: string;
    label: string;
    colspan?: number;
}

const LoadDetailInfo: React.FC<
    React.PropsWithChildren<LoadDetailInfoProps>
> = ({ className, children, colspan, label }) => {
    const classes = useClasses();

    return (
        <>
            <td>
                <p className={classes.labelText}>{label}:</p>
            </td>
            <td
                className={classNames(classes.contentCell, className)}
                colSpan={colspan}
            >
                {children}
            </td>
        </>
    );
};

export default LoadDetailInfo;
