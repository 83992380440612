import { buildClassesHook } from '../../../../helpers/styles';
import { BookingType } from '../../../../models/bookings/BookingType';
import { getBookingTypeColour } from '../../../../services/bookingService';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    bookingCard: {
        boxShadow: '1px 1px 3px 1px hsl(210, 10%, 92%)',
        borderStyle: 'solid',
        borderWidth: '0 0 0 5px',
        borderRadius: '0.25rem',
    },
    deliveryAndCollectionCard: {
        background: `linear-gradient(to right, white, white), ${getBookingTypeColour(
            BookingType.DeliveryAndCollection
        )}`,
        backgroundClip: 'padding-box, border-box',
    },
    bookingCardBody: {
        padding: '0.25rem 0',
    },
    bookingCardTitle: {
        display: 'inline',
        fontSize: '1.4rem',
    },
    bookingCardText: {
        margin: 0,
        lineHeight: '1.2rem',
    },
    store: {
        fontSize: '1.2rem',
        position: 'absolute',
        left: '15px',
        display: 'inline',
    },
    loadType: {
        position: 'absolute',
        right: '15px',
        fontSize: '1.2rem',
        display: 'inline',
    },
    customerName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    customerReference: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        lineHeight: '1.1rem',
        fontSize: '0.9rem',
        color: theme.colors.mediumDarkGrey,
    },
    resourcesText: {
        fontStyle: 'italic',
        fontSize: '0.8rem',
        margin: 0,
        color: theme.colors.mediumDarkGrey,
    },
    palletsOut: {
        float: 'right',
    },
}));
