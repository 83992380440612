import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Modal, { ModalBody } from '../../lib/bootstrap-ui/Modal';
import LoadingIndicator from '../loading/LoadingIndicator';
import InformationMessage from './InformationMessage';

const useClasses = buildClassesHook({
    savingBody: {
        width: '20rem',
        height: '5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    savedText: {
        textAlign: 'center',
        fontSize: '1rem',
    },
    savingHeader: {
        border: 'none',
        padding: '0 1rem',
    },
    modal: {
        padding: 0,
    },
});

interface SavingModalProps {
    isOpen: boolean;
    saved: boolean;
}

const SavingModal: React.FC<SavingModalProps> = ({ isOpen, saved }) => {
    const classes = useClasses();

    return (
        <Modal modalClassName={classes.modal} isOpen={isOpen}>
            <ModalBody className={classes.savingBody}>
                {!saved ? (
                    <LoadingIndicator text="saving changes" />
                ) : (
                    <InformationMessage
                        messageType="success"
                        className={classes.savedText}
                    >
                        Changes Saved
                    </InformationMessage>
                )}
            </ModalBody>
        </Modal>
    );
};

export default SavingModal;
