export enum LoadStatus {
    AwaitingUnloading = 'Awaiting Unloading',
    AwaitingSpace = 'Awating Space',
    AwaitingStock = 'Awaiting Stock',
    Picking = 'Picking',
    Loading = 'Loading',
    ReadyToDespatch = 'Ready to Despatch',
    ReadyToFinalCheck = 'Ready to Final Check',
    ReadyToRelease = 'Ready to Release',
    Completed = 'Completed',
    AwaitingLoadRelease = 'Awaiting Load Release',
    Invalid = 'Invalid',
    AwaitingHaulier = 'Awaiting Haulier',
    AwaitingBay = 'Awaiting Bay',
    AwaitingTunnelStock = 'Awaiting Tunnel Stock',
    AwaitingConfirmation = 'Awaiting Confirmation',
    PreDroppedAwaitingBay = 'Pre Dropped - Awaiting Bay',
    UnexpectedState = 'Unexpected State',
    Releasing = 'Releasing',
    PreDroppedAwaitingHaulier = 'Pre Dropped - Awaiting Haulier',
}
