import classNames from 'classnames';
import * as React from 'react';

import { useClasses } from './Page.styles';

interface PageProps {
    className?: string;
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({
    className,
    children,
}) => {
    const classes = useClasses();

    return (
        <div className={classNames(classes.page, className)}>{children}</div>
    );
};

export default Page;
