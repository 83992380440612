import * as React from 'react';
import { formatDate } from '../../../helpers/dates';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';

const useClasses = buildClassesHook({
    duplicateCustomerReferenceModal: {
        maxHeight: '90vh',
        width: '450px',
    },
});

interface DuplicateCustomerReferenceModalProps {
    isOpen: boolean;
    booking: Booking;
    onRequestSubmit: () => void;
    onRequestGoBack: () => void;
    onRequestClose: () => void;
}

const DuplicateCustomerReferenceModal: React.FC<
    DuplicateCustomerReferenceModalProps
> = ({ isOpen, booking, onRequestSubmit, onRequestGoBack, onRequestClose }) => {
    const classes = useClasses();

    return (
        <Modal
            isOpen={isOpen}
            modalClassName={classes.duplicateCustomerReferenceModal}
            onRequestClose={onRequestClose}
        >
            <ModalHeader>
                <h5>Duplicate Customer Reference</h5>
            </ModalHeader>
            <ModalBody>
                <p>{`A ${
                    booking.bookingType
                } with this Customer Reference is booked for ${formatDate(
                    booking.startDate,
                    'dd/MM/yyyy HH:mm'
                )}`}</p>
                <p>Are you sure to want to submit?</p>
            </ModalBody>
            <ModalFooter>
                <Button styleType="primary" onClick={onRequestSubmit}>
                    Submit
                </Button>
                <Button styleType="secondary" onClick={onRequestGoBack}>
                    Go Back
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DuplicateCustomerReferenceModal;
