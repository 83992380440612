import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';
import Icon from './Icon';

const useClasses = buildClassesHook((theme: Theme) => ({
    informationText: {
        padding: 0,
        margin: 0,
        fontWeight: 600,
        fontSize: '0.875rem',
        minHeight: '1.5em',
        '&:not(:first-child)': {
            marginTop: '1em',
        },
    },
    icon: {
        marginRight: 8,
    },
    warning: {
        color: theme.colors.warning,
    },
    error: {
        color: theme.colors.error,
    },
    info: {
        color: theme.colors.info,
    },
    success: {
        color: theme.colors.success,
    },
}));

interface InformationMessageProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    messageType?: MessageType;
    hideIcon?: boolean;
}

type MessageType = 'error' | 'warning' | 'loading' | 'success' | 'info';

function mapMessageTypeToIconType(messageType: MessageType) {
    switch (messageType) {
        case 'success':
            return 'checked';
        default:
            return messageType;
    }
}

const InformationMessage: React.FC<Readonly<InformationMessageProps>> = ({
    children,
    messageType = 'info',
    className,
    hideIcon,
    ...otherProps
}) => {
    const classes = useClasses();

    return (
        <div
            className={classNames(
                classes.informationText,
                {
                    [classes.error]: messageType === 'error',
                    [classes.warning]: messageType === 'warning',
                    [classes.info]: messageType === 'info',
                    [classes.success]: messageType === 'success',
                },
                className
            )}
            {...otherProps}
        >
            {!hideIcon && (
                <span className={classes.icon}>
                    <Icon type={mapMessageTypeToIconType(messageType)} />
                </span>
            )}
            {children}
        </div>
    );
};

export default InformationMessage;
