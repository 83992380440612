import * as React from 'react';
import { useState } from 'react';

import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Load } from '../../../models/loads/Load';
import { OrderLoadingStatus } from '../../../models/orders/OrderLoadingStatus';
import { apiPost } from '../../../services/api';
import ErrorModal from '../../common/ErrorModal';
import LoadingIndicator from '../../loading/LoadingIndicator';
import PiggybackOrderRemovalTable from '../tables/PiggybackOrderRemovalTable';
import { useClasses } from './RemovePiggybacksModal.styles';

interface ConfirmRemovePiggybacksModal {
    load: Load;
    isOpen: boolean;
    onClose(): void;
}

const RemovePiggybacksModal: React.FC<ConfirmRemovePiggybacksModal> = ({
    load,
    isOpen,
    onClose,
}) => {
    const classes = useClasses();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);

    const handleRemovePiggybacks = async () => {
        try {
            setUpdateInProgress(true);
            await apiPost(`Load/${load.id}/RemovePiggybacks`);
            setUpdateInProgress(false);
            onClose();
        } catch (error) {
            setUpdateInProgress(false);
            setShowErrorModal(true);
        }
    };

    const piggybackOnOrders: OrderLoadingStatus[] = !load.isStockTrailer
        ? load.orderLoadingStatuses.filter(
              (o) => o.order.iodId !== load.associatedIodId
          )
        : [];

    const headerText = updateInProgress
        ? `Removing Piggybacks from Load ${load.loadName}`
        : `Are you sure you would like to remove the following Orders from Load ${load.loadName}`;

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose}>
                <ModalHeader>
                    <h5>{headerText}</h5>
                </ModalHeader>
                {updateInProgress ? (
                    <LoadingIndicator text="Removing Piggybacks..." />
                ) : (
                    <>
                        <ModalBody className={classes.modalBody}>
                            <PiggybackOrderRemovalTable
                                orders={piggybackOnOrders}
                            />
                            <p>
                                Where possible Orders will be moved onto
                                existing Loads for the relevant IODs.
                            </p>
                            <p>
                                If Orders cannot be added to existing Loads,
                                this will create a new Stock Trailer containing
                                the Piggybacked Orders going to{' '}
                                {load.destination}
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button styleType="secondary" onClick={onClose}>
                                Close
                            </Button>
                            <Button
                                styleType="danger"
                                onClick={handleRemovePiggybacks}
                            >
                                Confirm
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Modal>
            <ErrorModal
                showModal={isOpen && showErrorModal}
                header={headerText}
                errorText={`There was an error removing the Piggybacks for Load ${load.loadName}`}
                onClose={() => setShowErrorModal(false)}
            ></ErrorModal>
        </>
    );
};

export default RemovePiggybacksModal;
