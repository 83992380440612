const saveFile = (blob: Blob | string, filename: string) => {
    const data = typeof blob === 'string' ? new Blob([blob]) : blob;
    const windowUntyped = window as any;

    if (windowUntyped.msSaveOrOpenBlob) {
        windowUntyped.msSaveOrOpenBlob(data, filename);
    } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0);
    }
};

export default saveFile;
