import { addDays } from 'date-fns';
import * as React from 'react';
import { useContext, useState } from 'react';

import { LookupContext } from '../../../contexts/LookupDataProvider';
import { arraysContainSameElements } from '../../../helpers/arrayFunctions';
import Button from '../../../lib/bootstrap-ui/Button';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { BookingCancellationReason } from '../../../models/bookings/BookingCancellationReason';
import { BookingStatus } from '../../../models/bookings/BookingStatus';
import { BookingType } from '../../../models/bookings/BookingType';
import HistoricalBookingFilter from '../../../models/bookings/HistoricalBookingFilter';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import { getBookingStatusLabel } from '../../../services/bookingService';
import CheckboxListSelector from '../../common/CheckboxListSelector';
import DatePickerInput from '../../common/DatePicker/DatePickerInput';
import SingleColumnRow from '../../common/SingleColumnRow';
import { useClasses } from './BookingsHistoryFilterModal.styles';

interface BookingsHistoryFilterModalProps {
    filter: HistoricalBookingFilter;
    isOpen: boolean;
    onFilterConfirm(confirmedFilter: HistoricalBookingFilter): void;
    onRequestClose(): void;
}

const BookingsHistoryFilterModal: React.FC<BookingsHistoryFilterModalProps> = ({
    filter,
    isOpen,
    onFilterConfirm,
    onRequestClose,
}) => {
    const classes = useClasses();

    const {
        bookingTypes: allBookingTypes,
        loadingResources: allLoadingResources,
        bookingCancellationReasons: allBookingCancellationReasons,
    } = useContext(LookupContext);

    const [bookingFilter, setBookingFilter] = useState(filter);
    const [displayStartDatePicker, setDisplayStartDatePicker] = useState(false);
    const [
        displayCancelledOrCompletedDatePicker,
        setDisplayCancelledOrCompletedDatePicker,
    ] = useState(false);

    const handleFilterPropertyChange =
        <T,>(name: keyof HistoricalBookingFilter) =>
        (value: T) => {
            setBookingFilter({ ...bookingFilter, [name]: value });
        };

    const handleStartOnOpen = () => {
        setDisplayCancelledOrCompletedDatePicker(false);
        setDisplayStartDatePicker(true);
    };

    const handleStartDateSelected = (selectedDate: Date) => {
        setBookingFilter({
            ...bookingFilter,
            startDate: selectedDate,
            endDate: selectedDate ? addDays(selectedDate, 1) : null,
        });
        setDisplayStartDatePicker(false);
    };

    const handleCancelledOrCompletedOnOpen = () => {
        setDisplayStartDatePicker(false);
        setDisplayCancelledOrCompletedDatePicker(true);
    };

    const handleCancelledOrCompletedDateSelected = (selectedDate: Date) => {
        setBookingFilter({
            ...bookingFilter,
            cancelledOrCompletedStartDate: selectedDate,
            cancelledOrCompletedEndDate: selectedDate
                ? addDays(selectedDate, 1)
                : null,
        });
        setDisplayCancelledOrCompletedDatePicker(false);
    };

    const closeDatePickers = () => {
        setDisplayStartDatePicker(false);
        setDisplayCancelledOrCompletedDatePicker(false);
    };

    const handleStatusSelected = (selectedStatuses: BookingStatus[]) => {
        setBookingFilter({
            ...bookingFilter,
            statuses: selectedStatuses,
            cancellationReasons: selectedStatuses.includes(
                BookingStatus.Cancelled
            )
                ? bookingFilter.statuses.includes(BookingStatus.Cancelled)
                    ? bookingFilter.cancellationReasons
                    : allBookingCancellationReasons
                : null,
        });
    };

    const handleFilterConfirm = () => {
        onFilterConfirm(bookingFilter);
        onRequestClose();
    };

    const bookingTypesUpdated = !arraysContainSameElements(
        bookingFilter.bookingTypes,
        filter.bookingTypes
    );
    const resourcesUpdated = !arraysContainSameElements(
        bookingFilter.resources,
        filter.resources
    );
    const statusesUpdated = !arraysContainSameElements(
        bookingFilter.statuses,
        filter.statuses
    );
    const cancellationReasonsUpdated = !arraysContainSameElements(
        bookingFilter.cancellationReasons,
        filter.cancellationReasons
    );
    const cancelledOrCompletedDateUpdated =
        bookingFilter.cancelledOrCompletedStartDate?.valueOf() !==
        filter.cancelledOrCompletedStartDate?.valueOf();

    const filterUpdated =
        bookingTypesUpdated ||
        bookingFilter.filter !== filter.filter ||
        bookingFilter.startDate?.valueOf() !== filter.startDate?.valueOf() ||
        cancelledOrCompletedDateUpdated ||
        statusesUpdated ||
        resourcesUpdated ||
        cancellationReasonsUpdated;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            modalClassName={classes.filterModal}
        >
            <ModalHeader showCloseButton onCloseButtonClick={onRequestClose}>
                <h5>Historical Bookings Filter</h5>
            </ModalHeader>
            <ModalBody>
                <>
                    <Row>
                        <Column>
                            <TextInput
                                label="Filter:"
                                placeholder="Filter..."
                                size="small"
                                value={bookingFilter.filter}
                                onChange={handleFilterPropertyChange<string>(
                                    'filter'
                                )}
                                autoConvertWhitespace
                                autoTrim
                            />
                        </Column>
                        <Column>
                            <DatePickerInput
                                inputLabel="Start Date:"
                                inputPlaceholder="dd/mm/yyyy"
                                popupPosition="bottom left"
                                isOpen={displayStartDatePicker}
                                value={bookingFilter.startDate}
                                onDateSelected={handleStartDateSelected}
                                onOpen={handleStartOnOpen}
                                onClose={closeDatePickers}
                            />
                        </Column>
                        <Column>
                            <DatePickerInput
                                inputLabel="Completed/Cancelled Date:"
                                inputPlaceholder="dd/mm/yyyy"
                                popupPosition="bottom left"
                                isOpen={displayCancelledOrCompletedDatePicker}
                                value={
                                    bookingFilter.cancelledOrCompletedStartDate
                                }
                                onDateSelected={
                                    handleCancelledOrCompletedDateSelected
                                }
                                onOpen={handleCancelledOrCompletedOnOpen}
                                onClose={closeDatePickers}
                            />
                        </Column>
                    </Row>
                    <div onClick={closeDatePickers}>
                        <SingleColumnRow>
                            <CheckboxListSelector
                                label={'Bookings To Display:'}
                                inline
                                columns={3}
                                createEmptyColumns
                                availableItems={[
                                    BookingStatus.Complete.toString(),
                                    BookingStatus.Cancelled.toString(),
                                ]}
                                selectedItems={bookingFilter.statuses.map((s) =>
                                    s.toString()
                                )}
                                onChange={(values) =>
                                    handleStatusSelected(
                                        values.map((v) => parseInt(v, 0))
                                    )
                                }
                                getItemLabel={(item) =>
                                    getBookingStatusLabel(parseInt(item, 0))
                                }
                            />
                        </SingleColumnRow>
                        <SingleColumnRow
                            className={classes.checkBoxListContainer}
                        >
                            <CheckboxListSelector
                                label={'Booking Types:'}
                                inline
                                columns={3}
                                availableItems={allBookingTypes}
                                selectedItems={bookingFilter.bookingTypes || []}
                                onChange={handleFilterPropertyChange<
                                    BookingType[]
                                >('bookingTypes')}
                            />
                        </SingleColumnRow>
                        <SingleColumnRow
                            className={classes.checkBoxListContainer}
                        >
                            <CheckboxListSelector
                                label={'Resources:'}
                                inline
                                columns={3}
                                availableItems={allLoadingResources}
                                selectedItems={bookingFilter.resources || []}
                                onChange={handleFilterPropertyChange<
                                    LoadingResource[]
                                >('resources')}
                            />
                        </SingleColumnRow>
                        {bookingFilter.statuses.includes(
                            BookingStatus.Cancelled
                        ) && (
                            <SingleColumnRow
                                className={classes.checkBoxListContainer}
                            >
                                <CheckboxListSelector
                                    label={'Cancellation Reasons:'}
                                    inline
                                    columns={3}
                                    availableItems={
                                        allBookingCancellationReasons
                                    }
                                    selectedItems={
                                        bookingFilter.cancellationReasons || []
                                    }
                                    onChange={handleFilterPropertyChange<
                                        BookingCancellationReason[]
                                    >('cancellationReasons')}
                                />
                            </SingleColumnRow>
                        )}
                    </div>
                </>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onRequestClose} styleType="secondary">
                    Close
                </Button>
                <Button
                    disabled={!filterUpdated}
                    onClick={handleFilterConfirm}
                    styleType="primary"
                >
                    Apply
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default BookingsHistoryFilterModal;
