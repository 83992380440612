import { default as classnames, default as classNames } from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import { FileType } from '../../../models/common/FileType';
import { Theme } from '../../../theme';
import Center from '../Center';
import CSSGrid from '../CSSGrid';
import Icon, { IconType } from '../Icon';

const useClasses = buildClassesHook((theme: Theme) => ({
    actionIcon: {
        fontSize: '1.2rem',
    },
    fileIcon: {
        fontSize: '1.75rem',
    },
    pdfFile: {
        color: '#b60706',
    },
    excelFile: {
        color: '#1D6F42',
    },
    wordFile: {
        color: '#2b569a',
    },
    otherFile: {
        color: theme.colors.darkGrey,
    },
    fileCard: {
        border: 'none',
        boxShadow: `1px 1px 3px 1px lightGrey`,
        borderRadius: '0.15rem',
        display: 'flex',
        flexDirection: 'row',
        padding: '0.25rem',
        whiteSpace: 'nowrap',
    },
    fileNameContainer: {
        display: 'inline-flex',
        flexDirection: 'row',
        width: '90%',
        alignItems: 'center',
        alignSelf: 'center',
        cursor: 'default',
        overflow: 'hidden',
    },
    fileNameText: {
        marginLeft: '0.5rem',
        fontWeight: 500,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '200px',
    },
    removeIcon: {
        color: theme.colors.errorDark,
    },
    spinnerIconContainer: {
        width: 'auto',
        height: 'auto',
        color: theme.colors.primary,
    },
    cssGrid: {
        marginTop: 20,
    },
}));

export interface FileToDisplay {
    name: string;
    type: FileType;
}

interface FileGridProps {
    className?: string;
    files: FileToDisplay[];
    downloadingFileNames?: string[];
    uploadingFileNames?: string[];
    deletingFileNames?: string[];
    showDownloadAction?: boolean;
    showRemoveAction?: boolean;
    onDownloadActionClicked?(fileName: string): void;
    onRemoveActionClicked?(fileName: string): void;
}

const FileGrid: React.FC<FileGridProps> = ({
    className,
    files,
    downloadingFileNames,
    deletingFileNames,
    uploadingFileNames,
    showDownloadAction = false,
    showRemoveAction = false,
    onDownloadActionClicked,
    onRemoveActionClicked,
}) => {
    const classes = useClasses();

    const getFileTypeIcon = (type: FileType) => {
        switch (type) {
            case FileType.doc:
            case FileType.docx:
                return (
                    <Icon
                        className={classnames(
                            classes.fileIcon,
                            classes.wordFile
                        )}
                        type="word-file"
                    />
                );
            case FileType.xls:
            case FileType.xlsx:
                return (
                    <Icon
                        className={classnames(
                            classes.fileIcon,
                            classes.excelFile
                        )}
                        type="excel-file"
                    />
                );
            case FileType.pdf:
                return (
                    <Icon
                        className={classnames(
                            classes.fileIcon,
                            classes.pdfFile
                        )}
                        type="pdf-file"
                    />
                );
            default:
                return (
                    <Icon
                        className={classNames(
                            classes.fileIcon,
                            classes.otherFile
                        )}
                        type="file"
                    />
                );
        }
    };

    const getFileActionButton = (iconType: IconType, onClick: () => any) => (
        <Button styleType="link" style={{ width: '10%' }} onClick={onClick}>
            <Center>
                <Icon
                    className={classNames(classes.actionIcon, {
                        [classes.removeIcon]: iconType === 'cross',
                    })}
                    type={iconType}
                />
            </Center>
        </Button>
    );

    const getSpinnerIcon = (className?: string) => (
        <Center className={classes.spinnerIconContainer}>
            <Icon
                className={classNames(classes.actionIcon, className)}
                type="spinner"
            />
        </Center>
    );

    const getFileActionButtons = (fileName: string) => {
        if (!showDownloadAction && !showRemoveAction) {
            return;
        }
        if (uploadingFileNames?.includes(fileName)) {
            return getSpinnerIcon();
        }
        return (
            <>
                {showDownloadAction && (
                    <>
                        {downloadingFileNames?.includes(fileName)
                            ? getSpinnerIcon()
                            : getFileActionButton(
                                  'download',
                                  () =>
                                      onDownloadActionClicked &&
                                      onDownloadActionClicked(fileName)
                              )}
                    </>
                )}
                {showRemoveAction && (
                    <>
                        {deletingFileNames?.includes(fileName)
                            ? getSpinnerIcon(classes.removeIcon)
                            : getFileActionButton(
                                  'cross',
                                  () =>
                                      onRemoveActionClicked &&
                                      onRemoveActionClicked(fileName)
                              )}
                    </>
                )}
            </>
        );
    };

    return (
        <CSSGrid
            columns="repeat(auto-fill, minmax(200px, 1fr))"
            gap={10}
            className={classNames(classes.cssGrid, className)}
        >
            {files.map((file, i) => (
                <div key={i} className={classes.fileCard}>
                    <div
                        className={classes.fileNameContainer}
                        title={file.name}
                    >
                        {getFileTypeIcon(file.type as FileType)}
                        <div className={classes.fileNameText}>{file.name}</div>
                    </div>
                    {getFileActionButtons(file.name)}
                </div>
            ))}
        </CSSGrid>
    );
};

export default FileGrid;
