import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    smsTemplateManagementContainer: {
        display: 'flex',
        height: '100%',
        flexFlow: 'column nowrap',
    },
    contentAlignEndColumn: {
        textAlign: 'end',
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        top: '4.5rem',
        position: 'sticky',
        backgroundColor: theme.colors.white,
        zIndex: 3,
        paddingBottom: '1rem',
        borderBottom: `1px solid ${theme.colors.lightGrey}`,
    },
}));
