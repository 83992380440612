import { Classes } from 'jss';
import { createUseStyles, DefaultTheme, Styles } from 'react-jss';

export const buildClassesHook = <
    C extends string = string,
    Props = unknown,
    Theme = DefaultTheme
>(
    styles:
        | Styles<C, Props, Theme>
        | ((theme: Theme) => Styles<C, Props, undefined>)
): ((data?: Props & { theme?: Theme }) => Classes<C>) =>
    createUseStyles(styles);
