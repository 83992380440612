import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    stockTransferFormContainer: {
        padding: '1rem 0',
        margin: '0 auto',
        maxWidth: 650,
        width: '100%',
    },
    formContent: {
        padding: '2rem 2rem 0 2rem;',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    },
    buttonIcon: {
        color: theme.colors.white,
        fontSize: '0.8rem',
        marginLeft: '0.5rem',
    },
    orderSelectorContainer: {
        padding: '0 2.5rem',
    },
}));
