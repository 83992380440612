import { BLOB_CLIENT_URI } from '../environment-variables';
import {
    BlobServiceClient,
    ContainerClient,
    BlockBlobClient,
} from '@azure/storage-blob';

export interface FileToDownload {
    name: string;
    token: string;
}

export interface FileToUpload {
    file: File;
    token: string;
}

export type blobContainer = 'booking-attachments';

const getBlobClient = (
    containerName: blobContainer,
    fileInfo: { name: string; token: string },
    folderPath?: string
) => {
    const blobServiceClient = new BlobServiceClient(
        `${BLOB_CLIENT_URI}${fileInfo.token}`
    );

    const containerClient: ContainerClient =
        blobServiceClient.getContainerClient(containerName);

    const blobClient: BlockBlobClient = containerClient.getBlockBlobClient(
        folderPath ? `${folderPath}\\${fileInfo.name}` : fileInfo.name
    );

    return blobClient;
};

const downloadFileFromBlobStorage = async (
    containerName: blobContainer,
    fileToDownload: FileToDownload,
    folderPath?: string
) => {
    const blobClient = getBlobClient(containerName, fileToDownload, folderPath);

    const response = await blobClient.download();
    return response.blobBody as Promise<Blob>;
};

const uploadFilesToBlobStorage = async (
    containerName: blobContainer,
    filesToUpload: FileToUpload[],
    folderPath?: string
) => {
    let success = true;
    const uploads = filesToUpload.map(async (uploadFile) => {
        const blobClient = getBlobClient(
            containerName,
            { name: uploadFile.file.name, token: uploadFile.token },
            folderPath
        );

        await blobClient.upload(uploadFile.file, uploadFile.file.size);
    });

    await Promise.all(uploads).catch((error) => {
        console.warn(error);
        success = false;
    });

    if (!uploads?.length) {
        success = false;
    }

    return success;
};

export { uploadFilesToBlobStorage, downloadFileFromBlobStorage, getBlobClient };
