import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    verticallyCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
        width: '100%',
    },
});
