import * as React from 'react';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';

interface CheckOutModalProps {
    booking: Booking;
    showModal: boolean;
    onCheckOut(): void;
    onClose(): void;
}

const CheckOutModal: React.FC<CheckOutModalProps> = ({
    booking,
    showModal,
    onCheckOut,
    onClose
}) => {
    const requiresCheckOut = booking.requiresCheckIn;

    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>
                    {requiresCheckOut ? 'Check Out' : 'Complete'} Booking{' '}
                    {booking.reference}
                </h5>
            </ModalHeader>
            <ModalBody>
                Are you sure you want to{' '}
                {requiresCheckOut ? 'check out' : 'complete'} booking{' '}
                {booking.reference} ?
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button styleType="primary" onClick={onCheckOut}>
                    {requiresCheckOut ? 'Check Out' : 'Complete'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CheckOutModal;
