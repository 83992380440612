export enum LoadType {
    TrailerStart = 'Trailer Start',
    StockTrailer = 'Stock Trailer',
    TopUp = 'Top-Up',
    FinalTopUp = 'Final Top-Up',
    FullLoad = 'Full Load',
    Tunnel = 'Tunnel',
    ThirdParty = 'Third Party',
    Internal = 'Internal'
}
