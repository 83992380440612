import React from 'react';
import Popup from 'reactjs-popup';
import { theme } from '../../theme';

const contentStyle: React.CSSProperties = {
    background: theme.colors.lightBlack,
    color: theme.colors.white,
    width: 'auto',
    padding: '0.1rem 1rem',
    marginLeft: '0.2rem'
};

const arrowStyle: React.CSSProperties = { color: theme.colors.lightBlack };

interface NavTooltipProps {
    navExpanded: boolean;
    tooltipText?: string;
    children(props: {
        ref: React.Ref<JSX.Element>;
        props?: object;
    }): JSX.Element;
}

const NavTooltip: React.FC<NavTooltipProps> = ({
    children,
    navExpanded,
    tooltipText
}) => {
    //eslint-disable-next-line react/display-name
    const TooltipTrigger = React.forwardRef<JSX.Element, object>((props, ref) =>
        children({ ref, props })
    );

    return (
        <Popup
            trigger={<TooltipTrigger />}
            position="right center"
            on={['hover', 'focus']}
            disabled={navExpanded}
            {...{ contentStyle, arrowStyle }}
        >
            <span> {tooltipText} </span>
        </Popup>
    );
};

export default NavTooltip;
