import * as React from 'react';
import classnames from 'classnames';

const NavItem: React.FC<React.HTMLAttributes<HTMLLIElement>> = ({
    children,
    className,
    ...rest
}) => (
    <li className={classnames('nav-item', className)} {...rest}>
        {children}
    </li>
);

export default NavItem;
