import * as React from 'react';
import { useState } from 'react';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';
import { apiPost } from '../../../services/api';
import LoadingIndicator from '../../loading/LoadingIndicator';

interface UndoCheckInModalProps {
    booking: Booking;
    showModal: boolean;
    onUndoCheckIn(): void;
    onClose(): void;
}

const UndoCheckInModal: React.FC<UndoCheckInModalProps> = ({
    booking,
    showModal,
    onUndoCheckIn,
    onClose
}) => {
    const [undoingCheckIn, setUndoingCheckIn] = useState(false);

    const handleUndoCheckInClicked = async () => {
        setUndoingCheckIn(true);
        await apiPost(`Booking/${booking.id}/UndoCheckIn`);
        setUndoingCheckIn(false);
        onUndoCheckIn();
    };

    const handleRequestClose = () => {
        if (undoingCheckIn) {
            return;
        }
        onClose();
    };

    return (
        <Modal isOpen={showModal} onRequestClose={handleRequestClose}>
            <ModalHeader>
                <h5>Undo Check In {booking.reference}</h5>
            </ModalHeader>
            {undoingCheckIn ? (
                <LoadingIndicator text="undoing check in" />
            ) : (
                <>
                    <ModalBody>
                        Are you sure you want to undo the check in of booking{' '}
                        {booking.reference}
                    </ModalBody>
                    <ModalFooter>
                        <Button styleType="secondary" onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            styleType="danger"
                            onClick={handleUndoCheckInClicked}
                        >
                            Undo
                        </Button>
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};

export default UndoCheckInModal;
