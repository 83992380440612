import { addHours, endOfDay, startOfDay } from 'date-fns';
import { BookingType } from '../models/bookings/BookingType';
import { LoadingResource } from '../models/bookings/LoadingResource';
import TimeSlot, {
    TimeSlotUnavailableReason
} from '../models/timeSlot/TimeSlot';
import { TrailerType } from '../models/trailers/TrailerType';

const TIME_SLOT_INTERVAL_HOURS = 1;

const createTimeSlotGenerator = (interval: number, duration: number) =>
    function* getTimeSlots(from: Date, to: Date) {
        for (let start = from; start < to; start = addHours(start, interval)) {
            yield {
                start,
                end: addHours(start, duration)
            };
        }
    };

const getTimeSlotsForDay = (
    selectedDate: Date,
    intervalHours: number,
    duration: number
) => {
    const generator = createTimeSlotGenerator(intervalHours, duration);
    const availableTimeSlots = generator(
        startOfDay(selectedDate),
        endOfDay(selectedDate)
    );
    return Array.from(availableTimeSlots) as TimeSlot[];
};

const canOverrideTimeSlot = (timeSlot: TimeSlot) => {
    const overrideableReasons: TimeSlotUnavailableReason[] = [
        'Too Many Bookings',
        'Required Resource Unavailable',
        'No Booking Period'
    ];
    return timeSlot.unavailableReasons.every(reason =>
        overrideableReasons.includes(reason)
    );
};

const getTimeSlotsOverPeriodUrl = (options: {
    bookingType: BookingType;
    checkResourceAvailability: boolean;
    duration: number | string;
    excludedBookingIds: string[];
    utcStart: string;
    utcEnd: string;
    resources: LoadingResource[];
    store: number | string;
    trailerType: TrailerType | null;
}) => {
    const resourceString = options.resources.length
        ? `${options.resources.map(r => `RequiredResources=${r}`).join('&')}`
        : null;

    const excludedBookingIdString = options.excludedBookingIds.length
        ? `${options.excludedBookingIds
              .map(e => `ExcludedBookings=${e}`)
              .join('&')}`
        : null;

    const trailerTypeString = options.trailerType
        ? `TrailerType=${options.trailerType}`
        : null;

    const checkResourceAvailabilityString = options.checkResourceAvailability
        ? `CheckResourceAvailability=${options.checkResourceAvailability}`
        : null;

    const urlQueryStrings = [
        trailerTypeString,
        resourceString,
        excludedBookingIdString,
        checkResourceAvailabilityString
    ];

    const urlQueryString = urlQueryStrings.some(s => !!s)
        ? `?${urlQueryStrings.filter(s => !!s).join('&')}`
        : '';

    return `Store/${options.store}/TimeSlots/From/${options.utcStart}/To/${options.utcEnd}/ForBookingType/${options.bookingType}/ForDuration/${options.duration}${urlQueryString}`;
};

export {
    TIME_SLOT_INTERVAL_HOURS,
    canOverrideTimeSlot,
    getTimeSlotsForDay,
    getTimeSlotsOverPeriodUrl
};
