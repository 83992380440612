import classnames from 'classnames';
import * as React from 'react';
import Icon from '../../../../components/common/Icon';
import StoreSelectorPills, {
    StoreSelectorOption,
} from '../../../../components/common/StoreSelectorPills';
import Button from '../../../../lib/bootstrap-ui/Button';
import { routeFor, RouteKey } from '../../../../Routes/routes';
import { useClasses } from './LoadingTableActionContainer.styles';

interface LoadTableActionContainerProps {
    actionsToDisplay: Action[];
    activeFilters?: boolean;
    storeOption: StoreSelectorOption;
    storeOptions?: StoreSelectorOption[];
    createNoIodTransferFeature?: boolean;
    onStoreOptionChange?(storeOption: StoreSelectorOption): void;
    onFilterClicked?(): void;
    onFilterReset?(): void;
}

export interface Action {
    title: ActionType;
    disabled?: boolean;
}

export type ActionType =
    | 'add'
    | 'storeSelector'
    | 'progressSummary'
    | 'workQueue'
    | 'workHistory'
    | 'filters';

const actionIsDisabled = (actionsToDisplay: Action[], actionType: ActionType) =>
    actionsToDisplay.find((action) => action.title === actionType)?.disabled;

const actionsToDisplayIncludes = (
    actionsToDisplay: Action[],
    actionType: ActionType
) => actionsToDisplay.some((action) => action.title === actionType);

const LoadTableActionContainer: React.FC<LoadTableActionContainerProps> = ({
    actionsToDisplay,
    activeFilters,
    storeOption,
    storeOptions,
    createNoIodTransferFeature,
    onStoreOptionChange,
    onFilterClicked,
    onFilterReset,
}) => {
    const classes = useClasses();
    return (
        <div className={classes.actionContainer}>
            <div className={classes.buttonContainer}>
                {actionsToDisplayIncludes(actionsToDisplay, 'storeSelector') &&
                    storeOption &&
                    onStoreOptionChange && (
                        <StoreSelectorPills
                            onStoreOptionChange={onStoreOptionChange}
                            active={storeOption}
                            options={storeOptions}
                            className={classnames(classes.navSection)}
                        />
                    )}
                {actionsToDisplayIncludes(actionsToDisplay, 'filters') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Filter Results"
                            onClick={(
                                e: React.MouseEvent<
                                    HTMLButtonElement,
                                    MouseEvent
                                >
                            ) => {
                                e.preventDefault();
                                onFilterClicked && onFilterClicked();
                            }}
                            styleType="link"
                            disabled={
                                actionsToDisplay.find(
                                    (x) => x.title === 'filters'
                                )?.disabled
                            }
                        >
                            <Icon
                                type={activeFilters ? 'filter-solid' : 'filter'}
                            />
                        </Button>
                        {activeFilters && (
                            <Button
                                className={classes.button}
                                title="Remove Filter"
                                onClick={onFilterReset}
                                styleType="link"
                                disabled={
                                    actionsToDisplay.find(
                                        (x) => x.title === 'filters'
                                    )?.disabled
                                }
                            >
                                <Icon type="filter-clear" />
                            </Button>
                        )}
                    </span>
                )}
                {actionsToDisplayIncludes(
                    actionsToDisplay,
                    'progressSummary'
                ) && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Progress Summary"
                            element="Link"
                            to={routeFor(RouteKey.StoreProgressSummary)({
                                store: storeOption,
                            })}
                            styleType="link"
                            disabled={actionIsDisabled(
                                actionsToDisplay,
                                'progressSummary'
                            )}
                        >
                            <Icon type={'tasks-alt'} />
                        </Button>
                    </span>
                )}
                {actionsToDisplayIncludes(actionsToDisplay, 'workQueue') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Work Queue"
                            element="Link"
                            to={routeFor(RouteKey.StoreWorkQueue)({
                                store: storeOption,
                            })}
                            styleType="link"
                            disabled={actionIsDisabled(
                                actionsToDisplay,
                                'workQueue'
                            )}
                        >
                            <Icon type={'truck-loading'} />
                        </Button>
                    </span>
                )}
                {actionsToDisplayIncludes(actionsToDisplay, 'workHistory') && (
                    <span className={classes.navSection}>
                        <Button
                            className={classes.button}
                            title="Work History"
                            element="Link"
                            to={routeFor(RouteKey.StoreWorkHistory)({
                                store: storeOption,
                            })}
                            styleType="link"
                            disabled={actionIsDisabled(
                                actionsToDisplay,
                                'workHistory'
                            )}
                        >
                            <Icon type={'history'} />
                        </Button>
                    </span>
                )}
                {actionsToDisplayIncludes(actionsToDisplay, 'add') &&
                    createNoIodTransferFeature && (
                        <span className={classes.navSection}>
                            <Button
                                className={classes.button}
                                title="Create Stock Transfer"
                                element="Link"
                                to={routeFor(RouteKey.CreateStockTransfer)()}
                                styleType="link"
                                disabled={actionIsDisabled(
                                    actionsToDisplay,
                                    'add'
                                )}
                            >
                                <Icon type="plus" />
                            </Button>
                        </span>
                    )}
            </div>
        </div>
    );
};

export default LoadTableActionContainer;
