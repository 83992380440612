import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { RouteKey, routeFor } from '../../Routes/routes';
import BookingSummaryContainer from '../../containers/bookings/view/summary/BookingSummaryContainer';
import Page from '../common/Page';

const BookingSummary: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <BookingSummaryContainer
                id={id!}
                type="booking"
                onContinue={() => navigate(routeFor(RouteKey.Bookings)())}
            />
        </Page>
    );
};

export default BookingSummary;
