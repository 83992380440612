import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    noDataFoundMessage: {
        fontWeight: 100,
        color: theme.colors.darkGrey,
    },
    smallText: {
        fontSize: '0.75rem',
        margin: '0.5rem 0 1rem 0',
    },
    mediumText: {
        fontSize: '1rem',
        margin: '1rem 0 1rem 0',
    },
    largeText: {
        fontSize: '1.25rem',
        margin: '1.5rem 0 1rem 0',
    },
    noDataFoundIcon: {
        color: '#bdd7f3',
        '--fa-primary-color': '#3f98f8',
        '--fa-primary-opacity': 1,
        '--fa-secondary-color': '#e9ecef',
        '--fa-secondary-opacity': 1,
    },
    errorFetchingDataIcon: {
        color: theme.colors.error,
    },
    smallIcon: {
        fontSize: '5rem',
    },
    mediumIcon: {
        fontSize: '7.5rem',
    },
    largeIcon: {
        fontSize: '10rem',
    },
}));
