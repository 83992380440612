import classnames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../../helpers/styles';
import Grid, { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../../models/bookings/Booking';
import { ThirdPartyCollectionOrder } from '../../../../models/thirdPartyCollections/ThirdPartyCollectionOrder';
import { bookingTypeHasAssociatedLoad } from '../../../../services/bookingService';
import { getTrailerTypeLabelForBooking } from '../../../../services/trailerService';
import SingleColumnRow from '../../../common/SingleColumnRow';
import BookingOrderTable from '../../../load/BookingOrderTable';
import LoadingIndicator from '../../../loading/LoadingIndicator';
import BookingSummaryRow from './BookingSummaryRow';

const useClasses = buildClassesHook({
    standardSummaryFieldsContainer: {
        '& > div': {
            marginBottom: 10,
        },
    },
    bookingSummaryContainer: {
        padding: '0 0.5rem',
        margin: 0,
    },
    bookingSummaryRow: {
        marginBottom: '15px',
    },
    summaryRowData: {
        maxHeight: 100,
        overflow: 'auto',
    },
    bookingSummaryHeader: {
        marginBottom: '1em',
        textAlign: 'center',
    },
});

interface BookingSummaryModalCardProps {
    booking: Booking;
    thirdPartyCollectionOrders?: ThirdPartyCollectionOrder[] | null;
    onContinue?(): void;
}

const BookingSummaryModalCard: React.FC<BookingSummaryModalCardProps> = ({
    booking,
    thirdPartyCollectionOrders,
}) => {
    const classes = useClasses();

    const labelColumnSize = 5;

    return !booking ? (
        <LoadingIndicator />
    ) : (
        <Grid className={classnames(classes.bookingSummaryContainer)} fluid>
            <Row justify="center">
                <Column>
                    <h5 className={classes.bookingSummaryHeader}>
                        {booking.bookingType &&
                            (booking.bookingType !== 'Delivery and Collection'
                                ? 'Booking Information'
                                : 'Delivery Information')}
                    </h5>
                    <Row className={classes.standardSummaryFieldsContainer}>
                        {booking.customerReference && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Customer Reference"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {booking.customerReference}
                                </BookingSummaryRow>
                            </Column>
                        )}
                        {booking.customerName && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Customer Name"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {booking.customerName}
                                </BookingSummaryRow>
                            </Column>
                        )}

                        {booking.haulierName && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Haulier Name"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {booking.haulierName}
                                </BookingSummaryRow>
                            </Column>
                        )}

                        {booking.productCode && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Product Code"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {booking.productCode}
                                </BookingSummaryRow>
                            </Column>
                        )}

                        {booking.supplierCode && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Supplier Code"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {booking.supplierCode}
                                </BookingSummaryRow>
                            </Column>
                        )}

                        {booking.trailerType && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Load Type"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {getTrailerTypeLabelForBooking(booking)}
                                </BookingSummaryRow>
                            </Column>
                        )}

                        {!!booking.resources.length && (
                            <Column size={6}>
                                <BookingSummaryRow
                                    dataColumnClassName={classes.summaryRowData}
                                    header="Resources"
                                    labelColumnSize={labelColumnSize}
                                >
                                    {booking.resources.map((r, i) => (
                                        <div key={i} className={'resourceItem'}>
                                            {r}
                                        </div>
                                    ))}
                                </BookingSummaryRow>
                            </Column>
                        )}
                    </Row>
                    {bookingTypeHasAssociatedLoad(booking.bookingType) && (
                        <>
                            <hr />
                            <h5 className={classes.bookingSummaryHeader}>
                                {booking.bookingType &&
                                    (booking.bookingType === 'Internal'
                                        ? 'Order Information'
                                        : 'Collection Information')}
                            </h5>
                            <BookingOrderTable
                                thirdPartyCollectionOrders={
                                    thirdPartyCollectionOrders
                                }
                            />
                            {booking.load && (
                                <Row>
                                    <Column size={5}>
                                        <BookingSummaryRow
                                            dataColumnClassName={
                                                classes.summaryRowData
                                            }
                                            header="Total Pallets"
                                        >
                                            {booking.load.totalPalletSpaces}
                                        </BookingSummaryRow>
                                    </Column>
                                </Row>
                            )}
                        </>
                    )}

                    {booking.bookingInstructions && (
                        <>
                            <hr />
                            <SingleColumnRow className={classes.summaryRowData}>
                                {booking.bookingInstructions}
                            </SingleColumnRow>
                        </>
                    )}
                </Column>
            </Row>
        </Grid>
    );
};

export default BookingSummaryModalCard;
