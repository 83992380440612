import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    container: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    removedMessage: {
        width: 220,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});
