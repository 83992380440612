import BookingFormDetailsContainer from '../containers/bookings/bookingForm/BookingFormDetailsContainer';
import BookingFormStoreContainer from '../containers/bookings/bookingForm/BookingFormStoreContainer';
import BookingFormOrderContainer from '../containers/bookings/bookingForm/BookingFormOrderContainer';
import BookingFormTimeSlotContainer from '../containers/bookings/bookingForm/BookingFormTimeSlotContainer';
import BookingFormResourceContainer from '../containers/bookings/bookingForm/BookingFormResourceContainer';
import RecurringBookingDurationContainer from '../containers/bookings/bookingForm/RecurringBookingDurationContainer';
import RecurringBookingStoreContainer from '../containers/bookings/bookingForm/RecurringBookingStoreContainer';
import RecurringBookingLoadTypeContainer from '../containers/bookings/bookingForm/RecurringBookingLoadTypeContainer';
import RecurringBookingScheduleContainer from '../containers/bookings/bookingForm/RecurringBookingScheduleContainer';
import RecurringBookingDetailContainer from '../containers/bookings/bookingForm/RecurringBookingDetailContainer';
import RecurringBookingEndDateContainer from '../containers/bookings/bookingForm/RecurringBookingEndDateContainer';
import { FormStep } from '../models/bookings/BookingFormStep';
import { CreateBooking } from '../models/bookings/BookingToAdd';
import { BookingType } from '../models/bookings/BookingType';
import { RecurringBookingToAdd } from '../models/bookings/RecurringBookingToAdd';

const StandardBookingFormSteps: FormStep<CreateBooking>[] = [
    {
        component: BookingFormOrderContainer,
        validator: (booking: CreateBooking) =>
            !!booking.trailerType &&
            !!booking.duration &&
            !booking.invalidOrderSelected
    },
    {
        component: BookingFormTimeSlotContainer,
        validator: (booking: CreateBooking) =>
            !!booking.selectedTimeSlot?.start &&
            !!booking.selectedTimeSlot?.end,
    },
    {
        component: BookingFormDetailsContainer,
        validator: (booking: CreateBooking) => !!booking.customerName,
    },
];

const InternalFormSteps: FormStep<CreateBooking>[] = [
    {
        component: BookingFormOrderContainer,
        validator: (booking: CreateBooking) =>
            !!booking.duration &&
            !booking.invalidOrderSelected &&
            (!!booking.resources?.length || booking.noResourcesSelected) ,
    },
    {
        component: BookingFormTimeSlotContainer,
        validator: (booking: CreateBooking) =>
            !!booking.selectedTimeSlot?.start &&
            !!booking.selectedTimeSlot?.end,
    },
    {
        component: BookingFormDetailsContainer,
        validator: (booking: CreateBooking) =>
            booking.orders.length > 0 || !!booking.customerName,
    },
];

const NoBookingsFormSteps: FormStep<CreateBooking>[] = [
    {
        component: BookingFormResourceContainer,
        validator: (booking: CreateBooking) => !!booking.duration,
    },
    {
        component: BookingFormTimeSlotContainer,
        validator: (booking: CreateBooking) =>
            !!booking.selectedTimeSlot?.start &&
            !!booking.selectedTimeSlot?.end,
    },
    {
        component: BookingFormDetailsContainer,
        validator: () => true,
    },
];

const ResourceFormSteps: FormStep<CreateBooking>[] = [
    {
        component: BookingFormResourceContainer,
        validator: (booking: CreateBooking) =>
            !!booking.resources?.length && !!booking.duration,
    },
    {
        component: BookingFormTimeSlotContainer,
        validator: (booking: CreateBooking) =>
            !!booking.selectedTimeSlot?.start &&
            !!booking.selectedTimeSlot?.end,
    },
    {
        component: BookingFormDetailsContainer,
        validator: () => true,
    },
];

const RecurringFormSteps: FormStep<RecurringBookingToAdd>[] = [
    {
        component: RecurringBookingLoadTypeContainer,
        validator: (booking: RecurringBookingToAdd) => !!booking.duration,
    },
    {
        component: RecurringBookingScheduleContainer,
        validator: (booking: RecurringBookingToAdd) =>
            !!booking.recurrencePattern && !!booking.startDate,
    },
    {
        component: RecurringBookingEndDateContainer,
        validator: (booking: RecurringBookingToAdd) =>
            !!booking.startDate &&
            ((!!booking.endDate &&
                new Date(new Date(booking.endDate).toDateString()) >
                    new Date(new Date(booking.startDate).toDateString())) ||
                !booking.endDate),
    },
    {
        component: RecurringBookingDetailContainer,
        validator: (booking: RecurringBookingToAdd) => !!booking.customerName,
    },
];

const RecurringNoBookingsFormSteps: FormStep<RecurringBookingToAdd>[] = [
    {
        component: RecurringBookingDurationContainer,
        validator: (booking: RecurringBookingToAdd) => !!booking.duration,
    },
    {
        component: RecurringBookingScheduleContainer,
        validator: (booking: RecurringBookingToAdd) =>
            !!booking.recurrencePattern && !!booking.startDate,
    },
    {
        component: RecurringBookingEndDateContainer,
        validator: (booking: RecurringBookingToAdd) =>
            !!booking.startDate &&
            ((!!booking.endDate &&
                new Date(new Date(booking.endDate).toDateString()) >
                    new Date(new Date(booking.startDate).toDateString())) ||
                !booking.endDate),
    },
    {
        component: RecurringBookingDetailContainer,
        validator: () => true,
    },
];

const BookingStoreSelectorContainer: FormStep<CreateBooking>[] = [
    {
        component: BookingFormStoreContainer,
        validator: (booking: CreateBooking) => booking.store !== null,
    },
];

const RecurringBookingStoreSelectorContainer: FormStep<RecurringBookingToAdd>[] =
    [
        {
            component: RecurringBookingStoreContainer,
            validator: (booking: RecurringBookingToAdd) =>
                booking.store !== null,
        },
    ];

const RecurringBookingFormSteps = (
    bookingType: BookingType,
    showStoreSelector?: boolean
): FormStep<RecurringBookingToAdd>[] => {
    switch (bookingType) {
        case BookingType.Collection:
        case BookingType.Delivery:
            return showStoreSelector
                ? RecurringBookingStoreSelectorContainer.concat(
                      RecurringFormSteps
                  )
                : RecurringFormSteps;
        case BookingType.NoBookings:
            return showStoreSelector
                ? RecurringBookingStoreSelectorContainer.concat(
                      RecurringNoBookingsFormSteps
                  )
                : RecurringNoBookingsFormSteps;
        default:
            throw new Error(
                'No Recurring Booking Steps configured for this booking type'
            );
    }
};

const BookingFormSteps = (
    bookingType: BookingType,
    showStoreSelector?: boolean
): FormStep<CreateBooking>[] => {
    switch (bookingType) {
        case BookingType.Delivery:
        case BookingType.Collection:
        case BookingType.DeliveryAndCollection:
            return showStoreSelector
                ? BookingStoreSelectorContainer.concat(StandardBookingFormSteps)
                : StandardBookingFormSteps;
        case BookingType.Internal:
            return showStoreSelector
                ? BookingStoreSelectorContainer.concat(
                      InternalFormSteps
                  )
                : InternalFormSteps;
        case BookingType.Resource:
            return showStoreSelector
                ? BookingStoreSelectorContainer.concat(ResourceFormSteps)
                : ResourceFormSteps;
        case BookingType.NoBookings:
            return showStoreSelector
                ? BookingStoreSelectorContainer.concat(NoBookingsFormSteps)
                : NoBookingsFormSteps;
    }
};

export { BookingFormSteps, RecurringBookingFormSteps };
