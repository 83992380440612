const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout | null;
    return function(this: any, ...args: any[]) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;

        const later = () => {
            timeout = null;
            func.apply(context, args);
        };

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
    };
};

export default debounce;
