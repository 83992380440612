import { API_URI } from '../environment-variables';
import { acquireApiToken } from './authentication';
import { useState, useEffect, useCallback, useRef } from 'react';

type Params = Record<
    string,
    string | number | string[] | number[] | null | undefined
>;

interface UseDataError {
    errorMessage: string;
}

const constructHeaders = async (otherHeaders?: object) => {
    const token = await acquireApiToken();
    return new Headers({
        authorization: `Bearer ${token}`,
        ...otherHeaders
    });
};

const getValidBody = (body: unknown) => {
    if (typeof body === 'string') {
        return `"${body}"`;
    }
    if (body instanceof FormData) {
        return body;
    }

    return JSON.stringify(body);
};

const isResponseJson = response => {
    const contentType = response.headers.get('content-type');
    return contentType && contentType.indexOf('application/json') !== -1;
};

const handleResponse = (response: Response) => {
    if (isResponseJson(response)) {
        return response.json();
    }
    if (response.status === 204) {
        return Promise.resolve(null);
    }
    return response.blob();
};

const getParameterOutput = (key: string, value: any) => {
    if (!value) {
        return;
    }

    if (value instanceof Array) {
        return value.map(v => getParameterOutput(key, v)).join('&');
    }

    return `${key}=${value}`;
};

interface FetchConfig {
    doNotLogin?: boolean;
    signal?: AbortSignal;
}

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const getPostPutRequestParams = async (
    method: RequestMethod,
    body?: unknown,
    headers?: object
) => {
    const standardHeaders = {};
    if (!(body instanceof FormData)) {
        standardHeaders['Content-Type'] = 'application/json';
    }

    const constructedHeaders = await constructHeaders({
        ...standardHeaders,
        ...headers
    });

    const params: RequestInit = {
        headers: constructedHeaders,
        method
    };
    if (body !== null && typeof body !== 'undefined') {
        params.body = getValidBody(body);
    }

    return params;
};

const apiRequest = async (
    url: string,
    params: RequestInit,
    config?: FetchConfig
): Promise<any> => {
    const requestParams = { ...params, signal: config && config.signal };
    const response = await fetch(API_URI + url, requestParams);
    if (response.ok) {
        return handleResponse(response);
    }
    const errorText = isResponseJson(response)
        ? await response.json()
        : await response.text();
    return Promise.reject({ status: response.status, message: errorText });
};

const apiGet = async (
    url: string | null,
    parameters?: Params,
    config?: FetchConfig
) => {
    if (url === null) {
        return new Promise<null>(resolve => resolve(null));
    }

    if (parameters) {
        if (url.indexOf('?') <= -1) {
            url += '?';
        } else {
            url += '&';
        }

        url +=
            Object.keys(parameters)
                .filter(k => !!parameters[k])
                ?.map(k => getParameterOutput(k, parameters[k]))
                .join('&') || '';
    }

    const headers = await constructHeaders();

    const params: RequestInit = {
        headers,
        method: 'GET'
    };

    return await apiRequest(url, params, config);
};

const apiGetMany = (...urls: (string | null)[]) => {
    const promises = [] as Promise<any>[];
    urls.forEach(u => promises.push(apiGet(u)));
    return Promise.all(promises);
};

const apiPut = async (
    url: string,
    body?: object,
    headers?: object,
    config?: FetchConfig
) => {
    const requestParams = await getPostPutRequestParams('PUT', body, headers);
    return await apiRequest(url, requestParams, config);
};

const apiPatch = async (
    url: string,
    body?: object,
    headers?: object,
    config?: FetchConfig
) => {
    const requestParams = await getPostPutRequestParams('PATCH', body, headers);
    return await apiRequest(url, requestParams, config);
};

const apiPost = async (
    url: string,
    body?: unknown,
    headers?: object,
    config?: FetchConfig
) => {
    const requestParams = await getPostPutRequestParams('POST', body, headers);
    return await apiRequest(url, requestParams, config);
};

const apiDelete = async (url: string, config?: FetchConfig) => {
    const headers = await constructHeaders();
    const params: RequestInit = {
        headers,
        method: 'DELETE'
    };
    return await apiRequest(url, params, config);
};

const useData = <T>(
    url: string | null,
    params?: Params
): [T | null, boolean, () => Promise<void>, UseDataError | null] => {
    const [data, setData] = useState<T | null>(null);
    const [activeRequests, setActiveRequests] = useState(0);
    const [error, setError] = useState<UseDataError | null>(null);

    const lastActiveRequestController = useRef<AbortController | null>();
    const dependencies = params ? [url, ...Object.values(params)] : [url];

    const getData = useCallback(
        () => {
            const removeRequest = () => setActiveRequests(prev => prev - 1);

            if (lastActiveRequestController.current) {
                lastActiveRequestController.current.abort();
            }
            setActiveRequests(prev => prev + 1);

            const controller = new AbortController();
            const signal = controller.signal;

            lastActiveRequestController.current = controller;

            const promise = apiGet(url, params, { signal })
                .then(result => {
                    removeRequest();
                    if (!signal.aborted) {
                        setError(null);
                        setData(result);
                    }
                })
                .catch(err => {
                    removeRequest();
                    // Avoid showing an error message if the fetch was aborted
                    if (err.name !== 'AbortError') {
                        setError({ errorMessage: err.message });
                    }
                });

            return [promise, controller] as [Promise<void>, AbortController];
        },
        // eslint-disable-next-line
        dependencies
    );

    useEffect(() => {
        if (url) {
            const [, controller] = getData();
            return () => {
                controller.abort();
            };
        }
    }, [url, getData]);

    const refreshFunction = () => {
        const [promise] = getData();
        return promise;
    };

    return [data, activeRequests > 0, refreshFunction, error];
};

export {
    apiGet,
    apiPost,
    apiPatch,
    apiPut,
    apiDelete,
    apiGetMany,
    useData,
    constructHeaders
};
