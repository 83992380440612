import React from 'react';

import classnames from 'classnames';
import { useClasses } from './NavlinkArea.styles';

export interface NavlinkAreaProps {
    navExpanded: boolean;
    horizontal?: boolean;
    containsParentItem?: boolean;
}

const NavlinkArea: React.FC<React.PropsWithChildren<NavlinkAreaProps>> = (
    props
) => {
    const { navExpanded, containsParentItem = false, children } = props;

    const classes = useClasses(props);

    return (
        <div
            className={classnames(classes.navlinkArea, {
                [classes.navlinkAreaExpanded]: navExpanded,
                [classes.navlinkContainer]: containsParentItem,
            })}
        >
            {children}
        </div>
    );
};

export default NavlinkArea;
