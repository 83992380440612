import * as React from 'react';
import { Load } from '../../../models/loads/Load';
import PiggybackTransferOrOrderToLoadModal from './PiggybackTransferOrOrderToLoadModal';

interface PiggybackTransferToLoadModalProps {
    load: Load;
    isOpen: boolean;
    onClose(): void;
}

const PiggybackTransferToLoadModal: React.FC<PiggybackTransferToLoadModalProps> = ({
    load,
    isOpen,
    onClose
}) => (
    <PiggybackTransferOrOrderToLoadModal
        piggybackFullTransfer={true}
        load={load}
        isOpen={isOpen}
        onClose={onClose}
    />
);

export default PiggybackTransferToLoadModal;
