import * as React from 'react';
import { useEffect, useState } from 'react';
import RecurringBookingFormSummaryPanel from '../../../components/bookings/recurring/RecurringBookingFormSummaryPanel';
import TimeSlotConflictsModal from '../../../components/bookings/recurring/TimeSlotConflictsModal';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { UserRole } from '../../../helpers/userRole';
import Button from '../../../lib/bootstrap-ui/Button/Button';
import { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import Modal, { ModalBody } from '../../../lib/bootstrap-ui/Modal';
import { BookingType } from '../../../models/bookings/BookingType';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import { apiPost, useData } from '../../../services/api';
import { useCurrentUser } from '../../../services/authentication';
import { RecurringBookingFormSteps } from '../../../services/bookingFormService';
import { getDefaultBookingTrailerType } from '../../../services/bookingService';
import { formatToUtc } from '../../../services/timeStringService';
import {
    userInRole,
    userIsAdmin,
    userToAllStores,
} from '../../../services/userService';
import { useClasses } from './RecurringBookingFormContainer.styles';

interface RecurringBookingFormContainerprops {
    bookingType: BookingType;
    onBookingConfirmation(id: string): void;
}

const RecurringBookingFormContainer: React.FC<
    RecurringBookingFormContainerprops
> = ({ bookingType, onBookingConfirmation }) => {
    const classes = useClasses();

    const user = useCurrentUser();
    const isAdminUser = userIsAdmin(user);

    const allUserStores = userToAllStores(user);
    const store = !user || !allUserStores?.length ? 1 : allUserStores[0];
    const overrideBookingConflicts =
        isAdminUser || userInRole(user)(UserRole.ShiftManager);
    const showStoreSelector = isAdminUser || allUserStores.length > 1;

    const [recurringBooking, setRecurringBooking] =
        useState<RecurringBookingToAdd>({
            id: null,
            bookingInstructions: '',
            bookingType: bookingType,
            customerName: null,
            duration: 0,
            recurrencePattern: null,
            startDate: null,
            endDate: null,
            resources: bookingType === BookingType.NoBookings ? [] : null,
            store: store,
            trailerType: getDefaultBookingTrailerType(bookingType),
            secondaryTrailerType: null,
            active: true,
            overrideStoreResource: false,
            overrideConflicts: overrideBookingConflicts,
        });

    const resourceString =
        recurringBooking.resources && recurringBooking.resources.length
            ? `${recurringBooking.resources
                  .map((r) => `?Resources=${r}`)
                  .join('&')}`
            : '';

    const [unavailableTimeSlots, loadingUnavailableTimeSlots] = useData<
        TimeSlot[]
    >(
        !!recurringBooking.store &&
            !!recurringBooking.duration &&
            !!recurringBooking.startDate &&
            !!recurringBooking.recurrencePattern &&
            !!recurringBooking.trailerType &&
            !!recurringBooking.resources
            ? `Store/${recurringBooking.store}/Recurrence/Start/${formatToUtc(
                  recurringBooking.startDate
              )}/ForDuration/${recurringBooking.duration}/ForPattern/${
                  recurringBooking.recurrencePattern
              }/ForBookingType/${recurringBooking.bookingType}/ForTrailerType/${
                  recurringBooking.trailerType
              }/Conflicts${resourceString}`
            : null
    );

    const [showResolveConflictsModal, setShowResolveConflictsModal] =
        useState(false);
    const [showCheckingConflictsModal, setShowCheckingConflictsModal] =
        useState(false);
    const [submittingBooking, setSubmittingBooking] = useState(false);
    const [step, setStep] = useState(0);

    const formSteps = RecurringBookingFormSteps(
        recurringBooking.bookingType,
        showStoreSelector
    );

    const currentFormStep = formSteps[step];
    const CurrentFormStepComponent = currentFormStep.component;

    const isLastStep = step === formSteps.length - 1;
    const isFirstStep = step === 0;

    const submitBooking = async () => {
        setSubmittingBooking(true);
        const createdBooking: RecurringBooking = await apiPost(
            'RecurringBooking',
            recurringBooking
        );
        setSubmittingBooking(false);
        onBookingConfirmation(createdBooking.id);
    };

    const handlePreviousClicked = () => {
        if (recurringBooking.endDate) {
            recurringBooking.endDate = null;
        }
        setStep(step - 1);
    };

    useEffect(
        () => {
            if (showCheckingConflictsModal) {
                setShowCheckingConflictsModal(false);
                if (unavailableTimeSlots && unavailableTimeSlots.length > 0) {
                    setShowResolveConflictsModal(true);
                } else {
                    submitBooking();
                }
            }
        },
        // eslint-disable-next-line
        [unavailableTimeSlots]
    );

    const handleRecurringBookingChange = (
        recurringBooking: RecurringBookingToAdd
    ) => {
        setRecurringBooking(recurringBooking);
    };

    const handleOverrideConflicts = () => {
        setShowResolveConflictsModal(false);
        submitBooking();
    };

    const handleOnNextClicked = async () => {
        if (isLastStep) {
            if (loadingUnavailableTimeSlots) {
                setShowCheckingConflictsModal(true);
                return;
            }

            if (unavailableTimeSlots && unavailableTimeSlots.length !== 0) {
                setShowResolveConflictsModal(true);
                return;
            }
            await submitBooking();
        } else {
            setStep(step + 1);
        }
    };

    return (
        <>
            <Row justify="center">
                <Column
                    xs={12}
                    lg={8}
                    xl={8}
                    xlOffset={2}
                    className={classes.formContainer}
                >
                    <Row>
                        <Column>
                            <CurrentFormStepComponent
                                formObject={recurringBooking}
                                onChange={handleRecurringBookingChange}
                                onConfirmation={() =>
                                    onBookingConfirmation(recurringBooking.id!)
                                }
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column className={classes.marginTop}>
                            {!isFirstStep && (
                                <Button
                                    className={classes.previousButton}
                                    onClick={handlePreviousClicked}
                                >
                                    Previous
                                </Button>
                            )}
                            <Button
                                className={classes.nextButton}
                                onClick={handleOnNextClicked}
                                disabled={
                                    submittingBooking ||
                                    !currentFormStep.validator(recurringBooking)
                                }
                            >
                                {isLastStep ? 'Submit' : 'Next'}
                            </Button>
                        </Column>
                    </Row>
                </Column>
                <Column
                    xsOrder={'first'}
                    xs={12}
                    lgOrder={'last'}
                    lg={4}
                    xl={3}
                    className={classes.summaryPanel}
                >
                    <RecurringBookingFormSummaryPanel
                        recurringBooking={recurringBooking}
                    />
                </Column>
            </Row>
            {submittingBooking && (
                <Modal isOpen={submittingBooking}>
                    <ModalBody>
                        <LoadingIndicator text="creating booking" />
                    </ModalBody>
                </Modal>
            )}
            {showCheckingConflictsModal && (
                <Modal
                    isOpen={showCheckingConflictsModal}
                    onRequestClose={() => setShowCheckingConflictsModal(false)}
                >
                    <ModalBody>
                        <LoadingIndicator text="checking for conflicts" />
                    </ModalBody>
                </Modal>
            )}
            {showResolveConflictsModal && unavailableTimeSlots && (
                <TimeSlotConflictsModal
                    unavailableTimeSlots={unavailableTimeSlots}
                    isOpen={showResolveConflictsModal}
                    allowOverride={overrideBookingConflicts}
                    onOverrideRequested={handleOverrideConflicts}
                    onCloseRequested={() => setShowResolveConflictsModal(false)}
                />
            )}
        </>
    );
};

export default RecurringBookingFormContainer;
