import * as React from 'react';
import Button from '../../lib/bootstrap-ui/Button';
import { useAuth } from '../../services/authentication';
import { useClasses } from './LoginButton.styles';

const LoginButton: React.FC = () => {
    const { login } = useAuth();
    const classes = useClasses();

    return (
        <Button onClick={login} className={classes.loginButton}>
            Login
        </Button>
    );
};

export default LoginButton;
