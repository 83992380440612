import * as React from 'react';
import { useState } from 'react';
import AttachmentsModal from '../../components/bookings/modals/AttachmentsModal';
import DeleteAttachmentModal from '../../components/bookings/modals/DeleteAttachmentModal';
import { FileToDisplay } from '../../components/common/fileUpload/FileGrid';
import saveFile from '../../components/helpers/saveFile';
import { Booking } from '../../models/bookings/Booking';
import {
    BookingAttachment,
    UploadStatus,
} from '../../models/bookings/BookingAttachment';
import {
    deleteBookingAttachment,
    downloadBookingAttachment,
    uploadBookingAttachments,
} from '../../services/bookingService';
import { getFileTypeFromFileName } from '../../services/fileService';

interface AttachmentsModalContainerProps {
    showModal: boolean;
    booking: Booking;
    onUpload(): void;
    onDelete(): void;
    onClose(): void;
}

const AttachmentsModalContainer: React.FC<AttachmentsModalContainerProps> = ({
    booking,
    showModal,
    onUpload,
    onDelete,
    onClose,
}) => {
    const [attachmentToDelete, setAttachmentToDelete] =
        useState<BookingAttachment | null>();
    const [downloadingAttachments, setDownloadingAttachments] = useState<
        string[]
    >([]);
    const [deletingAttachments, setDeletingAttachments] = useState<string[]>(
        []
    );

    const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
    const [uploadedAttachments, setUploadedAttachments] = useState<
        BookingAttachment[]
    >(booking.attachments);

    const [uploadFailure, setUploadFailure] = useState<boolean>(false);

    const handleDownloadRequested = async (fileName: string) => {
        const matchedAttachment = booking.attachments.find(
            (attachment) => attachment.fileName === fileName
        );
        if (matchedAttachment) {
            setDownloadingAttachments((prev) => [
                ...prev,
                matchedAttachment.fileName,
            ]);

            const downloadedFile = await downloadBookingAttachment(
                matchedAttachment
            );
            saveFile(downloadedFile, matchedAttachment.fileName);

            setDownloadingAttachments((prev) =>
                prev.filter((a) => a !== matchedAttachment.fileName)
            );
        }
    };

    const handleUploadRequested = async (files: File[]) => {
        const newAttachments: BookingAttachment[] = files.map((file) => ({
            fileName: file.name,
            type: getFileTypeFromFileName(file.name),
            status: UploadStatus.Pending,
        }));
        setUploadedAttachments((prev) => [
            ...prev,
            ...newAttachments.filter(
                (attachment) =>
                    !booking.attachments
                        .map((a) => a.fileName)
                        .includes(attachment.fileName)
            ),
        ]);

        setUploadingFiles((prev) => [
            ...prev,
            ...newAttachments.map((attachment) => attachment.fileName),
        ]);
        const success = await uploadBookingAttachments(booking.id, files, true);

        if (success) {
            setUploadingFiles((prev) =>
                prev.filter(
                    (fileName) =>
                        !files.map((file) => file.name).includes(fileName)
                )
            );
            onUpload();
        } else {
            setUploadingFiles([]);
        }

        setUploadFailure(!success);
    };

    const handleDeleteConfirmed = async () => {
        const attachment = attachmentToDelete;
        setAttachmentToDelete(null);
        if (attachment) {
            setDeletingAttachments([
                ...deletingAttachments,
                attachment.fileName,
            ]);

            const success = await deleteBookingAttachment(attachment);

            if (success) {
                setDeletingAttachments((prev) =>
                    prev.filter((a) => a !== attachment.fileName)
                );
                setUploadedAttachments((prev) => [
                    ...prev.filter((a) => a.fileName !== attachment.fileName),
                ]);
                onDelete();
            }
        }
    };

    const handleDeleteRequested = (fileName: string) => {
        const matchedAttachment = booking.attachments.find(
            (attachment) => attachment.fileName === fileName
        );
        setAttachmentToDelete(matchedAttachment);
    };

    const filesToDisplay: FileToDisplay[] = uploadedAttachments.map(
        (attachment) => ({
            name: attachment.fileName,
            type: getFileTypeFromFileName(attachment.fileName),
        })
    );

    return (
        <>
            <AttachmentsModal
                showModal={showModal && !attachmentToDelete}
                booking={booking}
                files={filesToDisplay}
                downloadingFileNames={downloadingAttachments}
                deletingFileNames={deletingAttachments}
                uploadingFileNames={uploadingFiles}
                uploadFailure={uploadFailure}
                onDownloadRequested={handleDownloadRequested}
                onUploadRequested={handleUploadRequested}
                onDeleteRequested={handleDeleteRequested}
                onRequestClose={onClose}
            />
            {attachmentToDelete && (
                <DeleteAttachmentModal
                    showModal={showModal && !!attachmentToDelete}
                    bookingAttachment={attachmentToDelete}
                    onDelete={handleDeleteConfirmed}
                    onClose={() => setAttachmentToDelete(null)}
                />
            )}
        </>
    );
};

export default AttachmentsModalContainer;
