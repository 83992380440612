import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import { TextArea } from '../../../lib/bootstrap-ui/Forms';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../models/bookings/Booking';

const useClasses = buildClassesHook({
    instructionsModal: {
        width: '600px',
    },
    instructionsModalBody: {
        maxHeight: '500px',
        overflow: 'auto',
    },
    instructionsTextArea: {
        marginBottom: 0,
        paddingBottom: 0,
    },
});

interface InstructionsModalProps {
    booking: Booking;
    bookingInstructions: string | null;
    showModal: boolean;
    unsavedChanges: boolean;
    onChange(instructions: string | null): void;
    onSubmit(): void;
    onClose(): void;
}

const InstructionsModal: React.FC<InstructionsModalProps> = ({
    booking,
    bookingInstructions,
    showModal,
    unsavedChanges,
    onChange,
    onSubmit,
    onClose,
}) => {
    const classes = useClasses();

    return (
        <Modal
            className={classes.instructionsModal}
            isOpen={showModal}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>{`Booking ${booking.reference} Instructions`}</h5>
            </ModalHeader>
            <ModalBody className={classes.instructionsModalBody}>
                <TextArea
                    id="BookingInstructions"
                    disabled={booking.isCheckedIn}
                    className={classes.instructionsTextArea}
                    value={bookingInstructions}
                    onChange={onChange}
                    autoConvertWhitespace
                    autoTrim
                />
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                {!booking.isCheckedIn && (
                    <Button
                        styleType="primary"
                        disabled={!unsavedChanges}
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default InstructionsModal;
