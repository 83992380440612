import * as React from 'react';
import { useEffect, useState } from 'react';

import ErrorModal from '../../../components/common/ErrorModal';
import Icon from '../../../components/common/Icon';
import LoadStatus from '../../../components/load/table/view/LoadStatus';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import LoadTimeRemaining from '../../../components/time/LoadTimeRemaining';
import Button from '../../../lib/bootstrap-ui/Button';
import { Load } from '../../../models/loads/Load';
import { useData } from '../../../services/api';
import { useClasses } from './LoadSummaryContainer.styles';
import LoadSummaryRow from './LoadSummaryRow';

interface LoadSummaryContainerProps {
    id: string;
    onContinue(): void;
}

const LoadSummaryContainer: React.FC<LoadSummaryContainerProps> = ({
    id,
    onContinue,
}) => {
    const classes = useClasses();

    const [load, loading, , fetchError] = useData<Load>(`Load/${id}`);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (fetchError) {
            setError(fetchError.errorMessage);
        }
    }, [fetchError]);

    const handleErrorDismiss = () => {
        setError(null);
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <div className={classes.LoadSummaryContainer}>
            {!load && !error ? (
                <div>
                    <h4>No Matching Load</h4>
                </div>
            ) : (
                <>
                    <h4>Part Load Summary</h4>
                    {!!load && (
                        <>
                            <div className={classes.ContentContainer}>
                                <LoadSummaryRow header="PL Ref.">
                                    {load.loadName.toUpperCase()}
                                </LoadSummaryRow>
                                <hr />
                                <LoadSummaryRow header="Source">
                                    {`Store ${load.sourceStore}`}
                                </LoadSummaryRow>
                                <LoadSummaryRow header="Destination">
                                    {load.destination}
                                </LoadSummaryRow>
                                <LoadSummaryRow header="Load Remaining">
                                    <LoadTimeRemaining
                                        loadByTime={load.loadByTime}
                                        completedTime={load.completedAt}
                                        useColors
                                    />
                                </LoadSummaryRow>
                                <hr />
                                <LoadSummaryRow header="Load Type">
                                    {load.loadType}
                                </LoadSummaryRow>
                                <LoadSummaryRow header="Pallets">
                                    {load.totalPalletSpacesToPick}
                                </LoadSummaryRow>
                                <hr />
                                <LoadSummaryRow header="Status">
                                    <LoadStatus load={load} disableLinks />
                                </LoadSummaryRow>
                                <hr />
                            </div>
                            <div className={classes.ButtonContainer}>
                                <Button onClick={onContinue}>
                                    Continue
                                    <Icon
                                        className={classes.ButtonIcon}
                                        type="chevron-right"
                                    />
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}

            {error && (
                <ErrorModal showModal={!!error} onClose={handleErrorDismiss} />
            )}
        </div>
    );
};

export default LoadSummaryContainer;
