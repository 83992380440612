import { buildClassesHook } from '../../../helpers/styles';
import { theme } from '../../../theme';

export const datePickerTooltip = {
    minWidth: 350,
    borderRadius: 5,
    zIndex: 3000,
    width: 'max-content',
};

export const useClasses = buildClassesHook({
    datePickerTooltip,
    datePickerTextInput: {
        '& input::-webkit-inner-spin-button': {
            display: 'none',
            WebkitAppearance: 'none',
        },
        '& input::-webkit-calendar-picker-indicator': {
            display: 'none',
            WebkitAppearance: 'none',
        },
    },
    removeValueButton: {
        position: 'absolute',
        right: '1.3rem',
        top: '1.95rem',
        padding: '0.25rem',
        color: theme.colors.mediumDarkGrey,
        '&:hover': {
            color: theme.colors.darkGrey,
        },
    },
});
