import * as React from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../../models/bookings/Booking';

interface ConvertToDeliveryModalProps {
    booking: Booking;
    showModal: boolean;
    disableConvertButton?: boolean;
    onConvertToDelivery(): void;
    onClose(): void;
}

const ConvertToDeliveryModal: React.FC<ConvertToDeliveryModalProps> = ({
    booking,
    showModal,
    disableConvertButton = false,
    onConvertToDelivery,
    onClose
}) => {
    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Convert Booking {booking.reference} to Delivery</h5>
            </ModalHeader>
            <ModalBody>
                Are you sure you want to convert booking {booking.reference} to
                delivery?
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    styleType="danger"
                    disabled={disableConvertButton}
                    onClick={onConvertToDelivery}
                >
                    Convert
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConvertToDeliveryModal;
