import * as React from 'react';
import { ModalType } from '../../../../containers/admin/thirdPartyManagement/ThirdPartyManagementContainer';
import { buildClassesHook } from '../../../../helpers/styles';
import Button from '../../../../lib/bootstrap-ui/Button';
import ThirdPartyValue from '../../../../models/thirdPartyValues/ThirdPartyValue';
import { theme } from '../../../../theme';
import Icon from '../../../common/Icon';

const useClasses = buildClassesHook({
    contentCenterColumn: {
        verticalAlign: 'middle',
        textAlign: 'center',
        '& Button': {
            padding: '0',
            margin: '0 0.5rem'
        }
    },
    deleteButton: {
        color: theme.colors.error,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.errorDark
        }
    },
    iconButton: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark
        }
    }
});

interface ManagementTableRowProps {
    thirdPartyValue: ThirdPartyValue;
    onModalSelected(value: ThirdPartyValue, action: ModalType): void;
}

const ManagementTableRow: React.FC<ManagementTableRowProps> = ({
    thirdPartyValue,
    onModalSelected
}) => {
    const classes = useClasses();

    return (
        <tr key={thirdPartyValue.id}>
            <td className={classes.contentCenterColumn}>
                {thirdPartyValue.value}
            </td>
            <td className={classes.contentCenterColumn}>
                <Button
                    element="button"
                    styleType="link"
                    onClick={() => onModalSelected(thirdPartyValue, 'edit')}
                >
                    <Icon className={classes.iconButton} type="pencil" />
                </Button>
                <Button
                    element="button"
                    styleType="link"
                    onClick={() => onModalSelected(thirdPartyValue, 'delete')}
                >
                    <Icon className={classes.deleteButton} type="delete" />
                </Button>
            </td>
        </tr>
    );
};

export default ManagementTableRow;
