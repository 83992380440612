import { Notification } from '.';
import {
    NewThirdPartyValueAdded,
    ThirdPartyValueUpdated
} from './ThirdPartyValue';

export const SupplierCodeAddedNotification: Notification<NewThirdPartyValueAdded> = {
    message: 'SupplierCodeAdded'
};

export const SupplierCodeRemovedNotification: Notification<number> = {
    message: 'SupplierCodeRemoved'
};

export const SupplierCodeUpdatedNotification: Notification<ThirdPartyValueUpdated> = {
    message: 'SupplierCodeUpdated'
};
