import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    autoGrid: {
        gridAutoColumns: 'max-content',
        gridAutoRows: 'max-content',
    },
    arrow: {
        fontSize: '2rem',
    },
    transportOnlyArrow: {
        fontSize: '2rem',
        color: `${theme.colors.grey}`,
    },
}));
