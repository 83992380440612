import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    pageBackground: {
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        width: '100%',
        background: 'url(/img/rb-background.png) no-repeat center center fixed',
        backgroundSize: 'cover',
    },
});
