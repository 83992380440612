import * as React from 'react';
import { useParams } from 'react-router-dom';
import LoadIssuesContainer from '../../containers/load/invalidLoadResolution/view/LoadIssuesContainer';
import Page from '../common/Page';

const LoadIssues: React.FC = () => {
    const { id } = useParams();

    return (
        <Page>
            <LoadIssuesContainer id={id!} />
        </Page>
    );
};

export default LoadIssues;
