import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
    oneDay,
    oneHour,
    oneMinute,
    oneSecond,
} from '../models/common/TimeAsMiliseconds';

const formatTimeString = (timeString: string) =>
    timeString.slice(0, timeString.lastIndexOf(':'));

const timeStringRegExp = new RegExp(
    '^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]:[0-5][0-9]$'
);

const minuteSecondRegExp = new RegExp('^([0-9]\\d*:[0-5]\\d$)');

const formatToUtc = (date: Date): string =>
    format(utcToZonedTime(date, 'UTC'), "yyyy-MM-dd'T'HH:mm:ss'Z'");

const formatToFriendlyTime = (ms: number, includeSeconds = false) => {
    const isNegative = ms < 0;
    const negativeString = isNegative ? '-' : '';
    const absoluteMs = Math.abs(ms);
    const days = Math.floor(absoluteMs / oneDay);
    const msWithoutDays = absoluteMs - days * oneDay;
    const hours = Math.floor(msWithoutDays / oneHour);
    if (days > 0) {
        return `${negativeString}${days}d, ${hours}h`;
    }
    const msWithoutDaysOrHours = msWithoutDays - hours * oneHour;
    const minutes = Math.floor(msWithoutDaysOrHours / oneMinute);
    if (hours > 0) {
        return `${negativeString}${hours}h, ${minutes}m`;
    }

    const msWithoutDaysOrHoursOrMinutes =
        msWithoutDaysOrHours - minutes * oneMinute;
    const seconds = Math.floor(msWithoutDaysOrHoursOrMinutes / oneSecond);
    if (minutes > 0) {
        return includeSeconds
            ? `${negativeString}${minutes}m, ${seconds}s`
            : `${negativeString}${minutes}m`;
    }

    if (!includeSeconds) {
        return `${minutes}m`;
    }

    if (seconds > 0) {
        return `${negativeString}${seconds}s`;
    }

    return `${seconds}s`;
};

export {
    formatToUtc,
    formatTimeString,
    formatToFriendlyTime,
    timeStringRegExp,
    minuteSecondRegExp,
};
