import { getDay, parseISO } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';

import { formatDate } from '../../../../helpers/dates';
import { getDayString, safeTrim } from '../../../../helpers/stringFunctions';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../../models/bookings/Booking';
import { EditBooking } from '../../../../models/bookings/EditBooking';
import {
    IsCustomerNameValid,
    getBookingWithExistingCustomerReference,
} from '../../../../services/bookingService';
import UnsavedChangesModal from '../../../common/UnsavedChangesModal';
import DuplicateCustomerReferenceModal from '../../modals/DuplicateCustomerReferenceModal';
import BookingDetailFields, { Property } from '../form/BookingDetailFields';
import { useClasses } from './EditModal.styles';

interface EditModalProps {
    booking: Booking;
    showModal: boolean;
    onEditConfirmed(editedBooking: EditBooking): void;
    onClose(): void;
}

const EditModal: React.FC<EditModalProps> = ({
    booking,
    showModal,
    onEditConfirmed,
    onClose,
}) => {
    const classes = useClasses();

    const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
        useState(false);
    const [bookingHaulierName, setBookingHaulierName] = useState(
        booking.haulierName
    );
    const [bookingCustomerName, setBookingCustomerName] = useState(
        booking.customerName
    );
    const [bookingProductCode, setBookingProductCode] = useState(
        booking.productCode
    );
    const [bookingSupplierCode, setBookingSupplierCode] = useState(
        booking.supplierCode
    );
    const [bookingCustomerRef, setBookingCustomerRef] = useState(
        booking.customerReference
    );
    const [bookingInstructions, setBookingInstructions] = useState(
        booking.bookingInstructions
    );

    const [
        showDuplicateCustomerReferenceModal,
        setShowDuplicateCustomerReferenceModal,
    ] = useState(false);
    const [
        duplicateCustomerReferenceBooking,
        setDuplicateCustomerReferenceBooking,
    ] = useState<Booking>();

    const unsavedChanges =
        safeTrim(booking.customerName) !== safeTrim(bookingCustomerName) ||
        safeTrim(booking.haulierName) !== safeTrim(bookingHaulierName) ||
        safeTrim(booking.productCode) !== safeTrim(bookingProductCode) ||
        safeTrim(booking.supplierCode) !== safeTrim(bookingSupplierCode) ||
        safeTrim(booking.customerReference) !== safeTrim(bookingCustomerRef) ||
        safeTrim(booking.bookingInstructions) !== safeTrim(bookingInstructions);

    const handleEditConfirmed = async () => {
        const editedBooking: EditBooking = {
            bookingInstructions: bookingInstructions,
            customerName: bookingCustomerName,
            customerReference: bookingCustomerRef,
            haulierName: bookingHaulierName,
            productCode: bookingProductCode,
            supplierCode: bookingSupplierCode,
        };

        onEditConfirmed(editedBooking);
        setShowUnsavedChangesModal(false);
        setShowDuplicateCustomerReferenceModal(false);
    };

    const checkForDuplicateCustomerReference = async () => {
        if (
            bookingCustomerRef &&
            bookingCustomerRef !== booking.customerReference
        ) {
            const bookingWithCustomerReference =
                await getBookingWithExistingCustomerReference(
                    bookingCustomerRef
                );
            if (bookingWithCustomerReference) {
                setDuplicateCustomerReferenceBooking(
                    bookingWithCustomerReference
                );
                setShowDuplicateCustomerReferenceModal(true);
                return true;
            }
        }
        return false;
    };

    const handleEditRequested = async () => {
        const isDuplicateCustomerRef =
            await checkForDuplicateCustomerReference();
        if (!isDuplicateCustomerRef) {
            handleEditConfirmed();
        }
    };

    const handleDiscardChangesClicked = () => {
        setBookingHaulierName(booking.haulierName);
        setBookingCustomerName(booking.customerName);
        setBookingInstructions(booking.bookingInstructions);
        setShowUnsavedChangesModal(false);
        onClose();
    };

    const handleRequestClose = () => {
        if (unsavedChanges) {
            setShowUnsavedChangesModal(true);
        } else {
            onClose();
        }
    };

    const handlePropertyChange =
        (property: Property) =>
        (value: string | null): void => {
            switch (property) {
                case 'customerName':
                    setBookingCustomerName(value);
                    break;
                case 'haulierName':
                    setBookingHaulierName(value);
                    break;
                case 'productCode':
                    setBookingProductCode(value);
                    break;
                case 'supplierCode':
                    setBookingSupplierCode(value);
                    break;
                case 'customerReference':
                    setBookingCustomerRef(value);
                    break;
                case 'bookingInstructions':
                    setBookingInstructions(value);
                    break;
                default:
                    throw new Error(
                        'Unexpected Property value - No handlePropertyChange case for supplied property value'
                    );
            }
        };

    const isCustomerNameValid = IsCustomerNameValid(
        bookingCustomerName,
        booking.bookingType
    );

    const isInvalid = !unsavedChanges || !isCustomerNameValid;

    const modalHeader = `Edit Booking ${booking.reference}`;
    const showEditModal =
        !showUnsavedChangesModal && !showDuplicateCustomerReferenceModal;

    return (
        <>
            <Modal
                isOpen={showModal && showEditModal}
                modalClassName={classes.editModal}
                onRequestClose={handleRequestClose}
            >
                <ModalHeader>
                    <h5>{modalHeader}</h5>
                    <h5 style={{ display: 'inline' }}>{`${getDayString(
                        getDay(parseISO(booking.startDate))
                    )} ${formatDate(
                        booking.startDate,
                        'dd/MM/yyyy HH:mm'
                    )}`}</h5>
                </ModalHeader>
                <>
                    <ModalBody>
                        <BookingDetailFields
                            booking={booking}
                            isCustomerValid={isCustomerNameValid}
                            onPropertyValueChange={handlePropertyChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            styleType="secondary"
                            onClick={handleRequestClose}
                        >
                            Close
                        </Button>
                        <Button
                            styleType="primary"
                            disabled={isInvalid}
                            onClick={handleEditRequested}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </>
            </Modal>
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={handleDiscardChangesClicked}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
            {showDuplicateCustomerReferenceModal && (
                <DuplicateCustomerReferenceModal
                    isOpen={showModal && showDuplicateCustomerReferenceModal}
                    booking={duplicateCustomerReferenceBooking!}
                    onRequestClose={() =>
                        setShowDuplicateCustomerReferenceModal(false)
                    }
                    onRequestGoBack={() =>
                        setShowDuplicateCustomerReferenceModal(false)
                    }
                    onRequestSubmit={handleEditConfirmed}
                />
            )}
        </>
    );
};

export default EditModal;
