import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    downloadButton: {
        padding: 0,
        fontSize: '0.9em',
        border: 'none',
        verticalAlign: 'text-bottom',
    },
    downloadIcon: {
        paddingLeft: '0.2rem',
        fontSize: '0.9em',
    },
    pickListText: {
        fontSize: '0.9em',
        verticalAlign: 'middle ! important', //TODO: Fix table styling
    },
    pickListTable: {
        margin: 0,
    },
    warningIcon: {
        color: theme.colors.warningDark,
        fontSize: '0.75em',
        verticalAlign: 'top',
    },
}));
