import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    bookingsHistoryContainer: {
        display: 'flex',
        height: '100%',
        flexFlow: 'column nowrap',
    },
    bookingsHistoryTableContainer: {
        height: '100%',
    },
    bookingsHistoryTable: {
        margin: 0,
        whiteSpace: 'nowrap',
        '& thead th': {
            top: '4.5rem',
        },
    },
    pageSelectionContainer: {
        position: 'sticky',
        bottom: '1rem',
        zIndex: 1,
        '& ul.pagination': {
            margin: 0,
        },
    },
});
