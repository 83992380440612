import { buildClassesHook } from '../../../../helpers/styles';

export const useClasses = buildClassesHook({
    container: {
        maxWidth: 600,
    },
    alert: {
        width: '100%',
    },
    removedMessage: {
        width: 220,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    validMessage: {
        width: 150,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    '@media (min-width: 620px)': {
        container: {
            width: 600,
        },
    },
});
