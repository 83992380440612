import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    logOutButton: {
        color: theme.colors.mediumDarkGrey,
        cursor: 'pointer',
        '&:hover': {
            background: 'transparent',
            color: theme.colors.white,
            textDecoration: 'none',
            border: 'none',
        },
        '&:not(:disabled)&:not(.disabled)&:active': {
            color: theme.colors.white,
            background: 'transparent',
            border: 'none',
        },
        '&:focus': {
            outline: '-webkit-focus-ring-color auto 1px',
            boxShadow: 'none',
        },
        '&:not(:disabled)&:not(.disabled)&:active&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
        background: 'transparent',
        border: 'none',
        padding: '0',
    },
}));
