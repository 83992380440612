import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey, routeFor } from '../../Routes/routes';
import WorkHistoryContainer from '../../containers/workHistory/WorkHistoryContainer';
import { StoreNumber } from '../../models/stores/StoreNumber';
import Page from '../common/Page';

const WorkHistory: React.FC = () => {
    const { store } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <WorkHistoryContainer
                selectedStore={
                    (parseInt(store ?? '') as StoreNumber) ?? undefined
                }
                onStoreChange={(store: StoreNumber) =>
                    navigate(routeFor(RouteKey.StoreWorkHistory)({ store }))
                }
            />
        </Page>
    );
};

export default WorkHistory;
