import * as React from 'react';
import { RecurringBooking } from '../../../models/bookings/RecurringBooking';
import { useState } from 'react';
import EmailModal from '../../../components/bookings/modals/EmailModal';
import EditModal from '../../../components/bookings/modals/recurringBooking/EditModal';
import LoadingModal from '../../../components/common/LoadingModal';
import { EditRecurringBooking } from '../../../models/bookings/EditRecurringBooking';
import { apiPut } from '../../../services/api';
import ErrorModal from '../../../components/common/ErrorModal';

interface RecurringBookingEditModalContainerProps {
    recurringBooking: RecurringBooking;
    showModal: boolean;
    onEditConfirmed?(): void;
    onEmailSent(): void;
    onClose(): void;
}

const RecurringBookingEditModalContainer: React.FC<RecurringBookingEditModalContainerProps> = ({
    recurringBooking,
    showModal,
    onEditConfirmed,
    onEmailSent,
    onClose
}) => {
    const [edited, setEdited] = useState(false);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<unknown>(false);

    const handleCloseRequested = () => {
        if (editing) {
            return;
        }
        onClose();
    };

    const handleEditConfirmed = async (editedBooking: EditRecurringBooking) => {
        setEditing(true);
        try {
            await apiPut(
                `RecurringBooking/${recurringBooking.id}`,
                editedBooking
            );
            setEditing(false);
            setEdited(true);
            onEditConfirmed && onEditConfirmed();
        } catch (e) {
            console.warn(e);
            setError(e);
            setEditing(false);
        }
    };

    const showEditModal = !edited && !editing && !error;

    return (
        <>
            <EditModal
                recurringBooking={recurringBooking}
                showModal={showModal && showEditModal}
                onEditConfirmed={handleEditConfirmed}
                onClose={onClose}
            />
            <LoadingModal
                showModal={showModal && editing}
                header={`Edit Recurring Booking ${recurringBooking.reference}`}
                loadingText="editing"
            />
            <EmailModal
                booking={recurringBooking}
                showModal={showModal && edited}
                emailType="edited"
                bookingType="recurring"
                onEmailSent={onEmailSent}
                onClose={handleCloseRequested}
            />
            <ErrorModal
                showModal={showModal && !!error}
                header={`Edit Recurring Booking ${recurringBooking.reference}`}
                errorText="There was an error editing the recurring booking."
                onClose={onClose}
            />
        </>
    );
};

export default RecurringBookingEditModalContainer;
