import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    alert: {
        width: '100%',
    },
    link: {
        verticalAlign: 'baseline',
        padding: 0,
    },
    invalidityReasonsList: {
        listStyleType: 'disc',
    },
});
