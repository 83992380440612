import { useEffect, useState, useCallback } from 'react';

function useWindowSize() {
    const isClient = typeof window === 'object';

    const getSize = useCallback(
        (): {
            width?: number;
            height?: number;
            isPortrait?: boolean;
        } => {
            if (!isClient) {
                return {};
            }
            return {
                width: window.innerWidth,
                height: window.innerHeight,
                isPortrait: window.innerHeight > window.innerWidth
            };
        },
        [isClient]
    );

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(
        () => {
            if (!isClient) {
                return;
            }

            function handleResize() {
                setWindowSize(getSize());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        },
        [getSize, isClient]
    );
    return windowSize;
}

const breakpointDefinitions: Record<'sm' | 'md' | 'lg' | 'xl', number> = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

function useBreakpoint(breakpoint: 'sm' | 'md' | 'lg' | 'xl') {
    const { width } = useWindowSize();
    if (!width) {
        return false;
    }
    return breakpointDefinitions[breakpoint] <= width;
}

export default useWindowSize;

export { useBreakpoint };
