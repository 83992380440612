import saveFile from '../components/helpers/saveFile';
import { Load } from '../models/loads/Load';
import { LoadType } from '../models/loads/LoadType';
import { Trailer } from '../models/trailers/Trailer';
import { StackUpLabel, TrailerType } from '../models/trailers/TrailerType';
import { apiDelete, apiGet } from './api';
const getPiggybackedPalletCountForLoad = (load: Load): number => {
    if (!load.associatedIodId) {
        return 0;
    }
    const orders = load.orderLoadingStatuses
        .filter((ols) => ols.isToReleaseOrLoad)
        .map((o) => o.order);
    const additional = orders.filter((o) => o.iodId !== load.associatedIodId);
    return additional.reduce((rv, curr) => rv + curr.palletSpaces, 0);
};

const getPalletCountToRemoveForLoad = (load: Load): number => {
    const ordersToUnload = load.orderLoadingStatuses
        .filter((ols) => ols.isToUnload)
        .map((o) => o.order);
    return ordersToUnload.reduce((rv, curr) => rv + curr.palletSpaces, 0);
};

const getTotalDrops = (load: Load) =>
    !load.isTransfer && !load.isColdStoreLoad
        ? load.associatedIod!.totalDrops
        : null;

const getTotalPalletSpaces = (load: Load) =>
    load.associatedIod
        ? load.associatedIod.totalPalletSpaces
        : load.totalPalletSpaces;

const getPalletsToPickForIod = (
    load: Load,
    iodId: string | null,
    heavyOnly = false
): number => {
    return load.orderLoadingStatuses
    .filter(
        (o) =>
            (iodId === null ? o.loadId == load.id : o.order.iodId === iodId) &&
            o.releaseToChess &&
            (!heavyOnly || o.order.containsHeavyPallets)
    )
    .reduce<number>((rv, curr) => rv + curr.order.palletSpaces, 0);
};

const isSubContracted = (load: Load) =>
    !load.isStockTrailer &&
    !load.isColdStoreLoad &&
    load.associatedIod!.isSubContracted;

const isReedBoardallLoad = (load: Load) =>
    !isSubContracted(load) && !load.isThirdParty;

const getSubcontractedString = (load: Load) =>
    isSubContracted(load) ? 'S' : load.isThirdParty ? '3rd' : '';

const getLoadTrailerTypeName = (load: Load) => {
    if (load.isThirdParty && load.trailerType === 'Specialised') {
        return StackUpLabel;
    } else if (load.isInternal) return 'None';

    return load.trailerType;
};

const canLoadBeReleasedToTrailer = (
    loadType: LoadType | null,
    trailerType: TrailerType | null,
    trailer: Trailer
) =>
    (trailerType === 'Single' ||
        trailerType === 'Handball' ||
        trailer.trailerType === trailerType) &&
    (!!trailer.active || loadType === 'Stock Trailer');

const isValidReedBoardallTrailerSelected = (
    load: Load,
    selectedTrailer: Trailer | null
) => {
    if (!load) return true;

    return (
        load.trailerId &&
        selectedTrailer &&
        canLoadBeReleasedToTrailer(
            load.loadType,
            load.trailerType,
            selectedTrailer
        )
    );
};

const getLoadExport = async (load: Load) => {
    const loadExport = await apiGet(`Load/${load.id}/LoadExport`);
    const loadExportName = load.isStockTrailer
        ? `Transfer Sheet ${load.iodOrLoadNumber}-ST${load.sourceStore}.xlsx`
        : `Load Plan ${load.iodOrLoadNumber}-ST${load.sourceStore}.xlsx`;
    saveFile(loadExport, loadExportName);
};

const getPickList = async (load: Load, pickListUpdateId: string) => {
    const pickListFile = await apiGet(
        `Load/${load.id}/PickList/${pickListUpdateId}`
    );
    saveFile(
        pickListFile,
        `Pick List - ${load.iodOrLoadNumber}-ST${load.sourceStore}.xlsx`
    );
};

const getPickListAmendment = async (load: Load, pickListUpdateId: string) => {
    const pickListAmendmentFile = await apiGet(
        `Load/${load.id}/PickList/${pickListUpdateId}/Amendments`
    );
    saveFile(
        pickListAmendmentFile,
        `Pick List Amendment - ${load.iodOrLoadNumber}-ST${load.sourceStore}.xlsx`
    );
};

const deleteAssociatedIods = async (load: Load) =>
    await apiDelete(`Load/${load.id}/DeleteAssociatedIods`);

const deleteNoIodStockTrailer = async (load: Load) =>
    await apiDelete(`Load/${load.id}/DeleteNoIodStockTrailer`);

const deleteThirdPartyLoad = async (load: Load) =>
    await apiDelete(`Load/${load.id}/DeleteThirdPartyLoad`);

export {
    getTotalDrops,
    getTotalPalletSpaces,
    getPalletsToPickForIod,
    getPiggybackedPalletCountForLoad,
    getPalletCountToRemoveForLoad,
    isSubContracted,
    getSubcontractedString,
    getPickListAmendment,
    getLoadTrailerTypeName,
    isReedBoardallLoad,
    canLoadBeReleasedToTrailer,
    isValidReedBoardallTrailerSelected,
    getLoadExport,
    getPickList,
    deleteAssociatedIods,
    deleteNoIodStockTrailer,
    deleteThirdPartyLoad,
};
