import * as React from 'react';
import AdminContainer, {
    AdminPage,
} from '../../containers/admin/common/AdminContainer';
import Page from '../common/Page';

const SiteResourceConfig: React.FC = () => (
    <Page>
        <AdminContainer page={AdminPage.SiteResources} />
    </Page>
);

export default SiteResourceConfig;
