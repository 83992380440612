import * as React from 'react';
import { Load } from '../../../models/loads/Load';
import { OrderLoadingStatus } from '../../../models/orders/OrderLoadingStatus';
import PiggybackTransferOrOrderToLoadModal from './PiggybackTransferOrOrderToLoadModal';

interface PiggybackOrderToLoadModalProps {
    load: Load;
    orderLoadingStatus: OrderLoadingStatus;
    isOpen: boolean;
    onClose(): void;
}

const PiggybackOrderToLoadModal: React.FC<PiggybackOrderToLoadModalProps> = ({
    load,
    orderLoadingStatus,
    isOpen,
    onClose
}) => (
    <PiggybackTransferOrOrderToLoadModal
        piggybackFullTransfer={false}
        load={load}
        orderLoadingStatus={orderLoadingStatus}
        isOpen={isOpen}
        onClose={onClose}
    />
);

export default PiggybackOrderToLoadModal;
