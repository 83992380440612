import * as React from 'react';
import AdminContainer, {
    AdminPage,
} from '../../containers/admin/common/AdminContainer';
import Page from '../common/Page';

const StoreResourceConfig: React.FC = () => (
    <Page>
        <AdminContainer page={AdminPage.StoreResources} />
    </Page>
);

export default StoreResourceConfig;
