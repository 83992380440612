import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import BookingSmsModal from '../../../components/bookings/modals/BookingSmsModal';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import UpdateNotification from '../../../components/common/UpdateNotification';
import Bay from '../../../models/bays/Bay';
import { Booking } from '../../../models/bookings/Booking';
import { HttpErrorResponse } from '../../../models/common/HttpErrorResponse';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { apiPatch, apiPost, apiPut, useData } from '../../../services/api';
import { getErrorMessage } from '../../../helpers/errors';

interface BookingSmsModalContainerProps {
    booking: Booking;
    showModal: boolean;
    onSave?(): void;
    onClose(): void;
}

const BookingSmsModalContainer: React.FC<BookingSmsModalContainerProps> = ({
    booking,
    showModal,
    onSave,
    onClose,
}) => {
    const [sendingBookingSms, setSendingBookingSms] = useState(false);
    const [updatingBooking, setUpdatingBooking] = useState(false);
    const [error, setError] = useState<HttpErrorResponse>();
    const getBaysUrl = () => {
        switch (booking.trailerType) {
            case 'Handball':
                return `Store/${booking.store}/Bays/ValidHandballBays`;
            case 'Specialised':
                return `Store/${booking.store}/Bays/ValidStackUpBays`;
        }
        return `Store/${booking.store}/Bays/Valid`;
    };

    const [bays, loadingBays] = useData<Bay[]>(getBaysUrl(), {
        loadingBaysOnly: String(true),
    });
    const [smsTemplates, loadingSMSTemplates] = useData<SMSTemplate[]>(
        'SMSTemplate',
        {
            excludeBookingCheckInTemplate: String(true),
        }
    );

    const updateBooking = async (
        contactNumber: string,
        bayNumber: number,
        bayNumberUpdateable: boolean
    ) => {
        setUpdatingBooking(true);
        if (booking.bayOrPagerNumber !== bayNumber && bayNumberUpdateable) {
            try {
                await apiPut(`Booking/${booking.id}/BayOrPagerNumber`, {
                    bayOrPagerNumber: bayNumber,
                });
            } catch (e) {
                console.warn(e);
                setUpdatingBooking(false);
                setError(e as HttpErrorResponse);
                return;
            }
        }

        if (booking.contactNumber !== contactNumber) {
            try {
                await apiPatch(`Booking/${booking.id}/ContactNumber`, {
                    contactNumber: contactNumber,
                });
            } catch (e) {
                console.warn(e);
                setUpdatingBooking(false);
                setError(e as HttpErrorResponse);
                return;
            }
        }
        setUpdatingBooking(false);
    };

    const sendSmsMessage = async (smsTemplateId: string) => {
        setSendingBookingSms(true);
        try {
            await apiPost(`Booking/${booking.id}/Sms`, {
                smsTemplateId,
            });
            toast.info(
                <UpdateNotification
                    heading={`SMS Message sent for Booking ${booking.reference}`}
                >
                    Message sent:{' '}
                    {smsTemplates?.find((t) => t.id === smsTemplateId)?.name}
                </UpdateNotification>
            );
            if (onSave) {
                onSave();
            }
        } catch (e) {
            console.warn(e);
            setSendingBookingSms(false);
            setError(e as HttpErrorResponse);
        }
    };

    const handleSaveClicked = async (
        contactNumber: string,
        smsTemplateId: string,
        bayNumber: number,
        bayNumberUpdateable: boolean
    ) => {
        await updateBooking(contactNumber, bayNumber, bayNumberUpdateable);

        if (!error) {
            await sendSmsMessage(smsTemplateId);
        }
    };

    const handleLoadSmsModalClose = () => {
        if (sendingBookingSms) {
            return;
        }
        onClose();
    };

    const modalHeader = `Contact driver: ${booking.reference}`;
    const showLoadingModal =
        (updatingBooking ||
            sendingBookingSms ||
            loadingBays ||
            loadingSMSTemplates) &&
        !error;
    const showSmsModal =
        !updatingBooking &&
        !sendingBookingSms &&
        !loadingBays &&
        !loadingSMSTemplates &&
        !error;
    const loadingText = updatingBooking
        ? 'Updating booking...'
        : sendingBookingSms
        ? 'Sending message...'
        : 'Loading...';

    return (
        <>
            <LoadingModal
                showModal={showModal && showLoadingModal}
                header={modalHeader}
                loadingText={loadingText}
            />
            <BookingSmsModal
                booking={booking}
                bays={bays ?? []}
                loadingBays={loadingBays}
                smsTemplates={smsTemplates || []}
                showModal={showModal && showSmsModal}
                onSave={handleSaveClicked}
                onClose={handleLoadSmsModalClose}
            />
            {!!error && (
                <ErrorModal
                    showModal={showModal}
                    header={modalHeader}
                    errorText={`An error has occurred when sending the text message: ${getErrorMessage(
                        error
                    )}`}
                    onClose={handleLoadSmsModalClose}
                />
            )}
        </>
    );
};

export default BookingSmsModalContainer;
