import * as React from 'react';
import { useContext } from 'react';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import { buildClassesHook } from '../../../helpers/styles';
import { Checkbox, Switch, TextInput } from '../../../lib/bootstrap-ui/Forms';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { BookingType } from '../../../models/bookings/BookingType';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import {
    ResourceTime,
    TrailerTypeResource
} from '../../../models/bookings/StoreBookingResource';
import {
    formatTimeString,
    timeStringRegExp
} from '../../../services/timeStringService';
import { getTrailerTypeLabelForBookingType } from '../../../services/trailerService';
import SingleColumnRow from '../../common/SingleColumnRow';

const useClasses = buildClassesHook({
    trailerResourceEditorContainer: {
        padding: '15px',
        borderRadius: '0.5rem',
        boxShadow: '1px 1px 3px 3px hsl(210, 10%, 92%)'
    },
    trailerResourceEditorBody: {
        padding: '0 10px 0 10px'
    },
    durationInputSection: {
        marginTop: '1rem'
    }
});

interface TrailerResourceEditorProps {
    selectedBookingType: BookingType;
    trailerResource: TrailerTypeResource;
    onTrailerResourceChange(trailerResource: TrailerTypeResource): void;
}

enum TimeOfWeek {
    Weekday = 'weekday',
    Saturday = 'saturday',
    Sunday = 'sunday'
}

const TrailerResourceEditor: React.FC<TrailerResourceEditorProps> = ({
    selectedBookingType,
    trailerResource,
    onTrailerResourceChange
}) => {
    const { loadingResources } = useContext(LookupContext);
    const classes = useClasses();

    const handleDurationChange = (value: string | null) => {
        const hourDuration = !!value ? parseInt(value) : 0;
        const editTrailerResource = { ...trailerResource, hourDuration };
        onTrailerResourceChange(editTrailerResource);
    };

    const handlePointsPerLoadChange = (value: string | null) => {
        const pointsPerLoad = !!value ? parseInt(value) : 0;
        const editTrailerResource = { ...trailerResource, pointsPerLoad };
        onTrailerResourceChange(editTrailerResource);
    };

    const handleTimeChange = (
        value: string | null,
        type: ResourceTime,
        timeOfWeek: TimeOfWeek
    ) => {
        const updatedTime = value + ':00';
        const editTrailerResource: TrailerTypeResource = {
            ...trailerResource,
            [`${timeOfWeek}${type}`]: updatedTime
        };

        onTrailerResourceChange(editTrailerResource);
    };

    const handleloadingResourceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const resourceName: string = event.target.value;

        if (event.target.checked) {
            onTrailerResourceChange({
                ...trailerResource,
                resources: [
                    ...trailerResource.resources,
                    resourceName as LoadingResource
                ]
            });
        } else {
            onTrailerResourceChange({
                ...trailerResource,
                resources: [
                    ...trailerResource.resources.filter(r => r !== resourceName)
                ]
            });
        }
    };

    const handleOpenAllDayChange = (
        openAllDay: boolean,
        propertyToChange: keyof TrailerTypeResource
    ) => {
        const updatedTrailerResource = {
            ...trailerResource,
            [propertyToChange]: openAllDay
        };
        onTrailerResourceChange(updatedTrailerResource);
    };

    const handleEstimatedPalletsChange = (textBoxValue: string) => {
        const estimatedPalletCount = parseInt(textBoxValue);
        const updatedTrailerResource: TrailerTypeResource = {
            ...trailerResource,
            estimatedPalletCount
        };
        onTrailerResourceChange(updatedTrailerResource);
    };

    const getTimeValue = (isOpenAllDay: boolean, timeStamp: string) =>
        isOpenAllDay ? '--:--' : formatTimeString(timeStamp);

    const isTimeValueValid = (timeStamp: string) =>
        !timeStringRegExp.test(timeStamp) ? 'Not a valid time format' : null;

    return (
        <>
            {trailerResource.active && (
                <Grid className={classes.trailerResourceEditorContainer}>
                    <Row>
                        <Column>
                            <h4>
                                {getTrailerTypeLabelForBookingType(
                                    selectedBookingType,
                                    trailerResource.trailerType,
                                    trailerResource.secondaryTrailerType
                                )}
                            </h4>
                        </Column>
                    </Row>
                    <div className={classes.trailerResourceEditorBody}>
                        <SingleColumnRow>
                            <label>Required Resources:</label>
                        </SingleColumnRow>
                        <SingleColumnRow>
                            {loadingResources.map((resource, i) => (
                                <Checkbox
                                    checked={trailerResource.resources.includes(
                                        resource
                                    )}
                                    inline
                                    label={resource}
                                    key={i}
                                    value={resource}
                                    onChange={handleloadingResourceChange}
                                />
                            ))}
                        </SingleColumnRow>
                        <Row className={classes.durationInputSection}>
                            <Column>
                                <TextInput
                                    label="Duration (hours):"
                                    error={
                                        trailerResource.hourDuration === null ||
                                        trailerResource.hourDuration <= 0
                                            ? 'Must be greater than 0.'
                                            : null
                                    }
                                    size="small"
                                    type="number"
                                    minValue={1}
                                    value={
                                        trailerResource.hourDuration !== null
                                            ? trailerResource.hourDuration
                                            : ''
                                    }
                                    onChange={handleDurationChange}
                                    highlightOnFocus
                                />
                            </Column>
                            <Column>
                                <TextInput
                                    label="Points Per Load (12 per hour):"
                                    error={
                                        trailerResource.pointsPerLoad ===
                                            null ||
                                        trailerResource.pointsPerLoad < 0 ||
                                        trailerResource.pointsPerLoad > 12
                                            ? 'Must be between 0 and 12 (inclusive)'
                                            : null
                                    }
                                    size="small"
                                    type="number"
                                    minValue={0}
                                    maxValue={12}
                                    step={1}
                                    value={
                                        trailerResource.pointsPerLoad !== null
                                            ? trailerResource.pointsPerLoad
                                            : ''
                                    }
                                    onChange={handlePointsPerLoadChange}
                                    highlightOnFocus
                                />
                            </Column>
                        </Row>
                        {(trailerResource.bookingType ===
                            BookingType.DeliveryAndCollection ||
                            trailerResource.bookingType ===
                                BookingType.Delivery) && (
                            <Row>
                                <Column size={6}>
                                    <TextInput
                                        label="Pallets In Count"
                                        size="small"
                                        type="number"
                                        minValue={0}
                                        value={
                                            trailerResource.estimatedPalletCount
                                        }
                                        onChange={handleEstimatedPalletsChange}
                                        highlightOnFocus
                                    />
                                </Column>
                            </Row>
                        )}

                        <SingleColumnRow>
                            <label>Start Times (hh:mm): </label>
                        </SingleColumnRow>
                        <Row>
                            <Column>
                                <TextInput
                                    label="Weekday:"
                                    size="small"
                                    type="text"
                                    disabled={trailerResource.openAllDayWeekday}
                                    error={isTimeValueValid(
                                        trailerResource.weekdayStartTime
                                    )}
                                    value={getTimeValue(
                                        trailerResource.openAllDayWeekday,
                                        trailerResource.weekdayStartTime
                                    )}
                                    onChange={value =>
                                        handleTimeChange(
                                            value,
                                            ResourceTime.Start,
                                            TimeOfWeek.Weekday
                                        )
                                    }
                                />
                            </Column>
                            <Column>
                                <TextInput
                                    label="Saturday:"
                                    size="small"
                                    type="text"
                                    disabled={
                                        trailerResource.openAllDaySaturday
                                    }
                                    error={isTimeValueValid(
                                        trailerResource.saturdayStartTime
                                    )}
                                    value={getTimeValue(
                                        trailerResource.openAllDaySaturday,
                                        trailerResource.saturdayStartTime
                                    )}
                                    onChange={event =>
                                        handleTimeChange(
                                            event,
                                            ResourceTime.Start,
                                            TimeOfWeek.Saturday
                                        )
                                    }
                                />
                            </Column>
                            <Column>
                                <TextInput
                                    label="Sunday:"
                                    size="small"
                                    type="text"
                                    disabled={trailerResource.openAllDaySunday}
                                    error={isTimeValueValid(
                                        trailerResource.sundayStartTime
                                    )}
                                    value={getTimeValue(
                                        trailerResource.openAllDaySunday,
                                        trailerResource.sundayStartTime
                                    )}
                                    onChange={event =>
                                        handleTimeChange(
                                            event,
                                            ResourceTime.Start,
                                            TimeOfWeek.Sunday
                                        )
                                    }
                                />
                            </Column>
                        </Row>
                        <SingleColumnRow>
                            <label>End Times (hh:mm): </label>
                        </SingleColumnRow>
                        <Row>
                            <Column>
                                <TextInput
                                    label="Weekday:"
                                    size="small"
                                    type="text"
                                    disabled={trailerResource.openAllDayWeekday}
                                    error={isTimeValueValid(
                                        trailerResource.weekdayEndTime
                                    )}
                                    value={getTimeValue(
                                        trailerResource.openAllDayWeekday,
                                        trailerResource.weekdayEndTime
                                    )}
                                    onChange={event =>
                                        handleTimeChange(
                                            event,
                                            ResourceTime.End,
                                            TimeOfWeek.Weekday
                                        )
                                    }
                                />
                            </Column>
                            <Column>
                                <TextInput
                                    label="Saturday:"
                                    size="small"
                                    type="text"
                                    disabled={
                                        trailerResource.openAllDaySaturday
                                    }
                                    error={isTimeValueValid(
                                        trailerResource.saturdayEndTime
                                    )}
                                    value={getTimeValue(
                                        trailerResource.openAllDaySaturday,
                                        trailerResource.saturdayEndTime
                                    )}
                                    onChange={event =>
                                        handleTimeChange(
                                            event,
                                            ResourceTime.End,
                                            TimeOfWeek.Saturday
                                        )
                                    }
                                />
                            </Column>
                            <Column>
                                <TextInput
                                    label="Sunday:"
                                    size="small"
                                    type="text"
                                    disabled={trailerResource.openAllDaySunday}
                                    error={isTimeValueValid(
                                        trailerResource.sundayEndTime
                                    )}
                                    value={getTimeValue(
                                        trailerResource.openAllDaySunday,
                                        trailerResource.sundayEndTime
                                    )}
                                    onChange={event =>
                                        handleTimeChange(
                                            event,
                                            ResourceTime.End,
                                            TimeOfWeek.Sunday
                                        )
                                    }
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <Switch
                                    value={trailerResource.openAllDayWeekday}
                                    label="Open All Day"
                                    onChange={(openAllDay: boolean) =>
                                        handleOpenAllDayChange(
                                            openAllDay,
                                            'openAllDayWeekday'
                                        )
                                    }
                                />
                            </Column>
                            <Column>
                                <Switch
                                    value={trailerResource.openAllDaySaturday}
                                    label="Open All Day"
                                    onChange={(openAllDay: boolean) =>
                                        handleOpenAllDayChange(
                                            openAllDay,
                                            'openAllDaySaturday'
                                        )
                                    }
                                />
                            </Column>
                            <Column>
                                <Switch
                                    value={trailerResource.openAllDaySunday}
                                    label="Open All Day"
                                    onChange={(openAllDay: boolean) =>
                                        handleOpenAllDayChange(
                                            openAllDay,
                                            'openAllDaySunday'
                                        )
                                    }
                                />
                            </Column>
                        </Row>
                    </div>
                </Grid>
            )}
        </>
    );
};

export default TrailerResourceEditor;
