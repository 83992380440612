import * as React from 'react';
import AdminContainer, {
    AdminPage,
} from '../../containers/admin/common/AdminContainer';
import Page from '../common/Page';

const BayManagement: React.FC = () => (
    <Page>
        <AdminContainer page={AdminPage.BayManagement} />
    </Page>
);

export default BayManagement;
