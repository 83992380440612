import * as React from 'react';
import classnames from 'classnames';

interface CardTitleProps extends React.HTMLAttributes<HTMLElement> {
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    subtitle?: boolean;
}

const CardTitle: React.FC<CardTitleProps> = ({
    level = 4,
    subtitle = false,
    children,
    className,
    ...otherProps
}) => {
    switch (level) {
        case 1:
            return (
                <h1
                    className={classnames(
                        { 'card-title': !subtitle, 'card-subtitle': subtitle },
                        className
                    )}
                    {...otherProps}
                >
                    {children}
                </h1>
            );
        case 2:
            return (
                <h2
                    className={classnames(
                        { 'card-title': !subtitle, 'card-subtitle': subtitle },
                        className
                    )}
                    {...otherProps}
                >
                    {children}
                </h2>
            );
        case 3:
            return (
                <h3
                    className={classnames(
                        { 'card-title': !subtitle, 'card-subtitle': subtitle },
                        className
                    )}
                    {...otherProps}
                >
                    {children}
                </h3>
            );
        case 4:
            return (
                <h4
                    className={classnames(
                        { 'card-title': !subtitle, 'card-subtitle': subtitle },
                        className
                    )}
                    {...otherProps}
                >
                    {children}
                </h4>
            );
        case 5:
            return (
                <h5
                    className={classnames(
                        { 'card-title': !subtitle, 'card-subtitle': subtitle },
                        className
                    )}
                    {...otherProps}
                >
                    {children}
                </h5>
            );
        case 6:
            return (
                <h6
                    className={classnames(
                        { 'card-title': !subtitle, 'card-subtitle': subtitle },
                        className
                    )}
                    {...otherProps}
                >
                    {children}
                </h6>
            );
    }
};

export default CardTitle;
