import classNames from 'classnames';
import * as React from 'react';
import { LoadingMilestoneThreshold } from '../../../../../../models/loads/LoadingMilestoneThreshold';
import { Pallet } from '../../../../../../models/pallets/Pallet';
import LoadingProgressDetailedMilestoneCell from './LoadingProgressDetailMilestoneCell';
import { useClasses } from './LoadingProgressPalletRow.styles';

interface LoadingProgressPalletRowProps {
    pallet: Pallet;
    milestoneThresholds: LoadingMilestoneThreshold[];
    loadCompletedTime?: Date;
    isTransferPallet?: boolean;
}

const convertToDateOrNull = (value: string | null): Date | null => {
    if (!value) {
        return null;
    }
    return new Date(value);
};

const convertToMaxDateOrNull = (values: (string | null)[]): Date | null => {
    const dates = values.map(convertToDateOrNull);

    if (!dates?.length) return null;
    if (dates?.length === 1) return dates[0];

    const maxDate = Math.max(...dates.map((d) => d?.getTime() ?? 0));

    return new Date(maxDate);
};

const LoadingProgressPalletRow: React.FC<LoadingProgressPalletRowProps> = ({
    pallet,
    milestoneThresholds,
    loadCompletedTime,
    isTransferPallet = false,
}) => {
    const classes = useClasses();

    const isEstimatedTime = pallet.status === 'Picked - By Load Xml' ?? false;

    const productDescription = pallet.productCode.includes('CasePick')
        ? 'Case Picked Pallet'
        : pallet.products[0].productDesc;

    const thresholdDuration = (milestone: string): string | null | undefined =>
        milestoneThresholds.find((mt) => mt.milestone === milestone)
            ?.thresholdDuration;

    return (
        <tr
            className={classNames(classes.palletDataRow, {
                [classes.rejectedPallet]: pallet.status === 'Problem',
            })}
        >
            <td className={classes.palletDataColumn} />
            <td
                className={classes.palletDataColumn}
                title={pallet.customerPalletNumber}
            >
                {pallet.customerPalletNumber}
            </td>
            <td className={classes.palletDataColumn} title={productDescription}>
                {productDescription}
            </td>
            <td className={classes.palletDataColumn} title={pallet.location}>
                {pallet.location}
            </td>
            <td className={classes.palletDataColumn}>
                {pallet.isHeavy ? 'H' : ''}
            </td>
            <td className={classes.loadingProgressMilestoneCell}>
                <LoadingProgressDetailedMilestoneCell
                    milestoneStartedAt={convertToDateOrNull(
                        pallet.loadingProgress.pickedStartTime
                    )}
                    milestoneEndedAt={
                        pallet.status === 'Problem'
                            ? convertToDateOrNull(
                                  pallet.loadingProgress.problemStartTime
                              )
                            : convertToMaxDateOrNull(
                                  !pallet.loadingProgress.pickedEndTime
                                      ? [null]
                                      : [
                                            pallet.loadingProgress
                                                .pickedStartTime,
                                            pallet.loadingProgress
                                                .pickedEndTime,
                                        ]
                              )
                    }
                    thresholdDuration={thresholdDuration('Allocated')}
                    estimate={isEstimatedTime}
                    isTransferPallet={isTransferPallet}
                />
            </td>
            <td className={classes.loadingProgressMilestoneCell}>
                <LoadingProgressDetailedMilestoneCell
                    milestoneStartedAt={convertToDateOrNull(
                        pallet.loadingProgress.pickedEndTime
                    )}
                    milestoneEndedAt={loadCompletedTime}
                    thresholdDuration={thresholdDuration('Picked')}
                    loaded
                    estimate={isEstimatedTime}
                    isTransferPallet={isTransferPallet}
                />
            </td>
            <td className={classes.loadingProgressMilestoneCell}>
                {isTransferPallet ? (
                    <></>
                ) : (
                    <LoadingProgressDetailedMilestoneCell
                        milestoneStartedAt={convertToDateOrNull(
                            pallet.loadingProgress.problemStartTime
                        )}
                        milestoneEndedAt={convertToDateOrNull(
                            pallet.loadingProgress.problemEndTime
                        )}
                        thresholdDuration={thresholdDuration('Problem')}
                    />
                )}
            </td>
        </tr>
    );
};

export default LoadingProgressPalletRow;
