import * as React from 'react';
import classnames from 'classnames';

import { pluralise } from '../../../helpers/stringFunctions';
import Card, { CardBody, CardText } from '../../../lib/bootstrap-ui/Card';
import Center from '../../common/Center';
import { useClasses } from './LoadDetailsCard.styles';

interface TransportOnlyDetailsCardProps {
    palletCount: number;
}

const TransportOnlyDetailsCard = ({
    palletCount,
}: TransportOnlyDetailsCardProps) => {
    const classes = useClasses();

    return (
        <Card
            className={classnames(
                'text-center',
                classes.card,
                classes.transportOnlyCard
            )}
        >
            <CardBody className={classes.body}>
                <div className={classes.headerContainer}>
                    <CardText>{'TO'}</CardText>
                    <CardText />
                </div>
                <Center className={classes.bodyContainer}>
                    <CardText className={classes.pallets}>
                        {pluralise('Pallet', palletCount)}
                    </CardText>
                </Center>
                <div className={classes.footerContainer} />
            </CardBody>
        </Card>
    );
};

export default TransportOnlyDetailsCard;
