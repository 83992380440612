import * as React from 'react';
import { useState } from 'react';
import { Booking } from '../../../models/bookings/Booking';
import { Load } from '../../../models/loads/Load';
import { LoadType } from '../../../models/loads/LoadType';
import { apiPut, useData } from '../../../services/api';
import ErrorModal from '../../common/ErrorModal';
import LoadingModal from '../../common/LoadingModal';
import UnsavedChangesModal from '../../common/UnsavedChangesModal';
import UpdateInternalWorkOrdersModal from '../../orders/UpdateInternalWorkOrdersModal';
import UpdateThirdPartyOrdersModal from '../../orders/UpdateThirdPartyOrdersModal';

interface UpdateBookingLoadOrdersModalProps {
    load: Load;
    showModal: boolean;
    onSaveRedirect?(id: string): void;
    onClose(): void;
}

const UpdateBookingLoadOrdersModal: React.FC<UpdateBookingLoadOrdersModalProps> = ({
    load,
    showModal,
    onSaveRedirect,
    onClose
}) => {
    const [error, setError] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(
        false
    );
    const [submittingUpdate, setSubmittingUpdate] = useState(false);

    const [bookingForLoad, loadingBookingForLoad] = useData<Booking>(
        load.id ? `Booking/${load.id}/AssociatedLoad` : null
    );

    const handleConfirmClicked = async (
        selectedOrderIds: string[],
        customerReference: string | null,
        customerName: string | null
    ) => {
        setSubmittingUpdate(true);
        try {
            await apiPut(`Load/${load.id}/Orders`, {
                orderIds: selectedOrderIds,
                customerReference: customerReference,
                customerName: customerName
            });
            setSubmittingUpdate(false);
            onSaveRedirect && onSaveRedirect(load.id);
            onClose();
        } catch (e) {
            console.warn(e);
            setError(!!e);
            setSubmittingUpdate(false);
        }
    };

    const handleRequestClose = (unsavedChanges: boolean) => {
        if (submittingUpdate) {
            return;
        }
        if (unsavedChanges) {
            setShowUnsavedChangesModal(true);
            return;
        }
        onClose();
    };

    const modalHeader = `Confirm Orders on ${load.loadName}`;

    const showUpdateOrdersModal =
        !submittingUpdate && !error && !showUnsavedChangesModal;

    if (loadingBookingForLoad || !bookingForLoad) {
        return (
            <LoadingModal
                showModal={showModal && submittingUpdate}
                header={modalHeader}
                loadingText="loading"
            />
        );
    }

    return (
        <>
            <LoadingModal
                showModal={showModal && submittingUpdate}
                header={modalHeader}
                loadingText="updating load"
            />
            <ErrorModal
                showModal={showModal && error}
                header={modalHeader}
                errorText="There was an error updating the load&#39;s orders."
                onClose={onClose}
            />
            <UnsavedChangesModal
                isOpen={showModal && showUnsavedChangesModal}
                onRequestDiscardChanges={onClose}
                onRequestClose={() => setShowUnsavedChangesModal(false)}
                onRequestGoBack={() => setShowUnsavedChangesModal(false)}
            />
            {load.loadType === LoadType.Internal ? (
                <UpdateInternalWorkOrdersModal
                    modalHeader={modalHeader}
                    booking={bookingForLoad}
                    showModal={showModal && showUpdateOrdersModal}
                    isLoading={submittingUpdate}
                    requireOrdersToSave
                    onSubmit={handleConfirmClicked}
                    onClose={handleRequestClose}
                />
            ) : (
                <UpdateThirdPartyOrdersModal
                    modalHeader={modalHeader}
                    booking={bookingForLoad}
                    showModal={showModal && showUpdateOrdersModal}
                    isLoading={submittingUpdate}
                    requireOrdersToSave
                    onSubmit={handleConfirmClicked}
                    onClose={handleRequestClose}
                />
            )}
        </>
    );
};

export default UpdateBookingLoadOrdersModal;
