import {
    differenceInCalendarWeeks,
    endOfMonth,
    endOfWeek,
    format,
    isSameMonth,
    isWeekend,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import * as React from 'react';
import { eachDay } from '../../../helpers/dates';
import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';
import CSSGrid from '../CSSGrid';
import DateCell from './DateCell';

const useClasses = buildClassesHook((theme: Theme) => ({
    headerColumn: {
        textAlign: 'center',
    },
    headerText: {
        color: theme.colors.black,
        marginBottom: '0.5rem',
    },
    headerTextDisabled: {
        color: theme.colors.darkGrey,
    },
    datesGrid: {
        maxWidth: '300px',
        margin: 'auto',
    },
}));

export enum WeekDay {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

interface DatePickerBodyProps {
    disabled?: boolean;
    disableWeekends?: boolean;
    excludedDates?: Date[];
    minDate?: Date;
    selectedMonth: Date;
    selectedDate: Date | null;
    weekStartsOn?: WeekDay;

    onDateSelected: (date: Date) => void;
}

const getDayOfWeekHeaders = (weekStartsOn: WeekDay) => {
    const weekStart = startOfWeek(new Date(), { weekStartsOn });
    const weekEnd = endOfWeek(new Date(), { weekStartsOn });
    return eachDay(weekStart, weekEnd);
};

const DatePickerBody: React.FC<DatePickerBodyProps> = ({
    disabled = false,
    disableWeekends = false,
    excludedDates = [],
    minDate,
    selectedDate,
    selectedMonth,
    weekStartsOn = WeekDay.Monday,
    onDateSelected,
}) => {
    const classes = useClasses();

    const monthStart = startOfMonth(selectedMonth);
    const monthEnd = endOfMonth(selectedMonth);
    const firstWeekStart = startOfWeek(monthStart, { weekStartsOn });
    const lastWeekEnd = endOfWeek(monthEnd, { weekStartsOn });

    const daysToDisplay = eachDay(firstWeekStart, lastWeekEnd);
    const daysOfWeekHeaders = getDayOfWeekHeaders(weekStartsOn);

    return (
        <div className={classes.datesGrid}>
            <CSSGrid
                columns="repeat(7, 1fr)"
                rows={`repeat(${differenceInCalendarWeeks(
                    monthStart,
                    monthEnd,
                    {
                        weekStartsOn,
                    }
                )}, 1fr)`}
                gap={10}
            >
                {daysOfWeekHeaders.map((h, i) => (
                    <div className={classes.headerColumn} key={i}>
                        <p
                            className={
                                !disabled
                                    ? classes.headerText
                                    : classes.headerTextDisabled
                            }
                        >
                            {format(h, 'E')}
                        </p>
                    </div>
                ))}
                {daysToDisplay.map((d, i) =>
                    isSameMonth(d, selectedMonth) ? (
                        <DateCell
                            key={i}
                            date={d}
                            disabled={
                                disabled ||
                                (disableWeekends && isWeekend(d)) ||
                                excludedDates.some(
                                    (ex) => ex.valueOf() === d.valueOf()
                                )
                            }
                            minDate={minDate}
                            selectedDate={selectedDate}
                            selectedMonth={selectedMonth}
                            onDateSelected={onDateSelected}
                        />
                    ) : (
                        <div key={i} />
                    )
                )}
            </CSSGrid>
        </div>
    );
};

export default DatePickerBody;
