import * as React from 'react';
import { useEffect, useState } from 'react';
import TrailerPicker from '../../components/releasing/TrailerPicker';
import { useNotificationListener } from '../../hooks/useNotificationListener';
import { LoadType } from '../../models/loads/LoadType';
import { TrailerUpdatedNotification } from '../../models/notifications/Trailer';
import { Trailer } from '../../models/trailers/Trailer';
import { TrailerType } from '../../models/trailers/TrailerType';
import { apiGet } from '../../services/api';
import { canLoadBeReleasedToTrailer } from '../../services/loadService';

interface TrailerPickerContainerProps {
    trailerType: TrailerType | null;
    loadType: LoadType | null;
    onTrailerChange: (trailer: Trailer) => void;
    id: string;
    label?: string;
    selectedValue: string | null;
    disabled?: boolean;
    setSelectedTrailerAsInactive: () => void;
}

const TrailerPickerContainer: React.FC<TrailerPickerContainerProps> = ({
    trailerType,
    loadType,
    onTrailerChange,
    label,
    selectedValue,
    disabled,
    setSelectedTrailerAsInactive
}) => {
    const [loading, setLoading] = useState(true);
    const [trailerOptions, setTrailerOptions] = useState<Trailer[]>([]);
    const [filteredTrailerOptions, setFilteredTrailerOptions] = useState<
        Trailer[]
    >([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const getItemText = (item: Trailer) => <div>{item.id}</div>;

    const getItemValue = (item: Trailer) => item.id;

    const shouldItemRender = (trailer: Trailer, searchValue: string) => {
        return (
            searchValue.length > 1 &&
            trailer.id.toLowerCase().startsWith(searchValue.toLowerCase()) &&
            canLoadBeReleasedToTrailer(loadType, trailerType, trailer)
        );
    };

    const getFilteredTrailers = (searchValue: string) => {
        if (trailerOptions) {
            const filteredTrailers = trailerOptions
                .filter(trailer => shouldItemRender(trailer, searchValue))
                .slice(0, 15);
            return filteredTrailers;
        }
        return [];
    };

    const fetchTrailers = async () => {
        setLoading(true);
        const newTrailerOptions = await apiGet(`Trailer`);
        if (
            selectedValue &&
            newTrailerOptions.filter(t => t.id === selectedValue).length ===
                0 &&
            trailerOptions.filter(t => t.id === selectedValue).length !== 0
        ) {
            setSelectedTrailerAsInactive();
        }
        setTrailerOptions(newTrailerOptions);
        setFilteredTrailerOptions(trailerOptions.slice(0, 15));
        setLoading(false);
    };

    useEffect(
        () => {
            fetchTrailers();
        },
        // eslint-disable-next-line
        []
    );

    useNotificationListener(TrailerUpdatedNotification, fetchTrailers);

    useEffect(
        () => {
            const selectedValueString = selectedValue || '';
            setFilteredTrailerOptions(getFilteredTrailers(selectedValueString));
            setSearchValue(selectedValueString);
        },
        // eslint-disable-next-line
        [selectedValue]
    );

    const handleAutocompleteValueChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        value: string
    ) => {
        if (trailerOptions) {
            const trailer = trailerOptions.find(
                t => t.id.toUpperCase() === value.toUpperCase()
            );
            if (trailer) {
                onTrailerChange(trailer);
            } else {
                onTrailerChange({
                    id: value,
                    trailerType: 'Unknown'
                });
            }
        }

        setFilteredTrailerOptions(getFilteredTrailers(value));
        setSearchValue(value);
    };

    const handleAutocompleteSelected = (_: string, item: Trailer) => {
        onTrailerChange(item);
        setFilteredTrailerOptions(getFilteredTrailers(item.id));
        setSearchValue(item.id);
    };

    return (
        <>
            {label && <label>{label}</label>}
            <TrailerPicker
                trailerOptions={filteredTrailerOptions}
                searchValue={searchValue}
                handleAutocompleteValueChange={handleAutocompleteValueChange}
                handleAutocompleteSelected={handleAutocompleteSelected}
                getItemText={getItemText}
                getItemValue={getItemValue}
                shouldItemRender={shouldItemRender}
                loading={loading}
                disabled={disabled || loading}
            />
        </>
    );
};

export default TrailerPickerContainer;
