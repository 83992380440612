import * as React from 'react';
import { WorkQueueTableModal } from '../../containers/workQueue/WorkQueueTableContainer';
import Table from '../../lib/bootstrap-ui/Table';
import { HeaderDetails, TextAlign } from '../../lib/bootstrap-ui/Table/Table';
import { Load } from '../../models/loads/Load';
import NoDataFoundTableRow from '../common/table/NoDataFoundTableRow';
import { useClasses } from './WorkQueueTable.styles';
import WorkQueueTableRow from './WorkQueueTableRow';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { WorkQueueFeatureFlags } from '../../models/loads/WorkQueueFeatureFlags';

interface WorkQueueTableProps {
    loading: boolean;
    onDisplayModal: (
        loadId: string
    ) => (actionForModal: WorkQueueTableModal) => void;
    loads: Load[];
    showError: boolean;
    children?: React.ReactNode;
}

const WorkQueueTableHeaders: (string | HeaderDetails)[] = [
    { title: 'Expanded', hide: true, width: '1rem', zIndex: 2 },
    { title: 'PL', tooltip: 'Part Load', width: '5rem', zIndex: 2 },
    { title: 'Sub', displayFrom: 'md', zIndex: 2 },
    { title: 'IOD', zIndex: 2 },
    { title: 'Trailer', displayFrom: 'lg', zIndex: 2 },
    { title: 'Trailer No.', displayFrom: 'lg', zIndex: 2 },
    { title: 'Type', displayFrom: 'sm', zIndex: 2 },
    { title: 'Status', zIndex: 2 },
    { title: 'Remaining', zIndex: 2 },
    { title: 'Destination', displayFrom: 'lg', zIndex: 2 },
    { title: 'Contact', width: '8rem', align: TextAlign.Center, zIndex: 2 },
    { title: 'Bay/Pager', displayFrom: 'xl', width: '5rem', zIndex: 2 },
    { title: 'Unload', align: TextAlign.Center, zIndex: 2 },
    { title: 'Load', align: TextAlign.Center, zIndex: 2 },
    { title: 'Pallets', displayFrom: 'md', align: TextAlign.Center, zIndex: 2 },
    { title: 'Drops', displayFrom: 'xl', align: TextAlign.Center, zIndex: 2 },
    { title: 'Action', hide: true, zIndex: 2 },
];

const WorkQueueTable: React.FC<Readonly<WorkQueueTableProps>> = ({
    loads,
    loading,
    showError,
    onDisplayModal,
}) => {
    const classes = useClasses();

    const [releaseLoadsFeature] = useFeatureFlag('phase1_releaseLoads');
    const [piggybackFeature] = useFeatureFlag('phase1_piggyback');
    const [updateTransferOrdersFeature] = useFeatureFlag(
        'phase1_updateTransferOrders'
    );
    const [cancelNoIodTransferFeature] = useFeatureFlag(
        'phase1_cancelNoIodTransfer'
    );

    const featureFlags: WorkQueueFeatureFlags = {
        releaseLoadsFeature: releaseLoadsFeature ?? false,
        piggybackFeature: piggybackFeature ?? false,
        updateTransferOrdersFeature: updateTransferOrdersFeature ?? false,
        cancelNoIodTransferFeature: cancelNoIodTransferFeature ?? false,
    };

    return (
        <Table
            className={classes.workQueueTable}
            headers={WorkQueueTableHeaders}
            loading={loading}
            placeholderRows={15}
            fixedHeader
            medium
        >
            {!showError &&
                loads
                    .sort(
                        (a, b) =>
                            new Date(a.loadByTime).valueOf() -
                            new Date(b.loadByTime).valueOf()
                    )
                    .map((l) => (
                        <WorkQueueTableRow
                            key={l.id}
                            load={l}
                            onDisplayModal={onDisplayModal}
                            featureFlags={featureFlags}
                        />
                    ))}
            {!loading && !showError && loads.length === 0 && (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No part loads were found for this store"
                    iconType="no-loads"
                    columnSpan={WorkQueueTableHeaders.length}
                    size="large"
                />
            )}
            {showError && (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="Error retrieving loads"
                    iconType="error"
                    columnSpan={WorkQueueTableHeaders.length}
                    size="large"
                    error
                />
            )}
        </Table>
    );
};

export default WorkQueueTable;
