import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const getSvgAsUri = (icon: IconDefinition, color?: string) => {
    const iconPath: string = icon.icon[4].toString();
    const iconWidth: number = icon.icon[0];
    const iconHeight: number = icon.icon[1];
    const iconFillColorString: string = color
        ? `fill='${encodeURIComponent(color!.toString())}'`
        : '';

    const svgString =
        'data:image/svg+xml;charset=UTF-8,' +
        '%3Csvg ' +
        `viewBox='0 0 ${iconWidth} ${iconHeight}' ` +
        "xmlns='http://www.w3.org/2000/svg' " +
        iconFillColorString +
        '%3E' +
        '%3Cpath ' +
        `d='${iconPath}'/%3E` +
        '%3C/svg%3E';

    return svgString;
};
