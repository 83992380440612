import * as React from 'react';
import TrailerResourceEditor from '../../../components/admin/bookingConfig/TrailerResourceEditor';
import CSSGrid from '../../../components/common/CSSGrid';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { buildClassesHook } from '../../../helpers/styles';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';
import { BookingType } from '../../../models/bookings/BookingType';
import { TrailerTypeResource } from '../../../models/bookings/StoreBookingResource';
import { getTrailerTypeLabelForBookingType } from '../../../services/trailerService';

const useClasses = buildClassesHook({
    trailerTypeEditSection: {
        margin: '20px 0',
    },
});

interface TrailerTypeConfigContainerProps {
    loading: boolean;
    selectedBookingType: BookingType | null;
    trailerTypeResources: TrailerTypeResource[];

    onTrailerResourceChange(trailerResource: TrailerTypeResource): void;
}

const TrailerTypeConfigContainer: React.FC<TrailerTypeConfigContainerProps> = ({
    loading,
    selectedBookingType,
    trailerTypeResources,
    onTrailerResourceChange,
}) => {
    const classes = useClasses();

    const handleTrailerResourceActiveChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        trailerTypeResource: TrailerTypeResource
    ) => {
        const editedTrailerTypeResource = {
            ...trailerTypeResource,
            active: event.target.checked,
        };
        onTrailerResourceChange(editedTrailerTypeResource);
    };

    return loading && !selectedBookingType ? (
        <LoadingIndicator />
    ) : (
        <>
            <div>Active Load Types:</div>
            <div>
                {trailerTypeResources.map((trailerResource, i) => (
                    <Checkbox
                        key={i}
                        checked={trailerResource.active}
                        inline
                        label={getTrailerTypeLabelForBookingType(
                            selectedBookingType!,
                            trailerResource.trailerType,
                            trailerResource.secondaryTrailerType
                        )}
                        onChange={(event) =>
                            handleTrailerResourceActiveChange(
                                event,
                                trailerResource
                            )
                        }
                    />
                ))}
            </div>
            <div className={classes.trailerTypeEditSection}>
                <CSSGrid
                    columns="repeat(2, 1fr)"
                    rows="repeat(1, 1fr)"
                    gap={20}
                >
                    {trailerTypeResources.map((trailerResource, i) => (
                        <TrailerResourceEditor
                            key={i}
                            selectedBookingType={selectedBookingType!}
                            trailerResource={trailerResource}
                            onTrailerResourceChange={onTrailerResourceChange}
                        />
                    ))}
                </CSSGrid>
            </div>
        </>
    );
};

export default TrailerTypeConfigContainer;
