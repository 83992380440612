import { isSameDay } from 'date-fns';
import * as React from 'react';

import { RouteKey } from '../../../../Routes/routes';
import { UserRole } from '../../../../helpers/userRole';
import Table from '../../../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../../../lib/bootstrap-ui/Table/Table';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingOptionType } from '../../../../models/bookings/BookingOptionType';
import { BookingType } from '../../../../models/bookings/BookingType';
import { useCurrentUser } from '../../../../services/authentication';
import { userInRole } from '../../../../services/userService';
import NoDataFoundTableRow from '../../../common/table/NoDataFoundTableRow';
import { useClasses } from './BookingTable.styles';
import BookingTableDateRow from './BookingTableDateRow';
import BookingTableNoBookingRow from './BookingTableNoBookingRow';
import BookingTableRow from './BookingTableRow';

interface BookingTableProps {
    bookings: Booking[] | null;
    completingBookingsIds: string[];
    loading: boolean;
    className?: string;
    showAction?: boolean;
    showStore?: boolean;

    onDisplayModal(bookingId: string, modalToDisplay: BookingOptionType): void;
}

const bookingTableHeaders: (string | HeaderDetails)[] = [
    {
        title: '',
        width: 5,
        noPadding: true,
    },
    { title: 'Reference', width: '9em' },
    'Type',
    { title: 'Haulier', displayFrom: 'xl' },
    'Start',
    'Duration',
    'Customer',
    {
        title: 'Cust. Ref',
        displayFrom: 'xl',
        tooltip: 'Customer Reference',
    },
    {
        title: 'Reg',
        displayFrom: 'xl',
        tooltip: 'Registration Plate',
    },
    'Bay/Pager',
    {
        title: 'ClT',
        tooltip: 'Clamp Truck',
    },
    {
        title: 'HbT',
        tooltip: 'Handball Team',
    },
    {
        title: 'CoT',
        tooltip: 'Counterbalance Truck',
    },
    {
        title: 'CvB',
        tooltip: 'Conveyor Belt',
    },
    {
        title: 'WS',
        tooltip: 'Weighing Scale',
    },
];

const simplifiedTableHeaders: (string | HeaderDetails)[] = [
    {
        title: '',
        width: '5px',
        noPadding: true,
    },
    'Reference',
    'Type',
    { title: 'Haulier', displayFrom: 'xl' },
    'Start',
    'Duration',
    'Customer',
    {
        title: 'Cust. Ref',
        displayFrom: 'xl',
        tooltip: 'Customer Reference',
    },
    {
        title: 'Reg',
        displayFrom: 'xl',
        tooltip: 'Registration Plate',
    },
    'Bay/Pager',
];

const BookingTable: React.FC<BookingTableProps> = ({
    className,
    bookings,
    loading,
    onDisplayModal,
    showAction,
    showStore,
    completingBookingsIds,
}) => {
    const classes = useClasses();

    const user = useCurrentUser();

    const showSimplifiedTableHeaders = userInRole(user)(UserRole.Gatehouse);

    const tableHeaders = showSimplifiedTableHeaders
        ? simplifiedTableHeaders
        : bookingTableHeaders;

    const getTableHeaders = () => {
        let headersToDisplay: (string | HeaderDetails)[] = tableHeaders;
        if (showStore) {
            headersToDisplay = [...headersToDisplay, 'Store'];
        }
        if (showAction) {
            headersToDisplay = [
                ...headersToDisplay,
                { title: 'Action', width: '1rem', hide: true },
            ];
        }
        return [...headersToDisplay, { title: 'Options', hide: true }];
    };

    const headers = getTableHeaders();

    return (
        <Table
            className={className}
            loading={loading}
            headers={headers}
            placeholderRows={10}
            fixedHeader
            hoverable={!!bookings?.length}
        >
            {bookings && (
                <>
                    {bookings.map((b, i) => (
                        <React.Fragment key={i}>
                            {(i === 0 ||
                                !isSameDay(
                                    new Date(bookings[i - 1].startDate),
                                    new Date(b.startDate)
                                )) && (
                                <BookingTableDateRow
                                    date={new Date(b.startDate)}
                                    colSpan={headers.length}
                                />
                            )}
                            {b.bookingType === BookingType.NoBookings ? (
                                <BookingTableNoBookingRow
                                    booking={b}
                                    onDisplayModal={onDisplayModal}
                                    showAction={showAction}
                                    showStore={showStore}
                                    showSimplifiedHeaders={
                                        showSimplifiedTableHeaders
                                    }
                                />
                            ) : (
                                <BookingTableRow
                                    booking={b}
                                    onDisplayModal={onDisplayModal}
                                    showStoreResources={
                                        !showSimplifiedTableHeaders
                                    }
                                    showAction={showAction}
                                    showStore={showStore}
                                    completing={completingBookingsIds.some(
                                        (id) => id === b.id
                                    )}
                                />
                            )}
                        </React.Fragment>
                    ))}
                    {!loading && bookings.length === 0 && (
                        <NoDataFoundTableRow
                            containerClassName={classes.noDataContainer}
                            messageText="No active bookings match your filters"
                            iconType="no-bookings"
                            columnSpan={headers.length}
                            size="large"
                            alternativeLink={{
                                route: RouteKey.BookingsHistory,
                                linkText: 'Search Bookings History',
                            }}
                        />
                    )}
                </>
            )}
        </Table>
    );
};

export default BookingTable;
