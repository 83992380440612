import { TrailerType } from '../trailers/TrailerType';
import { LoadingResource } from './LoadingResource';

export interface SiteResource {
    id: number;
    resource: LoadingResource;
    amount: number | null;
}

export interface StoreResource extends SiteResource {
    store: number;
}

export interface TrailerTypeResource {
    Id: number;
    store: number;
    bookingType: string;
    trailerType: TrailerType;
    resources: LoadingResource[];
    hourDuration: number;
    weekdayStartTime: string;
    weekdayEndTime: string;
    openAllDayWeekday: boolean;
    saturdayStartTime: string;
    saturdayEndTime: string;
    openAllDaySaturday: boolean;
    sundayStartTime: string;
    sundayEndTime: string;
    openAllDaySunday: boolean;
    pointsPerLoad: number;
    estimatedPalletCount: number | null;
    active: boolean;
    secondaryTrailerType: TrailerType | null;
}

export enum ResourceTime {
    Start = 'StartTime',
    End = 'EndTime'
}
