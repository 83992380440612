import { buildClassesHook } from '../../../helpers/styles';

export const useClasses = buildClassesHook({
    tableContainer: {
        display: 'flex',
        maxHeight: '32rem',
        overflow: 'auto',
    },
    loading: {
        maxHeight: '315px',
    },
});
