import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { formatDate } from '../../../../helpers/dates';
import debounce from '../../../../helpers/debounce';
import { pluralise } from '../../../../helpers/stringFunctions';
import { UserRole } from '../../../../helpers/userRole';
import Button from '../../../../lib/bootstrap-ui/Button';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingOptionType } from '../../../../models/bookings/BookingOptionType';
import { BookingType } from '../../../../models/bookings/BookingType';
import { apiPut } from '../../../../services/api';
import { useCurrentUser } from '../../../../services/authentication';
import { getBookingAction } from '../../../../services/bookingService';
import { getTrailerTypeLabelForBooking } from '../../../../services/trailerService';
import { userInRole } from '../../../../services/userService';
import Center from '../../../common/Center';
import Icon from '../../../common/Icon';
import MoreOptions from '../../../common/MoreOptions';
import HighlightableTableCell from '../../../common/table/HighlightableTableCell';
import BayOrPagerNumberCell from '../../BayOrPagerNumberCell';
import BookingColourSegment from '../../BookingColourSegment';
import BookingOptions from '../../BookingOptions';
import BookingTooltip from '../../tooltip/BookingTooltip';
import BookingTableRowAction, {
    ActionType,
} from '../../update/table/BookingTableRowAction';
import { useClasses } from './BookingTableRow.styles';

interface BookingTableRowProps {
    showStoreResources: boolean;
    booking: Booking;
    showAction?: boolean;
    showStore?: boolean;
    completing?: boolean;

    onDisplayModal(bookingId: string, modalToDisplay: BookingOptionType): void;
}

const BookingTableRow: React.FC<BookingTableRowProps> = ({
    showStoreResources,
    booking,
    onDisplayModal,
    showAction = true,
    showStore,
    completing,
}) => {
    const classes = useClasses();

    const user = useCurrentUser();

    const disableCheckInAndOut = userInRole(user)(UserRole.DockSystemReader);
    const disableEditBayOrPager = userInRole(user)(UserRole.Gatehouse);

    const [updatingBayOrPagerNumber, setUpdatingBayOrPagerNumber] =
        useState(false);
    const [bayOrPagerNumber, setBayOrPagerNumber] = useState<number | null>(
        booking.bayOrPagerNumber
    );
    const [showEditBayOrPagerIcon, setShowEditBayOrPagerIcon] = useState(false);
    const [previousBooking, setPreviousBooking] = useState<Booking | null>(
        null
    );

    const id = booking.recurringBooking ? booking.recurringBooking.id : '';

    const debouncedSetUpdateEnd = debounce(
        () => setPreviousBooking(booking),
        2000
    );

    useEffect(
        () => {
            if (previousBooking !== booking) {
                debouncedSetUpdateEnd();
            }
        },
        // eslint-disable-next-line
        [booking]
    );

    useEffect(() => {
        setBayOrPagerNumber(booking.bayOrPagerNumber);
    }, [booking.bayOrPagerNumber]);

    const handleActionClicked = (actionType: ActionType, id: string) => {
        if (actionType === 'Check In') {
            onDisplayModal(id, 'checkIn');
            return;
        }
        onDisplayModal(id, 'checkOut');
    };

    const handleBayOrPagerNumberChange = (value: string | null) => {
        const newBayOrPagerNumber = value ? parseInt(value) : null;
        setBayOrPagerNumber(newBayOrPagerNumber);
    };

    const handleBayOrPagerNumberSubmit = async () => {
        setUpdatingBayOrPagerNumber(false);
        if (booking.bayOrPagerNumber !== bayOrPagerNumber) {
            await apiPut(`Booking/${booking.id}/BayOrPagerNumber`, {
                bayOrPagerNumber: bayOrPagerNumber,
            });
        }
        setUpdatingBayOrPagerNumber(false);
    };

    useEffect(
        () => {
            setBayOrPagerNumber(booking.bayOrPagerNumber);
        },
        // eslint-disable-next-line
        [booking.id]
    );

    const bookingTrailerTypeLabel = getTrailerTypeLabelForBooking(
        booking,
        true
    );

    const bookingTrailerTypeLongDescriptionLabel =
        getTrailerTypeLabelForBooking(booking);

    const bookingAction = getBookingAction(booking, completing);
    const bookingHasSameIdAsPrevious =
        !!previousBooking && previousBooking.id === booking.id;
    const isRecurring = !!booking.recurringBooking && !!id;
    const hasAttachments =
        !!booking.attachments && booking.attachments.length > 0;
    const hasInstructions = !!booking.bookingInstructions;

    return (
        <tr
            className={classNames(classes.bookingTableRow, {
                [classes.noBookings]:
                    booking.bookingType === BookingType.NoBookings,
                [classes.onSite]:
                    booking.checkedInTime && !booking.checkedOutTime,
            })}
            onMouseLeave={() => setShowEditBayOrPagerIcon(false)}
            onMouseOver={() => setShowEditBayOrPagerIcon(true)}
        >
            <td
                className={classes.typeColumn}
                title={booking.bookingType || ''}
            >
                <BookingColourSegment bookingType={booking.bookingType} />
            </td>
            <td>
                <div className={classes.referenceData}>
                    <div className={classes.bookingRefText}>
                        <BookingTooltip
                            options={{ booking, type: 'booking' }}
                            showNoOrdersIcon
                            onTriggerClick={() =>
                                onDisplayModal(booking.id, 'bookingSummary')
                            }
                        />
                    </div>
                    <div
                        style={{
                            display: 'inline-flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Center direction="row">
                            {booking.isTimeSlotOverridden && (
                                <Icon
                                    className={classes.overriddenIcon}
                                    type="overridden"
                                    title="Booking's Time Slot is Overridden"
                                />
                            )}
                            {hasInstructions && (
                                <Button
                                    className={classes.iconButton}
                                    title="Booking Instructions"
                                    element="button"
                                    styleType="link"
                                    onClick={() =>
                                        onDisplayModal(
                                            booking.id,
                                            'instructions'
                                        )
                                    }
                                >
                                    <Icon
                                        className={classes.stickyNoteIcon}
                                        type="sticky-note"
                                    />
                                </Button>
                            )}
                            {hasAttachments && (
                                <Button
                                    className={classes.iconButton}
                                    title="Booking Attachments"
                                    element="button"
                                    styleType="link"
                                    onClick={() =>
                                        onDisplayModal(
                                            booking.id,
                                            'manageAttachments'
                                        )
                                    }
                                >
                                    <Icon
                                        className={classes.attachmentsIcon}
                                        type="paper-clip"
                                    />
                                </Button>
                            )}
                            {isRecurring && (
                                <Button
                                    element="button"
                                    className={classes.iconButton}
                                    styleType="link"
                                    onClick={() =>
                                        onDisplayModal(
                                            booking.id,
                                            'recurringBookingSummary'
                                        )
                                    }
                                    title={booking.recurringBooking?.reference}
                                >
                                    <Icon
                                        className={classes.recurringIcon}
                                        type="recurring-booking"
                                    />
                                </Button>
                            )}
                        </Center>
                    </div>
                </div>
            </td>
            <HighlightableTableCell
                highlight={
                    (previousBooking
                        ? getTrailerTypeLabelForBooking(previousBooking, true)
                        : null) !== bookingTrailerTypeLabel
                }
                highlightUpdates={bookingHasSameIdAsPrevious}
                title={bookingTrailerTypeLongDescriptionLabel}
            >
                {bookingTrailerTypeLabel}
            </HighlightableTableCell>
            <HighlightableTableCell
                className={classNames(
                    'd-none',
                    'd-xl-table-cell',
                    classes.restrictedCell,
                    classes.haulierName
                )}
                highlight={previousBooking?.haulierName !== booking.haulierName}
                highlightUpdates={bookingHasSameIdAsPrevious}
                title={booking.haulierName || undefined}
            >
                {booking.haulierName}
            </HighlightableTableCell>
            <HighlightableTableCell
                highlight={previousBooking?.startDate !== booking.startDate}
                highlightUpdates={bookingHasSameIdAsPrevious}
            >
                {formatDate(booking.startDate, 'HH:mm')}
            </HighlightableTableCell>
            <HighlightableTableCell
                highlight={
                    previousBooking?.hourDuration !== booking.hourDuration
                }
                highlightUpdates={bookingHasSameIdAsPrevious}
            >
                {pluralise('hour', booking.hourDuration)}
            </HighlightableTableCell>
            <HighlightableTableCell
                className={classes.restrictedCell}
                highlight={
                    previousBooking?.customerName !== booking.customerName
                }
                highlightUpdates={bookingHasSameIdAsPrevious}
                title={booking.customerName || undefined}
            >
                {booking.customerName}
            </HighlightableTableCell>
            <HighlightableTableCell
                className={classNames(
                    'd-none',
                    'd-xl-table-cell',
                    classes.restrictedCell
                )}
                highlight={
                    previousBooking?.customerReference !==
                    booking.customerReference
                }
                highlightUpdates={bookingHasSameIdAsPrevious}
                title={booking.customerReference || undefined}
            >
                {booking.customerReference}
            </HighlightableTableCell>
            <HighlightableTableCell
                className={classNames('d-none', 'd-xl-table-cell')}
                highlight={
                    previousBooking?.registrationPlate !==
                    booking.registrationPlate
                }
                highlightUpdates={bookingHasSameIdAsPrevious}
            >
                {booking.registrationPlate}
            </HighlightableTableCell>
            <BayOrPagerNumberCell
                className={classes.bayOrPagerCell}
                showEditIcon={!disableEditBayOrPager && showEditBayOrPagerIcon}
                editBayOrPagerNumber={updatingBayOrPagerNumber}
                bayOrPagerNumber={bayOrPagerNumber}
                previousValue={previousBooking?.bayOrPagerNumber}
                highlightUpdates={bookingHasSameIdAsPrevious}
                booking={booking}
                onEditButtonClick={() => setUpdatingBayOrPagerNumber(true)}
                onChange={handleBayOrPagerNumberChange}
                onSubmit={handleBayOrPagerNumberSubmit}
            />
            {showStoreResources && (
                <>
                    <HighlightableTableCell
                        highlight={
                            previousBooking?.resources.includes(
                                'Clamp Truck'
                            ) !== booking.resources.includes('Clamp Truck')
                        }
                        highlightUpdates={bookingHasSameIdAsPrevious}
                    >
                        {booking.resources.includes('Clamp Truck') && (
                            <Icon type="tick" />
                        )}
                    </HighlightableTableCell>
                    <HighlightableTableCell
                        highlight={
                            previousBooking?.resources.includes(
                                'Handball Team'
                            ) !== booking.resources.includes('Handball Team')
                        }
                        highlightUpdates={bookingHasSameIdAsPrevious}
                    >
                        {booking.resources.includes('Handball Team') && (
                            <Icon type="tick" />
                        )}
                    </HighlightableTableCell>

                    <HighlightableTableCell
                        highlight={
                            previousBooking?.resources.includes(
                                'Counterbalance Truck'
                            ) !==
                            booking.resources.includes('Counterbalance Truck')
                        }
                        highlightUpdates={bookingHasSameIdAsPrevious}
                    >
                        {booking.resources.includes('Counterbalance Truck') && (
                            <Icon type="tick" />
                        )}
                    </HighlightableTableCell>
                    <HighlightableTableCell
                        highlight={
                            previousBooking?.resources.includes(
                                'Conveyor Belt'
                            ) !== booking.resources.includes('Conveyor Belt')
                        }
                        highlightUpdates={bookingHasSameIdAsPrevious}
                    >
                        {booking.resources!.includes('Conveyor Belt') && (
                            <Icon type="tick" />
                        )}
                    </HighlightableTableCell>
                    <HighlightableTableCell
                        highlight={
                            previousBooking?.resources.includes(
                                'Weighing Scale'
                            ) !== booking.resources.includes('Weighing Scale')
                        }
                        highlightUpdates={bookingHasSameIdAsPrevious}
                    >
                        {booking.resources.includes('Weighing Scale') && (
                            <Icon type="tick" />
                        )}
                    </HighlightableTableCell>
                </>
            )}
            {showStore && <td>{booking.store}</td>}
            {showAction && (
                <HighlightableTableCell
                    highlightedClassName={classes.updatedLink}
                    highlight={
                        (previousBooking
                            ? getBookingAction(previousBooking)
                            : undefined) !== bookingAction
                    }
                    highlightUpdates={
                        bookingHasSameIdAsPrevious &&
                        bookingAction !== 'Loading'
                    }
                >
                    <BookingTableRowAction
                        booking={booking}
                        bookingAction={bookingAction}
                        disable={disableCheckInAndOut}
                        onActionInitiated={handleActionClicked}
                    />
                </HighlightableTableCell>
            )}
            <td>
                <MoreOptions onClose={() => setShowEditBayOrPagerIcon(false)}>
                    {(close: () => void) => (
                        <BookingOptions
                            bookingType={booking.bookingType!}
                            bookingStatus={booking.status}
                            bookingContactNumber={booking.contactNumber}
                            store={booking.store}
                            hideUndoCheckIn={disableCheckInAndOut}
                            onOptionRequested={(type) => {
                                close();
                                onDisplayModal(booking.id, type);
                            }}
                        />
                    )}
                </MoreOptions>
            </td>
        </tr>
    );
};

export default BookingTableRow;
