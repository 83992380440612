import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    progressSummaryTotalCellContent: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '0.5rem',
    },
    progressSummaryTotalCell: {
        borderLeft: `2px solid ${theme.colors.darkGrey} !important` /*TODO: Fix Table component styling*/,
        padding: '0 !important', //TODO: Fix Table component styling
    },
}));
