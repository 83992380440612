import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Modal, { ModalBody, ModalHeader } from '../../lib/bootstrap-ui/Modal';
import LoadingIndicator from '../loading/LoadingIndicator';

const useClasses = buildClassesHook({
    loadingModal: {
        minWidth: '200px',
    },
});

interface LoadingModalProps {
    className?: string;
    showModal: boolean;
    header?: string;
    loadingText?: string;
}

const LoadingModal: React.FC<LoadingModalProps> = ({
    className,
    showModal,
    header,
    loadingText,
}) => {
    const classes = useClasses();

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classNames(classes.loadingModal, className)}
        >
            {header && (
                <ModalHeader>
                    <h5>{header}</h5>
                </ModalHeader>
            )}
            <ModalBody>
                <LoadingIndicator text={loadingText} />
            </ModalBody>
        </Modal>
    );
};

export default LoadingModal;
