import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    status: {
        fontWeight: 'bold',
    },
    pallets: {
        fontSize: '0.9rem',
        fontWeight: 500,
    },
    loadByTime: {
        marginBottom: '0.25rem',
    },
    card: {
        minHeight: 175,
        width: 175,
        '& p': {
            lineHeight: 1,
        },
        background: theme.colors.nearlyWhite,
    },
    selectedFinishingTrailerCard: {
        background: theme.colors.primaryLight,
    },
    selectedTransferCard: {
        background: theme.colors.primaryContrastLight,
    },
    stackedCard: {
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: 'inherit',
            backgroundColor: 'inherit',
            width: 'inherit',
            height: 'inherit',
            border: 'inherit',
        },
        '&:before': {
            zIndex: -1,
            transform: 'translate(3px, 6px)',
        },
        '&:after': {
            zIndex: -2,
            transform: 'translate(8px, 10px)',
        },
    },
    finishingTrailerLoadCard: {
        border: `2px solid ${theme.colors.primary}`,
    },
    transferCard: {
        border: `2px solid ${theme.colors.primaryContrast}`,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0.5rem',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '1.2rem',
        '& p': {
            margin: 0,
        },
        '& *': {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
        },
        '& :first-child': {
            justifyContent: 'flex-start',
        },
        '& :last-child': {
            justifyContent: 'flex-end',
        },
    },
    bodyContainer: {
        height: 'unset',
        width: 'unset',
        margin: '0.5rem 0',
        '& h4': {
            marginBottom: '0.3rem',
        },
        '& p': {
            marginBottom: '0.6rem',
        },
    },
    statusContainer: {
        marginBottom: '0.15rem',
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontSize: '1.2rem',
        textAlign: 'right',
        '& p': {
            marginBottom: '0.3rem',
        },
    },
    partLoadRef: {
        margin: 0,
        lineHeight: 1,
    },
    transportOnlyCard: {
        border: `2px dashed ${theme.colors.grey}`,
    },
}));
