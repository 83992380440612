import * as React from 'react';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import HighlightableTableCell from './HighlightableTableCell';
import DropdownOption from '../../../lib/bootstrap-ui/Dropdown/DropdownOption';

interface EditableCellProps {
    className?: string;
    dropdownClassName?: string;
    formGroupClassName?: string;
    edit: boolean;
    options: DropdownOption[];
    value: string | number | string[] | null;
    type?: 'text' | 'number' | 'email' | 'date';
    title?: string;
    showEditButton: boolean;
    previousValue?: string | number | string[] | null;
    highlightUpdates?: boolean;
    maxValue?: number;
    onEditButtonClick(): void;
    onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
    onSubmit(): void;
    onEnterPressed?(): void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    className,
    dropdownClassName,
    formGroupClassName,
    showEditButton,
    options,
    value,
    title,
    previousValue,
    highlightUpdates = false,
    onChange,
    onSubmit,
    onEnterPressed
}) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            onEnterPressed && onEnterPressed();
        }
    };

    return (
        <HighlightableTableCell
            className={className}
            highlight={previousValue !== value}
            title={title}
            highlightUpdates={highlightUpdates && !showEditButton}
        >
            <SelectDropdown
                dropdownClassName={dropdownClassName}
                formGroupClassName={formGroupClassName}
                dropdownOptions={options}
                selectedOption={value}
                disabled={!showEditButton}
                onChange={onChange}
                onBlur={onSubmit}
                onKeyDown={handleKeyDown}
                hideBottomPadding
            />
        </HighlightableTableCell>
    );
};

export default EditableCell;
