import { ReducerAction } from '../models/reducers/ReducerAction';

type ActionType = 'ADD_PALLETS' | 'REMOVE_PALLETS' | 'REPLACE_PALLETS';

interface AddPalletsAction extends ReducerAction<ActionType, string[]> {
    type: 'ADD_PALLETS';
}

interface RemovePalletsAction extends ReducerAction<ActionType, string[]> {
    type: 'REMOVE_PALLETS';
}

interface ReplacePalletsAction extends ReducerAction<ActionType, string[]> {
    type: 'REPLACE_PALLETS';
}

export const UnpickedPalletCountReducer = (
    unpickedPalletIds: string[],
    action: AddPalletsAction | RemovePalletsAction | ReplacePalletsAction
): string[] => {
    switch (action.type) {
        case 'ADD_PALLETS':
            return [...unpickedPalletIds, ...action.payload];
        case 'REMOVE_PALLETS':
            return unpickedPalletIds.filter(
                id => !action.payload.some(rId => rId === id)
            );
        case 'REPLACE_PALLETS':
            return action.payload;
    }
};
