import React from 'react';
import { CanAccessConsumer } from '../../../contexts/CanAccessContext';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { useCurrentUser } from '../../../services/authentication';
import { userIsTester } from '../../../services/userService';

const AccessControlSuspendedModal: React.FC = () => {
    const user = useCurrentUser();

    return (
        <CanAccessConsumer>
            {({ canAccess, enableAccess }) =>
                !canAccess && (
                    <div>
                        <Modal isOpen={true}>
                            <ModalHeader>
                                <h5>Access Denied</h5>
                            </ModalHeader>
                            <ModalBody>
                                Access to Dock System and Bookings has been
                                temporarily suspended. Please try again later.
                            </ModalBody>
                            {userIsTester(user) && (
                                <ModalFooter>
                                    <Button
                                        styleType="danger"
                                        onClick={enableAccess}
                                    >
                                        Allow Access
                                    </Button>
                                </ModalFooter>
                            )}
                        </Modal>
                    </div>
                )
            }
        </CanAccessConsumer>
    );
};

export default AccessControlSuspendedModal;
