import * as React from 'react';

import { createContext, useEffect } from 'react';
import { apiPut, useData } from '../services/api';
import { useAuth } from '../services/authentication';

const CanAccessContext = createContext({
    canAccess: true,
    /* eslint-disable */
    revokeAccess: () => {},
    enableAccess: () => {},
    fetchCanAccess: () => {},
    /* eslint-enable */
});

const CanAccessProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [canAccessApi, , fetchCanAccessApi] =
        useData<boolean>('accessControl');
    const {
        authState: { loggedIn },
    } = useAuth();

    useEffect(
        () => {
            const fetchData = async () => {
                await fetchCanAccessApi();
            };
            if (loggedIn) {
                fetchData();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loggedIn]
    );

    const revokeAccess = async () => {
        await apiPut(`AccessControl/${false}`);
        await fetchCanAccessApi();
    };

    const enableAccess = async () => {
        await apiPut(`AccessControl/${true}`);
        await fetchCanAccessApi();
    };

    const fetchCanAccess = async () => {
        await fetchCanAccessApi();
    };

    return (
        <CanAccessContext.Provider
            value={{
                canAccess: canAccessApi ?? true,
                revokeAccess,
                enableAccess,
                fetchCanAccess,
            }}
        >
            {children}
        </CanAccessContext.Provider>
    );
};

const CanAccessConsumer = CanAccessContext.Consumer;

export { CanAccessContext, CanAccessProvider, CanAccessConsumer };
