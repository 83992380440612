import * as React from 'react';

import Icon from '../../../../components/common/Icon';
import LoadDetailInfo from '../../../../components/load/loadingProgress/detail/view/LoadDetailInfo';
import PickListDownloadTable from '../../../../components/load/pickList/PickListDownloadTable';
import TimeElapsedSince from '../../../../components/time/TimeElapsedSince';
import LoadTimeRemaining from '../../../../components/time/LoadTimeRemaining';
import Button from '../../../../lib/bootstrap-ui/Button';
import Grid, { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import Table from '../../../../lib/bootstrap-ui/Table';
import { Load } from '../../../../models/loads/Load';
import { useClasses } from './LoadingProgressLoadInfoContainer.styles';

interface LoadingProgressLoadInfoContainerProps {
    load: Load;
    loadCompletedTime?: Date;
    onBackButtonClick(): void;
}

const LoadingProgressLoadInfoContainer: React.FC<
    LoadingProgressLoadInfoContainerProps
> = ({ load, loadCompletedTime, onBackButtonClick }) => {
    const classes = useClasses();

    return (
        <Grid fluid className={classes.loadInfoContainer}>
            <Row>
                <Column>
                    <Button
                        className={classes.summaryButton}
                        styleType="link"
                        onClick={onBackButtonClick}
                    >
                        <div className={classes.backButtonContentContainer}>
                            <Icon
                                className={classes.backButtonIcon}
                                type="back"
                            />
                            Back
                        </div>
                    </Button>
                </Column>
                <Column className={classes.fieldsContainer}>
                    <Table noRowBorders>
                        <tr>
                            <LoadDetailInfo label="Bay">
                                <h5 title={`${load.bayId}`}>
                                    {load.bayId || ''}
                                </h5>
                            </LoadDetailInfo>
                            <LoadDetailInfo label="Remaining">
                                <LoadTimeRemaining
                                    className={classes.timeFromNow}
                                    loadByTime={load.loadByTime}
                                    completedTime={load.completedAt}
                                    useColors
                                />
                            </LoadDetailInfo>
                            <LoadDetailInfo label="Trailer">
                                <h5 title={load.trailerId || undefined}>
                                    {load.isTunnelTrailer || load.isInternal
                                        ? 'N/A'
                                        : load.trailerId || ''}
                                </h5>
                            </LoadDetailInfo>
                        </tr>
                        <tr>
                            <LoadDetailInfo label="PL">
                                <h5 title={`${load.loadName}`}>
                                    {load.loadName}
                                </h5>
                            </LoadDetailInfo>
                            <LoadDetailInfo label="Time&nbsp;Taken">
                                {load.releasedAt && (
                                    <TimeElapsedSince
                                        className={classes.timeFromNow}
                                        elapsedSince={new Date(load.releasedAt)}
                                        elapsedUntil={loadCompletedTime}
                                        useColors
                                    />
                                )}
                            </LoadDetailInfo>
                            <LoadDetailInfo label="Type">
                                <h5 title={load.trailerType}>
                                    {load.trailerType || ''}
                                </h5>
                            </LoadDetailInfo>
                        </tr>
                        <tr>
                            <LoadDetailInfo
                                className={classes.destinationInfo}
                                label="IOD"
                                colspan={3}
                            >
                                <h5 title={load.associatedIodNumber || ''}>
                                    {load.associatedIodNumber || ''}
                                </h5>
                            </LoadDetailInfo>
                            <LoadDetailInfo label="Load&nbsp;Type">
                                <h5 title={load.loadType}>
                                    {load.loadType || ''}
                                </h5>
                            </LoadDetailInfo>
                        </tr>
                        <tr>
                            <LoadDetailInfo
                                className={classes.destinationInfo}
                                label="Destination"
                                colspan={5}
                            >
                                <h5 title={load.destination}>
                                    {load.destination || ''}
                                </h5>
                            </LoadDetailInfo>
                        </tr>
                    </Table>
                </Column>
                <Column className={classes.pickListDownloadContainer}>
                    <h5>
                        Download Pick Lists
                        {load.orderLoadingStatuses
                            .filter((ols) => ols.releaseToChess)
                            .some((ols) =>
                                ols.order.pickLists.some(
                                    (pl) => pl.outstandingAmendment
                                )
                            ) && (
                            <Icon
                                className={classes.warningIcon}
                                title="Outstanding Amendments need to be downloaded"
                                type="warning-thicc"
                            />
                        )}
                    </h5>
                    <div className={classes.pickListDownloadTable}>
                        <PickListDownloadTable load={load} />
                    </div>
                </Column>
            </Row>
        </Grid>
    );
};

export default LoadingProgressLoadInfoContainer;
