import * as React from 'react';
import { useState } from 'react';
import CancelBookingModalContainer from '../../../../containers/bookings/CancelBookingModalContainer';
import RecurringBookingEditModalContainer from '../../../../containers/recurringBookings/modals/RecurringBookingEditModalContainer';
import { buildClassesHook } from '../../../../helpers/styles';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { RecurringBooking } from '../../../../models/bookings/RecurringBooking';
import MoreOptions from '../../../common/MoreOptions';
import RecurringBookingOptions, {
    RecurringBookingOptionType,
} from '../../recurring/RecurringBookingOptions';
import BookingSummary from '../../view/summary';
import ViewHistoryModal from '../bookingHistory/ViewHistoryModal';
import EmailModal from '../EmailModal';

const useClasses = buildClassesHook({
    recurringSummaryModal: {
        width: '80%',
        maxWidth: '550px',
    },
});

interface RecurringSummaryModalprops {
    recurringBooking: RecurringBooking;
    showModal: boolean;
    onClose(): void;
}

const RecurringSummaryModal: React.FC<RecurringSummaryModalprops> = ({
    recurringBooking,
    showModal,
    onClose,
}) => {
    const classes = useClasses();

    const [modalToDisplay, setModalToDisplay] =
        useState<RecurringBookingOptionType | null>(null);

    const resetModalToDisplay = () => setModalToDisplay(null);

    return (
        <Modal
            modalClassName={classes.recurringSummaryModal}
            isOpen={showModal}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h4>
                    Recurring {recurringBooking.bookingType} Booking Summary
                </h4>
                <MoreOptions>
                    {(close: () => void) => (
                        <RecurringBookingOptions
                            bookingType={recurringBooking.bookingType}
                            onOptionRequested={(type) => {
                                close();
                                setModalToDisplay(type);
                            }}
                        />
                    )}
                </MoreOptions>
            </ModalHeader>
            <ModalBody>
                <BookingSummary
                    options={{
                        booking: recurringBooking,
                        bookingType: 'recurring',
                    }}
                    hideSendEmail
                    hideTitle
                />
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
            {modalToDisplay === 'cancel' && (
                <CancelBookingModalContainer
                    options={{
                        bookingType: 'recurring',
                        booking: recurringBooking,
                    }}
                    showModal={modalToDisplay === 'cancel'}
                    onCancelBooking={onClose}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'email' && (
                <EmailModal
                    booking={recurringBooking}
                    bookingType="recurring"
                    showModal={modalToDisplay === 'email'}
                    onEmailSent={resetModalToDisplay}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'edit' && (
                <RecurringBookingEditModalContainer
                    recurringBooking={recurringBooking}
                    showModal={modalToDisplay === 'edit'}
                    onEmailSent={resetModalToDisplay}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'viewHistory' && (
                <ViewHistoryModal
                    booking={recurringBooking}
                    type="recurringBooking"
                    showModal={modalToDisplay === 'viewHistory'}
                    onClose={resetModalToDisplay}
                />
            )}
        </Modal>
    );
};

export default RecurringSummaryModal;
