import * as React from 'react';
import DatePicker from '../DatePicker';
import { startOfDay } from 'date-fns';
import { WeekDay } from '../DatePicker/DatePickerBody';
import TimeSlotPicker from './TimeSlotPicker';
import TimeSlot from '../../../models/timeSlot/TimeSlot';

interface DateTimeSlotPickerProps {
    excludedDates?: Date[];
    disableWeekends?: boolean;
    disabled?: boolean;
    loading?: boolean;
    highlightedTimeSlots?: TimeSlot[];
    selectedDate: Date;
    selectedTimeSlot: TimeSlot | null;
    allowOverrideFullTimeSlots?: boolean;
    timeSlots: TimeSlot[];
    weekStart?: WeekDay;

    onDateSelected(selectedDate: Date): void;
    onMonthChange(startOfMonth: Date): void;
    onTimeSlotSelected(selectedTimeSlot: TimeSlot): void;
}

const DateTimeSlotPicker: React.FC<DateTimeSlotPickerProps> = ({
    excludedDates = [],
    disableWeekends = false,
    disabled = false,
    loading = false,
    highlightedTimeSlots,
    selectedDate,
    allowOverrideFullTimeSlots = false,
    selectedTimeSlot,
    timeSlots,
    onDateSelected,
    onMonthChange,
    onTimeSlotSelected
}) => (
    <>
        <DatePicker
            selectedDay={selectedDate}
            disabled={disabled}
            disableWeekends={disableWeekends}
            excludedDates={excludedDates}
            minDate={startOfDay(new Date())}
            onDateSelected={onDateSelected}
            onMonthChange={onMonthChange}
        />
        <TimeSlotPicker
            allowOverrideFullTimeSlots={allowOverrideFullTimeSlots}
            disabled={disabled}
            loading={loading}
            selectedTimeSlot={selectedTimeSlot}
            highlightedTimeSlots={highlightedTimeSlots}
            timeSlots={timeSlots}
            onTimeSlotSelected={onTimeSlotSelected}
        />
    </>
);

export default DateTimeSlotPicker;
