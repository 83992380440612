import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Icon from './Icon';

const useClasses = buildClassesHook({
    pageLink: {
        cursor: 'pointer',
        userSelect: 'none',
    },
    cursorNotAllowed: {
        cursor: 'not-allowed',
    },
});

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    maxPageLinks?: number;
    className?: string;
    size?: 'sm' | 'md' | 'lg';
    onPageLinkClick(page: number): void;
}

type PageLinkOptions = {
    key: string | number;
    content: any;
    pageNumber: number;
    disabled: boolean;
};

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    maxPageLinks = 5,
    className,
    size,
    onPageLinkClick,
}) => {
    const classes = useClasses();

    maxPageLinks = totalPages > maxPageLinks ? maxPageLinks : totalPages;

    const calculatePageNumberToStartFrom = () => {
        let pageNumber = currentPage - Math.floor(maxPageLinks / 2);
        if (pageNumber > totalPages - maxPageLinks) {
            pageNumber = totalPages - maxPageLinks + 1;
        }
        if (pageNumber < 1) {
            pageNumber = 1;
        }
        return pageNumber;
    };

    const createPageLink = (options: PageLinkOptions) => (
        <li
            key={options.key}
            className={classNames('page-item', {
                disabled: options.disabled,
                [classes.cursorNotAllowed]: options.disabled,
            })}
        >
            <span
                className={classNames(classes.pageLink, 'page-link')}
                onClick={() => onPageLinkClick(options.pageNumber)}
            >
                {options.content}
            </span>
        </li>
    );

    const createNumberedPageLinks = () => {
        const pageNumberToStartFrom = calculatePageNumberToStartFrom();
        const pages: JSX.Element[] = [];
        for (let i = 0; i < maxPageLinks; i++) {
            pages.push(
                createPageLink({
                    key: i,
                    content: i + pageNumberToStartFrom,
                    pageNumber: i + pageNumberToStartFrom,
                    disabled: currentPage === i + pageNumberToStartFrom,
                })
            );
            if (i + pageNumberToStartFrom === totalPages) {
                break;
            }
        }
        return pages;
    };

    return (
        <nav className={className} aria-label="Pagination">
            <ul
                className={classNames('pagination', 'justify-content-center', {
                    'pagination-sm': size === 'sm',
                    'pagination-lg': size === 'lg',
                })}
            >
                {createPageLink({
                    key: 'previous',
                    content: <Icon type="previous" />,
                    pageNumber: currentPage - 1,
                    disabled: currentPage === 1,
                })}
                {createNumberedPageLinks()}
                {createPageLink({
                    key: 'next',
                    content: <Icon type="next" />,
                    pageNumber: currentPage + 1,
                    disabled: currentPage === totalPages,
                })}
            </ul>
        </nav>
    );
};

export default Pagination;
