import * as React from 'react';
import BayPicker from '../../components/releasing/BayPicker';
import Bay from '../../models/bays/Bay';
import { useData } from '../../services/api';
import { mapBaysToDropdownOptions } from '../../services/bayService';
import { TrailerType } from '../../models/trailers/TrailerType';
import { useEffect, useState } from 'react';
import { BayStatus } from '../../models/bays/BayStatus';

interface BayPickerContainerProps {
    storeId?: number;
    trailerType: TrailerType | null;
    selectedBay: Bay | null;
    bayOrPagerNumber?: number | null;
    bayStatuses: BayStatus[] | null;
    virtualBaysOnly?: boolean;
    excludeVirtualBays?: boolean;
    onBayChange: (bay: Bay | null) => void;
    setCheckingBay: () => void;
}

const BayPickerContainer: React.FC<BayPickerContainerProps> = ({
    storeId,
    trailerType,
    selectedBay,
    bayOrPagerNumber,
    bayStatuses,
    virtualBaysOnly = false,
    excludeVirtualBays = false,
    onBayChange,
    setCheckingBay,
}) => {
    const getBaysUrl = () => {
        switch (trailerType) {
            case 'Handball':
                return `Store/${storeId}/Bays/ValidHandballBays`;
            case 'Specialised':
                return `Store/${storeId}/Bays/ValidStackUpBays`;
        }
        return `Store/${storeId}/Bays/Valid`;
    };

    const [bays, loading, refreshExistingBays] = useData<Bay[]>(getBaysUrl(), {
        virtualBaysOnly: String(virtualBaysOnly),
        loadingBaysOnly: String(excludeVirtualBays),
    });

    const [bayPickerDirty, setBayPickerDirty] = useState(false);

    useEffect(
        () => {
            if (selectedBay?.isOutOfOrder ?? false) {
                onBayChange(null);
            }
            refreshExistingBays();
        },
        // eslint-disable-next-line
        [bayStatuses]
    );

    useEffect(
        () => {
            const newSelectedBay = bays?.find((b) => b.id === bayOrPagerNumber);

            if (bayOrPagerNumber && newSelectedBay && !bayPickerDirty) {
                setCheckingBay();
                onBayChange(newSelectedBay);
            }
        },
        // eslint-disable-next-line
        [bays, bayOrPagerNumber]
    );

    const getValueAsStringOrNull = (value: number | null) => {
        if (value !== null) {
            return String(value);
        }
        return null;
    };

    const handleBayChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCheckingBay();
        const bayNumber = parseInt(event.target.value, 10);
        const newSelectedBay = bays?.find((b) => b.id === bayNumber) || null;
        onBayChange(newSelectedBay);
        setBayPickerDirty(true);
    };

    return (
        <BayPicker
            bayDropDownOptions={mapBaysToDropdownOptions(bays) || []}
            disabled={loading}
            loadingBays={loading}
            selectedOption={getValueAsStringOrNull(selectedBay?.id || null)}
            onBayChange={handleBayChanged}
        />
    );
};

export default BayPickerContainer;
