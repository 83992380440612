import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Button from '../../lib/bootstrap-ui/Button';
import { theme } from '../../theme';
import Icon, { IconType } from './Icon';

const useClasses = buildClassesHook({
    button: {
        textDecoration: 'none',
        padding: '8px 16px',
        border: 0,
        '&:hover': {
            textDecoration: 'none',
            color: theme.colors.primaryDark,
        },
    },
    buttonNotSelected: {
        backgroundColor: theme.colors.white,
        color: theme.colors.primary,
    },
    buttonSelected: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        '&:hover': {
            color: theme.colors.white,
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
    navIcon: {
        marginRight: '0.5rem',
    },
});

interface IconButtonProps {
    active: boolean;
    buttonText: string;
    icon: IconType;
    hideText?: boolean;
    onClick(): void;
}

const IconButton: React.FC<IconButtonProps> = ({
    active,
    buttonText,
    icon,
    hideText,
    onClick,
}) => {
    const classes = useClasses();

    return (
        <Button
            className={classNames(
                classes.button,
                active ? classes.buttonSelected : classes.buttonNotSelected
            )}
            styleType="link"
            onClick={onClick}
        >
            <Icon
                type={icon}
                className={classNames({
                    [classes.navIcon]: !hideText,
                })}
            />
            {!hideText && buttonText}
        </Button>
    );
};

export default IconButton;
