import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    LoadSummaryContainer: {
        width: '100%',
        margin: '0 auto',
        padding: '1rem 0',
        maxWidth: 650,
    },
    ContentContainer: {
        margin: '1rem',
        padding: '0.5rem',
    },
    ButtonContainer: {},
    ButtonIcon: {
        color: theme.colors.white,
        marginLeft: '0.5rem',
        fontSize: '0.8rem',
    },
}));
