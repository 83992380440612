import classNames from 'classnames';
import * as React from 'react';

import { IodPlanStatistics } from '../../../services/iodPlanStatisticsService';
import Icon, { IconType } from '../../common/Icon';
import { useClasses } from './IodPlanStatComparison.styles';

interface IodPlanStatComparison {
    header: string;
    current: IodPlanStatistics;
    property: keyof IodPlanStatistics;
    comparisons?: IodPlanStatistics[];
}

const getIconType = (difference: number): IconType => {
    if (difference === 0) {
        return 'arrow-thicc-right';
    }

    return difference < 0 ? 'arrow-thicc-down' : 'arrow-thicc-up';
};

const IodPlanStatComparison: React.FC<IodPlanStatComparison> = ({
    header,
    current,
    property,
    comparisons,
}) => {
    const classes = useClasses();

    return (
        <span className={classes.infoSection}>
            <span className={classes.statsHeader}>{`${header}: `}</span>
            <span className={classes.statsValue}>
                {current[property]}{' '}
                {comparisons &&
                    comparisons.map((stats, index) => {
                        const difference: number =
                            stats[property] - current[property];

                        return (
                            <span
                                key={index}
                                className={classNames(
                                    classes.comparisonSection,
                                    {
                                        [classes.positive]: difference > 0,
                                        [classes.negative]: difference < 0,
                                    }
                                )}
                            >
                                <Icon
                                    className={classes.arrowIcon}
                                    type={getIconType(difference)}
                                />
                                {stats[property]}
                            </span>
                        );
                    })}
            </span>
        </span>
    );
};

export default IodPlanStatComparison;
