import classnames from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';
import Icon from '../../../../components/common/Icon';
import {
    safeTrim,
    whitespaceToNull,
} from '../../../../helpers/stringFunctions';
import Button from '../../Button';
import InputBase from '../Base/InputBase';
import { useClasses } from './TextInput.styles';

interface TextInputProps {
    id?: string;
    clearable?: boolean;
    className?: string;
    hideBottomPadding?: boolean;
    disabled?: boolean;
    label?: string;
    title?: string;
    helpText?: string;
    error?: string | null;
    inline?: boolean;
    minValue?: string | number | undefined;
    maxValue?: string | number | undefined;
    placeholder?: string;
    size?: 'small' | 'default' | 'large';
    step?: string | number;
    type?: 'text' | 'number' | 'email' | 'date';
    readonly?: boolean;
    value: string | number | string[] | undefined | null;
    onBlur?(): void;
    onClick?(): void;
    onChange?(value: string | null): void;
    onClear?(): void;
    onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
    autoConvertWhitespace?: boolean;
    autoTrim?: boolean;
    autoFocus?: boolean;
    highlightOnFocus?: boolean;
}

const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> = ({
    className,
    clearable = false,
    disabled,
    minValue,
    maxValue,
    placeholder,
    title = '',
    size,
    step,
    type = 'text',
    readonly,
    value,
    onBlur,
    onClick,
    onClear,
    onChange,
    onKeyDown,
    autoConvertWhitespace = false,
    autoTrim = false,
    autoFocus = false,
    highlightOnFocus = false,
    ...rest
}) => {
    const classes = useClasses();

    const [textContent, setTextContent] = React.useState(value);
    const [focussed, setFocussed] = React.useState(false);

    const handleInputChange = (value: string) => {
        setTextContent(value);

        let newValue: string | null = value;

        if (autoTrim) {
            newValue = safeTrim(newValue);
        }

        if (autoConvertWhitespace) {
            newValue = whitespaceToNull(newValue);
        }

        onChange && onChange(newValue);
    };

    useEffect(() => {
        setTextContent(value);
    }, [value]);

    return (
        <InputBase className={className} {...rest}>
            {({ className, onBlur: baseOnBlur, id }) => (
                <div
                    className={classNames(classes.inputContainer, {
                        [classes.focussed]: focussed,
                    })}
                    onFocus={() => setFocussed(true)}
                    onBlur={() => setFocussed(false)}
                >
                    <input
                        id={id}
                        autoFocus={autoFocus}
                        title={title}
                        type={type}
                        className={classnames(
                            className,
                            'form-control',
                            classes.input,
                            {
                                [classes.readonly]: readonly,
                                'form-control-sm': size === 'small',
                                'form-control-lg': size === 'large',
                            }
                        )}
                        placeholder={placeholder}
                        min={minValue}
                        max={maxValue}
                        step={step}
                        value={textContent ?? ''}
                        onChange={(event) =>
                            handleInputChange(event.target.value)
                        }
                        onFocus={(event) =>
                            highlightOnFocus && event.target.select()
                        }
                        readOnly={readonly}
                        disabled={disabled}
                        onBlur={() => {
                            baseOnBlur();
                            if (onBlur) {
                                onBlur();
                            }
                        }}
                        onClick={onClick}
                        onKeyDown={onKeyDown}
                    />
                    {!!textContent && clearable && (
                        <Button
                            className={classes.clearButton}
                            styleType="link"
                            stopOnClickPropagation
                            onClick={() => {
                                if (onClear) {
                                    onClear();
                                }
                            }}
                        >
                            <Icon type="cross" />
                        </Button>
                    )}
                </div>
            )}
        </InputBase>
    );
};

export default TextInput;
