import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Button from '../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../lib/bootstrap-ui/Modal';

const useClasses = buildClassesHook({
    unsavedChangesModal: {
        maxHeight: '90vh',
        minWidth: '400px',
    },
});

interface UnsavedChangesModalProps {
    isOpen: boolean;
    onRequestDiscardChanges(): void;
    onRequestClose(): void;
    onRequestGoBack(): void;
}

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
    isOpen,
    onRequestDiscardChanges,
    onRequestClose,
    onRequestGoBack,
}) => {
    const classes = useClasses();

    return (
        <Modal
            isOpen={isOpen}
            modalClassName={classes.unsavedChangesModal}
            onRequestClose={onRequestClose}
        >
            <ModalHeader>
                <h5>Unsaved Changes</h5>
            </ModalHeader>
            <ModalBody>
                Are you sure you want to discard unsaved changes?
            </ModalBody>
            <ModalFooter>
                <Button styleType="danger" onClick={onRequestDiscardChanges}>
                    Discard Changes
                </Button>
                <Button styleType="secondary" onClick={onRequestGoBack}>
                    Go Back
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default UnsavedChangesModal;
