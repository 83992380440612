import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { LoadingMilestoneThreshold } from '../../models/loads/LoadingMilestoneThreshold';
import { LoadWithPallets } from '../../models/loads/LoadsWithPallets';
import { Pallet, PalletStatus } from '../../models/pallets/Pallet';
import { PalletLoadingProgressMilestones } from '../../models/pallets/PalletLoadingProgress';
import EditableDropdownCell from '../common/table/EditableDropdownCell';
import IodDetailsLink from '../helpers/IodDetailsLink';
import PartLoadReference from '../load/common/view/PartLoadReference';
import LoadTimeRemaining from '../time/LoadTimeRemaining';
import ProgressSummaryTableMilestoneCell from './ProgressSummaryTableMilestoneCell';
import { useClasses } from './ProgressSummaryTableRow.styles';
import { getSubcontractedString } from '../../services/loadService';

interface ProgressSummaryTableRowProps {
    loadWithPallets: LoadWithPallets;
    milestoneThresholds: LoadingMilestoneThreshold[];
    onLoadUpdated(updatedLoad: LoadWithPallets): void;
    disablePriority: boolean;
}

const sortByDate = (a: string | null, b: string | null): number => {
    if (a === null) {
        return 1;
    }
    if (b === null) {
        return -1;
    }

    return new Date(a).getTime() - new Date(b).getTime();
};

const filterPalletsByStatus = (
    pallets: Pallet[],
    status: PalletStatus
): Pallet[] => {
    return pallets.filter((p) => p.status === status);
};

const getMilestoneTimeForStatus = (
    pallets: Pallet[],
    status: PalletStatus,
    milestoneName: keyof PalletLoadingProgressMilestones
): string | null =>
    filterPalletsByStatus(pallets, status)
        .sort((a, b) =>
            sortByDate(
                a.loadingProgress[milestoneName],
                b.loadingProgress[milestoneName]
            )
        )
        .shift()?.loadingProgress[milestoneName] || null;

const milestoneIsComplete = (
    pallets: Pallet[],
    status: PalletStatus,
    milestoneName: keyof PalletLoadingProgressMilestones
): boolean => {
    const palletsInStatus = filterPalletsByStatus(pallets, status);
    if (palletsInStatus.length > 0) {
        return false;
    }

    return pallets.some((p) => p.loadingProgress[milestoneName] !== null);
};

const thresholdDurationForMilestone = (
    milestoneThresholds: LoadingMilestoneThreshold[],
    milestone: PalletStatus
): string | null => {
    return (
        milestoneThresholds.find((mt) => mt.milestone === milestone)
            ?.thresholdDuration || null
    );
};

const ProgressSummaryTableRow: React.FC<ProgressSummaryTableRowProps> = ({
    loadWithPallets,
    milestoneThresholds,
    onLoadUpdated,
    disablePriority,
}) => {
    const classes = useClasses();

    const [showEditPriorityIcon, setShowEditPriorityIcon] = useState(false);
    const [editPriority, setEditPriority] = useState(false);

    const handlePriorityChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const priority = event.target.value as 'High' | 'Standard';
        const updatedLoadWithPallets: LoadWithPallets = {
            ...loadWithPallets,
            priority,
        };
        onLoadUpdated(updatedLoadWithPallets);
    };
    
    return (
        <tr
            key={loadWithPallets.id}
            onMouseEnter={() =>
                !disablePriority && setShowEditPriorityIcon(true)
            }
            onMouseLeave={() => {
                !disablePriority && setShowEditPriorityIcon(false);
                setEditPriority(false);
            }}
            >
            <td>{loadWithPallets.bayId}</td>
            <td>
                <PartLoadReference
                    load={loadWithPallets}
                    displayLoadReferenceLink
                    displayAmendmentLink
                    />
            </td>
            <td>
                <IodDetailsLink load={loadWithPallets} />
            </td>
            <EditableDropdownCell
                className={classes.EditPriorityCell}
                dropdownClassName={classNames(classes.EditPriorityDropdown, {
                    [classes.EditPriorityFormGroupDisabled]:
                        !showEditPriorityIcon,
                    })}
                    formGroupClassName={classes.EditPriorityFormGroup}
                    showEditButton={showEditPriorityIcon}
                    edit={editPriority}
                    options={[
                    { label: 'High', value: 'High' },
                    { label: 'Standard', value: 'Standard' },
                ]}
                value={loadWithPallets.priority}
                onEditButtonClick={() => setEditPriority(true)}
                onChange={handlePriorityChange}
                onSubmit={() => setEditPriority(false)}
            />
            <td>
                <div
                    className={classes.LoadingSummaryTableRow}
                    title={loadWithPallets.destination}
                    >
                    {loadWithPallets.destination}
                </div>
            </td>
            <td>{loadWithPallets.trailerType}</td>
            <td>
                {loadWithPallets.loadType}
            </td>
            <td>
                {getSubcontractedString(loadWithPallets)}
            </td>
            <td>
                <LoadTimeRemaining
                    loadByTime={loadWithPallets.loadByTime}
                    completedTime={loadWithPallets.completedAt}
                    useColors
                />
            </td>
            <ProgressSummaryTableMilestoneCell
                pallets={filterPalletsByStatus(
                    loadWithPallets.pallets,
                    'Allocated'
                )}
                milestoneStartedAt={getMilestoneTimeForStatus(
                    loadWithPallets.pallets,
                    'Allocated',
                    'pickedStartTime'
                )}
                milestoneComplete={milestoneIsComplete(
                    loadWithPallets.pallets,
                    'Allocated',
                    'pickedStartTime'
                )}
                thresholdDuration={thresholdDurationForMilestone(
                    milestoneThresholds,
                    'Allocated'
                )}
            />
            <ProgressSummaryTableMilestoneCell
                pallets={filterPalletsByStatus(
                    loadWithPallets.pallets,
                    'Picked'
                )}
                milestoneStartedAt={getMilestoneTimeForStatus(
                    loadWithPallets.pallets,
                    'Picked',
                    'pickedEndTime'
                )}
                thresholdDuration={thresholdDurationForMilestone(
                    milestoneThresholds,
                    'Picked'
                )}
                loaded
            />
            <ProgressSummaryTableMilestoneCell
                pallets={filterPalletsByStatus(
                    loadWithPallets.pallets,
                    'Problem'
                )}
                milestoneStartedAt={getMilestoneTimeForStatus(
                    loadWithPallets.pallets,
                    'Problem',
                    'problemStartTime'
                )}
                thresholdDuration={thresholdDurationForMilestone(
                    milestoneThresholds,
                    'Problem'
                )}
                milestoneComplete={milestoneIsComplete(
                    loadWithPallets.pallets,
                    'Problem',
                    'problemEndTime'
                )}
            />
        </tr>
    );
};

export default ProgressSummaryTableRow;
