import { compareDesc } from 'date-fns';
import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import NoDataFoundMessage from '../../../../components/common/NoDataFoundMessage';
import LoadingProgressDetailTable from '../../../../components/load/loadingProgress/detail/table/view/LoadingProgressDetailTable';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import { useNotificationListener } from '../../../../hooks/useNotificationListener';
import { Load } from '../../../../models/loads/Load';
import { LoadingMilestoneThreshold } from '../../../../models/loads/LoadingMilestoneThreshold';
import { LoadStatus } from '../../../../models/loads/LoadStatus';
import { LoadUpdatedNotification } from '../../../../models/notifications/Load';
import {
    PalletAddedNotification,
    PalletRemovedNotification,
    PalletsAddedNotification,
    PalletsRemovedNotification,
    PalletsUpdatedNotification,
} from '../../../../models/notifications/Pallet';
import { Pallet } from '../../../../models/pallets/Pallet';
import { PalletLoadingProgress } from '../../../../models/pallets/PalletLoadingProgress';
import LoadingProgressPalletReducer from '../../../../reducers/LoadingProgressPalletReducer';
import { useData } from '../../../../services/api';
import LoadingProgressLoadInfoContainer from './LoadingProgressLoadInfoContainer';

interface LoadingProgressDetailContainerProps {
    id: string;
    onBackButtonClick(): void;
}

const getLatestPalletPickedTime = (pallets: Pallet[]): Date | undefined => {
    const latestPalletPickedTime = pallets
        .map((p) => p.loadingProgress)
        .sort((lhs: PalletLoadingProgress, rhs: PalletLoadingProgress) =>
            compareDesc(
                lhs.pickedEndTime ? new Date(lhs.pickedEndTime) : 0,
                rhs.pickedEndTime ? new Date(rhs.pickedEndTime) : 0
            )
        )[0]?.pickedEndTime;
    return latestPalletPickedTime
        ? new Date(latestPalletPickedTime)
        : undefined;
};

const LoadingProgressDetailContainer: React.FC<
    LoadingProgressDetailContainerProps
> = ({ id, onBackButtonClick }) => {
    const [load, loading, fetchLoad] = useData<Load>(`Load/${id}`);
    const [pallets, loadingPallets] = useData<Pallet[]>(
        load ? `Load/${load.id}/Pallets` : null
    );
    const [milestoneThresholds, loadingMilestoneThresholds] = useData<
        LoadingMilestoneThreshold[]
    >(load ? `Store/${load.sourceStore}/LoadingMilestone/Thresholds` : null);

    const [updatingLoad, setUpdatingLoad] = useState(false);

    const [palletsToDisplay, updatePalletsToDisplay] = useReducer(
        LoadingProgressPalletReducer,
        []
    );

    const getUpdatedLoad = () => {
        setUpdatingLoad(true);
        fetchLoad().then(() => setUpdatingLoad(false));
    };

    useEffect(() => {
        if (pallets) {
            updatePalletsToDisplay({
                type: 'REPLACE_PALLETS',
                payload: pallets,
            });
        }
    }, [pallets]);

    useNotificationListener(PalletAddedNotification, (palletAdded) => {
        if (!load) return;

        const isAddNotificationRelevant = load.orderLoadingStatuses.some(
            (ols) => ols.orderId === palletAdded.addedPallet.orderId
        );

        if (isAddNotificationRelevant) {
            updatePalletsToDisplay({
                type: 'ADD_PALLET',
                payload: palletAdded.addedPallet,
            });
        }
    });
    useNotificationListener(PalletsAddedNotification, (palletsAdded) => {
        if (!load) return;

        const isAnyAddNotificationRelevant: boolean =
            load.orderLoadingStatuses.some((ols) =>
                palletsAdded
                    .map((notification) => notification.addedPallet.orderId)
                    .includes(ols.orderId)
            );

        if (isAnyAddNotificationRelevant) {
            updatePalletsToDisplay({
                type: 'ADD_PALLETS',
                payload: palletsAdded.map(
                    (notification) => notification.addedPallet
                ),
            });
        }
    });

    useNotificationListener(PalletRemovedNotification, (palletRemoved) => {
        if (!load) return;

        const isRemovedNotificationRelevant = palletsToDisplay
            ?.map((pallet) => pallet.id)
            .includes(palletRemoved.cancelledPalletId);

        if (isRemovedNotificationRelevant) {
            updatePalletsToDisplay({
                type: 'REMOVE_PALLET',
                payload: palletRemoved.cancelledPalletId,
            });
        }
    });

    useNotificationListener(PalletsRemovedNotification, (palletsRemoved) => {
        if (!load) return;

        const isAnyRemovedNotificationRelevant = palletsToDisplay?.some(
            (pallet) =>
                palletsRemoved
                    .map((notification) => notification.cancelledPalletId)
                    .includes(pallet.id)
        );

        if (isAnyRemovedNotificationRelevant) {
            updatePalletsToDisplay({
                type: 'REMOVE_PALLETS',
                payload: palletsRemoved.map(
                    (notification) => notification.cancelledPalletId
                ),
            });
        }
    });
    useNotificationListener(PalletsUpdatedNotification, (updatedPallets) => {
        if (!load) return;

        const isUpdateNotificationRelevant = updatedPallets.some((p) =>
            load.orderLoadingStatuses.some((ols) => ols.orderId === p.orderId)
        );

        if (isUpdateNotificationRelevant) {
            updatePalletsToDisplay({
                type: 'UPDATE_PALLETS',
                payload: updatedPallets,
            });
        }
    });

    useNotificationListener(
        LoadUpdatedNotification,
        (changesWithAffectedLoad) => {
            if (!load) return;

            const isloadUpdateNotificationRelevant: boolean =
                load.id === changesWithAffectedLoad.affectedLoad.id;
            if (isloadUpdateNotificationRelevant) {
                getUpdatedLoad();
            }
        }
    );

    if (
        !updatingLoad &&
        (loading ||
            loadingPallets ||
            loadingMilestoneThresholds ||
            !load ||
            !pallets ||
            !milestoneThresholds)
    ) {
        return <LoadingIndicator />;
    }

    if (!loading && !load) {
        return (
            <NoDataFoundMessage
                messageText="The load you were looking for could not be found"
                iconType="no-loads"
            />
        );
    }

    const loadCompletedTime: Date | undefined =
        load?.status === LoadStatus.Completed && palletsToDisplay
            ? getLatestPalletPickedTime(palletsToDisplay)
            : undefined;

    const tunnelTransferOrders =
        load?.orderLoadingStatuses
            .filter(
                (ols) =>
                    ols.isToReleaseOrLoad &&
                    ((ols.order.store === 2 && load.sourceStore === 3) ||
                        (ols.order.store === 3 && load.sourceStore === 2))
            )
            .map((o) => o.order) || [];

    const stockTransferOrders =
        load?.orderLoadingStatuses
            .filter(
                (ols) =>
                    ols.isToLoad && !tunnelTransferOrders?.includes(ols.order)
            )
            .map((o) => o.order) || [];

    return load ? (
        <>
            <LoadingProgressLoadInfoContainer
                load={load}
                loadCompletedTime={loadCompletedTime}
                onBackButtonClick={onBackButtonClick}
            />
            <hr />
            <LoadingProgressDetailTable
                orders={load.orderLoadingStatuses
                    .filter((ols) => ols.releaseToChess && !tunnelTransferOrders.includes(ols.order))
                    .map((o) => o.order)}
                tunnelTransferOrders={tunnelTransferOrders}
                stockTransferOrders={stockTransferOrders}
                pallets={palletsToDisplay || []}
                milestoneThresholds={milestoneThresholds || []}
                loadCompletedTime={loadCompletedTime}
                loading={loadingPallets || loadingMilestoneThresholds}
            />
        </>
    ) : (
        <NoDataFoundMessage
            messageText="The load you were looking for could not be found"
            iconType="no-loads"
        />
    );
};

export default LoadingProgressDetailContainer;
