import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    selectionTable: {
        '& thead th': {
            padding: '0.75rem 0.5rem',
        },
        '& td': {
            padding: '0.5rem',
        },
    },
    selectedOrderRow: {
        backgroundColor: theme.colors.primaryLight,
        borderBottomColor: `${theme.colors.primary} !important`, //TODO: Fix table styling
    },
    disabledOrderRow: {
        backgroundColor: theme.colors.lightestGrey,
        color: theme.colors.mediumDarkGrey,
    },
    noDataContainer: {
        minHeight: 250,
    },
}));
