import * as React from 'react';

import classNames from 'classnames';
import { convertThresholdToMilliseconds } from '../../models/loads/LoadingMilestoneThreshold';
import { Pallet } from '../../models/pallets/Pallet';
import { useClasses } from './ProgressSummaryTableMilestoneCell.styles';
import ProgressSummaryTableMilestoneProgress from './ProgressSummaryTableMilestoneProgress';

interface ProgressSummaryTableMilestoneCellProps {
    pallets: Pallet[];
    milestoneStartedAt: string | null;
    milestoneComplete?: boolean;
    thresholdDuration?: string | null;
    loaded?: boolean;
}

const getTimeElapsed = (fromDate: Date, toDate: Date) =>
    toDate.valueOf() - fromDate.valueOf();

const isPalletOverdue = (
    milestoneStart: Date,
    currentTime: Date,
    duration?: string | null
): boolean => {
    if (!duration) {
        return false;
    }
    const timeElapsed = getTimeElapsed(milestoneStart, currentTime);
    const thresholdInMiliseconds = convertThresholdToMilliseconds(duration);
    return timeElapsed > thresholdInMiliseconds;
};

const ProgressSummaryTableMilestoneCell: React.FC<
    ProgressSummaryTableMilestoneCellProps
> = ({
    pallets,
    milestoneStartedAt,
    milestoneComplete,
    thresholdDuration,
    loaded,
}) => {
    const classes = useClasses();

    return pallets.length > 0 && milestoneStartedAt && !loaded ? (
        <td className={classes.progressSummaryMilestoneCell}>
            <ProgressSummaryTableMilestoneProgress
                className={classNames(
                    classes.progressSummaryMilestoneContent,
                    classes.active
                )}
                overdueClassName={classes.overdue}
                milestoneStartedAt={new Date(milestoneStartedAt)}
                palletSpaces={pallets.length}
                useColors
                overdue={(currentTime: Date) =>
                    isPalletOverdue(
                        new Date(milestoneStartedAt),
                        currentTime,
                        thresholdDuration
                    )
                }
            />
        </td>
    ) : (
        <td className={classes.progressSummaryMilestoneCell}>
            <div
                className={classNames(classes.progressSummaryMilestoneContent, {
                    [classes.complete]: milestoneComplete && !loaded,
                    [classes.loaded]: pallets.length > 0 && loaded,
                })}
            >
                {loaded && pallets.length > 0 ? pallets.length : <br />}
            </div>
        </td>
    );
};

export default ProgressSummaryTableMilestoneCell;
