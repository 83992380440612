import * as React from 'react';
import Button from '../../lib/bootstrap-ui/Button';
import Icon, { IconType } from './Icon';

interface IconMenuLinkProps {
    buttonClassName?: string;
    label?: string;
    icon: IconType;
    iconClassName?: string;
    onClick(): void;
}

const IconMenuLink: React.FC<IconMenuLinkProps> = ({
    buttonClassName,
    label,
    icon,
    iconClassName,
    onClick
}) => (
    <Button
        className={buttonClassName}
        styleType="link"
        onClick={onClick}
        style={{ display: 'block' }}
    >
        <Icon className={iconClassName} type={icon} fixedWidth />
        {label}
    </Button>
);

export default IconMenuLink;
