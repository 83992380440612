import * as React from 'react';
import { buildClassesHook } from '../../../../helpers/styles';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import Table from '../../../../lib/bootstrap-ui/Table';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingModification } from '../../../../models/bookings/BookingModification';
import { RecurringBooking } from '../../../../models/bookings/RecurringBooking';
import { useData } from '../../../../services/api';
import HistoryTableRow from '../../../bookings/modals/bookingHistory/HistoryTableRow';

const useClasses = buildClassesHook({
    viewHistoryModal: {
        minWidth: '400px',
        maxWidth: '85%',
    },
    viewHistoryModalBody: {
        overflowY: 'auto',
        maxHeight: '70vh',
        padding: 0,
        margin: '1rem',
    },
});

interface ViewHistoryModalProps {
    booking: Booking | RecurringBooking;
    overrideValues?: BookingModification[];
    type: 'booking' | 'recurringBooking';
    showModal: boolean;
    onClose(): void;
}

const ViewHistoryModal: React.FC<ViewHistoryModalProps> = ({
    booking,
    overrideValues,
    type,
    showModal,
    onClose,
}) => {
    const classes = useClasses();

    const historyTableHeaders = [
        'Action',
        'Description',
        'User Name',
        'Time of Action',
    ];

    const [modifications] = useData<BookingModification[]>(
        !overrideValues && showModal ? `${type}/${booking.id}/Audit` : null
    );

    const bookingHistory = overrideValues ?? modifications;

    return (
        <Modal
            isOpen={showModal && !!bookingHistory}
            modalClassName={classes.viewHistoryModal}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>{`${booking.reference} History`}</h5>
            </ModalHeader>
            <ModalBody className={classes.viewHistoryModalBody}>
                <Table
                    headers={historyTableHeaders}
                    placeholderRows={10}
                    hoverable
                    fixedHeader
                    noRowBorders
                >
                    {bookingHistory &&
                        bookingHistory.map((m, i) => (
                            <HistoryTableRow key={i} modification={m} />
                        ))}
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ViewHistoryModal;
