import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    primaryTemplateRow: {
        backgroundColor: theme.colors.lightestBlue,
    },
    contentCenterColumn: {
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    deleteButton: {
        color: theme.colors.error,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    iconButton: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    hiddenButton: {
        visibility: 'hidden',
    },
}));
