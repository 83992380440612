import * as React from 'react';
import { UserRole } from '../../../helpers/userRole';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { CreateBooking } from '../../../models/bookings/BookingToAdd';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import { useCurrentUser } from '../../../services/authentication';
import { userInRole, userIsAdmin } from '../../../services/userService';
import TimeSlotSelectionContainer from '../TimeSlotSelectionContainer';

const BookingFormTimeSlotContainer: React.FC<FormStepProps<CreateBooking>> = ({
    formObject: booking,
    onChange: onBookingChange,
}) => {
    const user = useCurrentUser();
    const allowOverrideFullTimeSlots =
        userIsAdmin(user) || userInRole(user)(UserRole.ShiftManager);

    const handleTimeSlotSelected = (selectedTimeSlot: TimeSlot) => {
        if (selectedTimeSlot) {
            const editedBooking: CreateBooking = {
                ...booking,
                selectedTimeSlot,
                timeSlotOverrideRequested: !selectedTimeSlot.isAvailable,
            };
            onBookingChange(editedBooking);
        }
    };

    return (
        <TimeSlotSelectionContainer
            allowOverrideFullTimeSlots={allowOverrideFullTimeSlots}
            autoSelectEarliestTimeSlot
            duration={booking.duration}
            resources={booking.resources || []}
            store={booking.store}
            trailerType={booking.trailerType!}
            bookingType={booking.bookingType}
            selectedTimeSlot={booking.selectedTimeSlot}
            onTimeSlotSelected={handleTimeSlotSelected}
        />
    );
};

export default BookingFormTimeSlotContainer;
