import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingProgressDetailContainer from '../../containers/load/loadingDetail/view/LoadingProgressDetailContainer';
import Page from '../common/Page';

const LoadingProgressDetail: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <LoadingProgressDetailContainer
                id={id!}
                onBackButtonClick={() => navigate(-1)}
            />
        </Page>
    );
};

export default LoadingProgressDetail;
