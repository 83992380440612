import classnames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import { HeaderDetails, TextAlign } from './Table';

const useClasses = buildClassesHook({
    alignRight: {
        textAlign: 'right',
    },
    alignCenter: {
        textAlign: 'center',
    },
    alignLeft: {
        textAlign: 'left',
    },
});

interface TableHeadProps {
    styleType?: 'light' | 'dark' | 'none';
    headers?: (string | HeaderDetails)[];
    className?: string;
    [otherProps: string]: any;
}

const TableHead: React.FC<TableHeadProps> = ({
    styleType = 'none',
    headers,
    className = null,
    ...otherProps
}) => {
    const classes = useClasses();

    const newClassName = classnames(className, {
        [`thead-${styleType}`]: styleType !== 'none',
    });

    if (!headers) {
        return null;
    }

    return (
        <thead className={newClassName} {...otherProps}>
            <tr>
                {headers.map((h, i) => {
                    if (typeof h === 'string') {
                        return <th key={i}>{h}</th>;
                    } else {
                        return (
                            <th
                                key={i}
                                className={classnames({
                                    'd-none': h.displayFrom,
                                    [`d-${h.displayFrom}-table-cell`]:
                                        h.displayFrom,
                                    [classes.alignLeft]:
                                        !h.align || h.align === TextAlign.Left,
                                    [classes.alignRight]:
                                        h.align === TextAlign.Right,
                                    [classes.alignCenter]:
                                        h.align === TextAlign.Center,
                                    noPadding: h.noPadding,
                                })}
                                title={h.tooltip}
                                style={{
                                    width: h.width,
                                    top: h.top,
                                    zIndex: h.zIndex,
                                }}
                            >
                                {!h.hide && h.title}
                            </th>
                        );
                    }
                })}
            </tr>
        </thead>
    );
};

export default TableHead;
