import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import { Load } from '../../../models/loads/Load';
import { OrderLoadingStatus } from '../../../models/orders/OrderLoadingStatus';
import { apiPost, useData } from '../../../services/api';
import ErrorModal from '../../common/ErrorModal';
import LoadingIndicator from '../../loading/LoadingIndicator';
import OrderPalletTypeCounts from '../../orders/OrderPalletTypeCounts';
import PiggybackLoadSelectionTable from '../tables/PiggybackLoadSelectionTable';
import { useClasses } from './PiggybackModal.styles';

interface PiggybackTransferOrOrderToLoadModalProps {
    piggybackFullTransfer: boolean;
    load: Load;
    orderLoadingStatus?: OrderLoadingStatus;
    isOpen: boolean;
    onClose(): void;
}

const PiggybackTransferOrOrderToLoadModal: React.FC<
    PiggybackTransferOrOrderToLoadModalProps
> = ({ piggybackFullTransfer, load, orderLoadingStatus, isOpen, onClose }) => {
    const classes = useClasses();

    const [piggybackLoads, isLoading] = useData<Load[]>(
        isOpen
            ? piggybackFullTransfer
                ? `Load/${load.id}/PiggybackLoads`
                : `OrderLoadingStatus/${orderLoadingStatus?.id}/PiggybackLoads`
            : null
    );

    const piggybackOrderLoadingStatus =
        !piggybackFullTransfer && orderLoadingStatus
            ? (orderLoadingStatus as OrderLoadingStatus)
            : null;

    const [selectedLoad, setSelectedLoad] = useState<Load | null>(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);

    const handlePiggybackOrdersOnLoad = async () => {
        try {
            setUpdateInProgress(true);
            if (piggybackFullTransfer) {
                await apiPost(`Load/${load.id}/PiggybackLoad`, {
                    loadToPiggybackId: load.id,
                    targetLoadId: selectedLoad?.id,
                });
            } else {
                await apiPost(`Load/${selectedLoad?.id}/Piggyback`, {
                    loadId: selectedLoad?.id,
                    orderIds: [piggybackOrderLoadingStatus?.orderId],
                });
            }
            setUpdateInProgress(false);
            setSelectedLoad(null);
            onClose();
        } catch (error) {
            setUpdateInProgress(false);
            setShowErrorModal(true);
        }
    };

    const handleLoadSelected = (load: Load) => {
        setSelectedLoad(load);
    };

    const handleCloseModal = () => {
        setSelectedLoad(null);
        onClose();
    };

    const headerText = piggybackFullTransfer
        ? `Piggyback Orders on Load ${load.loadName} onto Load going to ${load.destination}`
        : `Piggyback Order ${piggybackOrderLoadingStatus?.order.customerOrderNumber} onto Load going to ${load.destination}`;

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
                <ModalHeader>
                    <h5>{headerText}</h5>
                </ModalHeader>
                {updateInProgress ? (
                    <LoadingIndicator text="Piggybacking Loads..." />
                ) : (
                    <>
                        <ModalBody>
                            <div
                                className={classNames(classes.tableContainer, {
                                    [classes.loading]: isLoading,
                                })}
                            >
                                <PiggybackLoadSelectionTable
                                    loading={isLoading}
                                    loads={piggybackLoads || []}
                                    selectedLoad={selectedLoad}
                                    onLoadSelected={handleLoadSelected}
                                />
                                <OrderPalletTypeCounts
                                    ordersOnLoad={
                                        piggybackFullTransfer
                                            ? load.orderLoadingStatuses.map(
                                                  (ols) => ols.order
                                              )
                                            : [
                                                  (
                                                      piggybackOrderLoadingStatus as OrderLoadingStatus
                                                  ).order,
                                              ]
                                    }
                                    selectedOrders={
                                        selectedLoad?.orderLoadingStatuses.map(
                                            (ols) => ols.order
                                        ) ?? []
                                    }
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                onClick={handleCloseModal}
                                styleType="secondary"
                            >
                                Close
                            </Button>
                            <Button
                                styleType="primary"
                                disabled={!selectedLoad}
                                onClick={handlePiggybackOrdersOnLoad}
                            >
                                Piggyback
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Modal>
            <ErrorModal
                showModal={isOpen && showErrorModal}
                header={headerText}
                errorText={
                    piggybackFullTransfer
                        ? `There was an error piggybacking the Orders from Load ${load.loadName} onto the selected Load`
                        : `There was an error piggybacking Order ${piggybackOrderLoadingStatus?.order.customerOrderNumber} onto the selected Load`
                }
                onClose={() => setShowErrorModal(false)}
            ></ErrorModal>
        </>
    );
};

export default PiggybackTransferOrOrderToLoadModal;
