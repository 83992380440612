import * as React from 'react';
import { useState } from 'react';
import DeleteSMSTemplateModal from '../../../components/admin/smsTemplates/update/DeleteSMSTemplateModal';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { apiDelete } from '../../../services/api';

interface DeleteSMSTemplateModalContainerProps {
    showModal: boolean;
    smsTemplate: SMSTemplate;
    onClose(): void;
}

const DeleteSMSTemplateModalContainer: React.FC<DeleteSMSTemplateModalContainerProps> = ({
    showModal,
    smsTemplate,
    onClose
}) => {
    const [updatingValues, setUpdatingValues] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleDeleteRequested = async (value: SMSTemplate) => {
        setUpdatingValues(true);
        try {
            await apiDelete(`SMSTemplate/${value.id}`);
            setUpdatingValues(false);
            onClose();
        } catch (e) {
            setUpdatingValues(false);
            setErrorMessage(
                'An error has occurred when trying to delete the SMS Template. If this continues, please contact IT.'
            );
        }
    };

    const showLoadingModal = showModal && updatingValues;
    const showDeleteModal = showModal && !updatingValues && !errorMessage;
    const showErrorModal = showModal && !!errorMessage;

    return (
        <>
            <LoadingModal
                showModal={showLoadingModal}
                loadingText="deleting..."
            />
            <DeleteSMSTemplateModal
                showModal={showDeleteModal}
                smsTemplate={smsTemplate}
                onDeleteRequested={handleDeleteRequested}
                onClose={onClose}
            />
            <ErrorModal
                showModal={showErrorModal}
                header="Error"
                errorText={errorMessage}
                onClose={onClose}
            />
        </>
    );
};

export default DeleteSMSTemplateModalContainer;
