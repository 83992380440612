export enum FileType {
    msg = '',
    eml = '',
    pdf = 'application/pdf',
    doc = 'application/msword',
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    rtf = 'application/rtf',
    xls = 'application/vnd.ms-excel',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    unknown = 'unknown',
}
