import * as React from 'react';
import classnames from 'classnames';

interface CardProps extends React.HTMLAttributes<HTMLElement> {
    header?: React.ReactNode;
    headerLevel?: 1 | 2 | 3 | 4 | 5 | 6;
    headerClassName?: string;
    footer?: React.ReactNode;
    footerClassName?: string;
}

const renderHeader = (header, headerLevel, headerClassName) => {
    switch (headerLevel) {
        case 1:
            return (
                <h1 className={classnames('card-header', headerClassName)}>
                    {header}
                </h1>
            );
        case 2:
            return (
                <h2 className={classnames('card-header', headerClassName)}>
                    {header}
                </h2>
            );
        case 3:
            return (
                <h3 className={classnames('card-header', headerClassName)}>
                    {header}
                </h3>
            );
        case 4:
            return (
                <h4 className={classnames('card-header', headerClassName)}>
                    {header}
                </h4>
            );
        case 5:
            return (
                <h5 className={classnames('card-header', headerClassName)}>
                    {header}
                </h5>
            );
        case 6:
            return (
                <h6 className={classnames('card-header', headerClassName)}>
                    {header}
                </h6>
            );
        default:
            return (
                <div className={classnames('card-header', headerClassName)}>
                    {header}
                </div>
            );
    }
};

const Card: React.FC<CardProps> = ({
    header,
    footer,
    headerLevel,
    headerClassName,
    footerClassName,
    children,
    className,
    ...otherProps
}) => (
    <div className={classnames('card', className)} {...otherProps}>
        {header && renderHeader(header, headerLevel, headerClassName)}
        {children}
        {footer && (
            <div className={classnames('card-footer', footerClassName)}>
                {footer}
            </div>
        )}
    </div>
);

export default Card;
