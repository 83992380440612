import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    contentContainer: {
        padding: '1rem 1rem 0 1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iodPlanGridArrow: {
        fontSize: '2rem',
    },
    removedMessage: {
        width: 220,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    iodPlanContainer: {
        margin: '3rem',
    },
    replanContainer: {
        backgroundColor: theme.colors.lightGrey,
        padding: '1.5rem',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
        gridGap: '10px',
        borderRadius: '0.5rem',
        maxWidth: '90%',
        width: '100%',
        margin: 'auto',
    },
    saveButtonContainer: {
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: '1px solid rgb(233, 235, 237)',
        backgroundColor: theme.colors.white,
        width: '100%',
        padding: '1rem',
        '& button': {
            margin: 'auto 0.75rem',
        },
    },
}));
