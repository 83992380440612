import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    workQueueTable: {
        '& thead th': {
            top: '4.5rem',
        },
        '& a': {
            textDecoration: 'underline',
        },
    },
    noDataContainer: {
        height: '80vh',
    },
});
