import * as React from 'react';
import classnames from 'classnames';

const CardText: React.FC<React.HTMLAttributes<HTMLElement>> = ({
    children,
    className,
    ...otherProps
}) => (
    <p className={classnames('card-text', className)} {...otherProps}>
        {children}
    </p>
);

export default CardText;
