import * as React from 'react';
import BookingDetails from '../../../models/bookings/BookingDetails';
import { CreateBooking } from '../../../models/bookings/BookingToAdd';
import { BookingType } from '../../../models/bookings/BookingType';
import LoadTypeSelectionContainer from '../LoadTypeSelectionContainer';

interface BookingFormLoadTypeContainerProps {
    booking: CreateBooking;
    onBookingChange(booking: CreateBooking): void;
}

const BookingFormLoadTypeContainer: React.FC<
    BookingFormLoadTypeContainerProps
> = ({ booking, onBookingChange }) => {
    const bookingDetails: BookingDetails = {
        bookingType: booking.bookingType,
        duration: booking.duration,
        overrideStoreResourceValues: booking.overrideStoreResource,
        resources: booking.resources,
        selectedTimeSlot: booking.selectedTimeSlot,
        store: booking.store,
        trailerType: booking.trailerType,
        secondaryTrailerType: booking.secondaryTrailerType,
    };

    const handleBookingDetailsUpdate = (details: BookingDetails) => {
        const editedBooking: CreateBooking = {
            ...booking,
            duration: details.duration,
            resources: details.resources,
            selectedTimeSlot: details.selectedTimeSlot,
            overrideStoreResource: details.overrideStoreResourceValues,
            timeSlotOverrideRequested: !details.selectedTimeSlot?.isAvailable,
            store: details.store,
            trailerType: details.trailerType,
            secondaryTrailerType: details.secondaryTrailerType,
        };

        onBookingChange(editedBooking);
    };

    return (
        <LoadTypeSelectionContainer
            disabled={
                booking.bookingType === BookingType.Internal &&
                booking.orders.length === 0
            }
            options={{
                details: bookingDetails,
                type: 'booking',
            }}
            onBookingDetailsUpdate={handleBookingDetailsUpdate}
        />
    );
};

export default BookingFormLoadTypeContainer;
