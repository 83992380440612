import * as React from 'react';
import { useMemo } from 'react';
import OverrideTimeSlotModal from '../../../components/bookings/modals/OverrideTimeSlotModal';
import LoadingModal from '../../../components/common/LoadingModal';
import { Booking } from '../../../models/bookings/Booking';
import { BookingType } from '../../../models/bookings/BookingType';
import {
    SiteResource,
    TrailerTypeResource
} from '../../../models/bookings/StoreBookingResource';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import { useData } from '../../../services/api';
import { formatToUtc } from '../../../services/timeStringService';

interface OverrideTimeSlotModalContainerProps {
    showModal: boolean;
    unavailableTimeSlot: TimeSlot;
    store: StoreNumber;
    excludedBookingIds?: string[];
    onCancel(): void;
    onConfirmSelect(): void;
}

const OverrideTimeSlotModalContainer: React.FC<OverrideTimeSlotModalContainerProps> = ({
    showModal,
    unavailableTimeSlot,
    store,
    excludedBookingIds,
    onCancel,
    onConfirmSelect
}) => {
    const [bookingsInTimeSlot, loadingBookingsInTimeSlot] = useData<Booking[]>(
        `Bookings/Active/From/${formatToUtc(
            unavailableTimeSlot.start
        )}/To/${formatToUtc(unavailableTimeSlot.end)}`,
        { excludedBookingIds }
    );

    const [
        storeTrailerTypeResources,
        loadingStoreTrailerTypeResources
    ] = useData<TrailerTypeResource[]>(`Store/${store}/TrailerTypeResources`);

    const [siteResources, loadingSiteResources] = useData<SiteResource[]>(
        `SiteResource`
    );

    const bookingsToShow = useMemo(() => {
        if (bookingsInTimeSlot && siteResources) {
            let bookings: Booking[] = [];
            const siteLoadingResources = siteResources?.map(sr => sr.resource);
            const unavailableSiteLoadingResources = unavailableTimeSlot.unavailableResources?.filter(
                r => siteLoadingResources?.includes(r)
            );
            if (
                unavailableTimeSlot.unavailableReasons.includes(
                    'Too Many Bookings'
                )
            ) {
                bookings = bookingsInTimeSlot.filter(b => b.store === store);
            } else if (
                unavailableTimeSlot.unavailableReasons.includes(
                    'Required Resource Unavailable'
                )
            ) {
                bookings = bookingsInTimeSlot.filter(
                    b =>
                        b.store === store &&
                        b.resources.some(r =>
                            unavailableTimeSlot.unavailableResources?.includes(
                                r
                            )
                        )
                );
            } else if (
                unavailableTimeSlot.unavailableReasons.includes(
                    'No Booking Period'
                )
            ) {
                bookings = bookingsInTimeSlot.filter(
                    b =>
                        b.store === store &&
                        b.bookingType === BookingType.NoBookings
                );
            }
            if (unavailableSiteLoadingResources) {
                const bookingsWithUnavailableSiteResource = bookingsInTimeSlot?.filter(
                    b =>
                        b.resources.some(r =>
                            unavailableSiteLoadingResources.includes(r)
                        ) && !bookings.includes(b)
                );
                bookings = [
                    ...bookings,
                    ...bookingsWithUnavailableSiteResource
                ].sort((a: Booking, b: Booking) => a.store - b.store);
            }
            return bookings;
        }
        return null;
    }, [
        bookingsInTimeSlot,
        siteResources,
        store,
        unavailableTimeSlot.unavailableResources,
        unavailableTimeSlot.unavailableReasons
    ]);

    const showLoadingModal =
        showModal &&
        (loadingBookingsInTimeSlot ||
            loadingStoreTrailerTypeResources ||
            loadingSiteResources);

    return (
        <>
            <LoadingModal showModal={showLoadingModal} />
            {bookingsToShow && storeTrailerTypeResources && (
                <OverrideTimeSlotModal
                    showModal={showModal && !showLoadingModal}
                    bookingsInTimeSlot={bookingsToShow}
                    storeTrailerTypeResources={storeTrailerTypeResources}
                    unavailableReasons={unavailableTimeSlot.unavailableReasons}
                    unavailableResources={
                        unavailableTimeSlot.unavailableResources
                    }
                    onCancelSelect={onCancel}
                    onConfirmSelect={onConfirmSelect}
                />
            )}
        </>
    );
};

export default OverrideTimeSlotModalContainer;
