import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    header: {
        paddingBottom: '0.5rem',
    },
    container: {
        padding: '0.8rem 2rem',
        width: 200,
        borderLeft: '1px solid darkgray',
        marginLeft: '2rem',
    },
});
