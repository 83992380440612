import classnames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import useInterval from '../../hooks/useInterval';
import { formatToFriendlyTime } from '../../services/timeStringService';
import {
    oneSecond,
    tenSeconds,
    oneMinute,
    twoMinutes,
    oneHour,
    oneDay,
} from '../../models/common/TimeAsMiliseconds';
import { useClasses } from './TimeComponent.styles';

interface LoadTimeRemainingProps {
    className?: string;
    loadByTime: string;
    completedTime: string | null;
    minRefreshInterval?: number;
    useColors?: boolean;
}

const getTimeRemaining = (date: Date, untilTime: Date) =>
    date.valueOf() - untilTime.valueOf();

const calculateTimeRemaining = (date: Date, untilTime: Date) =>
    formatToFriendlyTime(getTimeRemaining(date, untilTime));

const hasTimeRemaining = (compareDate: Date, untilTime: Date) =>
    untilTime <= compareDate;

const getIntervalDuration = (
    compareDate: Date,
    untilTime: Date,
    minRefreshInterval: number
) => {
    const timeRemaining = Math.abs(getTimeRemaining(compareDate, untilTime));
    if (timeRemaining < twoMinutes) {
        return minRefreshInterval || oneSecond;
    }
    if (timeRemaining < oneHour) {
        return Math.max(minRefreshInterval, tenSeconds);
    }
    if (timeRemaining < oneDay) {
        return Math.max(minRefreshInterval, oneMinute);
    }
    return Math.max(minRefreshInterval, oneHour);
};

const LoadTimeRemaining: React.FC<LoadTimeRemainingProps> = ({
    className,
    loadByTime,
    completedTime,
    minRefreshInterval = 0,
    useColors,
}) => {
    const classes = useClasses();

    const loadTime = new Date(loadByTime);
    const until = completedTime ? new Date(completedTime) : null;

    const [untilTime, setUntilTime] = useState(until ?? new Date());
    const [interval, setInterval] = useState(
        getIntervalDuration(loadTime, untilTime, minRefreshInterval)
    );

    useInterval(() => {
        const now = until ?? new Date();
        setUntilTime(now);
        setInterval(getIntervalDuration(loadTime, now, minRefreshInterval));
    }, interval);

    return (
        <span
            className={classnames(className, {
                [classes.overdue]:
                    !hasTimeRemaining(loadTime, untilTime) && useColors,
            })}
        >
            {calculateTimeRemaining(loadTime, untilTime)}
        </span>
    );
};

export default LoadTimeRemaining;
