import classnames from 'classnames';
import * as React from 'react';

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
    align?: string;
    justify?: string;
    children: React.ReactNode;
    className?: string;
    noGutters?: boolean;
}

const Row: React.FC<RowProps> = ({
    align,
    justify,
    className,
    children,
    noGutters = false,
    ...otherProps
}) => {
    const classes = classnames('row', className, {
        'no-gutters': noGutters,
        [`align-items-${align}`]: align,
        [`justify-content-${justify}`]: justify
    });
    return (
        <div className={classes} {...otherProps}>
            {children}
        </div>
    );
};

export default Row;
