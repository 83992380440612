import * as React from 'react';
import BookingsHistoryContainer from '../../containers/bookingsHistory/BookingsHistoryContainer';
import Page from '../common/Page';

const BookingsHistory: React.FC = () => (
    <Page>
        <BookingsHistoryContainer />
    </Page>
);

export default BookingsHistory;
