import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
//import Prompt from '../../lib/bootstrap-ui/Prompt/Prompt';
import UnsavedChangesModal from './UnsavedChangesModal';

interface BlockUnsavedChangesProps {
    blockNavigation: boolean;
    onClose?(): void;
}

const BlockUnsavedChangesNavigation: React.FC<BlockUnsavedChangesProps> = ({
    // blockNavigation,
    onClose,
}) => {
    const navigate = useNavigate();
    const [redirectLocation, setRedirectLocation] = useState('');
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
        useState(false);

    const handleOnContinue = async () => {
        setShowUnsavedChangesModal(false);
        navigate(redirectLocation);
    };

    const handleOnClose = () => {
        setShowUnsavedChangesModal(false);
        setRedirectLocation('');
        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            {/* <Prompt
                when={blockNavigation}
                message="Unsaved changes detected. Are you sure?"
            /> */}
            <UnsavedChangesModal
                isOpen={showUnsavedChangesModal}
                onRequestDiscardChanges={handleOnContinue}
                onRequestClose={handleOnClose}
                onRequestGoBack={handleOnClose}
            />
        </>
    );
};

export default BlockUnsavedChangesNavigation;
