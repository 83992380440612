import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey, routeFor } from '../../Routes/routes';
import WorkQueueContainer from '../../containers/workQueue/WorkQueueContainer';
import { StoreNumber } from '../../models/stores/StoreNumber';
import Page from '../common/Page';

const WorkQueue: React.FC = () => {
    const { store } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <WorkQueueContainer
                selectedStore={
                    (parseInt(store ?? '') as StoreNumber) ?? undefined
                }
                onStoreChange={(store: StoreNumber) =>
                    navigate(routeFor(RouteKey.StoreWorkQueue)({ store }))
                }
                onRedirectToRelease={(id: string) =>
                    navigate(routeFor(RouteKey.Release)({ id }))
                }
            />
        </Page>
    );
};

export default WorkQueue;
