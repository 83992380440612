import * as React from 'react';
import { useState } from 'react';
import Grid, { Row, Column } from '../../../lib/bootstrap-ui/Grid';
import { useData, apiPut } from '../../../services/api';
import { SiteResource } from '../../../models/bookings/StoreBookingResource';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import UnsavedChangesModal from '../../../components/common/UnsavedChangesModal';
import RequireSaveContainer from '../RequireSaveContainer';

const isSiteResourceValid = (siteResource: SiteResource) =>
    siteResource.amount !== null && siteResource.amount >= 0;

const areSiteResourcesEqual = (
    lhs: SiteResource,
    rhs: SiteResource
): boolean => {
    return (
        lhs.id === rhs.id &&
        lhs.resource === rhs.resource &&
        lhs.amount === rhs.amount
    );
};

const SiteResourceConfigContainer: React.FC<React.PropsWithChildren<
    unknown
>> = () => {
    const [existingSiteResources, , refreshSiteResources] = useData<
        SiteResource[]
    >('SiteResource');
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(
        false
    );

    const handleSiteResourceChange = (
        value: string | null,
        id: number,
        handleUpdate: (updatedSiteResource: SiteResource) => void
    ) => {
        if (existingSiteResources) {
            const resourceAmount = !!value ? parseInt(value, 10) : null;
            const matchingResource = existingSiteResources.find(
                resource => resource.id === id
            );

            if (matchingResource) {
                const updatedResource = {
                    ...matchingResource,
                    amount: resourceAmount
                };
                handleUpdate(updatedResource);
            }
        }
    };

    const hasSiteResourceChanged = (siteResource: SiteResource) => {
        const matchingSiteResource = existingSiteResources?.find(
            sr => sr.id === siteResource.id
        );
        return (
            !!matchingSiteResource &&
            !areSiteResourcesEqual(matchingSiteResource, siteResource)
        );
    };

    const handleSaveClicked = async (updatedSiteResources: SiteResource[]) => {
        await apiPut('SiteResource', updatedSiteResources);
        await refreshSiteResources();
    };

    return (
        <RequireSaveContainer<SiteResource>
            existingValues={existingSiteResources || []}
            comparator={(lhs: SiteResource, rhs: SiteResource) =>
                lhs.id === rhs.id
            }
            hasValueUpdated={hasSiteResourceChanged}
            valueValidator={isSiteResourceValid}
            Save={handleSaveClicked}
        >
            {({ updatedValues, onChange }) => (
                <>
                    <Grid fluid>
                        <Row>
                            {updatedValues &&
                                updatedValues.map((resource: SiteResource) => (
                                    <Column key={resource.resource} size={3}>
                                        <TextInput
                                            label={`${resource.resource}s: `}
                                            size="small"
                                            type="number"
                                            minValue={0}
                                            error={
                                                resource.amount === null ||
                                                resource.amount < 0
                                                    ? 'Must be greater or equal to 0'
                                                    : null
                                            }
                                            value={
                                                resource.amount !== null
                                                    ? resource.amount
                                                    : ''
                                            }
                                            onChange={value =>
                                                handleSiteResourceChange(
                                                    value,
                                                    resource.id,
                                                    onChange
                                                )
                                            }
                                            highlightOnFocus
                                        />
                                    </Column>
                                ))}
                        </Row>
                    </Grid>
                    <UnsavedChangesModal
                        isOpen={showUnsavedChangesModal}
                        onRequestDiscardChanges={() =>
                            setShowUnsavedChangesModal(false)
                        }
                        onRequestClose={() => setShowUnsavedChangesModal(false)}
                        onRequestGoBack={() =>
                            setShowUnsavedChangesModal(false)
                        }
                    />
                </>
            )}
        </RequireSaveContainer>
    );
};

export default SiteResourceConfigContainer;
