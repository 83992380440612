import * as React from 'react';
import { useState } from 'react';

import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { HistoricalLoadFilter } from '../../../models/loads/HistoricalLoadFilter';
import Center from '../../common/Center';
import DatePickerInput from '../../common/DatePicker/DatePickerInput';
import Icon from '../../common/Icon';
import SingleColumnRow from '../../common/SingleColumnRow';
import { useClasses } from './WorkHistoryFilterModalBody.styles';

interface WorkHistoryFilterModalBodyProps {
    filter: HistoricalLoadFilter;
    onFilterChange(newFilter: HistoricalLoadFilter): void;
}

const WorkHistoryFilterModalBody: React.FC<WorkHistoryFilterModalBodyProps> = ({
    filter,
    onFilterChange,
}) => {
    const classes = useClasses();

    const [displayStartDatePicker, setDisplayStartDatePicker] = useState(false);
    const [displayEndDatePicker, setDisplayEndDatePicker] = useState(false);

    const handleFilterPropertyChange =
        <T,>(name: keyof HistoricalLoadFilter) =>
        (value: T) => {
            onFilterChange({ ...filter, [name]: value });
        };

    const handleStartDateSelected = (selectedDate: Date | null) => {
        handleFilterPropertyChange('startDate')(selectedDate);
        setDisplayStartDatePicker(false);
    };

    const handleEndDateSelected = (selectedDate: Date | null) => {
        handleFilterPropertyChange('endDate')(selectedDate);
        setDisplayEndDatePicker(false);
    };

    return (
        <Grid>
            <SingleColumnRow>
                <TextInput
                    label="PL Reference:"
                    placeholder="PL Reference..."
                    size="small"
                    value={filter.loadReference}
                    onChange={handleFilterPropertyChange<string>(
                        'loadReference'
                    )}
                    autoConvertWhitespace
                    autoTrim
                    hideBottomPadding
                />
            </SingleColumnRow>
            <SingleColumnRow>
                <TextInput
                    label="IOD Number:"
                    placeholder="IOD Number..."
                    size="small"
                    value={filter.iodNumber}
                    onChange={handleFilterPropertyChange<string>('iodNumber')}
                    autoConvertWhitespace
                    autoTrim
                    hideBottomPadding
                />
            </SingleColumnRow>
            <SingleColumnRow>
                <TextInput
                    label="Job Number:"
                    placeholder="Job Number..."
                    size="small"
                    value={filter.orderNumber}
                    onChange={handleFilterPropertyChange<string>('orderNumber')}
                    autoConvertWhitespace
                    autoTrim
                    hideBottomPadding
                />
            </SingleColumnRow>
            <hr />
            <Row>
                <Column>
                    <DatePickerInput
                        inputLabel="From:"
                        inputPlaceholder="dd/mm/yyyy"
                        popupPosition="bottom left"
                        error={
                            filter.startDate &&
                            filter.endDate &&
                            filter.startDate.valueOf() >
                                filter.endDate.valueOf()
                                ? "Date must be before 'To' date"
                                : undefined
                        }
                        isOpen={displayStartDatePicker}
                        value={filter.startDate}
                        onDateSelected={handleStartDateSelected}
                        onOpen={() => {
                            displayEndDatePicker &&
                                setDisplayEndDatePicker(false);
                            setDisplayStartDatePicker(true);
                        }}
                        onClose={() => setDisplayStartDatePicker(false)}
                    />
                </Column>
                <Column className={classes.arrowIcon}>
                    <Center>
                        <Icon type="arrow-right" />
                    </Center>
                </Column>
                <Column>
                    <DatePickerInput
                        inputLabel="To:"
                        inputPlaceholder="dd/mm/yyyy"
                        popupPosition="bottom right"
                        hideBottomMargin
                        isOpen={displayEndDatePicker}
                        value={filter.endDate}
                        onDateSelected={handleEndDateSelected}
                        onOpen={() => {
                            displayStartDatePicker &&
                                setDisplayStartDatePicker(false);
                            setDisplayEndDatePicker(true);
                        }}
                        onClose={() => setDisplayEndDatePicker(false)}
                    />
                </Column>
            </Row>
        </Grid>
    );
};

export default WorkHistoryFilterModalBody;
