import * as React from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../../lib/bootstrap-ui/Modal';

import DropdownOption from '../../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import ThirdPartyValue from '../../../../models/thirdPartyValues/ThirdPartyValue';
import { useClasses } from './ModalStyle';

interface DeleteModalProps {
    showModal: boolean;
    thirdPartyValue: ThirdPartyValue;
    selectedOption: DropdownOption;
    onDeleteRequested(thirdPartyValue: ThirdPartyValue): void;
    onClose(): void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    showModal,
    thirdPartyValue,
    selectedOption,
    onDeleteRequested,
    onClose
}) => {
    const classes = useClasses();

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Delete {selectedOption.label}</h5>
            </ModalHeader>
            <ModalBody>
                Are you sure you want to delete {thirdPartyValue.value}?
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Go Back
                </Button>
                <Button
                    styleType="danger"
                    onClick={() => onDeleteRequested(thirdPartyValue)}
                >
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
