import * as React from 'react';

import CSSGrid from '../../../components/common/CSSGrid';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import { LoadingMilestoneThreshold } from '../../../models/loads/LoadingMilestoneThreshold';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { validThresholdDurationRegExp } from '../../../services/loadingProgressService';
import { minuteSecondRegExp } from '../../../services/timeStringService';
import { useClasses } from './StoreLoadingThresholdConfigContainer.styles';

interface StoreLoadingThresholdConfigContainerProps {
    store: StoreNumber;
    loadingMilestoneThresholds: LoadingMilestoneThreshold[];
    onChange(updatedThreshold: LoadingMilestoneThreshold): void;
}

const StoreLoadingThresholdConfigContainer: React.FC<
    StoreLoadingThresholdConfigContainerProps
> = ({ store, loadingMilestoneThresholds, onChange }) => {
    const classes = useClasses();

    const handleDurationChange = (
        updatedDuration: string | null,
        updatedThresholdId: number
    ) => {
        const matchingThreshold = loadingMilestoneThresholds?.find(
            (lmt) => lmt.id === updatedThresholdId
        );

        if (matchingThreshold) {
            const updatedThreshold: LoadingMilestoneThreshold = {
                ...matchingThreshold,
                thresholdDuration: updatedDuration,
            };

            onChange(updatedThreshold);
        }
    };

    const getInvalidMessageIfRequired = (
        durationString: string
    ): string | undefined => {
        if (!minuteSecondRegExp.test(durationString)) {
            return 'Value not in correct format (mm:ss)';
        }

        if (!validThresholdDurationRegExp.test(durationString)) {
            return 'Value exceeds maximum of 9999:59';
        }
    };

    return (
        <div className={classes.storeThresholdContainer}>
            <h4>{`Store ${store}`}</h4>
            <CSSGrid
                className={classes.thresholdDurationGrid}
                columns="repeat(auto-fill, minmax(auto, 300px))"
                rows="repeat(1, 1fr)"
                gap={20}
            >
                {loadingMilestoneThresholds
                    .filter((v) => v.store === store)
                    .map((threshold, index) => (
                        <TextInput
                            key={index}
                            label={`${threshold.milestone} (mm:ss):`}
                            size="small"
                            type="text"
                            error={getInvalidMessageIfRequired(
                                threshold.thresholdDuration || ''
                            )}
                            value={threshold.thresholdDuration}
                            onChange={(value) =>
                                handleDurationChange(value, threshold.id)
                            }
                        />
                    ))}
            </CSSGrid>
        </div>
    );
};

export default StoreLoadingThresholdConfigContainer;
