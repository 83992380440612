import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import { theme } from '../../../theme';
import DropdownOption from '../Dropdown/DropdownOption';
import Button from './Button';
import ButtonGroup from './ButtonGroup';

const useClasses = buildClassesHook({
    mainButton: {
        padding: 0,
        border: 0,
        backgroundColor: 'inherit',
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
    dropdownButton: {
        border: 0,
        padding: 0,
        backgroundColor: 'inherit',
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
        },
    },
    dropdown: {
        zIndex: 2,
        borderRadius: '0.25rem',
        backgroundColor: theme.colors.white,
        position: 'absolute',
        border: '1px solid rgba(0,0,0,.15)',
        maxHeight: '225px',
        overflowY: 'auto',
        right: 0,
    },
    selectedDropdownButton: {
        '&:focus': {
            backgroundColor: theme.colors.primaryDark,
        },
    },
    notSelectedDropdownButton: {
        '&:focus': {
            backgroundColor: theme.colors.lightGrey,
        },
    },
    buttonGroup: {
        border: 'none',
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        borderRadius: '0.25rem',
    },
    dropdownItem: {
        cursor: 'pointer',
    },
});

interface SplitButtonProps {
    className?: string;
    dropdownOptions: DropdownOption[];
    children: React.ReactNode;
    height?: number | string;
    width?: number | string;
    buttonProportion?: number;
    selectedValue: any;
    active?: boolean;

    onDropdownOptionChange(option: DropdownOption | null): void;
    onMainButtonClick(): void;
    onDropdownButtonClick?(): void;
}

const SplitButton: React.FC<SplitButtonProps> = ({
    dropdownOptions,
    children,
    className,
    height = 40,
    width = 100,
    buttonProportion = 0.7,
    selectedValue,
    active,
    onDropdownOptionChange,
    onMainButtonClick,
    onDropdownButtonClick,
}) => {
    const classes = useClasses();

    const [expanded, setExpanded] = useState(false);

    const dropdownOptionsDiv = useRef<HTMLDivElement>(null);

    const mainButtonWidth = `calc(${
        typeof width === 'number' ? `${width}px` : width
    } * ${buttonProportion})`;

    useEffect(() => {
        const handleClickOutsideDropdown = (event: MouseEvent) => {
            if (
                expanded &&
                dropdownOptionsDiv.current &&
                !dropdownOptionsDiv.current.contains(event.target as Node)
            ) {
                setExpanded(false);
            }
        };

        const clickHandlerTimeout = setTimeout(() => {
            document.addEventListener('click', handleClickOutsideDropdown);
        }, 0);

        return () => {
            clearTimeout(clickHandlerTimeout);
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, [expanded]);

    const handleDropdownButtonClicked = () => {
        setExpanded((prev) => !prev);
        onDropdownButtonClick && onDropdownButtonClick();
    };

    return (
        <ButtonGroup
            className={classNames(classes.buttonGroup, className)}
            style={{ height, width }}
        >
            <Button
                style={{ width: mainButtonWidth }}
                styleType="link"
                className={classNames(classes.mainButton, 'btn-link')}
                onClick={onMainButtonClick}
            >
                {children}
            </Button>
            <Button
                styleType="link"
                className={classNames(
                    'dropdown-toggle',
                    'dropdown-toggle-split',
                    classes.dropdownButton,
                    !active && active !== undefined
                        ? classes.notSelectedDropdownButton
                        : classes.selectedDropdownButton
                )}
                id="dropdown-button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={expanded}
                onClick={handleDropdownButtonClicked}
            >
                <span className="sr-only">Toggle Dropdown</span>
            </Button>
            <div
                ref={dropdownOptionsDiv}
                aria-labelledby="dropdown-button"
                style={{
                    top: height,
                }}
                className={classNames(classes.dropdown, {
                    'dropdown-menu': !expanded,
                    'dropdown-menu-show': expanded,
                })}
            >
                {dropdownOptions.map((option, i) => (
                    <div
                        key={i}
                        className={classNames(
                            'dropdown-item',
                            classes.dropdownItem,
                            {
                                active: selectedValue === option.value,
                            }
                        )}
                        onClick={() => {
                            onDropdownOptionChange(option);
                            setExpanded(false);
                        }}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </ButtonGroup>
    );
};

export default SplitButton;
