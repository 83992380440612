import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    store: {
        border: `solid ${theme.colors.lightGrey} 2px`,
        borderRadius: '5px',
        fontWeight: 500,
        height: 25,
        width: 25,
    },
    transfer: {
        borderColor: 'darkorange',
    },
    finishingTrailer: {
        borderColor: theme.colors.mediumDarkGrey,
    },
    userFinishingTrailer: {
        border: 'none',
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
    },
    userTransfer: {
        border: 'none',
        backgroundColor: 'darkorange',
        color: theme.colors.white,
    },
    card: {
        backgroundColor: theme.colors.white,
        border: `solid ${theme.colors.mediumDarkGrey} 2px`,
        borderRadius: '5px',
        display: 'flex',
        padding: '0.5rem',
        cursor: 'pointer',
    },
    replanStatsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        margin: '0 1rem',
        width: 'fit-content',
    },
    statsHeader: {
        color: theme.colors.mediumDarkGrey,
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        fontWeight: 500,
    },
    statsValue: {
        color: theme.colors.darkGrey,
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: '2rem',
    },
    planGrid: {
        margin: 'auto',
    },
    cardSelected: {
        backgroundColor: theme.colors.primaryLight,
        borderColor: theme.colors.primary,
    },
    cardDisabled: {
        backgroundColor: theme.colors.lightGrey,
        color: theme.colors.lightBlack,
        cursor: 'auto',
    },
}));
