import classnames from 'classnames';
import * as React from 'react';
import { Load } from '../../../models/loads/Load';
import { LoadsWithTransfers } from '../../../models/loads/LoadsWithTransfers';
import Center from '../../common/Center';
import CSSGrid from '../../common/CSSGrid';
import { useClasses } from './TransferDetails.styles';
import TransferDetailsGridItems from './TransferDetailsGridItems';

interface TransferDetailsProps {
    loadsWithTransfers: LoadsWithTransfers;
    selectedLoad: Load;
}

const TransferDetails: React.FC<TransferDetailsProps> = ({
    loadsWithTransfers,
    selectedLoad
}) => {
    const classes = useClasses();

    return (
        <Center>
            <CSSGrid
                gap={5}
                className={classnames(classes.autoGrid, 'px-3', 'px-sm-0')}
            >
                <TransferDetailsGridItems
                    loadsWithTransfers={loadsWithTransfers}
                    selectedLoad={selectedLoad}
                />
            </CSSGrid>
        </Center>
    );
};

export default TransferDetails;
