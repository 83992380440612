import * as React from 'react';
import classNames from 'classnames';

import { pluralise } from '../../../../helpers/stringFunctions';
import Card, {
    CardBody,
    CardText,
    CardTitle,
} from '../../../../lib/bootstrap-ui/Card';
import Grid, { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingType } from '../../../../models/bookings/BookingType';
import { TrailerTypeResource } from '../../../../models/bookings/StoreBookingResource';
import { getBookingTypeColour } from '../../../../services/bookingService';
import { getTrailerTypeLabelForBooking } from '../../../../services/trailerService';
import SingleColumnRow from '../../../common/SingleColumnRow';
import { useClasses } from './BookingCard.styles';

interface BookingCardProps {
    className?: string;
    booking: Booking;
    trailerTypeResource?: TrailerTypeResource;
}
const BookingCard: React.FC<BookingCardProps> = ({
    className,
    booking,
    trailerTypeResource,
}) => {
    const classes = useClasses();

    const palletsIn = trailerTypeResource?.estimatedPalletCount;
    const palletsOut = booking.requiresOrders
        ? booking.load?.totalPalletSpaces ?? 0
        : null;
    const showPalletsIn = !!palletsIn || palletsIn === 0;
    const showPalletsOut = !!palletsOut || palletsOut === 0;

    const isNoBookingsBooking: boolean =
        booking.bookingType === BookingType.NoBookings;

    return (
        <Card
            className={classNames(classes.bookingCard, className, {
                [classes.deliveryAndCollectionCard]:
                    booking.bookingType === BookingType.DeliveryAndCollection,
            })}
            style={{
                borderColor: getBookingTypeColour(booking.bookingType),
                margin: 0,
            }}
        >
            <CardBody className={classes.bookingCardBody}>
                <Grid>
                    <SingleColumnRow style={{ textAlign: 'center' }}>
                        <CardText
                            className={classNames(
                                classes.bookingCardText,
                                classes.store
                            )}
                        >
                            {booking.store}
                        </CardText>
                        <CardTitle
                            className={classNames(
                                classes.bookingCardText,
                                classes.bookingCardTitle
                            )}
                        >
                            {booking.reference}
                        </CardTitle>
                        <CardText
                            className={classNames(
                                classes.bookingCardText,
                                classes.loadType
                            )}
                        >
                            {getTrailerTypeLabelForBooking(booking)}
                        </CardText>
                    </SingleColumnRow>
                    {isNoBookingsBooking ? (
                        <SingleColumnRow>
                            <CardText
                                className={classNames(
                                    classes.bookingCardText,
                                    classes.customerReference
                                )}
                                title="No bookings"
                            >
                                No Bookings
                            </CardText>
                        </SingleColumnRow>
                    ) : (
                        <SingleColumnRow>
                            <CardText
                                className={classNames(
                                    classes.bookingCardText,
                                    classes.customerReference
                                )}
                                title={booking.customerReference || ''}
                            >
                                {booking.customerReference || <br />}
                            </CardText>
                        </SingleColumnRow>
                    )}

                    {booking.customerName && (
                        <SingleColumnRow>
                            <CardText
                                className={classNames(
                                    classes.bookingCardText,
                                    classes.customerName
                                )}
                                title={booking.customerName}
                            >
                                {booking.customerName}
                            </CardText>
                        </SingleColumnRow>
                    )}
                    <Row>
                        {showPalletsIn && (
                            <Column>
                                <CardText className={classes.bookingCardText}>
                                    {`${pluralise('Pallet', palletsIn!)} In`}
                                </CardText>
                            </Column>
                        )}
                        {showPalletsOut && (
                            <Column>
                                <CardText
                                    className={classNames(
                                        classes.bookingCardText,
                                        { [classes.palletsOut]: showPalletsIn }
                                    )}
                                >
                                    {`${pluralise('Pallet', palletsOut!)} Out`}
                                </CardText>
                            </Column>
                        )}
                    </Row>
                    {isNoBookingsBooking ? (
                        <SingleColumnRow>
                            <CardText className={classes.resourcesText}>
                                {booking.bookingInstructions}
                            </CardText>
                        </SingleColumnRow>
                    ) : (
                        <SingleColumnRow>
                            <CardText className={classes.resourcesText}>
                                {booking.resources.length > 0
                                    ? booking.resources.join(', ')
                                    : 'No Site/Store Resources'}
                            </CardText>
                        </SingleColumnRow>
                    )}
                </Grid>
            </CardBody>
        </Card>
    );
};

export default BookingCard;
