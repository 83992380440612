import * as React from 'react';
import { useState } from 'react';
import Modal, {
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../../lib/bootstrap-ui/Modal';
import Button from '../../../lib/bootstrap-ui/Button';
import PhoneNumberInput from '../../common/PhoneNumberInput';
import { Load } from '../../../models/loads/Load';

interface EditContactNumberModalProps {
    load: Load;
    showModal: boolean;
    onSave(contactNumber: string | null): void;
    onClose(): void;
}

const EditContactNumberModal: React.FC<EditContactNumberModalProps> = ({
    load,
    showModal,
    onSave,
    onClose
}) => {
    const [loadContactNumber, setLoadContactNumber] = useState(
        load.contactNumber
    );
    const [contactNumberIsValid, setContactNumberIsValid] = useState(false);

    const handleSave = () => {
        onSave(loadContactNumber);
        setLoadContactNumber(null);
    };

    const disableSave =
        !contactNumberIsValid || load.contactNumber === loadContactNumber;

    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Edit Contact Number: {load.loadName}</h5>
            </ModalHeader>
            <ModalBody>
                <PhoneNumberInput
                    label={'Contact Number:'}
                    placeholder={'Contact Number...'}
                    onPhoneNumberChanged={setLoadContactNumber}
                    phoneNumber={loadContactNumber}
                    required
                    onPhoneNumberValidation={setContactNumberIsValid}
                />
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={disableSave}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditContactNumberModal;
