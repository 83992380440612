import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteKey, routeFor } from '../../Routes/routes';
import ReleaseLoadContainer from '../../containers/releasing/ReleaseLoadContainer';
import { useCurrentUser } from '../../services/authentication';
import { userIsAdmin } from '../../services/userService';
import Page from '../common/Page';

const Release: React.FC = () => {
    const user = useCurrentUser();

    const returnToSameStore = userIsAdmin(user);

    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <ReleaseLoadContainer
                loadId={id!}
                goBackHandler={() => navigate(-1)}
                onReleaseComplete={(storeId: number) =>
                    navigate(
                        returnToSameStore
                            ? routeFor(RouteKey.StoreWorkQueue)({
                                  store: storeId,
                              })
                            : routeFor(RouteKey.WorkQueue)()
                    )
                }
            />
        </Page>
    );
};

export default Release;
