const pluralise = (word: string, amount: number, pluraliseString = 's') =>
    `${amount} ${word}${amount !== 1 ? pluraliseString : ''}`;

const filterValues = (values: string[]) => (
    searchValue: string,
    filteredItemAmount?: number
) =>
    values
        .filter(
            value =>
                searchValue.length > 1 &&
                value
                    .toLowerCase()
                    .trim()
                    .includes(searchValue.toLowerCase().trim())
        )
        .slice(0, filteredItemAmount);

const getDayString = (day: number) =>
    [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ][day];

const whitespaceToNull = (value: string | null): string | null =>
    !value?.trim() ? null : value;

const safeTrim = (value: string | null): string => value?.trim() ?? '';

export { pluralise, filterValues, getDayString, whitespaceToNull, safeTrim };
