import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';

const useClasses = buildClassesHook({
    modalFooter: {
        padding: '1rem 1rem 0 1rem',
    },
});

const ModalFooter: React.FC<
    React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ className, children, ...rest }) => {
    const classes = useClasses();

    return (
        <div
            className={classNames(
                'modal-footer',
                classes.modalFooter,
                className
            )}
            {...rest}
        >
            {children}
        </div>
    );
};

export default ModalFooter;
