import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    noDataFoundContainer: {
        height: '80vh',
    },
    noDataFoundMessage: {
        margin: '1.5rem 0 1rem 0',
        fontSize: '1.2rem',
        fontWeight: 100,
        color: theme.colors.darkGrey,
    },
    noDataFoundIcon: {
        color: '#bdd7f3',
        '--fa-primary-color': '#3f98f8',
        '--fa-primary-opacity': 1,
        '--fa-secondary-color': '#e9ecef',
        '--fa-secondary-opacity': 1,
        height: '10rem',
        width: '10rem',
    },
}));
