import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { RouteKey, routeFor } from '../../Routes/routes';
import BookingSummaryContainer from '../../containers/bookings/view/summary/BookingSummaryContainer';
import Page from '../common/Page';

const RecurringBookingSummary: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <Page>
            <BookingSummaryContainer
                id={id!}
                type="recurring"
                onContinue={() =>
                    navigate(routeFor(RouteKey.RecurringBookings)())
                }
            />
        </Page>
    );
};

export default RecurringBookingSummary;
