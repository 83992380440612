import Color from 'color';

const baseColors = {
    white: Color('hsl(0, 0%, 100%, 1)'),
    error: Color('hsl(354, 70%, 54%, 1)'),
    info: Color('hsl(188, 78%, 41%, 1)'),
    grey: Color('hsl(210, 10%, 80%, 1)'),
    purple: Color('hsl(291, 64%, 42%, 1)'),
    primary: Color('hsl(211, 100%, 50%, 1)'),
    green: Color('hsl(134, 61%, 41%, 1)'),
    warning: Color('hsl(45, 100%, 51%, 1)'),
    black: Color('hsl(0, 0%, 0%, 1)'),
    pink: Color('hsla(359, 46%, 51%, 0.2)'),
    orange: Color('hsla(36, 100%, 50%, 1)'),
    beige: Color('hsla(36, 36%, 70%, 1)'),
    blue: Color('hsl(210, 80%, 61%, 1)'),
};

const colors = {
    white: baseColors.white.hsl().string(),
    error: baseColors.error.hsl().string(),
    errorDark: baseColors.error.darken(0.15).hsl().string(),
    errorLight: baseColors.error.lighten(0.65).hsl().string(),
    info: baseColors.info.hsl().string(),
    nearlyWhite: baseColors.grey.lighten(0.225).hsl().string(),
    lightestGrey: baseColors.grey.lighten(0.2).hsl().string(),
    lightGrey: baseColors.grey.lighten(0.15).hsl().string(),
    mediumGrey: baseColors.grey.lighten(0.05).hsl().string(),
    grey: baseColors.grey.hsl().string(),
    mediumDarkGrey: baseColors.grey.darken(0.2).hsl().string(),
    darkGrey: baseColors.grey.darken(0.5).hsl().string(),
    darkerGrey: baseColors.grey.darken(0.6).hsl().string(),
    lightBlack: baseColors.grey.darken(0.7).hsl().string(),
    primary: baseColors.primary.hsl().string(),
    primaryContrast: baseColors.orange.hsl().string(),
    success: baseColors.green.hsl().string(),
    successDark: baseColors.green.darken(0.15).hsl().string(),
    successLight: baseColors.green.lighten(0.95).hsl().string(),
    translucentWhite: baseColors.white.opaquer(-0.15).hsl().string(),
    warning: baseColors.warning.hsl().string(),
    warningDark: baseColors.warning.darken(0.15).hsl().string(),
    black: baseColors.black.hsl().string(),
    primaryDark: baseColors.primary.darken(0.15).hsl().string(),
    primaryLight: baseColors.primary.lighten(0.85).hsl().string(),
    primaryContrastLight: baseColors.orange.lighten(0.85).hsl().string(),
    purple: baseColors.purple.hsl().string(),
    palePurple: baseColors.purple.lighten(0.75).string(),
    green: baseColors.green.hsl().string(),
    pink: baseColors.pink.hsl().string(),
    palePink: baseColors.pink.lighten(0.5).string(),
    deliveryBooking: baseColors.purple.hsl().string(),
    collectionBooking: baseColors.warning.hsl().string(),
    internalBooking: baseColors.primary.darken(0.15).hsl().string(),
    resourceBooking: baseColors.green.darken(0.15).hsl().string(),
    deliveryAndCollectionBooking: `linear-gradient(to bottom, ${baseColors.purple
        .hsl()
        .string()} 50%, ${baseColors.warning.hsl().string()} 50%)`,
    noBookings: baseColors.error.darken(0.15).hsl().string(),
    translucentBeige: baseColors.beige.opaquer(-0.65).hsl().string(),
    lightestBlue: baseColors.blue.lighten(0.6).hsl().string(),
};

export interface Theme {
    colors: Record<keyof typeof colors, string>;
}

export const theme: Theme = { colors };
