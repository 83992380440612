import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    saveButton: {
        margin: '15px',
    },
    storeSelector: {
        margin: 0,
        zIndex: 2,
        position: 'sticky',
        top: '4.5rem',
        backgroundColor: theme.colors.white,
    },
    storeSelectorDropdown: {
        maxWidth: '190px',
    },
    storeResourceEditSection: {
        margin: '20px 0',
    },
    footerRow: {
        backgroundColor: theme.colors.white,
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${theme.colors.lightGrey}`,
    },
}));
