import * as React from 'react';
import classnames from 'classnames';

interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
    type?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark';
    onDismiss?(): void;
}

const Alert: React.FC<AlertProps> = ({
    type = 'primary',
    onDismiss = null,
    className,
    children,
    ...otherProps
}) => (
    <div
        className={classnames('alert', `alert-${type}`, className, {
            'alert-dismissible': onDismiss !== null
        })}
        role="alert"
        {...otherProps}
    >
        {children}
        {onDismiss !== null && (
            <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={e => {
                    e.preventDefault();
                    onDismiss();
                }}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        )}
    </div>
);

export default Alert;
