import * as React from 'react';
import { useState } from 'react';
import CheckInModal from '../../components/bookings/modals/CheckInModal';
import ErrorModal from '../../components/common/ErrorModal';
import LoadingModal from '../../components/common/LoadingModal';
import { Booking } from '../../models/bookings/Booking';
import { apiPost } from '../../services/api';
import { getErrorMessage } from '../../helpers/errors';

interface CheckInModalContainerProps {
    allowCheckIn: boolean;
    booking: Booking;
    showModal: boolean;
    onCheckIn?(): void;
    onClose(): void;
}

const CheckInModalContainer: React.FC<CheckInModalContainerProps> = ({
    allowCheckIn,
    booking,
    showModal,
    onCheckIn,
    onClose,
}) => {
    const [checkingInBooking, setCheckingInBooking] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleCheckInConfirmedClicked = async (
        haulierName: string,
        registrationPlate: string,
        contactNumber: string | null
    ) => {
        setCheckingInBooking(true);
        try {
            const result: string[] | null = await apiPost(
                `Booking/${booking.id}/CheckIn`,
                {
                    haulierName,
                    registrationPlate,
                    contactNumber,
                }
            );

            if (result?.length) {
                setCheckingInBooking(false);
                setErrorMessage(
                    Array.isArray(result)
                        ? `An error has occurred when Checking In the Booking: ${result[0]}`
                        : 'An error has occurred when Checking In the Booking'
                );
            } else {
                if (onCheckIn) {
                    onCheckIn();
                }
            }
        } catch (e) {
            console.warn(e);
            setCheckingInBooking(false);
            setErrorMessage(
                `An error has occurred when Checking In the Booking: ${getErrorMessage(
                    e
                )}`
            );
        }
    };

    const handleCheckInModalClose = () => {
        if (checkingInBooking) {
            return;
        }
        onClose();
    };

    const modalHeader = `Check In Booking ${booking.reference}`;
    const showLoadingModal = checkingInBooking && allowCheckIn && !errorMessage;
    const showCheckInModal =
        !checkingInBooking && allowCheckIn && !errorMessage;
    const showBookingUpdatedError = !allowCheckIn && !checkingInBooking;

    return (
        <>
            <LoadingModal
                showModal={showModal && showLoadingModal}
                header={modalHeader}
                loadingText="checking in"
            />
            <CheckInModal
                booking={booking}
                showModal={showModal && showCheckInModal}
                onCheckIn={handleCheckInConfirmedClicked}
                onClose={handleCheckInModalClose}
            />
            <ErrorModal
                showModal={showModal && showBookingUpdatedError}
                header={modalHeader}
                errorText="Since you opened this pop-up the booking has been updated. Please close this pop-up and ensure the updated booking is correct before checking in."
                onClose={handleCheckInModalClose}
            />
            {errorMessage && (
                <ErrorModal
                    showModal={showModal && !!errorMessage}
                    header={modalHeader}
                    errorText={errorMessage}
                    onClose={handleCheckInModalClose}
                />
            )}
        </>
    );
};

export default CheckInModalContainer;
