import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../../helpers/styles';
import Button from '../../../../lib/bootstrap-ui/Button';
import { Booking } from '../../../../models/bookings/Booking';
import { theme } from '../../../../theme';
import Icon from '../../../common/Icon';
import { ActionType } from './BookingTableRowAction';

type ActionButtonType = 'Complete' | 'Check Out' | 'Check In';

const useClasses = buildClassesHook({
    checkButton: {
        padding: 0,
        verticalAlign: 'baseline',
        cursor: 'pointer',
    },
    completeResource: {
        color: theme.colors.green,
        '&:hover': {
            color: theme.colors.successDark,
        },
    },
    completeResourceIcon: {
        margin: '0.1rem 0.5rem',
    },
    checkOut: {
        color: theme.colors.success,
        '&:hover': {
            color: theme.colors.successDark,
        },
    },
    checkInAndOutIcon: {
        margin: '0.1rem 0.5rem',
    },
    checkIn: {
        color: theme.colors.primary,
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
});

interface BookingTableRowActionButtonProps {
    buttonTextClassName?: string;
    buttonType: ActionButtonType;
    booking: Booking;
    userCannotPerformAction?: boolean;
    onActionInitiated(type: ActionType, id: string): void;
}

const getButtonIcon = (buttonType: ActionButtonType) => {
    switch (buttonType) {
        case 'Complete':
            return 'tick';
        case 'Check In':
            return 'truck-arrive';
    }
    return 'truck-leave';
};

const BookingTableRowActionButton: React.FC<
    BookingTableRowActionButtonProps
> = ({
    buttonTextClassName,
    buttonType,
    booking,
    userCannotPerformAction = false,
    onActionInitiated,
}) => {
    const classes = useClasses();
    const buttonIcon = getButtonIcon(buttonType);

    return (
        <div
            title={
                userCannotPerformAction
                    ? 'You do not have permission to perform this action'
                    : undefined
            }
        >
            <Button
                className={classNames(classes.checkButton, {
                    [classes.completeResource]: buttonType === 'Complete',
                    [classes.checkOut]: buttonType === 'Check Out',
                    [classes.checkIn]: buttonType === 'Check In',
                })}
                styleType="link"
                disabled={userCannotPerformAction}
                onClick={() =>
                    onActionInitiated(
                        buttonType === 'Check In' ? buttonType : 'Complete',
                        booking.id
                    )
                }
            >
                <span style={{ display: 'inline' }}>
                    <Icon
                        className={
                            buttonType === 'Complete'
                                ? classes.completeResourceIcon
                                : classes.checkInAndOutIcon
                        }
                        type={buttonIcon}
                        title={buttonType}
                    />
                    <span className={buttonTextClassName}>{buttonType}</span>
                </span>
            </Button>
        </div>
    );
};

export default BookingTableRowActionButton;
