import classNames from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import { theme } from '../../../theme';

const useClasses = buildClassesHook({
    updated: {
        animation: '$updatedFade 2s',
    },
    '@keyframes updatedFade': {
        '0%': {
            backgroundColor: 'auto',
            color: 'auto',
        },
        '50%': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
        },
        '100%': {
            backgroundColor: 'auto',
            color: 'auto',
        },
    },
});

interface HighlightableTableCellProps {
    className?: string;
    highlight: boolean;
    highlightedClassName?: string;
    colspan?: number;
    highlightUpdates?: boolean;
    title?: string;
}

const HighlightableTableCell: React.FC<
    React.PropsWithChildren<HighlightableTableCellProps>
> = ({
    className,
    highlight,
    colspan,
    highlightedClassName,
    highlightUpdates = false,
    title,
    children,
}) => {
    const classes = useClasses();

    return (
        <td
            className={classNames(className, {
                [highlightedClassName ?? classes.updated]:
                    highlightUpdates && highlight,
            })}
            colSpan={colspan}
            title={title}
        >
            {children}
        </td>
    );
};

export default HighlightableTableCell;
