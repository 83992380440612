import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import Button from '../../lib/bootstrap-ui/Button';
import InputBase from '../../lib/bootstrap-ui/Forms/Base/InputBase';
import Icon from './Icon';
import { useClasses } from './PhoneNumberInput.styles';

interface PhoneNumberInputProps {
    label?: string;
    phoneNumber: string | null;
    required?: boolean;
    placeholder?: string;
    onPhoneNumberChanged(phoneNumber: string | null): void;
    onPhoneNumberSubmit?(): void;
    onPhoneNumberEnterPressed?(): void;
    onPhoneNumberValidation?(isValid: boolean): void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    label,
    phoneNumber,
    required,
    placeholder,
    onPhoneNumberChanged,
    onPhoneNumberSubmit,
    onPhoneNumberEnterPressed,
    onPhoneNumberValidation,
}) => {
    const classes = useClasses();

    const [phoneNumberValue, setPhoneNumberValue] = useState(phoneNumber);
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
    const [phoneNumberValidationError, setPhoneNumberValidationError] =
        useState<string | undefined>();
    const [focussed, setFocussed] = useState(false);

    const dialCodeRef = React.useRef('');

    const validatePhoneNumber = (inputNumber, country, countries): boolean => {
        if (country) {
            if (required && !inputNumber) {
                setPhoneNumberIsValid(false);
                setPhoneNumberValidationError('Please provide a phone number');
                return false;
            }

            if (!countries.some((c) => c.iso2 === country.iso2)) {
                setPhoneNumberIsValid(false);
                setPhoneNumberValidationError(
                    'The country selected is not valid'
                );
                return false;
            }

            if (
                !inputNumber.startsWith(country.dialCode) &&
                !country.dialCode.startsWith(inputNumber)
            ) {
                setPhoneNumberIsValid(false);
                setPhoneNumberValidationError(
                    'Please include a dial code as part of the phone number'
                );
                return false;
            }

            const strippedContactNumber =
                phoneNumberValue?.replace(/\s/g, '') || '';
            if (strippedContactNumber.length > 17) {
                setPhoneNumberIsValid(false);
                setPhoneNumberValidationError('Phone number is too long');
                return false;
            }

            if (
                strippedContactNumber.length < 11 &&
                strippedContactNumber.length > 0 &&
                strippedContactNumber !== country.dialCode
            ) {
                setPhoneNumberIsValid(false);
                setPhoneNumberValidationError('Phone number is too short');
                return false;
            }
        } else {
            setPhoneNumberIsValid(false);
            setPhoneNumberValidationError('Please select a country');
            return false;
        }

        setPhoneNumberIsValid(true);
        setPhoneNumberValidationError(undefined);
        return true;
    };

    useEffect(() => {
        onPhoneNumberValidation && onPhoneNumberValidation(phoneNumberIsValid);
        // eslint-disable-next-line
    }, [phoneNumberIsValid]);

    const handlePhoneNumberChange = (value: string, data) => {
        const validNumber = `+${value}`;

        setPhoneNumberValue(validNumber);
        if (value === data.dialCode) {
            onPhoneNumberChanged(null);
        } else {
            onPhoneNumberChanged(validNumber);
        }
    };

    const handleClear = () => {
        setPhoneNumberValue(dialCodeRef.current);
        onPhoneNumberChanged(null);
    };

    const handlePhoneNumberKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter') {
            onPhoneNumberEnterPressed && onPhoneNumberEnterPressed();
        }
    };

    return (
        <InputBase label={label} error={phoneNumberValidationError}>
            {({ onBlur: baseOnBlur }) => (
                <div
                    className={classNames(classes.inputWrapperContainer, {
                        [classes.focussed]: focussed,
                    })}
                    onFocus={() => setFocussed(true)}
                    onBlur={() => setFocussed(false)}
                >
                    <PhoneInput
                        buttonClass={classes.phoneNumberDropdownButton}
                        dropdownClass={classes.phoneNumberDropdown}
                        inputClass={classes.phoneNumberInput}
                        searchClass={classes.phoneNumberInputSearchField}
                        country={'gb'}
                        regions={'europe'}
                        value={phoneNumberValue}
                        enableSearch
                        disableDropdown={false}
                        placeholder={placeholder}
                        enableLongNumbers={false}
                        onKeyDown={handlePhoneNumberKeyDown}
                        onChange={handlePhoneNumberChange}
                        onBlur={() => {
                            baseOnBlur();
                            if (onPhoneNumberSubmit) {
                                onPhoneNumberSubmit();
                            }
                        }}
                        onClick={(event) => event.stopPropagation()}
                        autoFormat={false}
                        countryCodeEditable={false}
                        isValid={(inputNumber, country, countries) => {
                            dialCodeRef.current = (country as any).dialCode;
                            return validatePhoneNumber(
                                inputNumber,
                                country,
                                countries
                            );
                        }}
                    />
                    {!!phoneNumberValue && (
                        <Button
                            className={classes.clearButton}
                            styleType="link"
                            onClick={(event) => {
                                event?.stopPropagation();
                                handleClear();
                            }}
                        >
                            <Icon type="cross" />
                        </Button>
                    )}
                </div>
            )}
        </InputBase>
    );
};

export default PhoneNumberInput;
