import {
    endOfMonth,
    isSameDay,
    isSameMonth,
    parseISO,
    setHours,
    setMinutes,
    startOfDay,
    startOfMonth,
    subMinutes,
} from 'date-fns';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import InformationMessage from '../../components/common/InformationMessage';
import DateTimeSlotPicker from '../../components/common/TimeSlotPicker/DateTimeSlotPicker';
import { BookingType } from '../../models/bookings/BookingType';
import { LoadingResource } from '../../models/bookings/LoadingResource';
import { StoreNumber } from '../../models/stores/StoreNumber';
import TimeSlot from '../../models/timeSlot/TimeSlot';
import { TrailerType } from '../../models/trailers/TrailerType';
import { apiGet } from '../../services/api';
import {
    canOverrideTimeSlot,
    getTimeSlotsOverPeriodUrl,
} from '../../services/timeSlotService';
import { formatToUtc } from '../../services/timeStringService';
import OverrideTimeSlotModalContainer from './update/OverrideTimeSlotModalContainer';

const getDefaultTimeSlots = (): TimeSlot[] =>
    Array(24)
        .fill(null)
        .map((_: null, k: number) => ({
            start: setMinutes(setHours(new Date(), k), 0),
            end: setMinutes(setHours(new Date(), k + 1), 0),
            isAvailable: false,
            unavailableReasons: ['No Reason'],
            unavailableResources: [],
        }));

interface TimeSlotSelectionContainerProps {
    allowOverrideFullTimeSlots?: boolean;
    disableResourceAvailabilityCheck?: boolean;
    disableWeekends?: boolean;
    disabled?: boolean;
    excludedBookingIds?: string[];
    excludedDates?: Date[];
    duration: number;
    resources: LoadingResource[];
    store: StoreNumber;
    trailerType: TrailerType;
    bookingType: BookingType;
    selectedTimeSlot: TimeSlot | null;
    autoSelectEarliestTimeSlot?: boolean;

    onTimeSlotSelected(selectedTimeSlot: TimeSlot | null): void;
}

const TimeSlotSelectionContainer: React.FC<TimeSlotSelectionContainerProps> = ({
    allowOverrideFullTimeSlots = false,
    autoSelectEarliestTimeSlot = false,
    disableResourceAvailabilityCheck = false,
    disableWeekends = false,
    disabled = false,
    duration,
    excludedBookingIds = [],
    excludedDates = [],
    resources,
    store,
    trailerType,
    bookingType,
    selectedTimeSlot,
    onTimeSlotSelected,
}) => {
    const [selectedMonth, setSelectedMonth] = useState(
        startOfMonth(
            selectedTimeSlot ? new Date(selectedTimeSlot.start) : new Date()
        )
    );
    const [selectedDate, setSelectedDate] = useState(
        selectedTimeSlot
            ? startOfDay(new Date(selectedTimeSlot.start))
            : new Date()
    );

    const [unavailableTimeSlot, setUnavailableTimeSlot] =
        useState<TimeSlot | null>(null);

    const [showOverrideTimeSlotModal, setShowOverrideTimeSlotModal] =
        useState(false);

    const [fetchedTimeSlots, setFetchedTimeSlots] = useState({
        months: [] as { month: string; lastFetched: Date }[],
        timeSlots: [] as TimeSlot[],
    });
    const [loadingTimeSlots, setLoadingTimeSlots] = useState(true);

    const monthStart = formatToUtc(startOfMonth(selectedMonth));
    const monthEnd = formatToUtc(endOfMonth(selectedMonth));

    const timeSlotUrl = getTimeSlotsOverPeriodUrl({
        bookingType: bookingType,
        checkResourceAvailability: !disableResourceAvailabilityCheck,
        duration,
        excludedBookingIds,
        utcStart: monthStart,
        utcEnd: monthEnd,
        resources,
        store,
        trailerType,
    });

    const isSelectedDateLoaded = fetchedTimeSlots.months.some((m) =>
        isSameMonth(parseISO(m.month), selectedDate)
    );

    useEffect(() => {
        const now = new Date();
        const cacheDurationMinutes = 15;
        if (
            !fetchedTimeSlots.months.some(
                (m) =>
                    m.month === monthStart &&
                    m.lastFetched > subMinutes(now, cacheDurationMinutes)
            )
        ) {
            const fetchTimeSlots = async () => {
                if (!isSelectedDateLoaded) {
                    setLoadingTimeSlots(true);
                }
                const newTimeSlots = await apiGet(timeSlotUrl);
                setFetchedTimeSlots((prev) => ({
                    ...prev,
                    timeSlots: [
                        ...prev.timeSlots.filter(
                            (t) =>
                                !isSameMonth(
                                    new Date(t.start),
                                    parseISO(monthStart)
                                )
                        ),
                        ...newTimeSlots,
                    ],
                    months: [
                        ...prev.months,
                        { month: monthStart, lastFetched: now },
                    ],
                }));
                setLoadingTimeSlots(false);
            };
            fetchTimeSlots();
        }
    }, [
        selectedMonth,
        monthStart,
        timeSlotUrl,
        fetchedTimeSlots.months,
        isSelectedDateLoaded,
    ]);

    const timeSlotsForSelectedDate = useMemo(
        () =>
            fetchedTimeSlots.timeSlots.filter((t) =>
                isSameDay(new Date(t.start), selectedDate)
            ),
        [selectedDate, fetchedTimeSlots.timeSlots]
    );

    const getFirstValidTimeSlot = (allTimeslots: TimeSlot[]) =>
        allTimeslots.find((ts) => ts.isAvailable) || null;
    const getFirstValidTimeSlotForSelectedDay = () =>
        getFirstValidTimeSlot(timeSlotsForSelectedDate);

    const handleDateSelectedChange = (newDate: Date) => {
        if (autoSelectEarliestTimeSlot) {
            const timeSlotsForNewDay = fetchedTimeSlots.timeSlots.filter((t) =>
                isSameDay(new Date(t.start), newDate)
            );
            const earliestAvailableTimeSlotForNewDay =
                getFirstValidTimeSlot(timeSlotsForNewDay);
            onTimeSlotSelected(earliestAvailableTimeSlotForNewDay);
        }

        setSelectedDate(newDate);
    };

    const handleTimeSlotSelected = (selectedTimeSlot: TimeSlot) => {
        if (selectedTimeSlot.isAvailable) {
            onTimeSlotSelected(selectedTimeSlot);
        } else if (canOverrideTimeSlot(selectedTimeSlot)) {
            setUnavailableTimeSlot(selectedTimeSlot);
            setShowOverrideTimeSlotModal(true);
        }
    };

    const handleConfirmFullTimeSlotSelected = () => {
        setShowOverrideTimeSlotModal(false);
        onTimeSlotSelected(unavailableTimeSlot);
    };

    useEffect(() => {
        if (
            autoSelectEarliestTimeSlot &&
            timeSlotsForSelectedDate.length &&
            !selectedTimeSlot
        ) {
            onTimeSlotSelected(getFirstValidTimeSlotForSelectedDay());
        }
    });

    const earliestAvailableTimeSlot = useMemo(
        getFirstValidTimeSlotForSelectedDay,
        [selectedDate, timeSlotsForSelectedDate]
    );

    return (
        <>
            <DateTimeSlotPicker
                allowOverrideFullTimeSlots={allowOverrideFullTimeSlots}
                disableWeekends={disableWeekends}
                disabled={disabled}
                excludedDates={excludedDates}
                loading={loadingTimeSlots}
                highlightedTimeSlots={
                    autoSelectEarliestTimeSlot && earliestAvailableTimeSlot
                        ? [earliestAvailableTimeSlot]
                        : []
                }
                selectedDate={selectedDate}
                selectedTimeSlot={selectedTimeSlot}
                timeSlots={
                    timeSlotsForSelectedDate.length
                        ? timeSlotsForSelectedDate
                        : getDefaultTimeSlots()
                }
                onDateSelected={handleDateSelectedChange}
                onMonthChange={setSelectedMonth}
                onTimeSlotSelected={handleTimeSlotSelected}
            />
            {selectedTimeSlot && !selectedTimeSlot.isAvailable && (
                <InformationMessage messageType="warning">
                    The selected time slot is currently unavailable.
                </InformationMessage>
            )}
            {unavailableTimeSlot && (
                <OverrideTimeSlotModalContainer
                    showModal={showOverrideTimeSlotModal}
                    unavailableTimeSlot={unavailableTimeSlot}
                    store={store}
                    excludedBookingIds={excludedBookingIds}
                    onCancel={() => setShowOverrideTimeSlotModal(false)}
                    onConfirmSelect={handleConfirmFullTimeSlotSelected}
                />
            )}
        </>
    );
};

export default TimeSlotSelectionContainer;
