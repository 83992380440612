import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { formatDate } from '../../../helpers/dates';
import { Load } from '../../../models/loads/Load';
import {
    getLoadTrailerTypeName,
    getPiggybackedPalletCountForLoad,
    getSubcontractedString,
    getTotalDrops,
    getTotalPalletSpaces,
} from '../../../services/loadService';
import Icon from '../../common/Icon';
import IodDetailsLink from '../../helpers/IodDetailsLink';
import LoadOrdersTable from '../../load/loadOrders/table/view/LoadOrdersTable';
import PartLoadReference from '../../load/common/view/PartLoadReference';
import { useClasses } from './WorkHistoryTableRow.styles';

interface WorkHistoryTableProps {
    load: Load;
}

const WorkHistoryTableRow: React.FC<WorkHistoryTableProps> = ({ load }) => {
    const classes = useClasses();

    const [showOrders, setShowOrders] = useState(false);

    const piggybackedPallets: number = getPiggybackedPalletCountForLoad(load);

    return (
        <>
            <tr key={load.id} onClick={() => setShowOrders((prev) => !prev)}>
                <td>
                    <Icon
                        type="chevron-down"
                        className={classNames(classes.expandIcon, {
                            [classes.expandIconExpand]: !showOrders,
                            [classes.expandIconCollapse]: showOrders,
                        })}
                    />
                </td>
                <td>
                    <PartLoadReference 
                        load={load} 
                        displayLoadReferenceLink={!load.isCancelledAndReleasedTunnelTransfer} />
                </td>
                <td>{getSubcontractedString(load)}</td>
                <td>
                    <IodDetailsLink load={load} />
                </td>
                <td>{getLoadTrailerTypeName(load)}</td>
                <td>{load.trailerId}</td>
                <td>{load.loadType}</td>
                <td>
                    {load.completedAt 
                        ? formatDate(load.completedAt, 'HH:mm') 
                        : load.isCancelled 
                            ? (load.isCancelledAndReleasedTunnelTransfer && load.releasedAt) 
                                ? formatDate(load.releasedAt, 'HH:mm') 
                                : 'Cancelled'
                            : 'N/A'
                    }
                </td>
                <td>
                    <div
                        className={classes.DestinationColumn}
                        title={load.destination}
                    >
                        {load.destination}
                    </div>
                </td>
                <td>
                    {load?.predecessor
                        ? getPiggybackedPalletCountForLoad(load.predecessor)
                        : 0}
                </td>
                <td>
                    {`${load.totalPalletSpaces} ${
                        piggybackedPallets !== 0
                            ? `(+${piggybackedPallets})`
                            : ''
                    }`}
                </td>
                <td>{getTotalPalletSpaces(load)}</td>
                <td>{getTotalDrops(load)}</td>
                <td>{load.bayId}</td>
            </tr>
            <tr style={{ border: 'none' }}>
                <td
                    colSpan={14}
                    className={classNames(classes.orderTableContent, {
                        [classes.orderTableContentExpanded]: showOrders,
                    })}
                >
                    <div
                        className={classNames(classes.orderRow, {
                            [classes.orderRowCollapsed]: !showOrders,
                            [classes.orderRowExpanded]: showOrders,
                        })}
                    >
                        <LoadOrdersTable load={load} />
                    </div>
                </td>
            </tr>
        </>
    );
};

export default WorkHistoryTableRow;
