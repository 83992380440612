import * as React from 'react';
import { TextArea, TextInput } from '../../../../lib/bootstrap-ui/Forms';
import { Column, Row } from '../../../../lib/bootstrap-ui/Grid';
import { Booking } from '../../../../models/bookings/Booking';
import { CreateBooking } from '../../../../models/bookings/BookingToAdd';
import { BookingType } from '../../../../models/bookings/BookingType';
import NameLookup from '../../../common/NameLookup';

export type Property = keyof CreateBooking;

const isDeliveryAndCollectionBooking = (
    booking: Booking | CreateBooking
): boolean => booking.bookingType === BookingType.DeliveryAndCollection;
const isDeliveryBooking = (booking: Booking | CreateBooking): boolean =>
    booking.bookingType === BookingType.Delivery;
const isCollectionBooking = (booking: Booking | CreateBooking): boolean =>
    booking.bookingType === BookingType.Collection;
const isInternalBooking = (booking: Booking | CreateBooking): boolean =>
    booking.bookingType === BookingType.Internal;
const isResourceBooking = (booking: Booking | CreateBooking): boolean =>
    booking.bookingType === BookingType.Resource;
const isNoBookingsBooking = (booking: Booking | CreateBooking): boolean =>
    booking.bookingType === BookingType.NoBookings;

const displayProperty = (booking: Booking | CreateBooking) => (
    property: Property
): boolean => {
    switch (property) {
        case 'customerName':
            return (
                isDeliveryBooking(booking) ||
                isInternalBooking(booking) ||
                isCollectionBooking(booking) ||
                isDeliveryAndCollectionBooking(booking)
            );
        case 'haulierName':
        case 'productCode':
        case 'supplierCode':
            return (
                !isInternalBooking(booking) &&
                !isResourceBooking(booking) &&
                !isNoBookingsBooking(booking)
            );
        case 'customerReference':
            return !isResourceBooking(booking) && !isNoBookingsBooking(booking);
        default:
            return false;
    }
};

interface BookingDetailFieldsProps {
    booking: Booking | CreateBooking;
    isCustomerValid: boolean;
    onPropertyValueChange(property: Property): (value: string | null) => void;
}

const BookingDetailFields: React.FC<BookingDetailFieldsProps> = ({
    booking,
    isCustomerValid,
    onPropertyValueChange
}) => {
    const displayBookingProperty = displayProperty(booking);
    return (
        <div>
            {displayBookingProperty('customerName') && (
                <Row>
                    <Column>
                        <NameLookup
                            lookupType="Customer"
                            defaultSearchValue={booking.customerName}
                            isValid={isCustomerValid}
                            onNameSelected={onPropertyValueChange(
                                'customerName'
                            )}
                            required={true}
                            autoConvertWhitespace
                        />
                    </Column>
                </Row>
            )}
            {displayBookingProperty('haulierName') && (
                <>
                    <Row>
                        <Column>
                            <NameLookup
                                lookupType="Haulier"
                                defaultSearchValue={booking.haulierName}
                                isValid
                                onNameSelected={onPropertyValueChange(
                                    'haulierName'
                                )}
                                autoConvertWhitespace
                                hideBottomPadding
                            />
                        </Column>
                    </Row>
                    <hr />
                </>
            )}
            <Row>
                {displayBookingProperty('productCode') && (
                    <Column>
                        <TextInput
                            label="Product Code:"
                            value={booking.productCode}
                            onChange={onPropertyValueChange('productCode')}
                            placeholder="Enter Product Code..."
                            autoConvertWhitespace
                            autoTrim
                            hideBottomPadding
                        />
                    </Column>
                )}
                {displayBookingProperty('supplierCode') && (
                    <Column>
                        <NameLookup
                            title="Supplier Code:"
                            placeholder="Enter Supplier Code..."
                            lookupType="SupplierCode"
                            defaultSearchValue={booking.supplierCode}
                            isValid
                            onNameSelected={onPropertyValueChange(
                                'supplierCode'
                            )}
                            autoConvertWhitespace
                            hideBottomPadding
                        />
                    </Column>
                )}
            </Row>
            {displayBookingProperty('customerReference') && (
                <Row>
                    <Column>
                        <TextInput
                            label="Customer Reference:"
                            value={booking.customerReference}
                            onChange={onPropertyValueChange(
                                'customerReference'
                            )}
                            placeholder="Enter Customer Reference..."
                            autoConvertWhitespace
                            autoTrim
                            hideBottomPadding
                        />
                    </Column>
                </Row>
            )}
            <Row>
                <Column>
                    <TextArea
                        id="BookingInstructions"
                        label={
                            isNoBookingsBooking(booking)
                                ? 'No Bookings Reason:'
                                : 'Booking Instructions:'
                        }
                        value={booking.bookingInstructions}
                        onChange={onPropertyValueChange('bookingInstructions')}
                        autoConvertWhitespace
                        autoTrim
                        hideBottomPadding
                    />
                </Column>
            </Row>
        </div>
    );
};

export default BookingDetailFields;
