import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import useInterval from '../../hooks/useInterval';
import {
    oneMinute,
    oneHour,
    oneDay
} from '../../models/common/TimeAsMiliseconds';

interface ProgressSummaryTableMilestoneProgressProps {
    className?: string;
    overdueClassName: string;
    milestoneStartedAt: Date;
    palletSpaces: number;
    minRefreshInterval?: number;
    useColors?: boolean;
    overdue?(date: Date): boolean;
}

const getTimeElapsed = (date: Date, currentTime: Date) => {
    return currentTime.valueOf() - date.valueOf();
};

const getIntervalDuration = (
    compareDate: Date,
    currentTime: Date,
    minRefreshInterval: number
): number | null => {
    const timeElapsed = Math.abs(getTimeElapsed(compareDate, currentTime));
    if (timeElapsed < oneDay) {
        return Math.max(minRefreshInterval, oneMinute);
    }
    return Math.max(minRefreshInterval, oneHour);
};

const ProgressSummaryTableMilestoneProgress: React.FC<ProgressSummaryTableMilestoneProgressProps> = ({
    className,
    overdueClassName,
    milestoneStartedAt,
    palletSpaces,
    minRefreshInterval = 0,
    useColors,
    overdue
}) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [interval, setInterval] = useState(
        getIntervalDuration(milestoneStartedAt, currentTime, minRefreshInterval)
    );

    useInterval(() => {
        const now = new Date();
        setCurrentTime(now);
        setInterval(
            getIntervalDuration(milestoneStartedAt, now, minRefreshInterval)
        );
    }, interval);

    return (
        <span
            className={classNames(className, {
                [overdueClassName]: overdue && overdue(currentTime) && useColors
            })}
        >
            {palletSpaces}
        </span>
    );
};

export default ProgressSummaryTableMilestoneProgress;
