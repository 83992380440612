import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';
import { NavProps } from './Nav';

type NavClasses =
    | 'container'
    | 'navlinksContainer'
    | 'navLine'
    | 'navFooterLinks'
    | 'environmentLabel';

export const useClasses = buildClassesHook<NavClasses, NavProps, Theme>(
    (theme: Theme) => ({
        container: {
            background: theme.colors.lightBlack,
            width: '100%',
            height: ({ horizontal }) => (horizontal ? '100%' : '100vh'),
            position: 'sticky',
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: ({ horizontal }) =>
                horizontal
                    ? '0 -7px 7px 0 rgba(0,0,0,0.08)'
                    : '7px 0px 7px 0px rgba(0,0,0,0.08)',
        },
        navlinksContainer: {
            width: ({ horizontal }) => (horizontal ? '100%' : 'auto'),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: '1rem auto 1rem auto',
        },
        navLine: {
            borderTopColor: theme.colors.darkerGrey,
            paddingBottom: '0.5rem',
        },
        navFooterLinks: {
            marginBottom: '1.25rem',
        },
        environmentLabel: {
            color: theme.colors.white,
            fontSize: '0.65em',
            textAlign: 'center',
        },
    })
);
