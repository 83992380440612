import * as React from 'react';
import { Load } from '../../../models/loads/Load';
import { useState } from 'react';
import { apiPatch } from '../../../services/api';
import LoadingModal from '../../../components/common/LoadingModal';
import ErrorModal from '../../../components/common/ErrorModal';
import { HttpErrorResponse } from '../../../models/common/HttpErrorResponse';
import EditContactNumberModal from '../../../components/load/modals/EditContactNumberModal';
import { getErrorMessage } from '../../../helpers/errors';

interface EditContactNumberModalContainerProps {
    load: Load;
    showModal: boolean;
    onSave?(): void;
    onClose(): void;
}

const EditContactNumberModalContainer: React.FC<EditContactNumberModalContainerProps> = ({
    load,
    showModal,
    onSave,
    onClose
}) => {
    const [updatingContactNumber, setUpdatingContactNumber] = useState(false);
    const [error, setError] = useState<HttpErrorResponse>();

    const handleSaveClicked = async (contactNumber: string | null) => {
        if (load.contactNumber !== contactNumber) {
            setUpdatingContactNumber(true);
            try {
                await apiPatch(`Load/${load.id}/ContactNumber`, {
                    contactNumber: contactNumber
                });
            } catch (e) {
                console.warn(e);
                setUpdatingContactNumber(false);
                setError(e as HttpErrorResponse);
            }
            if (onSave) {
                onSave();
            }
        }
    };

    const handleEditContactNumberModalClose = () => {
        if (updatingContactNumber) {
            return;
        }
        onClose();
    };

    const modalHeader = `Edit contact number: ${load.loadName}`;
    const showLoadingModal = updatingContactNumber && !error;
    const showEditContactNumberModal = !updatingContactNumber && !error;

    return (
        <>
            <LoadingModal
                showModal={showModal && showLoadingModal}
                header={modalHeader}
                loadingText={
                    updatingContactNumber
                        ? 'Updating contact number...'
                        : 'Loading...'
                }
            />
            <EditContactNumberModal
                load={load}
                showModal={showModal && showEditContactNumberModal}
                onSave={handleSaveClicked}
                onClose={handleEditContactNumberModalClose}
            />
            {!!error && (
                <ErrorModal
                    showModal={showModal}
                    header={modalHeader}
                    errorText={`An error has occurred when updating the contact number: ${getErrorMessage(
                        error
                    )}`}
                    onClose={handleEditContactNumberModalClose}
                />
            )}
        </>
    );
};

export default EditContactNumberModalContainer;
