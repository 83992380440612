import * as React from 'react';
import { useState } from 'react';
import EmailModal from '../../components/bookings/modals/EmailModal';
import RescheduleModal from '../../components/bookings/modals/RescheduleModal';
import ErrorModal from '../../components/common/ErrorModal';
import LoadingModal from '../../components/common/LoadingModal';
import { Booking } from '../../models/bookings/Booking';
import { BookingType } from '../../models/bookings/BookingType';
import RescheduleBooking from '../../models/bookings/RescheduleBooking';
import TimeSlot from '../../models/timeSlot/TimeSlot';
import { apiPost } from '../../services/api';
import { getErrorMessage } from '../../helpers/errors';

interface RescheduleModalContainerProps {
    booking: Booking;
    showModal: boolean;
    onReschedule?(): void;
    onEmailSent(): void;
    onClose(): void;
}

const RescheduleModalContainer: React.FC<RescheduleModalContainerProps> = ({
    booking,
    showModal,
    onReschedule,
    onEmailSent,
    onClose
}) => {
    const [rescheduled, setRescheduled] = useState(false);
    const [submittingReschedule, setSubmittingReschedule] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleTimeSlotSelected = async (selectedTimeSlot: TimeSlot) => {
        setSubmittingReschedule(true);
        try {
            const rescheduleBooking: RescheduleBooking = {
                startDateTime: selectedTimeSlot.start,
                endDateTime: selectedTimeSlot.end,
                isTimeSlotOverridden: !selectedTimeSlot.isAvailable
            };
            await apiPost(
                `Booking/${booking!.id}/Reschedule`,
                rescheduleBooking
            );
            setRescheduled(true);
            setSubmittingReschedule(false);
            onReschedule && onReschedule();
            if (
                booking!.bookingType === BookingType.Resource ||
                booking!.bookingType === BookingType.Internal ||
                booking!.bookingType === BookingType.NoBookings
            ) {
                onClose();
            }
        } catch (error) {
            setSubmittingReschedule(false);
            setErrorMessage(getErrorMessage(error));
        }
    };

    const modalHeader = `Reschedule Booking ${booking?.reference}`;

    const showLoadingModal = showModal && submittingReschedule;
    const showRescheduleModal =
        showModal && !rescheduled && !submittingReschedule && !errorMessage;
    const showEmailModal = showModal && rescheduled;
    const showErrorModal = showModal && !!errorMessage;

    const onErrorModalClose = () => {
        setErrorMessage(null);
        onClose();
    };

    return (
        <>
            <LoadingModal
                showModal={showLoadingModal}
                loadingText="rescheduling"
            />
            {booking && (
                <>
                    <RescheduleModal
                        startDate={new Date(booking.startDate)}
                        endDate={new Date(booking.endDate)}
                        bookingType={booking.bookingType}
                        excludedBookingIds={[booking.id]}
                        resources={booking.resources}
                        store={booking.store}
                        trailerType={booking.trailerType}
                        showModal={showRescheduleModal}
                        onClose={onClose}
                        onTimeSlotSelected={handleTimeSlotSelected}
                    />
                    <EmailModal
                        booking={booking as Booking}
                        showModal={showEmailModal}
                        emailType="rescheduled"
                        bookingType="booking"
                        onEmailSent={onEmailSent}
                        onClose={onClose}
                    />
                    <ErrorModal
                        showModal={showErrorModal}
                        header={modalHeader}
                        errorText={`There was an error rescheduling the booking. ${errorMessage}`}
                        onClose={onErrorModalClose}
                    />
                </>
            )}
        </>
    );
};

export default RescheduleModalContainer;
