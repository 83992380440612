import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import * as React from 'react';
import reactPlugin from '../../helpers/appInsights';
import ErrorDisplay from '../helpers/ErrorDisplay';

const ExceptionLogger: React.FC<React.PropsWithChildren> = ({ children }) => (
    <AppInsightsErrorBoundary
        appInsights={reactPlugin}
        onError={(error) => (
            <ErrorDisplay
                error={`URL: ${
                    window.location.href
                }\n\nTIME: ${new Date().toISOString()}\n\n${error.message}\n\n${
                    error.stack
                }`}
            />
        )}
    >
        <>{children}</>
    </AppInsightsErrorBoundary>
);

export default ExceptionLogger;
