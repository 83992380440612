import * as React from 'react';
import classNames from 'classnames';

interface CheckboxProps {
    checked: boolean;
    containerClassName?: string;
    labelClassName?: string;
    checkboxClassName?: string;
    disabled?: boolean;
    inline?: boolean;
    label?: string;
    readOnly?: boolean;
    rtl?: boolean;
    size?: number | string;
    value?: any;

    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
    checked = false,
    containerClassName,
    labelClassName,
    checkboxClassName,
    disabled,
    inline = false,
    label,
    readOnly,
    rtl = false,
    size,
    value,
    onChange
}) => {
    return (
        <div
            className={classNames('form-check', containerClassName, {
                'form-check-inline': inline
            })}
        >
            {label ? (
                rtl ? (
                    <label
                        className={classNames(
                            'form-check-label',
                            labelClassName,
                            {
                                'text-muted': disabled
                            }
                        )}
                    >
                        {label}
                        <input
                            className={classNames(
                                'form-check-input',
                                checkboxClassName
                            )}
                            type="checkbox"
                            checked={checked}
                            disabled={disabled}
                            style={{ height: size, width: size }}
                            value={value}
                            onChange={onChange}
                        />
                    </label>
                ) : (
                    <label
                        className={classNames(
                            'form-check-label',
                            labelClassName,
                            {
                                'text-muted': disabled
                            }
                        )}
                    >
                        <input
                            className={classNames(
                                'form-check-input',
                                checkboxClassName
                            )}
                            type="checkbox"
                            checked={checked}
                            disabled={disabled}
                            style={{ height: size, width: size }}
                            value={value}
                            onChange={onChange}
                        />
                        {label}
                    </label>
                )
            ) : (
                <input
                    className={classNames(
                        'form-check-input position-static',
                        checkboxClassName
                    )}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    readOnly={readOnly}
                    style={{ height: size, width: size }}
                    value={value}
                    onChange={onChange}
                />
            )}
        </div>
    );
};

export default Checkbox;
