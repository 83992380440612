import { default as classnames, default as classNames } from 'classnames';
import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Alert from '../../lib/bootstrap-ui/Alert';
import Button from '../../lib/bootstrap-ui/Button';
import LoadingIndicator from '../loading/LoadingIndicator';

const useClasses = buildClassesHook({
    alert: {
        width: '100%',
        margin: '15px',
        '& button': {
            outline: 'none',
        },
    },
    link: {
        verticalAlign: 'baseline',
        padding: 0,
    },
    loadingIndicator: {
        padding: 0,
        display: 'inline-block',
    },
});

interface SignalRAlertProps {
    className?: string;
    messageType: 'disconnect' | 'reconnect failure' | 'retry';

    onRetryClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
    onDismiss?(): void;
}

const SignalRAlert: React.FC<SignalRAlertProps> = ({
    className,
    messageType,
    onRetryClick,
    onDismiss,
}) => {
    const classes = useClasses();

    return (
        <Alert
            type="danger"
            className={classNames(classes.alert, className)}
            onDismiss={onDismiss}
        >
            {messageType === 'disconnect' ? (
                <LoadingIndicator
                    className={classes.loadingIndicator}
                    text="Connection to the server has been lost. Live updates are not
                available. Attempting to reconnect."
                />
            ) : messageType === 'reconnect failure' ? (
                <>
                    Could not reconnect to the server. Live updates are not
                    available. Please try refreshing the page.
                </>
            ) : (
                <>
                    Connection to the server has been lost. Live updates are not
                    available. Please click{' '}
                    <Button
                        styleType="link"
                        className={classnames('alert-link', classes.link)}
                        onClick={(
                            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => {
                            e.preventDefault();
                            onRetryClick && onRetryClick(e);
                        }}
                    >
                        here
                    </Button>{' '}
                    to try to reconnect.
                </>
            )}
        </Alert>
    );
};

export default SignalRAlert;
