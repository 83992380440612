import * as React from 'react';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    faArrowAltCircleRight,
    faBan,
    faBook,
    faBoxes,
    faCalendarDay,
    faCalendarWeek,
    faCalendarAlt,
    faCheck,
    faCheckCircle,
    faChevronRight,
    faChevronLeft,
    faClipboardCheck,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faShippingFast,
    faRepeat,
    faTimes,
    faTrashAlt,
    faTruckLoading,
    faUndo,
    faMinusCircle,
    faHistory,
    faSearch,
    faPlus,
    faFilter,
    faVideo,
    faMinus,
    faExchange,
    faArrowToBottom,
    faFileAlt,
    faUpload,
    faDownload,
    faSpinnerThird,
    faPaperclip,
    faPencil,
    faCalendar,
    faPersonDollyEmpty,
    faTimesCircle,
    faEyeSlash,
    faFilesMedical,
    faSync,
    faTruckRamp,
    faPhone,
    faPhonePlus,
    faShareAll,
    faUnlink,
} from '@fortawesome/pro-light-svg-icons';
import {
    faBookOpen,
    faBoxOpen,
    faBars,
    faTruckLoading as faTruckLoadingDuo,
    faBook as faBookDuo,
    faCog,
    faTasksAlt as faTasksAltDuo,
    faHistory as faHistoryDuo,
    faRepeat as faRepeatDuo,
    faSignOut,
    faCameraCctv,
    faLongArrowLeft,
    faCommentAltLines,
    faPig,
    faArrowUpRightFromSquare,
} from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltDown } from '@fortawesome/pro-solid-svg-icons';
import {
    faArrowDown,
    faFilter as faFilterSolid,
    faStickyNote,
    faFilePdf,
    faFileExcel,
    faFileWord,
    faCircle,
    faChevronDown,
    faExclamationTriangle as faExclamationTriangleThicc,
    faPlus as faPlusSolid,
    faTimes as faTimesSolid,
} from '@fortawesome/free-solid-svg-icons';
import LoadingSpinnerIcon from '../loading/LoadingSpinnerIcon';
import classNames from 'classnames';

export type IconType =
    | 'bars'
    | 'back'
    | 'baywatch-link'
    | 'book'
    | 'book-duo'
    | 'recurring-booking'
    | 'recurring-booking-duo'
    | 'boxes'
    | 'calendar'
    | 'calendar-week'
    | 'calendar-day'
    | 'calendar-blank'
    | 'checked'
    | 'circle-arrow-right'
    | 'cross'
    | 'cross-solid'
    | 'cancel'
    | 'delete'
    | 'edit'
    | 'ellipsis'
    | 'empty-box'
    | 'envelope'
    | 'error'
    | 'external-link'
    | 'history'
    | 'history-duo'
    | 'info'
    | 'loading'
    | 'next'
    | 'out-of-order'
    | 'circle-arrow-right'
    | 'previous'
    | 'settings'
    | 'tick'
    | 'truck-arrive'
    | 'truck-leave'
    | 'truck-loading'
    | 'truck-loading-duo'
    | 'undo'
    | 'video'
    | 'warning'
    | 'warning-thicc'
    | 'plus'
    | 'plus-solid'
    | 'search'
    | 'filter'
    | 'filter-solid'
    | 'filter-clear'
    | 'sticky-note'
    | 'clipboard-check'
    | 'minus'
    | 'file'
    | 'pdf-file'
    | 'excel-file'
    | 'word-file'
    | 'upload'
    | 'download'
    | 'spinner'
    | 'paper-clip'
    | 'exchange'
    | 'arrow-to-bottom'
    | 'pencil'
    | 'person-dolly-empty'
    | 'no-show'
    | 'overridden'
    | 'sign-out'
    | 'truck-ramp'
    | 'no-bookings'
    | 'no-loads'
    | 'sync'
    | 'tasks-alt'
    | 'arrow-up'
    | 'arrow-right'
    | 'arrow-down'
    | 'arrow-left'
    | 'arrow-thicc-up'
    | 'arrow-thicc-right'
    | 'arrow-thicc-down'
    | 'arrow-thicc-left'
    | 'phone'
    | 'sms-message'
    | 'no-sms-templates'
    | 'chevron-down'
    | 'chevron-right'
    | 'phone-edit'
    | 'piggyback'
    | 'piggyback-transfer'
    | 'piggyback-remove'
    | 'share-all'
    | 'unlink';

interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
    type: IconType;
}

const Icon: React.FC<IconProps> = ({ type, className, ...rest }) => {
    switch (type) {
        case 'error':
            return (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className={className}
                    {...rest}
                />
            );
        case 'warning':
            return (
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className={className}
                    {...rest}
                />
            );
        case 'warning-thicc':
            return (
                <FontAwesomeIcon
                    icon={faExclamationTriangleThicc}
                    className={className}
                    {...rest}
                />
            );
        case 'loading':
            return (
                <LoadingSpinnerIcon
                    size={rest.size}
                    className={className}
                    {...rest}
                />
            );
        case 'info':
            return (
                <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={className}
                    {...rest}
                />
            );
        case 'out-of-order':
            return (
                <FontAwesomeIcon
                    icon={faMinusCircle}
                    className={className}
                    {...rest}
                />
            );
        case 'checked':
            return (
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={className}
                    {...rest}
                />
            );
        case 'tick':
            return (
                <FontAwesomeIcon
                    icon={faCheck}
                    className={className}
                    {...rest}
                />
            );
        case 'next':
            return (
                <FontAwesomeIcon
                    icon={faChevronRight}
                    className={className}
                    {...rest}
                />
            );
        case 'previous':
            return (
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={className}
                    {...rest}
                />
            );
        case 'chevron-down':
            return (
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={className}
                    {...rest}
                />
            );
        case 'chevron-right':
            return (
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={className}
                    transform="rotate--90"
                    {...rest}
                />
            );
        case 'back':
            return (
                <FontAwesomeIcon
                    icon={faLongArrowLeft}
                    className={className}
                    {...rest}
                />
            );
        case 'circle-arrow-right':
            return (
                <FontAwesomeIcon
                    icon={faArrowAltCircleRight}
                    className={className}
                    {...rest}
                />
            );
        case 'bars':
            return (
                <FontAwesomeIcon
                    icon={faBars}
                    className={className}
                    {...rest}
                />
            );
        case 'baywatch-link':
            return (
                <FontAwesomeIcon
                    icon={faCameraCctv}
                    className={className}
                    {...rest}
                    swapOpacity
                />
            );
        case 'truck-loading':
            return (
                <FontAwesomeIcon
                    icon={faTruckLoading}
                    className={className}
                    {...rest}
                />
            );
        case 'truck-loading-duo':
            return (
                <FontAwesomeIcon
                    icon={faTruckLoadingDuo}
                    className={className}
                    {...rest}
                />
            );
        case 'book':
            return (
                <FontAwesomeIcon
                    icon={faBook}
                    className={className}
                    {...rest}
                />
            );
        case 'book-duo':
            return (
                <FontAwesomeIcon
                    icon={faBookDuo}
                    className={className}
                    {...rest}
                />
            );
        case 'settings':
            return (
                <FontAwesomeIcon
                    icon={faCog}
                    className={className}
                    {...rest}
                    swapOpacity
                />
            );
        case 'cross':
            return (
                <FontAwesomeIcon
                    icon={faTimes}
                    className={className}
                    {...rest}
                />
            );
        case 'cross-solid':
            return (
                <FontAwesomeIcon
                    icon={faTimesSolid}
                    className={className}
                    {...rest}
                />
            );
        case 'calendar':
            return (
                <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className={className}
                    {...rest}
                />
            );
        case 'calendar-week':
            return (
                <FontAwesomeIcon
                    icon={faCalendarWeek}
                    className={className}
                    {...rest}
                />
            );
        case 'calendar-day':
            return (
                <FontAwesomeIcon
                    icon={faCalendarDay}
                    className={className}
                    {...rest}
                />
            );
        case 'calendar-blank':
            return (
                <FontAwesomeIcon
                    icon={faCalendar}
                    className={className}
                    {...rest}
                />
            );
        case 'delete':
            return (
                <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={className}
                    {...rest}
                />
            );
        case 'envelope':
            return (
                <FontAwesomeIcon
                    icon={faEnvelope}
                    className={className}
                    {...rest}
                />
            );
        case 'ellipsis':
            return (
                <FontAwesomeIcon
                    icon={faEllipsisH}
                    className={className}
                    {...rest}
                />
            );
        case 'truck-arrive':
            return (
                <FontAwesomeIcon
                    icon={faShippingFast}
                    className={className}
                    {...rest}
                />
            );
        case 'edit':
            return (
                <FontAwesomeIcon
                    icon={faEdit}
                    className={className}
                    {...rest}
                />
            );
        case 'external-link':
            return (
                <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className={className}
                    {...rest}
                    swapOpacity
                />
            );
        case 'boxes':
            return (
                <FontAwesomeIcon
                    icon={faBoxes}
                    className={className}
                    {...rest}
                />
            );
        case 'history':
            return (
                <FontAwesomeIcon
                    icon={faHistory}
                    className={className}
                    {...rest}
                />
            );
        case 'history-duo':
            return (
                <FontAwesomeIcon
                    icon={faHistoryDuo}
                    swapOpacity
                    className={className}
                    {...rest}
                />
            );
        case 'undo':
            return (
                <FontAwesomeIcon
                    icon={faUndo}
                    className={className}
                    {...rest}
                />
            );
        case 'video':
            return (
                <FontAwesomeIcon
                    icon={faVideo}
                    className={className}
                    {...rest}
                />
            );
        case 'recurring-booking':
            return (
                <FontAwesomeIcon
                    icon={faRepeat}
                    className={className}
                    {...rest}
                />
            );
        case 'recurring-booking-duo':
            return (
                <FontAwesomeIcon
                    icon={faRepeatDuo}
                    className={className}
                    {...rest}
                />
            );
        case 'truck-leave':
            return (
                <FontAwesomeIcon
                    icon={faShippingFast}
                    className={className}
                    transform={{ flipX: true }}
                    {...rest}
                />
            );
        case 'truck-ramp':
            return (
                <FontAwesomeIcon
                    icon={faTruckRamp}
                    className={className}
                    {...rest}
                />
            );
        case 'plus':
            return (
                <FontAwesomeIcon
                    icon={faPlus}
                    className={className}
                    {...rest}
                />
            );
        case 'plus-solid':
            return (
                <FontAwesomeIcon
                    icon={faPlusSolid}
                    className={className}
                    {...rest}
                />
            );
        case 'search':
            return (
                <FontAwesomeIcon
                    icon={faSearch}
                    className={className}
                    {...rest}
                />
            );
        case 'filter':
            return (
                <FontAwesomeIcon
                    icon={faFilter}
                    className={className}
                    {...rest}
                />
            );
        case 'filter-solid':
            return (
                <FontAwesomeIcon
                    icon={faFilterSolid}
                    className={className}
                    {...rest}
                />
            );
        case 'filter-clear':
            return (
                <span className={classNames('fa-layers fa-fw', className)}>
                    <FontAwesomeIcon icon={faFilter} />
                    <FontAwesomeIcon
                        icon={faTimes}
                        transform="shrink-5 right-8 down-6"
                    />
                </span>
            );
        case 'clipboard-check':
            return (
                <FontAwesomeIcon
                    icon={faClipboardCheck}
                    className={className}
                    {...rest}
                />
            );
        case 'sticky-note':
            return (
                <FontAwesomeIcon
                    icon={faStickyNote}
                    className={className}
                    {...rest}
                />
            );
        case 'minus':
            return (
                <FontAwesomeIcon
                    icon={faMinus}
                    className={className}
                    {...rest}
                />
            );
        case 'file':
            return (
                <FontAwesomeIcon
                    icon={faFileAlt}
                    className={className}
                    {...rest}
                />
            );
        case 'pdf-file':
            return (
                <FontAwesomeIcon
                    icon={faFilePdf}
                    className={className}
                    {...rest}
                />
            );
        case 'excel-file':
            return (
                <FontAwesomeIcon
                    icon={faFileExcel}
                    className={className}
                    {...rest}
                />
            );
        case 'word-file':
            return (
                <FontAwesomeIcon
                    icon={faFileWord}
                    className={className}
                    {...rest}
                />
            );
        case 'upload':
            return (
                <FontAwesomeIcon
                    icon={faUpload}
                    className={className}
                    {...rest}
                />
            );
        case 'exchange':
            return (
                <FontAwesomeIcon
                    icon={faExchange}
                    className={className}
                    {...rest}
                />
            );
        case 'arrow-to-bottom':
            return (
                <FontAwesomeIcon
                    icon={faArrowToBottom}
                    className={className}
                    {...rest}
                />
            );
        case 'arrow-up':
            return (
                <FontAwesomeIcon
                    icon={faArrowDown}
                    className={className}
                    transform="rotate--180"
                    {...rest}
                />
            );
        case 'arrow-right':
            return (
                <FontAwesomeIcon
                    icon={faArrowDown}
                    className={className}
                    transform="rotate--90"
                    {...rest}
                />
            );
        case 'arrow-down':
            return (
                <FontAwesomeIcon
                    icon={faArrowDown}
                    className={className}
                    {...rest}
                />
            );
        case 'arrow-left':
            return (
                <FontAwesomeIcon
                    icon={faArrowDown}
                    className={className}
                    transform="rotate--270"
                    {...rest}
                />
            );
        case 'arrow-thicc-up':
            return (
                <FontAwesomeIcon
                    icon={faArrowAltDown}
                    className={className}
                    transform="rotate--180"
                    {...rest}
                />
            );
        case 'arrow-thicc-right':
            return (
                <FontAwesomeIcon
                    icon={faArrowAltDown}
                    className={className}
                    transform="rotate--90"
                    {...rest}
                />
            );
        case 'arrow-thicc-down':
            return (
                <FontAwesomeIcon
                    icon={faArrowAltDown}
                    className={className}
                    {...rest}
                />
            );
        case 'arrow-thicc-left':
            return (
                <FontAwesomeIcon
                    icon={faArrowAltDown}
                    className={className}
                    transform="rotate--270"
                    {...rest}
                />
            );
        case 'download':
            return (
                <FontAwesomeIcon
                    icon={faDownload}
                    className={className}
                    {...rest}
                />
            );
        case 'spinner':
            return (
                <FontAwesomeIcon
                    icon={faSpinnerThird}
                    className={className}
                    style={{ animation: 'fa-spin 2s linear infinite' }}
                    {...rest}
                />
            );
        case 'paper-clip':
            return (
                <FontAwesomeIcon
                    icon={faPaperclip}
                    className={className}
                    {...rest}
                />
            );
        case 'pencil':
            return (
                <FontAwesomeIcon
                    icon={faPencil}
                    className={className}
                    {...rest}
                />
            );
        case 'person-dolly-empty':
            return (
                <FontAwesomeIcon
                    icon={faPersonDollyEmpty}
                    className={className}
                    {...rest}
                />
            );
        case 'cancel':
            return (
                <FontAwesomeIcon
                    icon={faTimesCircle}
                    className={className}
                    {...rest}
                />
            );
        case 'no-show':
            return (
                <FontAwesomeIcon
                    icon={faEyeSlash}
                    className={className}
                    {...rest}
                />
            );
        case 'overridden':
            return (
                <FontAwesomeIcon
                    icon={faFilesMedical}
                    className={className}
                    {...rest}
                />
            );
        case 'sync':
            return (
                <FontAwesomeIcon
                    icon={faSync}
                    className={className}
                    {...rest}
                />
            );
        case 'sign-out':
            return (
                <FontAwesomeIcon
                    icon={faSignOut}
                    className={className}
                    {...rest}
                />
            );
        case 'tasks-alt':
            return (
                <FontAwesomeIcon
                    icon={faTasksAltDuo}
                    className={className}
                    {...rest}
                />
            );
        case 'empty-box':
            return (
                <FontAwesomeIcon
                    icon={faBoxOpen}
                    className={className}
                    {...rest}
                />
            );
        case 'phone':
            return (
                <FontAwesomeIcon
                    icon={faPhone}
                    className={className}
                    {...rest}
                />
            );
        case 'phone-edit':
            return (
                <FontAwesomeIcon
                    icon={faPhonePlus}
                    className={className}
                    {...rest}
                />
            );
        case 'sms-message':
            return (
                <FontAwesomeIcon
                    icon={faCommentAltLines}
                    className={className}
                    {...rest}
                />
            );
        case 'piggyback':
            return (
                <FontAwesomeIcon icon={faPig} className={className} {...rest} />
            );
        case 'piggyback-transfer':
            return (
                <span className={classNames('fa-layers fa-fw', className)}>
                    <FontAwesomeIcon
                        icon={faPig}
                        transform="shrink-3 up-6 flip-h"
                        {...rest}
                    />
                    <FontAwesomeIcon
                        icon={faPig}
                        transform="shrink-3 left-8 down-6 flip-h"
                        {...rest}
                    />
                    <FontAwesomeIcon
                        icon={faPig}
                        transform="shrink-3 right-8 down-6 flip-h"
                        {...rest}
                    />
                </span>
            );
        case 'piggyback-remove':
            return (
                <span className={classNames('fa-layers fa-fw', className)}>
                    <FontAwesomeIcon
                        icon={faPig}
                        transform="shrink-2"
                        {...rest}
                    />
                    <FontAwesomeIcon
                        icon={faBan}
                        transform="grow-2"
                        {...rest}
                    />
                </span>
            );
        case 'no-bookings':
            return (
                <span className={classNames('fa-layers fa-fw', className)}>
                    <FontAwesomeIcon icon={faCircle} {...rest} />
                    <FontAwesomeIcon
                        icon={faBookOpen}
                        transform="shrink-1 rotate--25"
                        {...rest}
                    />
                </span>
            );
        case 'no-loads':
            return (
                <span className={classNames('fa-layers fa-fw', className)}>
                    <FontAwesomeIcon icon={faCircle} {...rest} />
                    <FontAwesomeIcon
                        icon={faTruckLoadingDuo}
                        transform="shrink-4"
                        {...rest}
                    />
                </span>
            );
        case 'no-sms-templates':
            return (
                <span className={classNames('fa-layers fa-fw', className)}>
                    <FontAwesomeIcon icon={faCircle} {...rest} />
                    <FontAwesomeIcon
                        icon={faCommentAltLines}
                        transform="shrink-2 rotate--10 down-1"
                        {...rest}
                    />
                </span>
            );
        case 'share-all':
            return (
                <FontAwesomeIcon
                    icon={faShareAll}
                    className={className}
                    {...rest}
                />
            );
        case 'unlink':
            return (
                <FontAwesomeIcon
                    icon={faUnlink}
                    className={className}
                    {...rest}
                />
            );
        default:
            return null;
    }
};

export default Icon;
