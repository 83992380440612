import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import Button from '../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../lib/bootstrap-ui/Modal';
import InformationMessage from '../common/InformationMessage';

const useClasses = buildClassesHook({
    errorModal: {
        minWidth: '200px',
        maxWidth: '600px',
    },
});

interface ErrorModalProps {
    showModal: boolean;
    header?: string;
    errorText?: string;
    showFooterButtons?: boolean;
    onContinue?(): void;
    onClose(): void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
    showModal,
    header,
    errorText,
    showFooterButtons = false,
    onContinue,
    onClose,
}) => {
    const classes = useClasses();

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.errorModal}
            onRequestClose={onClose}
        >
            {header && (
                <ModalHeader>
                    <h5>{header}</h5>
                </ModalHeader>
            )}
            <ModalBody>
                <InformationMessage messageType="error">
                    {errorText}
                </InformationMessage>
            </ModalBody>
            {showFooterButtons && (
                <ModalFooter>
                    {onClose && (
                        <Button styleType="secondary" onClick={onClose}>
                            Close
                        </Button>
                    )}
                    {onContinue && (
                        <Button styleType="danger" onClick={onContinue}>
                            Continue
                        </Button>
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
};

export default ErrorModal;
