import * as React from 'react';
import { useState } from 'react';
import UpdateOrdersModal from '../../../components/bookings/modals/UpdateOrdersModal';
import { Booking } from '../../../models/bookings/Booking';
import { useData } from '../../../services/api';

interface UpdateOrdersModalContainerProps {
    bookingId: string;
    showModal: boolean;
    onUpdate?(): void;
    onClose(): void;
}

const UpdateOrdersModalContainer: React.FC<UpdateOrdersModalContainerProps> = ({
    bookingId,
    showModal,
    onUpdate,
    onClose
}) => {
    const [originalBookingId] = useState<string>(bookingId);

    const [booking, loadingBooking] = useData<Booking>(
        originalBookingId ? `Booking/${originalBookingId}` : null
    );

    return (
        <>
            {booking && (
                <UpdateOrdersModal
                    booking={booking}
                    showModal={showModal && !loadingBooking}
                    onUpdate={onUpdate}
                    onClose={onClose}
                />
            )}
        </>
    );
};

export default UpdateOrdersModalContainer;
