import { ReducerAction } from '../models/reducers/ReducerAction';
import { SMSTemplate } from '../models/smsTemplates/SMSTemplate';
import { Change } from '../models/updates/Change';

export type SMSTemplateTableAction =
    | 'UpdateSMSTemplate'
    | 'RemoveSMSTemplate'
    | 'ReplaceSMSTemplates';

interface SMSTemplateTableModifySMSTemplates {
    affectedSMSTemplates: SMSTemplate[];
}

interface SMSTemplateRemoveSMSTemplate {
    removedSMSTemplateID: string;
}

interface SMSTemplateTableModifySMSTemplatesAction
    extends ReducerAction<
        SMSTemplateTableAction,
        SMSTemplateTableModifySMSTemplates
    > {
    type: Extract<
        SMSTemplateTableAction,
        'UpdateSMSTemplate' | 'ReplaceSMSTemplates'
    >;
    changes?: Change[];
}

interface SMSTemplateTableRemoveSMSTemplateAction
    extends ReducerAction<
        SMSTemplateTableAction,
        SMSTemplateRemoveSMSTemplate
    > {
    type: Extract<SMSTemplateTableAction, 'RemoveSMSTemplate'>;
}

const SMSTemplateTableReducer = (
    state: SMSTemplate[],
    smsTemplateTableAction:
        | SMSTemplateTableModifySMSTemplatesAction
        | SMSTemplateTableRemoveSMSTemplateAction
): SMSTemplate[] => {
    switch (smsTemplateTableAction.type) {
        case 'UpdateSMSTemplate':
            const updatedSMSTemplateIds = smsTemplateTableAction.payload
                .affectedSMSTemplates
                ? smsTemplateTableAction.payload.affectedSMSTemplates.map(
                      t => t.id
                  )
                : [];
            const updatedSMSTemplatesUnaffectedSMSTemplates: SMSTemplate[] = state.filter(
                t => !updatedSMSTemplateIds.includes(t.id)
            );
            const addedSMSTemplates: SMSTemplate[] = smsTemplateTableAction.payload.affectedSMSTemplates.filter(
                t => !state.map(s => s.id).includes(t.id)
            );
            const updatedSMSTemplatesAffectedSMSTemplates: SMSTemplate[] = state
                .filter(t => updatedSMSTemplateIds.includes(t.id))
                .map(af => {
                    const updatedSMSTemplate = smsTemplateTableAction.payload.affectedSMSTemplates?.find(
                        paf => paf.id === af.id
                    );
                    return updatedSMSTemplate || af;
                });

            return [
                ...updatedSMSTemplatesUnaffectedSMSTemplates,
                ...updatedSMSTemplatesAffectedSMSTemplates,
                ...addedSMSTemplates
            ];
        case 'RemoveSMSTemplate':
            return state.filter(
                l =>
                    l.id !== smsTemplateTableAction.payload.removedSMSTemplateID
            );
        case 'ReplaceSMSTemplates':
            return smsTemplateTableAction.payload.affectedSMSTemplates;
    }
};

export default SMSTemplateTableReducer;
