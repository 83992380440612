import * as React from 'react';
import { useMemo, useContext } from 'react';

import { StoreNumber } from '../../../models/stores/StoreNumber';
import { useAuth } from '../../../services/authentication';
import { userToAllStores } from '../../../services/userService';
import { useClasses } from './StoreSelector.styles';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';
import Icon from '../Icon';
import classNames from 'classnames';

interface StoreSelectorProps {
    sourceStore: StoreNumber | null;
    destinationStore: StoreNumber | null;
    onStoreSelected(type: 'Source' | 'Destination', value: StoreNumber): void;
}

const StoreSelector: React.FC<StoreSelectorProps> = ({
    sourceStore,
    destinationStore,
    onStoreSelected,
}) => {
    const { authState } = useAuth();
    const { stores } = useContext(LookupContext);

    const userStores: StoreNumber[] = useMemo(
        () => userToAllStores(authState.user),
        [authState]
    );

    const classes = useClasses();

    const handleSourceStoreUpdated = (store: StoreNumber) => {
        onStoreSelected('Source', store);
    };

    const handleDestinationStoreUpdated = (store: StoreNumber) => {
        onStoreSelected('Destination', store);
    };

    return (
        <div className={classes.storeSelector}>
            <div className={classes.sourceStoreSelectorContainer}>
                <h4>Source</h4>
                <div className={classes.storeOptionsContainer}>
                    {userStores.map((store) => (
                        <div
                            className={classNames(classes.storeOption, {
                                [classes.storeOptionUnselected]:
                                    store !== sourceStore &&
                                    store !== destinationStore,
                                [classes.storeOptionSelected]:
                                    store === sourceStore,
                                [classes.storeOptionDisabled]:
                                    store === destinationStore,
                            })}
                            key={store}
                            onClick={() =>
                                store !== destinationStore &&
                                handleSourceStoreUpdated(store)
                            }
                        >
                            <Checkbox
                                checkboxClassName={classes.storeOptionCheckbox}
                                containerClassName={
                                    classes.storeOptionCheckboxContainer
                                }
                                labelClassName={
                                    classes.storeOptionCheckboxLabel
                                }
                                checked={store === sourceStore}
                                disabled={store === destinationStore}
                                inline
                                rtl
                                label={`Store ${store.toString()}`}
                                value={store}
                                onChange={() => handleSourceStoreUpdated(store)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.arrowContainer}>
                <Icon className={classes.arrowIcon} type="arrow-right" />
            </div>
            <div className={classes.destinationStoreSelectorContainer}>
                <h4>Destination</h4>
                <div className={classes.storeOptionsContainer}>
                    {stores
                        .filter((store) => !!store)
                        .map((store) => (
                            <div
                                className={classNames(classes.storeOption, {
                                    [classes.storeOptionUnselected]:
                                        store !== sourceStore &&
                                        store !== destinationStore,
                                    [classes.storeOptionSelected]:
                                        store === destinationStore,
                                    [classes.storeOptionDisabled]:
                                        store === sourceStore,
                                })}
                                key={store}
                                onClick={() =>
                                    store !== sourceStore &&
                                    handleDestinationStoreUpdated(store)
                                }
                            >
                                <Checkbox
                                    checkboxClassName={
                                        classes.storeOptionCheckbox
                                    }
                                    containerClassName={
                                        classes.storeOptionCheckboxContainer
                                    }
                                    labelClassName={
                                        classes.storeOptionCheckboxLabel
                                    }
                                    checked={store === destinationStore}
                                    disabled={store === sourceStore}
                                    inline
                                    rtl
                                    label={`Store ${store.toString()}`}
                                    value={store}
                                    onChange={() =>
                                        handleDestinationStoreUpdated(store)
                                    }
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default StoreSelector;
