import * as React from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../../lib/bootstrap-ui/Modal';
import { SMSTemplate } from '../../../../models/smsTemplates/SMSTemplate';
import { useClasses } from './SMSTemplateModalStyles';

interface DeleteSMSTemplateModalProps {
    showModal: boolean;
    smsTemplate: SMSTemplate;
    onDeleteRequested(smsTemplate: SMSTemplate): void;
    onClose(): void;
}

const DeleteSMSTemplateModal: React.FC<DeleteSMSTemplateModalProps> = ({
    showModal,
    smsTemplate,
    onDeleteRequested,
    onClose
}) => {
    const classes = useClasses();

    return (
        <Modal
            isOpen={showModal}
            modalClassName={classes.modalStyle}
            onRequestClose={onClose}
        >
            <ModalHeader>
                <h5>Delete SMS Template</h5>
            </ModalHeader>
            <ModalBody>
                Are you sure you want to delete the SMS Template named{' '}
                <strong>{smsTemplate.name}</strong>?
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Go Back
                </Button>
                <Button
                    styleType="danger"
                    onClick={() => onDeleteRequested(smsTemplate)}
                >
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteSMSTemplateModal;
