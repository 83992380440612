import { addDays, format } from 'date-fns';
import { BookingAction } from '../components/bookings/update/table/BookingTableRowAction';
import { DateRange } from '../components/common/DateRangePills';
import { StoreSelectorOption } from '../components/common/StoreSelectorPills';
import { formatDate } from '../helpers/dates';
import { getDayString } from '../helpers/stringFunctions';
import { Booking } from '../models/bookings/Booking';
import {
    BookingAttachment,
    UploadStatus,
} from '../models/bookings/BookingAttachment';
import { BookingStatus } from '../models/bookings/BookingStatus';
import { BookingType } from '../models/bookings/BookingType';
import { LoadingResource } from '../models/bookings/LoadingResource';
import { RecurringBooking } from '../models/bookings/RecurringBooking';
import { FileType } from '../models/common/FileType';
import { ColdStoreOrder } from '../models/orders/ColdStoreOrder';
import { StoreNumber } from '../models/stores/StoreNumber';
import { TrailerType } from '../models/trailers/TrailerType';
import { theme } from '../theme';
import { apiDelete, apiGet, apiPut } from './api';
import {
    FileToDownload,
    downloadFileFromBlobStorage,
    uploadFilesToBlobStorage,
} from './blobClientService';
import { formatToUtc } from './timeStringService';

const BOOKING_TABLE_PAGE_SIZE = 200;

const getBookingWithExistingCustomerReference = async (
    customerReference: string | null
) => {
    if (customerReference) {
        const params = {
            customerReference: encodeURIComponent(customerReference),
        };
        const bookingWithCustomerReference: Booking = await apiGet(
            `Booking/CustomerReference`,
            params
        );
        return bookingWithCustomerReference;
    }
    return null;
};

const getBookingsEndDateFromDateRange = (dateRange: DateRange) => {
    if (dateRange === 'All') {
        return null;
    }
    const bookingsEndDate = addDays(
        new Date(),
        dateRange === 'Day' ? 1 : dateRange === 'Week' ? 7 : 30
    );
    const test = formatToUtc(bookingsEndDate);
    return new Date(test);
};

const getFormattedBookingHeader = (booking: Booking) => {
    const startDayAndMonth = formatDate(booking.startDate, 'dd/MM');
    const endDayAndMonth = formatDate(booking.endDate, 'dd/MM');
    if (startDayAndMonth === endDayAndMonth) {
        return `${formatDate(booking.startDate, 'dd/MM HH:mm')} - ${formatDate(
            booking.endDate,
            'HH:mm'
        )}`;
    }
    return `${formatDate(
        booking.startDate,
        'dd/MM HH:mm'
    )} - ${endDayAndMonth} ${formatDate(booking.endDate, 'HH:mm')}`;
};

const getRecurrenceString = (recurringBooking: RecurringBooking) => {
    let recurrence: string;
    const startDate = new Date(recurringBooking.startDate);
    const endDate = new Date(startDate.getTime());
    endDate.setHours(endDate.getHours() + recurringBooking.duration);
    const startTime = format(startDate, 'HH:mm');
    const endTime = format(endDate, 'HH:mm');

    switch (recurringBooking.recurrencePattern) {
        case 'Daily':
            recurrence = 'Day';
            break;
        case 'Weekdays':
            recurrence = 'Weekday';
            break;
        case 'Weekly':
            const startDay = startDate.getDay();
            const endDay = endDate.getDay();
            recurrence = getDayString(startDay);
            if (startDay !== endDay) {
                const bookingEndDay = getDayString(endDay);
                return `Every ${recurrence} ${startTime} - ${bookingEndDay} ${endTime}`;
            }
            break;
    }

    return `Every ${recurrence} ${startTime} - ${endTime}`;
};

const getBookingAction = (
    booking: Booking,
    completing?: boolean
): BookingAction => {
    if (completing) {
        return 'Loading';
    }

    switch (booking.status) {
        case BookingStatus.AwaitingCompletion:
            return booking.bookingType === BookingType.Internal ||
                booking.bookingType === BookingType.Resource ||
                booking.bookingType === BookingType.NoBookings
                ? 'Complete'
                : 'Check Out';
        case BookingStatus.AwaitingLoadCompletion:
            return 'Release Load';
        case BookingStatus.RequiresOrders:
            return 'Requires Orders';
        case BookingStatus.AwaitingHaulier:
        default:
            return 'Check In';
    }
};

const getBookingStatusLabel = (status: BookingStatus) => {
    switch (status) {
        case BookingStatus.AwaitingHaulier:
            return 'Awaiting Haulier';
        case BookingStatus.AwaitingLoadCompletion:
            return 'Awaiting Load Release';
        case BookingStatus.AwaitingCompletion:
            return 'Awaiting Completion';
        case BookingStatus.Complete:
            return 'Complete';
        case BookingStatus.Cancelled:
            return 'Cancelled';
        case BookingStatus.RequiresOrders:
            return 'Required Orders';
    }
};

const getBookingTypeColour = (bookingType: BookingType): string => {
    switch (bookingType) {
        case BookingType.Delivery:
            return theme.colors.deliveryBooking;
        case BookingType.Collection:
            return theme.colors.collectionBooking;
        case BookingType.Internal:
            return theme.colors.internalBooking;
        case BookingType.Resource:
            return theme.colors.resourceBooking;
        case BookingType.DeliveryAndCollection:
            return theme.colors.deliveryAndCollectionBooking;
        case BookingType.NoBookings:
            return theme.colors.noBookings;
    }
};

const getDefaultBookingTrailerType = (
    bookingType: BookingType
): TrailerType | null => {
    switch (bookingType) {
        case BookingType.NoBookings:
        case BookingType.Internal:
        case BookingType.Resource:
            return 'Internal';
        default:
            return null;
    }
};

const bookingTypeHasAssociatedLoad = (bookingType: BookingType) =>
    bookingType === BookingType.Collection ||
    bookingType === BookingType.Internal ||
    bookingType === BookingType.DeliveryAndCollection;

const uploadBookingAttachments = async (
    bookingId: string,
    files: File[],
    notifyApi?: boolean
) => {
    const fileUploadTokens: {
        blobPath: string;
        token: string;
    }[] = await apiGet(`Booking/${bookingId}/Attachments/Tokens/Upload`, {
        fileNames: files.map((file) => encodeURIComponent(file.name)),
    });

    const filesToUpload: {
        file: File;
        token: string;
    }[] = fileUploadTokens.map((fileToken) => ({
        file: files.find(
            (file) => `${bookingId}/${file.name}` === fileToken.blobPath
        )!,
        token: fileToken.token,
    }));

    const uploadSuccess = await uploadFilesToBlobStorage(
        'booking-attachments',
        filesToUpload,
        bookingId
    );

    if (!notifyApi) return uploadSuccess;

    if (!uploadSuccess) return false;

    const attachments: BookingAttachment[] = files.map((file) => ({
        fileName: file.name,
        type: file.type as FileType,
        status: UploadStatus.Complete,
    }));

    try {
        await apiPut(`Booking/${bookingId}/Attachments/Add`, attachments);
    } catch (error) {
        console.warn(error);
        return false;
    }

    return true;
};

const downloadBookingAttachment = async (attachment: BookingAttachment) => {
    const downloadToken: {
        blobPath: string;
        token: string;
    } = await apiGet(`Booking/Attachments/${attachment.id}/Tokens/Download`);

    const fileToDownload: FileToDownload = {
        name: downloadToken.blobPath,
        token: downloadToken.token,
    };

    const downloadedFile = await downloadFileFromBlobStorage(
        'booking-attachments',
        fileToDownload
    );
    return downloadedFile;
};

const deleteBookingAttachment = async (attachment: BookingAttachment) => {
    try {
        await apiDelete(
            `Booking/${attachment.bookingId}/Attachments/${attachment.id}`
        );
    } catch (error) {
        console.warn(error);
        return false;
    }
    return true;
};

const deleteAllBookingAttachments = async (booking: Booking) => {
    if (booking.attachments.length > 0) {
        try {
            await apiDelete(`Booking/${booking.id}/Attachments`);
        } catch (error) {
            console.warn(error);
            return false;
        }
    }
    return true;
};

const isNotificationRelevant = (
    selectedStoreOption: StoreSelectorOption,
    notificationStore: StoreNumber
) => selectedStoreOption === 'All' || notificationStore === selectedStoreOption;

const sortBookingsByStartDate = (a: Booking, b: Booking) =>
    new Date(a.startDate).getTime() - new Date(b.startDate).getTime();

const isLoadingResourceApplicableForBookingType = (
    loadingResource: LoadingResource,
    bookingType: BookingType
) => {
    switch (bookingType) {
        case BookingType.Collection:
            return loadingResource !== 'Delivery';
        case BookingType.Delivery:
            return loadingResource !== 'Collection';
        case BookingType.Resource:
        case BookingType.Internal:
            return (
                loadingResource !== 'Delivery' &&
                loadingResource !== 'Collection'
            );
        case BookingType.DeliveryAndCollection:
            return true;
    }
};

const GetCustomerName = (
    customerNames: string[] | null,
    orderWithEarliestDeliveryDate: ColdStoreOrder
) => {
    const updatedCustomerName = customerNames?.find((customerName) =>
        customerName.startsWith(
            `${orderWithEarliestDeliveryDate?.customerCode} `
        )
    );

    return updatedCustomerName ? updatedCustomerName : null;
};

const IsCustomerNameValid = (
    bookingCustomerName: string | null,
    bookingType: BookingType,
    hasOrders?: boolean
) => {
    return (
        bookingType === BookingType.Internal ||
        bookingType === BookingType.NoBookings ||
        bookingType === BookingType.Resource ||
        (bookingType === BookingType.Collection && hasOrders
            ? !!bookingCustomerName
            : true)
    );
};

const acceptedAttachmentFileTypes = [
    FileType.pdf,
    FileType.doc,
    FileType.docx,
    FileType.xls,
    FileType.xlsx,
     '.msg',
     '.eml',
     '.rtf',
];

export {
    getBookingsEndDateFromDateRange,
    getBookingWithExistingCustomerReference,
    getFormattedBookingHeader,
    getRecurrenceString,
    getBookingAction,
    getDefaultBookingTrailerType,
    getBookingStatusLabel,
    getBookingTypeColour,
    bookingTypeHasAssociatedLoad,
    uploadBookingAttachments,
    downloadBookingAttachment,
    deleteBookingAttachment,
    deleteAllBookingAttachments,
    isNotificationRelevant,
    isLoadingResourceApplicableForBookingType,
    sortBookingsByStartDate,
    GetCustomerName,
    IsCustomerNameValid,
    acceptedAttachmentFileTypes,
    BOOKING_TABLE_PAGE_SIZE,
};
