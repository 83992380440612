import { msalInstance } from './msal';
import { useContext, useEffect, useState, useCallback } from 'react';
import { UserRole } from '../helpers/userRole';
import { API_SCOPE, IDP, API_URI } from '../environment-variables';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import usePersistedState from '../hooks/usePersistedState';
import React from 'react';
import { constructHeaders } from './api';
import { userIsAdmin } from './userService';

export interface UserProfile {
    ipaddr: string;
    name: string;
    roles: UserRole[];
    idp: string | null | undefined;
    username: string;
}

interface AuthContextData {
    authState: AuthState;

    login(): void;
    logout(): void;
}

export interface AuthState {
    initialized: boolean;
    loggedIn: boolean;
    user: UserProfile | null;
}

const initialState = { initialized: false, loggedIn: false, user: null };

const loggedOutState = {
    initialized: true,
    loggedIn: false,
    user: null,
};

const shouldAddAdminRole = (user: StringDict) => {
    if (user.idp && IDP && IDP.length) {
        return user.idp === IDP;
    }
};

const getLoggedInState = () => {
    const user = msalInstance.getAccount();
    const profile = user.idToken as unknown as UserProfile;
    if (
        user &&
        profile &&
        (!profile.roles || !userIsAdmin(profile)) &&
        shouldAddAdminRole(user.idToken)
    ) {
        profile.roles = [...(profile.roles || []), UserRole.Admin];
    }
    return {
        initialized: true,
        loggedIn: true,
        user: profile,
    };
};

const tryLogin = async () => {
    const requestObj = {
        scopes: ['user.read'],
    };

    await msalInstance.loginPopup(requestObj);
};

const acquireApiToken = async () => {
    const requestObj = {
        scopes: [API_SCOPE],
    };
    try {
        const response = await msalInstance.acquireTokenSilent(requestObj);
        return response.accessToken;
    } catch (error) {
        console.warn(error);
        try {
            const response = await msalInstance.acquireTokenPopup(requestObj);
            return response.accessToken;
        } catch (error) {
            console.warn(error);
            try {
                await tryLogin();
                const response = await msalInstance.acquireTokenSilent(
                    requestObj
                );
                return response.accessToken;
            } catch (error) {
                console.error(error);
            }
        }
    }
};

export const AuthContext = React.createContext<AuthContextData>({
    authState: initialState,
    login: () => {
        /*DO NOTHING */
    },
    logout: () => {
        /*DO NOTHING */
    },
});

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [authState, setAuthState] = usePersistedState<AuthState>(
        initialState,
        'login-state'
    );
    const [loading, setLoading] = useState(true);

    const login = async () => {
        await tryLogin();
        setAuthState(getLoggedInState());
    };

    const logout = useCallback(() => {
        setAuthState(loggedOutState);
        localStorage.removeItem('work-history-filter');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loading && authState.initialized) {
            if (authState.loggedIn) {
                const testToken = async () => {
                    try {
                        const headers = await constructHeaders();
                        const response = await fetch(API_URI + 'account/ping', {
                            headers,
                        });
                        if (!response.ok) {
                            logout();
                        }
                    } catch {
                        logout();
                    }
                    setLoading(false);
                };

                testToken();
            } else {
                setLoading(false);
            }
        }
    }, [authState, loading, logout]);

    return (
        <AuthContext.Provider value={{ authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

const useCurrentUser = () => {
    const {
        authState: { user },
    } = useAuth();
    return user;
};

const useMappedPermissions = <InjectedProps extends object>(
    permissionsMapper: (authState: AuthState) => InjectedProps
) => {
    const { authState } = useAuth();
    return permissionsMapper(authState);
};

export {
    acquireApiToken,
    useAuth,
    useCurrentUser,
    AuthProvider,
    useMappedPermissions,
};
