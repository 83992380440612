import * as React from 'react';
import RecurringBookingEndDateSelector from '../../../components/bookings/recurring/RecurringBookingEndDateSelector';

import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';

const RecurringBookingEndDateContainer: React.FC<FormStepProps<
    RecurringBookingToAdd
>> = ({ formObject: booking, onChange: onBookingChange }) => {
    const handleBookingEndDateChange = (selectedDate: Date | null) => {
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            endDate: selectedDate
        };
        onBookingChange(editedBooking);
    };

    return (
        <>
            {!!booking.duration && !!booking.bookingType && (
                <RecurringBookingEndDateSelector
                    startDate={booking.startDate}
                    endDate={booking.endDate}
                    onEndDateChange={handleBookingEndDateChange}
                />
            )}
        </>
    );
};

export default RecurringBookingEndDateContainer;
