import { buildClassesHook } from '../../../../../../helpers/styles';
import { Theme } from '../../../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    orderRow: {
        backgroundColor: theme.colors.nearlyWhite,
        '& td': {
            padding: '0.25rem !important', //TODO: Fix Table component styling
        },
    },
    orderNumber: {
        fontWeight: 'bold',
    },
    borderedCell: {
        borderLeft: `2px solid ${theme.colors.lightGrey} !important` /*TODO: Fix Table component styling*/,
        padding: '0 !important', //TODO: Fix Table component styling
    },
}));
