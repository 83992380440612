import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteKey, routeFor } from '../../Routes/routes';
import StockTransferFormContainer from '../../containers/load/createStockTransfer/StockTransferFormContainer';
import Page from '../common/Page';

const CreateStockTransferForm: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Page>
            <StockTransferFormContainer
                onStockTransferCreated={(loadId: string) =>
                    navigate(routeFor(RouteKey.LoadSummary)({ id: loadId }))
                }
            />
        </Page>
    );
};

export default CreateStockTransferForm;
