import classnames from 'classnames';
import * as React from 'react';

import { TableBodyWithLoadingPlaceholder, TableHead } from '.';
import { useClasses } from './Table.styles';

export enum TextAlign {
    Left = 'left',
    Right = 'right',
    Center = 'center',
}

export interface HeaderDetails {
    title: string;
    displayFrom?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    hide?: boolean;
    align?: TextAlign;
    noPadding?: boolean;
    tooltip?: string;
    top?: string | number;
    width?: string | number;
    zIndex?:
        | number
        | '-moz-initial'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset'
        | 'auto';
}

export interface TableProps {
    bordered?: boolean;
    borderless?: boolean;
    bodyClassName?: string;
    striped?: boolean;
    hoverable?: boolean;
    small?: boolean;
    medium?: boolean;
    responsive?: boolean;
    fixedHeader?: boolean;
    columns?: number;
    className?: string;
    loading?: boolean;
    placeholderRows?: number;
    headers?: (string | HeaderDetails)[];
    noRowBorders?: boolean;
    [otherProps: string]: any;
}

const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
    bordered = false,
    borderless = false,
    bodyClassName,
    striped = false,
    hoverable = false,
    fixedHeader = false,
    small = false,
    medium = false,
    responsive = false,
    columns,
    children,
    className = null,
    loading,
    placeholderRows = 0,
    headers,
    noRowBorders = false,
    ...otherProps
}) => {
    const classes = useClasses();

    const newClassName = classnames(className, classes.table, 'table', {
        'table-bordered': bordered,
        'table-borderless': borderless,
        'table-striped': striped,
        'table-hover': hoverable,
        'table-sm': small,
        [classes.striped]: striped,
        [classes.mediumTable]: medium,
        [classes.fixedHeader]: fixedHeader,
        [classes.noRowBorders]: noRowBorders || loading,
        [classes.rowBorders]: !(noRowBorders || loading),
    });

    const table = (
        <table className={newClassName} {...otherProps}>
            <TableHead headers={headers} />
            <TableBodyWithLoadingPlaceholder
                className={bodyClassName}
                colSpan={columns || (headers && headers.length) || 1}
                loading={loading}
                placeholderRows={placeholderRows}
            >
                {children}
            </TableBodyWithLoadingPlaceholder>
        </table>
    );

    if (responsive) {
        return <div className="table-responsive">{table}</div>;
    }

    return table;
};

export default Table;
