import * as React from 'react';
import { Order } from '../../models/orders/Order';
import { useClasses } from './OrderPalletTypeCounts.styles';

interface OrderPalletTypeCountsProps {
    ordersOnLoad: Order[];
    selectedOrders: Order[];
    removeOrders?: boolean;
}

const getPalletTypeCount = (
    orders: Order[],
    predicate: (order: Order) => boolean
): number =>
    orders.filter(predicate).reduce((rv, curr) => rv + curr.palletSpaces, 0);

const getUkPalletCount = (orders: Order[]): number =>
    getPalletTypeCount(orders, (o) => o.hasUkPallets);
const getEuroPalletCount = (orders: Order[]): number =>
    getPalletTypeCount(orders, (o) => o.hasEuroPallets);
const getAsdaCageCount = (orders: Order[]): number =>
    getPalletTypeCount(orders, (o) => o.hasAsdaCages);

const OrderPalletTypeCounts = ({
    ordersOnLoad,
    selectedOrders,
    removeOrders = false,
}: OrderPalletTypeCountsProps) => {
    const classes = useClasses();

    const getPalletsTotal = (countOnLoad: number, countSelected: number) =>
        removeOrders
            ? countOnLoad - countSelected
            : countOnLoad + countSelected;

    const ukPalletsOnLoad = getUkPalletCount(ordersOnLoad);
    const ukPalletsSelected = getUkPalletCount(selectedOrders);
    const ukPalletsTotal = getPalletsTotal(ukPalletsOnLoad, ukPalletsSelected);

    const euroPalletsOnLoad = getEuroPalletCount(ordersOnLoad);
    const euroPalletsSelected = getEuroPalletCount(selectedOrders);
    const euroPalletsTotal = getPalletsTotal(
        euroPalletsOnLoad,
        euroPalletsSelected
    );

    const asdaCagesOnLoad = getAsdaCageCount(ordersOnLoad);
    const asdaCagesSelected = getAsdaCageCount(selectedOrders);
    const asdaCagesTotal = getPalletsTotal(asdaCagesOnLoad, asdaCagesSelected);

    return (
        <div className={classes.container}>
            <h6 className={classes.header}>Total Pallets</h6>
            <div>
                {!!ukPalletsTotal && <p>{ukPalletsTotal} UK</p>}
                {!!euroPalletsTotal && <p>{euroPalletsTotal} Euro</p>}
                {!!asdaCagesTotal && <p>{asdaCagesTotal} Asda Cage</p>}
            </div>
        </div>
    );
};

export default OrderPalletTypeCounts;
