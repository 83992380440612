import * as React from 'react';
import { buildClassesHook } from '../../helpers/styles';
import { UserRole } from '../../helpers/userRole';
import { BookingOptionType } from '../../models/bookings/BookingOptionType';
import { BookingStatus } from '../../models/bookings/BookingStatus';
import { BookingType } from '../../models/bookings/BookingType';
import { StoreNumber } from '../../models/stores/StoreNumber';
import { useCurrentUser } from '../../services/authentication';
import { getAvailableOptions } from '../../services/bookingOptionsService';
import {
    userHasAccessToStore,
    userInRole,
    userIsAdmin,
} from '../../services/userService';
import { Theme } from '../../theme';
import IconMenuLink from '../common/IconMenuLink';

const useClasses = buildClassesHook((theme: Theme) => ({
    dangerButton: {
        color: theme.colors.error,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.errorDark,
        },
    },
    iconButton: {
        color: theme.colors.primary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.colors.primaryDark,
        },
    },
    icon: {
        marginRight: '10px',
        width: '1.5em !important',
    },
}));

interface BookingOptionsProps {
    bookingType: BookingType;
    bookingStatus: BookingStatus;
    bookingContactNumber?: string | null;
    store: StoreNumber;
    hideUndoCheckIn?: boolean;
    onOptionRequested(type: BookingOptionType): void;
}

const BookingOptions: React.FC<BookingOptionsProps> = ({
    bookingType,
    bookingStatus,
    bookingContactNumber,
    store,
    hideUndoCheckIn = false,
    onOptionRequested,
}) => {
    const user = useCurrentUser();

    const userCanModifyBooking = userHasAccessToStore(user, store);
    const isSuperUser =
        userIsAdmin(user) || userInRole(user)(UserRole.ShiftManager);

    const classes = useClasses();

    const validOptions = getAvailableOptions(
        bookingStatus,
        bookingType,
        isSuperUser
    );

    const showEmailOption =
        userCanModifyBooking && validOptions.includes('email');

    const showUndoOption =
        (userCanModifyBooking || userInRole(user)(UserRole.Gatehouse)) &&
        validOptions.includes('undoCheckIn') &&
        !hideUndoCheckIn;

    const sendSmsOption =
        userCanModifyBooking &&
        validOptions.includes('sendSms') &&
        bookingContactNumber;

    const canUpdateResources =
        userCanModifyBooking && bookingType !== BookingType.Resource;

    return (
        <>
            {showEmailOption && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="Email"
                    icon="envelope"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('email')}
                />
            )}
            {userCanModifyBooking &&
                validOptions.includes('editContactNumber') && (
                    <IconMenuLink
                        buttonClassName={classes.iconButton}
                        label="Edit Contact Number"
                        icon="phone-edit"
                        iconClassName={classes.icon}
                        onClick={() => onOptionRequested('editContactNumber')}
                    />
                )}
            {sendSmsOption && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="SMS Message"
                    icon="sms-message"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('sendSms')}
                />
            )}
            {userCanModifyBooking && validOptions.includes('updateOrders') && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="Update Orders"
                    icon="boxes"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('updateOrders')}
                />
            )}
            {userCanModifyBooking && validOptions.includes('edit') && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="Edit"
                    icon="edit"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('edit')}
                />
            )}

            {userCanModifyBooking && validOptions.includes('reschedule') && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="Reschedule"
                    icon="calendar-day"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('reschedule')}
                />
            )}

            {canUpdateResources && validOptions.includes('updateResources') && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="Update Resources"
                    icon="sync"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('updateResources')}
                />
            )}

            {userCanModifyBooking &&
                validOptions.includes('convertToDelivery') && (
                    <IconMenuLink
                        buttonClassName={classes.iconButton}
                        label="Convert to Delivery Only"
                        icon="exchange"
                        iconClassName={classes.icon}
                        onClick={() => onOptionRequested('convertToDelivery')}
                    />
                )}

            {userCanModifyBooking &&
                validOptions.includes('manageAttachments') && (
                    <IconMenuLink
                        buttonClassName={classes.iconButton}
                        label="Attachments"
                        icon="paper-clip"
                        iconClassName={classes.icon}
                        onClick={() => onOptionRequested('manageAttachments')}
                    />
                )}
            {validOptions.includes('viewHistory') && (
                <IconMenuLink
                    buttonClassName={classes.iconButton}
                    label="View History"
                    icon="history"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('viewHistory')}
                />
            )}

            {showUndoOption && (
                <IconMenuLink
                    buttonClassName={classes.dangerButton}
                    label="Undo Check-in"
                    icon="undo"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('undoCheckIn')}
                />
            )}

            {userCanModifyBooking && validOptions.includes('cancel') && (
                <IconMenuLink
                    buttonClassName={classes.dangerButton}
                    label="Cancel"
                    icon="cross"
                    iconClassName={classes.icon}
                    onClick={() => onOptionRequested('cancel')}
                />
            )}
        </>
    );
};

export default BookingOptions;
