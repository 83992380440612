import * as React from 'react';
import { useState } from 'react';
import EditSMSTemplateModal from '../../../components/admin/smsTemplates/update/EditSMSTemplateModal';
import ErrorModal from '../../../components/common/ErrorModal';
import LoadingModal from '../../../components/common/LoadingModal';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { apiPut } from '../../../services/api';

interface EditSMSTemplateModalContainerProps {
    showModal: boolean;
    smsTemplate: SMSTemplate;
    onClose(): void;
}

const EditSMSTemplateModalContainer: React.FC<EditSMSTemplateModalContainerProps> = ({
    showModal,
    smsTemplate,
    onClose
}) => {
    const [updatingValues, setUpdatingValues] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const handleEditRequested = async (updatedValue: SMSTemplate) => {
        setUpdatingValues(true);
        try {
            await apiPut(`SMSTemplate/${updatedValue.id}`, updatedValue);
            setUpdatingValues(false);
            onClose();
        } catch (e) {
            setUpdatingValues(false);
            setErrorMessage(
                'An error has occurred when trying to update the SMS Template. If this continues, please contact IT.'
            );
        }
    };

    const showLoadingModal = showModal && updatingValues;
    const showEditModal = showModal && !updatingValues && !errorMessage;
    const showErrorModal = showModal && !!errorMessage;

    return (
        <>
            <LoadingModal
                showModal={showLoadingModal}
                loadingText="updating..."
            />
            <EditSMSTemplateModal
                showModal={showEditModal}
                smsTemplate={smsTemplate}
                onEditRequested={handleEditRequested}
                onClose={onClose}
            />
            <ErrorModal
                showModal={showErrorModal}
                header="Error"
                errorText={errorMessage}
                onClose={onClose}
            />
        </>
    );
};

export default EditSMSTemplateModalContainer;
