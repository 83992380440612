import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    contentAlignEndColumn: {
        textAlign: 'end',
    },
    valueTypeSelector: {
        maxWidth: 160,
    },
    filter: {
        margin: 'auto',
        maxWidth: 200,
        padding: 0,
        '& input': {
            padding: '0.375rem 0.75rem',
            height: 'calc(1.5em + 0.75rem + 5px)',
        },
    },
    formContainer: {
        padding: '2em 2em',
        borderRadius: '1em',
        maxWidth: 800,
    },
    selectorRow: {
        position: 'sticky',
        top: '4.5rem',
        backgroundColor: theme.colors.white,
        zIndex: 2,
    },
}));
