import * as React from 'react';
import AccessControlSettings from '../../../components/admin/accessControl/AccessControlSettings';
import Nav from '../../../lib/bootstrap-ui/Nav';
import { routeFor, RouteKey } from '../../../Routes/routes';
import { useCurrentUser } from '../../../services/authentication';
import { userIsTester } from '../../../services/userService';
import BayManagementContainer from '../bayManagement/BayManagementContainer';
import LoadingMilestoneConfigContainer from '../loadingProgress/LoadingMilestoneConfigContainer';
import SiteResourceConfigContainer from '../siteResources/SiteResourceConfigContainer';
import SMSTemplateManagementContainer from '../smsTemplates/SMSTemplateManagementContainer';
import StoreBookingConfigContainer from '../storeResources/StoreBookingConfigContainer';
import ThirdPartyManagementContainer from '../thirdPartyManagement/ThirdPartyManagementContainer';
import { useClasses } from './AdminContainer.styles';

export enum AdminPage {
    BayManagement = 'Bay Management',
    LoadingProgress = 'Loading Progress',
    StoreResources = 'Store Resources',
    SiteResources = 'Site Resources',
    ThirdPartyManagement = 'Third Party Management',
    SMSTemplateManagement = 'SMS Template Management',
    AccessControl = 'Access Controls',
}

interface AdminContainerProps {
    page: AdminPage;
}

const pages = {
    [AdminPage.BayManagement]: <BayManagementContainer />,
    [AdminPage.LoadingProgress]: <LoadingMilestoneConfigContainer />,
    [AdminPage.StoreResources]: <StoreBookingConfigContainer />,
    [AdminPage.SiteResources]: <SiteResourceConfigContainer />,
    [AdminPage.ThirdPartyManagement]: <ThirdPartyManagementContainer />,
    [AdminPage.SMSTemplateManagement]: <SMSTemplateManagementContainer />,
    [AdminPage.AccessControl]: <AccessControlSettings />,
};

const AdminContainer: React.FC<AdminContainerProps> = ({ page }) => {
    const user = useCurrentUser();
    const classes = useClasses();

    return (
        <>
            <div className={classes.actionContainer}>
                <Nav
                    className={classes.adminPageOptionsContainer}
                    links={[
                        {
                            label: AdminPage.BayManagement,
                            url: routeFor(RouteKey.BayManagement)(),
                        },
                        {
                            label: AdminPage.LoadingProgress,
                            url: routeFor(RouteKey.LoadingProgressConfig)(),
                        },
                        {
                            label: AdminPage.SiteResources,
                            url: routeFor(RouteKey.SiteResourceConfig)(),
                        },
                        {
                            label: AdminPage.SMSTemplateManagement,
                            url: routeFor(RouteKey.SMSTemplateManagement)(),
                        },
                        {
                            label: AdminPage.StoreResources,
                            url: routeFor(RouteKey.StoreResourceConfig)(),
                        },
                        {
                            label: AdminPage.ThirdPartyManagement,
                            url: routeFor(RouteKey.ThirdPartyManagement)(),
                        },
                        {
                            label: AdminPage.AccessControl,
                            url: routeFor(RouteKey.AccessControl)(),
                        },
                    ].filter((link) =>
                        userIsTester(user)
                            ? link.label
                            : link.label !== AdminPage.AccessControl
                    )}
                    active={page}
                    pills
                />
            </div>
            {pages[page]}
        </>
    );
};

export default AdminContainer;
