import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

interface NavLinkProps extends LinkProps {
    active?: boolean;
    disabled?: boolean;
    className?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
    children,
    className,
    active = false,
    disabled = false,
    ...rest
}) => (
    <Link
        className={classnames('nav-link', className, {
            active,
            disabled
        })}
        {...rest}
    >
        {children}
    </Link>
);

export default NavLink;
