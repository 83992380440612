import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    loadingContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    loadingModal: {
        width: '80%',
        maxWidth: '350px',
        minWidth: 'fit-content',
    },
    updateOrdersModal: {
        width: '80%',
        maxWidth: '550px',
        minWidth: '450px',
    },
    updateOrdersBody: {
        minHeight: '300px',
        padding: '1rem 0',
    },
    errorMessage: {
        color: theme.colors.error,
        marginBottom: 0,
        marginTop: '1rem',
    },
}));
