import * as React from 'react';
import { useState } from 'react';
import { StoreSelectorOption } from '../../../components/common/StoreSelectorPills';
import BookingTableFilterContainer from '../../../containers/bookings/BookingTableFilterContainer';
import { arraysContainSameElements } from '../../../helpers/arrayFunctions';
import { buildClassesHook } from '../../../helpers/styles';
import Button from '../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../lib/bootstrap-ui/Modal';
import BookingFilter from '../../../models/bookings/BookingFilter';

const useClasses = buildClassesHook({
    filterModal: {
        width: '465px',
    },
});

interface BookingTableFilterModalProps {
    showModal: boolean;
    selectedStoreOption: StoreSelectorOption;
    showStoreSelector: boolean;
    filter: BookingFilter;
    onStoreOptionChange(storeOption: StoreSelectorOption): void;
    onApplyFilter(filter: BookingFilter): void;
    onClose(): void;
}

const BookingTableFilterModal: React.FC<BookingTableFilterModalProps> = ({
    showModal,
    selectedStoreOption,
    showStoreSelector,
    filter,
    onStoreOptionChange,
    onApplyFilter,
    onClose,
}) => {
    const classes = useClasses();

    const [bookingFilter, setBookingFilter] = useState(filter);

    const bookingTypesUpdated = !arraysContainSameElements(
        bookingFilter.bookingTypes,
        filter.bookingTypes
    );
    const resourcesUpdated = !arraysContainSameElements(
        bookingFilter.resources,
        filter.resources
    );

    const filterUpdated =
        bookingTypesUpdated ||
        bookingFilter.customerName !== filter.customerName ||
        bookingFilter.startDate?.valueOf() !== filter.startDate?.valueOf() ||
        bookingFilter.orderNumber !== filter.orderNumber ||
        bookingFilter.productOrSupplierCode !== filter.productOrSupplierCode ||
        bookingFilter.reference !== filter.reference ||
        resourcesUpdated;

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={onClose}
            modalClassName={classes.filterModal}
        >
            <ModalHeader showCloseButton onCloseButtonClick={onClose}>
                <h5>Filter</h5>
            </ModalHeader>
            <ModalBody>
                <BookingTableFilterContainer
                    showStoreSelector={showStoreSelector}
                    onStoreOptionChange={onStoreOptionChange}
                    selectedStoreOption={selectedStoreOption}
                    bookingFilter={bookingFilter}
                    onFilterChange={setBookingFilter}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose} styleType="secondary">
                    Close
                </Button>
                <Button
                    onClick={() => onApplyFilter(bookingFilter)}
                    styleType="primary"
                    disabled={!filterUpdated}
                >
                    Apply
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default BookingTableFilterModal;
