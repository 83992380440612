import * as React from 'react';
import classnames from 'classnames';

const CardBody: React.FC<React.HTMLAttributes<HTMLElement>> = ({
    children,
    className,
    ...otherProps
}) => (
    <div className={classnames('card-body', className)} {...otherProps}>
        {children}
    </div>
);

export default CardBody;
