import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmCancelButtons from '../../components/common/ConfirmCancelButtons';
import InformationMessage from '../../components/common/InformationMessage';
import SingleColumnRow from '../../components/common/SingleColumnRow';
import UpdateNotification from '../../components/common/UpdateNotification';
import BayStatusData from '../../components/releasing/BayStatusTable';
import ReleaseData from '../../components/releasing/ReleaseDataComponent';
import { GENERATE_LOAD_PLANS } from '../../environment-variables';
import { compareDatesAsc, formatDate } from '../../helpers/dates';
import { useNotificationListener } from '../../hooks/useNotificationListener';
import Alert from '../../lib/bootstrap-ui/Alert';
import Grid, { Column, Row } from '../../lib/bootstrap-ui/Grid';
import Bay from '../../models/bays/Bay';
import { BayStatus } from '../../models/bays/BayStatus';
import { Load } from '../../models/loads/Load';
import { LoadStatus } from '../../models/loads/LoadStatus';
import { LoadType } from '../../models/loads/LoadType';
import { ReleaseApplication } from '../../models/loads/ReleaseApplication';
import {
    BayStatusUpdatedNotification,
    BaysUpdatedNotification,
} from '../../models/notifications/BayStatus';
import {
    LoadReleaseErrorNotification,
    LoadReleasedNotification,
    LoadUpdatedNotification,
} from '../../models/notifications/Load';
import { TrailerUpdatedNotification } from '../../models/notifications/Trailer';
import { Trailer } from '../../models/trailers/Trailer';
import { apiPatch, apiPost, useData } from '../../services/api';
import {
    getLoadExport,
    getPickList,
    isReedBoardallLoad,
    isValidReedBoardallTrailerSelected,
} from '../../services/loadService';
import { useClasses } from './ReleaseLoadContainer.styles';
import ReleasePropertyPickersContainer from './ReleasePropertyPickersContainer';
import LoadingModal from '../../components/common/LoadingModal';
import { DownloadExportType } from '../../components/common/DownloadExportType';

interface LoadToRelease {
    trailerId: string | null;
    bayId: number | null;
}

interface ReleaseLoadContainerProps {
    loadId: string;
    goBackHandler: () => void;
    onReleaseComplete: (storeId: number) => void;
}

const createLoadToRelease = (load: Load) => {
    const loadToRelease: LoadToRelease = {
        bayId: load.bayId,
        trailerId: load.trailerId,
    };
    return loadToRelease;
};

const getErrorMessage = (errorOrigin: ReleaseApplication): string => {
    let errorMessage = '';
    switch (errorOrigin) {
        case 'Dock System':
            errorMessage =
                'Something went wrong generating the release export.';
            break;
        case 'Export Downloader':
            errorMessage =
                'Something went wrong downloading the release export.';
            break;
        case 'Chess':
            errorMessage = 'No response received from Chess.';
            break;
        case 'Load XML Processor':
            errorMessage =
                'Something went wrong processing the export from Chess.';
            break;
        default:
            errorMessage = 'Something went wrong releasing the load.';
            break;
    }
    return (
        errorMessage +
        ' Please try again. If the issue persists please contact IT.'
    );
};

const ReleaseLoadContainer: React.FC<ReleaseLoadContainerProps> = ({
    loadId,
    goBackHandler,
}) => {
    const classes = useClasses();

    const [releasing, setReleasing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [loadReleaseSuccessful, setLoadReleaseSuccessful] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [load, setLoad] = useState<Load | null>(null);
    const [bayStatuses, setBayStatuses] = useState<BayStatus[] | null>([]);
    const [selectedBay, setSelectedBay] = useState<Bay | null>(null);
    const [selectedTrailer, setSelectedTrailer] = useState<Trailer | null>(
        null
    );

    const [fetchedLoad, loading, fetchLoad] = useData<Load>(`Load/${loadId}`);
    const [fetchedBayStatuses] = useData<BayStatus[]>(
        load &&
            load.loadType !== LoadType.Tunnel &&
            load.loadType !== LoadType.Internal
            ? `Store/${load.sourceStore}/BayStatuses`
            : null
    );
    const [fetchedTrailer] = useData<Trailer>(
        fetchedLoad?.trailerId ? `Trailer/${fetchedLoad?.trailerId}` : null
    );

    const [releaseStrategyValid, setReleaseStrategyValid] = useState(false);

    const loadExportType = load?.isStockTrailer
        ? 'Transfer Sheet'
        : 'Load Plan';
    const [downloading, setDownloading] =
        React.useState<DownloadExportType | null>(null);
    const isDownloading = !!downloading;

    useEffect(() => {
        setLoad(fetchedLoad);
    }, [fetchedLoad]);

    useEffect(() => {
        setBayStatuses(fetchedBayStatuses);

        if (load?.trailerId) {
            const bayForTrailer = fetchedBayStatuses?.find(
                (b) => b.trailerId === load.trailerId
            );

            if (bayForTrailer) {
                setLoad({
                    ...load,
                    bayId: bayForTrailer.id,
                    bayOrPagerNumber: bayForTrailer.id,
                });
            }
        }
        // eslint-disable-next-line
    }, [fetchedBayStatuses]);

    useEffect(() => {
        setSelectedTrailer(fetchedTrailer);
    }, [fetchedTrailer]);

    const downloadLoadPlanAndPickList = async () => {
        if (!load || !GENERATE_LOAD_PLANS) {
            return;
        }
        setDownloading(loadExportType as DownloadExportType);
        try {
            await getLoadExport(load);
            setDownloading(null);
            setError(null);
        } catch (error) {
            setDownloading(null);
            setError(
                'Something went wrong generating the load plan. Please try again, or contact your system administrator.'
            );
        }
    };

    const downloadPickList = async () => {
        if (!load || !GENERATE_LOAD_PLANS) {
            return;
        }

        const pickListUpdateId: string = load.orderLoadingStatuses
            .filter((ols) => ols.releaseToChess)
            .map((ols) => ols.order.pickLists)
            .reduce((a, b) => a.concat(b), [])
            .sort((lhs, rhs) =>
                compareDatesAsc(lhs.generatedTime, rhs.generatedTime)
            )[0].pickListUpdateId;

        setDownloading(DownloadExportType.PickList);
        try {
            await getPickList(load, pickListUpdateId);
            setDownloading(null);
            setError(null);
        } catch (error) {
            setDownloading(null);
            setError(
                'Something went wrong generating the pick list amendment. Please try again, or contact your system administrator.'
            );
        }
    };

    useNotificationListener(LoadReleasedNotification, (loadReleasedEvent) => {
        if (!load || load.id !== loadReleasedEvent.releasedLoad.id) {
            return;
        }
        setError(null);
        setReleasing(false);
        load.loadType === LoadType.Internal || !load.isFirstStandardLoad
            ? downloadPickList()
            : downloadLoadPlanAndPickList();
    });

    useNotificationListener(
        LoadReleaseErrorNotification,
        (loadReleaseError) => {
            if (!load || load.id !== loadReleaseError.loadId) {
                return;
            }
            setError(getErrorMessage(loadReleaseError.errorOrigin));
            fetchLoad();
            setReleasing(false);
        }
    );

    useNotificationListener(
        LoadUpdatedNotification,
        (changesWithAffectedLoad) => {
            if (!load) return;

            if (changesWithAffectedLoad.affectedLoad.id === load.id) {
                if (changesWithAffectedLoad.changes.length > 0) {
                    toast.info(
                        <UpdateNotification
                            heading={`Load ${load.loadName} Updated`}
                        >
                            {changesWithAffectedLoad.changes.map((c) => (
                                <div key={c.id}>{c.message}</div>
                            ))}
                        </UpdateNotification>
                    );
                }
                setLoad({
                    ...changesWithAffectedLoad.affectedLoad,
                    bayId:
                        changesWithAffectedLoad.affectedLoad.bayId ||
                        load!.bayId,
                    trailerId:
                        changesWithAffectedLoad.affectedLoad.trailerId ||
                        load!.trailerId,
                });
            }
        }
    );

    useNotificationListener(BaysUpdatedNotification, (changedBayStatuses) => {
        if (!bayStatuses) return;

        const selectedBayStatus = changedBayStatuses.find(
            (b) => b.id === selectedBay?.id
        );
        if (selectedBayStatus) {
            setSelectedBay((prev) => ({
                ...prev!,
                isOutOfOrder: !!selectedBayStatus.isOutOfOrder,
            }));
        }

        let newStatuses: BayStatus[] = bayStatuses;
        changedBayStatuses.forEach(
            (b) =>
                (newStatuses = newStatuses.map((s) => (s.id !== b.id ? s : b)))
        );
        setBayStatuses(newStatuses);
    });

    useNotificationListener(
        BayStatusUpdatedNotification,
        (changedBayStatus) => {
            if (!bayStatuses) return;

            const currentStatuses = bayStatuses;
            const newStatuses = currentStatuses.map((s) =>
                s.id !== changedBayStatus.id ? s : changedBayStatus
            );
            setBayStatuses(newStatuses);
        }
    );

    useNotificationListener(TrailerUpdatedNotification, (trailer: Trailer) => {
        if (!bayStatuses) return;

        const currentStatuses = bayStatuses;
        const newStatuses = currentStatuses.map((s) =>
            s.trailerId === trailer.id ? { ...s, trailer } : s
        );
        setBayStatuses(newStatuses);
    });

    const handleBayChanged = (bay: Bay | null) => {
        if (!load) return;

        setSelectedBay(bay);
        setLoad({ ...load, bayId: bay?.id || null });
    };

    const handleTrailerChanged = (trailer: Trailer | null) => {
        if (!load) return;

        setSelectedTrailer(trailer);
        setLoad({ ...load, trailerId: trailer?.id || null });
    };

    const releaseLoad = async () => {
        if (!load) return;

        if (load.isReleased && load.isPreDropped) {
            try {
                setUpdating(true);
                await apiPatch(`Load/${load.id}/UpdateBayAndTrailerNumber/`, {
                    bayId: load.bayId,
                    trailerId: load.trailerId,
                });
                goBackHandler();
            } catch (error) {
                setError(
                    'Something went wrong updating the load. Please try again, or contact your system administrator.'
                );
                setUpdating(false);
            }
        } else {
            try {
                setReleasing(true);
                await apiPost(
                    `Load/${load.id}/Release/`,
                    createLoadToRelease(load)
                );
                setLoadReleaseSuccessful(true);
            } catch (error) {
                setError(
                    'Something went wrong releasing the load. Please try again, or contact your system administrator.'
                );
                setReleasing(false);
            }
        }
    };

    const renderReleasingMessage = () => {
        return (
            <InformationMessage messageType="loading">
                This load is currently being released
            </InformationMessage>
        );
    };

    const renderUpdatingMessage = () => {
        return (
            <InformationMessage messageType="loading">
                This load is currently being updated
            </InformationMessage>
        );
    };

    const setSelectedTrailerAsInactive = () => {
        if (selectedTrailer) {
            const updatedSelectedTrailer: Trailer = {
                ...selectedTrailer,
                active: false,
            };
            setSelectedTrailer(updatedSelectedTrailer);
        }
    };

    const isTunnelOrInternal =
        !load ||
        load.loadType === LoadType.Tunnel ||
        load.loadType === LoadType.Internal;

    const releasePropertiesValid = !!(
        !load ||
        (load.bayId &&
            (!isReedBoardallLoad(load) ||
                isValidReedBoardallTrailerSelected(load, selectedTrailer)))
    );

    const releaseValid =
        isTunnelOrInternal || (releasePropertiesValid && releaseStrategyValid);

    const renderPropertyPickers = () => {
        return (
            <>
                {load?.isReleased && load?.isPreDropped && (
                    <InformationMessage messageType="warning">
                        Load pre-dropped, please assign physical bay number.
                    </InformationMessage>
                )}
                <ReleasePropertyPickersContainer
                    validateReleaseStrategy={setReleaseStrategyValid}
                    selectedBay={selectedBay}
                    bayStatuses={bayStatuses}
                    storeId={(load && load.sourceStore) || 1}
                    selectedTrailer={selectedTrailer}
                    fetchedTrailer={fetchedTrailer}
                    trailerType={!loading ? load && load.trailerType : null}
                    loadType={load ? load.loadType : null}
                    bayOrPagerNumber={load?.bayOrPagerNumber}
                    isPreDrop={
                        (load?.status === LoadStatus.AwaitingStock ||
                            load?.status === LoadStatus.AwaitingHaulier) &&
                        !load?.isPreDropped
                    }
                    hasTransportOnlyOrders={
                        load?.orderLoadingStatuses.some(
                            (ols) => ols.isTransportOnly
                        ) ?? false
                    }
                    onBayChange={handleBayChanged}
                    onTrailerChange={handleTrailerChanged}
                    isReedBoardallLoad={!load || isReedBoardallLoad(load)}
                    setSelectedTrailerAsInactive={setSelectedTrailerAsInactive}
                />
                <hr />
            </>
        );
    };

    const renderLoadPlanButton = () => {
        if (!load) return;

        return (
            <>
                <InformationMessage messageType="success">
                    Load released at{' '}
                    {`${formatDate(load.releasedAt!, 'dd/MM/yyyy HH:mm')}`}.
                </InformationMessage>
            </>
        );
    };

    const renderPickersOrMessage = () => {
        if (loading || !load) return null;

        if (releasing || load.awaitingReleaseResponse) {
            return renderReleasingMessage();
        }
        if (updating) {
            return renderUpdatingMessage();
        }
        if (
            (load.status === LoadStatus.AwaitingStock ||
                load.status === LoadStatus.AwaitingHaulier ||
                load.status === LoadStatus.ReadyToRelease ||
                (load.isPreDropped && !loadReleaseSuccessful)) &&
            load.loadType !== LoadType.Tunnel &&
            load.loadType !== LoadType.Internal
        ) {
            return renderPropertyPickers();
        }
        if (load.isReleased) {
            return renderLoadPlanButton();
        }
        if (
            !load.hasBeenReleased &&
            load.status !== LoadStatus.ReadyToRelease
        ) {
            if (load.status === LoadStatus.Invalid) {
                return (
                    <InformationMessage messageType="error">
                        This load is invalid.
                    </InformationMessage>
                );
            }
            return (
                <InformationMessage messageType="error">
                    This load is not ready to release.
                </InformationMessage>
            );
        }

        return null;
    };

    return (
        <Grid className={classes.releaseLoadContainer}>
            <SingleColumnRow>
                {error && <Alert type="danger">{error}</Alert>}
            </SingleColumnRow>
            <Row>
                <Column lg={9}>
                    <div className={classes.container}>
                        {isDownloading && (
                            <LoadingModal
                                showModal
                                loadingText={`Downloading ${downloading}...`}
                            />
                        )}
                        <ReleaseData load={load} loading={loading} />
                        <hr />
                        {renderPickersOrMessage()}
                        {!releasing &&
                            !updating &&
                            !loading &&
                            load &&
                            !load.awaitingReleaseResponse &&
                            (!load.isReleased ||
                                (load.isReleased &&
                                    load.isPreDropped &&
                                    !loadReleaseSuccessful)) && (
                                <ConfirmCancelButtons
                                    disableConfirm={loading || !releaseValid}
                                    onConfirm={releaseLoad}
                                    onCancel={goBackHandler}
                                    confirmText={
                                        !load.isReleased ? 'Release' : 'Update'
                                    }
                                />
                            )}
                    </div>
                </Column>
                <Column style={{ maxWidth: 200 }}>
                    <BayStatusData bayStatuses={bayStatuses} />
                </Column>
            </Row>
        </Grid>
    );
};

export default ReleaseLoadContainer;
