import { BookingStatus } from '../models/bookings/BookingStatus';
import { BookingType } from '../models/bookings/BookingType';
import { BookingOptionType } from '../models/bookings/BookingOptionType';

const getCollectionOptions = (status: BookingStatus): BookingOptionType[] => {
    switch (status) {
        case BookingStatus.AwaitingHaulier:
            return [
                'email',
                'updateOrders',
                'edit',
                'reschedule',
                'updateResources',
                'manageAttachments',
                'viewHistory',
                'cancel',
                'editContactNumber',
                'sendSms'
            ];
        case BookingStatus.RequiresOrders:
            return [
                'updateOrders',
                'viewHistory',
                'undoCheckIn',
                'editContactNumber',
                'sendSms'
            ];
        case BookingStatus.AwaitingLoadCompletion:
            return [
                'viewHistory',
                'undoCheckIn',
                'editContactNumber',
                'sendSms'
            ];
        default:
            return ['viewHistory'];
    }
};

const getDeliveryOptions = (status: BookingStatus): BookingOptionType[] => {
    switch (status) {
        case BookingStatus.AwaitingHaulier:
            return [
                'email',
                'edit',
                'reschedule',
                'updateResources',
                'manageAttachments',
                'viewHistory',
                'cancel',
                'editContactNumber',
                'sendSms'
            ];
        case BookingStatus.AwaitingCompletion:
            return [
                'viewHistory',
                'undoCheckIn',
                'editContactNumber',
                'sendSms'
            ];
        default:
            return ['viewHistory'];
    }
};

const getDeliveryAndCollectionOptions = (
    status: BookingStatus
): BookingOptionType[] => {
    switch (status) {
        case BookingStatus.AwaitingHaulier:
            return [
                'email',
                'updateOrders',
                'edit',
                'reschedule',
                'updateResources',
                'convertToDelivery',
                'manageAttachments',
                'viewHistory',
                'cancel',
                'editContactNumber',
                'sendSms'
            ];
        case BookingStatus.RequiresOrders:
            return [
                'updateOrders',
                'convertToDelivery',
                'viewHistory',
                'undoCheckIn',
                'editContactNumber',
                'sendSms'
            ];
        case BookingStatus.AwaitingLoadCompletion:
            return [
                'viewHistory',
                'undoCheckIn',
                'sendSms',
                'editContactNumber'
            ];
        default:
            return ['viewHistory'];
    }
};

const getResourceOptions = (status: BookingStatus): BookingOptionType[] => {
    switch (status) {
        case BookingStatus.AwaitingCompletion:
            return [
                'edit',
                'reschedule',
                'updateResources',
                'manageAttachments',
                'viewHistory',
                'cancel',
                'editContactNumber',
                'sendSms'
            ];
        default:
            return ['viewHistory'];
    }
};

const getInternalOptions = (status: BookingStatus): BookingOptionType[] => {
    switch (status) {
        case BookingStatus.AwaitingLoadCompletion:
        case BookingStatus.RequiresOrders:
            return [
                'updateOrders',
                'edit',
                'reschedule',
                'updateResources',
                'manageAttachments',
                'viewHistory',
                'cancel'
            ];
        default:
            return ['viewHistory'];
    }
};

const getNoBookingOptions = (
    status: BookingStatus,
    isSuperUser: boolean
): BookingOptionType[] => {
    switch (status) {
        case BookingStatus.AwaitingCompletion:
            return isSuperUser
                ? ['edit', 'reschedule', 'viewHistory', 'cancel']
                : ['viewHistory'];
        default:
            return ['viewHistory'];
    }
};

const getAvailableOptions = (
    status: BookingStatus,
    type: BookingType,
    isSuperUser: boolean
): BookingOptionType[] => {
    switch (type) {
        case BookingType.Collection:
            return getCollectionOptions(status);
        case BookingType.Delivery:
            return getDeliveryOptions(status);
        case BookingType.DeliveryAndCollection:
            return getDeliveryAndCollectionOptions(status);
        case BookingType.Resource:
            return getResourceOptions(status);
        case BookingType.Internal:
            return getInternalOptions(status);
        case BookingType.NoBookings:
            return getNoBookingOptions(status, isSuperUser);
    }
};

export { getAvailableOptions };
