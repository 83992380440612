import React from 'react';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from '../common/Icon';
import { NavItemProps } from './NavItem';
import { useClasses } from './NavItemContent.styles';

export interface NavItemContentProps extends NavItemProps {
    showSubItems?: boolean;
    onClick?(): void;
}

const NavItemContentArea: React.FC<NavItemContentProps> = (props) => {
    const {
        itemName,
        iconType,
        isASubItem = false,
        routeTo,
        target,
        showSubItems,
        navExpanded,
        horizontal,
        isExternalLink,
        onClick,
    } = props;

    const classes = useClasses(props);

    if (routeTo) {
        return (
            <NavLink className={classes.navlink} to={routeTo} target={target}>
                <Icon
                    className={classnames(classes.navlinkIcon, {
                        [classes.navSubItemIcon]: isASubItem,
                    })}
                    type={iconType}
                />
                {isExternalLink && (
                    <sup>
                        <Icon
                            className={classnames(classes.navlinkIcon, {
                                [classes.navSubItemIcon]: isASubItem,
                            })}
                            type="external-link"
                        />
                    </sup>
                )}

                {(horizontal || navExpanded) && (
                    <span className={classes.navlinkText}>{itemName}</span>
                )}
            </NavLink>
        );
    } else if (onClick) {
        const arrowIconClasses = classnames(classes.navlinkArrow, {
            [classes.navlinkArrowDown]: !showSubItems,
            [classes.navlinkArrowUp]: showSubItems,
            [classes.navlinkArrowActive]: showSubItems,
        });

        return (
            <span className={classes.navlink} onClick={onClick}>
                <Icon
                    className={
                        iconType === 'chevron-down'
                            ? arrowIconClasses
                            : classes.navlinkIcon
                    }
                    type={iconType}
                />
            </span>
        );
    } else {
        return (
            <>
                <Icon
                    className={classnames(classes.navlinkIcon, {
                        [classes.navSubItemIcon]: isASubItem,
                    })}
                    type={iconType}
                />

                {(horizontal || navExpanded) && (
                    <span className={classes.navlinkText}>{itemName}</span>
                )}
            </>
        );
    }
};

export default NavItemContentArea;
