import * as React from 'react';
import { useEffect, useState } from 'react';
import BayPicker from '../../../components/releasing/BayPicker';
import Button from '../../../lib/bootstrap-ui/Button';
import DropdownOption from '../../../lib/bootstrap-ui/Dropdown/DropdownOption';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader
} from '../../../lib/bootstrap-ui/Modal';
import Bay from '../../../models/bays/Bay';
import { Booking } from '../../../models/bookings/Booking';
import { SMSTemplate } from '../../../models/smsTemplates/SMSTemplate';
import { mapBaysToDropdownOptions } from '../../../services/bayService';
import PhoneNumberInput from '../../common/PhoneNumberInput';

interface BookingSmsModalProps {
    booking: Booking;
    bays: Bay[];
    loadingBays: boolean;
    smsTemplates: SMSTemplate[];
    showModal: boolean;
    onSave(
        contactNumber: string,
        smsTemplateId: string,
        bayNumber: number,
        bayNumberUpdateable: boolean
    ): void;
    onClose(): void;
}

const mapSMSTemplatesToDropdownOptions = (
    smsTemplates: SMSTemplate[] | null
): DropdownOption[] => {
    const smsTemplateData = smsTemplates;
    return (
        smsTemplateData?.map(s => ({
            label: s.name,
            value: s.id
        })) || []
    );
};

const getDefaultSMSTemplate = (smsTemplates: SMSTemplate[]) => {
    return smsTemplates.find(t => t.isDefault);
};

const BookingSmsModal: React.FC<BookingSmsModalProps> = ({
    booking,
    bays,
    loadingBays,
    smsTemplates,
    showModal,
    onSave,
    onClose
}) => {
    const [
        selectedSMSTemplate,
        setSelectedSMSTemplate
    ] = useState<SMSTemplate | null>(
        getDefaultSMSTemplate(smsTemplates) ?? null
    );
    const [bookingBayNumber, setBookingBayNumber] = useState(
        booking.bayOrPagerNumber
    );
    const [bookingContactNumber, setBookingContactNumber] = useState(
        booking.contactNumber
    );
    const [contactNumberIsValid, setContactNumberIsValid] = useState(false);

    useEffect(() => {
        setSelectedSMSTemplate(getDefaultSMSTemplate(smsTemplates) ?? null);
    }, [smsTemplates]);

    const handleSave = () => {
        if (
            bookingContactNumber &&
            selectedSMSTemplate &&
            selectedSMSTemplate.id
        ) {
            onSave(
                bookingContactNumber,
                selectedSMSTemplate.id,
                bookingBayNumber,
                selectedSMSTemplate.allowBaySelection ?? false
            );
        }
        setBookingContactNumber(null);
        setBookingBayNumber(0);
    };

    const handleBayChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const bayNumber = parseInt(event.target.value, 10);
        setBookingBayNumber(bayNumber);
    };

    const handleSelectedSmsTemplateChanges = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const smsTemplate =
            smsTemplates.find(t => t.id === event.target.value) ?? null;
        setSelectedSMSTemplate(smsTemplate);

        smsTemplate?.allowBaySelection
            ? setBookingBayNumber(booking.bayOrPagerNumber)
            : setBookingBayNumber(0);
    };

    const getValueAsStringOrNull = (value: number | null) => {
        if (value !== null) {
            return String(value);
        }
        return null;
    };

    const bayNumberUpdateable =
        booking.requiresCheckIn &&
        (!booking.load || !booking.load?.isReleased) &&
        selectedSMSTemplate?.allowBaySelection;
    const sendSmsAllowed =
        selectedSMSTemplate &&
        bookingContactNumber &&
        ((bayNumberUpdateable && bookingBayNumber) || !bayNumberUpdateable);

    return (
        <Modal isOpen={showModal} onRequestClose={onClose}>
            <ModalHeader>
                <h5>Contact driver: {booking.reference}</h5>
            </ModalHeader>
            <ModalBody>
                <PhoneNumberInput
                    label={'Contact Number:'}
                    placeholder={'Contact Number...'}
                    onPhoneNumberChanged={setBookingContactNumber}
                    phoneNumber={bookingContactNumber}
                    required
                    onPhoneNumberValidation={setContactNumberIsValid}
                />
                <SelectDropdown
                    id={'SmsTemplateDropdown'}
                    label={'Message:'}
                    dropdownOptions={mapSMSTemplatesToDropdownOptions(
                        smsTemplates
                    )}
                    defaultText={'Select...'}
                    onChange={handleSelectedSmsTemplateChanges}
                    selectedOption={selectedSMSTemplate?.id ?? null}
                    hideBottomPadding
                />
                {bayNumberUpdateable && (
                    <BayPicker
                        bayDropDownOptions={
                            mapBaysToDropdownOptions(bays) || []
                        }
                        disabled={loadingBays}
                        loadingBays={loadingBays}
                        selectedOption={getValueAsStringOrNull(
                            bookingBayNumber
                        )}
                        onBayChange={handleBayChanged}
                        hideBottomPadding
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button styleType="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    styleType="primary"
                    disabled={!sendSmsAllowed || !contactNumberIsValid}
                    onClick={handleSave}
                >
                    Send
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default BookingSmsModal;
