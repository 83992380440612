import * as React from 'react';
import Table from '../../lib/bootstrap-ui/Table';
import { HeaderDetails } from '../../lib/bootstrap-ui/Table/Table';
import { LoadingMilestoneThreshold } from '../../models/loads/LoadingMilestoneThreshold';
import { LoadWithPallets } from '../../models/loads/LoadsWithPallets';
import NoDataFoundTableRow from '../common/table/NoDataFoundTableRow';
import { useClasses } from './ProgressSummaryTable.styles';
import ProgressSummaryTableRow from './ProgressSummaryTableRow';
import ProgressSummaryTotalRow from './ProgressSummaryTotalRow';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { userIsNotReader } from '../../services/userService';
import { useCurrentUser } from '../../services/authentication';

interface GroupedLoadingMilestones {
    [store: string]: LoadingMilestoneThreshold[];
}

interface ProgressSummaryTableProps {
    loadsWithPallets: LoadWithPallets[];
    milestoneThresholds: LoadingMilestoneThreshold[];
    loading: boolean;
    onLoadUpdated(updatedLoad: LoadWithPallets): void;
}

const ProgressSummaryTableHeaders: (string | HeaderDetails)[] = [
    'Bay',
    { title: 'PL', tooltip: 'Part Load' },
    'IOD',
    { title: 'Priority', width: '7.5rem' },
    { title: 'Destination', width: '12rem' },
    'Trailer',
    'Type',
    'Sub',
    'Time Left',
    { title: 'Allocated', tooltip: 'Rack', width: '5rem' },
    {
        title: 'Picked',
        tooltip: 'Chamber floor or moving to dock',
        width: '5rem',
    },
    {
        title: 'Problem',
        tooltip: 'Problem',
        width: '5rem',
    },
];

const sortLoadWithPalletsByBayNumber = (
    lhs: LoadWithPallets,
    rhs: LoadWithPallets
): number => {
    return lhs.bayId! - rhs.bayId!;
};

const ProgressSummaryTable: React.FC<ProgressSummaryTableProps> = ({
    loadsWithPallets,
    milestoneThresholds,
    loading,
    onLoadUpdated,
}) => {
    const classes = useClasses();
    const user = useCurrentUser();

    const [editPriorityFeature] = useFeatureFlag('phase1_editLoadPriority');

    const disableEditPriority = !editPriorityFeature || !userIsNotReader(user);

    const milestonesGroupedByStore: GroupedLoadingMilestones =
        milestoneThresholds.reduce(
            (
                result: GroupedLoadingMilestones,
                currentValue: LoadingMilestoneThreshold
            ) => {
                (result[currentValue.store] =
                    result[currentValue.store] || []).push(currentValue);

                return result;
            },
            {}
        );

    return (
        <Table
            className={classes.progressSummaryTable}
            headers={ProgressSummaryTableHeaders}
            loading={loading}
            placeholderRows={15}
            fixedHeader
            medium
        >
            {!loading && loadsWithPallets.length === 0 ? (
                <NoDataFoundTableRow
                    containerClassName={classes.noDataContainer}
                    messageText="No loads were found for this store"
                    iconType="no-loads"
                    columnSpan={ProgressSummaryTableHeaders.length}
                    size="large"
                />
            ) : (
                <>
                    {loadsWithPallets
                        .sort(sortLoadWithPalletsByBayNumber)
                        .map((lwp) => (
                            <ProgressSummaryTableRow
                                key={lwp.id}
                                loadWithPallets={lwp}
                                milestoneThresholds={
                                    milestonesGroupedByStore[lwp.sourceStore]
                                }
                                onLoadUpdated={onLoadUpdated}
                                disablePriority={disableEditPriority}
                            />
                        ))}
                    <ProgressSummaryTotalRow
                        pallets={loadsWithPallets
                            .map((lwp) => lwp.pallets)
                            .reduce((rv, curr) => rv.concat(curr), [])}
                    />
                </>
            )}
        </Table>
    );
};

export default ProgressSummaryTable;
