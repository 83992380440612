import * as React from 'react';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import LoadTypeSelectionContainer from '../LoadTypeSelectionContainer';
import RecurringBookingDetails from '../../../models/bookings/RecurringBookingDetails';

const RecurringBookingLoadTypeContainer: React.FC<FormStepProps<
    RecurringBookingToAdd
>> = ({ formObject: booking, onChange: onBookingChange }) => {
    const bookingDetails: RecurringBookingDetails = {
        bookingType: booking.bookingType,
        duration: booking.duration,
        overrideStoreResourceValues: booking.overrideStoreResource,
        resources: booking.resources,
        startDate: booking.startDate,
        recurrencePattern: booking.recurrencePattern,
        store: booking.store,
        trailerType: booking.trailerType,
        secondaryTrailerType: booking.secondaryTrailerType
    };

    const handleBookingDetailsUpdate = (details: RecurringBookingDetails) => {
        const editedBooking: RecurringBookingToAdd = {
            ...booking,
            bookingType: details.bookingType,
            duration: details.duration,
            resources: details.resources,
            startDate: details.startDate,
            recurrencePattern: details.recurrencePattern,
            overrideStoreResource: details.overrideStoreResourceValues,
            store: details.store,
            trailerType: details.trailerType,
            secondaryTrailerType: booking.secondaryTrailerType
        };
        onBookingChange(editedBooking);
    };

    return (
        <LoadTypeSelectionContainer
            options={{
                details: bookingDetails,
                type: 'recurring'
            }}
            onBookingDetailsUpdate={handleBookingDetailsUpdate}
        />
    );
};

export default RecurringBookingLoadTypeContainer;
