import * as React from 'react';
import { useEffect, useState } from 'react';
import PickerMessageDisplay from '../../components/releasing/PickerMessageDisplay';
import Grid, { Column, Row } from '../../lib/bootstrap-ui/Grid';
import Bay from '../../models/bays/Bay';
import { BayStatus } from '../../models/bays/BayStatus';
import { LoadType } from '../../models/loads/LoadType';
import { Trailer } from '../../models/trailers/Trailer';
import { TrailerType } from '../../models/trailers/TrailerType';
import { apiGet } from '../../services/api';
import { getReleaseScreenWarningsAndErrors } from '../../services/releaseValidationService';
import { getTrailerId } from '../../services/trailerService';
import BayPickerContainer from '../releasing/BayPickerContainer';
import TrailerPickerContainer from '../releasing/TrailerPickerContainer';
import { useClasses } from './ReleasePropertyPickersContainer.styles';

interface ReleasePropertyPickersContainerProps {
    validateReleaseStrategy: (releaseStrategyValid: boolean) => void;
    bayStatuses: BayStatus[] | null;
    storeId?: number;
    selectedBay: Bay | null;
    selectedTrailer: Trailer | null;
    fetchedTrailer: Trailer | null;
    trailerType: TrailerType | null;
    loadType: LoadType | null;
    isReedBoardallLoad: boolean;
    bayOrPagerNumber?: number | null;
    isPreDrop: boolean;
    hasTransportOnlyOrders: boolean;
    onBayChange: (bay: Bay | null) => void;
    onTrailerChange: (trailer: Trailer | null) => void;
    setSelectedTrailerAsInactive: () => void;
}

const GetPreDropTrailer = (preDropTrailerType: TrailerType | null): Trailer => {
    return {
        id: 'PRE',
        trailerType: preDropTrailerType || 'Single',
        active: true,
    };
};

const ReleasePropertyPickersContainer: React.FC<
    ReleasePropertyPickersContainerProps
> = ({
    validateReleaseStrategy,
    bayStatuses,
    storeId,
    selectedBay,
    selectedTrailer,
    fetchedTrailer,
    trailerType,
    loadType,
    isReedBoardallLoad,
    bayOrPagerNumber,
    isPreDrop,
    hasTransportOnlyOrders,
    onBayChange,
    onTrailerChange,
    setSelectedTrailerAsInactive,
}) => {
    const classes = useClasses();

    const lastCurrentBayOfTrailerRequestController =
        React.useRef<AbortController | null>();
    const [currentBayIdOfSelectedTrailer, setCurrentBayIdOfSelectedTrailer] =
        useState<number | null>(null);
    const [trailerDetectedAtSelectedBay, setTrailerDetectedAtSelectedBay] =
        useState<Trailer | null>(null);
    const [checkingBay, setCheckingBay] = useState(false);

    const isSelectedTrailerAtSelectedBay = () => {
        return (
            getTrailerId(selectedTrailer) ===
            getTrailerId(trailerDetectedAtSelectedBay)
        );
    };

    const selectedTrailerNumber = selectedTrailer && selectedTrailer.id;
    const allowTrailerAutoUpdate =
        selectedTrailer && selectedTrailer.id !== ''
            ? isSelectedTrailerAtSelectedBay()
            : true;

    const isValidTrailerTypeForLoad = (trailer: Trailer | null) => {
        if (trailer) {
            switch (trailer.trailerType) {
                case 'Single':
                    return trailer.trailerType === trailerType;
                case 'Decker':
                default:
                    return true;
            }
        }
        return true;
    };

    const getCurrentBayIdOfTrailer = async (trailerNumber: string | null) => {
        if (trailerNumber) {
            if (lastCurrentBayOfTrailerRequestController.current) {
                lastCurrentBayOfTrailerRequestController.current.abort();
            }

            const controller = new AbortController();
            const signal = controller.signal;
            lastCurrentBayOfTrailerRequestController.current = controller;

            const currentBayOfTrailer: number | null = await apiGet(
                `Trailer/${trailerNumber}/Bay`,
                undefined,
                { signal }
            ).catch((err) => {
                if (err.name !== 'AbortError') {
                    throw err;
                }
            });
            return currentBayOfTrailer;
        }
        return null;
    };

    useEffect(
        () => {
            const checkBayForTrailer = async (bay: Bay | null) => {
                if (bay?.id) {
                    if (
                        !fetchedTrailer?.id &&
                        allowTrailerAutoUpdate &&
                        (isPreDrop || bay.isVirtualBay)
                    ) {
                        const preDropTrailer = GetPreDropTrailer(trailerType);
                        setTrailerDetectedAtSelectedBay(preDropTrailer);
                        onTrailerChange(preDropTrailer);
                        setCheckingBay(false);
                        return;
                    }

                    const trailerDetectedAtBay = await apiGet(
                        `Bay/${bay.id}/Trailer`
                    );
                    if (
                        trailerDetectedAtBay &&
                        !trailerDetectedAtBay.trailerType
                    ) {
                        trailerDetectedAtBay.trailerType = 'Unknown';
                    }
                    if (allowTrailerAutoUpdate) {
                        if (!trailerDetectedAtBay.id && !fetchedTrailer?.id) {
                            onTrailerChange(null);
                        }
                        const bayIdOfSelectedTrailer =
                            await getCurrentBayIdOfTrailer(
                                getTrailerId(trailerDetectedAtBay)
                            );
                        if (!!trailerDetectedAtBay.id) {
                            onTrailerChange(
                                isValidTrailerTypeForLoad(trailerDetectedAtBay)
                                    ? trailerDetectedAtBay
                                    : null
                            );
                            setCurrentBayIdOfSelectedTrailer(
                                bayIdOfSelectedTrailer
                            );
                        }
                    }
                    setCheckingBay(false);
                    setTrailerDetectedAtSelectedBay(
                        trailerDetectedAtBay && !trailerDetectedAtBay.id
                            ? null
                            : trailerDetectedAtBay
                    );
                }
            };

            checkBayForTrailer(selectedBay);
        },
        // eslint-disable-next-line
        [selectedBay]
    );

    useEffect(() => {
        const GetBayLocationOfTrailer = async () => {
            if (selectedBay && selectedTrailerNumber) {
                const fetchedBayIdOfSelectedTrailer =
                    await getCurrentBayIdOfTrailer(selectedTrailerNumber);
                setCurrentBayIdOfSelectedTrailer(fetchedBayIdOfSelectedTrailer);
            }
        };

        GetBayLocationOfTrailer();
    }, [selectedTrailerNumber, selectedBay]);

    const setTrailerToDetectedTrailer = async () => {
        if (trailerDetectedAtSelectedBay) {
            onTrailerChange(trailerDetectedAtSelectedBay);
            const currentBayId = await getCurrentBayIdOfTrailer(
                getTrailerId(trailerDetectedAtSelectedBay)
            );
            setCurrentBayIdOfSelectedTrailer(currentBayId);
        }
    };

    const releaseValidationObjects = getReleaseScreenWarningsAndErrors({
        bayStatuses,
        checkingBay,
        currentBayIdOfSelectedTrailer,
        isDetectedTrailerValid: isValidTrailerTypeForLoad(
            trailerDetectedAtSelectedBay
        ),
        isSelectedTrailerAtSelectedBay: isSelectedTrailerAtSelectedBay(),
        selectedBayNumber: selectedBay?.id ?? null,
        selectedTrailer,
        fetchedTrailer,
        trailerDetectedAtSelectedBay,
        trailerType,
        loadType,
        hasTransportOnlyOrders,
        isReedBoardallLoad,
        onUseDetectedTrailer: setTrailerToDetectedTrailer,
    });

    useEffect(() => {
        validateReleaseStrategy(
            !releaseValidationObjects.some((o) => o?.blockRelease)
        );
    }, [releaseValidationObjects, validateReleaseStrategy]);

    return (
        <Grid fluid>
            <Row className={classes.rowTop2}>
                <Column sm={6}>
                    <BayPickerContainer
                        storeId={storeId}
                        setCheckingBay={() => setCheckingBay(true)}
                        bayOrPagerNumber={bayOrPagerNumber}
                        onBayChange={onBayChange}
                        trailerType={trailerType}
                        selectedBay={selectedBay}
                        bayStatuses={bayStatuses}
                        virtualBaysOnly={isPreDrop}
                    />
                </Column>
                <Column sm={6}>
                    <TrailerPickerContainer
                        id="TrailerDropdown"
                        label="Trailer Number:"
                        onTrailerChange={onTrailerChange}
                        trailerType={trailerType}
                        loadType={loadType}
                        selectedValue={getTrailerId(selectedTrailer)}
                        setSelectedTrailerAsInactive={
                            setSelectedTrailerAsInactive
                        }
                        disabled={
                            selectedBay?.id === null ||
                            isPreDrop ||
                            selectedBay?.isVirtualBay
                        }
                    />
                </Column>
            </Row>
            <PickerMessageDisplay
                releaseValidationObjects={releaseValidationObjects}
            />
        </Grid>
    );
};

export default ReleasePropertyPickersContainer;
