import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ErrorModal from '../../../components/common/ErrorModal';
import InformationMessage from '../../../components/common/InformationMessage';
import UpdateNotification from '../../../components/common/UpdateNotification';
import IodPlanGrid from '../../../components/iod/view/IodPlanGrid';
import IodPlanInfo from '../../../components/iod/view/IodPlanInfo';
import LoadDetailsCard from '../../../components/load/LoadDetails/LoadDetailsCard';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { useConditionalDebounce } from '../../../hooks/useConditionalDebounce';
import { useNotificationListener } from '../../../hooks/useNotificationListener';
import Button from '../../../lib/bootstrap-ui/Button';
import { HttpErrorResponse } from '../../../models/common/HttpErrorResponse';
import { IodPlan } from '../../../models/iods/IodPlan';
import { Load } from '../../../models/loads/Load';
import { LoadType } from '../../../models/loads/LoadType';
import {
    ChangesWithAffectedLoad,
    LoadRemoved,
    LoadRemovedNotification,
    LoadUpdatedNotification,
} from '../../../models/notifications/Load';
import { apiPost, useData } from '../../../services/api';
import { useAuth } from '../../../services/authentication';
import {
    getAllLoadsFromIodPlan,
    getLoadRoleInPlan,
    IodPlanComparator,
    isFirstLoadInPlan,
} from '../../../services/iodPlanService';
import {
    getIodPlanStatistics,
    getResequencePalletCountForLoad,
    getTransportOnlyPalletCountForLoad,
    IodPlanStatistics,
} from '../../../services/iodPlanStatisticsService';
import { getIodPlanVariationsForUser } from '../../../services/iodReplanService';
import IodReplanOptionsContainer from '../../load/loadDetails/edit/IodReplanOptionsContainer';
import { useClasses } from './IodDetailsContainer.styles';
import { getErrorMessage } from '../../../helpers/errors';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { userIsNotReader } from '../../../services/userService';

interface IodDetailsContainerProps {
    iodNumber: string;
    selectedLoadId?: string;
    onBackButtonClick(): void;
}

const handleLoadUpdatedNotification =
    (iodLoads: Load[], fetch: () => Promise<void>) =>
    async (changesWithAffectedLoad: ChangesWithAffectedLoad) => {
        if (
            iodLoads.some(
                (l) =>
                    l.id === changesWithAffectedLoad.affectedLoad.id &&
                    changesWithAffectedLoad.changes.length > 0
            )
        ) {
            toast.info(
                <UpdateNotification
                    heading={`Load ${changesWithAffectedLoad.affectedLoad.loadName} Updated`}
                >
                    {changesWithAffectedLoad.changes.map((c) => (
                        <div key={c.id}>{c.message}</div>
                    ))}
                </UpdateNotification>
            );

            await fetch();
        }
    };

const handleLoadRemovedNotification =
    (iodLoads: Load[], fetch: () => Promise<void>) =>
    async (loadRemoved: LoadRemoved) => {
        if (iodLoads.some((l) => l.id === loadRemoved.loadId)) {
            toast.info(
                <UpdateNotification
                    heading={`Load ${loadRemoved.loadName} Removed`}
                />
            );

            await fetch();
        }
    };

const getReplanIodFromIodPlan = (iodPlan: IodPlan): ReplanIod => ({
    iodId: iodPlan.iod.id,
    iodVersion: 1,
    trip1: {
        loadInfo: {
            id: iodPlan.trip1.load.id,
            version: iodPlan.trip1.load.version,
        },
        predecessorTransferIds: iodPlan.trip1.predecessorTransfers.map(
            (transfer) => ({ id: transfer.id, version: transfer.version })
        ),
    },
    trip2: !!iodPlan.trip2
        ? {
              loadInfo: {
                  id: iodPlan.trip2.load.id,
                  version: iodPlan.trip2.load.version,
              },
              predecessorTransferIds: iodPlan.trip2.predecessorTransfers.map(
                  (transfer) => ({
                      id: transfer.id,
                      version: transfer.version,
                  })
              ),
          }
        : undefined,
    trip3: !!iodPlan.trip3
        ? {
              loadInfo: {
                  id: iodPlan.trip3.load.id,
                  version: iodPlan.trip3.load.version,
              },
              predecessorTransferIds: iodPlan.trip3.predecessorTransfers.map(
                  (transfer) => ({
                      id: transfer.id,
                      version: transfer.version,
                  })
              ),
          }
        : undefined,
});

interface FinishingTrailerInfo {
    predecessorTransferIds: { id: string; version: number }[];
    loadInfo: { id: string; version: number };
}

interface ReplanIod {
    iodId: string;
    iodVersion: number;
    trip1: FinishingTrailerInfo;
    trip2?: FinishingTrailerInfo;
    trip3?: FinishingTrailerInfo;
}

const IodDetailsContainer: React.FC<IodDetailsContainerProps> = ({
    iodNumber,
    selectedLoadId,
    onBackButtonClick,
}) => {
    const { authState } = useAuth();

    const classes = useClasses();

    const [iodPlan, loading, fetchIodPlan] = useData<IodPlan>(
        `Iod/${iodNumber}/Plan`
    );

    const [selectedPlan, setSelectedPlan] = useState<IodPlan | null>(null);
    const selectedReplan = useRef<ReplanIod | null>(null);
    const [hoveredPlan, setHoveredPlan] = useState<IodPlan | null>(null);
    const [submittingReplan, setSubmittingReplan] = useState(false);
    const [replanError, setReplanError] = useState<HttpErrorResponse | null>(
        null
    );

    const [mode, setMode] = useState<'view' | 'edit'>('view');

    const debounceIfNull = useConditionalDebounce(
        500,
        (newHoveredPlan: IodPlan | null) =>
            !!newHoveredPlan &&
            (!hoveredPlan || !IodPlanComparator(newHoveredPlan, hoveredPlan)),
        setHoveredPlan
    );

    const iodLoads: Load[] = useMemo(() => {
        if (!iodPlan) {
            return [];
        }
        return getAllLoadsFromIodPlan(iodPlan);
    }, [iodPlan]);

    useEffect(() => {
        if (!selectedPlan) {
            selectedReplan.current = null;
            return;
        }

        const replan: ReplanIod = getReplanIodFromIodPlan(selectedPlan);
        selectedReplan.current = replan;
    }, [selectedPlan]);

    useNotificationListener(
        LoadUpdatedNotification,
        handleLoadUpdatedNotification(iodLoads, fetchIodPlan)
    );
    useNotificationListener(
        LoadRemovedNotification,
        handleLoadRemovedNotification(iodLoads, fetchIodPlan)
    );

    const [replanFeature] = useFeatureFlag('phase1_replanIods');

    const canReplan =
        !!replanFeature &&
        !iodPlan?.iod.hasFinishingTrailerBeenReleased &&
        iodLoads.length > 1 &&
        userIsNotReader(authState.user);

    const iodPlanVariations: IodPlan[] = useMemo(
        () =>
            iodPlan && canReplan
                ? getIodPlanVariationsForUser(iodPlan, authState.user!)
                : [],
        [iodPlan, canReplan, authState.user]
    );

    const handleIodReplan = async () => {
        if (!selectedReplan.current) {
            throw Error('Cannot send empty replan');
        }

        setSubmittingReplan(true);

        try {
            await apiPost(
                `Iod/${iodPlan?.iod.id}/Replan`,
                selectedReplan.current
            );
            setSelectedPlan(null);
            setSubmittingReplan(false);
            setMode('view');
        } catch (error) {
            console.warn(error);
            setSubmittingReplan(false);
            setReplanError(error as HttpErrorResponse);
        }
    };

    const replanContainer = () => {
        if (!iodPlan) return null;
        if (!iodPlan.iod.hasFinishingTrailerBeenReleased) {
            return (
                <IodReplanOptionsContainer
                    currentPlan={iodPlan}
                    iodPlanVariations={iodPlanVariations}
                    selectedPlan={selectedPlan}
                    hoveredPlan={hoveredPlan}
                    onReplanSelected={(replan) => {
                        if (!replan) {
                            setHoveredPlan(selectedPlan);
                            setSelectedPlan(replan);
                        }
                        setSelectedPlan(replan);
                        setHoveredPlan(null);
                    }}
                    onReplanHovered={debounceIfNull}
                />
            );
        }

        return (
            <p>
                IOD{' '}
                {iodPlan.iod.isFinishingTrailerReleased
                    ? 'RELEASED'
                    : 'RELEASING'}
            </p>
        );
    };

    if (!iodPlan && loading) {
        return <LoadingIndicator />;
    }

    if (!!replanError) {
        return (
            <ErrorModal
                showModal={!!replanError}
                header={`Replan Iod ${iodPlan?.iod.iodNumber}`}
                errorText={`An error has occurred when replanning the Iod: ${getErrorMessage(
                    replanError
                )}`}
                onClose={() => setReplanError(null)}
            />
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.contentContainer}>
                {iodPlan &&
                    selectedLoadId &&
                    !getAllLoadsFromIodPlan(iodPlan).some(
                        (load) => load.id === selectedLoadId
                    ) && (
                        <InformationMessage
                            messageType="error"
                            className={classes.removedMessage}
                        >
                            This load has been removed.
                        </InformationMessage>
                    )}
                {iodPlan && (
                    <>
                        <IodPlanInfo
                            iodPlan={iodPlan}
                            comparisons={
                                [selectedPlan, hoveredPlan]
                                    .filter((plan) => !!plan)
                                    .map((plan) =>
                                        getIodPlanStatistics(plan!)
                                    ) as IodPlanStatistics[]
                            }
                            onBackButtonClick={onBackButtonClick}
                        />
                        <div className={classes.iodPlanContainer}>
                            <IodPlanGrid
                                iodPlan={iodPlan}
                                selectedLoadId={selectedLoadId}
                                arrowClassname={classes.iodPlanGridArrow}
                                gridItemRenderer={(
                                    load,
                                    loadsFromSameStore
                                ) => (
                                    <LoadDetailsCard
                                        load={load}
                                        loadsFromSameStore={loadsFromSameStore}
                                        iodId={iodPlan.iod.id}
                                        isSelected={load.id === selectedLoadId}
                                        role={getLoadRoleInPlan(iodPlan, load)}
                                        resequence={getResequencePalletCountForLoad(
                                            load
                                        )}
                                        isFirstLoadInPlan={isFirstLoadInPlan(
                                            iodPlan,
                                            load
                                        )}
                                    />
                                )}
                                transportOnlyPalletCount={getTransportOnlyPalletCountForLoad(
                                    iodLoads.find(
                                        (l) =>
                                            l.loadType ===
                                                LoadType.TrailerStart ||
                                            l.loadType === LoadType.FullLoad
                                    )
                                )}
                            />
                        </div>
                        {mode === 'edit' && (
                            <>
                                <div className={classes.replanContainer}>
                                    {replanContainer()}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            {canReplan && iodPlanVariations.length > 1 && (
                <div className={classes.saveButtonContainer}>
                    {mode === 'view' ? (
                        <Button
                            styleType="primary"
                            onClick={() => setMode('edit')}
                        >
                            Replan Iod
                        </Button>
                    ) : !submittingReplan ? (
                        <>
                            <Button
                                styleType="secondary"
                                onClick={() => {
                                    setMode('view');
                                    setHoveredPlan(null);
                                    setSelectedPlan(null);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                styleType="primary"
                                disabled={!selectedPlan}
                                onClick={handleIodReplan}
                            >
                                Submit Replan
                            </Button>
                        </>
                    ) : (
                        <LoadingIndicator text="Replanning..." />
                    )}
                </div>
            )}
        </div>
    );
};

export default IodDetailsContainer;
