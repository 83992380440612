import { buildClassesHook } from '../../../../helpers/styles';
import { Theme } from '../../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    dateLabel: {
        padding: '0 0 0 15px',
    },
    dateValue: {
        padding: '0 15px 0 0',
    },
    orderCard: {
        boxShadow: '1px 1px 3px 1px hsl(210, 10%, 92%)',
        margin: '15px 0',
    },
    orderCardBody: {
        padding: '0.5rem 0',
    },
    orderCardText: {
        margin: 0,
    },
    orderCardTitle: {
        margin: 0,
    },
    removeButton: {
        color: theme.colors.error,
        float: 'right',
        padding: 0,
        verticalAlign: 'baseline',
        '&:hover': {
            color: theme.colors.errorDark,
        },
        cursor: 'pointer',
    },
    titleRow: {
        marginBottom: '0.8rem',
    },
    additionalInfoText: {
        fontWeight: 'bold',
    },
}));
