import * as React from 'react';
import { LoadingMilestoneThreshold } from '../../../../../../models/loads/LoadingMilestoneThreshold';
import { Order } from '../../../../../../models/orders/Order';
import { Pallet } from '../../../../../../models/pallets/Pallet';
import { useClasses } from './LoadingProgressOrderRow.styles';
import LoadingProgressPalletRow from './LoadingProgressPalletRow';

interface LoadingProgressOrderRowProps {
    order: Order;
    orderPallets: Pallet[];
    milestoneThresholds: LoadingMilestoneThreshold[];
    loadCompletedTime?: Date;
    isTunnelTransferOrder?: boolean;
    isStockTransferOrder?: boolean;
}

const sortPalletsByWeightDesc = (a: Pallet, b: Pallet) => b.weight - a.weight;

const LoadingProgressOrderRow: React.FC<LoadingProgressOrderRowProps> = ({
    order,
    orderPallets,
    milestoneThresholds,
    loadCompletedTime,
    isTunnelTransferOrder = false,
    isStockTransferOrder = false,
}) => {
    const classes = useClasses();

    const sortedOrderPallets: Pallet[] = orderPallets.sort(
        sortPalletsByWeightDesc
    );

    return (
        <>
            <tr className={classes.orderRow}>
                <td colSpan={5} className={classes.orderNumber}>
                    {isTunnelTransferOrder
                        ? 'TUNNEL TRANSFER ORDER '
                        : isStockTransferOrder
                        ? 'STOCK TRANSFER ORDER '
                        : ''}
                    {order.customerOrderNumber === order.orderNumber
                        ? order.customerOrderNumber
                        : `${order.orderNumber} (${order.customerOrderNumber})`}
                </td>
                <td className={classes.borderedCell} />
                <td className={classes.borderedCell} />
                <td className={classes.borderedCell} />
            </tr>
            {sortedOrderPallets.map((p) => (
                <LoadingProgressPalletRow
                    key={p.id}
                    pallet={p}
                    milestoneThresholds={milestoneThresholds}
                    loadCompletedTime={loadCompletedTime}
                    isTransferPallet={
                        isTunnelTransferOrder || isStockTransferOrder
                    }
                />
            ))}
        </>
    );
};

export default LoadingProgressOrderRow;
