import * as React from 'react';

import { BayStatus } from '../../models/bays/BayStatus';
import { theme } from '../../theme';
import Icon from '../common/Icon';
import { useClasses } from './BayStatusTableRow.styles';

interface BayStatusTableRowProps {
    bayStatus: BayStatus;
}

const BayStatusTableRow: React.FC<Readonly<BayStatusTableRowProps>> = ({
    bayStatus,
}) => {
    const classes = useClasses();

    return (
        <tr className={classes.dataRow}>
            <td>{bayStatus.id}</td>
            <td>
                {bayStatus.isOutOfOrder ? (
                    <Icon
                        type="out-of-order"
                        title={'Bay out of order'}
                        color={theme.colors.error}
                    />
                ) : (
                    bayStatus.trailerId
                )}
            </td>
            <td>
                {(bayStatus.releasedTo && (
                    <Icon
                        type="clipboard-check"
                        title={'A load has recently been released to this bay'}
                        color={theme.colors.error}
                    />
                )) ||
                    (bayStatus.trailer && !bayStatus.trailer.active && (
                        <Icon
                            color={theme.colors.error}
                            title={'Vehicle Off Road'}
                            type="error"
                        />
                    ))}
            </td>
        </tr>
    );
};

export default BayStatusTableRow;
