import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    datePickerContainer: {
        marginTop: '1rem',
    },
    instructionsLabel: {
        color: theme.colors.info,
    },
}));
