import * as React from 'react';
import { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import Icon from '../components/common/Icon';
import { buildClassesHook } from '../helpers/styles';
import { Theme } from '../theme';

const useClasses = buildClassesHook((theme: Theme) => ({
    wrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: '5rem',
        overflowX: 'hidden',
        '@media(orientation: landscape)': {
            width: '100%',
            height: '100vh',
            marginTop: 0,
        },
    },
    icon: {
        fontSize: '15rem',
        color: theme.colors.darkGrey,
    },
    text: {
        fontSize: '2rem',
        color: theme.colors.darkGrey,
    },
    smallText: {
        fontSize: '0.8rem',
        color: theme.colors.darkGrey,
    },
}));

const AnimatedIcon = animated(Icon);

const NotFound: React.FC = () => {
    const classes = useClasses();

    const [move, setMove] = React.useState(false);
    const styles = useSpring({
        to: { transform: move ? 'translateX(100vw)' : 'translateX(0vw)' },
        from: { transform: 'translateX(0vw)' },
        config: { friction: 500, tension: 80, mass: 1 },
    });

    useEffect(() => {
        const timeout = setTimeout(() => setMove(true), 2000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={classes.wrapper}>
            <AnimatedIcon
                type="truck-arrive"
                className={classes.icon}
                style={styles}
            />
            <span className={classes.text}>
                Oh no! Seems like you&apos;re off course.
            </span>
            <span className={classes.smallText}>
                If you expected to find a page here, please contact the
                administrator.
            </span>
        </div>
    );
};

export default NotFound;
