import * as React from 'react';
import { useMemo, useState } from 'react';
import { addHours, format, startOfHour } from 'date-fns';
import { Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import useInterval from '../../../../hooks/useInterval';
import { Booking } from '../../../../models/bookings/Booking';
import { BookingType } from '../../../../models/bookings/BookingType';
import { TrailerTypeResource } from '../../../../models/bookings/StoreBookingResource';
import { useData } from '../../../../services/api';
import { theme } from '../../../../theme';

interface BookingSummaryChartProps {
    width: number;
    height: number;
    bookings: Booking[];
}

const BookingSummaryChart: React.FC<BookingSummaryChartProps> = ({
    width,
    height,
    bookings
}) => {
    const [trailerTypeResources, loadingTrailerTypeResources] = useData<
        TrailerTypeResource[]
    >(`TrailerTypeResource`);
    const [firstHour, setFirstHour] = useState<Date>(startOfHour(new Date()));

    useInterval(() => {
        setFirstHour(startOfHour(new Date()));
    }, 60000 /* every minute */);

    const bookingStats = useMemo(
        () =>
            Array.from({ length: 24 }, (_, k) => {
                const dateTimeToCompare = addHours(firstHour, k);
                return {
                    name: format(dateTimeToCompare, 'HH:00'),
                    points: (bookings || [])
                        .filter(
                            b =>
                                new Date(b.startDate) <= dateTimeToCompare &&
                                new Date(b.endDate) > dateTimeToCompare
                        )
                        .reduce((rv, curr) => {
                            if (!trailerTypeResources) {
                                return rv;
                            }
                            const trailerTypeResource = trailerTypeResources.find(
                                t =>
                                    t.bookingType === curr.bookingType &&
                                    t.store === curr.store &&
                                    t.trailerType === curr.trailerType
                            );
                            if (!trailerTypeResource) {
                                return rv;
                            }
                            if (
                                curr.bookingType === BookingType.Resource ||
                                curr.bookingType === BookingType.NoBookings
                            ) {
                                return rv;
                            }
                            return rv + trailerTypeResource.pointsPerLoad;
                        }, 0)
                };
            }),
        [firstHour, bookings, trailerTypeResources]
    );

    if (loadingTrailerTypeResources) {
        return null;
    }

    return (
        <BarChart width={width} height={height} data={bookingStats}>
            <XAxis
                dataKey="name"
                tick={false}
                axisLine={{ stroke: theme.colors.primary, strokeWidth: 0.8 }}
                height={1}
            />
            <YAxis domain={[0, 12]} hide />
            <Tooltip
                cursor={{ fill: theme.colors.lightestGrey }}
                content={({ label }) => (
                    <div style={{ backgroundColor: '#fff' }}>{label}</div>
                )}
            />
            <Bar dataKey="points" fill={theme.colors.primary} />
            <ReferenceLine
                y={12}
                stroke={theme.colors.error}
                strokeDasharray="3 3"
            />
        </BarChart>
    );
};

export default BookingSummaryChart;
