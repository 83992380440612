import { IodPlan } from '../models/iods/IodPlan';
import { Load } from '../models/loads/Load';
import { LoadGroup } from '../models/loads/LoadGroup';
import {
    getAllFinishingTrailerLoadsFromIodPlan,
    getAllGroupedTransferLoadsFromIodPlan,
    getSuccessorToLoadFromIodPlan
} from './iodPlanService';

export interface LoadRelationship {
    load: Load;
    relationship: 'finishing-trailer' | 'transfer';
    successor: Load;
}

const doesIodPlanMaintainRelationship = (
    iodPlan: IodPlan,
    loadRelationship: LoadRelationship
): boolean => {
    const loadRelationshipLoadId: string = loadRelationship.load.id;
    const loadRelationshipSuccessorId: string = loadRelationship.successor.id;

    switch (loadRelationship.relationship) {
        case 'finishing-trailer':
            return (
                (iodPlan.trip1.load.id === loadRelationshipLoadId &&
                    iodPlan.trip2?.load.id === loadRelationshipSuccessorId) ||
                (iodPlan.trip2?.load.id === loadRelationshipLoadId &&
                    iodPlan.trip3?.load.id === loadRelationshipSuccessorId)
            );
        case 'transfer':
            if (
                iodPlan.trip1.predecessorTransfers
                    .map(transfer => transfer.id)
                    .includes(loadRelationshipLoadId)
            )
                return iodPlan.trip1.load.id === loadRelationshipSuccessorId;
            if (
                iodPlan.trip2?.predecessorTransfers
                    .map(transfer => transfer.id)
                    .includes(loadRelationshipLoadId)
            )
                return iodPlan.trip2?.load.id === loadRelationshipSuccessorId;
            if (
                iodPlan.trip3?.predecessorTransfers
                    .map(transfer => transfer.id)
                    .includes(loadRelationshipLoadId)
            )
                return iodPlan.trip3?.load.id === loadRelationshipSuccessorId;
            return false;
        default:
            throw Error('Unrecognised Load Relationship Type.');
    }
};

export const doesIodPlanMaintainRelationships = (
    iodPlan: IodPlan,
    relationships: LoadRelationship[]
): boolean =>
    relationships.every(relationship =>
        doesIodPlanMaintainRelationship(iodPlan, relationship)
    );

const getRelationshipsForGroupedTransfers = (
    transferGroups: LoadGroup[],
    getSuccessorFromIodPlan: (load: Load) => Load
): LoadRelationship[] =>
    transferGroups.reduce<LoadRelationship[]>(
        (rv: LoadRelationship[], curr: Load[]) => [
            ...rv,
            ...curr.map(t => {
                const successorLoad: Load = getSuccessorFromIodPlan(t);
                return {
                    load: t,
                    relationship: 'transfer' as const,
                    successor: successorLoad
                };
            })
        ],
        []
    );

export const getLoadRelationships = (iodPlan: IodPlan): LoadRelationship[] => {
    const getSuccessorFromIodPlan = getSuccessorToLoadFromIodPlan(iodPlan);

    const iodPlanFinishingTrailerLoads: Load[] = getAllFinishingTrailerLoadsFromIodPlan(
        iodPlan
    );
    const iodPlanTransfers: LoadGroup[] = getAllGroupedTransferLoadsFromIodPlan(
        iodPlan
    );

    const groupsWithReleasedOrReleasingTransfers: LoadGroup[] = iodPlanTransfers.filter(
        storeTransfers => storeTransfers.some(t => t.hasBeenReleased)
    );

    let relationshipsToMaintain: LoadRelationship[] = getRelationshipsForGroupedTransfers(
        groupsWithReleasedOrReleasingTransfers,
        getSuccessorFromIodPlan
    );

    const groupsWithMultiIodStockTrailers: LoadGroup[] = iodPlanTransfers.filter(
        storeTransfers =>
            storeTransfers.some(
                t => t.isMergedStockTrailer || t.hasPiggybackedOrders
            )
    );

    relationshipsToMaintain = relationshipsToMaintain.concat(
        getRelationshipsForGroupedTransfers(
            groupsWithMultiIodStockTrailers,
            getSuccessorFromIodPlan
        )
    );

    // If there are multiple transfers from a store and some have been piggybacked,
    // must maintain same destination for both
    const groupsWithMultipleTransfersAndSomePiggybacked: LoadGroup[] = iodPlanTransfers.filter(
        storeTransfers =>
            storeTransfers.length > 1 &&
            storeTransfers.some(t => t.hasPiggybackedOrders)
    );

    relationshipsToMaintain = relationshipsToMaintain.concat(
        getRelationshipsForGroupedTransfers(
            groupsWithMultipleTransfersAndSomePiggybacked,
            getSuccessorFromIodPlan
        )
    );

    const piggybackedFinishingTrailerLoads: Load[] = iodPlanFinishingTrailerLoads.filter(
        load => load.hasPiggybackedOrders
    );

    relationshipsToMaintain = relationshipsToMaintain.concat(
        piggybackedFinishingTrailerLoads.map(transfer => {
            const successorLoad: Load = getSuccessorFromIodPlan(transfer);
            return {
                load: transfer,
                relationship: 'finishing-trailer',
                successor: successorLoad
            };
        })
    );

    return relationshipsToMaintain;
};
