import * as React from 'react';
import { useContext, useState } from 'react';

import BlockUnsavedChangesNavigation from '../../../components/common/BlockUnsavedChangesNavigation';
import SavingModal from '../../../components/common/SavingModal';
import SingleColumnRow from '../../../components/common/SingleColumnRow';
import UnsavedChangesModal from '../../../components/common/UnsavedChangesModal';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import Button from '../../../lib/bootstrap-ui/Button';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { BookingType } from '../../../models/bookings/BookingType';
import {
    StoreResource,
    TrailerTypeResource,
} from '../../../models/bookings/StoreBookingResource';
import { apiGet, apiPut } from '../../../services/api';
import { getStoreDropdownOptions } from '../../../services/storeService';
import { timeStringRegExp } from '../../../services/timeStringService';
import { useClasses } from './StoreResourceConfigContainer.styles';
import TrailerTypeConfigContainer from './TrailerTypeConfigContainer';

const StoreBookingConfigContainer: React.FC = () => {
    const classes = useClasses();

    const [selectedStore, setSelectedStore] = useState<number | null>(null);
    const [selectedBookingType, setSelectedBookingType] = useState<
        string | null
    >(null);
    const [storeResources, setStoreResources] = useState<
        StoreResource[] | null
    >(null);
    const [trailerTypeResources, setTrailerTypeResources] = useState<
        TrailerTypeResource[] | null
    >(null);
    const [loadingStoreResources, setLoadingStoreResources] = useState(false);
    const [loadingBookingType, setLoadingBookingType] = useState(false);
    const [trailerTypeResourceUpdated, setTrailerTypeResourceUpdated] =
        useState(false);
    const [storeResourceUpdated, setStoreResourceUpdated] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
        useState(false);
    const [showSavingModal, setShowSavingModal] = useState(false);
    const [storeToChangeTo, setStoreToChangeTo] = useState<number | null>(null);
    const [saved, setSaved] = useState(false);
    const [bookingTypeToChangeTo, setBookingTypeToChangeTo] = useState<
        string | null
    >(null);
    const { bookingTypes, stores } = useContext(LookupContext);

    const resetUpdatedFlags = () => {
        setStoreResourceUpdated(false);
        setTrailerTypeResourceUpdated(false);
        setStoreToChangeTo(null);
        setBookingTypeToChangeTo(null);
        setSaved(true);
        setTimeout(function () {
            setShowSavingModal(false);
        }, 1000);
    };

    const fetchStoreResourcesForStore = async (store: number) => {
        setLoadingStoreResources(true);
        const newStoreResources = await apiGet(`Store/${store}/Resources`);
        setLoadingStoreResources(false);

        setStoreResources(newStoreResources);
        setTrailerTypeResources(null);
        setSelectedBookingType(null);
    };

    const handleStoreChange = (newSelectedStore: number) => {
        setSelectedStore(newSelectedStore);
        fetchStoreResourcesForStore(newSelectedStore);
        resetUpdatedFlags();
    };

    const requestStoreChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const newSelectedStore = parseInt(event.target.value, 10);
        if (storeResourceUpdated || trailerTypeResourceUpdated) {
            setStoreToChangeTo(newSelectedStore);
            setShowUnsavedChangesModal(true);
        } else {
            handleStoreChange(newSelectedStore);
        }
    };

    const handleBookingTypeChange = async (newBookingType: string) => {
        setSelectedBookingType(newBookingType);

        setLoadingBookingType(true);
        const newTrailerTypeResources = await apiGet(
            `Store/${selectedStore}/BookingType/${newBookingType}/TrailerTypeResources`
        );
        setLoadingBookingType(false);
        setTrailerTypeResources(newTrailerTypeResources);
        setTrailerTypeResourceUpdated(false);
    };

    const requestBookingTypeChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const newSelectedBookingType = event.target.value;
        if (trailerTypeResourceUpdated) {
            setBookingTypeToChangeTo(newSelectedBookingType);
            setShowUnsavedChangesModal(true);
        } else {
            await handleBookingTypeChange(newSelectedBookingType);
        }
    };

    const handleTrailerResourceChange = (
        trailerResource: TrailerTypeResource
    ) => {
        if (trailerTypeResources) {
            const editedTrailerTypeResources = [...trailerTypeResources].map(
                (resource) => {
                    if (
                        resource.trailerType === trailerResource.trailerType &&
                        (!trailerResource.secondaryTrailerType ||
                            resource.secondaryTrailerType ===
                                trailerResource.secondaryTrailerType)
                    ) {
                        resource = trailerResource;
                    }
                    return resource;
                }
            );
            setTrailerTypeResources(editedTrailerTypeResources);
            setTrailerTypeResourceUpdated(true);
        }
    };

    const handleStoreResourceChange = (
        value: string | null,
        storeResource: StoreResource
    ) => {
        if (storeResources) {
            const resourceAmount = !!value ? parseInt(value, 10) : null;
            const editedStoreResources = [...storeResources].map((resource) => {
                if (resource.resource === storeResource.resource) {
                    resource.amount = resourceAmount;
                }
                return resource;
            });

            setStoreResources(editedStoreResources);
            setStoreResourceUpdated(true);
        }
    };

    const isStoreResourceValid = (storeResource: StoreResource) =>
        storeResource.amount !== null;

    const isTrailerTypeResourceValid = (
        trailerTypeResource: TrailerTypeResource
    ) =>
        trailerTypeResource.hourDuration !== null &&
        trailerTypeResource.hourDuration > 0 &&
        trailerTypeResource.pointsPerLoad !== null &&
        trailerTypeResource.pointsPerLoad >= 0 &&
        trailerTypeResource.pointsPerLoad <= 12 &&
        timeStringRegExp.test(trailerTypeResource.weekdayStartTime) &&
        timeStringRegExp.test(trailerTypeResource.weekdayEndTime) &&
        timeStringRegExp.test(trailerTypeResource.saturdayStartTime) &&
        timeStringRegExp.test(trailerTypeResource.saturdayEndTime) &&
        timeStringRegExp.test(trailerTypeResource.sundayStartTime) &&
        timeStringRegExp.test(trailerTypeResource.sundayEndTime);

    const isFormValid = () =>
        storeResources !== null &&
        storeResources.every((resource) => isStoreResourceValid(resource)) &&
        (trailerTypeResources === null ||
            (trailerTypeResources !== null &&
                trailerTypeResources
                    .filter((resource) => resource.active)
                    .every((resource) =>
                        isTrailerTypeResourceValid(resource)
                    )));

    const handleSaveClicked = async () => {
        setSaved(false);
        setShowSavingModal(true);
        if (storeResourceUpdated && storeResources) {
            await apiPut('StoreResource', storeResources);
        }

        if (trailerTypeResourceUpdated && trailerTypeResources) {
            await apiPut(`TrailerTypeResource`, trailerTypeResources);
        }
        resetUpdatedFlags();
    };

    const handleDiscardChanges = () => {
        if (storeToChangeTo) {
            handleStoreChange(storeToChangeTo);
        } else if (bookingTypeToChangeTo) {
            handleBookingTypeChange(bookingTypeToChangeTo);
        }
        setShowUnsavedChangesModal(false);
    };

    const handleGoBackAndClose = () => {
        setShowUnsavedChangesModal(false);
        setStoreToChangeTo(null);
        setBookingTypeToChangeTo(null);
    };

    const onClose = () => {
        setShowUnsavedChangesModal(false);
    };

    return (
        <div>
            <Grid fluid>
                <SingleColumnRow
                    rowJustifyContent="left"
                    className={classes.storeSelector}
                >
                    <SelectDropdown
                        className={classes.storeSelectorDropdown}
                        id="StoreSelect"
                        defaultText="Select Store..."
                        dropdownOptions={getStoreDropdownOptions(
                            stores.map(String)
                        )}
                        label="Store:"
                        selectedOption={
                            selectedStore ? String(selectedStore) : null
                        }
                        onChange={requestStoreChange}
                    />
                </SingleColumnRow>
                {storeResources && !loadingStoreResources && (
                    <>
                        <SingleColumnRow
                            rowJustifyContent="center"
                            className={classes.storeResourceEditSection}
                        >
                            <SingleColumnRow>
                                <h2>{`Store ${selectedStore}`}</h2>
                            </SingleColumnRow>
                            <SingleColumnRow>
                                <h5>Total Store Resources:</h5>
                            </SingleColumnRow>
                            <Row>
                                {storeResources.map((storeResource, i) => (
                                    <Column key={i} size={3}>
                                        <TextInput
                                            label={
                                                storeResource.resource ===
                                                'Delivery'
                                                    ? 'Deliveries: '
                                                    : `${storeResource.resource}s: `
                                            }
                                            size="small"
                                            type="number"
                                            minValue={0}
                                            error={
                                                storeResource.amount === null ||
                                                storeResource.amount < 0
                                                    ? 'Must be equal to or greater than 0'
                                                    : null
                                            }
                                            value={
                                                storeResource.amount !== null
                                                    ? storeResource.amount
                                                    : ''
                                            }
                                            onChange={(event) =>
                                                handleStoreResourceChange(
                                                    event,
                                                    storeResource
                                                )
                                            }
                                            highlightOnFocus
                                        />
                                    </Column>
                                ))}
                            </Row>
                            <SingleColumnRow columnSize={3}>
                                <SelectDropdown
                                    defaultText="Select..."
                                    dropdownOptions={bookingTypes
                                        .filter(
                                            (bt) =>
                                                bt !== BookingType.Resource &&
                                                bt !== BookingType.NoBookings
                                        )
                                        .map((b) => ({
                                            label: b,
                                            value: b,
                                        }))}
                                    label="Booking Type:"
                                    selectedOption={selectedBookingType || null}
                                    onChange={requestBookingTypeChange}
                                />
                            </SingleColumnRow>
                            {trailerTypeResources && (
                                <>
                                    <hr />
                                    <TrailerTypeConfigContainer
                                        loading={loadingBookingType}
                                        selectedBookingType={
                                            selectedBookingType as BookingType
                                        }
                                        trailerTypeResources={
                                            trailerTypeResources
                                        }
                                        onTrailerResourceChange={
                                            handleTrailerResourceChange
                                        }
                                    />
                                </>
                            )}
                        </SingleColumnRow>
                        <div className={classes.footerRow}>
                            <Button
                                className={classes.saveButton}
                                disabled={
                                    !isFormValid() ||
                                    (!storeResourceUpdated &&
                                        !trailerTypeResourceUpdated)
                                }
                                onClick={handleSaveClicked}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </>
                )}
                {loadingStoreResources && (
                    <LoadingIndicator text="loading store resources" />
                )}
                {loadingBookingType && (
                    <LoadingIndicator text="loading booking type resources" />
                )}
            </Grid>
            <UnsavedChangesModal
                isOpen={showUnsavedChangesModal}
                onRequestDiscardChanges={handleDiscardChanges}
                onRequestClose={handleGoBackAndClose}
                onRequestGoBack={handleGoBackAndClose}
            />
            <BlockUnsavedChangesNavigation
                blockNavigation={
                    storeResourceUpdated || trailerTypeResourceUpdated
                }
                onClose={onClose}
            />
            <SavingModal isOpen={showSavingModal} saved={saved} />
        </div>
    );
};

export default StoreBookingConfigContainer;
