import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    errorMessageContainer: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    errorMessage: {
        color: theme.colors.error,
        marginBottom: 0,
        marginTop: '1rem',
    },
}));
