import * as React from 'react';
import { useState, useEffect } from 'react';

let callbacks: {
    id: number;
    callback: (nodes: React.ReactNode[]) => void;
}[] = [];

let id = 0;

let currentAlertId = 0;

let alerts = [] as { id: number; node: React.ReactNode }[];

const addCallback = (callback: (nodes: React.ReactNode[]) => void): number => {
    id++;
    callbacks.push({ id, callback });
    callback(alerts.map((a) => a.node));
    return id;
};

const removeCallback = (alertId: number) => {
    callbacks = callbacks.filter((cb) => cb.id !== alertId);
};

const notify = () => {
    callbacks.forEach((cb) => cb.callback(alerts.map((a) => a.node)));
};

const alert = (
    node: React.ReactNode | ((id: number) => React.ReactNode)
): number => {
    currentAlertId++;
    alerts = [
        ...alerts,
        {
            id: currentAlertId,
            node:
                typeof node === 'function'
                    ? (node as (id: number) => React.ReactNode)(currentAlertId)
                    : node,
        },
    ];
    notify();
    return currentAlertId;
};

const removeAlert = (alertId: number) => {
    alerts = alerts.filter((a) => a.id !== alertId);
    notify();
};

const AlertContainer: React.FC = () => {
    const [currentAlerts, setCurrentAlerts] = useState([] as React.ReactNode[]);

    useEffect(() => {
        const callbackId = addCallback(setCurrentAlerts);
        return () => {
            removeCallback(callbackId);
        };
    }, []);

    if (currentAlerts.length === 0) {
        return null;
    }

    return (
        <div>
            {currentAlerts.map((a, i) => (
                <React.Fragment key={i}>{a}</React.Fragment>
            ))}
        </div>
    );
};

export { alert, removeAlert };

export default AlertContainer;
