import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    recurringBookingTableContainer: {
        height: '100%',
    },
    recurringBookingTable: {
        '& thead th': {
            position: 'sticky',
            zIndex: 2,
            backgroundColor: theme.colors.white,
            top: '4.5rem',
        },
    },
}));
