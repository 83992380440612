import { format } from 'date-fns';
import * as React from 'react';
import { buildClassesHook } from '../../../helpers/styles';
import TimeSlot from '../../../models/timeSlot/TimeSlot';

const useClasses = buildClassesHook({
    conflictsList: {
        overflowY: 'auto',
        paddingLeft: '1rem',
    },
    conflictsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    conflictsLabel: {
        margin: '1rem 0',
    },
});

interface TimeSlotConflictsListProps {
    label: string;
    conflictTimeSlots: TimeSlot[];
    listMaxHeight?: string | number;
}

const TimeSlotConflictsList: React.FC<TimeSlotConflictsListProps> = ({
    label,
    conflictTimeSlots,
    listMaxHeight,
}) => {
    const classes = useClasses();

    return (
        <div className={classes.conflictsContainer}>
            <p className={classes.conflictsLabel}>{label}</p>
            <div
                className={classes.conflictsList}
                style={{ maxHeight: listMaxHeight }}
            >
                {conflictTimeSlots.map((c, i) => (
                    <li key={i}>{format(new Date(c.start), 'dd/MM/yyyy')}</li>
                ))}
            </div>
        </div>
    );
};

export default TimeSlotConflictsList;
