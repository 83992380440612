import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    formContainer: {
        padding: '2em 2em',
        borderRadius: '1em',
        maxWidth: 800,
    },
    selectorRow: {
        position: 'sticky',
        top: '4.5rem',
        backgroundColor: theme.colors.white,
        zIndex: 2,
    },
}));
