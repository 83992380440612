import { formatISO, parseISO } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { EventType, PopupPosition } from 'reactjs-popup/dist/types';
import { formatDate } from '../../../helpers/dates';
import { TextInput } from '../../../lib/bootstrap-ui/Forms';
import { datePickerTooltip, useClasses } from './DatePickerInput.styles';
import DatePicker from './index';

interface DatePickerInput {
    inputLabel?: string;
    inputPlaceholder?: string;
    triggerAction?: EventType | EventType[];
    popupPosition: PopupPosition | PopupPosition[];
    isOpen?: boolean;
    error?: string;
    hideBottomMargin?: boolean;
    value: Date | null;
    onDateSelected(selectedDate: Date | null): void;
    onOpen?(): void;
    onClose?(): void;
}

const isValidDate = (date: Date | null) =>
    !!date && date >= new Date('1900-01-01');

const DatePickerInput: React.FC<DatePickerInput> = ({
    inputLabel,
    isOpen,
    error,
    inputPlaceholder,
    popupPosition,
    triggerAction = 'click',
    hideBottomMargin = false,
    value,
    onDateSelected,
    onOpen,
    onClose,
}) => {
    const classes = useClasses();

    const [dateInputValue, setDateInputValue] = useState<string | null>(
        value ? formatISO(value) : null
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
        }

        if (event.key === 'Escape') {
            event.preventDefault();
            isOpen && event.stopPropagation();
            onClose && onClose();
        }
    };

    useEffect(() => {
        const selectedDate = dateInputValue ? parseISO(dateInputValue) : null;
        if (isValidDate(selectedDate)) {
            onDateSelected(new Date(formatISO(selectedDate!)));
        }
        //eslint-disable-next-line
    }, [dateInputValue]);

    const handleDatePickerValueChange = (selectedDate: Date | null) => {
        setDateInputValue(selectedDate ? formatISO(selectedDate) : null);
    };

    const getErrorText = (): string | null | undefined => {
        if (isOpen) {
            return;
        }

        return (
            error ||
            (dateInputValue &&
            !isValidDate(dateInputValue ? new Date(dateInputValue) : null)
                ? 'Date must be after 01/01/1900'
                : null)
        );
    };

    return (
        <>
            <Popup
                trigger={
                    <div>
                        <TextInput
                            autoConvertWhitespace
                            clearable
                            className={classes.datePickerTextInput}
                            type="date"
                            size="small"
                            label={inputLabel}
                            placeholder={inputPlaceholder}
                            value={
                                dateInputValue &&
                                formatDate(dateInputValue, 'yyyy-MM-dd')
                            }
                            error={getErrorText()}
                            hideBottomPadding={hideBottomMargin}
                            onChange={setDateInputValue}
                            onClear={() => handleDatePickerValueChange(null)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                }
                contentStyle={datePickerTooltip}
                open={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                on={triggerAction}
                position={popupPosition}
                keepTooltipInside
                closeOnDocumentClick
                nested
            >
                <DatePicker
                    selectedDay={value}
                    onDateSelected={handleDatePickerValueChange}
                />
            </Popup>
        </>
    );
};

export default DatePickerInput;
