import classnames from 'classnames';
import * as React from 'react';
import { useClasses } from './Center.styles';

interface CenterProps extends React.HTMLAttributes<HTMLDivElement> {
    direction?: 'column' | 'row';
}

const Center: React.FC<React.PropsWithChildren<CenterProps>> = ({
    className,
    children,
    direction = 'column',
    ...otherProps
}) => {
    const classes = useClasses();

    return (
        <div
            className={classnames(classes.verticallyCenter, className)}
            style={{ flexDirection: direction }}
            {...otherProps}
        >
            {children}
        </div>
    );
};

export default Center;
