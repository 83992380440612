import * as React from 'react';
import { useContext } from 'react';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { FormStepProps } from '../../../models/bookings/BookingFormStep';
import { CreateBooking } from '../../../models/bookings/BookingToAdd';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { getDefaultBookingTrailerType } from '../../../services/bookingService';
import { getStoreDropdownOptions } from '../../../services/storeService';
import { userToAllStores } from '../../../services/userService';
import { useCurrentUser } from '../../../services/authentication';

const BookingFormStoreContainer: React.FC<FormStepProps<CreateBooking>> = ({
    formObject: booking,
    onChange: onBookingChange,
}) => {
    const { stores } = useContext(LookupContext);

    const user = useCurrentUser();

    const availableStores = !user ? [1] : userToAllStores(user);

    const handleStoreChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const store = parseInt(event.target.value) as StoreNumber;

        const editedBooking: CreateBooking = {
            ...booking,
            orders: [],
            resources: [],
            duration: 0,
            store,
            trailerType: getDefaultBookingTrailerType(booking.bookingType),
            selectedTimeSlot: null,
            timeSlotOverrideRequested: false,
            overrideStoreResource: false,
        };
        onBookingChange(editedBooking);
    };

    const storeDropdownOptions = getStoreDropdownOptions(
        stores.map(String)
    ).filter((s) => availableStores.includes(s.value));

    return (
        <Row>
            <Column>
                <SelectDropdown
                    id="StoreSelect"
                    defaultText="Select Store..."
                    dropdownOptions={storeDropdownOptions}
                    label="Store:"
                    selectedOption={String(booking.store)}
                    onChange={handleStoreChange}
                />
            </Column>
        </Row>
    );
};

export default BookingFormStoreContainer;
