import { differenceInDays } from 'date-fns';
import * as React from 'react';
import {
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';

import AddBookingTypeSelectionModal from '../../../components/bookings/modals/AddBookingTypeSelectionModal';
import BookingTableFilterModal from '../../../components/bookings/modals/BookingTableFilterModal';
import { DateRange } from '../../../components/common/DateRangePills';
import ErrorModal from '../../../components/common/ErrorModal';
import HeaderContainer from '../../../components/common/HeaderContainer';
import Pagination from '../../../components/common/Pagination';
import { StoreSelectorOption } from '../../../components/common/StoreSelectorPills';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import bookingTableReducer, {
    BookingTableReducerAction,
} from '../../../helpers/bookingTableReducer';
import { UserRole } from '../../../helpers/userRole';
import useInterval from '../../../hooks/useInterval';
import usePersistedState from '../../../hooks/usePersistedState';
import { useBreakpoint } from '../../../hooks/useWindowSize';
import { Booking } from '../../../models/bookings/Booking';
import BookingFilter from '../../../models/bookings/BookingFilter';
import { BookingStatus } from '../../../models/bookings/BookingStatus';
import DateInterval from '../../../models/common/DateInterval';
import FilterResults from '../../../models/common/FilterResults';
import { BookingUpdated } from '../../../models/notifications/Booking';
import { RecurringBookingUpdated } from '../../../models/notifications/RecurringBooking';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { apiGet } from '../../../services/api';
import { useCurrentUser } from '../../../services/authentication';
import {
    BOOKING_TABLE_PAGE_SIZE,
    getBookingsEndDateFromDateRange,
    sortBookingsByStartDate,
} from '../../../services/bookingService';
import { formatToUtc } from '../../../services/timeStringService';
import {
    userInRole,
    userIsAdmin,
    userToAllStores,
} from '../../../services/userService';
import BookingTableContainer from '../view/modal/BookingTableContainer';
import { useClasses } from './BookingContainer.styles';
import BookingTableActionContainer, {
    Action,
} from './BookingTableActionContainer';

export type ModalType = 'add' | 'filter' | 'error';

const BookingContainer: React.FC = () => {
    const classes = useClasses();

    const { bookingTypes } = useContext(LookupContext);

    const user = useCurrentUser();
    const userIsGatehouse = userInRole(user)(UserRole.Gatehouse);

    const storeOptions: StoreSelectorOption[] = !user
        ? []
        : [...userToAllStores(user), 'All'];
    const allowAllStoreActions = userIsAdmin(user) || userIsGatehouse;
    const showAddBookingButtons = !userIsGatehouse;
    const showRecurringBookingNav = !userIsGatehouse;

    const [selectedStoreOption, setSelectedStoreOption] =
        usePersistedState<StoreSelectorOption>(
            storeOptions[0],
            'booking-store'
        );
    const [selectedDateRange, setSelectedDateRange] =
        usePersistedState<DateRange>('Day', 'booking-date-range');

    const [modalToDisplay, setModalToDisplay] = useState<ModalType | null>(
        null
    );

    const [refreshingBookings, setRefreshingBookings] = useState(false);

    const isLGWidthOrAbove = useBreakpoint('lg');
    const [filteredBookings, setFilteredBookings] =
        useState<FilterResults<Booking> | null>(null);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);

    const [bookingFilter, setBookingFilter] = useState<BookingFilter>({
        bookingTypes: null,
        customerName: null,
        reference: null,
        orderNumber: null,
        productOrSupplierCode: null,
        resources: null,
        startDate: null,
        endDate: getBookingsEndDateFromDateRange(selectedDateRange),
    });

    const filterStartDate = bookingFilter.startDate;
    const filterEndDate = bookingFilter.endDate;

    const totalResults = filteredBookings?.totalResultCount || 0;

    const currentPage =
        (offset + BOOKING_TABLE_PAGE_SIZE) / BOOKING_TABLE_PAGE_SIZE;
    const totalPages = Math.ceil(totalResults / BOOKING_TABLE_PAGE_SIZE);

    const url =
        selectedStoreOption !== 'All'
            ? `Store/${selectedStoreOption}/Bookings/Active`
            : `Bookings/Active`;

    const [bookings, dispatchBookings] = useReducer(bookingTableReducer, []);

    const sortedBookings = useMemo(() => {
        return bookings
            .sort(sortBookingsByStartDate)
            .slice(0, BOOKING_TABLE_PAGE_SIZE);
    }, [bookings]);

    const lastActiveRequestController = useRef<AbortController | null>();

    const fetchFilteredBookings = async () => {
        setLoading(true);

        if (lastActiveRequestController.current) {
            lastActiveRequestController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        lastActiveRequestController.current = controller;

        const filteredBookingsResult: FilterResults<Booking> = await apiGet(
            url,
            {
                bookingTypes: bookingFilter.bookingTypes,
                customerName: bookingFilter.customerName,
                reference: bookingFilter.reference,
                orderNumber: bookingFilter.orderNumber,
                productOrSupplierCode: bookingFilter.productOrSupplierCode,
                resources: bookingFilter.resources,
                startDate: bookingFilter.startDate
                    ? formatToUtc(bookingFilter.startDate)
                    : null,
                endDate: bookingFilter.endDate
                    ? formatToUtc(bookingFilter.endDate)
                    : null,
                values: BOOKING_TABLE_PAGE_SIZE,
                offset: offset,
            },
            { signal }
        ).catch((err) => {
            // Avoid showing an error message if the fetch was aborted
            if (err.name !== 'AbortError') {
                setModalToDisplay('error');
                setLoading(false);
            }
        });
        if (!signal.aborted) {
            if (filteredBookingsResult) {
                setFilteredBookings(filteredBookingsResult);
                dispatchBookings({
                    bookings: filteredBookingsResult.resultsToShow,
                    action: 'Replace',
                    filter: bookingFilter,
                    currentPage,
                });
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (bookingFilter.bookingTypes) {
            fetchFilteredBookings();
        }
        // eslint-disable-next-line
    }, [url, bookingFilter, offset]);

    useEffect(() => {
        if (filterStartDate && filterEndDate) {
            const differenceInDates = Math.abs(
                differenceInDays(filterStartDate, filterEndDate)
            );
            if (differenceInDates <= 1) {
                setSelectedDateRange('Day');
            } else if (differenceInDates <= 7) {
                setSelectedDateRange('Week');
            } else if (differenceInDates <= 31) {
                setSelectedDateRange('Month');
            } else {
                setSelectedDateRange('All');
            }
        }
        //eslint-disable-next-line
    }, [filterStartDate, filterEndDate]);

    const resetModalToDisplay = () => {
        setModalToDisplay(null);
    };

    const refreshBookings = async () => {
        setRefreshingBookings(true);
        await fetchFilteredBookings();
        setRefreshingBookings(false);
    };

    useInterval(() => {
        refreshBookings();
    }, 3600000 /* 1 hour */);

    useEffect(() => {
        if (bookingTypes.length > 0) {
            setBookingFilter((filter) => ({
                ...filter,
                bookingTypes: bookingTypes,
            }));
        }
    }, [bookingTypes]);

    const resetPageNumber = () => {
        setOffset(0);
    };

    const handleStoreChange = (storeNumber: StoreNumber) => {
        resetPageNumber();
        setSelectedStoreOption(storeNumber);
    };

    const handleDateRangeChange = (
        dateRange: DateRange,
        dateInterval: DateInterval | null
    ) => {
        resetPageNumber();
        setSelectedDateRange(dateRange);
        setBookingFilter((prev) => ({
            ...prev,
            startDate: dateInterval?.start || null,
            endDate: dateInterval?.end || null,
            startDateFilteredApplied: false,
        }));
    };

    const handleFilterReset = () => {
        resetPageNumber();
        setBookingFilter((prev) => ({
            ...prev,
            bookingTypes: bookingTypes,
            customerName: null,
            reference: null,
            orderNumber: null,
            productOrSupplierCode: null,
            resources: null,
            startDate: null,
            endDate: getBookingsEndDateFromDateRange(selectedDateRange),
            startDateFilteredApplied: false,
        }));
    };

    const handleFilterChange = (newFilter: BookingFilter) => {
        resetPageNumber();
        setBookingFilter(newFilter);
        resetModalToDisplay();
    };

    const handleBookingUpdate = (bookingUpdate: BookingUpdated) => {
        refreshBookings();
        let action: BookingTableReducerAction;
        switch (bookingUpdate.action) {
            case 'Add':
                action = 'Add';
                break;
            case 'Cancel':
            case 'Complete':
                action = 'Remove';
                break;
            default:
                action = 'Update';
        }
        dispatchBookings({
            bookings: [bookingUpdate.updatedBooking],
            action,
            filter: bookingFilter,
            currentPage,
        });
    };

    const handleRecurringBookingUpdate = (
        recurringBookingUpdate: RecurringBookingUpdated
    ) => {
        refreshBookings();
        const recurringBooking = recurringBookingUpdate.updatedRecurringBooking;
        if (recurringBookingUpdate.action === 'Update') {
            const bookingsToUpdate = sortedBookings.filter(
                (b) =>
                    b.recurringBooking?.id === recurringBooking.id &&
                    b.status === BookingStatus.AwaitingHaulier
            );
            const updatedBookings = bookingsToUpdate.map((b) => {
                return {
                    ...b,
                    customerName: recurringBooking.customerName,
                    bookingInstructions: recurringBooking.bookingInstructions,
                };
            });
            dispatchBookings({
                bookings: updatedBookings,
                action: 'Update',
                filter: bookingFilter,
                currentPage,
            });
        }
    };

    const activeFilters =
        !!bookingFilter.reference ||
        !!bookingFilter.customerName ||
        !!bookingFilter.orderNumber ||
        !!bookingFilter.productOrSupplierCode ||
        !!bookingFilter.startDateFilteredApplied ||
        (!!bookingFilter.bookingTypes &&
            bookingFilter.bookingTypes.length !== bookingTypes.length) ||
        (!!bookingFilter.resources && bookingFilter.resources.length > 0);

    const showBookingResourceCharts =
        !!bookings &&
        selectedStoreOption !== 'All' &&
        selectedDateRange === 'Day';

    const showStoreActions =
        allowAllStoreActions ||
        (selectedStoreOption !== 'All' &&
            storeOptions.includes(selectedStoreOption));

    const getBookingActions = () => {
        let actions: Action[] = [
            { title: 'dateSelector' },
            { title: 'storeSelector' },
            { title: 'filters', disabled: !bookingFilter.bookingTypes },
        ];

        if (showAddBookingButtons) {
            actions = [...actions, { title: 'add' }];
        }

        if (showBookingResourceCharts) {
            actions = [...actions, { title: 'charts' }];
        }

        if (showRecurringBookingNav && showStoreActions) {
            actions = [...actions, { title: 'recurringBookingNav' }];
        }

        if (showStoreActions) {
            actions = [...actions, { title: 'historicalBookingNav' }];
        }

        return actions;
    };

    return (
        <div className={classes.bookingContainer}>
            <HeaderContainer headerText="Bookings">
                <BookingTableActionContainer
                    className={classes.actionContainer}
                    bookings={sortedBookings || []}
                    actionsToDisplay={getBookingActions()}
                    activeFilters={activeFilters}
                    dateRange={selectedDateRange}
                    storeOption={selectedStoreOption}
                    storeOptions={storeOptions}
                    onStoreOptionChange={handleStoreChange}
                    onDateRangeChange={handleDateRangeChange}
                    onAddBookingClicked={() => setModalToDisplay('add')}
                    onFilterClicked={() => setModalToDisplay('filter')}
                    onFilterReset={handleFilterReset}
                />
            </HeaderContainer>
            <BookingTableContainer
                className={classes.bookingTableContainer}
                bookingTableClassName={classes.bookingTable}
                bookings={sortedBookings || null}
                loading={loading}
                onRefresh={refreshBookings}
                onRecurringBookingUpdate={handleRecurringBookingUpdate}
                onBookingUpdate={handleBookingUpdate}
                refreshingBookings={refreshingBookings}
                showAction={showStoreActions}
                showStore={selectedStoreOption === 'All'}
                selectedStoreOption={selectedStoreOption}
            />
            {sortedBookings && totalResults > BOOKING_TABLE_PAGE_SIZE && (
                <div className={classes.pageSelectionContainer}>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageLinkClick={(pageNumber: number) =>
                            setOffset(
                                (pageNumber - 1) * BOOKING_TABLE_PAGE_SIZE
                            )
                        }
                    />
                </div>
            )}

            {modalToDisplay === 'filter' && (
                <BookingTableFilterModal
                    showModal={modalToDisplay === 'filter'}
                    selectedStoreOption={selectedStoreOption}
                    showStoreSelector={!isLGWidthOrAbove}
                    filter={bookingFilter}
                    onApplyFilter={handleFilterChange}
                    onStoreOptionChange={setSelectedStoreOption}
                    onClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'add' && (
                <AddBookingTypeSelectionModal
                    open={modalToDisplay === 'add'}
                    onRequestClose={resetModalToDisplay}
                />
            )}
            {modalToDisplay === 'error' && (
                <ErrorModal
                    showModal={modalToDisplay === 'error'}
                    errorText="There was an error retrieving the list of bookings, please try refreshing the page. If this error continues, please contact IT."
                    onClose={resetModalToDisplay}
                />
            )}
        </div>
    );
};

export default BookingContainer;
