import * as React from 'react';
import Button from '../../../../lib/bootstrap-ui/Button';
import { Load } from '../../../../models/loads/Load';
import Icon from '../../../common/Icon';
import { useClasses } from './ContactNumberCell.styles';

interface ContactNumberCellProps {
    className?: string;
    contactNumber: string | null;
    previousContactNumber: string | null;
    load: Load;
    userCanEdit: boolean;
    onEditContactNumberClick(): void;
    onSendSmsClick(): void;
}

const ContactNumberCell: React.FC<ContactNumberCellProps> = ({
    className,
    contactNumber,
    load,
    userCanEdit,
    onEditContactNumberClick,
    onSendSmsClick,
}) => {
    const classes = useClasses();

    const showSendSmsButton = userCanEdit && contactNumber && load.isThirdParty;
    const showEditContactNumberButton =
        userCanEdit &&
        load.isThirdParty &&
        (load.isReleased ||
            load.status === 'Ready to Release' ||
            load.status === 'Awaiting Bay');

    return (
        <td className={className}>
            <div className={classes.contactNumberCellButtonWrapper}>
                {showSendSmsButton && (
                    <Button
                        className={classes.contactNumberCellButton}
                        style={{
                            visibility: showSendSmsButton
                                ? 'visible'
                                : 'hidden',
                        }}
                        element="button"
                        styleType="link"
                        stopOnClickPropagation
                        onClick={onSendSmsClick}
                    >
                        <Icon
                            className={classes.contactNumberCellSMSIcon}
                            type={'sms-message'}
                        />
                    </Button>
                )}
                {showEditContactNumberButton && (
                    <Button
                        className={classes.contactNumberCellButton}
                        style={{
                            visibility: showEditContactNumberButton
                                ? 'visible'
                                : 'hidden',
                        }}
                        element="button"
                        styleType="link"
                        stopOnClickPropagation
                        onClick={onEditContactNumberClick}
                    >
                        <Icon
                            className={classes.contactNumberCellIcon}
                            type="pencil"
                        />
                    </Button>
                )}
            </div>
        </td>
    );
};

export default ContactNumberCell;
