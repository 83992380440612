import { ColdStoreOrder } from '../models/orders/ColdStoreOrder';

const getColdStoreOrderText = (order: ColdStoreOrder) =>
    `${order.orderNumber}${
        order.customerOrderNumber &&
        order.customerOrderNumber.length &&
        order.orderNumber !== order.customerOrderNumber
            ? ` (${order.customerOrderNumber})`
            : ''
    }`;

export { getColdStoreOrderText };
