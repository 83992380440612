import { differenceInHours } from 'date-fns';
import * as React from 'react';
import RescheduleTimeSlotContainer from '../../../containers/bookings/RescheduleTimeSlotContainer';
import { buildClassesHook } from '../../../helpers/styles';
import { UserRole } from '../../../helpers/userRole';
import Modal, { ModalBody, ModalHeader } from '../../../lib/bootstrap-ui/Modal';
import { BookingType } from '../../../models/bookings/BookingType';
import { LoadingResource } from '../../../models/bookings/LoadingResource';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import TimeSlot from '../../../models/timeSlot/TimeSlot';
import { TrailerType } from '../../../models/trailers/TrailerType';
import { useAuth } from '../../../services/authentication';
import { userInRole, userIsAdmin } from '../../../services/userService';

const useClasses = buildClassesHook({
    rescheduleModal: {
        width: '80%',
        maxWidth: '650px',
        maxHeight: '80vh',
    },
    rescheduleModalHeader: {
        padding: '0 1rem',
        border: 'none',
    },
});

interface RescheduleModalProps {
    startDate: Date;
    endDate: Date;
    bookingType: BookingType;
    excludedBookingIds: string[];
    resources: LoadingResource[];
    store: StoreNumber;
    trailerType: TrailerType;
    showModal: boolean;
    onTimeSlotSelected(timeSlot: TimeSlot): void;
    onClose(): void;
}

const RescheduleModal: React.FC<RescheduleModalProps> = ({
    startDate,
    endDate,
    bookingType,
    excludedBookingIds,
    resources,
    store,
    trailerType,
    showModal,
    onTimeSlotSelected,
    onClose,
}) => {
    const classes = useClasses();

    const {
        authState: { loggedIn, user },
    } = useAuth();

    const allowOverrideFullTimeSlots =
        loggedIn &&
        (userIsAdmin(user) || userInRole(user)(UserRole.ShiftManager));

    const bookingTimeSlot: TimeSlot = {
        start: startDate,
        end: endDate,
        isAvailable: true,
        unavailableReasons: ['No Reason'],
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={onClose}
            modalClassName={classes.rescheduleModal}
        >
            <ModalHeader
                className={classes.rescheduleModalHeader}
                showCloseButton
                onCloseButtonClick={onClose}
            />
            <ModalBody>
                <RescheduleTimeSlotContainer
                    allowOverrideFullTimeSlots={allowOverrideFullTimeSlots}
                    bookingType={bookingType}
                    duration={differenceInHours(endDate, startDate)}
                    excludedBookingIds={excludedBookingIds}
                    resources={resources}
                    selectedTimeSlot={bookingTimeSlot}
                    store={store}
                    trailerType={trailerType}
                    onTimeSlotSelected={onTimeSlotSelected}
                />
            </ModalBody>
        </Modal>
    );
};

export default RescheduleModal;
