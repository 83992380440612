import * as React from 'react';
import { Link } from 'react-router-dom';
import { routeFor, RouteKey } from '../../Routes/routes';
import { Load } from '../../models/loads/Load';

interface IodDetailsLinkProps {
    load: Load;
}

const IodDetailsLink: React.FC<IodDetailsLinkProps> = ({ load }) => (
    <>
        {!!load.associatedIodNumber && (
            <Link
                to={
                    load.isMergedStockTrailer || load.isNoIodStockTrailer
                        ? routeFor(RouteKey.StockTrailerDetails)({
                              loadId: load.id
                          })
                        : `${routeFor(RouteKey.IodDetails)({
                              iodNumber: load.associatedIodNumber
                          })}?selectedLoad=${load.id}`
                }
            >
                {load.associatedIodNumber}
            </Link>
        )}
    </>
);

export default IodDetailsLink;
