import { buildClassesHook } from '../../helpers/styles';

export const useClasses = buildClassesHook({
    dataRow: {
        '& td': {
            padding: '0.5rem 0.75rem',
        },
    },
    cameraErrorIcon: {
        float: 'right',
    },
});
