import * as React from 'react';

import LoadTypeSelectionContainer from '../../../../containers/bookings/LoadTypeSelectionContainer';
import Button from '../../../../lib/bootstrap-ui/Button';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '../../../../lib/bootstrap-ui/Modal';
import { Booking } from '../../../../models/bookings/Booking';
import BookingDetails from '../../../../models/bookings/BookingDetails';
import { TrailerTypeResource } from '../../../../models/bookings/StoreBookingResource';
import { useClasses } from './UpdateResourcesModal.styles';

interface UpdateResourcesModalProps {
    booking: Booking;
    unsavedChanges: boolean;
    loading?: boolean;
    storeTrailerTypeResources?: TrailerTypeResource[] | null;
    bookingDetails: BookingDetails;
    showModal: boolean;
    onBookingDetailsChange(details: BookingDetails): void;
    onSubmit(): void;
    onClose(): void;
}

const UpdateResourcesModal: React.FC<UpdateResourcesModalProps> = ({
    booking,
    unsavedChanges,
    loading,
    storeTrailerTypeResources,
    bookingDetails,
    showModal,
    onBookingDetailsChange,
    onSubmit,
    onClose,
}) => {
    const classes = useClasses();

    const currentTrailerTypeResource =
        storeTrailerTypeResources?.find(
            (r) =>
                r.trailerType === bookingDetails.trailerType &&
                (!bookingDetails.secondaryTrailerType ||
                    r.secondaryTrailerType ===
                        bookingDetails.secondaryTrailerType)
        ) || null;

    const disableUpdateButton =
        !unsavedChanges ||
        (!!currentTrailerTypeResource &&
            bookingDetails.duration < currentTrailerTypeResource.hourDuration);

    const handleBookingDetailsChange = (newDetails: BookingDetails) => {
        if (
            newDetails.trailerType !== bookingDetails.trailerType ||
            (bookingDetails.secondaryTrailerType &&
                newDetails.secondaryTrailerType !==
                    bookingDetails.secondaryTrailerType)
        ) {
            newDetails.overrideStoreResourceValues = true;
        }
        onBookingDetailsChange(newDetails);
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={onClose}
            modalClassName={classes.updateResourcesModal}
        >
            <>
                <ModalHeader>
                    <h5>{`Update Booking ${booking.reference} Resources`}</h5>
                </ModalHeader>
                <ModalBody className={classes.updateResourcesModalBody}>
                    <div className={classes.resourceInputSection}>
                        <LoadTypeSelectionContainer
                            resourceOverrideClassName={classes.resourceOverride}
                            options={{
                                type: 'booking',
                                details: bookingDetails,
                            }}
                            loading={loading}
                            onBookingDetailsUpdate={handleBookingDetailsChange}
                            overrideDefaultResourcesOnTrailerTypeChange
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button styleType="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        styleType="primary"
                        onClick={onSubmit}
                        disabled={disableUpdateButton}
                    >
                        Update
                    </Button>
                </ModalFooter>
            </>
        </Modal>
    );
};

export default UpdateResourcesModal;
