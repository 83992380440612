import * as Msal from 'msal';
import {
    CLIENT_ID,
    LOGOUT_REDIRECT_URI,
    REDIRECT_URI,
    TENANT_ID
} from '../environment-variables';

export const msalConfig: Msal.Configuration = {
    auth: {
        clientId: CLIENT_ID,
        postLogoutRedirectUri: LOGOUT_REDIRECT_URI,
        redirectUri: REDIRECT_URI,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
};

const msalInstance = new Msal.UserAgentApplication(msalConfig);

export { msalInstance };
