import uniqueId from 'lodash.uniqueid';
import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import { useClasses } from './Switch.styles';

interface SwitchProps {
    value: boolean;
    label: string;
    id?: string;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({
    value,
    label,
    id,
    disabled = false,
    onChange,
}) => {
    const classes = useClasses();

    const [switchId] = useState<string>(id || uniqueId());

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked: boolean = event.target.checked;
        onChange(checked);
    };

    return (
        <div className="custom-control custom-switch">
            <input
                type="checkbox"
                className={classNames('custom-control-input', classes.switch)}
                checked={value}
                disabled={disabled}
                onChange={handleValueChange}
                id={switchId}
            />
            <label
                className={classNames(
                    'custom-control-label',
                    classes.switchLabel
                )}
                htmlFor={switchId}
            >
                {label}
            </label>
        </div>
    );
};

export default Switch;
