import classnames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';

import { getDayString, pluralise } from '../../../helpers/stringFunctions';
import Grid from '../../../lib/bootstrap-ui/Grid';
import { RecurringBookingToAdd } from '../../../models/bookings/RecurringBookingToAdd';
import { getTrailerTypeLabelForBooking } from '../../../services/trailerService';
import SingleColumnRow from '../../common/SingleColumnRow';
import { BookingSummaryRow } from './../view/summary';
import { useClasses } from './RecurringBookingFormSummaryPanel.styles';

interface RecurringBookingFormSummaryContainerProps {
    recurringBooking: RecurringBookingToAdd;
}

const getRecurrenceString = (recurringBooking: RecurringBookingToAdd) => {
    let recurrence = '';
    switch (recurringBooking.recurrencePattern) {
        case 'Daily':
            recurrence = 'Day';
            break;
        case 'Weekdays':
            recurrence = 'Weekday';
            break;
        case 'Weekly':
            if (recurringBooking.startDate) {
                const startDate = new Date(recurringBooking.startDate);
                recurrence = getDayString(startDate.getDay());
            } else {
                recurrence = 'Week';
            }
            break;
    }

    return `Every ${recurrence}`;
};

const renderCustomerInfo = (recurringBooking: RecurringBookingToAdd) => (
    <>
        {recurringBooking.customerName && (
            <BookingSummaryRow header="Customer Name">
                {recurringBooking.customerName}
            </BookingSummaryRow>
        )}

        {recurringBooking.customerName && <hr />}
    </>
);

const renderProductInfo = (recurringBooking: RecurringBookingToAdd) => (
    <>
        {recurringBooking.trailerType && (
            <BookingSummaryRow header="Load Type">
                {getTrailerTypeLabelForBooking(recurringBooking)}
            </BookingSummaryRow>
        )}

        {recurringBooking.resources &&
            recurringBooking.resources.length > 0 && (
                <BookingSummaryRow header="Resources">
                    {recurringBooking.resources.map((r, i) => (
                        <div key={i} className={'resourceItem'}>
                            {r}
                        </div>
                    ))}
                </BookingSummaryRow>
            )}
    </>
);

const renderTimeInfo = (recurringBooking: RecurringBookingToAdd) => (
    <>
        {recurringBooking.startDate && (
            <BookingSummaryRow header="Start Date">
                {format(
                    new Date(recurringBooking.startDate),
                    'dd/MM/yyyy HH:mm'
                )}
            </BookingSummaryRow>
        )}
        {recurringBooking.endDate !== null && (
            <BookingSummaryRow header="End Date">
                {format(new Date(recurringBooking.endDate), 'dd/MM/yyyy')}
            </BookingSummaryRow>
        )}

        {!!recurringBooking.duration && (
            <BookingSummaryRow header="Duration">
                {pluralise('hour', recurringBooking.duration)}
            </BookingSummaryRow>
        )}

        {recurringBooking.recurrencePattern && (
            <BookingSummaryRow header="Recurrence">
                {getRecurrenceString(recurringBooking)}
            </BookingSummaryRow>
        )}
    </>
);

const RecurringBookingFormSummaryPanel: React.FC<
    RecurringBookingFormSummaryContainerProps
> = ({ recurringBooking }) => {
    const classes = useClasses();

    return (
        <Grid className={classnames(classes.recurringBookingSummaryContainer)}>
            <SingleColumnRow className={classes.recurringBookingSummaryRow}>
                <h4>
                    Recurring {recurringBooking.bookingType} Booking Summary
                </h4>
            </SingleColumnRow>
            <hr />

            {recurringBooking.store && (
                <BookingSummaryRow header="Store">
                    {recurringBooking.store}
                </BookingSummaryRow>
            )}

            {renderCustomerInfo(recurringBooking)}
            {renderProductInfo(recurringBooking)}
            {renderTimeInfo(recurringBooking)}
        </Grid>
    );
};

export default RecurringBookingFormSummaryPanel;
