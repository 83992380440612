import * as React from 'react';
import { Load } from '../../../../models/loads/Load';
import LoadProgressDetailsLink from './LoadProgressDetailsLink';

interface PartLoadReferenceProps {
    className?: string;
    load: Load;
    displayLoadReferenceLink?: boolean;
    displayAmendmentLink?: boolean;
}

const PartLoadReference: React.FC<PartLoadReferenceProps> = ({
    className,
    load,
    displayLoadReferenceLink = false,
    displayAmendmentLink = false,
}) => {
    const anyAmendments = load.orderLoadingStatuses
        .filter((ols) => ols.releaseToChess)
        .some((ols) =>
            ols.order.pickLists.some((pl) => pl.outstandingAmendment)
        );

    return (
        <div className={className}>
            {displayLoadReferenceLink ? (
                <LoadProgressDetailsLink
                    loadId={load.id}
                    loadName={load.loadName}
                    displayAmendmentLink={false}
                />
            ) : (
                load.loadName
            )}

            {displayAmendmentLink && anyAmendments && (
                <LoadProgressDetailsLink
                    loadId={load.id}
                    displayAmendmentLink={true}
                />
            )}
        </div>
    );
};

export default PartLoadReference;
