import { ToastContainer } from 'react-toastify';
import Layout from './Layout';
import AlertHandler from '../../components/alerts/AlertHandler';
import * as React from 'react';
import Delay from '../../components/helpers/Delay';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import { Outlet } from 'react-router';
import AccessControlSuspendedModal from '../../components/admin/accessControl/AccessControlSuspendedModal';

const AppLayout: React.FC = () => (
    <Layout>
        <ToastContainer hideProgressBar toastClassName="dock-system-toast" />
        <AlertHandler />
        <AccessControlSuspendedModal />
        <React.Suspense
            fallback={
                <Delay ms={500}>
                    <LoadingIndicator />
                </Delay>
            }
        >
            <Outlet />
        </React.Suspense>
    </Layout>
);

export default AppLayout;
