import * as React from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import reactPlugin from '../helpers/appInsights';

const AppInsightsContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => (
    <AppInsightsContext.Provider value={reactPlugin}>
        {children}
    </AppInsightsContext.Provider>
);

export default AppInsightsContextProvider;
