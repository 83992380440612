import { buildClassesHook } from '../../../helpers/styles';
import { Theme } from '../../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    container: {
        display: 'block !important',
        position: 'absolute',
        right: 0,
        left: 0,
        maxHeight: '300px',
        overflowY: 'auto',
        zIndex: 1000,
        '& ul': {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
        },
    },
    fullWidthContainer: {
        display: 'block !important',
        position: 'absolute',
        maxHeight: '300px',
        overflowY: 'auto',
        zIndex: 1000,
        '& ul': {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
        },
    },
    openContainer: {
        border: `1px solid ${theme.colors.primary}`,
        boxShadow: '0 2px 5px 0 #00000040',
    },
    item: {
        backgroundColor: theme.colors.white,
        padding: '6px 15px',
        cursor: 'pointer',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: theme.colors.darkGrey,
    },
    input: {
        width: '100%',
    },
    highlightedItem: {
        background: theme.colors.primary,
        color: theme.colors.white,
    },
    menu: {
        backgroundColor: theme.colors.white,
    },
}));
