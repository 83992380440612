import * as React from 'react';
import { useContext, useState } from 'react';

import SingleColumnRow from '../../../components/common/SingleColumnRow';
import UnsavedChangesModal from '../../../components/common/UnsavedChangesModal';
import { LookupContext } from '../../../contexts/LookupDataProvider';
import SelectDropdown from '../../../lib/bootstrap-ui/Dropdown/SelectDropdown';
import Grid, { Column, Row } from '../../../lib/bootstrap-ui/Grid';
import { LoadingMilestoneThreshold } from '../../../models/loads/LoadingMilestoneThreshold';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { apiPut, useData } from '../../../services/api';
import { validThresholdDurationRegExp } from '../../../services/loadingProgressService';
import { getStoreDropdownOptions } from '../../../services/storeService';
import { minuteSecondRegExp } from '../../../services/timeStringService';
import RequireSaveContainer from '../RequireSaveContainer';
import { useClasses } from './LoadingMilestoneConfigContainer.styles';
import StoreLoadingThresholdConfigContainer from './StoreLoadingThresholdConfigContainer';

const areThresholdsEqual = (
    lhs: LoadingMilestoneThreshold,
    rhs: LoadingMilestoneThreshold
): boolean =>
    lhs.id === rhs.id &&
    lhs.milestone === rhs.milestone &&
    lhs.store === rhs.store &&
    lhs.thresholdDuration === rhs.thresholdDuration;

const LoadingMilestoneConfigContainer: React.FC = () => {
    const classes = useClasses();

    const { stores } = useContext(LookupContext);
    const [selectedStore, setSelectedStore] = useState<StoreNumber | null>(
        null
    );
    const [storeToChangeTo, setStoreToChangeTo] = useState<StoreNumber | null>(
        null
    );
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
    const [existingLoadingMilestones, loading, refreshLoadingMilestones] =
        useData<LoadingMilestoneThreshold[]>(
            selectedStore
                ? `Store/${selectedStore}/LoadingMilestone/Thresholds`
                : 'LoadingMilestone/Thresholds'
        );

    const hasLoadingThresholdUpdated = (
        loadingMilestoneThreshold: LoadingMilestoneThreshold
    ): boolean => {
        const matchingBayThreshold = existingLoadingMilestones?.find(
            (elm) => elm.id === loadingMilestoneThreshold.id
        );
        return (
            !!matchingBayThreshold &&
            !areThresholdsEqual(matchingBayThreshold, loadingMilestoneThreshold)
        );
    };

    const requestStoreChange = (
        outstandingChanges: boolean,
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const newSelectedStore = parseInt(
            event.target.value,
            10
        ) as StoreNumber;
        if (outstandingChanges) {
            setStoreToChangeTo(newSelectedStore);
            setShowSaveChangesModal(true);
        } else {
            setSelectedStore(newSelectedStore);
            setStoreToChangeTo(null);
        }
    };

    const handleSaveClicked = async (
        updatedMilestoneThresholds: LoadingMilestoneThreshold[]
    ) => {
        await apiPut('LoadingMilestone/Thresholds', updatedMilestoneThresholds);
        refreshLoadingMilestones();
        setStoreToChangeTo(null);
    };

    const isLoadingMilestoneThresholdValid = (
        threshold: LoadingMilestoneThreshold
    ): boolean => {
        return (
            !!threshold.thresholdDuration &&
            minuteSecondRegExp.test(threshold.thresholdDuration) &&
            validThresholdDurationRegExp.test(threshold.thresholdDuration)
        );
    };

    const handleDiscardChanges = () => {
        if (storeToChangeTo) {
            setSelectedStore(storeToChangeTo);
            setStoreToChangeTo(null);
        }
        setShowSaveChangesModal(false);
    };

    const handleGoBackAndClose = () => {
        setShowSaveChangesModal(false);
        setStoreToChangeTo(null);
    };

    return (
        <RequireSaveContainer<LoadingMilestoneThreshold>
            existingValues={existingLoadingMilestones || []}
            comparator={(lhs, rhs) => lhs.id === rhs.id}
            hasValueUpdated={hasLoadingThresholdUpdated}
            valueValidator={isLoadingMilestoneThresholdValid}
            Save={handleSaveClicked}
        >
            {({ updatedValues, outstandingChanges, onChange }) => (
                <>
                    <Grid fluid>
                        <Row className={classes.actionRow}>
                            <Column size={6}>
                                <SelectDropdown
                                    id="StoreSelect"
                                    label="Store:"
                                    className={classes.storeSelector}
                                    dropdownOptions={getStoreDropdownOptions(
                                        stores.map(String),
                                        {
                                            addAllStores: true,
                                        }
                                    )}
                                    selectedOption={selectedStore}
                                    onChange={(event) =>
                                        requestStoreChange(
                                            outstandingChanges,
                                            event
                                        )
                                    }
                                    disabled={loading}
                                    hideBottomPadding
                                />
                            </Column>
                        </Row>
                        <SingleColumnRow>
                            {selectedStore ? (
                                <StoreLoadingThresholdConfigContainer
                                    key={selectedStore}
                                    store={selectedStore}
                                    loadingMilestoneThresholds={
                                        updatedValues || []
                                    }
                                    onChange={onChange}
                                />
                            ) : (
                                stores
                                    .filter((store) => store.toString() !== '0')
                                    .map((store) => (
                                        <StoreLoadingThresholdConfigContainer
                                            key={store}
                                            store={
                                                store as unknown as StoreNumber
                                            }
                                            loadingMilestoneThresholds={
                                                updatedValues || []
                                            }
                                            onChange={onChange}
                                        />
                                    ))
                            )}
                        </SingleColumnRow>
                    </Grid>
                    {showSaveChangesModal && (
                        <UnsavedChangesModal
                            isOpen={showSaveChangesModal}
                            onRequestDiscardChanges={handleDiscardChanges}
                            onRequestClose={handleGoBackAndClose}
                            onRequestGoBack={handleGoBackAndClose}
                        />
                    )}
                </>
            )}
        </RequireSaveContainer>
    );
};

export default LoadingMilestoneConfigContainer;
