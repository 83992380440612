import * as React from 'react';
import RecurringBookingsContainer from '../../containers/recurringBookings/table/RecurringBookingsContainer';
import Page from '../common/Page';

const RecurringBookings: React.FC = () => (
    <Page>
        <RecurringBookingsContainer />
    </Page>
);

export default RecurringBookings;
