import classNames from 'classnames';
import * as React from 'react';

import { IodPlan } from '../../../models/iods/IodPlan';
import { StoreNumber } from '../../../models/stores/StoreNumber';
import { useAuth } from '../../../services/authentication';
import {
    AreIodPlansEqual,
    getAllTransferLoadsFromIodPlan,
    getAllFinishingTrailerLoadsFromIodPlan,
} from '../../../services/iodPlanService';
import {
    getIodPlanStatistics,
    IodPlanStatistics,
} from '../../../services/iodPlanStatisticsService';
import {
    userHasAccessToStore,
    userIsAdmin,
} from '../../../services/userService';
import Center from '../../common/Center';
import IodPlanGrid from '../view/IodPlanGrid';
import { useClasses } from './IodReplanCard.styles';
import { Checkbox } from '../../../lib/bootstrap-ui/Forms';

interface IodReplanCardProps {
    iodPlan: IodPlan;
    isDisabled?: boolean;
    isSelected?: boolean;
    onSelected(selectedPlan: IodPlan | null): void;
    onPlanHovered(hoveredPlan: IodPlan | null): void;
}

const arePropsEqual = (
    prevProps: IodReplanCardProps,
    nextProps: IodReplanCardProps
): boolean =>
    prevProps.isDisabled === nextProps.isDisabled &&
    prevProps.isSelected === nextProps.isSelected &&
    AreIodPlansEqual(prevProps.iodPlan)(nextProps.iodPlan);

const isLoadOfTypeInPlan =
    (iodPlan: IodPlan) =>
    (loadId: string, type: 'finishing-trailer' | 'transfer'): boolean => {
        if (type === 'transfer') {
            return getAllTransferLoadsFromIodPlan(iodPlan)
                .map((transfer) => transfer.id)
                .includes(loadId);
        }
        return getAllFinishingTrailerLoadsFromIodPlan(iodPlan)
            .map((load) => load.id)
            .includes(loadId);
    };

const IodReplanCard: React.FC<IodReplanCardProps> = ({
    iodPlan,
    isDisabled = false,
    isSelected = false,
    onSelected,
    onPlanHovered,
}) => {
    const { authState } = useAuth();
    const isUserAdmin: boolean = userIsAdmin(authState.user);

    const classes = useClasses();

    const isLoadOfType = isLoadOfTypeInPlan(iodPlan);
    const iodPlanStats: IodPlanStatistics = getIodPlanStatistics(iodPlan);

    const handleCardClick = () => {
        if (isDisabled) return;

        onSelected(!isSelected ? iodPlan : null);
    };

    return (
        <div
            className={classNames(classes.card, {
                [classes.cardSelected]: isSelected,
                [classes.cardDisabled]: isDisabled,
            })}
            onClick={handleCardClick}
            onMouseEnter={() => {
                !isDisabled && !isSelected && onPlanHovered(iodPlan);
            }}
            onMouseLeave={() => {
                !isDisabled && !isSelected && onPlanHovered(null);
            }}
        >
            <Checkbox
                size={15}
                checked={isSelected}
                disabled={isDisabled}
                onChange={handleCardClick}
            />
            <IodPlanGrid
                iodPlan={iodPlan}
                gridClassname={classes.planGrid}
                gridItemRenderer={(load) => (
                    <Center
                        className={classNames(classes.store, classes.transfer, {
                            [classes.transfer]: isLoadOfType(
                                load.id,
                                'transfer'
                            ),
                            [classes.finishingTrailer]: isLoadOfType(
                                load.id,
                                'finishing-trailer'
                            ),
                            [classes.userTransfer]:
                                !isUserAdmin &&
                                isLoadOfType(load.id, 'transfer') &&
                                userHasAccessToStore(
                                    authState.user,
                                    load.sourceStore as StoreNumber
                                ),
                            [classes.userFinishingTrailer]:
                                !isUserAdmin &&
                                isLoadOfType(load.id, 'finishing-trailer') &&
                                userHasAccessToStore(
                                    authState.user,
                                    load.sourceStore as StoreNumber
                                ),
                        })}
                    >
                        {load.sourceStore}
                    </Center>
                )}
            />
            <Center className={classes.replanStatsContainer}>
                <span>
                    <span className={classes.statsHeader}>{'Transfers: '}</span>
                    <span className={classes.statsValue}>
                        {iodPlanStats.transferCount}
                    </span>
                </span>
                <span>
                    <span className={classes.statsHeader}>
                        {'Transferred Pallets: '}
                    </span>
                    <span className={classes.statsValue}>
                        {iodPlanStats.transferredPalletCount}
                    </span>
                </span>
                <span>
                    <span className={classes.statsHeader}>
                        {'Tunnelled Pallets: '}
                    </span>
                    <span className={classes.statsValue}>
                        {iodPlanStats.tunnelledPalletCount}
                    </span>
                </span>
                <span>
                    <span className={classes.statsHeader}>
                        {'Resequenced Pallets: '}
                    </span>
                    <span className={classes.statsValue}>
                        {iodPlanStats.resequencePalletCount}
                    </span>
                </span>
            </Center>
        </div>
    );
};

export default React.memo(IodReplanCard, arePropsEqual);
