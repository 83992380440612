import classNames from 'classnames';
import * as React from 'react';

import { useClasses } from './ModalHeader.styles';

interface ModalHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    showCloseButton?: boolean;
    onCloseButtonClick?(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void;
}

const ModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({
    className,
    children,
    showCloseButton = false,
    onCloseButtonClick,
    ...rest
}) => {
    const classes = useClasses();

    return (
        <div
            className={classNames(
                'modal-header',
                classes.modalHeader,
                className
            )}
            {...rest}
        >
            {children}
            {showCloseButton && (
                <button
                    type="button"
                    className={classNames('close', classes.closeButton)}
                    onClick={onCloseButtonClick}
                >
                    <span>&times;</span>
                </button>
            )}
        </div>
    );
};

export default ModalHeader;
