import * as React from 'react';

const Offline: React.FC = () => (
    <div style={{ margin: '1rem' }}>
        <h3>Application Offline</h3>
        <p>
            Updated version of application can be found{' '}
            <a href="https://docksystem.reedboardall.com">here</a>
        </p>
    </div>
);

export default Offline;
