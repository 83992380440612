import { buildClassesHook } from '../../helpers/styles';
import { Theme } from '../../theme';

export const useClasses = buildClassesHook((theme: Theme) => ({
    footerRow: {
        backgroundColor: theme.colors.white,
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${theme.colors.lightGrey}`,
    },
    saveButton: {
        margin: '15px',
    },
}));
